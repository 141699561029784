import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  AssessmentTitle,
  CandidateEmail,
  CompanyName,
  rootAssessmentNameRedux
} from '../../redux/AssessmentSession/selectors';
import mixpanel from '../mixpanel';
import events from './mixpaneEventNames.json';

export default function useMixpanelOopsPageview() {
  const companyName = useSelector(CompanyName);
  const assessmentTitle = useSelector(AssessmentTitle);
  const rootAssessmentName = useSelector(rootAssessmentNameRedux);
  const candidateEmail = useSelector(CandidateEmail);

  useEffect(() => {
    mixpanel.track(events.oopsPageview, {
      'Assessment Name': assessmentTitle,
      'Root Assessment Name': rootAssessmentName,
      'Company Name': companyName,
      'Candidate Email': candidateEmail
    });
  }, []);
}
