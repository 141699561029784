import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import {
  candidateSolutionsSelector,
  selectedAssessmentForReportSelector
} from '../../../redux/candidateDetail/selectors';
import { dateFormatter } from '../../../utils/helpers';

const ProgressBar = ({ score }) => {
  return (
    <div className="FlexRow gap-16 verticallyCenter">
      <div className="w-100">
        <div
          className="w-100"
          style={{
            background: '#f4f4f4',
            borderRadius: '3px',
            height: '12px',
            alignSelf: 'center'
          }}
        >
          <div
            style={{
              height: '100%',
              borderBottomLeftRadius: '3px',
              borderTopLeftRadius: '3px',
              width: `${(score !== -1 ? score : 0) || 0}%`,
              background: '#4AC389',
              borderRadius: score === 100 ? '3px' : 'inherit'
            }}
          />
        </div>
      </div>

      <span
        className="FlexRow fw-500 font-16 c-darkGray verticallyCenter"
        style={{
          lineHeight: '18px'
        }}
      >
        {score !== -1 ? `${score}/100` : 'N/A'}
      </span>
    </div>
  );
};

export default function ReportAssessmentDetail() {
  const {
    label = null,
    currentFunnel = null,
    score = null,
    addDate = null,
    arriveType = null
  } = useSelector(selectedAssessmentForReportSelector) || {};

  const solutionList = useSelector(candidateSolutionsSelector);
  const totalTime = solutionList?.length > 0 && solutionList?.reduce((sum, x) => sum + x?.time, 0);
  const { t } = useTranslation(['candidateDetail', 'dateDict']);

  return (
    <div className="FlexColumn gap-16">
      <span className="fw-500 font-18 c-darkGray">{label || '-'}</span>

      <div className="FlexRow">
        <div className="FlexColumn gap-10" style={{ width: '40%' }}>
          <span className="fw-400 font-16 c-darkGray-05">
            {arriveType === 10 ? (
              <span>
                <Trans i18nKey="invDate" ns="candidateDetail">
                  0<span>1</span>
                </Trans>
              </span>
            ) : (
              <span>
                <Trans i18nKey="appDate" ns="candidateDetail">
                  0<span>1</span>
                </Trans>
              </span>
            )}
          </span>
          <span className="fw-400 font-16 c-darkGray">
            {addDate && dateFormatter(addDate, false, x => t(`dateDict:${x}`))}
          </span>
        </div>

        <div className="FlexColumn gap-10" style={{ width: '40%' }}>
          <span className="fw-400 font-16 c-darkGray-05">{t('hiringStatus')}</span>
          <span className="fw-400 font-16 c-darkGray">{currentFunnel || '-'}</span>
        </div>

        <div className="FlexColumn gap-10" style={{ width: '20%' }}>
          <span className="fw-400 font-16 c-darkGray-05">{t('timeTaken')}</span>
          <span className="fw-400 font-16 c-darkGray">
            {totalTime % 60 === 0 ? (
              <Trans i18nKey="totalMin" ns="candidateDetail" values={{ totalMin: totalTime / 60 }}>
                0<span>1</span>
                <span>2</span>
              </Trans>
            ) : totalTime > 60 ? (
              <span>
                <Trans
                  i18nKey="totalMinSec"
                  ns="candidateDetail"
                  values={{ totalMin: Math.floor(totalTime / 60), totalSec: totalTime % 60 }}
                >
                  0<span>1</span>
                  <span>2</span>
                  <span>3</span>
                  <span>4</span>
                </Trans>
              </span>
            ) : (
              <Trans i18nKey="totalSec" ns="candidateDetail" values={{ totalSec: totalTime }}>
                0<span>1</span>
                <span>2</span>
              </Trans>
            )}
          </span>
        </div>
      </div>

      <div className="FlexColumn gap-10 mt-4">
        <span className="fw-400 font-16 c-darkGray-05">{t('candScore')}</span>
        <ProgressBar score={score} />
      </div>
    </div>
  );
}
