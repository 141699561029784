import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { multiSelectAnswersRedux, selectedOptionIndexRedux } from '../../../redux/addQuestion/selectors';
import { setMultiSelectAnswersRedux, setSelectedOptionIndexRedux } from '../../../redux/addQuestion/slice';
import styles from '../Styles/MultiSelect.module.css';
import MultiSelectTextEditor from './MultiSelectTextEditor';

function MultiSelectAnswers() {
  const dispatch = useDispatch();
  const reduxMultiSelectAnswers = useSelector(multiSelectAnswersRedux);
  const reduxSelectedOptionIndex = useSelector(selectedOptionIndexRedux);

  const [arrayElementChange, setArrayElementChange] = useState(0);
  const [numberOfChoices, setNumberOfChoices] = useState(reduxMultiSelectAnswers.length);

  useEffect(async () => {
    setArrayElementChange(0);
  }, [arrayElementChange]);


  useEffect(async () => {
    setNumberOfChoices(reduxMultiSelectAnswers.length);
  }, [reduxMultiSelectAnswers]);

  const addChoice = () => {
    setNumberOfChoices(numberOfChoices + 1);
  };

  useEffect(async () => {
    if (numberOfChoices > reduxMultiSelectAnswers.length) {
      const temp = reduxMultiSelectAnswers.slice();
      temp.push('');
      dispatch(setMultiSelectAnswersRedux(temp));
    }
  }, [numberOfChoices]);

  const removeChoice = index => {
    if (numberOfChoices > 2) {
      setNumberOfChoices(numberOfChoices - 1);
      const temp = reduxMultiSelectAnswers.slice();
      temp.splice(index, 1);
      dispatch(setMultiSelectAnswersRedux(temp));

      if (reduxSelectedOptionIndex > index) {
        dispatch(setSelectedOptionIndexRedux(reduxSelectedOptionIndex-1));
      }

      if (reduxSelectedOptionIndex === index) {
        dispatch(setSelectedOptionIndexRedux(0));
      }
    }
  };

  const updateChoice = (val, index) => {
    const temp = reduxMultiSelectAnswers.slice();
    temp[index] = val;
    dispatch(setMultiSelectAnswersRedux(temp));
  };

  const updateSelect = index => {
    dispatch(setSelectedOptionIndexRedux(index));
  };

  const [clicked, setClicked] = useState(-1);

  return (
    <div className={styles.MultiSelectRightPane}>
      <div className={styles.MultiSelectHeader}>
        <b>Answers</b><br />
        Enter the options and choose the right one
      </div>

      <div className={styles.AnswerBlock}>
        {reduxMultiSelectAnswers.map((e, i) => (
          <div 
            className={`${styles['FormInput-custom']}`}
            style={clicked !== i ? {maxHeight: "200px"} : {maxHeight: "550px"}}
            onClick={() => setClicked(i)}
          >
            <div className={styles.TopRow}>
              <input
                type="checkbox"
                checked={i === reduxSelectedOptionIndex}
                onClick={() => updateSelect(i)}
                name="option_name"
              />
              <span style={{marginLeft: "5px"}}> Option #{i + 1}</span>
              <button
                type="button"
                style={{ marginLeft: "auto"}}
                onClick={() => {
                  removeChoice(i);
                  setArrayElementChange(1);
                }}
              >
                X
              </button>
            </div>

            <MultiSelectTextEditor index={i} change={arrayElementChange} clicked={clicked} />
          </div>
        ))}
        <br />
        {numberOfChoices < 5 ? (
          <input type="button" value="Add Answer" onClick={() => {addChoice(); setArrayElementChange(1);}} />
        ) : null}
      </div>
    </div>
  );
}
export default MultiSelectAnswers;
