import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';

const FilterDetailSlice = createSlice({
  name: 'filterDetail',
  initialState,
  reducers: {
    setInitial: () => initialState,
    setEmail: (state, action) => {
      state.candidateEmail = action.payload;
    },
    setID: (state, action) => {
      state.candidateId = action.payload;
    },
    setDefaultAssessmentId: (state, action) => {
      state.defaultAssessmentId = action.payload;
    },
  }
});

export const {
  setInitial,
  setEmail,
  setID,
  setDefaultAssessmentId,
} = FilterDetailSlice.actions;

export default FilterDetailSlice.reducer;
