export default {
  loginVol2: `api/User/Login`,
  getCurrentUserDetail: `api/User/GetCurrentUserDetail`,

  getRootAssessmentList: `api/assessment/GetRootAssessmentList`,

  // New api assessment list call
  getCompanyAssessmentList: `api/company/GetAssessmentListByCompanyId`,
  // getCompanyAssessmentCandidateCounts: `/api/Company/GetCompanyAssessmentCounts`,
  getCompanyAssessmentFilterCount: `/api/company/GetAssessmentCountsByCompanyId`,

  // Add coding question popup
  getCodingLanguages: `api/Question/GetCodingLanguages`,
  // Custom api assessment list call
  createCustomAssessment: `/api/assessment/CreateCustomAssessment`,

  // update Custom Assessment -> also call when page is reloaded ? (creates a new assessment based on custom list)
  updateCustomAssessment: `api/assessment/UpdateCustomAssessment`,

  // Add&Delete free-text and multiple choice question
  removeSkillBasedQuestion: `api/assessment/RemoveQuestionFromAssessment`,
  updateQuestionWeight: `api/assessment/UpdateQuestionWeight`,

  // Api for the filter column

  getQuestionByFilter: `api/Question/GetQuestionByFilter`,

  // add question based question
  addQuestionBasedQuestion: `api/assessment/AddQuestionBasedQuestion`,

  // Try assessment
  tryAssessment: `api/assessment/TryAssessment`,

  // Send for Review
  getUserListByCompany: `/api/user/GetUserListByCompany`,

  sendEmailForReview: `/api/Assessment/ShareLinkForReview`,

  // get skills based on job role
  getCandidatePublicInfo: '/api/Candidate/GetCandidatePublicInfo',

  getCandidatePublicSolutions: '/api/Candidate/GetCandidatePublicSolutions',

  getCandidateAssessmentPublicLabels: '/api/Candidate/GetCandidateAssessmentPublicLabels',

  getCandidateIsScored: '/api/Candidate/GetCandidateIsScored',

  postRunCode: `api/AnswerQuestion/AnswerCodeControl`,
  getCreateQuestionDropdown: `api/Question/GetCreateQuestionDropdown`,
  getCreateQuestionSubcategoryDropdown: `api/Question/GetCreateQuestionSubcategoryDropdown`,
  getCreateQuestionAssessmentLabelDropdown: `api/Question/GetCreateQuestionAssessmentLabelDropdown`,
  createMultipleChoiceQuestion: `api/Question/CreateMultipleChoiceQuestion`,
  createFreeTextQuestion: `api/Question/CreateFreeTextQuestion`,
  createCodingQuestion: `api/AnswerQuestion/TestMainCodeCreate`,
  getQuestionDetail: `api/Question/GetQuestionDetail`,
  getCandidateSolutions: `/api/Candidate/getCandidateSolutions`,
  getCandidateAssessmentLabels: '/api/Candidate/GetCandidateAssessmentLabels',
  inviteUser: `/api/Assessment/UserInvite`,

  addAssessment: `api/Assessment/AddAssessment`, // For new assessment creation,
  getAtsList: `api/Assessment/GetAssessmentDistributorListForEnableAts`,

  getAssessmentDetailAfterCreate: `api/Assessment/GetCompanyAssessmentAfterCreate`,

  getCurrentPlan: `api/Bundle/GetCurrentPlan`,
  getAllPlans: `api/Bundle/GetBundles`,
  getCurrentUsage: `api/Company/GetCurrentUsage`,
  getAddOns: `api/Company/GetAddOns`,
  getPackageDetail: `api/Bundle/GetBundleDetail`,
  getBillingInfo: `api/Company/GetBillingInformation`,
  editBillingInfo: `api/Company/EditBillingInformation`,
  getCountries: `api/Country/GetCountries`,
  applicantSendEmail: `api/Applicant/ApplicantAssessmentSendMail`,
  addCard: `api/Payment/CreateCard`,
  getMyCards: `api/Payment/MyCards`, // Bu tam olarak ne döndürüyor farklı anlamadım
  contactSales: `api/BundleItemMap/ContactSalesMail`,
  createPackageOrder: `api/Order/PurchaseOrder`,
  comparePlansAndFeatures: `api/Bundle/GetBundleDetails`,
  postSqlRunCode: `api/AnswerQuestion/AnswerSqlCodeControl`,
  createSqlQuestion: `api/AnswerQuestion/TestMainSqlCodeCreate`,
  // getAssessmentCandidateList: `api/Company/GetAssessmentCandidateFilterList`,
  // getCandidateCountByFunnelId: `api/Funnel/GetCandidateCountByFunnelId`,
  getCandidateInformationByFunnelId: `api/Funnel/GetCandidateInformationByFunnelId `,
  addUserToFunnel: `api/Funnel/AddUsersToFunnel`,
  getAssessmentListForCandidate: `api/Candidate/GetAssessmentCandidateList`,
  // getAssessmentListCounts: `api/Company/GetCompanyAssessmentCounts`,

  // Assessment flow
  applicantAssessmentApplyEncrypted: `api/AssessmentSession/ApplicantAssessmentApplyEncrypted`,
  getCandidateApplicationDetail: `api/AssessmentSession/CandidateApplicationDetail`,
  candidateHasData: `api/Candidate/CandidateHasDataOrExistScore`,
  candidateContractCheck: `api/Candidate/CandidateContractCheck`,
  candidateContractConfirm: `api/Candidate/CandidateContractConfirm`,
  startAssessmentSession: `api/AssessmentSession/StartAssessmentSession`,
  getNextQuestion: `api/AssessmentSession/GetNextQuestionFromAssessment`,
  getCurrentQuestion: `api/AssessmentSession/GetCurrentQuestionFromAssessment`,
  submitMultipleChoiceQuestionAnswer: `api/AssessmentSession/SubmitMultipleChoiceQuestionAnswer`,
  submitFreeTextQuestionAnswer: `api/AssessmentSession/SubmitFreeTextQuestionAnswer`,
  submitCodingQuestionAnswer: `api/AssessmentSession/SubmitCodingQuestionAnswer`,
  runCodeTestPage: `api/AssessmentSession/RunCode`,
  getAssessmentSessionDetail: `api/AssessmentSession/GetAssessmentSessionDetail`,
  ChangeOrAddApplicantDetail: `api/Candidate/ChangeOrAddApplicantDetail`,
  incrementTabSwitch: `/api/AssessmentSession/IncrementTabSwitch`,
  applyCandidateToAssessment: `/api/Candidate/ApplyCandidateToAssessment`,
  // getCandidateSolutions: `api/Candidate/GetCandidateSolutions`,

  unsubscribeAddOn: `api/Order/UnsubscribeAddOn`,
  unsubscribePlan: `api/Company/CancelPackage`,
  downgradePackage: `api/Company/CompanyBundleFuturePlanCreate`,
  upgradePackage: `api/Company/CompanyBundleUpgradePlan`,

  getCompanyProfile: `api/Company/GetCompanyProfile`,
  updateCompanyProfile: `api/Company/UpdateCompanyProfile`,
  getProfile: `api/User/MyProfile`,
  updateProfile: `api/User/UpdateMyProfile`,
  // Sales screen
  getCustomerSuccessTeam: `api/Company/GetAccountManagerList`,
  getCompanyList: `api/Company/GetCompanyList`,
  getCompanyBundleInfo: `api/Company/GetCurrentCustomBundle`,
  getTemplates: `api/Bundle/GetTemplateDropdownList`,
  getTemplateInfo: `api/Bundle/GetTemplateById`,
  createTemplate: `api/Bundle/CreateTemplate`,
  updateCompany: `api/Company/UpdateCustomBundleItem`,
  connectCompanyWithBundle: `api/Bundle/ConnectTemplateWithCompany`,
  // Create company apisi
  createCompany: `api/Company/CreateCompany`,
  cancelCustomBundle: `api/Company/CancelCustomBundle`,
  publishAssessmentControl: `api/Company/IsCompanyNeededPublishAssessment`,

  // new tokenden gelenler
  changeCompany: `api/Company/ChangeCompany`,
  // getUserList: `api/User/GetUserListByCompany`, // Duplicate api - birini kaldıralım
  addUserToCompany: `api/User/AddUserToCompany`,
  refreshPassword: `api/User/RefreshPassword`,
  resendInvitation: `api/User/SendInvitation`,

  getTeam: `api/Company/GetTeam`,
  makeAdmin: `api/User/MakeAdmin`,
  removeFromAdmins: `api/User/RemoveFromAdmins`,
  editTeamMember: `api/Company/EditCompanyUser`,
  deleteTeamMember: `api/Company/DeleteUserFromCompany`,
  signupRequest: 'api/User/SignUpRequest',

  getUserGroupTypeList: 'api/user/GetUserGroupTypeList',

  // Assessment Detail api
  getAssessmentDetail: 'api/Assessment/GetCompanyAssessmentDetail',

  // Assessment Settings Popup API
  addConnectionToAssessment: 'api/Assessment/AddConnectionToAssessment',
  getAssessmentInfo: 'api/Assessment/GetAssessmentSettingsPopupInfo',
  changeAuthorizedUsersOfAssessment: 'api/Assessment/ChangeAssessmentuserList',
  updateAssessmentInfo: 'api/Assessment/UpdateCompanyAssessmentInfo',
  getAssessmentStatus: 'api/Assessment/GetCompanyAssessmentStatus',
  getAssessmentConnections: 'api/Assessment/GetAssessmentConnections',
  getCandidateDataExport: 'api/Assessment/GetCandidateDataExport',
  getCandidateInfoFromEmail: 'api/Candidate/GetCandidateInfoFromEmail',

  // Cancel the cancel
  abortCancelInProgress: 'api/Company/AbortPackageCancellation',

  purchaseAddOn: 'api/Order/PurchaseAddOn',
  isAuthorizedForCandidateReport: 'api/User/IsUserAuthorizeCandidateReport',
  unlockCandidateReport: 'api/Candidate/UnlockCandidateReport',
  getInvoiceList: 'api/Company/GetInvoiceList',
  downloadInvoice: 'api/CompanyBundle/DownloadInvoice',

  // Signup api
  signupByEmail: 'api/User/SignUpByEmail',
  signupStep2: 'api/User/SignUp',
  requestDemo: 'api/User/RequestDemo',
  sendVerificationEmail: 'api/User/SendVerificationMail',
  forgotPassword: 'api/User/ForgotPassword',
  createSignupInfo: 'api/User/CreateSignupInfo',
  activateUser: 'api/User/UserActivate',
  quickEmailVerification: 'api/User/QuickEmailVerification',
  invitedUserActivate: 'api/User/InvitedUserActivate',
  getCompanyLogoByUserId: 'api/Company/GetCompanyLogoByUser',
  forgotPasswordCheck: 'api/User/ForgotPasswordCheck',
  getUpgradePrices: 'api/Payment/GetPaymentTotalPrice',

  // Root assessment filtering
  getRootAssessmentDropdownList: 'api/Assessment/AssessmentDropdownList',
  // Rejection Mail New
  getCompanySubjectAndEmailContent: 'api/Company/GetCompanySubjectAndEmailContent',
  sendRejectedEmailContent: 'api/Company/SendRejectedEmailContent',
  createRejectedEmailContent: 'api/company/CreateRejectedEmailContent',
  justSendEmailContent: 'api/company/JustSendEmailContent',

  // new Assessment Detail
  getCandidatesByFilter: 'api/Candidate/GetCandidatesFromAssessmentByFilterV2',
  getFunnelInformation: 'api/Funnel/GetFunnelInfo',
  addNewFunnel: 'api/Funnel/AddFunnel',
  updateFunnelName: 'api/Funnel/UpdateFunnelName',
  deleteFunnel: 'api/Funnel/DeleteFunnel',
  updateFunnelPriority: 'api/Funnel/UpdateFunnelPriority',
  getScreeningFilters: 'api/Screening/GetAllScreeningQuestions',
  getIndustries: 'api/Company/GetIndustries',
  getSchools: 'api/Company/GetSchools',
  getRejectionReasons: 'api/Assessment/GetRejectionReasons',
  getFilterMajors: 'api/Company/GetMajors',

  fetchShortUrl: 'api/create-short-url',

  // Candidate Report //
  getCandidateInfo: 'api/Candidate/GetCandidateInfo',
  // Comment API's
  createComment: 'api/CommentCandidate/CreateComment',
  updateComment: 'api/CommentCandidate/UpdateComment',
  deleteComment: 'api/CommentCandidate/DeleteComment',
  getComments: 'api/CommentCandidate/GetComments',

  getLogoByCompanyId: 'api/Company/GetCompanyLogo',

  // Screening Questions
  getScreeningQuestions: 'api/Screening/GetScreeningQuestions',
  saveScreeningAnswer: 'api/Screening/ScreeningCandidateAnswer',

  getWelcomeCandidateLink: 'api/assessment/GetWelcomeCandidateLink',
  // Candiate Detail
  getCandidateProfile: 'api/Candidate/GetCandidateProfile',
  getCandidatePositions: 'api/candidate/getpositions',
  unlockNewOpsCandidate: 'api/Candidate/UnlockNewOpsCandidate',
  newOpsCandidateCheck: 'api/Candidate/GetNewOpsCandidateCheck',
  getNotes: 'api/Note/GetAllByUserId',
  createNote: 'api/Note/Create',
  updateNote: 'api/Note/Update',
  deleteNote: 'api/Note/Delete',
  getNotebyId: 'api/Note/GetById',
  shareProfileLink: 'api/Candidate/ShareProfileLink',
  getCandidateBackground: 'api/Candidate/GetCandidateBackground',
  getCandidateCV: 'api/Candidate/GetCandidateCv',
  getFilteredUserId: 'api/Candidate/GetFilteredUserIdV2',
  getPersonalInformation: 'api/Candidate/GetPersonalInformation',
  getActivities: 'api/Candidate/GetActivities',

  // Assessment Creation
  generateTest: 'api/ChatGpt/CreateRootAssessment',

  // Preview Assessment
  previewDraftAssessmentCheck: 'api/Assessment/PreviewDraftAssessmentDetail',
  editQuestion: 'api/Question/EditQuestion',
  removeDraftQuestion: 'api/Question/DeleteDraftQuestion',
  regenerateQuestions: 'api/ChatGpt/RegenerateQuestions',
  publishAssessment: 'api/Assessment/PublishAssessment',
  regenerateAssessment: 'api/ChatGpt/RegenerateAssessment',
  regenerateAssessmentAvailabilityCheck: 'api/ChatGpt/CheckAssessmentCreateAvailability',
  regenerateQuestionAvailabilityCheck: 'api/ChatGpt/CheckRegenerateQuestionAvailability',

  // Assessment Record Page
  getCandidateAssessmentRecord: 'api/Candidate/GetCandidateAssessmentRecords',
  getConnectedAssessmentCount: 'api/Candidate/GetConnectedAssessmentCount',
  confirmDeletingAssessmentRecord: 'api/Candidate/ConfirmDeletingAssessmentRecord',
  // Candidate Search
  getCandidateSearchList: 'api/Candidate/GetCandidateByFilter',

  getJobDescriptionList: 'api/Assessment/GetJobDescriptionList',

  // Admin Elastic Screen
  getUserElasticInfo: '/api/Candidate/GetElasticUsersInfo',
  updateUserElasticInfo: 'api/Candidate/UpdateElasticUsersInfo',

  // Auto apply
  getUserAutoApply: 'api/Candidate/GetUserAutoApply',
  applyUserAutoApply: 'api/Candidate/AutoApplyCandidate',
  deactivateUserAutoApply: 'api/Candidate/DeactivateAutoApply',
  triggerAssessment: 'api/Assessment/TriggerAssessmentEmail',
  getUserAutoApplyList: 'api/Candidate/GetUserAutoApplyList',
  getAllCompanyAutoApplyList: 'api/Company/GetAllCompaniesAutoApplyStatusList',
  updateAutoApplyStatus: 'api/Company/UpdateAutoApplyStatus',

  // Save Filter
  getFilters: 'api/Assessment/GetFilters',
  saveFilter: 'api/Assessment/SaveFilter',
  renameFilter: 'api/Assessment/RenameFilter',
  deleteFilter: 'api/Assessment/DeleteFilter',
  // Image save
  imageSave: 'api/common/ImageSave',
  // PiT
  getPitTestResults: 'api/Candidate/GetPitTestResult',
  sendPitTestToCandidate: 'api/Candidate/SendPitTestToCandidate',
  // Proficiency
  getHrPeakTestResults: 'api/Candidate/GetHrPeakTestResult',
  sendHrPeakTestToCandidate: 'api/Candidate/SendHrPeakTestToCandidate',
  // Admin Position without assessment
  changeHasAssessment: 'api/Assessment/ChangeHasAssessment',

  // Candidate camera shot
  saveCameraShot: 'api/AssessmentSession/SaveScreenShot',
  getCameraShots: 'api/AssessmentSession/GetScreenShots',
  // Garanti survey
  saveSurveyAnswers: 'api/Assessment/Garantisurvey',
  // Custom Hiring Funnel
  getCustomFunnelList: 'api/Funnel/GetCustomFunnelList',
  addCustomFunnel: 'api/Funnel/AddCustomFunnelGroup',
  renameCustomFunnel: 'api/Funnel/RenameCustomFunnelGroup',
  deleteCustomFunnel: 'api/Funnel/DeleteCustomFunnelGroup',
  updateCustomFunnelGroup: 'api/Funnel/UpdateCustomFunnelGroup',

  // Insight
  getInsightApplicantsGeneral: 'api/Company/GetInsightApplicantsGeneral',
  getInsightSourceOfApplication: 'api/Company/GetInsightSourceOfApplication',
  getInsightExperienceLevel: 'api/Company/GetInsightExperienceLevel',
  getInsightApplicationDays: 'api/Company/GetInsightApplicationDays',
  getInsightTopSchools: 'api/Company/GetInsightTopSchools',
  getInsightTopIndustries: 'api/Company/GetInsightTopIndustries',
  getCompanyPositions: 'api/Company/GetCompanyPositions',
  getAssignedActivePosition: 'api/Company/GetAssignedActivePosition',
  getInsightSourceOfHireCompany: 'api/Company/GetInsightSourceOfHireCompany',
  getRecruitmentPerformence: 'api/Company/GetRecruitmentPerformence',
  getInsightFunnelActivities: 'api/Company/GetInsightFunnelActivities',

  // Admin Edit Assessment Detail
  getPositionDetails: 'api/Assessment/GetPositionDetails',
  modifyPositionDetails: 'api/Assessment/ModifyPositionDetails',

  // Admin Candidate Result
  getContent: 'api/Company/GetContent',
  updateContent: 'api/Company/UpdateContent',

  // Admin IsScreening Settings
  getDefaultRequirements: 'api/Company/GetDefaultRequirements',
  updateDefaultRequirements: 'api/Company/UpdateDefaultRequirements',
  changeAssessmentNeeds: '/api/Assessment/ChangeAssessmentNeeds',

  // Case Study
  getCaseStudy: '/api/Assessment/GetCaseStudy',
  uploadCaseStudy: '/api/Assessment/UploadCaseStudy',
  // Suspended User Notify CS
  sendSuspendedAccountMail: 'api/Company/SendSuspendedAccountMail',

  // OTP Verification
  sendVerificationCode: '/api/Candidate/SendVerificationCode',
  updatePhoneNumber: '/api/Candidate/UpdatePhoneNumber',
  validateVerificationCode: '/api/Candidate/ValidateVerificationCode',
  sendEmailVerificationCode: '/api/Candidate/SendEmailVerificationCode',

  // BigFive PT
  getPersonalityTest: '/api/v3/get-personality-test',
  sendPersonalityTest: 'api/v3/invite-personality-test'
};
