import React from "react";
import PageContainer from "../../../Common/Pages/PageContainer";
import AssessmentRecordContent from "./AssessmentRecordContent";


const AssessmentRecordPage = () => {

  return (
    <PageContainer>
      <AssessmentRecordContent />
    </PageContainer>
  )
}

export default AssessmentRecordPage;