import React, { useCallback, useEffect, useRef } from 'react';
import Webcam from 'react-webcam';
import blackImageConst from '../../../../utils/blackImageConst';

export default function WebcamComponent({ visible = false, onCapture = () => {} }) {
  const webcamRef = useRef(null);
  // const [imgSrc, setImgSrc] = useState(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef?.current?.getScreenshot();
    // setImgSrc(imageSrc);
    onCapture(imageSrc || blackImageConst?.file);
  }, [webcamRef]);

  useEffect(() => {
    document.addEventListener('capturePhoto', capture);

    return () => {
      document.removeEventListener('capturePhoto', capture);
    };
  });

  return (
    <div style={visible ? {} : { maxWidth: '0', maxHeight: '0' }}>
      <Webcam
        mirrored
        minScreenshotWidth={720}
        width={visible ? 240 : 0}
        ref={webcamRef}
        screenshotFormat="image/jpg"
      />
      {/* {imgSrc && <img style={{ top: '10px' }} src={imgSrc} alt="img" />} */}
    </div>
  );
}
