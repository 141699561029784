import { useEffect, useState } from 'react';

/**
 * A custom React hook that detects when the page has been translated by services like Google Translate
 * by monitoring for the addition of 'translated-ltr' class on the <html> element.
 */
function useTranslationDetection() {
  const [translateEnabled, setTranslateEnabled] = useState(false);

  useEffect(() => {
    // Callback to execute when mutations are observed
    const callback = function (mutationsList, observer) {
      // Directly check the <html> element for the 'translated-ltr' class
      if (document.documentElement.classList.contains('translated-ltr')) {
        // Execute the callback if translation is detected
        setTranslateEnabled(true);
      } else {
        setTranslateEnabled(false);
      }
    };


    callback()
    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Configuration for the observer (to monitor class attribute changes)
    const config = { attributes: true, attributeFilter: ['class'] };

    // Start observing the <html> element for configured mutations
    observer.observe(document.documentElement, config);

    // Cleanup function to disconnect the observer when the component unmounts
    return () => observer?.disconnect();
  }, []);

  return translateEnabled;
}

export default useTranslationDetection;
