import React, { useEffect, useState } from 'react';
import PageContainer from '../../../Common/Pages/PageContainer';
import Dropdown from '../../../Common/Dropdown/Dropdown';
import Checkbox from '../../../Common/Components/Checkbox';
import ToggleButton from '../../../Common/Components/ToggleButton';
import {
  getCompanyListAction,
  getCompanyAssessmentListAction,
  changeAssessmentNeedsAction,
  getDefaultRequirementsAction,
  updateDefaultRequirementsAction
} from '../../Api/actions';
import styles from './CameraControl.module.css';

const CameraControl = () => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [assessments, setAssessments] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [assessmentsLoading, setAssesmentsLoading] = useState(false);
  const [companyDefaults, setCompanyDefaults] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCompanies();
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      fetchCompanyAssessmentList(selectedCompany.id);
      fetchCompanyDefaults(selectedCompany.id);
    } else {
      setAssessments([]);
    }
  }, [selectedCompany, isActive]);

  const fetchCompanies = async () => {
    const resp = await getCompanyListAction();
    if (resp.data) {
      const companies = resp.data.map(company => {
        return {
          id: company.id,
          name: company.name
        };
      });
      setCompanies(companies);
    }
  };

  const fetchCompanyDefaults = async companyId => {
    setLoading(true);
    setCompanyDefaults(null);
    await getDefaultRequirementsAction({ companyId })
      .then(res => {
        if (res.data) {
          setCompanyDefaults(res.data);
          console.log(res.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchCompanyAssessmentList = async companyId => {
    setAssesmentsLoading(true);
    const resp = await getCompanyAssessmentListAction({ companyId, isActive });
    if (resp.data) {
      const positions = resp.data.map(position => {
        return {
          id: position.id,
          name: position.name,
          isCameraNeeded: position.isCameraNeeded,
          isOtpNeeded: position.isOtpNeeded
        };
      });
      setAssessments(positions);
    }
    setAssesmentsLoading(false);
  };

  const handleDefaultToggle = async (toggleValue, type, companyId, setLoading) => {
    setLoading(true);

    if (type === 'Camera') {
      await updateDefaultRequirementsAction({
        companyId,
        isCameraNeeded: toggleValue
      })
        .then(res => {
          console.log(res.data);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (type === 'OTP') {
      await updateDefaultRequirementsAction({
        companyId,
        isOtpNeeded: toggleValue
      })
        .then(res => {
          console.log(res.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    setLoading(false);
  };

  const handleToggle = async (toggleValue, type, companyAssessmentId, setLoading) => {
    setLoading(true);

    if (type === 'Camera') {
      await changeAssessmentNeedsAction({
        companyAssessmentId,
        isCameraNeeded: toggleValue
      })
        .then(res => {
          if (res.data) {
            setAssessments(
              assessments.map(assessment => {
                if (assessment.id === companyAssessmentId) {
                  return {
                    ...assessment,
                    isCameraNeeded: toggleValue
                  };
                }
                return assessment;
              })
            );
          }
        })
        .catch(err => {
          alert('Failed to update assessment needs', err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (type === 'OTP') {
      await changeAssessmentNeedsAction({
        companyAssessmentId,
        isOtpNeeded: toggleValue
      })
        .then(res => {
          if (res.data) {
            setAssessments(
              assessments.map(assessment => {
                if (assessment.id === companyAssessmentId) {
                  return {
                    ...assessment,
                    isOtpNeeded: toggleValue
                  };
                }
                return assessment;
              })
            );
          }
        })
        .catch(err => {
          alert('Failed to update assessment needs', err);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    setLoading(false);
  };

  return (
    <PageContainer>
      <div className={styles.container}>
        <div className={styles.upperRow}>
          <Dropdown
            title="Company"
            placeholder="Select Company"
            searchBarPlaceholder="Search Company"
            list={companies}
            openItemCount={7}
            selectedOption={setSelectedCompany}
            setSelectedOption={_ => setSelectedCompany(_)}
            isSearchable
            height="5rem"
            wrapperStyle={{ width: '300px' }}
          />
          <div className={styles.activeSelection}>
            <label>Active Assessments: </label>
            <Checkbox
              myChecked={isActive}
              size={20}
              myOnClick={_ => {
                setIsActive(_.target.checked);
              }}
            />
          </div>
        </div>
        {selectedCompany && !loading && (
          <div className={styles.midRow}>
            <span className={styles.companyName}>{selectedCompany.name}</span>
            <div className={styles.options}>
              <div className={styles.option}>
                <span>Camera Active: </span>
                <ToggleButton
                  onChange={(isChecked, setLoading) =>
                    handleDefaultToggle(isChecked, 'Camera', selectedCompany.id, setLoading)
                  }
                  checked={companyDefaults?.isCameraNeeded}
                  loading={loading}
                />
              </div>

              <div className={styles.option}>
                <span>OTP Active: </span>
                <ToggleButton
                  onChange={(isChecked, setLoading) =>
                    handleDefaultToggle(isChecked, 'OTP', selectedCompany.id, setLoading)
                  }
                  checked={companyDefaults?.isOtpNeeded}
                  loading={loading}
                />
              </div>
            </div>
          </div>
        )}
        <div className={styles.lowerRow}>
          <div className={styles.companyList}>
            {assessmentsLoading ? (
              <span className="fw-400 font-18" style={{ paddingTop: '20px' }}>
                Loading...
              </span>
            ) : (
              assessments.map(assessment => (
                <div key={assessment.companyId} className={styles.companyListItem}>
                  <div className={styles.companyName}>{assessment.name}</div>
                  <div className={styles.options}>
                    <div className={styles.option}>
                      <span>Camera Active: </span>
                      <ToggleButton
                        onChange={(isChecked, setLoading) =>
                          handleToggle(isChecked, 'Camera', assessment.id, setLoading)
                        }
                        checked={assessment.isCameraNeeded}
                      />
                    </div>
                    <div className={styles.option}>
                      <span>OTP Active: </span>
                      <ToggleButton
                        onChange={(isChecked, setLoading) =>
                          handleToggle(isChecked, 'OTP', assessment.id, setLoading)
                        }
                        checked={assessment.isOtpNeeded}
                      />
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default CameraControl;
