import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import generatePDF, { Margin } from 'react-to-pdf';

import { useTranslation, Trans } from 'react-i18next';

import CustomButton from '../../../../Common/Components/CustomButton';
import {
  candidateProfileSelector,
  candidateSolutionsSelector,
  loadingForSolutionsSelector,
  priceForUnlockSelector,
  selectedAssessmentForReportSelector,
  solutionAuthorizeSelector
} from '../../../../redux/candidateDetail/selectors';
import styles from './AssessmentReport.module.css';
import QuestionBar from './QuestionBar';
import QuestionReport from './QuestionReport';
import lockedIcon from './lockedIcon.svg';
import unlockedIcon from './unlockedIcon.svg';
import { currentPlanRedux } from '../../../../redux/plan/selectors';
import { getParam } from '../../../../utils/helpers';
import HeadsUpModal from '../../../../Auth/Modals/HeadsUpModal';
import FreeTrialModal from '../../../../Auth/Modals/FreeTrialModal/FreeTrialModal';
import PurchaseAddOnSuccessModal from '../../../../Auth/Modals/PurchaseAddOnSuccess/PurchaseAddOnSuccessModal';
import ContactSalesSuccess from '../../../../Auth/Modals/UnpaidContactSales/ContactSalesSuccess';
import { unlockCandidateReportAction } from '../../../../redux/plan/actions';
import { setToBePurchasedAddOn } from '../../../../redux/plan/slice';
import Skeleton from '../../../../Common/Components/Skeleton';
import AssessmentSummary from './AssessmentSummary';
import ReportPdfPage from '../../../Pages/ReportPdf/ReportPdfPage';
import InfoBanner from '../../../../Common/Components/InfoBanner';
import { getCameraShotsAction } from '../../../Api/action';
import { ReactComponent as Download } from './downloadReport.svg';
import { ReactComponent as Camera } from './cameraIcon.svg';
import { userSelector } from '../../../../redux/auth/selectors';
import { mixpanelReturnUserRelatedProperties } from '../../../../utils/mixpanelHelpers';
import mixpanel from '../../../../utils/mixpanel';
import useCameraShotHandler from '../../../Utils/useCameraShotHandler';
import usePublicReportButtonHandler from '../../../Utils/usePublicReportButtonHandler';

const AssessmentReport = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const solutionList = useSelector(candidateSolutionsSelector);
  const isAuthorizedFlag = useSelector(solutionAuthorizeSelector);
  const priceForUnlock = useSelector(priceForUnlockSelector);
  const loading = useSelector(loadingForSolutionsSelector);
  const myPlan = useSelector(currentPlanRedux);

  const [contactSalesSuccessShow, setContactSalesSuccessShow] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [purchasedSuccess, setPurchasedSuccess] = useState(false);
  const [continuePurchase, setContinuePurchase] = useState(false);

  const { t } = useTranslation(['candidateDetail']);

  // TODO: new endpoint with userId
  async function unlockFree() {
    const data = {
      userId: id,
      companyAssessmentId: getParam(location.search, 'currentAssessmentId')
    };

    const a = await dispatch(unlockCandidateReportAction(data));
    if (a?.meta?.requestStatus === 'fulfilled') {
      await setPurchasedSuccess(true);
    } else {
      setContinuePurchase(true);
    }
  }

  const unlockMethod = () => {
    if (myPlan?.name === 'Free Trial') {
      unlockFree();
    } else {
      setPaymentModal(true);
    }
  };

  // TODO: purchaseAddOnAction with userId
  useEffect(() => {
    let data = {};
    if (myPlan?.name !== 'Free Trial') {
      data = {
        bundleItemCode: 30,
        userId: id,
        companyAssessmentId: getParam(location.search, 'currentAssessmentId')
      };
    } else {
      data = {
        userId: id,
        companyAssessmentId: getParam(location.search, 'currentAssessmentId')
      };
    }
    dispatch(setToBePurchasedAddOn(data));
  }, []);

  const targetRef = useRef();
  const [pdfLoading, setPdfLoading] = useState(false);
  const profileRedux = useSelector(candidateProfileSelector);

  const MyDoc = (
    <div style={{ display: pdfLoading ? 'initial' : 'none', opacity: '0' }}>
      <ReportPdfPage target={targetRef} />
    </div>
  );

  const savePdf = async () => {
    setPdfLoading(true);
  };

  useEffect(() => {
    if (pdfLoading) {
      setTimeout(() => {
        generatePDF(targetRef, {
          filename: `${profileRedux?.fullName} - Coensio Report`,
          page: { margin: Margin.SMALL }
        });
      }, 1000);
    }
  }, [pdfLoading]);

  const reduxUserDetail = useSelector(userSelector);
  const downloadButtonClick = () => {
    savePdf();
    const userData = mixpanelReturnUserRelatedProperties(reduxUserDetail);
    mixpanel.track('Download Report Click', {
      ...userData
    });
  };

  const selectedAssessmentRedux = useSelector(selectedAssessmentForReportSelector);

  const downloadDisabled =
    Object.keys(profileRedux).length === 0 || !selectedAssessmentRedux || !solutionList;

  const { ShowButton, GalleryModal } = useCameraShotHandler();

  const { PublicReportButton } = usePublicReportButtonHandler();

  return (
    <>
      {GalleryModal}
      <div className={styles.Wrapper}>
        <AssessmentSummary />
        <div className={styles.ReportWrapper}>
          <div className="FlexRow spaceBetween verticallyCenter">
            <text className={styles.ReportText}>{t('report')}</text>
            <div className="FlexRow verticallyCenter gap-12">
              <ShowButton />
              <CustomButton
                Icon={Download}
                type={5}
                textField={t('download')}
                size="small"
                buttonOnClick={downloadButtonClick}
                isDisabled={downloadDisabled}
                loading={pdfLoading}
              />
              <PublicReportButton />
            </div>
          </div>
          {solutionList?.length !== 0 ? (
            <div className={styles.QuestionWithHeader}>
              {loading ? (
                <Skeleton type="candidateDetail-assessmentReport" />
              ) : (
                <>
                  <QuestionBar />
                  {!isAuthorizedFlag ? (
                    <div className={styles.lockPosition}>
                      <LockIcon onClickBehavior={unlockMethod} />
                    </div>
                  ) : null}

                  <div
                    className={`${styles.QuestionListWrapper} ${
                      !isAuthorizedFlag ? styles.blurredWrapper : ''
                    }`}
                  >
                    {solutionList?.map((e, i) => (
                      <QuestionReport qInfo={e} index={i} />
                    ))}
                  </div>
                </>
              )}
            </div>
          ) : (
            <text className={styles.NotScoredText}>{t('noResults')}</text>
          )}
        </div>

        <>
          <HeadsUpModal
            addOnCode={30}
            show={paymentModal}
            onHide={() => setPaymentModal(false)}
            title={myPlan?.isCustom ? t('hello') : t('wantToUnlock')}
            description={
              !myPlan?.isCustom ? (
                <Trans
                  i18nKey="unlockReportPrice"
                  ns="candidateDetail"
                  values={{ priceForUnlock: priceForUnlock }}
                >
                  0<span>1</span>
                  <span>2</span>
                  <span>3</span>
                </Trans>
              ) : (
                t('seeCandAnsw')
              )
            }
            isCustom={myPlan?.isCustom}
            setSuccess={myPlan?.isCustom ? setContactSalesSuccessShow : setPurchasedSuccess}
          />

          <FreeTrialModal
            show={continuePurchase}
            onHide={() => {
              window.dispatchEvent(new Event('locationchange'));
              setContinuePurchase(false);
            }}
            addOnCode={30}
          />
          {/* Purchase AddOn Success */}
          <PurchaseAddOnSuccessModal
            show={purchasedSuccess}
            onHide={() => {
              window.dispatchEvent(new Event('locationchange'));
              setPurchasedSuccess(false);
            }}
            addOnCode={30}
          />
          <ContactSalesSuccess
            icon="tick"
            show={contactSalesSuccessShow}
            onHide={() => setContactSalesSuccessShow(false)}
            title={t('requestReceived')}
            description={t('expertsContact')}
          />
        </>
      </div>
      {MyDoc}
      <InfoBanner
        show={pdfLoading}
        text={t('downloadBeginBanner')}
        setStatus={() => setPdfLoading(false)}
      />
      {/* {CustomBlur} */}
    </>
  );
};
export default AssessmentReport;

const LockIcon = ({ onClickBehavior = () => {} }) => {
  const [hoverState, setHoverState] = useState(true);
  const { t } = useTranslation(['candidateDetail']);

  return (
    <div
      className={styles.LockIconWrapper}
      onMouseOver={() => setHoverState(false)}
      onMouseOut={() => setHoverState(true)}
    >
      <img src={hoverState ? lockedIcon : unlockedIcon} alt="lockIcon" />
      <CustomButton
        type={1}
        size="medium"
        buttonOnClick={onClickBehavior}
        textField={t('clickToUnlock')}
      />
    </div>
  );
};
