import React from 'react';
import styles from './Dropdown.module.css';

export default function DropdownItem({ item, height, onSelect = () => {}, selected, first, last }) {
  return (
    <button
      style={{ height: height }}
      type="button"
      className={`${styles.listItem} ${selected && styles.selectedItem} ${
        first && styles.firstItem
      } ${last && styles.lastItem}`}
      onClick={onSelect}
    >
      {item.name}
    </button>
  );
}
