import React, { useEffect, useLayoutEffect, useState } from 'react';

import styles from '../Styles/CircularProgressBar.module.css';
import MultipleCircularProgressBar from '../../Candidate/Components/MultipleCircularProgressBar';

function CircularProgressBar({ score, avg }) {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const temp = document.getElementById('progress')?.getBoundingClientRect().height;
    setHeight(temp);
  }, []);

  function useWindowSize() {
    // Use this hook to update the bounding box in the parent!
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  const [parentWidth, parentHeight] = useWindowSize();
  const [boundingBox, setBox] = useState({});

  useEffect(() => {
    setBox(document.getElementById('progressCircular')?.getBoundingClientRect());
  }, [parentWidth]);

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: "100%",
        height,
        // minWidth: "117px"
      }}
      id="progressCircular"
    >
      <div
        className={`${styles.CircularWrapper}`}
        style={{ position: 'absolute' }}
      >
        {/* <CircularProgressbar value={score} text={`${score}%`} strokeWidth={18} /> */}
        <MultipleCircularProgressBar score={score} avg={avg} />
      </div>
    </div>
  );
}

export default CircularProgressBar;
