import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';
import {
  getAssessmentInfoAction
} from './actions';

const assessmentsSlice = createSlice({
  name: 'assessmentDetail',
  initialState,
  reducers: {

    setInviteCandidatesModalShow: (state, action) => {
      state.inviteCandidatesModalShow = action.payload;
    },

    setCopiedState: (state, action) => {
      state.copiedState = action.payload;
    },
    
    setSettingsSuccess: (state, action) => {
      state.settingsSuccess = action.payload;
    },
    setSettingsError: (state, action) => {
      state.settingsError = action.payload;
    },
    setSettingsBannerMessage: (state, action) => {
      state.settingsBannerMessage = action.payload;
    }
  },
  extraReducers: builder => {

    builder.addCase(getAssessmentInfoAction.fulfilled, (state, action) => {
      state.assessmentSettingInfo = action.payload.data;
      state.assessmentSettingLoading = false;
    });
    builder.addCase(getAssessmentInfoAction.pending, state => {
      state.assessmentSettingLoading = true;
    });
  }
});

export const {
  setInviteCandidatesModalShow,
  setSettingsSuccess,
  setSettingsError,
  setSettingsBannerMessage,
  setCopiedState
} = assessmentsSlice.actions;

export default assessmentsSlice.reducer;
