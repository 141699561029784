import {
  applyUserAutoApplyService,
  deactivateUserAutoApplyService,
  getUserAutoApplyService,
  saveCameraShotService,
  sendVerificationCodeService,
  updatePhoneNumberService,
  validateVerificationCodeService,
  sendEmailVerificationCodeService
} from './service';

export const getUserAutoApplyAction = async data => {
  try {
    return await getUserAutoApplyService(data);
  } catch (error) {
    return null;
  }
};

export const applyUserAutoApplyAction = async data => {
  try {
    return await applyUserAutoApplyService(data);
  } catch (error) {
    return null;
  }
};

export const deactivateUserAutoApplyAction = async data => {
  try {
    return await deactivateUserAutoApplyService(data);
  } catch (error) {
    return null;
  }
};

export const saveCameraShotAction = async data => {
  try {
    return await saveCameraShotService(data);
  } catch (error) {
    return null;
  }
};

export const sendVerificationCodeAction = async data => {
  try {
    return await sendVerificationCodeService(data);
  } catch (error) {
    return error;
  }
};

export const updatePhoneNumberAction = async data => {
  try {
    return await updatePhoneNumberService(data);
  } catch (error) {
    return error;
  }
};

export const validateVerificationCodeAction = async data => {
  try {
    return await validateVerificationCodeService(data);
  } catch (error) {
    return error;
  }
};

export const sendEmailVerificationCodeAction = async data => {
  try {
    return await sendEmailVerificationCodeService(data);
  } catch (error) {
    return error;
  }
};
