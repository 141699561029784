import { createAsyncThunk } from '@reduxjs/toolkit';

import { addUserToCompanyService, getUserGroupTypeListService, resendInvitationService } from './service';

// eski api - taşındı
// export const getUserList = createAsyncThunk('users/list', async (data, getThunkAPI) => {
//   try {
//     return await getUserService(data);
//   } catch (e) {
//     return getThunkAPI.rejectWithValue(e);
//   }
// });

// export const deleteUser = createAsyncThunk('users/delete', async (data, thunkAPI) => {
//   try {
//     return await deleteUserService(data);
//   } catch (e) {
//     return thunkAPI.rejectWithValue(e);
//   }
// });

// export const createUser = createAsyncThunk('users/create', async (data, thunkAPI) => {
//   try {
//     return await createUserService(data);
//   } catch (e) {
//     return thunkAPI.rejectWithValue(e);
//   }
// });

// export const updateUser = createAsyncThunk('users/update', async (data, thunkAPI) => {
//   try {
//     return await updateUserService(data);
//   } catch (e) {
//     return thunkAPI.rejectWithValue(e);
//   }
// });

// export const GetPackageListAction = createAsyncThunk('users/GetPackageListAction', async (data, thunkAPI) => {
//   try {
//     return await GetPackageListService(data);
//   } catch (e) {
//     return thunkAPI.rejectWithValue(e);
//   }
// });

// export const payAction = createAsyncThunk('users/payAction', async (data, thunkAPI) => {
//   try {
//     return await payService(data);
//   } catch (e) {
//     return thunkAPI.rejectWithValue(e);
//   }
// });

// export const cancelPayAction = createAsyncThunk('users/cancelPayAction', async (data, thunkAPI) => {
//   try {
//     return await cancelPayService(data);
//   } catch (e) {
//     return thunkAPI.rejectWithValue(e);
//   }
// });

// export const GetBillingPaymentMethodsAction = createAsyncThunk('users/GetBillingPaymentMethodsAction', async (data, thunkAPI) => {
//   try {
//     return await getBillingPaymentMethodsService(data);
//   } catch (e) {
//     return thunkAPI.rejectWithValue(e);
//   }
// });

// eski api - taşındı
// export const resendInvitationAction = createAsyncThunk('users/resendInvitationAction', async (data, thunkAPI) => {
//   try {
//     return await resendInvitationService(data);
//   } catch (e) {
//     return thunkAPI.rejectWithValue(e);
//   }
// });

// export const getCountryListAction = createAsyncThunk('users/GetCountryListAction', async (data, thunkAPI) => {
//   try {
//     return await getCountryListService(data);
//   } catch (e) {
//     return thunkAPI.rejectWithValue(e);
//   }
// });

// export const payUpdateAction = createAsyncThunk('users/payUpdateAction', async (data, thunkAPI) => {
//   try {
//     return await payUpdateService(data);
//   } catch (e) {
//     return thunkAPI.rejectWithValue(e);
//   }
// });


// New endpoint
// export const getUserListAction = createAsyncThunk('users/getList', async (data, getThunkAPI) => {
//   try {
//     return await getUserListService(data);
//   } catch (e) {
//     return getThunkAPI.rejectWithValue(e);
//   }
// });

export const addUserToCompanyAction = createAsyncThunk('users/addUserToCompany', async (data, thunkAPI) => {
  try {
    const response = await addUserToCompanyService(data);
    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const resendInvitationAction = createAsyncThunk('users/resendInvitation', async (data, thunkAPI) => {
  try {
    const response = await resendInvitationService(data);
    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
})

export const getUserGroupTypeListAction = createAsyncThunk('users/getGroupTypeList', async (data, getThunkAPI) => {
  try {
    const response = await getUserGroupTypeListService(data);
    return response.data;
  } catch (e) {
    return getThunkAPI.rejectWithValue(e);
  }
});