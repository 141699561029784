import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation, Trans } from 'react-i18next';

import styles from './Experience.module.css';
import { ReactComponent as ExperienceIcon } from './experienceIcon.svg';
import { candidateBackgroundSelector } from '../../../../redux/candidateDetail/selectors';
import CustomButton from '../../../../Common/Components/CustomButton';

const Description = ({ exp }) => {
  const [showMoreClicked, setShowMoreClicked] = useState(false);

  const { t } = useTranslation(['candidateDetail']);

  return (
    <span className={styles.descriptionWrapper}>
      {exp?.description?.length > 220 && !showMoreClicked
        ? exp?.description.substring(0, 220)
        : exp?.description}
      {!showMoreClicked && exp?.description?.length > 220 ? (
        <button type="button" className={styles.seeMore} onClick={() => setShowMoreClicked(true)}>
          {t('seeMoreExperience')}
        </button>
      ) : null}
    </span>
  );
};

const Experience = () => {
  const experience = useSelector(candidateBackgroundSelector);
  const [expNoAvailable, setExpNoAvailable] = useState(2);

  const { t } = useTranslation(['candidateDetail']);

  return (
    <>
      {experience?.experiences ? (
        <div className={styles.wrapper}>
          <span className={styles.header}>{t('experience')}</span>

          {experience?.experiences?.slice(0, expNoAvailable)?.map((exp, i) => (
            <div className={styles.rowWrapper}>
              <div className={styles.companyLogo}>
                {exp?.companyLogo && exp?.companyLogo !== 'Coensio' ? (
                  <img className={styles.logoImg} src={exp?.companyLogo} alt={`companyLogo-${i}`} />
                ) : (
                  <ExperienceIcon />
                )}
              </div>

              <div className={styles.textWrapper}>
                <div className={styles.companyRowWrapper}>
                  <span className={styles.subHeader}>{exp?.position}</span>
                  {exp?.sector ? <div className={styles.labelWrapper}>{exp?.sector}</div> : null}
                </div>
                <span className={styles.companyText}>{exp?.companyName}</span>
                <span className={styles.experienceDate}>{exp?.date}</span>
                <Description exp={exp} />
              </div>
            </div>
          ))}
          {experience?.experiences?.length > 2 &&
          expNoAvailable !== experience?.experiences?.length ? (
            <CustomButton
              type={5}
              textField={
                <span>
                  <Trans
                    i18nKey="showAllExp"
                    ns="candidateDetail"
                    values={{ experiencesCount: experience?.experiences?.length }}
                  >
                    0<span>1</span>
                  </Trans>
                </span>
              }
              customStyle={{ width: '100%' }}
              size="small"
              buttonOnClick={() => setExpNoAvailable(experience?.experiences?.length)}
            />
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default Experience;
