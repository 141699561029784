import React from 'react';
import styles from './AutoApplyComponent.module.css';
import { useWindowSize } from '../../../../utils/helpers';

import constants from '../../../../utils/constants';

const logo1 = `${process.env.REACT_APP_IMAGE_API}/AutoApplyCompanyLogos/arcelik2.png`;
const logo2 = `${process.env.REACT_APP_IMAGE_API}/AutoApplyCompanyLogos/akbank.png`;
const logo3 = `${process.env.REACT_APP_IMAGE_API}/AutoApplyCompanyLogos/dominos.png`;
const logo4 = `${process.env.REACT_APP_IMAGE_API}/AutoApplyCompanyLogos/telekom.png`;
const logo5 = `${process.env.REACT_APP_IMAGE_API}/AutoApplyCompanyLogos/yapıkredi.png`;
const logo6 = `${process.env.REACT_APP_IMAGE_API}/AutoApplyCompanyLogos/intertech.png`;
const logo7 = `${process.env.REACT_APP_IMAGE_API}/AutoApplyCompanyLogos/turkcell.webp`;

export const BlurredImageWrapper = () => {
  const [screenW] = useWindowSize();
  const md = screenW > constants.MOBILE_THRESHOLD;
  const sm = screenW > constants.MOBILE_LARGE_SUPPORT_THRESHOLD;
  const xs = screenW > constants.MOBILE_MID_SUPPORT_THRESHOLD;
  const xxs = screenW > constants.MOBILE_MIN_SUPPORT_THRESHOLD;

  return (
    <div
      className={`FlexRow spaceBetween w-100 verticallyCenter ${styles.imageWrapper}`}
      style={{
        maxHeight: '80px',
        minHeight: '50px'
      }}
    >
      <img src={logo3} alt="logo" className={styles.imageItem} />
      <img src={logo1} alt="logo" className={styles.imageItem} />
      <img src={logo2} alt="logo" className={styles.imageItem} />
      {xxs ? <img src={logo6} alt="logo" className={styles.imageItem} /> : null}
      {xs ? <img src={logo5} alt="logo" className={styles.imageItem} /> : null}
      {sm ? <img src={logo4} alt="logo" className={styles.imageItem} /> : null}
      {md ? <img src={logo7} alt="logo" className={styles.imageItem} /> : null}
    </div>
  );
};
