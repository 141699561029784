export default {
  filteredRootAssessmentList: [],
  currentUserDetail2: [],
  customAssessmentId: null,
  rootAssessmentId: null,
  assessmentActiveTab: 0,
  rootAssessmentQuestionList: null,
  codingLanguageList: null,
  publishedAssessment: false,
  assessmentToBeDeleted: null,
  questionBeingAdded: null,
  candidateQuestionList: null,
  filterState: {
    pageSize: 50,
    currentPage: 1,
    rootAssessmentId: '',
    skills: [],
    questionTypeIds: [],
    searchParameter: '',
    difficultyList: [],
    questionLanguages: [],
    purchaseTypes: [],
    isCustom: false
  },
  removeQuestionShow: false,
  questionBeingRemoved: null,

  questionAddedInSession: false,
  inviteUserShow: false,
  tryAssessmentQuestions: [],
  allUserList: [],
  invitedAllUserList: [],
  HMUserList: [],
  RecUserList: [],
  invitedHMList: [],
  invitedRecList: [],
  questionDetail: null,
  maxPageCount: 25,
  authorizedHMList: [],
  authorizedRecList: [],
  isLoading: false,
  userList: [],
  newlyInvitedIDList: [],
  newlyInvitedID: '',
  AtsList: [],
  connectionList: [],
  CompanyAssessmentId: null,
  authorizedUsers: '',
  pageIndex: 1,
  assessmentDetailAfterCreate: {},
  scoredEmailCount: 0,
  invitedCount: 0,
  ibeScoredRedirect: false,
  sentMailSuccess: false,
  mailRejected: false,

  selectedRootAssessmentQuestionList: [],

  // flag for CreateCustomAssessment endpoint
  continueToAssessmentCustomization: 'pending',

  // flag for updateCustomAssessmentAction endpoint
  updateCustomAssessmentCheck: 'pending',

  // flag for add assessment action
  continueToAssessmentDetail: null,

  // flag for remove question on recruiter view
  removeSkillBasedError: null,

  // most popular root assessment list
  mostPopularRootAssessmentList: [],
  selectedRootAssmFilters: {},

  // Assessment Added popup
  assessmentAddedBannerShow: null,
  assessmentAddedBannerText: null
};
