import React from 'react';
import styles from '../Styles/RadioButton.module.css';

function RadioButton({
  item,
  activate,
  myKey,
  isActive,
  itemStyle,
  textStyle,
  disableUnselect,
  setUnselectedItem = () => {},
  checkboxBoxStyle = {}
}) {
  const handleClick = () => {
    if (isActive && !disableUnselect) {
      setUnselectedItem('true');
      activate(null);
    } else {
      setUnselectedItem(null);
      activate(myKey);
    }
  };
  return (
    <div className={styles.wrapper} style={itemStyle} onClick={() => handleClick()}>
      <div className={`${styles.box} ${isActive && styles.selected}`} style={!isActive ? checkboxBoxStyle : {}} />
      <div key={myKey} className={styles.itemText} style={textStyle}>
        {item}
      </div>
    </div>
  );
}

export default RadioButton;
