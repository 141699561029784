import React, { useEffect, useState } from 'react';
import RadioButtonItem from './RadioButtonItem';
import styles from '../Styles/RadioButton.module.css';

function RadioButton({
  // to reset the radiobutton group selectedOption should be -1
  containerStyle,
  data,
  selectedOption,
  setSelectedOption = () => {},
  radioItemStyle,
  radioItemTextStyle,
  setUnselectedButtons = () => {},
  disableUnselect
}) {
  const [activeElement, setActiveElement] = useState();
  const [unselected, setUnselected] = useState(null);

  useEffect(() => {
    setSelectedOption(data[activeElement]);
  }, [activeElement]);

  useEffect(() => {
    if (selectedOption) {
      if (selectedOption === -1) {
        setActiveElement(-1);
      } else {
        setActiveElement(data.findIndex(x => x === selectedOption));
      }
    }
  }, [selectedOption]);

  useEffect(() => {
    setUnselectedButtons(unselected);
  }, [unselected]);

  return (
    <div className={styles['radio-button-container']} style={containerStyle}>
      {data.map((item, index) => (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div>
          <RadioButtonItem
            disableUnselect={disableUnselect}
            textStyle={radioItemTextStyle}
            itemStyle={(data?.length !== index + 1) ? radioItemStyle : null}
            item={item}
            activate={setActiveElement}
            isActive={activeElement === index}
            myKey={index}
            setUnselectedItem={setUnselected}
          />
        </div>
      ))}
    </div>
  );
}

export default RadioButton;
