import React from 'react';

const EditMenuIcon = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="30"
      viewBox="0 0 24 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_8463_21488)">
        <circle
          cx="12.0001"
          cy="21.087"
          r="1.82609"
          transform="rotate(180 12.0001 21.087)"
          fill="#393939"
        />
        <circle
          cx="12.0001"
          cy="15"
          r="1.82609"
          transform="rotate(180 12.0001 15)"
          fill="#393939"
        />
        <circle
          cx="12.0001"
          cy="8.91302"
          r="1.82609"
          transform="rotate(180 12.0001 8.91302)"
          fill="#393939"
        />
      </g>
      <defs>
        <clipPath id="clip0_8463_21488">
          <rect y="30" width="30" height="24" rx="12" transform="rotate(-90 0 30)" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EditMenuIcon;
