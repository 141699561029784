import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  applicantAssessmentDetailService,
  applicantAssessmentDetailWithEmailService,
  applyCandidateToAssessmentService,
  candidateContractCheckService,
  candidateContractConfirmService,
  candidateHasDataService,
  ChangeOrAddApplicantDetailService,
  getAssessmentSessionDetailService,
  getCandidateInfoFromEmailService,
  getCurrentQuestionService,
  getNextQuestionService,
  getScreeningQuestionsService,
  incrementTabSwitchService,
  runCodeTestPageService,
  saveScreeningAnswerService,
  startAssessmentSessionService,
  submitCodingQuestionAnswerService,
  submitFreeTextQuestionAnswerService,
  submitMultipleChoiceQuestionAnswerService
} from './service';

export const applicantAssessmentDetailWithEmailAction = createAsyncThunk(
  'applicantAssessmentDetailWithEmail',
  async (data, thunkAPI) => {
    try {
      const response = await applicantAssessmentDetailWithEmailService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const applicantAssessmentDetailAction = createAsyncThunk(
  'applicantAssessmentDetail',
  async (data, thunkAPI) => {
    try {
      const response = await applicantAssessmentDetailService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const candidateHasDataAction = createAsyncThunk(
  'candidateHasAction',
  async (data, thunkAPI) => {
    try {
      const response = await candidateHasDataService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const candidateContractCheckAction = createAsyncThunk(
  'candidateContractCheck',
  async (data, thunkAPI) => {
    try {
      const response = await candidateContractCheckService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const candidateContractConfirmAction = createAsyncThunk(
  'candidateContractConfirm',
  async (data, thunkAPI) => {
    try {
      const response = await candidateContractConfirmService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const startAssessmentSessionAction = createAsyncThunk(
  'startAssessmentSession',
  async (data, thunkAPI) => {
    try {
      const response = await startAssessmentSessionService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getNextQuestionAction = createAsyncThunk('getNextQuestion', async (data, thunkAPI) => {
  try {
    const response = await getNextQuestionService(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getCurrentQuestionAction = createAsyncThunk(
  'getCurrentQuestion',
  async (data, thunkAPI) => {
    try {
      const response = await getCurrentQuestionService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const submitMultipleChoiceQuestionAnswerAction = createAsyncThunk(
  'submitMultipleChoiceQuestionAnswer',
  async (data, thunkAPI) => {
    try {
      const response = await submitMultipleChoiceQuestionAnswerService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const saveCurrentMultipleChoiceAnswerAction = createAsyncThunk(
  'saveCurrentMultipleChoiceAnswer',
  async (data, thunkAPI) => {
    try {
      const response = await submitMultipleChoiceQuestionAnswerService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const submitFreeTextQuestionAnswerAction = createAsyncThunk(
  'submitFreeTextQuestionAnswer',
  async (data, thunkAPI) => {
    try {
      const response = await submitFreeTextQuestionAnswerService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const saveCurrentFreeTextAnswerAction = createAsyncThunk(
  'saveCurrentFreeTextAnswer',
  async (data, thunkAPI) => {
    try {
      const response = await submitFreeTextQuestionAnswerService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const submitCodingQuestionAnswerAction = createAsyncThunk(
  'submitCodingQuestionAnswer',
  async (data, thunkAPI) => {
    try {
      const response = await submitCodingQuestionAnswerService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const saveCurrentCodingAnswerAction = createAsyncThunk(
  'saveCurrentCodingAnswer',
  async (data, thunkAPI) => {
    try {
      const response = await submitCodingQuestionAnswerService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const runCodeTestPageAction = createAsyncThunk('runCodeTestPage', async (data, thunkAPI) => {
  try {
    const response = await runCodeTestPageService(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const ChangeOrAddApplicantDetailAction = createAsyncThunk(
  'ChangeOrAddApplicantDetail',
  async (data, thunkAPI) => {
    try {
      const response = await ChangeOrAddApplicantDetailService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const incrementTabSwitchAction = createAsyncThunk(
  'incrementTabSwitch',
  async (data, thunkAPI) => {
    try {
      const response = await incrementTabSwitchService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const applyCandidateToAssessmentAction = createAsyncThunk(
  'applyCandidateToAssessment',
  async (data, thunkAPI) => {
    try {
      const response = await applyCandidateToAssessmentService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAssessmentSessionDetailAction = createAsyncThunk(
  'getAssessmentSessionDetail',
  async (data, thunkAPI) => {
    try {
      const response = await getAssessmentSessionDetailService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCandidateInfoFromEmailAction = createAsyncThunk(
  'getCandidateInfoFromEmail',
  async (data, thunkAPI) => {
    try {
      const response = await getCandidateInfoFromEmailService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getScreeningQuestionsAction = createAsyncThunk(
  'getScreeningQuestions',
  async (data, thunkAPI) => {
    try {
      const response = await getScreeningQuestionsService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const saveScreeningAnswerAction = createAsyncThunk(
  'saveScreeningAnswer',
  async (data, thunkAPI) => {
    try {
      const response = await saveScreeningAnswerService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
