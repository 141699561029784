import React, { useState } from 'react';
import styles from './ToggleButton.module.css';

const ToggleButton = ({ onChange, checked, disabled }) => {
  const [isChecked, setChecked] = useState(checked || false);
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    if (!disabled) {
      setChecked(!isChecked);
      // eslint-disable-next-line no-unused-expressions
      onChange && onChange(!isChecked, setLoading);
    }
  };

  return (
    <label
      className={`${styles.switch} ${isChecked ? styles.checked : ''} ${
        disabled ? styles.disabled : ''
      } ${loading ? styles.loading : ''}`}
    >
      <input
        type="checkbox"
        checked={isChecked}
        onChange={() => {}}
        onClick={handleClick}
        disabled={disabled || loading}
        className={styles.input}
      />
      <div className={`${styles.slider} ${loading ? styles.loading : ''}`}>
        <div className={`${styles.indicator} ${loading ? styles.loading : ''}`} />
      </div>
    </label>
  );
};

export default ToggleButton;
