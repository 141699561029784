import React from 'react';

const SettingsCategoryTitle = ({ title, icon, ...props }) => {
  return (
    <div className="FlexRow verticallyCenter gap-18" style={props.style}>
      {icon}
      <span className="fw-400 font-20 noSelect c-darkGray-05">{title}</span>
    </div>
  );
};

export default SettingsCategoryTitle;
