import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import CustomButton from '../../../../Common/Components/CustomButton';
import { createNoteAction } from '../../../../redux/candidateDetail/actions';
import { getParam } from '../../../../utils/helpers';
import styles from './Notes.module.css';

const NoteInput = () => {
  const { id } = useParams();
  const [active, setActive] = useState(false);
  const [note, setNote] = useState({ note: '' });
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const ref = useRef();
  const buttonRef = useRef();
  const button2Ref = useRef();

  const { t } = useTranslation(['candidateDetail'])

  function handleOutsideClick(event) {
    if (
      buttonRef &&
      ref &&
      !buttonRef?.current?.contains(event?.target) &&
      !ref?.current?.contains(event?.target)
    ) {
      setActive(false);
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [active]);

  async function createNote() {
    await setLoading(true);
    const noteData = {
      userId: id,
      note: note.note,
      CompanyAssessmentId: getParam(location.search, 'currentAssessmentId'),
    };

    await dispatch(createNoteAction(noteData));
    await setLoading(false);
    await setActive(false);
  }

  function cancelWriting() {
    setNote('');
    document.getElementById('addNote').value = '';
    setActive(false);
  }

  useEffect(() => {
    if (active === false) {
      setNote('');
      document.getElementById('addNote').value = '';
    }
  }, [active]);

  return (
    <div className={active ? styles.boxActive : styles.box}>
      <textarea
        id="addNote"
        ref={ref}
        className={styles.myTextArea}
        style={!active ? { height: 34 } : { height: 56 }}
        placeholder={t('addNote')}
        onClick={() => setActive(true)}
        onChange={e => {
          setNote({
            ...note,
            note: e.target.value
          });
        }}
      />
        <div 
          className={styles.buttonWrapper}
          style={{ 
            paddingRight: active ? '0px' :'12px',
            visibility: active ? 'visible' : 'hidden',
            opacity: active ? '1' : '0',
            pointerEvents: active ? 'inherit' : 'none',
            maxHeight: active ? '36px' : '0px',
            transition: 'visibility 0.7s, opacity 0.3s, max-height 0.7s, padding-right 0.7s'
          }}
        >
          <CustomButton
            buttonRef={button2Ref}
            customStyle={{ width: 83 }}
            type={2}
            textField={t('cancel')}
            size="small"
            buttonOnClick={() => cancelWriting()}
          />
          <CustomButton
            buttonRef={buttonRef}
            customStyle={{ width: 83 }}
            type={1}
            textField={t('save')}
            size="small"
            buttonOnClick={() => createNote()}
            loading={loading}
            isDisabled={note.note === ''}
          />
        </div>
    </div>
  );
};
export default NoteInput;
