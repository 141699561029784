import React from 'react';
import ReportHeader from '../../Components/ReportHeader/ReportHeader';

import ReportCandidateInfo from '../../Components/ReportCandidateInfo/ReportCandidateInfo';
import ReportAssessmentDetail from '../../Components/ReportAssessmentDetail/ReportAssessmentDetail';
import ReportAssessmentSummary from '../../Components/ReportAssessmentSummary/ReportAssessmentSummary';
import ReportFooter from '../../Components/ReportFooter/ReportFooter';

export default function ReportPdfPage({ target }) {
  return (
    <div
      ref={target}
      id="#candidateReport"
      style={{
        width: '100%',
        maxWidth: '848px',
        minWidth: '848px',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '0px 36px',
        minHeight: '100dvh',
        backgroundColor: '#ffffff'
      }}
    >
      <ReportHeader />
      <div className="mt-12">
        <ReportCandidateInfo />
      </div>
      <div className="mt-28">
        <ReportAssessmentDetail />
      </div>
      <div className="mt-32">
        <ReportAssessmentSummary />
      </div>
      <div className="mt-48">
        <ReportFooter />
      </div>
    </div>
  );
}
