import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './BillingRow.module.css';
import config from '../../../../constant/configVol2';
import { invoiceListRedux } from '../../../../redux/plan/selectors';
import { useWindowSize } from '../../../../utils/helpers';
import download from '../../../Images/downloadIcon.svg';

function BillingRow({ index, invoiceNo, date, amount, currency }) {
  const dispatch = useDispatch();
  const invoices = useSelector(invoiceListRedux);
  const [screenW] = useWindowSize();

  async function downloadInvoice() {
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${localStorage.getItem('authTokenVol2')}`);
    myHeaders.append('Content-Type', 'application/pdf');
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    fetch(
      `${config.BASE_URL}/api/companyBundle/downloadInvoice?InvoiceNo=${invoiceNo}`,
      requestOptions
    )
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${invoiceNo || 'invoice_coensio'}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  }

  return (
    <div
      className={styles.row}
      style={{
        borderBottom:
          index !== invoices?.invoiceList?.length - 1 && '1px solid rgba(57, 57, 57, 0.2)'
      }}
    >
      <div className={styles.billingDate} style={{ gridColumn: 1, gridRow: 1, gridColumnEnd: 1 }}>
        {screenW > 1020 ? `#${invoiceNo}` : `#${invoiceNo?.substring(0, 3)}`}
      </div>
      <div className={styles.billingDate} style={{ gridColumn: 2, gridRow: 1, gridColumnEnd: 2 }}>
        {date}
      </div>
      <div className={styles.billingDate} style={{ gridColumn: 3, gridRow: 1, gridColumnEnd: 3 }}>
        {currency !== 10 ? <span>$</span> : <span>&#8378;</span>}
        <span>{amount}</span>
      </div>
      <div
        style={{
          gridColumn: 4,
          gridRow: 1,
          gridColumnEnd: 4,
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <div
          className={styles.downloadButton}
          onClick={async () => {
            downloadInvoice();
          }}
        >
          {screenW > 576 ? 'Download' : <img src={download} alt="" />}
          
        </div>
      </div>
    </div>
  );
}

export default BillingRow;
