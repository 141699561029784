import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  outputVariableRedux,
  parametersRedux,
  testCasesRedux,
  validCasesRedux,
  verificationSelectedLangRedux
} from '../../../redux/addQuestion/selectors';
import { setIsPassedVerificationRedux, setTestCasesRedux, setValidCasesRedux, setVerificationCodeRedux, setVerificationCodeResponseRedux } from '../../../redux/addQuestion/slice';
import { initialCodeGeneration } from '../Constants/codingLanguageConstants';

import styles from '../Styles/Coding.module.css';

function TestCaseElement({ id, removeElement, setChange, group }) {
  const dispatch = useDispatch();
  const reduxParameters = useSelector(parametersRedux);
  const reduxOutputVariable = useSelector(outputVariableRedux);
  const reduxTestCases = useSelector(testCasesRedux);
  const reduxValidCases = useSelector(validCasesRedux);
  const reduxSelectedLanguage = useSelector(verificationSelectedLangRedux);

  const [testCaseVis, setTestCaseVis] = useState(false);

  function handleHideTestCase() {
    setTestCaseVis(!testCaseVis);
  }

  const onChangeResetVerificationCode = () => {
    dispatch(setIsPassedVerificationRedux(false));
    const init = initialCodeGeneration(reduxOutputVariable, reduxParameters);
    dispatch(setVerificationCodeRedux(init[reduxSelectedLanguage.value]));
    dispatch(setVerificationCodeResponseRedux(""));
  }

  const handleChange = (type, variable, index) => {
    onChangeResetVerificationCode();
    const testTemp = [];
    reduxTestCases.forEach((e, i) => {
      testTemp.push({});
      // testTemp[i].name = e.name;
      testTemp[i].input = e.input;
      testTemp[i].result = e.result;
      testTemp[i].weight = e.weight;
    });
    const validTemp = [];
    reduxValidCases.forEach((e, i) => {
      validTemp.push({});
      // validTemp[i].name = e.name;
      validTemp[i].input = e.input;
      validTemp[i].result = e.result;
      validTemp[i].weight = e.weight;
    });

    switch (type) {
      // case 'name':
      //   if (group === 'test') {
      //     testTemp[index].name = variable.target.value;
      //     dispatch(setTestCasesRedux(testTemp));
      //   } else if (group === 'valid') {
      //     validTemp[index].name = variable.target.value;
      //     dispatch(setValidCasesRedux(validTemp));
      //   }
      //   break;

      case 'input':
        if (group === 'test') {
          testTemp[index].input = variable;
          dispatch(setTestCasesRedux(testTemp));
        } else if (group === 'valid') {
          validTemp[index].input = variable;
          dispatch(setValidCasesRedux(validTemp));
        }
        break;

      case 'result':
        if (group === 'test') {
          testTemp[index].result = variable.target.value;
          dispatch(setTestCasesRedux(testTemp));
        } else if (group === 'valid') {
          validTemp[index].result = variable.target.value;
          dispatch(setValidCasesRedux(validTemp));
        }
        break;

      case 'weight':
        if (group === 'test') {
          testTemp[index].weight = variable.target.value;
          dispatch(setTestCasesRedux(testTemp));
        } else if (group === 'valid') {
          validTemp[index].weight = variable.target.value;
          dispatch(setValidCasesRedux(validTemp));
        }
        break;
      default:
        break;
    }
  };

  const fillInputArray = (value, index) => {
    if (group === 'test') {
      const temp = reduxTestCases[id].input.slice();
      temp[index] = value.target.value;
      handleChange('input', temp, id);
    } else if (group === 'valid') {
      const temp = reduxValidCases[id].input.slice();
      temp[index] = value.target.value;
      handleChange('input', temp, id);
    }
  };

  const testCaseHeaderFunctionSignature = (params, output) => {
    let temp = "(";
    params.forEach((e,i) => {
      if (!e.startsWith('[') && !e.endsWith(']') ) {
        if (reduxParameters[i].type.includes("Array")){
          temp = temp.concat('[');
          temp = temp.concat(e);
          temp = temp.concat(']');
        } else {
          temp = temp.concat(e);
        }
      } else {
        temp = temp.concat(e);
      }
      if (params.length - 1 !== i) {
        temp = temp.concat(', ')
      }
    })
    temp = temp.concat(') = ')
    if (!output.startsWith('[') && !output.endsWith(']')) {
      if (reduxOutputVariable.type.includes("Array")) {
        temp = temp.concat('[');
        temp = temp.concat(output);
        temp = temp.concat(']');
      } else {
        temp = temp.concat(output)
      }
    } else {
      temp = temp.concat(output)
    }
    return temp;
  }

  return (
    <div>
      <div className={styles.testCaseHeader}>
        <div className={styles.TestCaseCountWrapper}>Test {id + 1}</div>
        {group === 'test' ? (
          <div className={styles.TestCaseSignature}>
            {reduxOutputVariable.name ? reduxOutputVariable.name : "undefined"}{testCaseHeaderFunctionSignature(reduxTestCases[id].input, reduxTestCases[id].result)}
          </div>
        ) : (
          <div className={styles.TestCaseSignature}>
            {reduxOutputVariable.name ? reduxOutputVariable.name : "undefined"}{testCaseHeaderFunctionSignature(reduxValidCases[id].input, reduxValidCases[id].result)}
          </div>
        )}
        <div className={styles.ButtonWrapper}>
          
          {group === 'test' ? <div className={styles.WeightWrapper}>Weight: {reduxTestCases[id].weight}</div> : <div className={styles.WeightWrapper}>Weight: {reduxValidCases[id].weight}</div>}
          <button type="button" onClick={() => removeElement(id)}>
            X
          </button>
          <button type="button" onClick={handleHideTestCase}>
            ^
          </button>
        </div>
        
      </div>
      {testCaseVis && (
        <div className={styles.TestCaseBody}>
          {/* <div className={styles.IndividiualRow}>
            <div className={styles.TestCaseBodyInfoArea}>
              Enter a name for this case:
            </div>
            <input
              className={`${styles.TestCaseInputField}`}
              placeholder="Name"
              type="text"
              onChange={value => {
                handleChange('name', value, id);
                setChange(1);
              }}
              value={
                group === 'test'
                  ? reduxTestCases[id].name
                  : group === 'valid'
                  ? reduxValidCases[id].name
                  : null
              }
            />
          </div> */}
          
          {group === 'test'
            ? reduxTestCases[id].input.map((element, index) => (
              <div className={styles.IndividiualRow}>
                <div className={styles.TestCaseBodyInfoArea}>
                  {reduxParameters[index].name} ({reduxParameters[index].type})
                </div>
                <input
                  className={`${styles.TestCaseInputField}`}
                  placeholder="Inputs"
                  type="text"
                  onChange={value => {
                    fillInputArray(value, index);
                    setChange(1);
                  }}
                  value={element}
                />
              </div>
              ))
            : group === 'valid'
            ? reduxValidCases[id].input.map((element, index) => (
              <div className={styles.IndividiualRow}>
                <div className={styles.TestCaseBodyInfoArea}>
                  {reduxParameters[index].name} ({reduxParameters[index].type})
                </div>
                <input
                  className={`${styles.TestCaseInputField}`}
                  placeholder="Inputs"
                  type="text"
                  onChange={value => {
                    fillInputArray(value, index);
                    setChange(1);
                  }}
                  value={element}
                />
              </div>
              ))
            : null}
          <div className={styles.IndividiualRow}>
            <div className={styles.TestCaseBodyInfoArea}>
              Expected output: ({reduxOutputVariable.type})
            </div>
            <input
              className={`${styles.TestCaseInputField}`}
              placeholder="Result"
              type="text"
              onChange={value => {
                handleChange('result', value, id);
                setChange(1);
              }}
              value={
                group === 'test'
                  ? reduxTestCases[id].result
                  : group === 'valid'
                  ? reduxValidCases[id].result
                  : null
              }
            />
          </div>
          <div className={styles.IndividiualRow}>
            <div className={styles.TestCaseBodyInfoArea}>
              Weight:
            </div>
            <input
              className={`${styles.TestCaseInputField}`}
              placeholder="Weight"
              type="text"
              onChange={value => {
                handleChange('weight', value, id);
                setChange(1);
              }}
              value={
                group === 'test'
                  ? reduxTestCases[id].weight
                  : group === 'valid'
                  ? reduxValidCases[id].weight
                  : null
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default TestCaseElement;
