import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useTranslation } from 'react-i18next';

import EmailText from '../../../Common/Components/EmailText';
import { ReactComponent as CoensioLogo } from '../../../images/Common/Coensio_newLogo.svg';
import styles from './ForgotPasswordComponent.module.css';
import CustomButton from '../../../Common/Components/CustomButton';
import { forgotPasswordAction } from '../../../redux/signup/actions';
import { setForgotPasswordEmail } from '../../../redux/signup/slice';
import isEmail from '../../../utils/isEmail';
import GoBackButton from '../../../Common/Components/GoBackButton';

const ForgotPasswordComponent = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const history = useHistory();

  const { t } = useTranslation(['forgotPassword'])

  const [buttonLoading, setButtonLoading] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const forgotPasswordClick = async () => {
    setButtonLoading(true);

    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('forgotPassword');
    await dispatch(setForgotPasswordEmail(email));
    const data = {
      email,
      reCaptchaToken: token
    };
    await dispatch(forgotPasswordAction(data));

    setButtonLoading(false);
  };

  // const Back = () => {
  //   return (
  //     <div className={styles.back}>
  //       <Link to={route.login} style={{ display: 'flex' }}>
  //         <Arrow className={styles.arrow} />
  //         <div className={styles.backText}>Back to Login</div>
  //       </Link>
  //     </div>
  //   );
  // };

  return (
    <div className={styles.PageWrapper}>
      <div className={styles.CardWrapper}>
        <div className={styles.outerCard}>
          <div className={styles.innerCard}>
            <CoensioLogo className={styles.logo} />
            <div className={styles.backWrapper}>
              <GoBackButton
                style={{ marginLeft: '-10px' }}
                onClick={() => {
                  history.push(`/login`);
                }}
              />
              <label className={styles.cardTitle}>{t('forgotPassw')}</label>
            </div>
            <label className={styles.cardText}>
              {t('enterMail')}
            </label>

            <EmailText
              value={email}
              setValue={setEmail}
              placeholder={t('email')}
              showInvisibleError
              trimWhiteSpaces
            />
            <CustomButton
              isDisabled={!email || !isEmail(email)}
              customStyle={{ width: '100%', marginTop: '0.6rem', marginBottom: '2rem' }}
              textField={t('sendMail')}
              buttonOnClick={forgotPasswordClick}
              loading={buttonLoading}
              enterEnabled
            />
            {/* <Back /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordComponent;
