import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import styles from '../Styles/AssessmentWeightSelectorItem.module.css';
import { ReactComponent as DropDown } from '../../../images/AddAssessment/AssessmentCustomization/weight_dropdown.svg';
import codingIcon from '../../../images/AddAssessment/AssessmentCustomization/coding_assessment.svg';
import freeTextIcon from '../../../images/AddAssessment/AssessmentCustomization/freetext_assessment.svg';
import multipleChoiceIcon from '../../../images/AddAssessment/AssessmentCustomization/multiple_assessment.svg';
import { updateQuestionWeightAction } from '../../../redux/assessmentsVol2/actions';
import {
  setRemoveQuestionShow,
  setQuestionBeingRemoved
} from '../../../redux/assessmentsVol2/slice';
import Tooltip from '../../../Common/Components/Tooltip';
import TextOverflow from '../../../Common/Components/TextOverflow';

const GreyDiv = styled.div`
  background: #f4f4f4;
  border-radius: 8px;
  display: flex;
  margin-right: 15px;
  max-width: 54px;
  width: 54px;
  position: relative;
  flex-direction: row;
  align-items: center;
`;

const PointComponent = React.memo(({ item }) => {
  return (
    <GreyDiv id={styles.points}>
      <div>{item.scoreInt}</div>
    </GreyDiv>
  );
});

export default function AssessmentWeightSelectorItem({ item, id, weightFlag = false, index }) {
  const dispatch = useDispatch();
  const [isExtended, setIsExtended] = useState(false);
  const array = [1, 2, 3, 4, 5];
  const [weight, setWeight] = useState(item?.weight);

  const WeightButton = ({ text, index }) => (
    <div
      onClick={e => {
        if (isExtended) {
          e.stopPropagation();
          setWeight(text);
          const data = {
            rootAssessmentId: id,
            questionId: item.questionId,
            weight: text
          };
          dispatch(updateQuestionWeightAction(data));
          setIsExtended(!isExtended);
        }
      }}
      className={styles.weightbarInner}
      style={
        text === weight
          ? { color: 'white', backgroundColor: '#585858' }
          : index === 4
          ? { borderRight: 'none' }
          : {}
      }
    >
      {text}
    </div>
  );

  const DeleteIcon = () => (
    <span
      className={`${styles.deleteContainer} ${styles.questionDelete}`}
      onClick={() => {
        const data = {
          rootAssessmentId: id,
          questionId: item.questionId
        };
        dispatch(setRemoveQuestionShow(true));
        dispatch(setQuestionBeingRemoved(data));
      }}
    />
  );

  return (
    <div className={`${styles.container}  ${isExtended ? styles.extented : ''}`} key={item}>
      <div
        className={`${styles.innerContainer} ${styles.questionContainer} ${
          isExtended ? styles.extentedInner : ''
        } `}
      >
        <div className="FlexRow">
          <Tooltip
            content={
              item.questionType === 1
                ? 'Multiple Choice'
                : item.questionType === 2
                ? 'Coding'
                : 'Free Text'
            }
            childrenStyle={{ width: 'fit-content' }}
          >
            <img
              src={
                item.questionType === 1
                  ? multipleChoiceIcon
                  : item.questionType === 2
                  ? codingIcon
                  : freeTextIcon
              }
              alt=""
              className={styles.questionIcon}
            />
          </Tooltip>
        </div>

        <span>{index + 1}.</span>

        <TextOverflow text={item?.title} />

        <div className={styles.weightRowRight}>
          {weightFlag ? (
            <>
              <GreyDiv
                className={styles.dropdown}
                onClick={() => {
                  setIsExtended(!isExtended);
                }}
              >
                <div
                  className={`${isExtended ? styles.nestedTable : styles.weightbar}`}
                  style={
                    isExtended
                      ? { width: 250, transform: 'translate(-200px)', top: 40, opacity: 1 }
                      : { width: 50, top: 0, opacity: 0 }
                  }
                >
                  <div
                    className={styles.innerAnimation}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      height: 30,
                      position: 'absolute',
                      left: isExtended ? 0 : (weight - 1) * -50,
                      top: 0
                    }}
                  >
                    {array.map((item, index) => (
                      <WeightButton index={index} text={item} key={`${index}-${item}`} />
                    ))}
                  </div>
                </div>
                <div className={styles.weight}>{weight}</div>
                <DropDown />
              </GreyDiv>
              <PointComponent item={item} />
            </>
          ) : null}
          <DeleteIcon />
        </div>
      </div>
    </div>
  );
}
