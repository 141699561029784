import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useTranslation, Trans } from 'react-i18next';

import CustomButton from '../../../Common/Components/CustomButton';
import {
  customAssessmentId,
  rootAssessmentQuestionList,
  sessionQuestion
} from '../../../redux/assessmentsVol2/selectors';
import AssessmentWeightSelectorItem from './AssessmentWeightSelectorItem';
import NoQuestion from '../Images/no-question-image.svg';
import { setSessionQuestion } from '../../../redux/assessmentsVol2/slice';
import styles from '../Styles/QuestionBasedRightColumn.module.css';

export default function QuestionBasedRightColumn() {
  const array = useSelector(rootAssessmentQuestionList);
  const id = useSelector(customAssessmentId);
  const history = useHistory();
  const questionAdded = useSelector(sessionQuestion);
  const dispatch = useDispatch();

  const { t } = useTranslation(['questionBasedAssm']);

  const [totalTime, setTotalTime] = useState();
  const calculateMin = questionList => {
    let timeInSeconds = 0;

    questionList?.forEach(item => {
      timeInSeconds += parseInt(item.questionDuration, 10);
    });

    const time = {
      min: Math.floor(timeInSeconds / 60),
      sec: timeInSeconds - Math.floor(timeInSeconds / 60) * 60
    };
    if (time.sec === 0) {
      // return `${time.min} min`;
      return (
        <Trans i18nKey="timeMin" ns="questionBasedAssm" values={{ min: time.min }}>
          0<span>1</span>
          <span>2</span>
        </Trans>
      );
    }
    if (time.min === 0) {
      // return `${time.sec} sec`;
      return (
        <Trans i18nKey="timeSec" ns="questionBasedAssm" values={{ sec: time.sec }}>
          0<span>1</span>
          <span>2</span>
        </Trans>
      );
    }
    // return `${time.min} min ${time.sec} sec`;
    return (
      <Trans i18nKey="timeMinSec" ns="questionBasedAssm" values={{ min: time.min, sec: time.sec }}>
        0<span>1</span>
        <span>2</span>
        <span>3</span>
        <span>4</span>
      </Trans>
    );
  };

  useEffect(() => {
    setTotalTime(calculateMin(array));
  }, [array]);

  useEffect(() => {
    return () => {
      dispatch(setSessionQuestion(false));
    };
  }, []);

  return (
    <>
      <div style={{ padding: '16px 20px' }}>
        {!questionAdded ? (
          <CustomButton
            buttonOnClick={() => {
              history.goBack();
            }}
            style={{
              width: '100%'
            }}
            textField={t('backAssm')}
            type={5}
            size="small"
          />
        ) : (
          <CustomButton
            buttonOnClick={() => {
              history.goBack();
            }}
            style={{
              width: '100%'
            }}
            textField={t('confirm')}
            type={1}
            size="small"
          />
        )}
      </div>

      <div className="FlexColumn w-100" style={{ padding: '0px 20px' }}>
        <div
          className="fw-400 font-12 uppercase"
          style={{ color: 'rgba(88, 88, 88, 0.6)', padding: '12px 0px 10px' }}
        >
          {t('assmDetails').toLocaleUpperCase('tr')}
        </div>

        <div
          className={`FlexRow spaceBetween verticallyCenter w-100 gap-8 ${styles.detailBox}`}
          style={{
            minHeight: '48px',
            height: 'fit-content',
            background: '#f4f4f4',
            borderRadius: '12px',
            marginBottom: '24px'
          }}
        >
          <div className="Inline verticallyCenter">
            <span className="fw-400 font-16 c-darkGray" style={{ marginRight: '4px' }}>
              {t('questions')}
            </span>
            <span className="fw-500 font-16 c-darkGray">{(array && array.length) || '0'}</span>
          </div>
          <div className="Inline verticallyCenter">
            <span className="fw-400 font-16 c-darkGray" style={{ marginRight: '4px' }}>
              {t('duration')}
            </span>
            <span className="fw-500 font-16 c-darkGray">{totalTime}</span>
          </div>
        </div>
      </div>

      <span
        className="fw-400 font-12 uppercase"
        style={{ color: 'rgba(88, 88, 88, 0.6)', padding: '0px 20px 12px' }}
      >
        {t('questionsTitle')}
      </span>
      <div className={styles.listContainer}>
        {array && array.length === 0 ? (
          <div className={styles.noQImage}>
            <img alt="" src={NoQuestion} />
            <span className="centerText fw-500 font-20 c-darkGray" style={{ marginTop: '40px' }}>
              {t('noQuestion')}
            </span>
          </div>
        ) : (
          array?.map((item, index) => (
            <AssessmentWeightSelectorItem key={`${item}`} id={id} item={item} index={index} />
          ))
        )}
      </div>
    </>
  );
}
