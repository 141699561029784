import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import Modal from '../../../Common/CustomModal/Modal';
import CloseButton from '../../../Common/Components/CloseButton';
import TextInput from '../../../Common/Components/TextInput';
import CustomButton from '../../../Common/Components/CustomButton';
import { editQuestionDurationAction } from '../Api/actions';

export default function EditQuestionDurationModal({
  show,
  onClose,
  questionData,
  isCustom,
  isQuestionInOtherAssessment,
  rootAssessmentId
}) {
  const dispatch = useDispatch();
  const [duration, setDuration] = useState(questionData?.questionDuration);
  const [updatedDuration, setUpdatedDuration] = useState(questionData?.questionDuration);
  const [flowState, setFlowState] = useState(1);
  const [updateExisting, setUpdateExisting] = useState(false);
  const questionId = questionData?.questionId;

  function resetStates() {
    setDuration(questionData?.questionDuration);
    setUpdatedDuration(questionData?.questionDuration);
    setFlowState(1);
  }

  useEffect(() => {
    resetStates();
  }, [questionData]);

  async function updateDuration({ duration, updateExisting = null }) {
    const data = {
      questionId: questionId,
      duration: duration ?? updatedDuration,
      updateExisting: updateExisting ?? null,
      rootAssessmentId: rootAssessmentId ?? null
    };
    console.log(data);
    const resp = await dispatch(editQuestionDurationAction(data));
    console.log(resp);
    return resp;
  }

  const flowHandler = async duration => {
    if (!isCustom) {
      const resp = await updateDuration({ duration: duration });
      if (resp.meta.requestStatus === 'fulfilled') setFlowState(2);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (isQuestionInOtherAssessment) {
        setFlowState(3);
      } else {
        setUpdateExisting(true);
        const resp = await updateDuration({ updateExisting: true });
        if (resp.meta.requestStatus === 'fulfilled')
          setFlowState(2);
      }
    }
  };

  const confirmClick = async duration => {
    setUpdatedDuration(duration);
    await flowHandler(duration);
  };

  const saveAsNew = async () => {
    setUpdateExisting(false);
    const resp = await updateDuration({ updateExisting: false });
    if (resp.meta.requestStatus === 'fulfilled') setFlowState(2);
  };

  const updateCurrent = async () => {
    setUpdateExisting(true);
    const resp = await updateDuration({ updateExisting: true });
    if (resp.meta.requestStatus === 'fulfilled') setFlowState(2);
  };

  return (
    <Modal
      show={show}
      onClose={onClose}
      centerHorizontally
      centerVertically
      outsideClickClose
      contentStyle={{ borderRadius: '16px' }}
    >
      {flowState === 1 ? (
        <EditContent
          duration={duration}
          onClose={onClose}
          questionId={questionData?.questionId}
          confirmClick={confirmClick}
          isCustom={isCustom}
          isQuestionInOtherAssessment={isQuestionInOtherAssessment}
          initialDuration={questionData?.questionDuration}
        />
      ) : flowState === 2 ? (
        <SuccessContent onClose={onClose} isCustom={isCustom} updateExisting={updateExisting} />
      ) : flowState === 3 ? (
        <SelectionContent onClose={onClose} saveAsNew={saveAsNew} updateCurrent={updateCurrent} />
      ) : null}
    </Modal>
  );
}

const EditContent = ({ duration, onClose, confirmClick, isCustom, isQuestionInOtherAssessment, initialDuration }) => {
  const [min, setMin] = useState(Math.floor(duration / 60));
  const [sec, setSec] = useState(duration % 60);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const updatedDuration = Number(Number(min * 60) + Number(sec));

  useEffect(() => {
    setMin(Math.floor(duration / 60));
    setSec(duration % 60);
  }, [duration]);

  function isNumberKey(evt) {
    const charCode = evt.which ? evt.which : evt.keyCode;
    // Allow control characters (like backspace, tab, etc.)
    if (charCode <= 31) return true;
    // Disallow the point character (.)
    if (charCode === 46) return false;
    // Allow numeric characters (0-9)
    if (charCode >= 48 && charCode <= 57) return true;
    // Disallow all other characters
    return false;
  }

  const disableNonNumericalEvent = evt => {
    if (!isNumberKey(evt)) {
      evt.preventDefault();
      evt.stopPropagation();
    }
  };

  const passDurationToParent = async () => {
    setConfirmLoading(true);
    await confirmClick(updatedDuration);
    setConfirmLoading(false);
  };

  const { t } = useTranslation(['assmCustomization']);

  return (
    <div className="FlexColumn gap-24" style={{ width: '520px', padding: '24px' }}>
      <div className="FlexColumn gap-12">
        <div className="FlexRow verticallyCenter">
          <span className="fw-500 font-18 c-darkGray">{t('editTimeHeader')}</span>
          <CloseButton size="small" onClick={onClose} />
        </div>
        {isCustom ? (
          <span className="fw-300 font-16 c-lightGray">
            <Trans i18nKey="editTimeUpdate" ns="assmCustomization">
              <span>1</span>
              <span className="fw-400">2</span>
              <span>3</span>
            </Trans>
          </span>
        ) : (
          <span className="fw-300 font-16 c-lightGray">
            <Trans i18nKey="editTimeSave" ns="assmCustomization">
              <span>1</span>
              <span>2</span>
              <span className="fw-400">3</span>
            </Trans>
          </span>
        )}
      </div>
      <div className="FlexRow gap-24">
        <TextInput
          placeholder={t('enterMin')}
          value={min}
          onKeyDown={disableNonNumericalEvent}
          onChange={e => setMin(e?.target?.value)}
          style={{ width: '100%' }}
        />
        <TextInput
          placeholder={t('enterSec')}
          value={sec}
          onKeyDown={disableNonNumericalEvent}
          onChange={e => setSec(e?.target?.value)}
          style={{ width: '100%' }}
        />
      </div>
      <div className="FlexRow gap-12" style={{ justifyContent: 'flex-end' }}>
        <CustomButton textField={t('cancel')} type={2} buttonOnClick={onClose} />
        <CustomButton
          textField={t('confirm')}
          type={1}
          buttonOnClick={passDurationToParent}
          loading={confirmLoading}
          isDisabled={initialDuration === updatedDuration}
        />
      </div>
    </div>
  );
};

const SelectionContent = ({ onClose, saveAsNew, updateCurrent }) => {
  const [saveNewLoading, setSaveNewLoading] = useState(false);
  const [updateCurrentLoading, setUpdateCurrentLoading] = useState(false);

  const saveNewClick = async () => {
    setSaveNewLoading(true);
    await saveAsNew();
    setSaveNewLoading(false);
  };

  const updateCurrentClick = async () => {
    setUpdateCurrentLoading(true);
    await updateCurrent();
    setUpdateCurrentLoading(false);
  };

  const { t } = useTranslation(['assmCustomization']);

  return (
    <div className="FlexColumn gap-8" style={{ width: '572px', padding: '24px' }}>
      <CloseButton size="small" onClick={onClose} />
      <div className="FlexColumn gap-48 verticallyCenter" style={{ padding: '0px 8px' }}>
        <div className="FlexColumn gap-20 verticallyCenter">
          <Warning />
          <div className="FlexColumn gap-12 verticallyCenter centerText c-lightGray">
            <span className="fw-400 font-24">{t('newOrUpdate')}</span>
            <span className="fw-300 font-18">{t('ifUpdateText')}</span>
          </div>
        </div>
        <div className="FlexRow gap-12 w-100">
          <CustomButton
            textField={t('saveAsNewButton')}
            type={5}
            customStyle={{ width: '100%' }}
            buttonOnClick={saveNewClick}
            loading={saveNewLoading}
          />
          <CustomButton
            textField={t('updateTimeButton')}
            type={1}
            customStyle={{ width: '100%' }}
            buttonOnClick={updateCurrentClick}
            loading={updateCurrentLoading}
          />
        </div>
      </div>
    </div>
  );
};

const SuccessContent = ({ onClose, isCustom, updateExisting }) => {
  const { t } = useTranslation(['assmCustomization']);
  return (
    <div className="FlexColumn gap-8" style={{ width: '572px', padding: '24px' }}>
      <CloseButton size="small" onClick={onClose} />
      <div className="FlexColumn gap-48 verticallyCenter" style={{ padding: '0px 8px' }}>
        <div className="FlexColumn gap-20 verticallyCenter">
          <Tick />
          <div className="FlexColumn gap-12 verticallyCenter centerText c-lightGray">
            {isCustom && !updateExisting ? (
              <span className="fw-400 font-24" style={{ padding: '0px 60px' }}>
                {t('savedAsNew')}
              </span>
            ) : (
              <span className="fw-400 font-24" style={{ padding: '0px 60px' }}>
                {t('updatedTime')}
              </span>
            )}

            {!isCustom ? (
              <span className="fw-300 font-18">
                <Trans i18nKey="savedQTo" ns="assmCustomization">
                  <span>1</span>
                  <span>2</span>
                  <span className="fw-400">3</span>
                </Trans>
              </span>
            ) : updateExisting ? (
              <span className="fw-300 font-18">
                <Trans i18nKey="updatedQIn" ns="assmCustomization">
                  <span>1</span>
                  <span>2</span>
                  <span className="fw-400">3</span>
                </Trans>
              </span>
            ) : null}
          </div>
        </div>
        <CustomButton
          textField={t('okay')}
          type={1}
          buttonOnClick={onClose}
          customStyle={{ width: '100%' }}
        />
      </div>
    </div>
  );
};

const Tick = () => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="42" height="42" rx="21" fill="#4AC389" />
    <path
      d="M18.0461 31.5C17.3936 31.5 16.7755 31.1955 16.3795 30.6704L11.0195 23.5883C10.8538 23.3695 10.7328 23.1203 10.6635 22.8548C10.5941 22.5893 10.5778 22.3127 10.6154 22.0409C10.653 21.7691 10.7439 21.5074 10.8827 21.2707C11.0215 21.034 11.2056 20.827 11.4245 20.6615C11.6433 20.4953 11.8928 20.3739 12.1586 20.3043C12.4244 20.2348 12.7013 20.2183 12.9735 20.2559C13.2457 20.2936 13.5077 20.3845 13.7447 20.5236C13.9816 20.6627 14.1888 20.8472 14.3543 21.0665L17.8811 25.723L26.7485 11.4836C27.0425 11.0135 27.5111 10.6792 28.0513 10.554C28.5916 10.4289 29.1594 10.5231 29.6302 10.8161C30.6098 11.4251 30.9113 12.7152 30.2993 13.6963L19.8223 30.5129C19.6435 30.8013 19.3969 31.0416 19.1042 31.2131C18.8114 31.3846 18.4811 31.4821 18.1422 31.497C18.1092 31.5 18.0792 31.5 18.0461 31.5Z"
      fill="white"
    />
  </svg>
);

const Warning = () => (
  <svg width="42" height="43" viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.635742" width="42" height="42" rx="21" fill="#FFD600" />
    <path
      d="M19.2 10.2357H22.4L21.76 26.2357H19.84L19.2 10.2357ZM19.04 31.3557C19.04 30.8651 19.2107 30.4491 19.552 30.1077C19.9147 29.7664 20.3307 29.5957 20.8 29.5957C21.2907 29.5957 21.7067 29.7664 22.048 30.1077C22.3893 30.4491 22.56 30.8651 22.56 31.3557C22.56 31.8251 22.3893 32.2411 22.048 32.6037C21.7067 32.9451 21.2907 33.1157 20.8 33.1157C20.3307 33.1157 19.9147 32.9451 19.552 32.6037C19.2107 32.2411 19.04 31.8251 19.04 31.3557Z"
      fill="#585858"
    />
  </svg>
);
