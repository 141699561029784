import React, { useEffect, useState } from 'react';
import Editor from '@monaco-editor/react';
import styles from './ReportQuestionBody.module.css';

function QuestionDetailMultipleChoice({
  qDesc,
  mcOptions,
  mcCorrectAnswer,
  mcSelectedAnswer,
  imageOpt
}) {
  const opt = [];
  try {
    const innerOpt = JSON.parse(mcOptions);
    innerOpt?.forEach(x => {
      const singleOpt = x?.replaceAll(
        process.env.REACT_APP_IMAGE_API,
        process.env.REACT_APP_S3_URL
      );
      opt.push(singleOpt);
    });
  } catch (error) {
    console.log(error, 'error on parsing multiple choice options');
    console.log(mcOptions);
  }

  useEffect(() => {
    const re = new RegExp(process.env.REACT_APP_S3_URL + '/.*alt');
    // const re2 = new RegExp(process.env.REACT_APP_IMAGE_API + '/.*alt');

    const temp = [];
    opt?.forEach(x => {
      const matchOnOptions = x?.match(re);
      // const matchOnOptions2 = x?.match(re2);

      if (matchOnOptions) {
        matchOnOptions?.forEach(x => {
          const path = x.split('" alt')[0];
          temp.push(path);
        });
      }

      // if (matchOnOptions2) {
      //   matchOnOptions2?.forEach(x => {
      //     const path = x.split('" alt')[0];
      //     temp.push(path);
      //   });
      // }
    });

    temp?.forEach(x => {
      fetch(x, { cache: 'reload' }).catch(e => console.log(e));
    });
  }, []);

  return (
    <div className={styles.BodyWrapper}>
      <div className={styles.MultipleChoiceWrapper}>
        <div
          className={styles.MultipleChoiceQuestionText}
          dangerouslySetInnerHTML={{ __html: qDesc }}
        />
        {opt.map((e, index) => (
          <div className={styles.OptionWrapper}>
            <div
              className={`${styles.OptionCheckbox} ${
                index === mcCorrectAnswer && styles.CorrectOption
              } `}
            >
              {index === mcSelectedAnswer && (
                <div
                  className={`${styles.SelectedOption} ${
                    index === mcCorrectAnswer && styles.CorrectSelectedOption
                  } `}
                />
              )}
            </div>
            {imageOpt ? (
              <div
                className={`${styles.OptionText} ${
                  index === mcSelectedAnswer && styles.SelectedOptionText
                }`}
                dangerouslySetInnerHTML={{ __html: e }}
              />
            ) : (
              <div
                className={`${styles.OptionText} ${
                  index === mcSelectedAnswer && styles.SelectedOptionText
                }`}
              >
                {e}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

function QuestionDetailFreeText({ qDesc, ftCandidateAnswer }) {
  return (
    <div className={styles.BodyWrapper}>
      <div className={styles.FreeTextWrapper}>
        <div className={styles.FreeTextQuestionText} dangerouslySetInnerHTML={{ __html: qDesc }} />
        {ftCandidateAnswer && (
          <div className={styles.FreeTextAnswerWrapper}>{ftCandidateAnswer}</div>
        )}
      </div>
    </div>
  );
}

function QuestionDetailCoding({ qDesc, cdCandidateCodeLang, cdCandidateAnswer }) {
  let lang = '';

  switch (cdCandidateCodeLang) {
    case 'C - Coding':
      lang = 'c';
      break;
    case 'C++ - Coding':
      lang = 'cpp';
      break;
    case 'C# - Coding':
      lang = 'csharp';
      break;
    case 'Java - Coding':
      lang = 'java';
      break;
    case 'Javascript - Coding':
      lang = 'javascript';
      break;
    case 'Kotlin - Coding':
      lang = 'kotlin';
      break;
    case 'Python3 - Coding':
      lang = 'python';
      break;
    case 'Swift - Coding':
      lang = 'swift';
      break;
    default:
      break;
  }

  const SINGLE_LINE_HEIGHT = 18;
  const BUFFER_LINE_COUNT = 4;
  const lineCount = (cdCandidateAnswer?.split('\n')?.length || 0) + BUFFER_LINE_COUNT;

  return (
    <div className={styles.CodingBodyWrapper} style={{ marginBottom: '10px' }}>
      <div className={styles.CodingWrapper}>
        <div className={styles.CodingQuestionText} dangerouslySetInnerHTML={{ __html: qDesc }} />
      </div>
      <div className={styles.CodingEditorWrapper}>
        <Editor
          defaultLanguage={lang}
          className={styles.MonacoEditor}
          defaultValue={
            cdCandidateAnswer.startsWith('"/') ? JSON.parse(cdCandidateAnswer) : cdCandidateAnswer
          }
          theme="vs-dark"
          options={{
            wordWrap: true,
            readOnly: true,
            minimap: { enabled: false },
            stickyTabStops: false
          }}
          height={`${lineCount * SINGLE_LINE_HEIGHT}px`}
        />
      </div>
    </div>
  );
}

function ReportQuestionBody({
  type,
  qBody,
  mcCorrectAnswer,
  mcSelectedAnswer,
  mcOptions,
  ftCandidateAnswer,
  cdCandidateAnswer,
  cdCandidateCodeLang,
  imageOpt
}) {
  let qDesc = '';
  try {
    qDesc = JSON.parse(qBody);
    qDesc = qDesc.replaceAll('https://api.coens.io/', '');
    qDesc = qDesc.replaceAll('https://file.coens.io/', '');
    qDesc = qDesc.replaceAll('<img src="', `<img src="${process.env.REACT_APP_S3_URL}/`);
    qDesc = qDesc.replaceAll('<source src="', `<source src="${process.env.REACT_APP_S3_URL}/`);
    qDesc = qDesc.replaceAll('font-size: 18px;', 'font-size: unset;');
  } catch (error) {
    console.log(error, 'error on parsing question body');
    console.log(qBody);
  }

  const [imageList, setImageList] = useState();

  useEffect(() => {
    try {
      const re = new RegExp(process.env.REACT_APP_S3_URL + '/.*alt');
      const match = qDesc?.match(re);

      const temp = [];
      if (match) {
        match?.forEach(x => {
          const path = x.split('" alt')[0];
          temp.push(path);
        });
      }

      setImageList(temp);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (imageList && imageList?.length > 0) {
      imageList?.forEach(x => {
        fetch(x, { cache: 'reload' }).catch(e => console.log(e));
      });
    }
  }, [imageList]);

  switch (type) {
    case 1:
      return QuestionDetailMultipleChoice({
        qDesc,
        mcOptions,
        mcCorrectAnswer,
        mcSelectedAnswer,
        imageOpt
      });
    case 2:
      return QuestionDetailCoding({ qDesc, cdCandidateCodeLang, cdCandidateAnswer });
    case 3:
      return QuestionDetailFreeText({ qDesc, ftCandidateAnswer });

    default:
      return QuestionDetailFreeText({ qDesc, ftCandidateAnswer });
  }
}
export default ReportQuestionBody;
