import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';

import { ASSESSMENT_FLOW_MOBILE_THRESHOLD } from '../../../utils/helpers';
import {
  CompanyName,
  LogoPath,
  rootAssessmentNameRedux
} from '../../../redux/AssessmentSession/selectors';

import mockImage from '../../../images/Common/Coensio_newLogo.svg';
import oopsImage from '../../Images/oopsImg.svg';
import styles from './OopsComponent.module.css';

import useMixpanelOopsPageview from '../../../utils/mixpanelHelpers/useMixpanelOopsPageview';

const OopsComponent = () => {
  const { t } = useTranslation(['application']);
  const companyName = useSelector(CompanyName);
  const rootAssessmentName = useSelector(rootAssessmentNameRedux);
  const logoPath = useSelector(LogoPath);

  useMixpanelOopsPageview();

  return (
    <PageWrapper>
      <CardWrapper>
        <BigCard>
          <InnerCard>
            <div className={`${styles.Panel} ${styles.Left}`}>
              <div className={styles.logoWrapper}>
                <img
                  className={styles.companyLogo}
                  src={logoPath ? `${process.env.REACT_APP_IMAGE_API}/${logoPath}` : mockImage}
                  alt="companyLogo"
                />
              </div>
              <div className={styles.imageWrapper}>
                <img className={styles.oopsImg} src={oopsImage} alt="oops" />
              </div>
            </div>
            <div className={`${styles.Panel} ${styles.Right}`}>
              <div className={styles.Header}>{t('oopsHeader')}</div>
              <div className={styles.Subtext}>
                <Trans
                  i18nKey="oopsSubtext1"
                  values={{
                    rootAssessmentName: rootAssessmentName
                  }}
                >
                  Dear candidate, we are no longer looking for {rootAssessmentName}.
                </Trans>
              </div>
              <div className={styles.Subtext}>
                <Trans
                  i18nKey="oopsSubtext2"
                  values={{
                    companyName: companyName
                  }}
                >
                  Keep following our career opportunities! We would like to see you in the future at{' '}
                  {companyName}.
                </Trans>
              </div>
            </div>
          </InnerCard>
        </BigCard>
      </CardWrapper>
      <Powered>
        <div className={styles.powered}>powered by</div>
        <img style={{ maxHeight: '1.4rem' }} alt="" src={mockImage} />
      </Powered>
    </PageWrapper>
  );
};

export default OopsComponent;

const PageWrapper = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;
  flex-direction: column;
  position: relative;
  justify-content: center;
  height: 100%;
  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    background-color: white;
    justify-content: space-between;
  }
`;

const CardWrapper = styled.div`
  width: 100%;
  height: 410px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    justify-content: flex-start;
    padding: 2.4rem;
    padding-top: 28px;
    height: fit-content;
  }
`;

const BigCard = styled.div`
  max-width: 1000px; // Margin should be bigger if there screen is bigger
  min-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px; // If less change to mobile version
  background: #ffffff;
  display: flex;
  margin-left: 2rem;
  width: 60%; // To try the continuous scaling
  margin-right: 2rem;
  flex-direction: column;
  padding: 3.2rem;
  justify-content: center;
  box-shadow: 0px 1px 24px rgba(131, 131, 131, 0.1);

  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    width: 100%;
    max-width: none; // Bunlar nasıl daha iyi yapılır
    max-height: none;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    min-width: 0;
    box-shadow: none;
  }
`;

const InnerCard = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
`;

const Powered = styled.div`
  display: flex;
  max-width: 1000px; // Margin should be bigger if there screen is bigger
  min-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px; // If less change to mobile version
  width: 60%;
  margin-top: 1.6rem;
  flex-direction: row;
  margin-right: auto;
  margin-left: auto;
  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    display: none;
  }
  align-items: center;
`;
