import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import AddAssessmentBody from '../Components/AddAssessmentBody';
import PageContainer from '../../../Common/Pages/PageContainer';
import { userSelector } from '../../../redux/auth/selectors';
import mixpanel from '../../../utils/mixpanel';

function AddAssessment() {
  /* --------------- Mixpanel pageview --------------- */
  localStorage.removeItem('Mixpanel_AssessmentCustomizationRootAssessmentName')

  const user = useSelector(userSelector);
  useEffect(() => {
    if (user) {
      mixpanel.track('Add Assessment - Pageview', {
        'User Id': user.userId,
        'Company Name': user.companyName,
        "Name Surname": user.userNameSurname,
      });
    }
    
  }, [user]);
  /* ------------------------------------------------- */

  return (
    <PageContainer>
      <AddAssessmentBody />
    </PageContainer>
  );
}

export default AddAssessment;
