import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import RejectCandidatesModal from '../../../Assessment/AssessmentDetail/Modals/RejectionMail/RejectCandidatesModal';
import RejectSuccessModal from '../../../Assessment/AssessmentDetail/Modals/RejectionMail/RejectSuccessModal';
import InfoBanner from '../../../Common/Components/InfoBanner';
import {
  getCandidatePositionsAction,
  refreshCandidatePositionsAction,
  newOpsCandidateCheckAction,
  getNotesAction,
  getActivitiesAction
} from '../../../redux/candidateDetail/actions';
import {
  currentAssessmentFunnelListSelector,
  defaultAssessmentFunnelListSelector,
  infoBannerShowSelector,
  infoBannerTextSelector,
  isRejectionMailSentSelector,
  showChangeFunnelPopupSelector,
  showRejectionPopupFlagSelector,
  showRejectionSuccessFlagSelector
} from '../../../redux/candidateDetail/selectors';
import {
  setInfoBannerShow,
  setInfoBannerText,
  setIsRejectionMailSent,
  setShowChangeFunnelPopup,
  setShowRejectionPopupFlag,
  setShowRejectionSuccessFlag
} from '../../../redux/candidateDetail/slice';
import { getParam, useWindowSize } from '../../../utils/helpers';
import styles from './CandidateDetailComponent.module.css';
import ChangeFunnelModal from './ChangeFunnelModal';
import ContentWrapper from './ContentWrapper/ContentWrapper';
import { moveCandidateToRejected } from './helpers';
import Notes from './Notes/Notes';
import PositionWrapper from './Positions/PositionWrapper';
import CandidateProfile from './Profile/CandidateProfile';
import PitCard from './Pit/PitCard';
import ProficiencyCard from './Proficiency/ProficiencyCard';
import useBigFivePTHandler from '../../Utils/useBigFivePTHandler';

const CandidateDetailComponent = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    const data = {
      companyAssessmentId: getParam(location.search, 'defaultAssessmentId'),
      candidateId: id
    };
    const cand = {
      CompanyAssessmentId: getParam(location.search, 'defaultAssessmentId'),
      UserId: id
    };
    const note = {
      UserId: id,
      CompanyAssessmentId: getParam(location.search, 'currentAssessmentId')
    };
    dispatch(getCandidatePositionsAction(data));
    dispatch(getNotesAction(note));
  }, []);

  async function fetchPositions() {
    const data = {
      companyAssessmentId: getParam(location.search, 'defaultAssessmentId'),
      candidateId: id
    };
    await dispatch(refreshCandidatePositionsAction(data));
  }

  function fetchActivities() {
    const dataBack = {
      UserId: id,
      CompanyAssessmentId: getParam(location.search, 'defaultAssessmentId')
    };
    dispatch(getActivitiesAction(dataBack));
  }

  useEffect(() => {
    fetchActivities();
    window.addEventListener('candidateFunnelUpdateOnCandidateDetail', fetchPositions);
    window.addEventListener('candidateFunnelUpdateOnCandidateDetail', fetchActivities);
    window.addEventListener('fetchActivites', fetchActivities);
    return () => {
      window.removeEventListener('candidateFunnelUpdateOnCandidateDetail', fetchPositions);
      window.removeEventListener('candidateFunnelUpdateOnCandidateDetail', fetchActivities);
      window.removeEventListener('fetchActivites', fetchActivities);
    };
  }, []);

  const showBanner = useSelector(infoBannerShowSelector);
  const bannerText = useSelector(infoBannerTextSelector);

  const showRejectionPopup = useSelector(showRejectionPopupFlagSelector);
  const [showRejectionPopup2, setShowRejectionPopup2] = useState(false);
  const showRejectionSuccess = useSelector(showRejectionSuccessFlagSelector);
  const [showRejectionSuccess2, setShowRejectionSuccess2] = useState(false);
  const isRejectionMailSentRedux = useSelector(isRejectionMailSentSelector);
  const currentAssessmentFunnelListRedux = useSelector(currentAssessmentFunnelListSelector);

  useEffect(() => {
    if (showRejectionPopup) {
      setShowRejectionPopup2(true);
    } else {
      setTimeout(() => {
        setShowRejectionPopup2(false);
      }, 300);
    }
  }, [showRejectionPopup]);

  useEffect(() => {
    if (showRejectionSuccess) {
      setShowRejectionSuccess2(true);
    } else {
      setTimeout(() => {
        setShowRejectionSuccess2(false);
      }, 300);
    }
  }, [showRejectionSuccess]);

  const showChangeFunnelPopupRedux = useSelector(showChangeFunnelPopupSelector);
  const [showFunnelPopup, setShowFunnelPopup] = useState(false);

  useEffect(() => {
    if (showChangeFunnelPopupRedux) {
      setShowFunnelPopup(true);
    } else {
      setTimeout(() => {
        setShowFunnelPopup(false);
      }, 300);
    }
  }, [showChangeFunnelPopupRedux]);

  const [selectedReasonToReject, setSelectedReasonToReject] = useState(null);

  const { BigFivePTComponent } = useBigFivePTHandler();

  return (
    <>
      <div className={styles.ComponentWrapper}>
        {/* HEADER */}
        <div className={styles.leftColumn}>
          <CandidateProfile />

          <SecondRow>
            <PositionWrapper />
            <Notes />
          </SecondRow>

          <PositionRow>
            <PositionWrapper />
          </PositionRow>

          <ContentWrapper />

          <NotesRow>
            <Notes />
          </NotesRow>
        </div>

        <div className={styles.rightColumn}>
          <PositionWrapper />
          <PitCard />
          <BigFivePTComponent />
          <ProficiencyCard />
          <Notes />
        </div>
      </div>

      <InfoBanner
        show={showBanner}
        text={bannerText}
        setStatus={() => {
          dispatch(setInfoBannerShow(null));
          dispatch(setInfoBannerText(null));
        }}
      />

      {showRejectionPopup2 ? (
        <RejectCandidatesModal
          newCandidateDetailFlag
          show={showRejectionPopup}
          onClose={() => {
            dispatch(setShowRejectionPopupFlag(false));
            setSelectedReasonToReject(null);
          }}
          showSuccess={e => dispatch(setShowRejectionSuccessFlag(e))}
          setMailSentFlag={e => dispatch(setIsRejectionMailSent(e))}
          selectedReasonToReject={selectedReasonToReject}
          setSelectedReasonToReject={setSelectedReasonToReject}
          moveMethod={e => {
            if (selectedReasonToReject === null) {
              moveCandidateToRejected(e, dispatch, id, currentAssessmentFunnelListRedux);
            } else {
              moveCandidateToRejected(
                e,
                dispatch,
                id,
                currentAssessmentFunnelListRedux,
                selectedReasonToReject.id
              );
            }
          }}
        />
      ) : null}
      {showRejectionSuccess2 ? (
        <RejectSuccessModal
          show={showRejectionSuccess}
          onClose={() => dispatch(setShowRejectionSuccessFlag(false))}
          isMailSent={isRejectionMailSentRedux}
          selectedCandCount={1}
        />
      ) : null}

      {showFunnelPopup && (
        <ChangeFunnelModal
          show={showChangeFunnelPopupRedux}
          onClose={() => dispatch(setShowChangeFunnelPopup(false))}
        />
      )}
    </>
  );
};
export default CandidateDetailComponent;

const SecondRow = ({ children }) => {
  const [screenW, screenH] = useWindowSize();

  return screenW > 700 && screenW <= 960 ? (
    <div className={styles.secondRow}>{children}</div>
  ) : null;
};

const PositionRow = ({ children }) => {
  const [screenW, screenH] = useWindowSize();
  return screenW <= 700 ? <div className={styles.positionRow}>{children}</div> : null;
};

const NotesRow = ({ children }) => {
  const [screenW, screenH] = useWindowSize();
  return screenW <= 700 ? <div className={styles.notesRow}>{children}</div> : null;
};
