import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  forgotPasswordService,
  sendVerificationEmailService,
  signupByEmailService,
  requestDemoService,
  signupStep2Service,
  createSignupInfoService,
  activateUserService,
  quickEmailVerificationService,
  refreshPasswordService,
  invitedUserActivateService,
  getCompanyLogoByUserIdService,
  forgotPasswordCheckService
} from './service';

export const signupByEmailAction = createAsyncThunk('signupByEmail', async (data, thunkAPI) => {
  try {
    const response = await signupByEmailService(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const requestDemoAction = async data => {
  try {
    return await requestDemoService(data);
  } catch (error) {
    return error;
  }
};

export const signupStep2Action = createAsyncThunk('signupStep2', async (data, thunkAPI) => {
  try {
    const response = await signupStep2Service(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const sendVerificationEmailAction = createAsyncThunk(
  'sendVerificationEmail',
  async (data, thunkAPI) => {
    try {
      const response = await sendVerificationEmailService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const forgotPasswordAction = createAsyncThunk('forgotPassword', async (data, thunkAPI) => {
  try {
    const response = await forgotPasswordService(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const createSignupInfoAction = createAsyncThunk(
  'createSignupInfo',
  async (data, thunkAPI) => {
    try {
      const response = await createSignupInfoService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const activateUserAction = createAsyncThunk('activateUserAction', async (data, thunkAPI) => {
  try {
    const response = await activateUserService(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const quickEmailVerificationAction = createAsyncThunk(
  'quickEmailVerificationAction',
  async (data, thunkAPI) => {
    try {
      const response = await quickEmailVerificationService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const refreshPasswordAction = createAsyncThunk(
  'refreshPasswordAction',
  async (data, thunkAPI) => {
    try {
      const response = await refreshPasswordService(data);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const invitedUserActivateAction = createAsyncThunk(
  'invitedUserActivate',
  async (data, thunkAPI) => {
    try {
      const response = await invitedUserActivateService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCompanyLogoByUserIdAction = createAsyncThunk(
  'getCompanyLogoByUserId',
  async (data, thunkAPI) => {
    try {
      const response = await getCompanyLogoByUserIdService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const forgotPasswordCheckAction = createAsyncThunk(
  'forgotPasswordCheck',
  async (data, thunkAPI) => {
    try {
      const response = await forgotPasswordCheckService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
