import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import CloseButton from '../../../../Common/Components/CloseButton';
import MobileModal from '../../../../Common/Components/MobileModal/MobileModal';
import Modal from '../../../../Common/CustomModal/Modal';
import { useWindowSize } from '../../../../utils/helpers';

import success from './rejectSuccess.svg';

const RejectSuccessModal = ({ show, onClose, isMailSent, selectedCandCount }) => {
  const [screenW, screenH] = useWindowSize();
  const { t } = useTranslation(['assessmentDetail']);

  const WebSuccessModal = (
    <Modal
      contentStyle={{
        border: '1px solid rgba(88, 88, 88, 0.2)',
        borderRadius: '24px',
        padding: '4.6rem 8rem'
      }}
      //   size={{ width: '57.2rem', height: '21.8rem' }}
      centerHorizontally
      centerVertically
      onClose={onClose}
      show={show}
      outsideClickClose
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontFamily: 'Jost-300',
          fontSize: '1.8rem',
          color: '#585858',
          textAlign: 'center'
        }}
      >
        <div
          style={{
            position: 'absolute',
            right: '2rem',
            top: '1.7rem',
            width: '3.4rem',
            height: '3.4rem'
          }}
        >
          <CloseButton onClick={onClose} />
        </div>
        <img
          src={success}
          alt="successIcon"
          style={{ width: '4.2rem', height: '4.2rem', marginBottom: '3.2rem' }}
        />
        <>
          {isMailSent ? ( // Remove if check when new candidate detail releases
            // <text>
            //   Rejection mail have been sent to&nbsp;
            //   <text style={{ fontFamily: 'Jost-400' }}>
            //     {selectedCandCount} candidate
            //     {selectedCandCount > 1 ? 's' : ''}
            //   </text>
            //   &nbsp;you selected.
            //   <br />
            //   The candidate{selectedCandCount > 1 ? 's are' : ' is'} in the ‘Rejected’ stage
            // </text>
            <span>
              <Trans
                i18nKey="rejectionMailSent"
                ns="assessmentDetail"
                values={{
                  selectedCandCount: selectedCandCount,
                  selectedCandPlural: selectedCandCount > 1 ? t('s') : ''
                }}
              >0
                <span>1</span>
                <span style={{ fontFamily: 'Jost-400' }}>2</span>
                <span style={{ fontFamily: 'Jost-400' }}>3</span>
                <span>4</span>
              </Trans><br />
              <Trans
                i18nKey="rejectedMailSentText"
                ns="assessmentDetail"
                values={{ selectedCandPlural: selectedCandCount > 1 ? t('arePlural') : t('is') }}
              >
                0<span>1</span>
                <span>2</span>
                <span>3</span>
              </Trans>
            </span>
          ) : (
            // Remove if check when new candidate detail releases

            // <text>
            //   Selected candidate{selectedCandCount > 1 ? 's have' : ' has'} been moved to&nbsp;
            //   <text style={{ fontFamily: 'Jost-400' }}>‘Rejected’</text>
            //   &nbsp;stage from now.
            // </text>
            <span>
              <Trans
                i18nKey="rejectedMailSentNow"
                ns="assessmentDetail"
                values={{
                  selectedCandCount: selectedCandCount,
                  selectedCandPlural: selectedCandCount > 1 ? 's have' : 'has'
                }}
              >
                <span>1</span>
                <span>2</span>
                <span>3</span>
                <span style={{ fontFamily: 'Jost-400' }}>4</span>
                <span>5</span>
              </Trans>
            </span>
          )}
        </>
      </div>
    </Modal>
  );

  const MobileSuccessModal = (
    <MobileModal
      backdropStyle={{ overflow: 'hidden' }}
      contentStyle={{
        padding: '20px 20px 24px'
      }}
      onClose={onClose}
      show={show}
      outsideClickClose
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontFamily: 'Jost-300',
          fontSize: '16px',
          color: '#585858',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            fontFamily: 'Jost-400',
            fontSize: '16px',
            lineHeight: '23px',
            color: '#585858',
            textAlign: 'center',
            marginBottom: '24px'
          }}
        >
          <img
            src={success}
            alt="successIcon"
            style={{ width: '25px', height: '25px', marginRight: '12px' }}
          />
          <text>{t('mailSent')}</text>
          <div
            style={{
              width: '24px',
              height: '24px',
              marginLeft: 'auto'
            }}
          >
            <CloseButton onClick={onClose} />
          </div>
          
        </div>

        <span>
              <Trans
                i18nKey="rejectionMailSent"
                ns="assessmentDetail"
                values={{
                  selectedCandCount: selectedCandCount,
                  selectedCandPlural: selectedCandCount > 1 ? t('s') : ''
                }}
              >0
                <span>1</span>
                <span style={{ fontFamily: 'Jost-400' }}>2</span>
                <span style={{ fontFamily: 'Jost-400' }}>3</span>
                <span>4</span>
              </Trans><br />
              <Trans
                i18nKey="rejectedMailSentText"
                ns="assessmentDetail"
                values={{ selectedCandPlural: selectedCandCount > 1 ? t('arePlural') : t('is') }}
              >
                0<span>1</span>
                <span>2</span>
                <span>3</span>
              </Trans>
            </span>
      </div>
    </MobileModal>
  );

  return screenW > 576 ? WebSuccessModal : MobileSuccessModal;
};

export default RejectSuccessModal;
