import React from 'react';

const DeleteIcon = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.7778 8.05556H13.8333L13.2778 7.5H10.5L9.94444 8.05556H8V9.16667H15.7778M8.55556 16.3889C8.55556 16.6836 8.67262 16.9662 8.88099 17.1746C9.08937 17.3829 9.37198 17.5 9.66667 17.5H14.1111C14.4058 17.5 14.6884 17.3829 14.8968 17.1746C15.1052 16.9662 15.2222 16.6836 15.2222 16.3889V9.72222H8.55556V16.3889Z"
        fill="#DE4747"
      />
    </svg>
  );
};

export default DeleteIcon;
