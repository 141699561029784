import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { AreYouSureModal } from './AreYouSureModal';
import { deactivateUserAutoApplyAction } from '../../Api/actions';
import useMixpanelAutoApplyOptOutModal from '../../../../utils/mixpanelHelpers/useMixpanelAutoApplyOptOutModal';
import useMixpanelAutoApplyOptOut from '../../../../utils/mixpanelHelpers/useMixpanelAutoApplyOptOut';

export const AcceptedCase = ({ setStatus, candidateEmail, isNewComer }) => {
  const { t } = useTranslation(['application']);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [triggerEventModal, setTriggerEventModal] = useState(false);
  const [triggerEventClick, setTriggerEventClick] = useState(false);
  useMixpanelAutoApplyOptOutModal(triggerEventModal);
  useMixpanelAutoApplyOptOut(triggerEventClick);

  const showModal = () => {
    setShowConfirmModal(true);
    setTriggerEventModal(true);
  };

  const hideModal = () => {
    setShowConfirmModal(false);
    setTriggerEventModal(false);
  };

  const deactivateAutoApply = async () => {
    const data = {
      email: candidateEmail
    };
    const resp = await deactivateUserAutoApplyAction(data);
    setTriggerEventClick(true);
    if (resp?.status === 200) {
      hideModal();
      setTimeout(() => {
        setStatus(false);
      }, 300);
    }
  };

  return (
    <>
      <span className="c-coensio font-16r fw-300 centerText">
        <Trans i18nKey="autoApplySuccessText">
          You shared your profile with more than 50+ companies for their <br />
          <strong className="fw-400"> current and future job opportunities! </strong>
        </Trans>
      </span>

      {isNewComer ? null : (
        <span
          className="font-16r fw-400 pointer"
          style={{ color: '#58585899' }}
          onClick={showModal}
        >
          {t('autoApplyDisableText')}
        </span>
      )}

      <AreYouSureModal
        show={showConfirmModal}
        onClose={hideModal}
        deactivateMethod={deactivateAutoApply}
      />
    </>
  );
};
