import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { useTranslation, Trans } from 'react-i18next';

import styles from './SignupComponent.module.css';
import { ReactComponent as Tick } from '../../Images/purpleTick.svg';
import { ReactComponent as CoensioLogo } from '../../../images/Common/Coensio_newLogo.svg';
import EmailText from '../../../Common/Components/EmailText';
import CustomButton from '../../../Common/Components/CustomButton';
import { requestDemoAction, quickEmailVerificationAction } from '../../../redux/signup/actions';
import { signUpStep1ErrorMessageRedux } from '../../../redux/signup/selectors';
import {
  setSignupEmail,
  setSignUpStep1ErrorMessage,
  setSignupStep
} from '../../../redux/signup/slice';
import route from '../../../constant/route';
import isEmail from '../../../utils/isEmail';

import mixpanel from '../../../utils/mixpanel';

const CatchPhrase = ({ text }) => {
  return (
    <>
      <div className={styles.catchPhrase}>
        <div>
          <Tick className={styles.tick} />
        </div>

        <div className={styles.catchphraseText}>{text}</div>
      </div>
    </>
  );
};

const SignupComponent = () => {
  const dispatch = useDispatch();
  const errorMessage = useSelector(signUpStep1ErrorMessageRedux);

  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    setEmail('');
    setError(false);

    /* --------------- Mixpanel pageview --------------- */

    mixpanel.track('Signup Page 1 - Pageview');

    /* ------------------------------------------------- */
  }, []);

  const signUpByEmailRequest = async () => {
    setButtonLoading(true);

    dispatch(setSignupEmail(email));

    const data = {
      email: email
    };

    const validCheck = await dispatch(quickEmailVerificationAction(data));

    if (validCheck.meta.requestStatus === 'fulfilled') {
      const parsedResponse = JSON.parse(validCheck?.payload?.response);

      const isInvalidEmailFlag =
        parsedResponse?.disposable === 'true' ||
        parsedResponse?.result !== 'valid' ||
        parsedResponse?.free === 'true' ||
        parsedResponse?.domain?.includes('edu.tr');

      if (!isInvalidEmailFlag) {
        const resp = await requestDemoAction(data);

        if (resp.status === 200) {
          dispatch(setSignUpStep1ErrorMessage(null));
          dispatch(setSignupStep(2));
          setButtonLoading(false);
        } else {
          setButtonLoading(false);
          dispatch(setSignUpStep1ErrorMessage(resp.message));
        }
      } else {
        dispatch(setSignUpStep1ErrorMessage(t('enterCorpMail')));
        setButtonLoading(false);
      }
    } else {
      setButtonLoading(false);
    }

    // const validCheck = await dispatch(quickEmailVerificationAction(data));

    // if (validCheck.meta.requestStatus === 'fulfilled') {
    //   const parsedResponse = JSON.parse(validCheck?.payload?.response);

    //   const isInvalidEmailFlag =
    //     parsedResponse?.disposable === 'true' ||
    //     parsedResponse?.result !== 'valid' ||
    //     parsedResponse?.free === 'true' ||
    //     parsedResponse?.domain?.includes('edu.tr');

    //     console.log('isInvalidEmailFlag', isInvalidEmailFlag);

    //   if (!isInvalidEmailFlag) {
    //     const resp = await dispatch(inviteByEmailAction(data));
    //     if (resp.meta.requestStatus !== 'fulfilled') {
    //       setButtonLoading(false);
    //     }
    //   } else {
    //     dispatch(setSignUpStep1ErrorMessage('Please enter a corporate email'));
    //     setButtonLoading(false);
    //   }
    // } else {
    //   setButtonLoading(false);
    // }

    // if (validCheck.meta.requestStatus === 'fulfilled') {
    //   const parsedResponse = JSON.parse(validCheck?.payload?.response);

    //   const isInvalidEmailFlag =
    //     parsedResponse?.disposable === 'true' ||
    //     parsedResponse?.result !== 'valid' ||
    //     parsedResponse?.free === 'true' ||
    //     parsedResponse?.domain?.includes('edu.tr');

    //   if (!isInvalidEmailFlag) {
    //     const resp = await dispatch(signupByEmailAction(data));
    //     if (resp.meta.requestStatus !== 'fulfilled') {
    //       setButtonLoading(false);
    //     }
    //   } else {
    //     dispatch(setSignUpStep1ErrorMessage('Please enter a corporate email'));
    //     setButtonLoading(false);
    //   }
    // } else {
    //   setButtonLoading(false);
    // }
  };

  useEffect(() => {
    setError(false);
    dispatch(setSignUpStep1ErrorMessage(null));
  }, [email]);

  useEffect(() => {
    if (errorMessage) {
      setError(errorMessage);
    }
  }, [errorMessage]);

  const { t } = useTranslation(['signup']);

  return (
    <div className={styles.PageWrapper}>
      <div className={styles.CardWrapper}>
        <div className={styles.InnerCard}>
          <div className={styles.Left}>
            <CoensioLogo className={styles.logo} />
            <CatchPhrase text={t('optimizeScreening')} />
            <CatchPhrase text={t('catchCand')} />
            <CatchPhrase text={t('notSuitableCand')} />
          </div>
          <div className={styles.Right}>
            <div className={styles.signup}>
              <label className={styles.trialText}>{t('reqDemo')}</label>
              <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1.7rem' }}>
                <EmailText
                  value={email}
                  setValue={setEmail}
                  placeholder={t('email')}
                  showInvisibleError
                  errorMessage={error}
                  trimWhiteSpaces
                />
              </div>
              <CustomButton
                isDisabled={!email || !isEmail(email)}
                textField={t('submit')}
                customStyle={{ width: '100%', marginBottom: '3.2rem' }}
                size="medium"
                buttonOnClick={() => signUpByEmailRequest()}
                loading={buttonLoading}
                enterEnabled
              />
            </div>
            {/* <div className={styles.login}>
              <div className={styles.text}>{t('alreadyHaveAcc')}</div>
              <Link to={route.login}>
                <div className={styles.loginText}>{t('login')}</div>
              </Link>
            </div> */}
            <span className={styles.login} style={{ display: 'flex', gap: '4px' }}>
              <Trans i18nKey="alreadyHaveAcc" ns="signup">
                0<span className={styles.text}>1</span>
                <Link to={route.login} className={styles.loginText}>
                  <span>2</span>
                </Link>
              </Trans>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupComponent;
