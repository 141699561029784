import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import styles from './ProfileTab.module.css';
import edit from '../../../../images/Auth/Settings/billingEdit.svg';
import logout from '../../../../images/Auth/Settings/logout.svg';
import { logoutAndRedirect } from '../../../../utils/helpers';
import { userSelector } from '../../../../redux/auth/selectors';
import { getProfileAction } from '../../../../redux/plan/actions';
import EditUserModal from '../Modals/EditUserModal';
import { profileObjSelector } from '../../../../redux/plan/selectors';
import Skeleton from '../../../../Common/Components/Skeleton';

const ProfileCard = ({ name, email, surname, onClick }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.iconWrapper} onClick={onClick}>
        <img src={edit} alt="editProfile" className={styles.editIcon} />
      </div>
      <div className={styles.profileIcon}>{name && `${name?.charAt(0)}${surname?.charAt(0)}`}</div>
      <div className={styles.name}>{`${name || ''} ${
        surname ? surname?.charAt(0) + surname?.slice(1)?.toLocaleLowerCase('tr') : ''
      }`}</div>
      <div className={styles.email}>{email}</div>
    </div>
  );
};

const ProfileTab = () => {
  const user = useSelector(userSelector);
  const profile = useSelector(profileObjSelector);
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();

  const { t } = useTranslation(['myProfileTab'])

  useEffect(() => {
    if (user) {
      const rawData = {
        UserId: user.userId
      };
      dispatch(getProfileAction(rawData));
    }
  }, [user]);

  return (
    <>
      {profile ? (
        <>
          <div className={styles.contentWrapper}>
            <div className={styles.title}>{t('myProfile')}</div>
            <ProfileCard
              name={profile?.name}
              email={profile?.email}
              surname={profile?.surname}
              onClick={() => setModal(!modal)}
            />
            <div
              className={styles.row}
              onClick={() => {
                logoutAndRedirect();
              }}
            >
              <img src={logout} alt="logout" />
              <div className={styles.logout}>{t('logout')}</div>
            </div>
          </div>
          <EditUserModal
            show={modal}
            onClose={() => setModal(false)}
            user={profile}
            type="profile"
          />
        </>
      ) : (
        <Skeleton type="settingsProfile" />
      )}
    </>
  );
};

export default ProfileTab;
