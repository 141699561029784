import React from 'react';

import { useTranslation } from 'react-i18next';

import useHandlePositionSettingsTabSwitch from '../../../Utils/useHandlePositionSettingsTabSwitch';
import usePositionTabResolutionHandler from '../../../Utils/usePositionTabResolutionHandler';

export default function PositionTab() {
  usePositionTabResolutionHandler();

  const { t } = useTranslation(['positionTab'])

  const { PositionSettingsTabSwitch, PositionSettingsContent } =
    useHandlePositionSettingsTabSwitch();

  return (
    <div className="FlexColumn gap-20 center" style={{ maxWidth: '1200px' }}>
      <span className="font-24 fw-400 c-darkGray">{t('positionTitle')}</span>
      {/* <PositionSettingsTabSwitch /> */}
      <PositionSettingsContent />
    </div>
  );
}
