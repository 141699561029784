import React from 'react';
import styled from 'styled-components';
import Loading from '../Components/Loading';

export default function LoadingWrapper() {
  return (
    <LoadingWrapperStyle>
      <Loading />
    </LoadingWrapperStyle>
  );
}

const LoadingWrapperStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100dvh;
`;
