import client from '../../../constant/indexVol2';
import API from '../../../constant/apiVol2';

export const getUserAutoApplyService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getUserAutoApply, { params: data }).then(resolve).catch(reject);
  });

export const applyUserAutoApplyService = data =>
  new Promise((resolve, reject) => {
    client.post(API.applyUserAutoApply, data).then(resolve).catch(reject);
  });

export const deactivateUserAutoApplyService = data =>
  new Promise((resolve, reject) => {
    client.post(API.deactivateUserAutoApply, data).then(resolve).catch(reject);
  });

export const saveCameraShotService = data =>
  new Promise((resolve, reject) => {
    client.post(API.saveCameraShot, data).then(resolve).catch(reject);
  });

export const sendVerificationCodeService = data =>
  new Promise((resolve, reject) => {
    client.post(API.sendVerificationCode, data).then(resolve).catch(reject);
  });

export const updatePhoneNumberService = data =>
  new Promise((resolve, reject) => {
    client.post(API.updatePhoneNumber, data).then(resolve).catch(reject);
  });

export const validateVerificationCodeService = data =>
  new Promise((resolve, reject) => {
    client.post(API.validateVerificationCode, data).then(resolve).catch(reject);
  });

export const sendEmailVerificationCodeService = data =>
  new Promise((resolve, reject) => {
    client.post(API.sendEmailVerificationCode, data).then(resolve).catch(reject);
  });
