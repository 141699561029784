import React from 'react';
import MonacoEditor from '@monaco-editor/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isPassedVerificationRedux,
  sqlCreateTableCodeRedux,
  sqlQueryTypeRedux,
  sqlTestCasesRedux,
  sqlValidCasesRedux,
  sqlVerificationCodeRedux,
  verificationCodeResponseRedux
} from '../../../redux/addQuestion/selectors';
import styles from '../Styles/Sql.module.css';
import { setIsPassedVerificationRedux, setSqlVerificationCode, setVerificationCodeResponseRedux } from '../../../redux/addQuestion/slice';
import { postSqlRunCodeAction } from '../../../redux/addQuestion/actions';

function SqlVerificationCode() {
  const dispatch = useDispatch();

  const reduxSqlTestCases = useSelector(sqlTestCasesRedux);
  const reduxSqlValidCases = useSelector(sqlValidCasesRedux);
  const reduxVerificationCodeResponse = useSelector(verificationCodeResponseRedux);
  const reduxIsPassedVerification = useSelector(isPassedVerificationRedux);
  const reduxSqlVerificationCode = useSelector(sqlVerificationCodeRedux);
  const reduxSqlQueryType = useSelector(sqlQueryTypeRedux);
  const reduxSqlCreateTableCode = useSelector(sqlCreateTableCodeRedux);

  async function sendForVerification() {
    const mergedCases = [];
    reduxSqlTestCases.forEach(e => {
      mergedCases.push(e);
    })
    reduxSqlValidCases.forEach(e => {
      mergedCases.push(e);
    })

    const testCaseSetupsTemp = [];
    const checkingQueriesTemp = [];
    const resultsTemp = [];
    const weightsTemp = [];
    mergedCases.forEach(e => {
      testCaseSetupsTemp.push(e.setup.replaceAll(`\r\n`, `\n`));
      resultsTemp.push(e.result.replaceAll(`\r\n`, `\n`));
      weightsTemp.push(e.weight.replaceAll(`\r\n`, `\n`));
      if (reduxSqlQueryType !== "SELECT" && reduxSqlQueryType !== "") {
        checkingQueriesTemp.push(e.checkingQuery.replaceAll(`\r\n`, `\n`));
      }
    })

    
    const rawData = {
      inputCount: mergedCases.length,
      testCount: mergedCases.length,
      queryType: reduxSqlQueryType,
      testCaseSetups: testCaseSetupsTemp,
      results: resultsTemp,
      weights: weightsTemp,
      createTablesQuery: reduxSqlCreateTableCode,
      answerQuery: reduxSqlVerificationCode,
      checkingQueries: checkingQueriesTemp,
    }
    await dispatch(postSqlRunCodeAction(rawData));
    
  }

  const onChangeResetVerificationCode = () => {
    dispatch(setIsPassedVerificationRedux(false));
    dispatch(setVerificationCodeResponseRedux(''));
  };
  return (
    <div className={styles.SqlVerificationCodeWrapper}>
      <div className={styles.SqlVerificationCodeInfo}>
        <b>Verification Code</b> <br />
        To validate your SQL question, write a code to pass all your test cases.
      </div>
      <div className={styles.SqlVerificationCodeCompilerWrapper}>
        <div className={styles.SqlVerificationCodeCompiler}>
          <MonacoEditor
            defaultLanguage="sql"
            theme="vs-dark"
            options={{ wordWrap: 'on', minimap: { enabled: false } }}
            value={reduxSqlVerificationCode}
            onChange={e => {dispatch(setSqlVerificationCode(e)); onChangeResetVerificationCode();}}
          />
        </div>
        <div className={styles.SqlVerificationCodeTestOutput}>
          <div className={styles.SqlVerificationCodeTestOutputHeader}>
            <div style={{ 'padding-left': '10px', color: 'white' }}>Test Output</div>
            <button type="button" onClick={() => sendForVerification()}>
              Run Test
            </button>
          </div>
          <div
            className={styles.SqlVerificationCodeTestOutputResult}
            style={{ 'padding-left': '10px', color: 'white' }}
          >
            Is passed verification code:&nbsp;
            <span style={reduxIsPassedVerification ? {color: "#66d766"} : {color: "#e55454"}}>{reduxIsPassedVerification.toString().toUpperCase()}</span>
            <br />
            {reduxVerificationCodeResponse && reduxVerificationCodeResponse !== ''
              ? reduxVerificationCodeResponse === 'Running...'
                ? 'Running...'
                : reduxVerificationCodeResponse.isSuccess
                ? `Got ${reduxVerificationCodeResponse.point} point out of ${reduxVerificationCodeResponse.weight}`
                : reduxVerificationCodeResponse.errorMessage
              : 'Try and check your test'}
          </div>
        </div>
      </div>
    </div>
  );
}
export default SqlVerificationCode;
