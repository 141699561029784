/* eslint-disable no-else-return */
/* eslint-disable no-return-assign */
/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';

import CustomButton from '../../../../Common/Components/CustomButton';

import { getAssessmentDetailAfterCreateAction } from '../../../../redux/assessmentsVol2/actions';
import {
  setSettingsBannerMessage,
  setSettingsSuccess
} from '../../../../redux/assessmentDetail/slice';

import styles from './ConnectionRow.module.css';

import linkedin from '../../../../images/Connections/linkedin.svg';
import breezy from '../../../../images/Connections/breezy.svg';
import workable from '../../../../images/Connections/workable.svg';
import recruitee from '../../../../images/Connections/recruitee.svg';
import jazzhr from '../../../../images/Connections/jazzhr.svg';
import kariyer from '../../../../images/Connections/kariyer.png';
import rightIcon from '../../../../images/AssessmentDetail/rightArrow.svg';
import downIcon from '../../../../images/AssessmentDetail/downArrow.svg';
import youtube from '../../../../images/AssessmentDetail/youtube.svg';
import { ReactComponent as CopyIcon } from '../../../AddAssessment/Images/purpleCopyIcon.svg';
import { ReactComponent as copyIcon } from '../../../../images/AssessmentDetail/copy.svg';

function ConnectionRow({
  connection,
  assessmentID,
  video,
  mobile,
  createAssessment,
  updateConnection,
  isOpen = false,
  copied = () => {}
}) {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [logo, setLogo] = useState();
  const [open, setOpen] = useState(isOpen);
  const [ats, setAts] = useState(false);
  const [detailAfterCreate, setDetailAfterCreate] = useState();
  const [switchValue, setSwitchValue] = useState(1);

  const { t } = useTranslation(['assessmentDetail']);

  function handleType(int) {
    switch (int) {
      case 10:
        setName('Linkedin');
        setLogo(linkedin);
        break;
      case 20:
        setName('Linkedin Recruiter');
        setLogo(linkedin);
        break;
      case 30:
        setName('Kariyer.net');
        setLogo(kariyer);
        break;
      case 60:
        setName('Breezy');
        setLogo(breezy);
        break;
      case 70:
        setName('Recruitee');
        setLogo(recruitee);
        break;
      case 9:
        setName('Other');
        // setLogo(recruitee);
        break;
      case 80:
        setName('Workable');
        setLogo(workable);
        break;
      case 90:
        setName('JazzHR');
        setLogo(jazzhr);
        break;
      default:
        setName('');
        break;
    }
  }

  useEffect(async () => {
    const data = { CompanyAssessmentId: assessmentID };
    console.log('data connectionRow', data);
    const resp = await dispatch(getAssessmentDetailAfterCreateAction(data));
    if(connection === 30) {
      setDetailAfterCreate(resp?.payload.kariyerDto);
    } else {
      setDetailAfterCreate(resp?.payload.linkedinDto);
    }
    await handleType(connection);
    if (connection !== 10 && connection !== 20) {
      setAts(true);
    }
  }, [updateConnection]);

  async function handleCopySuccess() {
    if (mobile && createAssessment) {
      copied();
    } else {
      // set success banner true
      await dispatch(setSettingsBannerMessage(t('copiedBanner')));
      await dispatch(setSettingsSuccess(true));
    }
  }

  return (
    <div className={styles.outerWrapper}>
      <div className={mobile ? styles.containerMobile : styles.container}>
        {!mobile ? (
          <div className={styles.wrapper}>
            {name !== 'Other' ? (
              <span className={styles.logo} style={{ width: name !== 'Other' ? '20px' : 0 }}>
                <img alt="" width={18} src={logo} />
              </span>
            ) : null}
            <span className={styles.title}>{name}</span>
          </div>
        ) : !createAssessment ? (
          <div className={styles.wrapperMobile}>
            <span style={{ marginRight: '10px' }} className={styles.title}>
              {name}
            </span>

            {name !== 'Other' ? (
              <span className={styles.logo} style={{ width: name !== 'Other' ? '20px' : 0 }}>
                <img alt="" width={18} src={logo} />
              </span>
            ) : null}
          </div>
        ) : null}
        {!createAssessment ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: mobile && '20px'
            }}
            onClick={() => setOpen(!open)}
          >
            <span
              style={{ color: open && '#393939' }}
              className={mobile ? styles.connectTextMobile : styles.connectText}
            >
              <Trans
                i18nKey="howToConnectModal"
                ns="assessmentDetail"
                values={{ connectName: name }}
              >
                0<span>1</span>
                <span>2</span>
                <span>3</span>
              </Trans>
            </span>
            <div style={{ width: '12px' }}>
              <img alt="" src={open ? downIcon : rightIcon} />
            </div>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: mobile && '20px'
            }}
            onClick={() => setOpen(!open)}
          >
            <span
              style={{ color: open && '#393939' }}
              className={mobile ? styles.connectTextMobile : styles.connectText}
            >
              {/* <Trans i18nKey="howToConnect" values={{ connectName: name }}>
                {t('howToConnectModal')}
              </Trans> */}
              <span>
                <Trans
                  i18nKey="howToConnectModal"
                  ns="assessmentDetail"
                  values={{ connectName: name }}
                >
                  0<span>1</span>
                  <span>2</span>
                  <span>3</span>
                </Trans>
              </span>
            </span>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {name !== 'Other' ? (
                <span className={styles.logo} style={{ width: name !== 'Other' ? '20px' : 0 }}>
                  <img alt="" width={18} src={logo} />
                </span>
              ) : null}
              <div style={{ width: '12px' }}>
                <img alt="" src={open ? downIcon : rightIcon} />
              </div>
            </div>
          </div>
        )}
      </div>
      {open && (
        <div className={styles.openWrapper}>
          <div className={styles.tutorialWrapper} style={{ height: mobile && 'fit-content' }}>
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px' }}>
              <span style={{ fontFamily: mobile && 'Jost-300' }} className={styles.tutorialText}>
                1.&nbsp;
              </span>
              <span>
                <Trans i18nKey="watchTutorial" ns="assessmentDetail">
                  0
                  <span
                    style={{ fontFamily: mobile && 'Jost-300' }}
                    className={styles.tutorialText}
                  >
                    1
                  </span>
                  <a
                    style={{ fontFamily: mobile && 'Jost-300' }}
                    rel="noopener noreferrer"
                    target="_blank"
                    href={video}
                    className={styles.link}
                  >
                    2
                  </a>
                  <span
                    style={{ fontFamily: mobile && 'Jost-300' }}
                    className={styles.tutorialText}
                  >
                    3
                  </span>
                  4
                </Trans>
              </span>
              <span>
                <a rel="noopener noreferrer" target="_blank" href={video}>
                  <img src={youtube} alt="" className={styles.play} />
                </a>
              </span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontFamily: mobile && 'Jost-300' }} className={styles.tutorialText}>
                2.&nbsp;
              </span>
              <span style={{ fontFamily: mobile && 'Jost-300' }} className={styles.tutorialText}>
                {t('emailTemplateAsTutorial')}
              </span>
            </div>
          </div>
          <div className={styles.copyWrapper}>
            <div className={styles.tutorialText}>
              {!ats ? t('appPageLink') : t('templateEmail')}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {ats && !mobile && (
                <div className={styles.switchWrapper}>
                  <span
                    className={styles.htmlText}
                    style={{ color: switchValue === 0 ? '#7458d3' : '#5858584d' }}
                  >
                    HTML
                  </span>
                  <div
                    className={`${styles.switchContainer} ${
                      switchValue === 1 ? styles.active : ''
                    }`}
                    onClick={() => setSwitchValue(switchValue === 1 ? 0 : 1)}
                  >
                    <div
                      style={switchValue === 0 ? { marginLeft: 2 } : { marginLeft: 21 }}
                      className={styles.switchButton}
                    />
                  </div>
                  <span
                    className={styles.textText}
                    style={{ color: switchValue === 1 ? '#7458d3' : '#5858584d' }}
                  >
                    Text
                  </span>
                  <span className={styles.arrowImg} />
                </div>
              )}
              {!mobile ? (
                <CustomButton
                  size="small"
                  type={3}
                  textField={
                    ats ? (switchValue === 0 ? t('copyHTML') : t('copyText')) : t('copyLink')
                  }
                  style={{
                    marginRight: 0,
                    marginLeft: !ats ? 'auto' : '12px',
                    width: 'fit-content'
                  }}
                  Icon={!ats ? CopyIcon : null}
                  iconStyle={{ fill: 'none' }}
                  buttonOnClick={() =>
                    ats
                      ? switchValue === 0
                        ? navigator.clipboard.writeText(detailAfterCreate?.mailTemplateHtml) &&
                          handleCopySuccess()
                        : navigator.clipboard.writeText(
                            document?.getElementById('htmlToTextDiv')?.innerText
                          ) && handleCopySuccess()
                      : navigator.clipboard.writeText(detailAfterCreate?.link) &&
                        handleCopySuccess()
                  }
                />
              ) : (
                <CustomButton
                  size="small"
                  type={5}
                  textField={t('copy')}
                  style={{
                    marginRight: 0,
                    marginLeft: !ats ? 'auto' : '12px',
                    width: '100px'
                  }}
                  Icon={copyIcon}
                  iconStyle={{ fill: 'none' }}
                  buttonOnClick={() =>
                    ats
                      ? switchValue === 0
                        ? navigator.clipboard.writeText(detailAfterCreate?.mailTemplateHtml) &&
                          handleCopySuccess()
                        : navigator.clipboard.writeText(
                            document?.getElementById('htmlToTextDiv')?.innerText
                          ) && handleCopySuccess()
                      : navigator.clipboard.writeText(detailAfterCreate?.link) &&
                        handleCopySuccess()
                  }
                />
              )}
              <div
                id="htmlToTextDiv"
                dangerouslySetInnerHTML={{ __html: detailAfterCreate?.mailTemplateText }}
                style={{ display: 'none' }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ConnectionRow;
