import client from '../../constant/indexVol2';
import API from '../../constant/apiVol2';

export const getCandidateProfileService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCandidateProfile, { params: data }).then(resolve).catch(reject);
  });

export const getCandidatePositionsService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCandidatePositions, { params: data }).then(resolve).catch(reject);
  });

export const addCandidatesToFunnelService = data =>
  new Promise((resolve, reject) => {
    client.post(API.addUserToFunnel, data).then(resolve).catch(reject);
  });

export const unlockNewOpsCandidateService = data =>
  new Promise((resolve, reject) => {
    client.post(API.unlockNewOpsCandidate, data).then(resolve).catch(reject);
  });

export const newOpsCandidateCheckService = data =>
  new Promise((resolve, reject) => {
    client.get(API.newOpsCandidateCheck, { params: data }).then(resolve).catch(reject);
  });

export const getNotesService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getNotes, { params: data }).then(resolve).catch(reject);
  });

export const getNotebyIdService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getNotebyId, { params: data }).then(resolve).catch(reject);
  });

export const createNoteService = data =>
  new Promise((resolve, reject) => {
    client.post(API.createNote, data).then(resolve).catch(reject);
  });

export const deleteNoteService = data =>
  new Promise((resolve, reject) => {
    client.post(API.deleteNote, data).then(resolve).catch(reject);
  });

export const updateNoteService = data =>
  new Promise((resolve, reject) => {
    client.post(API.updateNote, data).then(resolve).catch(reject);
  });

export const getCandidateSolutionsService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCandidateSolutions, { params: data }).then(resolve).catch(reject);
  });

export const shareProfileLinkService = data =>
  new Promise((resolve, reject) => {
    client.post(API.shareProfileLink, data).then(resolve).catch(reject);
  });

export const getCandidateBackgroundService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCandidateBackground, { params: data }).then(resolve).catch(reject);
  });

export const getCandidateCVService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCandidateCV, { params: data }).then(resolve).catch(reject);
  });

export const getFilteredUserIdService = data =>
  new Promise((resolve, reject) => {
    client.post(API.getFilteredUserId, data).then(resolve).catch(reject);
  });

export const getPersonalInformationService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getPersonalInformation, { params: data }).then(resolve).catch(reject);
  });

export const getActivitiesService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getActivities, { params: data }).then(resolve).catch(reject);
  });

