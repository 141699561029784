import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';
import {
  deleteCommentAction,
  updateCommentAction,
  createCommentAction,
  getCommentsAction
} from './actions';

const candidateSlice = createSlice({
  name: 'candidate',
  initialState,
  activeFilter: 'active',
  reducers: {
    selectCandidateDetail: {
      reducer: (state, action) => {
        state.candidateDetail = action.payload;
      }
    },

    // Reject popup related states

    setShowRejectPopup: (state, action) => {
      state.showRejectPopup = action.payload;
    },
    setShowRejectSuccess: (state, action) => {
      state.showRejectSuccess = action.payload;
    }
  },
  extraReducers: builder => {
    // comment
    builder.addCase(deleteCommentAction.fulfilled, (state, action) => {
      state.commentList = action.payload;
      state.commentLoading = false;
    });
    builder.addCase(createCommentAction.fulfilled, (state, action) => {
      state.commentList = action.payload.commentList;
      state.commentLoading = false;
    });
    builder.addCase(updateCommentAction.fulfilled, (state, action) => {
      state.commentList = action.payload.commentList;
      state.commentLoading = false;
    });
    builder.addCase(deleteCommentAction.pending, state => {
      state.commentLoading = true;
    });
    builder.addCase(createCommentAction.pending, state => {
      state.commentLoading = true;
    });
    builder.addCase(updateCommentAction.pending, state => {
      state.commentLoading = true;
    });
    builder.addCase(getCommentsAction.pending, state => {
      state.commentLoading = true;
    });
    builder.addCase(getCommentsAction.fulfilled, (state, action) => {
      state.commentList = action.payload;
      state.commentLoading = false;
    });
  }
});

export const { selectCandidateDetail, setShowRejectPopup, setShowRejectSuccess } =
  candidateSlice.actions;
export default candidateSlice.reducer;
