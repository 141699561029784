import React from 'react';

const EditIcon = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.51466 14.4757C7.51918 14.3716 7.56253 14.2731 7.63614 14.1995L13.26 8.57565C13.4225 8.41315 13.6859 8.41315 13.8484 8.57565L15.2957 10.0229C15.4582 10.1854 15.4582 10.4489 15.2957 10.6114L9.67188 16.2352C9.59827 16.3089 9.49974 16.3522 9.39573 16.3567L7.88265 16.4225C7.63883 16.4331 7.43827 16.2326 7.44887 15.9887L7.51466 14.4757Z"
        fill="#7458D3"
        stroke="#7458D3"
        stroke-width="0.832192"
      />
      <rect
        x="14.4693"
        y="7.3645"
        width="1.02339"
        height="2.87898"
        rx="0.416096"
        transform="rotate(-45 14.4693 7.3645)"
        fill="white"
        stroke="#7458D3"
        stroke-width="0.832192"
      />
    </svg>
  );
};

export default EditIcon;
