import React from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { ReactComponent as PersonalIcon } from '../../../images/Auth/Settings/bannerPersonal.svg';
import { ReactComponent as CompanyIcon } from '../../../images/Auth/Settings/bannerCompany.svg';
import { ReactComponent as Logout } from '../../../images/Auth/Settings/logout.svg';

import styles from './SettingsContent.module.css';
import { logoutAndRedirect } from '../../../utils/helpers';

const SettingsContent = () => {
  const history = useHistory();

  const { t } = useTranslation(['profileBanner'])

  return (
    <>
      <div className={styles.setting}>{t('settings')}</div>
      <div className={styles.settingBlock}>
        <div
          className={styles.settingRow}
          onClick={() => {
            history.push('/settings/myprofile');
          }}
        >
          <PersonalIcon className={styles.icon} />
          <div className={styles.settingText}>{t('personal')}</div>
        </div>
        <div
          className={`${styles.settingRow}`}
          onClick={() => {
            history.push('/settings/general');
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            id="companyDiv"
          >
            <CompanyIcon className={styles.icon} />
            <div className={styles.settingText}>{t('company')}</div>
          </div>
        </div>
      </div>
      <div className={styles.borderBottom} />
      <div
        className={styles.logoutRow}
        onClick={() => {
          logoutAndRedirect();
        }}
      >
        <Logout />
        <div className={styles.logoutText}>{t('logout')}</div>
      </div>
    </>
  );
};

export default SettingsContent;
