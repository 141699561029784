import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation, Trans } from 'react-i18next';

import CloseButton from '../../Common/Components/CloseButton';
import CustomButton from '../../Common/Components/CustomButton';
import {
  contactSalesAction,
  purchaseAddOnAction,
  getBillingInfoAction
} from '../../redux/plan/actions';
import styles from '../Styles/HeadsUpModal.module.css';
import { ReactComponent as Cloud } from '../../images/Auth/Payment/helloThereIcon.svg';
import { ReactComponent as Diamond } from '../Images/Diamond.svg';
import { ReactComponent as Lock } from '../../images/Auth/Payment/lockIcon.svg';
import { ReactComponent as BlueInfo } from '../Images/blueInfoIcon.svg';
import { ReactComponent as Unlock } from '../Images/unlockHeadsUp.svg';
import { ReactComponent as UnlockMobile } from '../Images/unlockMobileHeadsUp.svg';
import hands from '../Images/handsIcon.svg';
// import handsMobile from "../Images/handMobileIcon.svg";

import { userSelector } from '../../redux/auth/selectors';
import {
  toBePurchasedAddOnRedux,
  billingInfoRedux,
  paymentFailedErrorMessageRedux
} from '../../redux/plan/selectors';
import { setPaymentMethodModalShow } from '../../redux/plan/slice';
import stylesP from '../Styles/PaymentFailedModal.module.css';
import { ReactComponent as DownArrow } from '../Images/arrow-down.svg';
import Mastercard from '../Images/mastercardIcon.svg';
import Visa from '../../images/Auth/Payment/Visa.svg';
import Amex from '../Images/Americanexpress.svg';
import redCircle from '../Images/redCircle.svg';
import { useWindowSize } from '../../utils/helpers';
import { unlockNewOpsCandidateAction } from '../../redux/candidateDetail/actions';
import MobileModal from '../../Common/Components/MobileModal/MobileModal';
import Modal from '../../Common/CustomModal/Modal';

export default function HeadsUpModal({
  show,
  onHide,
  title,
  description,
  isCustom,
  addOnCode,
  setSuccess,
  newOpData,
  failEvent = () => {}, // New op unlock candidate fail event
  successEvent = () => {} // New op unlock candidate success event
}) {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const toBePurchasedAddOn = useSelector(toBePurchasedAddOnRedux);
  const [loading, setLoading] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [messageShow, setMessageShow] = useState(true);
  const [screenW] = useWindowSize();

  const { t } = useTranslation(['planTab']);

  useEffect(() => {
    if (show) {
      setPaymentFailed(false);
    }
  }, [show]);

  async function handleButtonClick() {
    await setLoading(true);

    if (isCustom) {
      // Contact Sales
      const data = {
        userId: user?.userId,
        bundleItemCode: addOnCode
      };
      const a = await dispatch(contactSalesAction(data));
      if (a.payload) {
        await setLoading(false);
        await onHide();
        await setSuccess(true);
      }
    } else if (addOnCode === 40) {
      await dispatch(unlockNewOpsCandidateAction(newOpData)).then(async a => {
        if (a?.meta?.requestStatus === 'fulfilled') {
          // TODO: mixpanelUnlockCandidateSuccessEvent
          successEvent();
          await setLoading(false);
          await onHide();
          await setSuccess(true);
        } else {
          // TODO: mixpanelUnlockCandidateFailEvent
          failEvent({ isCustom: false });
          await setLoading(false);
          await setPaymentFailed(true);
        }
      });
    } else {
      const a = await dispatch(purchaseAddOnAction(toBePurchasedAddOn));
      if (a?.meta?.requestStatus === 'fulfilled') {
        await setLoading(false);
        await onHide();
        await setSuccess(true);
      } else {
        // fail
        await setLoading(false);
        await setPaymentFailed(true);
      }
    }
    setMessageShow(true);
  }

  // Payment Failed Variables:
  const billingInfo = useSelector(billingInfoRedux);
  const [association, setAssociation] = useState('');
  const error = useSelector(paymentFailedErrorMessageRedux);

  useEffect(() => {
    if (show) {
      const rawData = {
        CompanyId: user?.companyId
      };
      dispatch(getBillingInfoAction(rawData));
    }


    // Show contact sales popup when new op unlock check fails
    if (show && addOnCode === 40 && isCustom) {
      // TODO: mixpanelUnlockCandidateFailEvent
      failEvent({ isCustom: true });
    }
  }, [show]);

  useEffect(() => {
    if (billingInfo) {
      if (billingInfo?.cardAssociation === 'MASTER_CARD') {
        setAssociation('Mastercard');
      } else if (billingInfo?.cardAssociation === 'AMERICAN_EXPRESS') {
        setAssociation('Amex');
      } else if (billingInfo?.cardAssociation === 'VISA') {
        setAssociation('Visa');
      }
    }
  }, [billingInfo]);

  const MobilModal = (
    <MobileModal
      id="mobile-headsUp-modal"
      onClose={onHide}
      show={show}
      outsideClickClose
      contentStyle={{
        padding: '20px',
        minWidth: '375px',
        overflow: 'hidden'
      }}
    >
      <CloseButton onClick={onHide} width={24} height={24} />
      <>
        <div className={styles.titleWrapper}>
          {addOnCode === 40 ? (
            <UnlockMobile />
          ) : addOnCode === 70 ? (
            <span className={styles.successIcon}>
              <img src={hands} alt="hands-icon" />
            </span>
          ) : null}
          <div className={styles.titleMobile}>{title}</div>
        </div>
        <div className={styles.textWrapper}>
          <div className={styles.subtitleMobile}>{description}</div>
        </div>

        <div className={styles.mobileButtonWrapper}>
          {!isCustom && (
            <CustomButton
              size="mobile"
              style={{ width: '100%' }}
              textField={t('cancel')}
              buttonOnClick={() => {
                onHide();
              }}
              type={8}
            />
          )}
          <CustomButton
            size="mobile"
            loading={loading}
            style={{ width: '100%', marginBottom: '8px' }}
            type={1}
            textField={isCustom ? t('contactSales') : t('confirm')}
            buttonOnClick={() => {
              handleButtonClick();
            }}
          />
        </div>
      </>
    </MobileModal>
  );

  const WebModal = (
    <Modal
      show={show}
      onClose={onHide}
      centerHorizontally
      centerVertically
      outsideClickClose
      contentClassName={`${styles.modalContent} ${styles['modal-content']}`}
    >
      <CloseButton onClick={onHide} width={24} height={24} />
      <>
        {addOnCode === 60 ||
        addOnCode === 62 ||
        addOnCode === 65 ||
        addOnCode === 66 ||
        addOnCode === 67 ||
        addOnCode === 61 ||
        addOnCode === 63 ? (
          <Diamond className={styles.successIcon} />
        ) : addOnCode === 30 ? (
          <Lock />
        ) : addOnCode === 40 ? (
          <Unlock style={{ marginBottom: 30 }} />
        ) : addOnCode === 70 ? (
          <span className={styles.successIcon}>
            <img src={hands} alt="hands-icon" />
          </span>
        ) : addOnCode === 10 ? (
          <BlueInfo className={styles.successIcon} />
        ) : (
          <Cloud className={styles.successIcon} />
        )}

        <div className={styles.title}>{title}</div>
        <div className={styles.textWrapper}>
          <div className={styles.subtitle}>{description}</div>
        </div>
        <CustomButton
          loading={loading}
          style={{ width: '100%', marginBottom: '8px' }}
          type={1}
          textField={isCustom ? t('contactSales') : t('confirm')}
          buttonOnClick={() => {
            handleButtonClick();
          }}
        />
        {!isCustom && (
          <CustomButton
            style={{ width: '100%' }}
            textField={t('cancel')}
            buttonOnClick={() => {
              onHide();
            }}
            type={2}
          />
        )}
      </>
    </Modal>
  );

  const PaymentFailedModal = (
    <Modal
      show={show}
      onClose={onHide}
      centerHorizontally
      centerVertically
      outsideClickClose
      contentClassName={`${stylesP.modalContent} ${stylesP['modal-content']}`}
    >
      <CloseButton onClick={onHide} width={24} height={24} />
      <>
        <span className={stylesP.redCircle}>
          <img src={redCircle} alt="" />
        </span>
        <div className={stylesP.title}>{t('payFailed')}</div>
        <div className={stylesP.subtitle}>{t('cantReceivePayment')}</div>
        <DownArrow className={stylesP.downArrow} />
        <div className={stylesP.cardWrapper}>
          <div className={stylesP.cardContainer}>
            {/* <span className={stylesP.cardTitle}>{`${
              billingInfo?.cardAssociation ? association : '*****'
            }****${billingInfo?.cardLastFourDigits}`}</span> */}
            <span>
                <Trans
                  i18nKey="lastFourDigits"
                  ns="planTab"
                  values={{
                    cardAssoc: billingInfo?.cardAssociation ? association : '*****',
                    lastFourDigits: billingInfo?.cardLastFourDigits
                  }}
                >
                  0<span>1</span>
                  <span>2</span>
                  <span>3</span>
                </Trans>
              </span>
            <span className={stylesP.cardInfo}>
              <Trans
                i18nKey="expiresOnDate"
                ns="planTab"
                values={{ expireDate: billingInfo?.cardExpireDate }}
              >0<span>1</span><span>2</span></Trans>
            </span>
          </div>

          {/* {`Expires on ${billingInfo?.cardExpireDate}`} */}

          {billingInfo?.cardAssociation === 'MASTER_CARD' ? (
            <span className={stylesP.mastercard}>
              <img src={Mastercard} alt="" />
            </span>
          ) : billingInfo?.cardAssociation === 'AMERICAN_EXPRESS' ? (
            <span className={stylesP.mastercard}>
              <img src={Amex} alt="" />
            </span>
          ) : billingInfo?.cardAssociation === 'VISA' ? (
            <span className={stylesP.mastercard}>
              <img src={Visa} alt="" />
            </span>
          ) : (
            <div />
          )}
        </div>
        <div className={stylesP.errorMessage}>{messageShow ? error : ''}</div>

        <div className={stylesP.row}>
          <CustomButton
            style={{ width: screenW > 576 && '508px', marginBottom: '8px' }}
            type={1}
            textField={t('changePayMethod')}
            buttonOnClick={() => {
              onHide();
              dispatch(setPaymentMethodModalShow('change'));
            }}
          />
          <CustomButton
            style={{ width: screenW > 576 && '508px' }}
            textField={t('tryAgain')}
            buttonOnClick={async () => {
              await setMessageShow(false);
              await handleButtonClick();
            }}
            type={2}
            loading={loading}
          />
        </div>
      </>
    </Modal>
  );

  return (
    <>
      {paymentFailed ? (
        <>{PaymentFailedModal}</>
      ) : (screenW <= 576 && addOnCode === 40) || (screenW <= 576 && addOnCode === 70) ? (
        <>{MobilModal}</>
      ) : (
        <>{WebModal}</>
      )}
    </>
  );
}
