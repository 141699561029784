import { useWindowSize } from "./helpers";

function isMobile() {
  const [screenW] = useWindowSize();
  if (screenW <= 576) {
    return true;
  }
  return false;
}

export default isMobile;
