import { createSlice } from '@reduxjs/toolkit';
import {
  getCodingLanguagesAction,
  createCustomAssessmentAction,
  getRootAssessmentListActionVol2,
  updateCustomAssessmentAction,
  removeSkillBasedQuestionAction,
  updateQuestionWeightAction,
  getQuestionByFilterAction,
  addQuestionBasedQuestionAction,
  tryAssessmentAction,
  getUserListByCompanyAction,
  getQuestionDetailAction,
  inviteUser,
  getAtsListAction,
  addAssessmentActionVol2,
  getAssessmentDetailAfterCreateAction,
  applicantAssessmentSendEmailAction
} from './actions';
import initialState from './initialState';

const assessmentsVol2Slice = createSlice({
  name: 'assessmentsVol2',
  initialState,
  reducers: {
    setToInitialState2: () => initialState,
    setAssessmentId: (state, action) => {
      state.rootAssessmentId = action.payload;
    },
    setAssessmentActiveTab: (state, action) => {
      state.assessmentActiveTab = action.payload;
    },
    setDeletedId: (state, action) => {
      state.assessmentToBeDeleted = action.payload;
    },
    setCustomAssessment: (state, action) => {
      state.customAssessmentId = action.payload;
    },
    setPublishedAssessment: (state, action) => {
      state.publishedAssessment = action.payload;
    },
    setQuestionBeingAdded: (state, action) => {
      state.questionBeingAdded = action.payload;
    },
    setRootQuestionList: (state, action) => {
      state.rootAssessmentQuestionList = action.payload;
    },
    setFilterState: (state, action) => {
      state.filterState = action.payload;
    },
    setRemoveQuestionShow: (state, action) => {
      state.removeQuestionShow = action.payload;
    },
    setQuestionBeingRemoved: (state, action) => {
      state.questionBeingRemoved = action.payload;
    },
    removeFilter: (state, action) => {
      state.filterState = action.payload;
    },
    setSessionQuestion: (state, action) => {
      state.questionAddedInSession = action.payload;
    },
    setInviteUserShow: (state, action) => {
      state.inviteUserShow = action.payload;
    },
    setAuthorizedHm: (state, action) => {
      state.authorizedHMList = action.payload;
    },
    setAuthorizedRec: (state, action) => {
      state.authorizedRecList = action.payload;
    },
    setFilteredRootAssessmentList: (state, action) => {
      state.filteredRootAssessmentList = action.payload;
    },
    setNewlyInvitedIDList: (state, action) => {
      state.newlyInvitedIDList = action.payload;
    },
    setFilterLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setAuthorizedUsers: (state, action) => {
      state.authorizedUsers = action.payload;
    },

    setPageIndex: (state, action) => {
      state.pageIndex = action.payload;
    },

    setIBEScored: (state, action) => {
      state.ibeScoredRedirect = action.payload;
    },

    setSentMailSuccess: (state, action) => {
      state.sentMailSuccess = action.payload;
    },

    setSentMailRejected: (state, action) => {
      state.mailRejected = action.payload;
    },

    setSelectedRootAssessmentQuestionList: (state, action) => {
      state.selectedRootAssessmentQuestionList = action.payload;
    },

    setContinueToAssessmentCustomization: (state, action) => {
      state.continueToAssessmentCustomization = action.payload;
    },
    setUpdateCustomAssessmentCheck: (state, action) => {
      state.updateCustomAssessmentCheck = action.payload;
    },
    setContinueToAssessmentDetail: (state, action) => {
      state.continueToAssessmentDetail = action.payload;
    },
    resetCandidateQuestionList: state => {
      state.candidateQuestionList = null;
    },

    setRemoveSkillBasedError: (state, action) => {
      state.removeSkillBasedError = action.payload;
    },

    setMostPopularRootAssessmentList: (state, action) => {
      state.mostPopularRootAssessmentList = action.payload;
    },

    resetMostPopularRootAssessmentList: state => {
      state.mostPopularRootAssessmentList = [];
    },

    setSelectedRootAssmFilters: (state, action) => {
      state.selectedRootAssmFilters = action.payload;
    },
    setAssessmentAddedBannerShow: (state, action) => {
      state.assessmentAddedBannerShow = action.payload;
    },
    setAssessmentAddedBannerText: (state, action) => {
      state.assessmentAddedBannerText = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(getRootAssessmentListActionVol2.pending, state => {
      state.filteredRootAssessmentList = [];
      state.mostPopularRootAssessmentList = [];
    });

    builder.addCase(createCustomAssessmentAction.fulfilled, (state, action) => {
      state.customAssessmentId = action.payload.rootAssessmentId;
      state.rootAssessmentQuestionList = action.payload.questionList;
      state.continueToAssessmentCustomization = true;
    });
    builder.addCase(createCustomAssessmentAction.pending, state => {
      state.continueToAssessmentCustomization = 'pending';
    });
    builder.addCase(createCustomAssessmentAction.rejected, state => {
      state.continueToAssessmentCustomization = false;
    });

    builder.addCase(getCodingLanguagesAction.fulfilled, (state, action) => {
      state.codingLanguageList = action.payload;
    });

    builder.addCase(removeSkillBasedQuestionAction.fulfilled, (state, action) => {
      state.rootAssessmentQuestionList = action.payload.questionList;
      state.questionAddedInSession = true;
      state.removeSkillBasedError = false;
    });

    builder.addCase(removeSkillBasedQuestionAction.pending, state => {
      state.removeSkillBasedError = null;
    });

    builder.addCase(removeSkillBasedQuestionAction.rejected, state => {
      state.removeSkillBasedError = true;
    });

    builder.addCase(updateCustomAssessmentAction.fulfilled, (state, action) => {
      state.rootAssessmentQuestionList = action.payload.questionList;
      localStorage.setItem(
        'Mixpanel_AssessmentCustomizationRootAssessmentName',
        action.payload.rootAssessmentName
      );
      state.updateCustomAssessmentCheck = true;
    });
    builder.addCase(updateCustomAssessmentAction.pending, state => {
      state.updateCustomAssessmentCheck = 'pending';
    });
    builder.addCase(updateCustomAssessmentAction.rejected, state => {
      state.updateCustomAssessmentCheck = false;
    });

    builder.addCase(updateQuestionWeightAction.fulfilled, (state, action) => {
      state.rootAssessmentQuestionList = action.payload.questionList;
    });

    builder.addCase(getQuestionByFilterAction.fulfilled, (state, action) => {
      state.candidateQuestionList = action.payload.questionList;
      state.maxPageCount = action.payload.totalPageCount;
      state.isLoading = false;
    });
    builder.addCase(getQuestionByFilterAction.rejected, state => {
      state.candidateQuestionList = [];
      state.isLoading = true;
    });
    builder.addCase(addQuestionBasedQuestionAction.fulfilled, (state, action) => {
      state.rootAssessmentQuestionList = action.payload;
      state.questionAddedInSession = true;
    });
    builder.addCase(tryAssessmentAction.fulfilled, (state, action) => {
      state.tryAssessmentQuestions = action.payload;
    });

    builder.addCase(getUserListByCompanyAction.fulfilled, (state, action) => {
      state.allUserList = action.payload;
      state.invitedAllUserList = action.payload?.filter(x => x.isActive === 0);
      state.HMUserList = action.payload?.filter(x => x.userGroupType === 'Hiring Manager');
      state.RecUserList = action.payload?.filter(x => x.userGroupType !== 'Hiring Manager');
      state.invitedHMList = action.payload?.filter(
        x => x.isActive === 0 && x.userGroupType === 'Hiring Manager'
      );
      state.invitedRecList = action.payload?.filter(
        x => x.isActive === 0 && x.userGroupType !== 'Hiring Manager'
      );
    });

    builder.addCase(getQuestionDetailAction.fulfilled, (state, action) => {
      state.questionDetail = action.payload;
    });
    builder.addCase(inviteUser.fulfilled, (state, action) => {
      state.userList = action.payload.companyUserList;
      state.newlyInvitedID = action.payload.userId;
    });
    builder.addCase(getAtsListAction.fulfilled, (state, action) => {
      state.AtsList = action.payload.filter(x => x.isAts);
      state.connectionList = action.payload.filter(x => !x.isAts);
    });

    builder.addCase(addAssessmentActionVol2.fulfilled, (state, action) => {
      state.CompanyAssessmentId = action.payload.companyAssessmentId; // pass the assessment id
      localStorage.setItem('addAssessmentCompanyId', action.payload.companyAssessmentId);
      state.continueToAssessmentDetail = true;
    });
    builder.addCase(addAssessmentActionVol2.rejected, state => {
      state.continueToAssessmentDetail = false;
      state.CompanyAssessmentId = null;
      localStorage.removeItem('addAssessmentCompanyId');
    });
    builder.addCase(addAssessmentActionVol2.pending, state => {
      state.continueToAssessmentDetail = null;
      state.CompanyAssessmentId = null;
      localStorage.removeItem('addAssessmentCompanyId');
    });

    builder.addCase(getAssessmentDetailAfterCreateAction.fulfilled, (state, action) => {
      state.assessmentDetailAfterCreate = action.payload;
    });
    builder.addCase(applicantAssessmentSendEmailAction.fulfilled, (state, action) => {
      state.scoredEmailCount = action.payload.scoredEmailCount;
      state.invitedCount = action.payload.totalEmailCount - action.payload.scoredEmailCount;
      state.sentMailSuccess = true;
      state.mailRejected = false;
    });
    builder.addCase(applicantAssessmentSendEmailAction.pending, state => {
      state.scoredEmailCount = 0;
      state.invitedCount = 0;
      state.mailRejected = false;
      state.sentMailSuccess = false;
    });
    builder.addCase(applicantAssessmentSendEmailAction.rejected, state => {
      state.sentMailSuccess = false;
      state.mailRejected = true;
    });
  }
});

export const {
  setAssessmentId,
  setAssessmentActiveTab,
  setDeletedId,
  setCustomAssessment,
  setPublishedAssessment,
  setQuestionBeingAdded,
  setRootQuestionList,
  setFilterState,
  setRemoveQuestionShow,
  setQuestionBeingRemoved,
  removeFilter,
  setSessionQuestion,
  setInviteUserShow,
  setAuthorizedHm,
  setAuthorizedRec,
  setFilteredRootAssessmentList,
  setNewlyInvitedIDList,
  setAuthorizedUsers,
  setToInitialState2,
  setPageIndex,
  setFilterLoading,
  setIBEScored,
  setSentMailSuccess,
  setSentMailRejected,
  setSelectedRootAssessmentQuestionList,
  setContinueToAssessmentCustomization,
  setUpdateCustomAssessmentCheck,
  setContinueToAssessmentDetail,
  resetCandidateQuestionList,
  setRemoveSkillBasedError,
  setMostPopularRootAssessmentList,
  resetMostPopularRootAssessmentList,
  setSelectedRootAssmFilters,
  setAssessmentAddedBannerShow,
  setAssessmentAddedBannerText
} = assessmentsVol2Slice.actions;

export default assessmentsVol2Slice.reducer;
