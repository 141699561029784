import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { settingsPageErrorMessage, settingsPageErrorShow } from '../../redux/auth/selectors';
import InfoBanner from '../../Common/Components/InfoBanner';
import { ReactComponent as Error } from '../../images/Common/errorSubmit.svg';
import { setSettingsPageErrorShow } from '../../redux/auth/slice';

export default function useSettingsPageErrorBanner() {
  const dispatch = useDispatch();
  const errorText = useSelector(settingsPageErrorMessage);
  const errorShow = useSelector(settingsPageErrorShow);

  const SettingsPageInfoBanner = () => {
    return (
      <InfoBanner
        CustomIcon={Error}
        text={errorText}
        show={errorShow}
        setStatus={e => dispatch(setSettingsPageErrorShow(e))}
      />
    );
  };

  return { SettingsPageInfoBanner };
}
