import React from 'react';
import { useSelector } from 'react-redux';
import { nextButtonLoadingRedux } from '../../../../redux/AssessmentSession/selectors';
import styles from './multipleChoiceQuestion.module.css';

const IndividualOption = ({ index, selectedIndex, text, select, imageOpt }) => {
  const alignedText = item => {
    let temp = item;
    temp = temp.replaceAll('text-align:right;', 'margin-left:auto;');
    temp = temp.replaceAll('text-align:left;', 'margin-right:auto;');
    temp = temp.replaceAll('text-align:none;', 'margin-right:auto;margin-left:auto;');
    return temp;
  };

  return (
    <div className={styles.IndividualOptionWrapper}>
      <div className={`${styles.Checkbox}`} onClick={() => select(index)}>
        {index === selectedIndex && <div className={styles.SelectedCheckbox} />}
      </div>
      {imageOpt ? (
        <div
          className={styles.OptionText}
          onClick={() => select(index)}
          dangerouslySetInnerHTML={{ __html: alignedText(text) }}
        />
      ) : (
        <div className={styles.OptionText} onClick={() => select(index)}>
          {text}
        </div>
      )}
    </div>
  );
};

const MultipleChoiceQuestion = ({
  qData,
  qDesc,
  headersHeight,
  selected,
  mcMobileThreshold,
  screenWidth
}) => {
  const selectOption = index => {
    if (index === selected[0]) {
      selected[1](-1);
    } else {
      selected[1](index);
    }
  };

  const buttonLoading = useSelector(nextButtonLoadingRedux);
  

  return (
    <div
      className={styles.MultipleChoiceQuestionBodyWrapper}
      style={{ height: `calc(100vh - ${headersHeight}px)`, pointerEvents: buttonLoading ? "none" : "initial", filter: buttonLoading ? "blur(4px)" : "blur(0)" }}
    >
      <div className={styles.MultipleChoiceOverflowWrapper}>
        <div
          className={
            screenWidth > mcMobileThreshold
              ? styles.MultipleChoiceQuestionDetailsWrapper
              : styles.MultipleChoiceQuestionDetailsWrapperMobile
          }
        >
          <div
            className={styles.MultipleChoiceQuestionDescription}
            dangerouslySetInnerHTML={{ __html: qDesc }}
          />
          <div className={styles.MultipleChoiceOptionsWrapper}>
            {qData.optionsJson.map((e, i) => (
              <IndividualOption
                index={i}
                selectedIndex={selected[0]}
                text={e}
                select={selectOption}
                imageOpt={qData.imageOption}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultipleChoiceQuestion;
