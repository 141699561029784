import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { currentPlanRedux, currentUsageRedux } from '../../../redux/plan/selectors';
import { userSelector } from '../../../redux/auth/selectors';
import { getCurrentUsageAction } from '../../../redux/plan/actions';
import Skeleton from '../../Components/Skeleton';
import styles from './CurrentUsage.module.css';
import ProgressCircle from '../../../Candidate/Components/AnimatedProgressBar';
import { setShowPackageDetail } from '../../../redux/plan/slice';

const CurrentUsageContent = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const currentUsage = useSelector(currentUsageRedux);
  const currentPlan = useSelector(currentPlanRedux);
  const user = useSelector(userSelector);

  const { t } = useTranslation(['profileBanner'])

  useEffect(async () => {
    setLoading(true);
    const rawData = {
      CompanyId: user.companyId
    };
    await dispatch(getCurrentUsageAction(rawData));
    setLoading(false);
  }, []);

  return (
    <>
      <div className={styles.titleRow}>
        <div className={styles.current}>{t('currentUsage')}</div>
        {currentPlan?.isCustom === false && (
          <div className={styles.plans} onClick={() => dispatch(setShowPackageDetail(true))}>
            See plans
          </div>
        )}
      </div>
      <div className={styles.usageBlock}>
        <div className={`${styles.usageRow}`}>
          {loading ? (
            <Skeleton type="currentUsage" />
          ) : (
            <div className={styles.currentUsageItemWrapper}>
              <text className={styles.planNames}>{t('assm')}</text>
              <ul>
                <li>
                  <div className={styles.contentWrapper}>
                    <text>{t('published')}</text>
                    <div className={styles.content}>
                      <div className={styles.available}>
                        {`${currentUsage?.usedAssessmentCount}/${currentUsage?.allowedAssessmentCount}`}
                      </div>
                      <div className={styles.circleContainer}>
                        <ProgressCircle
                          style={{ margin: 0, width: '1.8rem', height: '1.8rem' }}
                          score={
                            currentUsage?.allowedAssessmentCount
                              ? currentUsage?.usedAssessmentCount >
                                currentUsage?.allowedAssessmentCount
                                ? 100
                                : (currentUsage?.usedAssessmentCount /
                                    currentUsage?.allowedAssessmentCount) *
                                  100
                              : 0
                          }
                        />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          )}
        </div>
        <div className={styles.usageRow}>
          {loading ? (
            <Skeleton type="currentUsage" />
          ) : (
            <div className={styles.currentUsageItemWrapper}>
              <text className={styles.planNames}>{t('cand')}</text>
              <ul>
                <li>
                  <div className={styles.contentWrapper}>
                    <text>{t('applied')}</text>
                    <div className={styles.content}>
                      <div className={styles.available}>
                        {`${currentUsage?.usedCandidates}/${currentUsage?.allowedCandidates}`}
                      </div>
                      <div className={styles.circleContainer}>
                        <ProgressCircle
                          style={{ margin: 0, width: '1.8rem', height: '1.8rem' }}
                          score={
                            currentUsage?.allowedCandidates
                              ? currentUsage?.usedCandidates > currentUsage?.allowedCandidates
                                ? 100
                                : (currentUsage?.usedCandidates / currentUsage?.allowedCandidates) *
                                  100
                              : 0
                          }
                        />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CurrentUsageContent;
