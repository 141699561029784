import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import styles from '../Styles/QuestionBasedMiddleColumn.module.css';
import SearchBar from '../../../Common/Components/SearchBar';
import FilteredQuestion from './FilteredQuestion';
import {
  candidateQuestionList,
  customAssessmentId,
  filterState,
  loading,
  maxPageCount,
  pageIndex,
  assessmentAddedBannerTextSelector,
  assessmentAddedBannerShowSelector
} from '../../../redux/assessmentsVol2/selectors';
import { getQuestionByFilterAction } from '../../../redux/assessmentsVol2/actions';
import {
  setAssessmentAddedBannerShow,
  setAssessmentAddedBannerText,
  setFilterLoading,
  setFilterState,
  setPageIndex
} from '../../../redux/assessmentsVol2/slice';
import Loading from '../../../Common/Components/Loading';
import InfoBanner from '../../../Common/Components/InfoBanner';
import { useWindowSize } from '../../../utils/helpers';
import TabSwitch from '../../../Common/Components/TabSwitch';
import useEditDurationHandler from '../Utils/useEditDurationHandler';

export default function QuestionBasedMiddleColumn({
  resetCurrentListFlag,
  setResetCurrentFlagList,
  resetScrollPositionFlag,
  setResetScrollPositionFlag,
  resetFilterFlag
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['questionBasedAssm'])

  const newcomingQList = useSelector(candidateQuestionList);

  const filter = useSelector(filterState);
  const id = useSelector(customAssessmentId);
  const maxPageNo = useSelector(maxPageCount);
  const isLoading = useSelector(loading);
  const myPageIndex = useSelector(pageIndex);

  const addedText = useSelector(assessmentAddedBannerTextSelector);
  const addedShow = useSelector(assessmentAddedBannerShowSelector);

  const filteredQListRef = useRef();
  const qPerPAge = 50;
  const url = window.location.href;
  const lastSegment = url.split('/').pop();

  const [qList, setQList] = useState(newcomingQList);
  const [search, setSearch] = useState('');
  const [change, setChange] = useState(false);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  const [isScrollAllowed, setIsScrollAllowed] = useState(true);

  useEffect(() => {
    if (resetFilterFlag) {
      setSearch('');
    }
  }, [resetFilterFlag]);

  const handleScroll = async () => {
    if (resetScrollPositionFlag) {
      setLastScrollPosition(false);
      setResetScrollPositionFlag(false);
    } else if (!isScrollAllowed) {
      filteredQListRef?.current.scrollTo({ top: lastScrollPosition });
    } else {
      const { scrollTop, scrollHeight, clientHeight } = filteredQListRef?.current;
      if (filteredQListRef?.current) {
        if (
          scrollTop + clientHeight < scrollHeight + 5 &&
          scrollTop + clientHeight > scrollHeight - 5 &&
          qList.length / qPerPAge === myPageIndex &&
          qList.length >= 50 &&
          maxPageNo > qList.length % 50
        ) {
          const myIndex = myPageIndex + 1;
          dispatch(setPageIndex(myIndex));
          setLastScrollPosition(scrollTop);

          await dispatch(
            getQuestionByFilterAction({
              ...filter,
              pageSize: qPerPAge,
              currentPage: myIndex,
              rootAssessmentId: lastSegment
            })
          );
        }
      }
    }
  };

  const saveLastScrollPos = () => {
    const { scrollTop } = filteredQListRef?.current || {};
    setLastScrollPosition(scrollTop);
  };

  useEffect(() => {
    if (resetCurrentListFlag) {
      setQList(newcomingQList);
    } else {
      if (newcomingQList && myPageIndex === 1) {
        setQList(newcomingQList);
        filteredQListRef?.current.scrollTo({ top: 0 });
      }
      if (qList && myPageIndex !== 1) {
        newcomingQList?.forEach(item => {
          setQList(current => [...current, item]);
        });
      }
    }

    filteredQListRef?.current.scrollTo({ top: lastScrollPosition });
    setIsScrollAllowed(true);
    setTimeout(() => {
      setChange(!change);
      setResetCurrentFlagList(false);
    }, 50);
  }, [JSON.stringify(newcomingQList)]);

  const [screenWidth] = useWindowSize();
  const [questionMaxWidth, setQuestionMaxWidth] = useState();
  const WRAPPER_PADDING_SIDES = 20;

  useEffect(() => {
    // Timeout is added to match the animation
    // Animation delay 400ms => screenWidth update delay 200ms + timeout 200mx
    setTimeout(() => {
      const middlePanelWidth = document
        .getElementById('question-based-middle-column-wrapper')
        ?.getBoundingClientRect()?.width;
      setQuestionMaxWidth(middlePanelWidth - 2 * WRAPPER_PADDING_SIDES);
    }, 200);
  }, [screenWidth]);

  const searchQuestion = () => {
    dispatch(setFilterLoading(true));
    setLastScrollPosition(false);
    setResetScrollPositionFlag(true);
    dispatch(setPageIndex(1));
    dispatch(
      setFilterState({
        ...filter,
        rootAssessmentId: id,
        searchParameter: search,
        currentPage: 1
      })
    );
    dispatch(
      getQuestionByFilterAction({
        ...filter,
        rootAssessmentId: id,
        searchParameter: search,
        currentPage: 1
      })
    );
  };

  const tabSwitchNameList = [t('coensioLib'), t('myCompLib')];
  const [activeTabIndex, setActiveTabIndex] = useState(null);
  const tabSwitchFunctionList = [...Array(tabSwitchNameList?.length)]
    .map((x, index) => index)
    .map(x => () => setActiveTabIndex(x));

  useEffect(() => {
    if (activeTabIndex !== null) {
      dispatch(setFilterLoading(true));

      dispatch(setPageIndex(1));
      setSearch('');
      dispatch(
        setFilterState({
          ...filter,
          rootAssessmentId: id,
          isCustom: !!activeTabIndex,
          currentPage: 1,
          searchParameter: ''
        })
      );

      setLastScrollPosition(false);
      setResetScrollPositionFlag(true);

      dispatch(
        getQuestionByFilterAction({
          ...filter,
          rootAssessmentId: id,
          isCustom: !!activeTabIndex,
          currentPage: 1,
          searchParameter: ''
        })
      );
    }
  }, [activeTabIndex]);

  const { EditQuestionDurationModal, setEditDurationQuestionData } = useEditDurationHandler({});

  return (
    <>
      <div style={{ padding: '16px 20px 0px' }}>
        <TabSwitch tabNameList={tabSwitchNameList} onClickFuncList={tabSwitchFunctionList} />
      </div>
      <div
        id="question-based-middle-column-wrapper"
        className="FlexRow verticallyCenter w-100"
        style={{
          padding: `16px ${WRAPPER_PADDING_SIDES}px 8px`,
          background: 'rgba(244, 244, 244, 0.6)'
        }}
      >
        <span className="fw-400 font-18 c-lightGray">{t('questionsTitle')}</span>
        <SearchBar
          style={{ marginLeft: 'auto', marginRight: '-10px' }}
          isExtended={1}
          inputFieldValue={search}
          inputFieldOnChange={event => {
            setSearch(event.target.value);
            if (event.target.value === '') {
              dispatch(setFilterLoading(true));
              setLastScrollPosition(false);
              setResetScrollPositionFlag(true);
              dispatch(setPageIndex(1));
              dispatch(
                setFilterState({
                  ...filter,
                  rootAssessmentId: id,
                  searchParameter: event.target.value,
                  currentPage: 1
                })
              );
              dispatch(
                getQuestionByFilterAction({
                  ...filter,
                  rootAssessmentId: id,
                  searchParameter: event.target.value,
                  currentPage: 1
                })
              );
            }
          }}
          inputFieldOnKeyDown={event => {
            if (event.key === 'Enter') {
              searchQuestion();
            }
          }}
          color="white"
          size="xsmall"
          extendedIconOnClick={() => {
            searchQuestion();
          }}
          inputFieldPlaceholder={t('search')}
        />
      </div>
      <div
        className="w-100 h-100 overflowAuto"
        onScroll={() => handleScroll()}
        ref={filteredQListRef}
        changeProp={change}
        id="filtered-questions"
        style={{
          padding: `16px ${WRAPPER_PADDING_SIDES - 8}px 30px ${WRAPPER_PADDING_SIDES}px`,
          background: 'rgba(244, 244, 244, 0.6)',
          pointerEvents: isScrollAllowed ? 'initial' : 'none',
          opacity: isScrollAllowed ? '1' : '0.4'
        }}
      >
        {!isLoading &&
          qList?.map((question, index) => (
            <FilteredQuestion
              item={question}
              language={question.questionLanguage}
              difficulty={question.difficulty}
              isIncluded={question.isIncluded}
              questionDuration={question.questionDuration}
              questionTypeId={question.questionTypeId}
              title={question.title}
              questionId={question.questionId}
              skills={question.codingLanguages}
              index={index + 1}
              setScrollPos={saveLastScrollPos}
              disableScrollTemp={setIsScrollAllowed}
              isScrollAllowed={isScrollAllowed}
              search={search}
              allowedMaxWidth={questionMaxWidth}
              setEditDurationQuestionData={setEditDurationQuestionData}
            />
          ))}
        {!isLoading && qList?.length === 0 && (
          <div className={styles.loading}>{t('noQuestionAvailable')}</div>
        )}
        {isLoading && (
          <div className={styles.loading}>
            <Loading />
          </div>
        )}
      </div>
      <InfoBanner
        fromBottom={false}
        text={addedText}
        show={addedShow}
        setStatus={() => {
          dispatch(setAssessmentAddedBannerShow(false));
          dispatch(setAssessmentAddedBannerText(''));
        }}
      />
      {/* <EditQuestionDurationModal /> */}
      {EditQuestionDurationModal}
    </>
  );
}
