import client from '../../../constant/indexVol2';
import API from '../../../constant/apiVol2';

export const triggerAssessmentService = data =>
  new Promise((resolve, reject) => {
    client.post(API.triggerAssessment, data).then(resolve).catch(reject);
  });

export const getScreeningFiltersService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getScreeningFilters, { params: data }).then(resolve).catch(reject);
  });

export const getSchoolsService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getSchools, { params: data }).then(resolve).catch(reject);
  });

export const getIndustriesService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getIndustries, { params: data }).then(resolve).catch(reject);
  });

export const getFiltersService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getFilters, { params: data }).then(resolve).catch(reject);
  });

export const saveFilterService = data =>
  new Promise((resolve, reject) => {
    client.post(API.saveFilter, data).then(resolve).catch(reject);
  });

export const renameFilterService = data =>
  new Promise((resolve, reject) => {
    client.post(API.renameFilter, data).then(resolve).catch(reject);
  });

export const deleteFilterService = data =>
  new Promise((resolve, reject) => {
    client.post(API.deleteFilter, data).then(resolve).catch(reject);
  });
export const getFilterMajorsService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getFilterMajors, { params: data }).then(resolve).catch(reject);
  });

export const getCandidateDataExportService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCandidateDataExport, { params: data }).then(resolve).catch(reject);
  });
