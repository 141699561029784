import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import RemoveQuestionModal from '../Modals/RemoveQuestionModal';
import QuestionBasedFilterBody from '../Components/QuestionBasedFilterBody';
import AddCodingModal from '../Modals/AddCodingModal';
import { removeQuestionShow, questionBeingAdded } from '../../../redux/assessmentsVol2/selectors';
import {
  setRemoveQuestionShow,
  setQuestionBeingAdded,
  setPublishedAssessment
} from '../../../redux/assessmentsVol2/slice';
import ConfimationPopup from '../../../Common/Components/ConfimationPopup';
import DeleteAssessmentModal from '../Modals/DeleteAssessmentModal';
import PageContainer from '../../../Common/Pages/PageContainer';

export default function QuestionBasedAssessmentPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(null);
  const showRemoveQModal = useSelector(removeQuestionShow);
  const toBeAddedQ = useSelector(questionBeingAdded);

  const Modals = (
    <>
      <DeleteAssessmentModal
        show={showDeleteModal}
        onHide={() => {
          setShowDeleteModal(false);
        }}
        setConfirmedNavigation={setConfirmedNavigation}
      />
      <ConfimationPopup
        when
        pathname={history.location.pathname}
        deleteModal={setShowDeleteModal}
        confirmedNavigation={confirmedNavigation}
        setConfirmedNavigation={setConfirmedNavigation}
        navigate={path => history.push(path)}
        shouldBlockNavigation={clocation => {
          if (
            clocation.pathname === location.pathname ||
            clocation.pathname.startsWith('/assessment-customization/')
          ) {
            dispatch(setPublishedAssessment(false));
            return false;
          }
          return true;
        }}
      />
      <RemoveQuestionModal
        show={showRemoveQModal}
        onHide={() => dispatch(setRemoveQuestionShow(false))}
      />
      <AddCodingModal
        show={toBeAddedQ && toBeAddedQ.type === 'Coding' && !toBeAddedQ.isCustom}
        onHide={() => {
          dispatch(setQuestionBeingAdded(null));
        }}
      />
    </>
  );

  return (
    <>
      <PageContainer isScrollAvailable={false}>
        <QuestionBasedFilterBody />
      </PageContainer>
      {Modals}
    </>
  );
}
