import React from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { ReactComponent as Back } from '../../../images/Auth/Settings/backButtonSettings.svg';

const SettingsTitle = () => {
  const history = useHistory();

  const { t } = useTranslation(['settingsBar']);

  return (
    <div
      className="FlexRow verticallyCenter gap-18"
      style={{ marginTop: '24px', marginBottom: '10px' }}
    >
      <Back
        className="FlexRow verticallyCenter pointer"
        style={{
          width: '17px',
          height: '17px'
        }}
        onClick={() => history.goBack()}
      />
      <span className="fw-400 font-24 c-darkGray noSelect">{t('settings')}</span>
    </div>
  );
};

export default SettingsTitle;
