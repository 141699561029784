import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import CustomButton from '../../../Common/Components/CustomButton';
import { removeSkillBasedQuestionAction } from '../../../redux/assessmentsVol2/actions';
import { questionBeingRemoved } from '../../../redux/assessmentsVol2/selectors';
import {
  setRemoveQuestionShow,
  setQuestionBeingRemoved,
  setAssessmentAddedBannerText,
  setAssessmentAddedBannerShow
} from '../../../redux/assessmentsVol2/slice';
import styles from '../Styles/RemoveQuestionModal.module.css';
import Modal from '../../../Common/CustomModal/Modal';

export default function RemoveQuesionModal({
  show = false,
  onHide = () => {},
}) {
  const dispatch = useDispatch();
  const questionToBeRemoved = useSelector(questionBeingRemoved);
  const [loading, setLoading] = useState(false);
  const handleRemoveQuestion = async () => {
    setLoading(true);
    const a = await dispatch(removeSkillBasedQuestionAction(questionToBeRemoved));
    if (a) {
      dispatch(setRemoveQuestionShow(false));
      dispatch(setQuestionBeingRemoved(null));
      dispatch(setAssessmentAddedBannerText(t('qSuccessfullyRemoved')));
      dispatch(setAssessmentAddedBannerShow(true));
      // dispatch(setShow({ type: 2, appear: true }));
      setLoading(false);
    }
  };

  const { t } = useTranslation(['questionBasedAssm'])

  useEffect(() => {
    if (!show) {
      setLoading(false);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onClose={onHide}
      centerHorizontally
      centerVertically
      outsideClickClose
      contentClassName={styles.modalContent}
    >
      <div className={styles.title}>{t('rmQuestion')}</div>
      <div className={styles.subtitle}>{t('rmQuestionText')}</div>
      <div className={styles.row}>
        <CustomButton
          type={2}
          textField={t('cancel')}
          buttonOnClick={onHide}
          style={{ marginRight: '4px' }}
        />
        <CustomButton
          textField={t('remove')}
          buttonOnClick={() => {
            handleRemoveQuestion();
            onHide();
          }}
          type={6}
          loading={loading}
        />
      </div>
    </Modal>
  );
}
