import React from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import { ASSESSMENT_FLOW_MOBILE_THRESHOLD } from '../../../utils/helpers';
import CoensioLogo from './Coensio_expiredLogo.svg';
import oopsImage from './expiredInvitedUserImg.svg';
import CustomButton from '../../../Common/Components/CustomButton';
import styles from './ExpiredInvitedUserComponent.module.css';
import route from '../../../constant/route';

const ExpiredInvitedUserComponent = () => {
  return (
    <PageWrapper>
      <CardWrapper>
        <BigCard>
          <InnerCard>
            <div className={`${styles.Panel} ${styles.Left}`}>
              <div className={styles.logoWrapper}>
                <img className={styles.companyLogo} src={CoensioLogo} alt="coensioLogo" />
              </div>
              <div className={styles.imageWrapper}>
                <img className={styles.oopsImg} src={oopsImage} alt="oops" />
              </div>
            </div>
            <div className={`${styles.Panel} ${styles.Right}`}>
              <ExpiredRedirectToLogin />
            </div>
          </InnerCard>
        </BigCard>
      </CardWrapper>
    </PageWrapper>
  );
};

export default ExpiredInvitedUserComponent;

const ExpiredRedirectToLogin = () => {
  return (
    <>
      <div className={styles.Header}>Your account is created!</div>
      <div className={styles.Subtext}>
        You have already created an account with this link. Please login to start using your
        account.
      </div>
      <Link to={route.login}>
        <CustomButton textField="Login" customStyle={{ width: '100%' }} />
      </Link>
    </>
  );
};

const PageWrapper = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;
  flex-direction: column;
  position: relative;
  justify-content: center;
  height: 100%;
  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    background-color: white;
    justify-content: space-between;
  }
`;

const CardWrapper = styled.div`
  width: 100%;
  height: 410px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    justify-content: flex-start;
    padding: 2.4rem;
    padding-top: 28px;
    height: fit-content;
  }
`;

const BigCard = styled.div`
  max-width: 1000px; // Margin should be bigger if there screen is bigger
  min-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px; // If less change to mobile version
  background: #ffffff;
  display: flex;
  margin-left: 2rem;
  width: 60%; // To try the continuous scaling
  margin-right: 2rem;
  flex-direction: column;
  padding: 3.2rem;
  justify-content: center;
  box-shadow: 0px 1px 24px rgba(131, 131, 131, 0.1);

  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    width: 100%;
    max-width: none; // Bunlar nasıl daha iyi yapılır
    max-height: none;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    min-width: 0;
    box-shadow: none;
  }
`;

const InnerCard = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
`;
