import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import InfoBanner from '../../../Common/Components/InfoBanner';
import { invitedUserActivateErrorRedux } from '../../../redux/signup/selectors';
import {
  resetInvitedUserActivateError,
  resetInvitedUserCompanyLogo
} from '../../../redux/signup/slice';
import InvitedUSerActivateComponent from '../../Components/Signup/InvitedUserActivateComponent';
import { ReactComponent as Error } from '../../../images/Common/errorSubmit.svg';
import styles from './InvitedUserActivatePage.module.css';
import {
  getCompanyLogoByUserIdAction
} from '../../../redux/signup/actions';
import Loading from '../../../Common/Components/Loading';
import ExpiredInvitedUserComponent from '../../Components/Signup/ExpiredInvitedUserComponent';

const InvitedUserActivatePage = () => {
  const dispatch = useDispatch();
  const invitedUserActivateError = useSelector(invitedUserActivateErrorRedux);
  const { search } = useLocation();
  const objId = new URLSearchParams(search).get('userId');
  const history = useHistory();

  const [isExpired, setIsExpired] = useState(null);

  const checkForExpired = async () => {
    const resp = await dispatch(getCompanyLogoByUserIdAction({ UserId: objId }));
    if (resp?.meta?.requestStatus === 'rejected' && resp?.payload?.message === "User not found!") {
      setIsExpired(true);
    } else if (resp?.meta?.requestStatus === 'rejected') {
      history.push('/not-found');
    } else {
      setIsExpired(false);
    }
  };

  useEffect(async () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('authTokenVol2');
    localStorage.removeItem('user');
    dispatch(resetInvitedUserActivateError());
    dispatch(resetInvitedUserCompanyLogo());

    checkForExpired();
  }, []);

  return (
    <div className={styles.ContentBody}>
      {isExpired === false && <InvitedUSerActivateComponent />}
      {isExpired === true && <ExpiredInvitedUserComponent />}
      {isExpired === null && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%'
          }}
        >
          <Loading />
        </div>
      )}

      <InfoBanner
        text={invitedUserActivateError}
        show={invitedUserActivateError}
        CustomIcon={Error}
        setStatus={type => dispatch(resetInvitedUserActivateError())}
        bannerStyle={{ backgroundColor: 'white', border: '1px solid rgba(221, 58, 58, 0.1)' }}
      />
    </div>
  );
};

export default InvitedUserActivatePage;
