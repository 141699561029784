import React, { useEffect, useState } from 'react';
import styles from './SwitchButton.module.css';

export default function SwitchButton({ switchState, setSwitchState = () => {}, isResponsive = false, isMobile = false }) {
  const [isOpen, setIsOpen] = useState();

  useEffect(() => {
    if (switchState !== null) {
      setIsOpen(switchState);
    }
  }, [switchState]);

  return (
    <div
      className={`${styles.switch} ${isOpen ? styles.on : styles.off} ${isResponsive ? styles.responsive : ''} ${isMobile ? styles.mobile : ''}`}
      onClick={() => {
        setIsOpen(!isOpen);
        setSwitchState(!isOpen);
      }}
    >
      <div />
    </div>
  );
}
