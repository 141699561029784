/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import styles from '../Styles/CandidateResultPageContent.module.css';
import {
  CandidateEmail,
  CompanyName,
  CvCheck,
  hasAssessmentRedux,
  screeningCheckRedux,
  screeningListObjRedux,
  contentTemplates
} from '../../../redux/AssessmentSession/selectors';

import DoubleArrowIcon from '../Images/doubleArrow.svg';
import DoubleArrowIconMobile from '../Images/doubleArrowMobile.svg';
import CvCardComponent from './CvCardComponent/CvCardComponent';
import { ASSESSMENT_FLOW_MOBILE_THRESHOLD, useWindowSize } from '../../../utils/helpers';

import ScreeningCardComponent from './Screening/ScreeningCardComponent';
import AutoApplyComponent from './AutoApplyComponent/AutoApplyComponent';
import useMixpanelCandidateResultPageview from '../../../utils/mixpanelHelpers/useMixpanelCandidateResultPageview';

const CandidateResultPageContent = ({
  id,
  application,
  innerPhone,
  isAlreadyPassed,
  autoApplyStatus
}) => {
  const { t, i18n } = useTranslation(['application']);
  const [width] = useWindowSize();

  const [cardState, setCardState] = useState(1);

  const screeningCheck = useSelector(screeningCheckRedux);
  const cvCheck = useSelector(CvCheck);
  const [path, setPath] = useState(null);
  const companyName = useSelector(CompanyName);
  const hasAssessmentFlag = useSelector(hasAssessmentRedux);

  const contentTemplatesRedux = useSelector(contentTemplates);

  const getContentText = sourceType => {
    return contentTemplatesRedux.find(content => content.sourceType === sourceType);
  };

  const [cvComponentSavedState, setCvComponentSavedState] = useState(false);
  const candidateEmail = useSelector(CandidateEmail);

  /* ------------------------- Screening related methods -------------------------*/
  const questionListObj = useSelector(screeningListObjRedux);
  const [screeningCompleted, setScreeningCompleted] = useState(false);
  /* ------------------------- Screening related methods -------------------------*/

  /* ------------------------- Mixpanel related methods -------------------------*/
  useMixpanelCandidateResultPageview(application, isAlreadyPassed, autoApplyStatus);
  /* ------------------------- Mixpanel related methods -------------------------*/

  const SHOW_SCREENING = screeningCheck && questionListObj && !screeningCompleted;
  const SHOW_APPLICATION_TEXTS = !SHOW_SCREENING;
  const SHOW_CV_UPLOAD = !SHOW_SCREENING && cvCheck;
  const SHOW_AUTO_APPLY = !SHOW_SCREENING && cvCheck && autoApplyStatus !== undefined;
  const SHOW_CONTACT_TEXT = !SHOW_SCREENING && !cvCheck;

  return (
    <>
      {SHOW_SCREENING && (
        <ScreeningCardComponent
          closeScreening={() => setScreeningCompleted(true)}
          application={application}
        />
      )}

      {SHOW_APPLICATION_TEXTS &&
        (application ? (
          i18n.language === 'tr' ? (
            <>
              <div className={styles.title}>{getContentText(2).titleTr}</div>
              {hasAssessmentFlag && (
                <div
                  className={styles.subtitle}
                  dangerouslySetInnerHTML={{
                    __html: getContentText(2).subtitleTr
                  }}
                />
              )}
            </>
          ) : (
            <>
              <div className={styles.title}>{getContentText(2).titleEn}</div>
              {hasAssessmentFlag && (
                <div
                  className={styles.subtitle}
                  dangerouslySetInnerHTML={{
                    __html: getContentText(2).subtitleEn
                  }}
                />
              )}
            </>
          )
        ) : i18n.language === 'tr' ? (
          <>
            <div className={styles.title}>{getContentText(1).titleTr}</div>
            <div
              className={styles.subtitle}
              dangerouslySetInnerHTML={{
                __html: getContentText(1).subtitleTr
              }}
            />
          </>
        ) : (
          <>
            <div className={styles.title}>{getContentText(1).titleEn}</div>
            <div
              className={styles.subtitle}
              dangerouslySetInnerHTML={{
                __html: getContentText(1).subtitleEn
              }}
            />
          </>
        ))}

      {SHOW_CV_UPLOAD && (
        <CvCardComponent
          id={id}
          cardState={cardState}
          setCardState={setCardState}
          path={path}
          setPath={setPath}
          innerPhone={innerPhone}
          setParentSavedState={setCvComponentSavedState}
          application={application}
          autoApplyStatus={autoApplyStatus}
        />
      )}

      {SHOW_AUTO_APPLY && (
        <div className={styles.NewOpWrapper}>
          <div className={styles.NewOpInfoWrapper}>
            <div className={styles.NewOpHeaderInfoTextWrapper}>
              <div className={styles.DoubleArrowIconWrapper}>
                <img
                  src={
                    width < ASSESSMENT_FLOW_MOBILE_THRESHOLD
                      ? DoubleArrowIconMobile
                      : DoubleArrowIcon
                  }
                  alt="doubleArrowIcon"
                />
              </div>
              <div className={styles.NewOpInfoHeader}>
                {width <= ASSESSMENT_FLOW_MOBILE_THRESHOLD
                  ? t('autoApplyInfoHeaderMobile')
                  : t('autoApplyInfoHeaderWeb')}
              </div>
              <div className={styles.DoubleArrowIconWrapper}>
                <img
                  src={
                    width < ASSESSMENT_FLOW_MOBILE_THRESHOLD
                      ? DoubleArrowIconMobile
                      : DoubleArrowIcon
                  }
                  alt="doubleArrowIcon"
                />
              </div>
            </div>
          </div>

          <AutoApplyComponent
            status={autoApplyStatus}
            assessmentKey={id}
            candidateEmail={candidateEmail}
          />
        </div>
      )}

      {SHOW_CONTACT_TEXT && companyName !== 'BT Akademi' && (
        <div className={styles.subtitle}>{t('contactText')}</div>
      )}

      {SHOW_APPLICATION_TEXTS && ((!cardState && (cvComponentSavedState || path)) || !cvCheck) && (
        <div className={styles.closeWindow}>
          <span>{companyName === 'Brisa' ? t('closeWindowBrisa') : t('closeWindow')}</span>
        </div>
      )}
    </>
  );
};

CandidateResultPageContent.propTypes = {
  id: PropTypes.string.isRequired,
  application: PropTypes.bool,
  innerPhone: PropTypes.string,
  isAlreadyPassed: PropTypes.string,
  autoApplyStatus: PropTypes.bool.isRequired
};

CandidateResultPageContent.defaultProps = {
  application: false,
  innerPhone: '',
  isAlreadyPassed: ''
};

export default CandidateResultPageContent;
