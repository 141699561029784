import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { nextButtonLoadingRedux } from '../../../../redux/AssessmentSession/selectors';
import styles from './freeTextQuestion.module.css';

const FreeTextQuestion = ({
  qData,
  qDesc,
  headersHeight,
  ftAnswer,
  ftMobileThreshold,
  screenWidth
}) => {
  const { t } = useTranslation(['application']);
  const buttonLoading = useSelector(nextButtonLoadingRedux);
  return (
    <div
      className={styles.FreeTextQuestionBodyWrapper}
      style={{
        height: `calc(100vh - ${headersHeight}px)`,
        pointerEvents: buttonLoading ? 'none' : 'initial',
        filter: buttonLoading ? "blur(4px)" : "blur(0)"
      }}
    >
      <div className={styles.FreeTextOverflowWrapper}>
        <div
          className={
            screenWidth > ftMobileThreshold
              ? styles.FreeTextQuestionDetailsWrapper
              : styles.FreeTextQuestionDetailsWrapperMobile
          }
        >
          <div
            className={styles.FreeTextQuestionDescription}
            dangerouslySetInnerHTML={{ __html: qDesc }}
          />
          <div className={styles.FreeTextAnswerFieldWrapper}>
            <textarea
              type="text"
              className={styles.TextArea}
              defaultValue={ftAnswer[0]}
              placeholder={t('freeTextPlaceholder')}
              value={ftAnswer[0]}
              onChange={e => ftAnswer[1](e.target.value)}
              onPaste={e => e.preventDefault()}
              onCopy={e => e.preventDefault()}
              onCut={e => e.preventDefault()}
            />
          </div>
          <div className={styles.FreeTextInfo}>
            {t('freeTextInfo')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreeTextQuestion;
