import React, { useState } from 'react';

import { NotYetAcceptedCase } from './NotYetAcceptedCase';
import { AcceptedCase } from './AcceptedCase';
import useMixpanelAutoApplyPageview from '../../../../utils/mixpanelHelpers/useMixpanelAutoApplyPageview';

export default function AutoApplyComponent({ status, assessmentKey, candidateEmail }) {
  const isNewComer = status === null
  const [innerStatus, setInnerStatus] = useState(status);

  useMixpanelAutoApplyPageview(status)

  return (
    <div
      className="FlexColumn w-100 gap-30 verticallyCenter"
      style={{
        padding: '24px 30px 30px 30px',
        borderRadius: '16px',
        background: '#FFF',
        boxShadow: '0px 1px 24px rgba(208, 208, 208, 0.15)',
        marginBottom: '50px'
      }}
    >
      {innerStatus ? (
        <AcceptedCase setStatus={setInnerStatus} candidateEmail={candidateEmail} isNewComer={isNewComer} />
      ) : (
        <NotYetAcceptedCase
          setStatus={setInnerStatus}
          assessmentKey={assessmentKey}
          candidateEmail={candidateEmail}
        />
      )}
    </div>
  );
}
