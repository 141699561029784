import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import styles from './CompanySignup.module.css';
import { createCompanyAction } from '../../../redux/salesScreen/actions';
import TextInput from '../../../Common/Components/TextInput';
import CustomButton from '../../../Common/Components/CustomButton';

const CompanySignup = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [kariyerCodes, setKariyerCodes] = useState(null);

  async function handleCreateCompany() {
    const data = {
      name: name,
      surname: surname,
      email: email,
      companyName: companyName,
      phoneNumber: phoneNumber,
      password: password,
      kariyerCompanyIds: kariyerCodes?.length ? kariyerCodes : null
    };
    await dispatch(createCompanyAction(data))
      .then(resp => {
        if (resp?.meta?.requestStatus === 'fulfilled') {
          history.push('/nRdDOsdIFdwEdfdsaSd/3?state=new');
        } else {
          alert('Error');
        }
      })
      .catch(x => alert('Error'));
  }

  return (
    <div className={styles.contentBody}>
      <h1 style={{ marginBottom: '2rem' }}>Company Sign Up Form</h1>

      <div className={styles.row}>
        <label htmlFor="name">Name</label>
        <TextInput
          type="text"
          name="name"
          hidePlaceholder
          value={name}
          onChange={event => {
            setName(event.target.value);
          }}
          inputstyle={{ height: '42px', width: '300px' }}
        />
      </div>
      <div className={styles.row}>
        <label htmlFor="surname">Surname</label>
        <TextInput
          type="text"
          name="surname"
          hidePlaceholder
          value={surname}
          onChange={event => {
            setSurname(event.target.value);
          }}
          inputstyle={{ height: '42px', width: '300px' }}
        />
      </div>
      <div className={styles.row}>
        <label htmlFor="email">Corporate E-mail</label>
        <TextInput
          type="text"
          name="email"
          hidePlaceholder
          value={email}
          onChange={event => {
            setEmail(event.target.value);
          }}
          inputstyle={{ height: '42px', width: '300px' }}
        />
      </div>
      <div className={styles.row}>
        <label htmlFor="companyName">Company Name</label>
        <TextInput
          type="text"
          name="companyName"
          hidePlaceholder
          value={companyName}
          onChange={event => {
            setCompanyName(event.target.value);
          }}
          inputstyle={{ height: '42px', width: '300px' }}
        />
      </div>
      <div className={styles.row}>
        <label htmlFor="password">Password</label>
        <TextInput
          type="text"
          name="password"
          hidePlaceholder
          value={password}
          onChange={event => {
            setPassword(event.target.value);
          }}
          inputstyle={{ height: '42px', width: '300px' }}
        />
      </div>
      <div className={styles.row}>
        <label htmlFor="phone">Phone Number</label>
        <PhoneInput
          inputProps={{
            name: 'phone',
            required: true
          }}
          country="tr"
          countryCodeEditable={false}
          specialLabel=""
          value={phoneNumber}
          onChange={value => setPhoneNumber(value)}
          containerStyle={{ width: '300px' }}
          inputStyle={{ height: '42px', borderRadius: '10px', fontSize: '1.6rem' }}
        />
      </div>
      <div className={styles.row} style={{ alignItems: 'baseline' }}>
        <label htmlFor="kariyerIds">Kariyer Müşteri Kodu</label>
        <div className="FlexColumn gap-8">
          {kariyerCodes?.map((x, i) => (
            <div className="FlexRow gap-4 verticallyCenter" key={`${i}-${kariyerCodes?.length}`}>
              <TextInput
                type="number"
                name={`kariyerCode-${i}`}
                hidePlaceholder
                value={x}
                onChange={event => {
                  const temp = kariyerCodes ? [...kariyerCodes] : [];
                  temp[i] = event.target.value ? Number(event.target.value) : null;
                  setKariyerCodes(temp);
                }}
                inputstyle={{ height: '42px', width: '300px' }}
              />
              <CustomButton
                type={6}
                size="small"
                textField="x"
                buttonOnClick={() => {
                  const temp = kariyerCodes ? [...kariyerCodes] : [];
                  temp.splice(i, 1);
                  setKariyerCodes(temp);
                }}
              />
            </div>
          ))}
        </div>
        <CustomButton
          type={5}
          textField="+"
          buttonOnClick={() => {
            const temp = kariyerCodes ? [...kariyerCodes] : [];
            temp.push(null);
            setKariyerCodes(temp);
          }}
          customStyle={{ marginLeft: '12px' }}
        />
      </div>
      <div className={styles.row}>
        <input
          disabled
          name="candidateCount"
          style={{ marginLeft: 0, marginBottom: 0 }}
          type="checkbox"
          checked
        />
        <label className={styles.privacy} htmlFor="candidateCount">
          Terms of Use & Privacy Policy
        </label>
      </div>

      <div className={styles.row} style={{ gap: '12px', marginLeft: '5rem' }}>
        <CustomButton
          type={8}
          textField="Cancel"
          buttonOnClick={() => {
            history.goBack();
          }}
        />
        <CustomButton
          textField="Continue"
          buttonOnClick={() => {
            handleCreateCompany();
          }}
          isDisabled={
            name?.trim() === '' ||
            surname?.trim() === '' ||
            email?.trim() === '' ||
            password?.trim() === '' ||
            companyName?.trim() === '' ||
            (kariyerCodes?.length && kariyerCodes.includes(null))
          }
        />
      </div>
    </div>
  );
};

export default CompanySignup;
