import client from '../../constant/indexVol2';
import API from '../../constant/apiVol2';

export const updateCompanyProfileService = data =>
  new Promise((resolve, reject) => {
    client.post(API.updateCompanyProfile, data).then(resolve).catch(reject);
  });

export const getCompanyProfileService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCompanyProfile, { params: data }).then(resolve).catch(reject);
  });

export const getUpgradePricesService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getUpgradePrices, { params: data }).then(resolve).catch(reject);
  });
