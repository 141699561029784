export default {
  signupEmail: null,
  signedUserId: null,
  signUpStep: 1,
  signUpStep1ErrorMessage: null,
  signUpStep2ErrorMessage: null,

  userInfoStep: 1,
  userInfoSelectedAnswer: [null, null, null, null],

  forgotPasswordStep: 1,
  forgotPasswordEmail: null,
  forgotPasswordError: null,
  forgotPasswordCompanyName: null,
  forgotPasswordEdgeCaseState: null,  // 1 - registered but not activated user, 2 - invited user

  newPasswordPageErrorFlag: null,
  newPasswordPageErrorMessage: null,

  invitedUserActivateError: null,
  invitedUserCompanyLogo: null,
};
