import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-dropdown';
import MonacoEditor from '@monaco-editor/react';
import { nextButtonLoadingRedux } from '../../../../redux/AssessmentSession/selectors';
import styles from './codingQuestion.module.css';
import resetButton from '../../Images/refresh_outline.svg';
import { runCodeTestPageAction } from '../../../../redux/AssessmentSession/actions';
import CustomButton from '../../../../Common/Components/CustomButton';
import {
  connectToServerByType,
  disconnectFromLSP,
  getFilePathFromLanguage,
  initializeMonacoLanguages
} from './monacoEditor';
import { dropdownListUpdater } from '../../helpers';

const CodingQuestion = ({
  qData,
  qDesc,
  headersHeight,
  codingAnswer,
  codingLanguage,
  codingMobileThreshold,
  screenWidth,
  selectedIndex,
  resetToInitialCode
}) => {
  const dispatch = useDispatch();

  const buttonLoading = useSelector(nextButtonLoadingRedux);

  useEffect(() => {
    // Seçilmiş olan dilin gelen değerler arasında kaçıncı dil olduğunu tutmak için
    qData?.language.forEach((e, i) => {
      if (codingLanguage[0].value === e) {
        selectedIndex[1](i);
      }
    });
  }, [codingLanguage[0]]);

  const [totalTestCases, setTotalTestCases] = useState(-1);
  const [passedTestCases, setPassedTestCases] = useState(-1);
  const [runCodeInfoMessage, setRunCodeInfoMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);

  const updateAnswerForPhp = code => {
    let ret = code;
    ret = ret.replace('<?php ', '');
    ret = ret.replace('?>', '');
    return ret;
  };

  const { t } = useTranslation(['application'])

  const connectToLspServer = useCallback(
    languageCode => {
      connectToServerByType(languageCode, codingAnswer[0], qData.questionIndex);
    },
    [codingAnswer[0], qData.questionIndex]
  );

  const runCode = async () => {
    setLoading(true);
    setErrorMessage();
    const data = {
      questionId: qData.questionId,
      codingLanguage: codingLanguage[0].value,
      code:
        codingLanguage[0].value === 'PHP' ? updateAnswerForPhp(codingAnswer[0]) : codingAnswer[0]
    };

    const resp = await dispatch(runCodeTestPageAction(data));
    if (resp.type === 'runCodeTestPage/fulfilled') {
      setTotalTestCases(resp.payload.totalCases);
      setPassedTestCases(resp.payload.passedCases);
      setRunCodeInfoMessage(resp.payload.message);
      // setRunCodeInfoMessage(`<p>${resp.payload.message}</p>`);
      setLoading(false);
    } else {
      setErrorMessage(t('codeHasError'));
      setLoading(false);
    }
  };

  const resetToInitial = () => {
    resetToInitialCode();
    setTotalTestCases(-1);
    setPassedTestCases(-1);
    setRunCodeInfoMessage(null);
    setErrorMessage();
    setLoading(false);
  };

  const [compilerHeight, setCompilerHeight] = useState();

  useEffect(() => {
    const compilerWrapperHeight = document
      .getElementById('CodingCompilerWrapper')
      ?.getBoundingClientRect().height;
    const compilerHeaderHeight = document
      .getElementById('CompilerHeader')
      ?.getBoundingClientRect().height;
    const compilerFooterHeight = document
      .getElementById('CompilerFooter')
      ?.getBoundingClientRect().height;
    setCompilerHeight(compilerWrapperHeight - compilerHeaderHeight - compilerFooterHeight);
  });

  const handleEditorOnMount = (editor, monaco) => {
    editor.updateOptions({
      contextmenu: false,
      lightbulb: {
        enabled: false
      }
    });
    initializeMonacoLanguages(monaco);
  };

  useEffect(() => {
    if (codingLanguage[0]) {
      connectToLspServer(dropdownListUpdater(codingLanguage[0]));
    }
  }, [connectToLspServer, codingLanguage[0]]);

  return (
    <div
      className={
        screenWidth > codingMobileThreshold
          ? styles.CodingQuestionBodyWrapper
          : styles.CodingQuestionBodyWrapperMobile
      }
      style={{
        height: `calc(100vh - ${Math.ceil(headersHeight) + 1}px)`,
        pointerEvents: buttonLoading ? 'none' : 'initial',
        filter: buttonLoading ? 'blur(4px)' : 'blur(0)'
      }}
    >
      <div
        className={
          screenWidth > codingMobileThreshold
            ? styles.CodingQuestionDescriptionWrapper
            : styles.CodingQuestionDescriptionWrapperMobile
        }
        dangerouslySetInnerHTML={{ __html: qDesc }}
        style={{ minWidth: `calc((${codingMobileThreshold}px - 2 * 4.2rem) * 0.5)` }}
      />
      <div
        id="CodingCompilerWrapper"
        className={
          screenWidth > codingMobileThreshold
            ? styles.CodingQuestionCompilerWrapper
            : styles.CodingQuestionCompilerWrapperMobile
        }
        style={{ minWidth: `calc((${codingMobileThreshold}px - 2 * 4.2rem) * 0.5)` }}
      >
        <div id="CompilerHeader" className={styles.CompilerHeader}>
          <Dropdown
            className={styles.LanguageDropdown}
            options={qData.language}
            value={codingLanguage[0]}
            onChange={e => {
              codingLanguage[1](e);
            }}
          />
          <div className={styles.ResetButton} onClick={() => resetToInitial()}>
            <img src={resetButton} alt="resetInitialCode" style={{ marginRight: '4px' }} />
            {t('resetInitialCode')}
          </div>
        </div>
        <div className={styles.CompilerBodyWrapper} style={{ height: compilerHeight }}>
          <MonacoEditor
            theme="vs-dark"
            value={
              codingAnswer[0] || (selectedIndex[0] === -1 && t('selectCodingLang'))
            }
            onChange={e => codingAnswer[1](e)}
            options={{ wordWrap: 'on', minimap: { enabled: false } }}
            onMount={handleEditorOnMount}
            path={getFilePathFromLanguage(dropdownListUpdater(codingLanguage[0]))}
          />
        </div>

        <div id="CompilerFooter" className={styles.CompilerFooter}>
          <div className={styles.CompilerFooterUpper}>
            {t('output')}
            <CustomButton
              buttonOnClick={() => runCode()}
              size={screenWidth < codingMobileThreshold ? 'small' : 'medium'}
              type={2}
              textField={t('runButton')}
              loading={loading}
            />
          </div>
          <div className={styles.CompilerFooterBottom}>
            <div className={styles.DefaultColor}>
              {loading && t('codeRunning')}
              {!loading && errorMessage && errorMessage}
              {!loading && !errorMessage && passedTestCases !== -1 && totalTestCases !== -1 && (
                <div style={{ whiteSpace: 'pre-wrap' }}>{runCodeInfoMessage}</div>
              )}
              {!loading &&
                !errorMessage &&
                passedTestCases === -1 &&
                totalTestCases === -1 &&
                t('clickRunButton')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodingQuestion;
