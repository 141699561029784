import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import InfoBanner from '../../../../Common/Components/InfoBanner';
import styles from './GeneralTab.module.css';
import { companyInfo, userSelector } from '../../../../redux/auth/selectors';
import { getCompanyProfileAction } from '../../../../redux/auth/actions';
import edit from '../../../../images/Auth/Settings/billingEdit.svg';
import { ReactComponent as AddLogoIcon } from '../../../Images/logoUploadIcon.svg';
import Skeleton from '../../../../Common/Components/Skeleton';
import EditCompanyRelatedModal from '../Modals/EditCompanyRelatedModal';

const GeneralTab = () => {
  const dispatch = useDispatch();
  const [showSaved, setShowSaved] = useState(false);
  const user = useSelector(userSelector);
  const profileInfo = useSelector(companyInfo);

  const { t } = useTranslation(['generalTab'])

  const [logo, setLogo] = useState();

  const [modalShowState, setModalShowState] = useState(false);
  const [modalType, setModalType] = useState('');

  // Onboarding related stuff
  //   #region
  const logoContainerRef = useRef();

  //   #endregion

  useEffect(() => {
    const data = {
      CompanyId: user.companyId
    };
    dispatch(getCompanyProfileAction(data));
  }, []);

  useEffect(() => {
    if (profileInfo && profileInfo?.companyLogoUrl) {
      let temp = '';
      if (profileInfo?.companyLogoUrl?.startsWith('CompanyLogo')) {
        temp = temp.concat(`${process.env.REACT_APP_IMAGE_API}/`);
      }
      temp = temp.concat(profileInfo?.companyLogoUrl);
      setLogo(temp);
    } else {
      setLogo('');
    }
  }, [profileInfo]);

  const CompanyWrapper = (
    <div className={styles.companyWrapper}>
      <div className={styles.companyTag}>
      {t('company').toLocaleUpperCase('tr')}
        <img
          src={edit}
          alt=""
          className={styles.editIcon}
          onClick={() => {
            setModalType('company');
            setModalShowState(true);
          }}
        />
      </div>

      {profileInfo?.companyId ? (
        <>
          <div className={styles.companyNameWrapper}>
            <div className={styles.innerTitle}>{profileInfo?.companyName}</div>
            <div className={styles.innerSubtitle}>{profileInfo?.website}</div>
          </div>

          <div
            ref={logoContainerRef}
            className={`${styles.logoContainer} ${logo && styles.logoPresent}`}
            onClick={() => {
              setModalType('company');
              setModalShowState(true);
            }}
          >
            {!logo ? (
              <div className={styles.uploadLogoWrapper}>
                <AddLogoIcon style={{ height: '30px' }} />
                <span className={styles.innerTitle}>{t('addLogo')}</span>
                <span className={styles.innerSubtitle}>
                  {t('increaseCompVisibility')}
                </span>
              </div>
            ) : (
              <img className={styles.logo} alt="" src={logo} />
            )}
          </div>
        </>
      ) : (
        <Skeleton type="settingsGeneralCompany" />
      )}
    </div>
  );

  const InfoWrapper = (
    <div className={styles.companyInfoWrapper}>
      <div className={styles.companyTag}>
        {t('compInfo').toLocaleUpperCase('tr')}
        <img
          src={edit}
          alt=""
          className={styles.editIcon}
          onClick={() => {
            setModalType('info');
            setModalShowState(true);
          }}
        />
      </div>

      {profileInfo?.companyId ? (
        <div>
          {profileInfo?.companyInformation?.trim()?.length > 0 ? (
            <span className={styles.companyDescText}>{profileInfo?.companyInformation}</span>
          ) : (
            <span className={styles.placeholderText}>
              {t('tellCandAboutComp')}
            </span>
          )}
        </div>
      ) : (
        <Skeleton type="settingsGeneralInfo" />
      )}
    </div>
  );

  return (
    <>
      <div className={styles.contentWrapper}>
        <div className={styles.title}>{t('general')}</div>

        <div className={styles.cardWrapper}>
          {CompanyWrapper}
          {InfoWrapper}
        </div>
      </div>
      <InfoBanner
        text={t('changesSaved')}
        show={showSaved}
        setStatus={type => {
          setShowSaved(type);
        }}
      />

      <EditCompanyRelatedModal
        show={modalShowState}
        type={modalType}
        onClose={() => setModalShowState(false)}
        showSuccess={setShowSaved}
      />
    </>
  );
};

export default GeneralTab;
