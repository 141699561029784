import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useWindowSize } from '../../utils/helpers';

export default function usePositionTabResolutionHandler() {
  const [screenWidth] = useWindowSize();
  const history = useHistory();

  useEffect(() => {
    if (screenWidth && screenWidth < 576) {
      history.push('/settings/general');
    }
  }, [screenWidth]);
}
