import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../Styles/Sql.module.css';
import { sqlTestCasesRedux, sqlValidCasesRedux } from '../../../redux/addQuestion/selectors';
import { setIsPassedVerificationRedux, setSqlTestCases, setSqlValidCases, setVerificationCodeResponseRedux } from '../../../redux/addQuestion/slice';
import SqlTestCaseElement from './sqlTestCaseElement';

function SqlTestCases() {
  const dispatch = useDispatch();

  const reduxSqlTestCases = useSelector(sqlTestCasesRedux);
  const reduxSqlValidCases = useSelector(sqlValidCasesRedux);

  const [testCaseNo, setTestCaseNo] = useState(reduxSqlTestCases.length);
  const [validCaseNo, setValidCaseNo] = useState(reduxSqlValidCases.length);

  const [testCaseChange, setTestCaseChange] = useState(0);
  const [validCaseChange, setValidCaseChange] = useState(0);

  useEffect(async () => {
    setTestCaseChange(0);
  }, [testCaseChange]);

  useEffect(async () => {
    setValidCaseChange(0);
  }, [validCaseChange]);

  useEffect(async () => {
    setTestCaseNo(reduxSqlTestCases.length);  
  }, [reduxSqlTestCases]);

  useEffect(async () => {
    setValidCaseNo(reduxSqlValidCases.length);
  }, [reduxSqlValidCases]);

  function addTestCaseElement() {
    setTestCaseNo(testCaseNo + 1);
  }
  function addValidCaseElement() {
    setValidCaseNo(validCaseNo + 1);
  }

  const onChangeResetVerificationCode = () => {
    dispatch(setIsPassedVerificationRedux(false));
    dispatch(setVerificationCodeResponseRedux(''));
  };

  

  useEffect(async () => {
    if (testCaseNo > reduxSqlTestCases.length) {
      const temp = reduxSqlTestCases.slice();
      const setup = '--Please use ; after each sql command!\n\n';
      const checkingQuery = '--Please use ; after each sql command!\n\n';
      const result = '';
      const weight = '1';
      temp.push({ setup, checkingQuery, result,  weight });
      dispatch(setSqlTestCases(temp));
    }
  }, [testCaseNo]);

  useEffect(async () => {
    if (validCaseNo > reduxSqlValidCases.length) {
      const temp = reduxSqlValidCases.slice();
      const setup = '--Please use ; after each sql command!\n\n';
      const checkingQuery = '--Please use ; after each sql command!\n\n';
      const result = '';
      const weight = '1';
      temp.push({ setup, checkingQuery, result,  weight });
      dispatch(setSqlValidCases(temp));
    }
  }, [validCaseNo]);

  const removeTestCaseElement = index => {
    onChangeResetVerificationCode();
    if (testCaseNo > 1) {
      setTestCaseNo(testCaseNo - 1);
      const temp = reduxSqlTestCases.slice();
      temp.splice(index, 1);
      dispatch(setSqlTestCases(temp));
    }
  };

  const removeValidCaseElement = index => {
    onChangeResetVerificationCode();
    if (validCaseNo > 1) {
      setValidCaseNo(validCaseNo - 1);
      const temp = reduxSqlValidCases.slice();
      temp.splice(index, 1);
      dispatch(setSqlValidCases(temp));
    }
  };

  return (
    <div className={styles.CasesWrapper}>
      <div className={styles.TestCases}>
        <p>Test cases to be used during the test</p>
        {reduxSqlTestCases.map((item, i) => (
          <SqlTestCaseElement id={i} item={item} removeElement={removeTestCaseElement} group="test" />
        ))}
        <button
          type="button"
          onClick={val => {
            addTestCaseElement();
            setTestCaseChange(1);
            onChangeResetVerificationCode();
          }}
        >
          Add New
        </button>
      </div>

      <div className={styles.ValidationCases}>
        <p>Test cases to be used for validation</p>
        {reduxSqlValidCases.map((item, i) => (
          <SqlTestCaseElement id={i} item={item} removeElement={removeValidCaseElement} group="valid" />
        ))}
        <button
          type="button"
          onClick={val => {
            addValidCaseElement();
            setValidCaseChange(1);
            onChangeResetVerificationCode();
          }}
        >
          Add New
        </button>
      </div>
    </div>
  );
}
export default SqlTestCases;