export default {
  category: '',
  assessmentLabel: '',
  subcategory: '',
  title: '',
  difficulty: '',
  language: '',
  limit: '',
  status: true,

  questionDescription: '',

  multiSelectAnswers: ['', '', '', '', ''],
  selectedOptionIndex: 0,

  freeTextAnswers: ['', '', '', '', ''],

  testCases: [
    { input: [''], result: '', weight: "1" },
    { input: [''], result: '', weight: "1" },
    { input: [''], result: '', weight: "1" }
  ],
  validCases: [
    { input: [''], result: '', weight: "1" },
    { input: [''], result: '', weight: "1" },
    { input: [''], result: '', weight: "1" }
  ],
  outputVariable: {
    type: '',
    name: ''
  },
  parameters: [{ type: '', name: '' }],
  initialSelectedLang: { value: 'python', label: 'Python3' },
  initialCodeDictionary: {},
  isPassedVerification: false,
  verificationCode: '',
  verificationSelectedLang: { value: '', label: '' },


  sqlQueryType: '',
  sqlCreateTableCode: '--Please use ; after each sql command!\n\nCREATE TABLE\n## VALUES ##;\n',
  sqlTestCases: [
    { setup: '--Please use ; after each sql command!\n\n', checkingQuery: '--Please use ; after each sql command!\n\n', result: '', weight: '1'},
  ],
  sqlValidCases: [
    { setup: '--Please use ; after each sql command!\n\n', checkingQuery: '--Please use ; after each sql command!\n\n', result: '', weight: '1'},
  ],
  sqlVerificationCode: '--Please use ; after each sql command!\n\n--your SQL script goes below this line',





  isSuccess: "loading",
  encryptedQuestionId: '',
  verificationCodeResponse: "",
};
