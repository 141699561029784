import { createSlice } from '@reduxjs/toolkit';
import {
  forgotPasswordAction,
  getCompanyLogoByUserIdAction,
  invitedUserActivateAction,
  refreshPasswordAction,
  signupByEmailAction,
  signupStep2Action
} from './actions';
import initialState from './initialState';

const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    resetToInitial: () => initialState,
    setSignupEmail: (state, action) => {
      state.signupEmail = action.payload;
    },
    setSignedUserId: (state, action) => {
      state.signedUserId = action.payload;
    },
    setSignupStep: (state, action) => {
      state.signUpStep = action.payload;
    },
    setUserInfoStep: (state, action) => {
      state.userInfoStep = action.payload;
    },
    setUserInfoSelectedAnswer: (state, action) => {
      state.userInfoSelectedAnswer = action.payload;
    },
    setSignUpStep1ErrorMessage: (state, action) => {
      state.signUpStep1ErrorMessage = action.payload;
    },
    setForgotPasswordStep: (state, action) => {
      state.forgotPasswordStep = action.payload;
    },
    setForgotPasswordEmail: (state, action) => {
      state.forgotPasswordEmail = action.payload;
    },
    resetNewPasswordPageErrorFlag: state => {
      state.newPasswordPageErrorFlag = null;
    },
    resetNewPasswordPageErrorMessage: state => {
      state.newPasswordPageErrorMessage = null;
    },
    resetSignUpStep2ErrorMessage: state => {
      state.signUpStep2ErrorMessage = null;
    },
    resetForgotPasswordError: state => {
      state.forgotPasswordError = null;
    },
    resetForgotPasswordCompanyName: state => {
      state.forgotPasswordCompanyName = null;
    },
    resetForgotPasswordEdgeCaseState: state => {
      state.forgotPasswordEdgeCaseState = null;
    },
    resetInvitedUserActivateError: state => {
      state.invitedUserActivateError = null;
    },
    resetInvitedUserCompanyLogo: state => {
      state.invitedUserCompanyLogo = null;
    }
  },
  extraReducers: builder => {
    builder.addCase(signupByEmailAction.fulfilled, (state, action) => {
      state.signedUserId = action.payload.id;
      state.signUpStep = 2;
      state.signUpStep1ErrorMessage = null;
    });
    builder.addCase(signupByEmailAction.rejected, (state, action) => {
      state.signUpStep1ErrorMessage = action.payload.message;
    });
    builder.addCase(signupByEmailAction.pending, state => {
      state.signUpStep1ErrorMessage = null;
    });

    builder.addCase(signupStep2Action.pending, state => {
      localStorage.removeItem('verificationMailSentDate');
      state.signUpStep2ErrorMessage = null;
    });
    builder.addCase(signupStep2Action.fulfilled, state => {
      state.signUpStep = 3;
      localStorage.setItem('verificationMailSentDate', new Date());
    });
    builder.addCase(signupStep2Action.rejected, (state, action) => {
      state.signUpStep2ErrorMessage = action.payload.message;
    });

    builder.addCase(forgotPasswordAction.fulfilled, (state, action) => {
      state.forgotPasswordStep = action.payload.isOk ? 2 : 3;
      state.forgotPasswordCompanyName = action.payload.isOk
        ? null
        : action.payload.companyName || 'Deneme';
      state.forgotPasswordEdgeCaseState = action.payload.isOk
        ? null
        : action.payload.companyStatus === 1
        ? 2
        : 1;
      state.signedUserId = action.payload.signedUserId;
      localStorage.setItem('forgotPasswordMailSent', new Date());
    });
    builder.addCase(forgotPasswordAction.pending, state => {
      state.forgotPasswordError = null;
      state.forgotPasswordCompanyName = null;
      state.forgotPasswordEdgeCaseState = null;
      localStorage.removeItem('forgotPasswordMailSent', new Date());
    });
    builder.addCase(forgotPasswordAction.rejected, (state, action) => {
      state.forgotPasswordError = action.payload.message;
      state.forgotPasswordCompanyName = null;
      state.forgotPasswordEdgeCaseState = null;
    });

    builder.addCase(refreshPasswordAction.rejected, (state, action) => {
      state.newPasswordPageErrorFlag = true;
      state.newPasswordPageErrorMessage = action.payload.message;
    });

    builder.addCase(invitedUserActivateAction.rejected, (state, action) => {
      state.invitedUserActivateError = action.payload.message;
    });
    builder.addCase(invitedUserActivateAction.pending, state => {
      state.invitedUserActivateError = null;
    });
    builder.addCase(invitedUserActivateAction.fulfilled, state => {
      state.invitedUserActivateError = null;
    });

    builder.addCase(getCompanyLogoByUserIdAction.fulfilled, (state, action) => {
      state.invitedUserCompanyLogo = action.payload.companyLogo;
    });
    builder.addCase(getCompanyLogoByUserIdAction.pending, state => {
      state.invitedUserCompanyLogo = null;
    });
    builder.addCase(getCompanyLogoByUserIdAction.rejected, state => {
      state.invitedUserCompanyLogo = null;
    });
  }
});

export const {
  resetToInitial,
  setSignupEmail,
  setSignedUserId,
  setSignupStep,
  setUserInfoStep,
  setUserInfoSelectedAnswer,
  setSignUpStep1ErrorMessage,
  setForgotPasswordStep,
  setForgotPasswordEmail,
  resetNewPasswordPageErrorFlag,
  resetNewPasswordPageErrorMessage,
  resetSignUpStep2ErrorMessage,
  resetForgotPasswordError,
  resetForgotPasswordCompanyName,
  resetForgotPasswordEdgeCaseState,
  resetInvitedUserActivateError,
  resetInvitedUserCompanyLogo
} = signupSlice.actions;

export default signupSlice.reducer;
