export default {
  /* Filter related states */
  searchParameter: null,
  orderType: null, // 1: points desc, 2: points asc, 3:AddDate desc, 4:AddDate asc
  funnelId: null,
  startDate: null,
  endDate: null,
  startScore: null,
  endScore: null,
  pageNumber: null,
  arriveType: null,
  oldestInitialDate: null,
  industryList: null,
  schoolList: null,
  majorList: null,
  industryIncludeType: null,
  screeningExperiences: null,
  screeningWorkTypes: null,
  screeningNationalities: null,
  screeningLocations: null,
  screeningSalaryCurrency: null,
  screeningSalaryMinValue: null,
  screeningSalaryMaxValue: null,

  activeFilterCount: 0,
  authFlag: null, // If unauthorized -> background image
  rejectReasonListUrl: null,
  rejectReasonListFromApi: null,
  /* ---------------------- */

  /* Company Assessment Detail related states */
  industryAvg: null,
  top5Avg: null,
  assessmentObjStatus: null,
  rootAssessmentName: null,
  rating: null, // might be unneccessary
  companyAssessmentName: null,
  fiveCandidatesExistFlag: null,
  isCustomFlag: null,
  rootAssessmentId: null,
  assessmentDetailLoading: true,

  assessmentDetailObj: null, // Added for mixpanel
  /* ---------------------- */

  /* Candidate List related states */
  candidateList: null,
  selectedCandidateList: null,
  candidateCountWithCurrentFilter: null,
  loadingForScrollCandidates: null, // Loading for fetching additional candidates with scroll
  loadingForFirstFetch: null, // Loading for first fetch after candidateList is reset

  newOpMixpanelData: null,  // Added for mixpanel

  blurCandidateWrapperAfterMoveFlag: null, // Loading effect only for fetching candidates after move
  /* ---------------------- */

  /* Move candidates to funnel related states */
  isMoveEnabledFlag: null, // IMPORTANT: Flag to keep state of move, whether it is active or not
  /* ---------------------- */

  /* Funnel Information */
  funnelInfo: [],
  funnelOpen: false,
  funnelLoading: true,
  /* ---------------------- */

  /* Funnel Popup related states */
  popupShowState: false,
  popupTypeState: null,
  clickedFunnel: null,

  rejectModalState: false,
  rejectModalSelectedCount: null,
  rejectSuccessState: false,

  infoBannerShowState: false,
  infoBannerText: null,
  infoBannerErrorFlag: false,
  /* ---------------------- */


  showMailModal: false,
  mailModalClickedFunnelInfo: null,
};
