import React from 'react';

import { useTranslation } from 'react-i18next';

import constants from '../../../../utils/constants';

export default function NonEditableFunnelList({ activeFunnelInformation }) {
  return (
    <div className="FlexColumn overflowAuto" style={{ padding: '14px 10px 28px' }}>
      <FunnelCategoryWrapper funnelList={activeFunnelInformation?.funnels} />
    </div>
  );
}

const FunnelCategoryStatic = ({ title, funnelList }) => {
  return (
    <div className="FlexColumn gap-10">
      <span className="fw-500 font-12 c-darkGray-04">{title}</span>
      <div
        className="FlexColumn gap-4"
        style={{ borderLeft: '2px solid var(--dark-gray-02)', paddingLeft: '10px' }}
      >
        {funnelList
          ?.sort((a, b) => a?.priority - b?.priority)
          ?.map((x, i) => (
            <span className="fw-400 font-16 c-darkGray" style={{ padding: '6px 10px' }}>
              {x?.customFunnelName}
            </span>
          ))}
      </div>
    </div>
  );
};

const FunnelCategoryWrapper = ({ funnelList }) => {
const { t } = useTranslation(['createAssm'])

  return (
    <div className="FlexColumn gap-10">
      <FunnelCategoryStatic title={t('hiringFunnelCategory')} funnelList={constants?.HIRING_FUNNEL_LIST} />
      <FunnelCategoryStatic title={t('new')} funnelList={constants?.NEW_LIST} />
      <FunnelCategoryStatic title={t('inReview')} funnelList={funnelList} />
      <FunnelCategoryStatic title={t('concluded')} funnelList={constants?.CONCLUDED_LIST} />
    </div>
  );
};
