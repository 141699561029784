import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getCandidateBackgroundAction,
  getCandidateCVAction,
  getCandidateSolutionsAction,
  getPersonalInformationAction
} from '../../../../redux/candidateDetail/actions';
import { getParam, useWindowSize } from '../../../../utils/helpers';
import ActivityTab from '../ActivityTab/ActivityTab';
import AssessmentReport from '../AssessmentReport/AssessmentReport';
import ProfileTab from '../ProfileTab/ProfileTab';
import UpperTab from '../UpperTab/UpperTab';
import styles from './ContentWrapper.module.css';
import {
  candidateBackgroundSelector,
} from '../../../../redux/candidateDetail/selectors';

const ContentWrapper = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [selectedTabPosition, setSelectedTabPosition] = useState(0);
  const [screenW] = useWindowSize();
  const candidateBackground = useSelector(candidateBackgroundSelector);

  function fetchCurrentSolutions() {
    const data = {
      CompanyAssessmentId: getParam(location.search, 'currentAssessmentId'),
      UserId: id
    };
    dispatch(getCandidateSolutionsAction(data));
  }

  function fetchCandidateBackground() {
    const dataBack = {
      UserId: id,
      CompanyAssessmentId: getParam(location.search, 'defaultAssessmentId')
    };
    dispatch(getCandidateBackgroundAction(dataBack));
  }

  function switchToAssessmentReport() {
    if (screenW > 576) {
      setSelectedTabPosition(1);
    }
  }

  function fetchPersonalInfo() {
    const data = {
      UserId: id
    };
    dispatch(getPersonalInformationAction(data));
  }

  useEffect(() => {
    if (screenW) {
      window.addEventListener('locationchange', switchToAssessmentReport);
    }
    return () => {
      window.removeEventListener('locationchange', switchToAssessmentReport);
    };
  }, [screenW]);

  useEffect(() => {
    fetchCandidateBackground();
    fetchCurrentSolutions();
    fetchPersonalInfo();

    window.addEventListener('locationchange', fetchCurrentSolutions);
    return () => {
      window.removeEventListener('locationchange', fetchCurrentSolutions);
    };
  }, []);

  useEffect(() => {
    if (screenW <= 576) {
      setSelectedTabPosition(0);
    }
  }, [screenW]);

  useEffect(() => {
    if (selectedTabPosition === 0 && candidateBackground) {
      if (!candidateBackground.experiences && !candidateBackground.educations) {
        switchToAssessmentReport();
      }
    }
  }, [candidateBackground]);

  useEffect(() => {
    const dataCv = {
      UserId: id
    };
    dispatch(getCandidateCVAction(dataCv));
  }, []);

  return (
    <div className={styles.ContentWrapper}>
      <TabWrapper>
        <UpperTab setSelectedTab={setSelectedTabPosition} selectedTab={selectedTabPosition} />
      </TabWrapper>
      {selectedTabPosition === 0 ? (
        <ProfileTab />
      ) : selectedTabPosition === 1 ? (
        <AssessmentReportWrapper>
          <AssessmentReport />
        </AssessmentReportWrapper>
      ) : selectedTabPosition === 2 ? (
        <ActivityTab />
      ) : null}
    </div>
  );
};
export default ContentWrapper;

const AssessmentReportWrapper = ({ children }) => {
  const [screenW] = useWindowSize();
  return screenW > 576 ? <>{children}</> : null;
};

const TabWrapper = ({ children }) => {
  const [screenW] = useWindowSize();
  return screenW > 576 ? <>{children}</> : null;
};
