import React, { useState, useEffect } from 'react';
import styles from '../Styles/PasswordInput.module.css';
import { ReactComponent as Eye } from '../Images/eye_show.svg';
import { ReactComponent as EyeOff } from '../Images/eye-hide.svg';
import { useWindowSize } from '../../utils/helpers';

const PasswordInput = ({
  id,
  placeholder = '',
  name = '',
  hoverIcon = false,
  disabled = false,
  onChange = () => {},
  onBlur = () => {},
  value = null,
  mref = null,
  style = {},
  inputstyle,
  autofocus = false,
  isPasswordAgain = false,
  firstPasswordForPasswordAgainField = null,
  secondPasswordForPasswordField = null,
  setParentErrorState = () => {},
  showInvisibleError,
  ...props
}) => {
  const [isVisible, setVisible] = useState(false);
  const [type, setType] = useState('password');

  function handleVisible() {
    setVisible(!isVisible);
  }

  useEffect(() => {
    if (isVisible) {
      setType('text');
    } else {
      setType('password');
    }
  }, [isVisible]);

  const [passwordMatch, setPasswordMatch] = useState(true);
  const [passwordConstraint, setPasswordConstraint] = useState(false);

  const [uppercase, setUppercase] = useState(false);
  const [characters, setCharacters] = useState(false);
  const [number, setNumber] = useState(false);

  const [timerId, setTimerId] = useState(-1);

  const passwordCheckFunction = () => {
    if (isPasswordAgain) {
      if (firstPasswordForPasswordAgainField !== value) {
        setPasswordMatch(false);
      } else {
        setPasswordMatch(true);
      }
      if (value.length === 0) {
        setPasswordMatch(true);
      }
    } else {
      if (secondPasswordForPasswordField) {
        if (value.length === 0 && secondPasswordForPasswordField.length !== 0) {
          setPasswordMatch(false);
        }
        if (
          value !== secondPasswordForPasswordField &&
          secondPasswordForPasswordField.length !== 0
        ) {
          setPasswordMatch(false);
        } else {
          setPasswordMatch(true);
        }
      }

      if (value.length > 0) {
        const hasNumber = /\d/;
        const hasUpperCase = /[A-Z]/;
        if (hasUpperCase.test(value) && hasNumber.test(value) && value.length >= 8) {
          setPasswordConstraint(false);
        } else {
          setPasswordConstraint(true);
        }
        if (hasUpperCase.test(value)) {
          setUppercase(true);
        } else {
          setUppercase(false);
        }
        if (hasNumber.test(value)) {
          setNumber(true);
        } else {
          setNumber(false);
        }
        if (value.length >= 8) {
          setCharacters(true);
        } else {
          setCharacters(false);
        }
      }
    }
  };

  useEffect(() => {
    if (timerId !== -1) {
      clearTimeout(timerId);
      setTimerId(-1);
    }

    if (value) {
      setTimerId(
        setTimeout(() => {
          passwordCheckFunction();
        }, 1000)
      );
    } else {
      setPasswordConstraint(false);
      setPasswordMatch(true);
    }
  }, [value, firstPasswordForPasswordAgainField, secondPasswordForPasswordField]);

  useEffect(() => {
    if (isPasswordAgain) {
      setParentErrorState(!passwordMatch);
    } else {
      setParentErrorState(passwordConstraint);
    }
  }, [passwordMatch, passwordConstraint]);

  const [width, height] = useWindowSize();
  const [eyePos, setEyePos] = useState();
  const [eyeMargin, setEyeMargin] = useState(0);

  useEffect(() => {
    const inputHeight = document?.getElementById('passwordInput')?.getBoundingClientRect()?.height;
    const inputWidth = document?.getElementById('passwordInput')?.getBoundingClientRect()?.width;
    setEyePos(inputHeight);
    if (inputWidth <= 60) {
      setEyeMargin(0);
    } else {
      setEyeMargin(inputWidth - 30);
    }
  }, [width, height]);

  return (
    <div
      id={id}
      className={`${styles.inputContainer} ${
        ((!isPasswordAgain && passwordConstraint) || (isPasswordAgain && !passwordMatch)) &&
        styles.errorContainer
      }`}
      style={style}
      ref={mref}
    >
      {isVisible ? (
        <div
          className={styles.eye}
          style={{ top: eyePos * 0.25, marginLeft: eyeMargin }}
          onClick={handleVisible}
        >
          <EyeOff />
        </div>
      ) : (
        <div
          className={styles.eye}
          style={{ top: eyePos * 0.25, marginLeft: eyeMargin }}
          onClick={handleVisible}
        >
          <Eye />
        </div>
      )}
      <input
        autoFocus={autofocus}
        name={name}
        id="passwordInput"
        type={type}
        autoComplete="off"
        style={hoverIcon ? inputstyle : { backgroundImage: 'none', ...inputstyle }}
        value={value}
        onChange={e => {
          if (e.nativeEvent.data !== ' ') {
            onChange(e);
          }
        }}
        onKeyDown={e => {
          if (e.code === 'Space') {
            e.preventDefault();
          }
        }}
        onBlur={onBlur}
        {...props}
        className={value}
        disabled={disabled}
      />
      <label className={value && styles.filled} htmlFor={name}>
        {placeholder}
      </label>
      {isPasswordAgain && (showInvisibleError || !passwordMatch) && (
        <div
          className={styles.passwordConstraintErrorWrapper}
          style={{
            visibility: showInvisibleError ? (!passwordMatch ? 'visible' : 'hidden') : 'visible'
          }}
        >
          Password did not match!
        </div>
      )}
      {!isPasswordAgain && (showInvisibleError || passwordConstraint) && (
        <div
          className={styles.passwordConstraintErrorWrapper}
          style={{
            visibility: showInvisibleError ? (passwordConstraint ? 'visible' : 'hidden') : 'visible'
          }}
        >
          You should use&nbsp;
          {passwordConstraint && (
            <>
              <div
                style={{
                  color: characters ? 'rgba(57, 57, 57, 0.6)' : '#de4747'
                }}
              >
                at least 8 characters,&nbsp;
              </div>
              <div
                style={{
                  color: uppercase ? 'rgba(57, 57, 57, 0.6)' : '#de4747'
                }}
              >
                at least 1 uppercase,&nbsp;
              </div>
              <div
                style={{
                  color: number ? 'rgba(57, 57, 57, 0.6)' : '#de4747'
                }}
              >
                at least 1 number
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default PasswordInput;
