import { triggerAssessmentAction } from '../Api/actions';

export default async function triggerAssessmentMail(userIdList, companyAssessmentId, setLoading) {
  const data = {
    userIdList,
    companyAssessmentId
  };

  const resp = await triggerAssessmentAction(data);
  setLoading(false);
  return resp;
}
