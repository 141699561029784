import React from 'react';
import styles from '../Styles/Loading.module.css';
import { ReactComponent as C } from '../../images/Common/Loading/C.svg';
import { ReactComponent as O } from '../../images/Common/Loading/O.svg';
import { ReactComponent as E } from '../../images/Common/Loading/E.svg';
import { ReactComponent as N } from '../../images/Common/Loading/N.svg';
import { ReactComponent as S } from '../../images/Common/Loading/S.svg';
import { ReactComponent as I } from '../../images/Common/Loading/I.svg';

export default function Loading() {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: 30 }}>
      <C className={styles.C} style={{ marginRight: 1, height: 30, maxWidth: 30 }} />
      <O className={styles.O} style={{ marginRight: 1, height: 30, maxWidth: 30 }} />
      <E className={styles.E} style={{ marginRight: 1, height: 30, maxWidth: 30 }} />
      <N className={styles.N} style={{ marginRight: 1, height: 30, maxWidth: 30 }} />
      <S className={styles.S} style={{ marginRight: 1, height: 30, maxWidth: 30 }} />
      <I className={styles.I} style={{ marginRight: 1, height: 30, maxWidth: 30 }} />
      <O className={styles.O2} style={{ marginRight: 1, height: 30, maxWidth: 30 }} />
    </div>
  );
}
