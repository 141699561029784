import React from 'react';

const PlusIcon = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.24206 13.4297H17.377C17.5101 13.4297 17.619 13.3453 17.619 13.2422V12.1172C17.619 12.0141 17.5101 11.9297 17.377 11.9297H6.24206C6.10893 11.9297 6 12.0141 6 12.1172V13.2422C6 13.3453 6.10893 13.4297 6.24206 13.4297Z"
        fill="#7458D3"
      />
      <path
        d="M11 7.17175L11 18.3067C11 18.4398 11.0844 18.5487 11.1875 18.5487L12.3125 18.5487C12.4156 18.5487 12.5 18.4398 12.5 18.3067L12.5 7.17175C12.5 7.03862 12.4156 6.92969 12.3125 6.92969L11.1875 6.92969C11.0844 6.92969 11 7.03862 11 7.17175Z"
        fill="#7458D3"
      />
    </svg>
  );
};

export default PlusIcon;
