import React from 'react';
import styles from './BackgroundImageWrapper.module.css';

const BackgroundImageWrapper = ({ image, header, subtext,  ...props }) => {
  return (
    <div
      className={styles.Wrapper}
      style={{ width: props.width, minWidth: props.minWidth, ...props.style }}
    >
      <img src={image} alt="" />
      <div className={styles.TextWrapper}>
        <span className={styles.header}>{header}</span>
        {subtext && <span className={styles.subtext}>{subtext}</span>}
      </div>
    </div>
  );
};
export default BackgroundImageWrapper;
