import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import ReportQuestionBody from './ReportQuestionBody';

export default function ReportQuestionWrapper({ question, index }) {
  const { t } = useTranslation(['candidateDetail']);
  return (
    <div
      className="w-100 FlexColumn gap-12"
      style={{
        padding: '12px 14px 14px',
        borderRadius: '8px',
        background: 'rgba(244, 244, 244, 0.80)'
      }}
    >
      {/* Info Row */}
      <div
        className="FlexRow verticallyCenter"
        style={{
          lineHeight: '22px',
          padding: '0px 2px'
        }}
      >
        <span className="fw-400 font-16 c-darkGray" style={{ width: '44px' }}>
          {index + 1}
        </span>
        <span className="fw-400 font-16 c-darkGray" style={{ flexGrow: '1' }}>
          {question?.questionTitle}
        </span>
        <span
          className="FlexRow fw-400 font-16 c-darkGray"
          style={{
            justifyContent: 'end',
            marginRight: '28px',
            width: '30px',
            minWidth: '30px',
            color: question?.tabSwitch > 0 ? '#ca1515' : '#393939'
          }}
        >
          {question?.tabSwitch > 0 ? question?.tabSwitch : '-'}
        </span>

        <span
          className="FlexRow fw-400 font-16 c-darkGray"
          style={{
            justifyContent: 'end',
            width: '85px',
            minWidth: '85px'
          }}
        >
          {question?.time % 60 === 0 ? (
            <Trans
              i18nKey="qTimeMin"
              ns="candidateDetail"
              values={{ qTimeMin: question?.time / 60 }}
            >
              0<span>1</span>
              <span>2</span>
            </Trans>
          ) : question?.time > 60 ? (
            // ? `${Math.floor(question?.time / 60)}m ${question?.time % 60}s`
            <span>
              <Trans
                i18nKey="qTimeMinSec"
                ns="candidateDetail"
                values={{
                  qTimeMin: Math.floor(question?.time / 60),
                  qTimeSec: question?.time % 60
                }}
              >
                0<span>1</span>
                <span>2</span>
                <span>3</span>
                <span>4</span>
              </Trans>
            </span>
          ) : (
            // `${question?.time}s`
            <Trans
              i18nKey="qTimeSec"
              ns="candidateDetail"
              values={{ qTimeSec: question?.time }}
            >
              0<span>1</span>
              <span>2</span>
            </Trans>
          )}
        </span>

        <span
          className="FlexRow fw-400 font-16 c-darkGray"
          style={{
            justifyContent: 'end',
            width: '85px',
            minWidth: '85px'
          }}
        >
          {question?.point > 0 ? Math.round(question?.point) : 0}/{Math.round(question?.maxPoints)}
        </span>
      </div>

      {/* Question Row */}
      <div
        className="FlexColumn"
        style={{
          background: '#ffffff',
          borderRadius: '8px',
          padding: '14px 18px',
          margin: '0px 2px'
        }}
      >
        <ReportQuestionBody
          type={question?.questionTypeId}
          qBody={question?.questionDescription}
          mcCorrectAnswer={question?.questionAnswerOption}
          mcSelectedAnswer={question?.candidateAnswerOption}
          mcOptions={question?.options}
          ftCandidateAnswer={question?.candidateAnswerFreetext}
          cdCandidateAnswer={question?.candidateAnswerCode}
          cdCandidateCodeLang={question?.questionCategory}
          imageOpt={question?.imageOption}
        />
      </div>

      {/* Label Row */}
      <div className="FlexRow gap-12 verticallyCenter" style={{ lineHeight: '20px' }}>
        <span className="c-darkGray-06 fw-400 font-14 uppercase">
          {question?.questionTypeId === 1
            ? t('multipleChoice')
            : question?.questionTypeId === 2
            ? t('coding')
            : t('freeText')}
        </span>
        <span className="c-darkGray-06 fw-400 font-14 uppercase">
          &#x2022; &nbsp; {question?.difficulty}
        </span>

        {question?.point >= question?.maxPoints ? ( // Max point
          <div
            className="FlexRow verticallyCenter fw-500 font-13 uppercase"
            style={{
              borderRadius: '6px',
              padding: '4px 8px',
              marginLeft: 'auto',
              lineHeight: '19px',

              color: '#4ac389',
              background: 'rgba(74, 195, 137, 0.1)'
            }}
          >
            {t('correct')}
          </div>
        ) : question?.point === 0 ? ( // 0 point
          question?.questionTypeId === 1 && question?.candidateAnswerOption === -1 ? ( // Multiple choice ise ve candidateAnswerOption alanı -1 ise aday soruyu skiplemiş demek, bu durumda da Not Answered basmak istiyoruz
            <div
              className="FlexRow verticallyCenter fw-500 font-13 uppercase"
              style={{
                borderRadius: '6px',
                padding: '4px 8px',
                marginLeft: 'auto',
                lineHeight: '19px',

                color: '#585858',
                background: 'rgba(88, 88, 88, 0.2)'
              }}
            >
              {t('notAnswered')}
            </div>
          ) : question?.questionTypeId === 3 &&
            (question?.candidateAnswerFreetext === '' ||
              question?.candidateAnswerFreetext === 'null' ||
              question?.candidateAnswerFreetext === 'Null' ||
              question?.candidateAnswerFreetext === null) ? ( // Free text ise ve candidateAnswerFreetext alanı boşsa ("", "null", "Null", null), Not Answered basmak istiyoruz
            <div
              className="FlexRow verticallyCenter fw-500 font-13 uppercase"
              style={{
                borderRadius: '6px',
                padding: '4px 8px',
                marginLeft: 'auto',
                lineHeight: '19px',

                color: '#585858',
                background: 'rgba(88, 88, 88, 0.2)'
              }}
            >
              {t('notAnswered')}
            </div>
          ) : (
            <div
              className="FlexRow verticallyCenter fw-500 font-13 uppercase"
              style={{
                borderRadius: '6px',
                padding: '4px 8px',
                marginLeft: 'auto',
                lineHeight: '19px',

                color: '#ca1515',
                background: 'rgba(202, 21, 21, 0.1)'
              }}
            >
              {t('wrong')}
            </div>
          )
        ) : question?.point === -1 ? ( // -1 point
          <div
            className="FlexRow verticallyCenter fw-500 font-13 uppercase"
            style={{
              borderRadius: '6px',
              padding: '4px 8px',
              marginLeft: 'auto',
              lineHeight: '19px',

              color: '#585858',
              background: 'rgba(88, 88, 88, 0.2)'
            }}
          >
            {t('notAnswered')}
          </div>
        ) : (
          // Partial point
          <div
            className="FlexRow verticallyCenter fw-500 font-13 uppercase"
            style={{
              borderRadius: '6px',
              padding: '4px 8px',
              marginLeft: 'auto',
              lineHeight: '19px',

              color: '#ffb800',
              background: 'rgba(255, 184, 0, 0.1)'
            }}
          >
            {t('partiallyCorrect')}
          </div>
        )}
      </div>
    </div>
  );
}
