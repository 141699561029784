import API from '../../constant/apiVol2';
import client from '../../constant/indexVol2';

export const getCandidateAssessmentPublicLabelsService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCandidateAssessmentPublicLabels, { params: data }).then(resolve).catch(reject);
  });

export const getCandidateIsScoredService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCandidateIsScored, { params: data }).then(resolve).catch(reject);
  });

export const getCandidatePublicInfoService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCandidatePublicInfo, { params: data }).then(resolve).catch(reject);
  });

export const getCandidatePublicSolutionsService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCandidatePublicSolutions, { params: data }).then(resolve).catch(reject);
  });
