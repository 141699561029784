/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import TabSwitch from '../../../../Common/Components/TabSwitch';
import { setAssessmentActiveTab } from '../../../../redux/assessmentsVol2/slice';
import { candidateCvPathSelector } from '../../../../redux/candidateDetail/selectors';
import { useWindowSize } from '../../../../utils/helpers';
import OverviewTab from './OverviewTab';
import styles from './ProfileTab.module.css';
import ResumeTab from './ResumeTab';

const ProfileTab = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const candidateCV = useSelector(candidateCvPathSelector);

  const { t } = useTranslation(['candidateDetail']);

  useEffect(() => {
    dispatch(setAssessmentActiveTab(0));
    return () => {
      dispatch(setAssessmentActiveTab(0));
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <TabWrapper>
        <TabSwitch
          isDisabledList={[false, !candidateCV?.path]}
          tabNameList={[t('overview'), t('resume')]}
          tooltipList={[
            null,
            !candidateCV?.path
              ? t('notUploadCVTooltip')
              : null
          ]}
          onClickFuncList={[
            () => {
              setActiveTab(0);
            },
            () => {
              setActiveTab(1);
            }
          ]}
          height="37px"
          myStyle={{ fontSize: '16px', lineHeight: '23px' }}
          backgroundColor="#7458D3"
        />
      </TabWrapper>

      <>
        <div
          style={{
            visibility: activeTab === 1 ? 'hidden' : 'visible',
            maxHeight: activeTab === 1 && 0
          }}
        >
          <OverviewTab />
        </div>

        <div style={{ visibility: activeTab === 0 ? 'hidden' : 'visible' }}>
          <ResumeTab isHidden={activeTab === 0} />
        </div>
      </>
    </div>
  );
};

export default ProfileTab;

const TabWrapper = ({ children }) => {
  const [screenW] = useWindowSize();
  return screenW > 576 ? <>{children}</> : <div style={{ marginLeft: '20px' }}>{children}</div>;
};
