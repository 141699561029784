import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Skeleton from '../../Common/Components/Skeleton';
import ToolTip from '../../Common/Components/Tooltip';
import ShortText from '../../Common/Components/ShortText';
import { getCandidatePublicInfo } from '../../redux/CandidatePublic/selector';

import styles from '../Styles/InfoBlockWrapper.module.css';

import linkIcon from '../../images/Connections/linkedin.svg';
import mailIcon from '../../images/Candidate/PublicPage/mailIcon.svg';
import saveIcon from '../../images/Candidate/PublicPage/saveIcon.svg';
import telIcon from '../../images/Candidate/PublicPage/telIcon.svg';
import exportIcon from '../../images/Candidate/PublicPage/exportIcon.svg';
import infoIcon from '../../images/Candidate/PublicPage/cvTooltipIcon.svg';
import greyLinkedIn from '../../images/Candidate/PublicPage/greyLinkedin.svg';

function InfoBlockWrapper() {
  const reduxInfo = useSelector(getCandidatePublicInfo);

  const candidateEmail = reduxInfo?.email;

  const [candidateInfo, setCandidateInfo] = useState();
  const [cvPresent, isCvPresent] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cvPath, setCvPath] = useState();
  const [linkedinPath, setLinkedinPath] = useState();
  const [visiblePhoneArea, setVisiblePhoneArea] = useState();

  const publicPageCheck = () => {
    if (reduxInfo) {
      setCandidateInfo(reduxInfo);
      isCvPresent(reduxInfo?.cvPath !== null && reduxInfo?.cvPath !== '');
      setLoading(false);
    }
  };

  useEffect(() => {
    publicPageCheck();
  });


  useEffect(() => {
    if (candidateInfo) {
      // Cv Path update
      let cvpath = candidateInfo.cvPath;
      if (cvpath) {
        cvpath = cvpath.replace('https://api.coens.io', '');
        cvpath = cvpath.replace('https://coensioapi.solvera.com.tr', '');
        setCvPath(`${process.env.REACT_APP_IMAGE_API}${cvpath}`);
      }

      // Linkedin Path update
      const linkedinpath = candidateInfo.linkedIn;
      if (
        linkedinpath &&
        linkedinpath !== 'Null' &&
        linkedinpath !== 'NULL' &&
        linkedinpath.length > 0
      ) {
        if (linkedinpath.startsWith('https://www.linkedin')) {
          setLinkedinPath(linkedinpath);
        } else if (linkedinpath.startsWith('www.linkedin')) {
          let temp = '';
          temp = temp.concat('https://');
          temp = temp.concat(linkedinpath);
          setLinkedinPath(temp);
        } else if (linkedinpath.startsWith('linkedin')) {
          let temp = '';
          temp = temp.concat('https://www.');
          temp = temp.concat(linkedinpath);
          setLinkedinPath(temp);
        } else {
          let temp = '';
          temp = temp.concat('https://www.linkedin.com/in/');
          temp = temp.concat(linkedinpath);
          setLinkedinPath(temp);
        }
      } else {
        setLinkedinPath(null);
      }

      if (!candidateInfo.phone || candidateInfo.phone === '' || candidateInfo.phone === 'None') {
        setVisiblePhoneArea(false);
      } else {
        setVisiblePhoneArea(true);
      }

    }
  }, [candidateInfo, candidateEmail]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.backButtonInfoWrapper}>
        <div className={styles.goBack}>
          <div style={{ width: '34px' }} />
        </div>
        <div className={styles.infoWrapper}>
          <div className={styles.nameWrapper}>
            {loading ? (
              <Skeleton type="name" />
            ) : candidateInfo && candidateInfo?.name ? (
              <ShortText
                name={`${candidateInfo.name
                  .concat(' ')
                  .concat(candidateInfo.surname ? candidateInfo.surname : '')}`}
                textClassName={styles.nameText}
                type={1}
              />
            ) : (
              candidateInfo.email && (
                <ShortText name={candidateInfo.email} textClassName={styles.nameText} />
              )
            )}
            {linkedinPath ? (
              <a href={linkedinPath} target="_blank" rel="noreferrer" className={styles.linkIcon}>
                <img alt="" src={linkIcon} width="26px" height="26px" />
              </a>
            ) : (
              <img
                alt=""
                src={greyLinkedIn}
                className={styles.linkIcon}
                width="26px"
                height="26px"
              />
            )}
          </div>
          {/*  */}
          <div className={styles.infoText}>
            <img alt="" src={mailIcon} className={styles.icon} />

            {loading ? (
              <Skeleton type="email" />
            ) : (
              candidateInfo &&
              candidateInfo.email && <ShortText name={candidateInfo.email} type={0} />
            )}
          </div>

          {loading ? (
            <div className={styles.infoText}>
              <img alt="" src={telIcon} className={styles.icon} />
              <Skeleton type="email" />
            </div>
          ) : (
            visiblePhoneArea && (
              <div className={styles.infoText}>
                <img alt="" src={telIcon} className={styles.icon} />
                {candidateInfo && candidateInfo.phone && (
                  <ShortText name={candidateInfo.phone} type={0} />
                )}
              </div>
            )
          )}
        </div>
      </div>

      {loading ? (
        <Skeleton type="cv" />
      ) : (
        <div className={styles.cvWrapper}>
          <img
            alt=""
            src={saveIcon}
            className={styles.icon}
            style={!cvPresent ? { opacity: 0.2 } : { opacity: 1 }}
          />
          <span className={styles.cvText} style={!cvPresent ? { opacity: 0.2 } : { opacity: 1 }}>
            CV
          </span>

          {cvPresent ? (
            <a href={cvPath} target="_blank" rel="noreferrer">
              <img alt="" src={exportIcon} className={styles.icon} />
            </a>
          ) : (
            <ToolTip
              position="right"
              text="The reminder email has been sent to the candidate. Please wait for the candidate to upload a CV."
            >
              <img alt="" src={infoIcon} className={styles.icon} />
            </ToolTip>
          )}
        </div>
      )}
    </div>
  );
}

export default InfoBlockWrapper;
