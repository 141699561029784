/* eslint-disable no-return-assign */
import React from 'react';
import styles from './ContactSalesSuccess.module.css';
import { ReactComponent as Success } from '../../../images/Common/SFR_success.svg';
import { ReactComponent as PurpleSuccess } from '../../Images/purpleSuccess.svg';
import CloseButton from '../../../Common/Components/CloseButton';
import Modal from '../../../Common/CustomModal/Modal';

export default function ContactSalesSuccess({
  show,
  onHide,
  title,
  description,
  footer,
  icon,
}) {
  return (
    <Modal
      onClose={onHide}
      show={show}
      centerHorizontally
      centerVertically
      outsideClickClose
      contentClassName={`${styles.modalContent} ${styles['modal-content']}`}
    >
      <div className={styles.successContainer}>
        <CloseButton width={24} height={24} onClick={onHide} />
        {icon === 'tick' ? (
          <Success className={styles.successIcon} />
        ) : (
          <PurpleSuccess className={styles.successIcon} />
        )}
        {title && <div className={styles.successTitle}>{title}</div>}
        {description && <div className={styles.descText}>{description}</div>}
        {footer && <div className={styles.successFootNote}>{footer}</div>}
      </div>
    </Modal>
  );
}
