import React from 'react';
import styles from '../Styles/CandidateInformationTable.module.css';
import { ReactComponent as Point } from '../../images/Candidate/PublicPage/candidateEllipse.svg';
import AnimatedText from './AnimatedText';

export default function CandidateInformationTable({ score, industry }) {
  return (
    <div className={styles.wrapper}>
      <div
        className={`${styles.table} ${styles.candidate}`}
        style={
          Math.round(score) >= industry
            ? { color: '#4AC389' }
            : Math.round(score) >= industry * 0.8
            ? { color: '#FFD600' }
            : { color: '#FF7E62' }
        }
      >
        <div className={styles.row}>
          <Point
            fill={
              Math.round(score) >= industry
                ? '#4AC389'
                : Math.round(score) >= industry * 0.8
                ? '#FFD600'
                : '#FF7E62'
            }
            className={styles.point}
          />
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <span className={styles.text}>Candidate's Score</span>
        </div>
        <div className={styles.row}>
          {/* <span className={styles.score}>{score}</span> */}
          {score !== 'N/A' ? (
            <AnimatedText
              score={score}
              style={{
                fontFamily: 'Jost-500',
                fontSize: '16px',
                lineHeight: '23px',
                marginBottom: '10px',
                justifyContent: 'left'
              }}
            />
          ) : (
            <div
              style={{
                fontFamily: 'Jost-500',
                fontSize: '16px',
                lineHeight: '23px',
                marginBottom: '10px',
                justifyContent: 'left',
                marginLeft: '16px'
              }}
            >
              {score}
            </div>
          )}
        </div>
      </div>
      <div className={`${styles.table} ${styles.industry}`}>
        <div className={styles.row}>
          <Point fill="rgba(88, 88, 88, 0.8)" className={styles.point} />
          <span>Industry Average</span>
        </div>
        <div className={styles.row}>
          {industry >= 0 ? (
            <AnimatedText
              score={industry}
              style={{
                fontFamily: 'Jost-500',
                fontSize: '16px',
                lineHeight: '23px',
                marginBottom: '10px',
                justifyContent: 'left'
              }}
            />
          ) : (
            <div
              style={{
                fontFamily: 'Jost-500',
                fontSize: '16px',
                lineHeight: '23px',
                marginBottom: '10px',
                justifyContent: 'left',
                marginLeft: '16px'
              }}
            >
              N/A
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
