import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import styles from '../Styles/NameCustomAssessment.module.css';
import TextInput from '../../../Common/Components/TextInput';
import { setName } from '../../../redux/addAssessment/slice';
import { AssessmentName } from '../../../redux/addAssessment/selectors';
import CustomButton from '../../../Common/Components/CustomButton';

import { userSelector } from '../../../redux/auth/selectors';
import mixpanel from '../../../utils/mixpanel';
import { useWindowSize } from '../../../utils/helpers';

export default function NameCustomAssessment({ id }) {
  const assessmentName = useSelector(AssessmentName);
  const dispatch = useDispatch();
  const history = useHistory();
  const [windowW] = useWindowSize();

  const { t } = useTranslation(['createAssm'])

  /* Mixpanel pageview */
  const user = useSelector(userSelector);
  useEffect(() => {
    mixpanel.track('Create Assessment Page - Name Position Pageview', {
      'User Id': user.userId,
      'Company Name': user.companyName,
      'Name Surname': user.userNameSurname,
      'Root Assessment Id': id,
      'Root Assessment Name': localStorage.getItem(
        'Mixpanel_AssessmentCustomizationRootAssessmentName'
      )
    });
  }, []);

  return (
    <div className={styles.container}>
      <div>
        {windowW > 700 ? <div className={styles.title}>{t('namePosition')}</div> : null}
        <div className={styles.subtitle}>
          {windowW > 700
            ? t('addPositionNameText')
            : t('nameAssmText')}
        </div>
        <div className={styles.nameInputWrapper}>
          <TextInput
            inputstyle={{ height: windowW <= 700 && '50px' }}
            style={{ width: '100%', marginTop: windowW > 700 ? 24 : 0 }}
            value={assessmentName}
            placeholder={t('PositionNamePholder')}
            onChange={event => {
              dispatch(setName(event.target.value));
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                history.replace(`/create-assessment/${id}/${2}`);
              }
            }}
          />
        </div>
      </div>
      {windowW > 700 ? (
        <div>
          <div className={styles.buttonWrapper}>
            <CustomButton
              buttonOnClick={() => {
                history.replace(`/add-assessment`);
              }}
              type={5}
              textField={t('backLib')}
            />
            <CustomButton
              buttonOnClick={() => {
                history.replace(`/create-assessment/${id}/${2}`);
              }}
              isDisabled={!assessmentName}
              type={1}
              textField={t('next')}
            />
          </div>
        </div>
      ) : (
        <div className={styles.MobileButtonWrapper}>
          <CustomButton
            textField={t('next')}
            isDisabled={!assessmentName}
            type={1}
            size="mobile"
            buttonOnClick={() => {
              history.replace(`/create-assessment/${id}/${2}`);
            }}
          />
        </div>
      )}
    </div>
  );
}
