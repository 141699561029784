export const weightSum = state => state.addAssessmentState.weightSum;
export const index = state => state.addAssessmentState.index;
export const AssessmentName = state => state.addAssessmentState.AssessmentName;
export const funnelGroupIdSelector = state => state.addAssessmentState.funnelGroupId;
export const switchState = state => state.addAssessmentState.switchState;
export const atsState = state => state.addAssessmentState.atsState;
export const checkBoxState = state => state.addAssessmentState.checkBoxState;
export const userTypeOfAuthComponent = state => state.addAssessmentState.userTypeOfAuthComponent;
export const publishEnabled = state => state.addAssessmentState.publishEnabled;

export const rootAssmCategories = state => state.addAssessmentState.rootAssmCategories;
export const rootAssmJobRoles = state => state.addAssessmentState.rootAssmJobRoles;
export const rootAssmSeniorities = state => state.addAssessmentState.rootAssmSeniorities;
