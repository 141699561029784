import React from 'react';
import { useSelector } from 'react-redux';
import { candidateProfileSelector } from '../../../redux/candidateDetail/selectors';

import { ReactComponent as Phone } from '../CandidateDetail/Profile/phone.svg';
import { ReactComponent as Email } from '../CandidateDetail/Profile/email.svg';
import { ReactComponent as Location } from '../CandidateDetail/Profile/location.svg';

export default function ReportCandidateInfo() {
  const { email, fullName, headline, phone, location } = useSelector(candidateProfileSelector);

  return (
    <div
      className="FlexRow spaceBetween p-20"
      style={{ borderRadius: '10px', background: 'rgba(244, 244, 244, 0.80)' }}
    >
      <div className="FlexColumn gap-8">
        <span className="fw-500 font-16 c-darkGray">{fullName}</span>
        <span className="fw-400 font-14 c-darkGray">{headline || '-'}</span>
      </div>

      <div className="FlexColumn gap-10" style={{ minWidth: '200px' }}>
        <span
          className="FlexRow fw-400 font-14 c-darkGray gap-8 verticallyCenter ellipsis"
          style={{ maxWidth: '400px', lineHeight: '22px' }}
        >
          <Location style={{ minWidth: '14px', minHeight: '14px' }} />
          {location || '-'}
        </span>
        <span
          className="FlexRow fw-400 font-14 c-darkGray gap-8 verticallyCenter ellipsis"
          style={{ maxWidth: '400px', lineHeight: '22px' }}
        >
          <Phone style={{ minWidth: '14px', minHeight: '14px' }} />
          {phone || '-'}
        </span>
        <span
          className="FlexRow fw-400 font-14 c-darkGray gap-8 verticallyCenter ellipsis"
          style={{ maxWidth: '400px', lineHeight: '22px' }}
        >
          <Email style={{ minWidth: '14px', minHeight: '14px' }} />
          {email || '-'}
        </span>
      </div>
    </div>
  );
}
