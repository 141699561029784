import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import WelcomeCandidateContent from '../Components/WelcomeCandidateContent';
import {
  applicantAssessmentDetailAction,
  applicantAssessmentDetailWithEmailAction
} from '../../redux/AssessmentSession/actions';
import { PageLoading } from '../../redux/AssessmentSession/selectors';
import Loading from '../../Common/Components/Loading';
import mixpanel from '../../utils/mixpanel';
import styles from '../Styles/WelcomeCandidatePage.module.css';

import { setLoading } from '../../redux/AssessmentSession/slice';

const ContentBody = styled.div`
  width: 100vw;
  height: 100vh;
  display: block;
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const WelcomeCandidatePage = ({ application = false }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { id } = useParams();

  const email = new URLSearchParams(search).get('email');
  const assessmentId = new URLSearchParams(search).get('assessment');
  const arriveType = new URLSearchParams(search).get('ArriveType');

  // Kariyernet integration token
  const kariyernetToken = new URLSearchParams(search).get('ats_token');

  const history = useHistory();
  const pageLoading = useSelector(PageLoading);

  useEffect(() => {
    const a = setTimeout(() => {
      localStorage.removeItem('kariyernetToken');
      localStorage.removeItem('assessmentSessionToken');
      localStorage.removeItem('candidateEmail');
      localStorage.removeItem('companyName');
      localStorage.removeItem('isCandidateAlreadyPassed');
    }, 2000);

    return () => clearTimeout(a);
  }, []);

  function getUtmFromWelcomeCandidate() {
    const url = window.location.href;
    const array = url.split('&');
    const utmArray = [];
    array?.forEach(x => {
      if (x.includes('utm')) {
        utmArray.push(x);
      }
    });
    let utmUrl = '';
    if (utmArray.length > 0) {
      utmUrl = utmArray.join('&');
    }
    return utmUrl;
  }

  useEffect(async () => {
    if (kariyernetToken) {
      localStorage.setItem('kariyernetToken', kariyernetToken);
    }

    let resp;
    if (assessmentId || id) {
      const jsonData = {
        Key: id || assessmentId
      };
      resp = await dispatch(applicantAssessmentDetailAction(jsonData));
    }

    if (resp.meta.requestStatus === 'fulfilled') {
      if (application) {
        mixpanel.track('Application - Pageview', {
          'Assessment Name': resp?.payload?.companyAssessmentName,
          'Root Assessment Name': resp?.payload?.rootAssessmentName,
          'Company Name': resp?.payload?.companyName
        });
      } else {
        mixpanel.track('Welcome Candidate - Pageview', {
          'Assessment Name': resp?.payload?.companyAssessmentName,
          'Root Assessment Name': resp?.payload?.rootAssessmentName,
          'Company Name': resp?.payload?.companyName,
          'Candidate Email': email,
          'Is Mail Encrypted': !!email
        });
      }

      if (resp.payload.companyAssessmentObjStatusId === 0) {
        history.push('/not-found');
      }

      // isExpired check'i
      if (email && resp?.payload?.companyAssessmentObjStatusId === 1) {
        if (!application) {
          mixpanel.track('Welcome Candidate - Continue Button Click', {
            'Assessment Name': resp?.payload?.companyAssessmentName,
            'Root Assessment Name': resp?.payload?.rootAssessmentName,
            'Company Name': resp?.payload?.companyName,
            'Candidate Email': email,
            'Is Mail Encrypted': true
          });
        }

        const jsonData = { companyAssessmentKey: id || assessmentId, email };
        const innerResp = await dispatch(applicantAssessmentDetailWithEmailAction(jsonData));
        if (innerResp?.meta?.requestStatus === 'fulfilled') {
          const isPassed = innerResp.payload.isAlreadyPassed;
          localStorage.setItem('isCandidateAlreadyPassed', isPassed);

          // Welcome Candidate - Continue Button Success
          mixpanel.track('Welcome Candidate - Continue Button Success', {
            'Assessment Name': resp?.payload?.companyAssessmentName,
            'Root Assessment Name': resp?.payload?.rootAssessmentName,
            'Company Name': resp?.payload?.companyName,
            'Candidate Email': innerResp?.payload?.email,
            'Is Mail Encrypted': false,
            'Already Scored Flag': innerResp?.payload?.isAlreadyPassed,
            'CV Flag': !!innerResp?.payload?.cvPath
          });

          if (isPassed) {
            history.replace(`/candidate-result?assessment=${assessmentId}`);
          } else {
            const utms = getUtmFromWelcomeCandidate();
            history.push(
              `/assessment-session-information?assessment=${assessmentId}${utms && `&${utms}`}${
                arriveType ? `&ArriveType=${arriveType}` : ''
              }`
            );
            // history.push(`/assessment-session-information?assessment=${assessmentId}`);
          }
        } else {
          // Welcome Candidate - Continue Button Fail
          mixpanel.track('Welcome Candidate - Continue Button Fail', {
            'Assessment Name': resp?.payload?.companyAssessmentName,
            'Root Assessment Name': resp?.payload?.rootAssessmentName,
            'Company Name': resp?.payload?.companyName,
            'Candidate Email': email,
            'Is Mail Encrypted': true,
            'Error message': innerResp?.payload?.message || 'Mail in the link is wrong'
          });
          history.push('/not-found');
        }
      }
    } else {
      history.push('/not-found');
    }

    dispatch(setLoading(false));
  }, [assessmentId, id]);

  return (
    <ContentBody>
      <div className={styles.WelcomeCandidatePageWrapper}>
        {pageLoading ? (
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        ) : (
          <WelcomeCandidateContent application={application} id={id || assessmentId} />
        )}
      </div>
    </ContentBody>
  );
};

export default WelcomeCandidatePage;
