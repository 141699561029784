import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ResponsiveModal from '../../../../Common/Components/ResponsiveModal/ResponsiveModal';
import CloseButton from '../../../../Common/Components/CloseButton';
import { BlurredImageWrapper } from './BlurredImageWrapper';
import Checkbox from '../../../../Common/RawCheckbox/Checkbox';
import CustomButton from '../../../../Common/Components/CustomButton';
import constants from '../../../../utils/constants';
import { useWindowSize } from '../../../../utils/helpers';

export const AreYouSureModal = ({ show, onClose, deactivateMethod }) => {
  const { t } = useTranslation(['application']);
  const MOBILE_MODAL_THRESHOLD = constants.MOBILE_THRESHOLD;
  const [screenW] = useWindowSize();
  const [checkboxState, setCheckboxState] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    if (!show) {
      setCheckboxState(false);
    }
  }, [show]);

  const confirmClick = async () => {
    setButtonLoading(true);
    await deactivateMethod();
    setButtonLoading(false);
  };

  return (
    <ResponsiveModal
      show={show}
      onClose={onClose}
      contentStyle={{ padding: '24px', maxWidth: '600px', width: '100%' }}
      borderRadius={16}
      responsiveCondition={screenW > MOBILE_MODAL_THRESHOLD}
    >
      <div className="FlexColumn gap-24">
        <div className="FlexRow verticallyCenter spaceBetween">
          <span className="font-18 fw-500 c-darkGray">{t('autoApplyModalHeader')}</span>
          <CloseButton onClick={onClose} size="small" />
        </div>

        <span className="font-16 fw-400 c-darkGray">{t('autoApplyModalText')}</span>

        <BlurredImageWrapper />

        <div className="FlexRow gap-4">
          <Checkbox
            isChecked={checkboxState}
            setIsChecked={setCheckboxState}
            size={16}
            myStyle={{ margin: '4px 10px 0px' }}
          />
          <span className="font-16 fw-400 c-darkGray">{t('autoApplyModalCheckboxText')}</span>
        </div>

        <div
          className="FlexRow gap-10"
          style={screenW > MOBILE_MODAL_THRESHOLD ? { marginLeft: 'auto' } : {}}
        >
          <CustomButton
            textField={t('cancel')}
            type={2}
            buttonOnClick={onClose}
            customStyle={screenW > MOBILE_MODAL_THRESHOLD ? {} : { width: '100%' }}
          />
          <CustomButton
            textField={t('confirm')}
            type={1}
            buttonOnClick={confirmClick}
            customStyle={screenW > MOBILE_MODAL_THRESHOLD ? {} : { width: '100%' }}
            isDisabled={!checkboxState}
            loading={buttonLoading}
          />
        </div>
      </div>
    </ResponsiveModal>
  );
};
