import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { node, string, oneOfType, object, number, bool } from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  overflow: hidden;
`;
// Eğer tooltip bozulursa overflow: hidden'i kaldır.

const TooltipContent = styled.div`
  position: absolute;
  height: fit-content;
  width: fit-content;
  max-width: fit-content;
  white-space: wrap;
  background-color: ${({ theme }) => (theme === 'dark' ? '#585858' : '#fff')};
  color: ${({ theme }) => (theme === 'dark' ? '#fff' : '#585858')};
  padding: 8px;
  box-shadow: 0 3px 4px 3px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  font-size: 14px;
  opacity: ${({ active }) => (active ? '1' : '0')};
  animation: ${({ active }) => (active ? fadeIn : 'none')} 0.3s ease;
  transition: opacity 0.3s ease;
  z-index: 100000;
`;

const TooltipArrow = styled.div`
  position: absolute;
  border-width: 6px;
  border-style: solid;
  border-color: ${({ theme }) => (theme === 'dark' ? '#585858' : '#fff')} transparent transparent
    transparent;
`;

const Tooltip = ({
  id,
  content,
  children,
  offset,
  direction,
  theme,
  style,
  containerStyle,
  childrenStyle,
  delay,
  show
}) => {
  const [active, setActive] = useState(false);

  const tooltipWrapperRef = useRef(null);
  const sourceRef = useRef(null);
  const tooltipId = useRef(uuidv4());

  if (!tooltipWrapperRef.current) {
    tooltipWrapperRef.current = document.createElement('div');
    tooltipWrapperRef.current.id = tooltipId.current;
    document.body.appendChild(tooltipWrapperRef.current);
  }

  let timeout;

  const showTooltip = () => {
    clearTimeout(timeout); // Clear any existing timeout
    timeout = setTimeout(() => {
      if (show) setActive(true);
    }, delay);
  };

  const hideTooltip = () => {
    clearTimeout(timeout); // Clear the timeout if hideTooltip is called
    setActive(false);
  };

  const RenderTooltip = ({ direction }) => {
    const sourceRect = sourceRef?.current?.getBoundingClientRect();
    const firstDivChild = tooltipWrapperRef?.current?.querySelector('div');
    const tooltipContentRect = firstDivChild?.getBoundingClientRect();

    const [tooltipStyle, setTooltipStyle] = useState({
      top: 0,
      left: 0
    });

    useEffect(() => {
      if (active) {
        switch (direction) {
          case 'top':
            setTooltipStyle({
              bottom: window.innerHeight - sourceRect.top + (8 + offset) - window.scrollY,
              left:
                sourceRect.x +
                sourceRect.width / 2 -
                (tooltipContentRect?.width || 0) / 2 -
                window.scrollX
            });
            break;
          case 'right':
            setTooltipStyle({
              top:
                sourceRect.top +
                sourceRect.height / 2 -
                (tooltipContentRect?.height || 0) / 2 +
                window.scrollY,
              left: sourceRect.right + 8 + offset + window.scrollX
            });
            break;
          case 'bottom':
            setTooltipStyle({
              top: sourceRect.bottom + 8 + offset + window.scrollY,
              left:
                sourceRect.left +
                sourceRect.width / 2 +
                window.scrollX -
                (tooltipContentRect?.width || 0) / 2
            });
            break;
          case 'left':
            setTooltipStyle({
              top:
                sourceRect.top +
                sourceRect.height / 2 -
                (tooltipContentRect?.height || 0) / 2 +
                window.scrollY,
              right: window.innerWidth - sourceRect.left + (8 + offset) - window.scrollX
            });
            break;
          default:
            break;
        }
      }
    }, [firstDivChild, active]);

    return active
      ? ReactDOM.createPortal(
          <TooltipContent active={active} style={{ ...tooltipStyle, ...style }} theme={theme}>
            {content}
            <TooltipArrow style={getArrowStyle(direction)} theme={theme} />
          </TooltipContent>,
          tooltipWrapperRef.current
        )
      : null;
  };

  const getArrowStyle = direction => {
    switch (direction) {
      case 'top':
        return { top: '100%', left: '50%', transform: 'translateX(-50%) rotate(0deg)' };
      case 'right':
        return { top: '50%', left: '-12px', transform: 'translateY(-50%) rotate(90deg)' };
      case 'bottom':
        return { bottom: '100%', left: '50%', transform: 'translateX(-50%) rotate(180deg)' };
      case 'left':
        return { top: '50%', right: '-12px', transform: 'translateY(-50%) rotate(-90deg)' };
      default:
        return {};
    }
  };

  const source = (
    <TooltipContainer onMouseEnter={showTooltip} onMouseLeave={hideTooltip} style={containerStyle}>
      <span
        id={id}
        tabIndex="0"
        role="button"
        onFocus={showTooltip}
        onBlur={hideTooltip}
        key="0"
        aria-describedby={tooltipId.current}
        ref={sourceRef}
        style={{ ...childrenStyle }}
      >
        {children}
      </span>
      {/* {renderTooltip()} */}
      <RenderTooltip direction={direction} />
    </TooltipContainer>
  );

  return source;
};

Tooltip.propTypes = {
  /** Tooltip Id */
  id: string,
  /** Tooltip show */
  show: bool,
  /** Tooltip content */
  content: oneOfType([node, string]).isRequired,
  /** Wrapped node */
  children: node.isRequired,
  /** Tooltip offset */
  offset: number,
  /** Tooltip direction */
  direction: string,
  /** Tooltip Theme */
  theme: oneOfType(['light', 'dark']),
  /** Custom Tooltip styles */
  style: object,
  /** Custom children styles */
  childrenStyle: object,
  /** Tooltip Delay */
  delay: number
};

Tooltip.defaultProps = {
  id: '',
  show: true,
  offset: 5,
  direction: 'top',
  theme: 'light',
  style: {},
  childrenStyle: {},
  delay: 100
};

export default Tooltip;
