export default {
  pageNumber: 0,
  billingPeriod: '',
  assessmentNumber: null,
  candidateNumber: null,
  candidateReport: false,
  customQuestion: false,
  packageName: '',
  price: 0,
  currentPeriod: '',
  currentName: '',
  templateName: '',
  infoModal: false,
  infoText: 'Deneme',
  CSList: [],
  CompanyList: [],
  selectedCompany: { label: '', value: '' },
  templateList: [],
  customBundleId: '',
  createdCompanyId: null,
  createTempAndConnectTempActionSuccessCheck: null,
  cancelBundleSuccessCheck: null
};
