import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Dropdown from '../../../../Common/Dropdown/Dropdown';
import { setScreeningLocation } from '../../../../redux/AssessmentSession/slice';
import styles from './ScreeningCardComponent.module.css';

export default function LocationQuestion({ content, setWorkPermitState }) {
  const { t } = useTranslation(['application']);
  const dispatch = useDispatch();

  const { currentLocation: currentLocationId, locations: locationsList } = content || {};

  const [selectedLocation, setSelectedLocation] = useState(
    locationsList?.filter(x => x.id === currentLocationId)[0] || null
  );

  useEffect(() => {
    dispatch(setScreeningLocation(selectedLocation?.id));
  });

  // If Location question is showed, work permit question is not showed to the user,
  // so set it to pre-existing value here
  useEffect(() => {
    setWorkPermitState();
  }, []);

  return (
    <div className={styles.questionWrapper}>
      <div className={styles.question}>{t('locationQuestionText')}</div>

      <div className={styles.answer}>
        <Dropdown
          list={locationsList}
          height="4.8rem"
          isSearchable
          openItemCount={3}
          searchBarPlaceholder={t('locationDropdownPlaceholder')}
          title={t('locationDropdownTitle')}
          selectedOption={selectedLocation}
          setSelectedOption={setSelectedLocation}
        />
      </div>
    </div>
  );
}

LocationQuestion.propTypes = {
  content: PropTypes.object.isRequired,
  setWorkPermitState: PropTypes.func.isRequired
};
