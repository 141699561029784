import React from 'react';
import SearchVector from '../Images/searchVector.svg';
import SearchIcon from '../Images/searchIcon.svg';
import styles from '../Styles/SearchBar.module.css';
import CloseButton from './CloseButton';

function SearchBar({
  isExtended, // 1 === default search bar / 0 === click on icon to extend to search bar
  color, // white or gray
  size, // "small", "large" or "line" (%100 width)
  extendedIconOnClick, // onClick behavior of search icon "in" search bar
  inputFieldValue, // value of input field
  inputFieldPlaceholder, // placeholder for input field
  inputFieldOnChange, // onChange behavior of input field
  inputFieldOnFocus, // onFocus behavior of input field
  inputFieldOnKeyDown, // onKeyDown behavior of input field
  iconOnClick, // onClick behavior for clickable search icon before extend / If isExtended === 1, no need
  crossOnClick, // onClick behavior for cross icon in input field
  autoFocus, // If given, autoFocus is enabled
  extendedStyle,
  barId,
  ...props
}) {
  return (
    <div style={props.style}>
      {isExtended === 1 ? (
        <SearchBarExtended
          color={color}
          size={size}
          extendedIconOnClick={extendedIconOnClick}
          inputFieldValue={inputFieldValue}
          inputFieldPlaceholder={inputFieldPlaceholder}
          inputFieldOnChange={inputFieldOnChange}
          inputFieldOnFocus={inputFieldOnFocus}
          inputFieldOnKeyDown={inputFieldOnKeyDown}
          crossOnClick={crossOnClick}
          autoFocus={autoFocus}
          innerStyle={extendedStyle}
          barId={barId}
        />
      ) : (
        <button
          type="button"
          onClick={iconOnClick}
          style={{
            border: 'none',
            background: '#FFFFFF',
            outline: 'none',
            cursor: 'pointer'
          }}
        >
          <img
            src={size === 'xsmall' ? SearchVector : SearchIcon}
            alt=""
            className=""
            width="38px"
            height="38px"
          />
        </button>
      )}
    </div>
  );
}
export default SearchBar;

function SearchBarExtended({
  color,
  size,
  extendedIconOnClick,
  inputFieldValue,
  inputFieldPlaceholder,
  inputFieldOnChange,
  inputFieldOnFocus,
  inputFieldOnKeyDown,
  crossOnClick,
  autoFocus,
  innerStyle,
  barId
}) {
  return (
    <div
      id={barId}
      className={
        size === 'large'
          ? color === 'white'
            ? `${styles.SearchBarLarge} ${styles.SearchBarWhite}`
            : `${styles.SearchBarLarge} ${styles.SearchBarGrey}`
          : size === 'line'
          ? color === 'white'
            ? `${styles.SearchBarLine} ${styles.SearchBarWhite}`
            : `${styles.SearchBarLine} ${styles.SearchBarGrey}`
          : size === 'xsmall'
          ? color === 'white'
            ? `${styles.SearchBarxSmall} ${styles.SearchBarWhite}`
            : `${styles.SearchBarxSmall} ${styles.SearchBarGrey}`
          : color === 'white'
          ? `${styles.SearchBarSmall} ${styles.SearchBarWhite}`
          : `${styles.SearchBarSmall} ${styles.SearchBarGrey}`
      }
      style={innerStyle}
    >
      <button
        aria-label="search"
        type="button"
        style={{
          backgroundImage: size === 'xsmall' ? `url(${SearchVector})` : `url(${SearchIcon})`,
          marginLeft: size === 'xsmall' && '5px'
        }}
        className={styles.SearchIconButton}
        onClick={extendedIconOnClick}
      />
      <input
        id="Search"
        className={styles.SearchBarInputField}
        type="text"
        value={inputFieldValue}
        name="search"
        placeholder={inputFieldPlaceholder}
        onChange={inputFieldOnChange}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        onFocus={inputFieldOnFocus}
        onKeyDown={inputFieldOnKeyDown}
      />
      {crossOnClick && (
        <div className={styles.closeWrapper}>
          <CloseButton onClick={crossOnClick} size="small" />
        </div>
      )}
    </div>
  );
}
