import React from 'react';
import { useSelector } from 'react-redux';
import SettingsBar from '../../Components/SettingsBar';
import styles from './Settings.module.css';
import { availableSpaceSelector } from '../../../redux/user/selectors';
import PageContainer from '../../../Common/Pages/PageContainer';
import useSettingsPageErrorBanner from '../../Utils/useSettingsPageErrorBanner';
import useSettingsPageComponentHandler from '../../Utils/useSettingsPageComponentHandler';

const SettingsPage = () => {
  const availableWindowH = useSelector(availableSpaceSelector);

  const { SettingsPageInfoBanner } = useSettingsPageErrorBanner();
  const { SettingsPageContent } = useSettingsPageComponentHandler();

  return (
    <>
      <PageContainer>
        <div className={styles.layoutContainer} style={{ height: availableWindowH }}>
          <SettingsBar />
          <SettingsPageContent />
        </div>
      </PageContainer>

      <SettingsPageInfoBanner />
    </>
  );
};

export default SettingsPage;
