import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import Modal from '../../../../Common/CustomModal/Modal';
import styles from './EditUserModal.module.css';
import CloseButton from '../../../../Common/Components/CloseButton';
import { ReactComponent as Edit } from '../../../../images/Auth/Settings/billingEdit.svg';
import TextInput from '../../../../Common/Components/TextInput';
import EmailText from '../../../../Common/Components/EmailText';
import Dropdown from '../../../../Common/Components/CustomDropdown/CustomDropdown';
import { addUserToCompanyAction, getUserGroupTypeListAction } from '../../../../redux/user/actions';
import CustomButton from '../../../../Common/Components/CustomButton';
import { getProfileAction, updateMyProfileAction } from '../../../../redux/plan/actions';
import {
  clearUserData,
  setErrorInTeam,
  setErrorMessageInTeam,
  setSuccessInTeam,
  setSuccessMessageInTeam
} from '../../../../redux/auth/slice';
import MobileModal from '../../../../Common/Components/MobileModal/MobileModal';
import { useWindowSize } from '../../../../utils/helpers';
import { editTeamMemberAction, getTeamAction } from '../../../../redux/auth/actions';
import { userSelector } from '../../../../redux/auth/selectors';
import Checkbox from '../../../../Common/RawCheckbox/Checkbox';
import { ReactComponent as AddUser } from '../../../Images/addusermodal.svg';

const EditUserModal = ({ show, onClose, user, type }) => {
  // Format of users returned from MyProfile and GetTeam are different,
  // so we are modifying them
  const innerUser =
    type === 'profile'
      ? user
      : type === 'editTeamUser'
      ? {
          id: user?.userId,
          name: user?.name,
          surname: user?.surname,
          email: user?.email,
          userGroupType: user?.role,
          userGroupTypeId: user?.roleId,
          isAdmin: user?.isAdmin,
          isActive: user?.isActive
        }
      : null;

  const currentUser = useSelector(userSelector);
  const [screenW] = useWindowSize();
  const isMobile = screenW <= 576;
  const dispatch = useDispatch();

  const { t } = useTranslation(['teamTab'])

  const [name, setName] = useState();
  const [surname, setSurname] = useState();
  const [email, setEmail] = useState();
  const [isAdmin, setAdmin] = useState();

  const [errorDisabled, setErrorDisabled] = useState(false);

  const [groups, setGroups] = useState([]);
  const currentGroupType = groups?.filter(x => x.typeId === innerUser?.userGroupTypeId)[0];
  const [jobRole, setJobRole] = useState();

  const [dropdownShowFlag, setDropdownShowFlag] = useState(false);

  const [buttonLoading, setButtonLoading] = useState(false);

  const resetStates = () => {
    setName(innerUser?.name || '');
    setSurname(innerUser?.surname || '');
    setEmail(innerUser?.email || '');
    setAdmin(innerUser?.isAdmin || false);
    setJobRole();
  };

  useEffect(async () => {
    if (show) {
      const resp = await dispatch(getUserGroupTypeListAction());
      const innerGroups = resp.payload.map((x, index) => {
        return { typeId: x?.id, id: index + 1, title: x?.name, key: index + 1 };
      });
      setGroups(innerGroups);
      const innerCurrentGroupType = innerGroups?.filter(
        x => x.typeId === innerUser?.userGroupTypeId
      )[0];
      resetStates();
      setJobRole(innerCurrentGroupType || null);
      setDropdownShowFlag(true);
    } else {
      setTimeout(() => {
        setDropdownShowFlag(false);
      }, 100);
    }
  }, [show]);

  async function myProfileUpdate() {
    const rawData = {
      userId: innerUser?.id,
      name,
      surname,
      email,
      userGroupTypeId: jobRole?.typeId || innerUser?.userGroupTypeId,
      userGroupType: jobRole?.title || innerUser?.userGroupType
    };
    setButtonLoading(true);
    await dispatch(updateMyProfileAction(rawData));

    const data = {
      UserId: innerUser?.id
    };

    await dispatch(getProfileAction(data));
    dispatch(clearUserData());
    setButtonLoading(false);
    onClose();
  }

  async function editTeamMember() {
    setButtonLoading(true);
    const data = {
      id: innerUser?.id,
      name,
      surname,
      email,
      roleId: jobRole?.typeId,
      isAdmin
    };
    const a = await dispatch(editTeamMemberAction(data));
    if (a?.meta?.requestStatus === 'rejected') {
      dispatch(setErrorInTeam(true));
      dispatch(setErrorMessageInTeam(a?.payload?.message));
    } else {
      await dispatch(getTeamAction({ CompanyId: currentUser?.companyId }));
      dispatch(setSuccessInTeam(true));
      dispatch(setSuccessMessageInTeam(t('changesSaved')));
      if (currentUser?.userId === innerUser.id) {
        dispatch(clearUserData());
      }
    }
    setButtonLoading(false);
    onClose();
  }

  async function addNewUserMethod() {
    setButtonLoading(true);
    const data = {
      name,
      surname,
      email,
      userGroupTypeId: jobRole?.typeId,
      isAdmin
    };
    const a = await dispatch(addUserToCompanyAction(data));
    if (a?.meta?.requestStatus === 'rejected') {
      dispatch(setErrorInTeam(true));
      dispatch(setErrorMessageInTeam(a?.payload?.message));
    } else {
      await dispatch(getTeamAction({ CompanyId: currentUser?.companyId }));
    }
    setButtonLoading(false);
    onClose();
  }

  const saveButtonClick =
    type === 'profile'
      ? () => myProfileUpdate()
      : type === 'editTeamUser'
      ? () => editTeamMember()
      : type === 'addNewUser'
      ? () => addNewUserMethod()
      : null;

  const ContentHeader = ({ text, Icon = false }) => (
    <div className={styles.contentHeader}>
      <div className={styles.headerTextWrapper}>
        {Icon && <Icon />}
        {text}
      </div>
      <CloseButton size="small" onClick={onClose} />
    </div>
  );

  const InputFieldWrapper = (
    <div className={styles.inputFieldWrapper}>
      <div className={styles.nameSurnameWrapper}>
        <TextInput
          value={name}
          onChange={event => {
            setName(event.target.value);
          }}
          placeholder={t('name')}
          name="text"
          style={isMobile ? {} : { width: '50%' }}
        />
        <TextInput
          value={surname}
          onChange={event => {
            setSurname(event.target.value);
          }}
          placeholder={t('surname')}
          style={isMobile ? {} : { width: '50%' }}
        />
      </div>
      <EmailText
        errorDisable="true"
        setErrorDisable={setErrorDisabled}
        setValue={setEmail}
        value={email}
        placeholder={t('email')}
        isDisabled={innerUser?.isActive === false}
      />
    </div>
  );

  const RoleDropdown = () =>
    // Dropdown has to be conditional, otherwise its state does not reset between different popups
    dropdownShowFlag ? (
      <Dropdown
        list={groups}
        defaultSelectedId={jobRole?.id}
        placeholder={t('selectRole')}
        title={t('role')}
        visibleItemNo={2}
        setSelectedOption={setJobRole}
        wrapperStyle={
          innerUser?.userGroupType === 'Super Admin' && { pointerEvents: 'none', opacity: '0.4' }
        }
      />
    ) : null;

  const saveButtonIsDisabledFlag =
    type === 'profile'
      ? errorDisabled ||
        !email ||
        email.trim() === '' ||
        (name === innerUser?.name &&
          surname === innerUser?.surname &&
          email === innerUser?.email &&
          jobRole?.id === currentGroupType?.id)
      : type === 'editTeamUser'
      ? errorDisabled ||
        !email ||
        email.trim() === '' ||
        (name === innerUser?.name &&
          surname === innerUser?.surname &&
          isAdmin === innerUser?.isAdmin &&
          email === innerUser?.email &&
          jobRole?.id === currentGroupType?.id)
      : type === 'addNewUser'
      ? !name || !surname || !email || !jobRole?.id || errorDisabled
      : null;

  const ButtonRow = (
    <div className={styles.buttonRow}>
      <CustomButton
        buttonOnClick={onClose}
        type={2}
        textField={t('cancel')}
        customStyle={isMobile ? { width: '100%' } : {}}
      />
      <CustomButton
        isDisabled={saveButtonIsDisabledFlag}
        loading={buttonLoading}
        buttonOnClick={saveButtonClick}
        type={1}
        textField={t('save')}
        customStyle={isMobile ? { width: '100%' } : {}}
      />
    </div>
  );

  const IsAdminWrapper = () => (
    <div className={styles.isAdminWrapper} onClick={() => setAdmin(prev => !prev)}>
      <Checkbox size={16} isChecked={isAdmin} />
      <text className={styles.adminText}>{isAdmin ? t('admin') : t('makeAdmin')}</text>
    </div>
  );

  const MyProfileContent = (
    <>
      <ContentHeader text={t('editProfile')} Icon={Edit} />
      {InputFieldWrapper}
      <RoleDropdown />
      {ButtonRow}
    </>
  );

  const TeamUserContent = (
    <>
      <ContentHeader text={t('editUser')} Icon={Edit} />
      {InputFieldWrapper}
      <RoleDropdown />
      <IsAdminWrapper />
      {ButtonRow}
    </>
  );

  const AddNewUserContent = (
    <>
      <ContentHeader text={t('addUser')} Icon={AddUser} />
      {InputFieldWrapper}
      <RoleDropdown />
      <IsAdminWrapper />
      {ButtonRow}
    </>
  );

  const Web = (
    <Modal
      show={show}
      onClose={onClose}
      outsideClickClose
      centerHorizontally
      centerVertically
      size={{ width: '540px' }}
      contentStyle={{ borderRadius: '24px', padding: '18px 24px 24px' }}
    >
      <div className={styles.modalWrapper}>
        {type === 'profile'
          ? MyProfileContent
          : type === 'editTeamUser'
          ? TeamUserContent
          : type === 'addNewUser'
          ? AddNewUserContent
          : null}
      </div>
    </Modal>
  );

  const Mobile = (
    <MobileModal
      show={show}
      onClose={onClose}
      outsideClickClose
      contentStyle={{ padding: '20px 12px 20px 12px', minWidth: '350px' }}
      backdropStyle={{ overflowY: 'hidden' }}
    >
      <div className={styles.modalWrapper}>
        {type === 'profile'
          ? MyProfileContent
          : type === 'editTeamUser'
          ? TeamUserContent
          : type === 'addNewUser'
          ? AddNewUserContent
          : null}
      </div>
    </MobileModal>
  );

  return isMobile ? Mobile : Web;
};

export default EditUserModal;
