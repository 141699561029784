import React from 'react';
import smallClose from '../Images/smallCloseButton.svg';
import largeClose from '../Images/largeCloseButton.svg';

import styles from '../Styles/CloseButton.module.css';

function CloseButton({ onClick, size, width = null, height = null, ...props }) {
  return (
    <div
      className={styles.CloseButtonContainer}
      style={{
        'margin-bottom': props.marginBottom,
        marginRight: props.style && props.style.marginRight && props.style.marginRight,
        ...props.style
      }}
    >
      <img
        src={size === 'small' ? smallClose : largeClose}
        alt="close"
        // size="30"
        onClick={onClick}
        width={width}
        height={height}
        className={styles.CloseButtonImage}
        centered
      />
    </div>
  );
}

export default CloseButton;
