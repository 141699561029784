import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { getCameraShotsAction } from '../Api/action';
import CustomButton from '../../Common/Components/CustomButton';
import { ReactComponent as Camera } from '../Components/CandidateDetail/AssessmentReport/cameraIcon.svg';
import { getParam } from '../../utils/helpers';
import Modal from '../../Common/CustomModal/Modal';
import CloseButton from '../../Common/Components/CloseButton';

export default function useCameraShotHandler() {
  const { id } = useParams();
  const [imagePathList, setImagePathList] = useState();
  const [showModal, setShowModal] = useState(false);

  const { t } = useTranslation(['candidateDetail'])

  async function fetchCameraShots() {
    setImagePathList();
    const data = {
      CandidateId: id,
      CompanyAssessmentId: getParam(location.search, 'currentAssessmentId')
    };
    const resp = await getCameraShotsAction(data);
    setImagePathList(resp?.data);
  }

  useEffect(() => {
    fetchCameraShots();
    window.addEventListener('locationchange', fetchCameraShots);
    return () => {
      window.removeEventListener('locationchange', fetchCameraShots);
    };
  }, []);

  const ShowButton = useCallback(() => {
    if (imagePathList?.length > 0) {
      return (
        <CustomButton
          Icon={Camera}
          type={5}
          textField={t('seeScreenshots')}
          size="small"
          buttonOnClick={() => setShowModal(true)}
        />
      );
    }

    return null;
  }, [imagePathList]);

  const GalleryModal = (
    <GalleryModalComponent
      showModal={showModal}
      onClose={() => setShowModal(false)}
      imagePathList={imagePathList}
    />
  );

  return { ShowButton, GalleryModal };
}

const GalleryModalComponent = ({ showModal, onClose, imagePathList }) => {
  const staticTexts = {
    title: 'Candidate’s Screenshots'
  };

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [hoverIndex, setHoverIndex] = useState(null);
  const imageCount = imagePathList?.length;

  function imageOnClick(index) {
    setSelectedImageIndex(index);
  }

  useEffect(() => {
    if (showModal) setSelectedImageIndex(0);
  }, [showModal]);

  function prevClick() {
    if (selectedImageIndex === 0) setSelectedImageIndex(0);
    else setSelectedImageIndex(prev => prev - 1);
  }

  function nextClick() {
    if (selectedImageIndex === imageCount - 1) setSelectedImageIndex(imageCount - 1);
    else setSelectedImageIndex(prev => prev + 1);
  }

  const Counter = useCallback(() => {
    return (
      <div
        className="FlexRow gap-12 verticallyCenter"
        style={{
          padding: '10px 5px',
          borderRadius: '10px',
          background: 'rgba(255, 255, 255, 0.80)',
          width: 'fit-content'
        }}
      >
        <BackIcon onClick={() => prevClick()} />
        <span className="c-darkGray fw-400 font-16">
          {`${selectedImageIndex + 1} / ${imageCount}`}{' '}
        </span>
        <NextIcon onClick={() => nextClick()} />
      </div>
    );
  }, [selectedImageIndex, imageCount]);

  return (
    <Modal
      centerHorizontally
      centerVertically
      show={showModal}
      outsideClickClose
      onClose={onClose}
      contentStyle={{ borderRadius: '10px', padding: '18px 24px', maxWidth: '635px' }}
      contentClassName="FlexColumn gap-16 w-100"
    >
      <div className="FlexRow spaceBetween verticallyCenter">
        <span className="c-darkGray font-18 fw-500">{staticTexts.title}</span>
        <CloseButton onClick={onClose} size={24} />
      </div>

      <div
        className="FlexRow verticallyCenter horizontallyCenter"
        style={{
          backgroundColor: 'var(--gray-1)',
          borderRadius: '10px',
          maxWidth: '100%',
          position: 'relative'
        }}
      >
        {imageCount > 0 ? (
          <img
            loading="lazy"
            style={{ maxHeight: '440px', maxWidth: '100%', borderRadius: '10px' }}
            alt="Selected Candidate"
            src={`https://file.coens.io/${imagePathList[selectedImageIndex]?.imgUrl}`}
          />
        ) : null}

        <div style={{ position: 'absolute', bottom: '18px' }}>
          <Counter />
        </div>
      </div>

      <div className="FlexRow gap-12" style={{ maxWidth: '100%', overflowX: 'auto' }}>
        {imagePathList?.map((path, i) => (
          <div
            className="FlexRow verticallyCenter horizontallyCenter"
            style={{
              borderRadius: '10px',
              width: '116px',
              height: '84px',
              position: 'relative'
            }}
            onMouseEnter={() => setHoverIndex(i)}
            onMouseLeave={() => setHoverIndex()}
          >
            {hoverIndex === i ? (
              <div
                className="FlexRow verticallyCenter horizontallyCenter"
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  pointerEvents: 'none',
                  background: 'rgba(57, 57, 57, 0.20)',
                  borderRadius: '10px'
                }}
              >
                <ZoomIcon />
              </div>
            ) : null}

            <img
              loading="lazy"
              alt={`candidate-${i + 1}`}
              src={`https://file.coens.io/${path?.imgUrl}`}
              className="pointer"
              style={{
                maxHeight: '80px',
                maxWidth: '112px',
                margin: '2px',
                borderRadius: '10px',
                outline: i === selectedImageIndex ? '2px solid var(--coensio, #7458D3)' : null,
                background: 'var(--gray-1)'
              }}
              onClick={() => imageOnClick(i)}
            />
          </div>
        ))}
      </div>
    </Modal>
  );
};

const BackIcon = ({ onClick }) => (
  <svg
    onClick={onClick}
    className="pointer"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M15.204 5L8.25391 11.9501L15.204 18.9001"
      stroke="#393939"
      stroke-opacity="0.5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const NextIcon = ({ onClick }) => (
  <svg
    onClick={onClick}
    className="pointer"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M8.79604 5L15.7461 11.9501L8.79604 18.9001"
      stroke="#393939"
      stroke-opacity="0.5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const ZoomIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clip-path="url(#clip0_8422_30740)">
      <path
        d="M23.4104 20.59L19.2004 16.38C20.657 14.2198 21.2568 11.5944 20.8826 9.01598C20.5085 6.43754 19.1873 4.09091 17.1768 2.43378C15.1663 0.776652 12.6105 -0.0721385 10.0081 0.0529567C7.40564 0.178052 4.94311 1.26806 3.10078 3.11039C1.25845 4.95272 0.168441 7.41525 0.0433455 10.0177C-0.0817496 12.6201 0.76704 15.1759 2.42417 17.1864C4.0813 19.1969 6.42793 20.5181 9.00637 20.8923C11.5848 21.2664 14.2102 20.6666 16.3704 19.21L20.5904 23.42C20.9651 23.7925 21.472 24.0016 22.0004 24.0016C22.5288 24.0016 23.0357 23.7925 23.4104 23.42C23.5963 23.2343 23.7439 23.0137 23.8445 22.7709C23.9452 22.5281 23.997 22.2678 23.997 22.005C23.997 21.7422 23.9452 21.4819 23.8445 21.2391C23.7439 20.9963 23.5963 20.7757 23.4104 20.59ZM10.5004 2C12.1815 2 13.8249 2.49852 15.2227 3.43251C16.6206 4.3665 17.71 5.69402 18.3534 7.24719C18.9967 8.80036 19.165 10.5094 18.8371 12.1583C18.5091 13.8071 17.6995 15.3217 16.5108 16.5104C15.3221 17.6992 13.8075 18.5087 12.1587 18.8367C10.5098 19.1646 8.80075 18.9963 7.24758 18.353C5.69441 17.7096 4.36689 16.6202 3.4329 15.2223C2.49891 13.8245 2.00039 12.1811 2.00039 10.5C2.00304 8.24647 2.89942 6.086 4.49291 4.49252C6.08639 2.89903 8.24686 2.00265 10.5004 2Z"
        fill="white"
      />
      <path
        d="M7 11.5H9.5V14C9.5 14.2652 9.60536 14.5196 9.79289 14.7071C9.98043 14.8946 10.2348 15 10.5 15C10.7652 15 11.0196 14.8946 11.2071 14.7071C11.3946 14.5196 11.5 14.2652 11.5 14V11.5H14C14.2652 11.5 14.5196 11.3946 14.7071 11.2071C14.8946 11.0196 15 10.7652 15 10.5C15 10.2348 14.8946 9.98043 14.7071 9.79289C14.5196 9.60536 14.2652 9.5 14 9.5H11.5V7C11.5 6.73478 11.3946 6.48043 11.2071 6.29289C11.0196 6.10536 10.7652 6 10.5 6C10.2348 6 9.98043 6.10536 9.79289 6.29289C9.60536 6.48043 9.5 6.73478 9.5 7V9.5H7C6.73478 9.5 6.48043 9.60536 6.29289 9.79289C6.10536 9.98043 6 10.2348 6 10.5C6 10.7652 6.10536 11.0196 6.29289 11.2071C6.48043 11.3946 6.73478 11.5 7 11.5Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_8422_30740">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
