import React from 'react';

import { useTranslation } from 'react-i18next';

import styles from '../Styles/PlanUnsubscribedSuccess.module.css';
import CloseButton from '../../Common/Components/CloseButton';
import Modal from '../../Common/CustomModal/Modal';

export default function UnsubscribeSuccessModal({ show, onHide, ...props }) {
  const { t } = useTranslation(['planTab']);
  return (
    <Modal
      onClose={onHide}
      show={show}
      centerHorizontally
      centerVertically
      outsideClickClose
      contentClassName={`${styles.modalContent} ${styles['modal-content']}`}
    >
      <div className={styles.successContainer}>
        <div className={styles.titleContainer}>
          <div className={styles.successTitle}>{t('unsubModalTitle')}</div>
          <CloseButton width={24} height={24} onClick={onHide} />
        </div>
        <div className={styles.descText}>
          {t('unsubModalDesc')}
        </div>
        <div className={styles.successFootNote}>
          {t('unsubModalFooter')}
        </div>
      </div>
    </Modal>
  );
}
