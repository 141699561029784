import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAssessmentListForCandidateServiceVol2,
  getCameraShotsService,
  getCandidateAssessmentLabelsService,
  getCandidateSolutionsService,
  getPitTestResultsService,
  sendPitTestToCandidateService,
  getHrPeakTestResultsService,
  sendHrPeakTestToCandidateService,
  getCaseStudyService,
  uploadCaseStudyService,
  getPersonalityTestService,
  sendPersonalityTestService
} from './service';

export const getCandidateAssessmentLabelsAction = createAsyncThunk(
  '/getCandidateAssessmentLabelsAction',
  async (data, thunkAPI) => {
    try {
      const resp = await getCandidateAssessmentLabelsService(data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getAssessmentListForCandidateActionVol2 = createAsyncThunk(
  '/getAssessmentListForCandidateActionVol2 ',
  async (data, thunkAPI) => {
    try {
      return await getAssessmentListForCandidateServiceVol2(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCandidateSolutionsAction = createAsyncThunk(
  '/getCandidateSolutionsAction ',
  async (data, thunkAPI) => {
    try {
      return await getCandidateSolutionsService(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getPitTestResultsAction = async data => {
  try {
    const resp = await getPitTestResultsService(data);
    return resp;
  } catch (error) {
    return null;
  }
};

export const sendPitTestToCandidateAction = async data => {
  try {
    const resp = await sendPitTestToCandidateService(data);
    return resp;
  } catch (error) {
    return null;
  }
};

export const getCameraShotsAction = async data => {
  try {
    const resp = await getCameraShotsService(data);
    return resp;
  } catch (error) {
    return null;
  }
};

export const getHrPeakTestResultsAction = async data => {
  try {
    const resp = await getHrPeakTestResultsService(data);
    return resp;
  } catch (error) {
    return null;
  }
};

export const sendHrPeakTestToCandidateAction = async data => {
  try {
    const resp = await sendHrPeakTestToCandidateService(data);
    return resp;
  } catch (error) {
    return null;
  }
};

export const getCaseStudyAction = async data => {
  const resp = await getCaseStudyService(data);
  return resp;
};

export const uploadCaseStudyAction = async data => {
  const resp = await uploadCaseStudyService(data);
  return resp;
};

export const getPersonalityTestAction = async data => {
  const resp = await getPersonalityTestService(data);
  return resp;
};

export const sendPersonalityTestAction = async data => {
  const resp = await sendPersonalityTestService(data);
  return resp;
};
