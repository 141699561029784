/* eslint-disable */
import React, { useEffect, useState } from 'react';
import CandidateQuestionBar from './CandidateQuestionBar';
import CandidateQuestionDetail from './CandidateQuestionDetail';
import styles from '../Styles/CandidateQuestionWrapper.module.css';
import { useSelector } from 'react-redux';
import Skeleton from '../../Common/Components/Skeleton';
import { getCandidatePublicSolutions } from '../../redux/CandidatePublic/selector';

function CandidateQuestionWrapper() {
  const publicQuestion = useSelector(getCandidatePublicSolutions);

  const [questionArray, setQuestionArray] = useState();
  const [score, setScore] = useState();
  const [loading, setLoading] = useState(true);

  const [isScored, setIsScored] = useState(false);

  const publicPageCheck = () => {
    if (publicQuestion) {
      setQuestionArray(publicQuestion.solutionList);
      setScore(publicQuestion.totalScore);
      setIsScored(publicQuestion.totalScore >= 0)
      setLoading(false);
    }
  };

  useEffect(() => {
    publicPageCheck();
  }, []);

  return (
    <div className={styles.BodyWrapper}>
      {score !== -1 && isScored && <CandidateQuestionBar id="QuestionsBar" />}
      {loading && (
        <>
          <Skeleton type={'question'} />
          <Skeleton type={'question'} />
          <Skeleton type={'question'} />
        </>
      )}
      {(!loading && score === -1) ||
        (!isScored && !loading && (
          <div className={styles.InfoWrapper}>
            This candidate has not started the assessment yet. Therefore there are no test results
            to show.
          </div>
        ))}
      {!loading && score !== -1 && isScored && (
        <>
          {questionArray.length === 0 && (
            <div className={styles.InfoWrapper}>No question to show</div>
          )}
          {questionArray.length !== 0 && (
            <div
              className={styles.noSelect}
            >
              {questionArray.map((e, index) => (
                <CandidateQuestionDetail item={e} index={index} />
              ))}
            </div>
          )}
        </>
      )}

    </div>
  );
}

export default CandidateQuestionWrapper;
