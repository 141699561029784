import React, { useEffect, useState } from 'react';
import { fetchCustomFunnelList } from './helpers';

export default function useGetCustomFunnelHandler() {
  const [funnelGroupList, setFunnelGroupList] = useState();
  const [funnelLoading, setFunnelLoading] = useState(true);

  useEffect(async () => {
    setFunnelLoading(true);
    fetchCustomFunnelList()
      .then(x => setFunnelGroupList(x))
      .then(() => setFunnelLoading(false));
  }, []);

  return { funnelGroupList, setFunnelGroupList, funnelLoading };
}
