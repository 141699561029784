import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { ReactComponent as Share } from './share.svg';
import { ReactComponent as Phone } from './phone.svg';
import { ReactComponent as Email } from './email.svg';
import { ReactComponent as Location } from './location.svg';
import { ReactComponent as Linkedin } from './linkedin.svg';
import { getCandidateProfileAction } from '../../../../redux/candidateDetail/actions';
import styles from './CandidateProfile.module.css';
import CustomButton from '../../../../Common/Components/CustomButton';
import {
  candidatePositionsSelector,
  candidateProfileSelector,
  isAutoApplyCandidateSelector,
  loadingForPersonalInfoSelector,
  personalInfoExperienceSelector,
  personalInfoNationalitySelector,
  personalInfoSalarySelector,
  personalInfoWorkTypeSelector
} from '../../../../redux/candidateDetail/selectors';
import TextOverflow from '../../../../Common/Components/TextOverflow';
import { getParam, useWindowSize } from '../../../../utils/helpers';
import ShareProfileModal from '../ShareProfileModal/ShareProfileModal';
import anon from './anonymous.svg';
import Skeleton from '../../../../Common/Components/Skeleton';
import { userSelector } from '../../../../redux/auth/selectors';
import { genericMixpanelCandidateDetailEventGenerator } from '../helpers';

const CandidateProfile = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const profile = useSelector(candidateProfileSelector);
  const [screenW] = useWindowSize();
  const [profileLoading, setProfileLoading] = useState(true);

  const [shareShow, setShareShow] = useState(false);
  const [shareShow2, setShareShow2] = useState(false);

  const [linkedinPath, setLinkedinPath] = useState();

  const isAutoApplyCandidateFlag = useSelector(isAutoApplyCandidateSelector);

  const { t } = useTranslation(['candidateDetail']);

  function getProfileData() {
    setProfileLoading(true);
    const candidate = {
      UserId: id,
      CompanyAssessmentId: getParam(location.search, 'defaultAssessmentId')
    };
    dispatch(getCandidateProfileAction(candidate)).then(() => {
      setProfileLoading(false);
    });
  }

  // Mixpanel Share Profile - Click
  // #region

  const currentUser = useSelector(userSelector);
  const positionsList = useSelector(candidatePositionsSelector);
  const loadingForPersonalInfoRedux = useSelector(loadingForPersonalInfoSelector);
  const personalInfoExperienceRedux = useSelector(personalInfoExperienceSelector);
  const personalInfoNationalityRedux = useSelector(personalInfoNationalitySelector);
  const personalInfoSalaryRedux = useSelector(personalInfoSalarySelector);
  const personalInfoWorkTypeRedux = useSelector(personalInfoWorkTypeSelector);

  function mixpanelShareProfileEvent() {
    genericMixpanelCandidateDetailEventGenerator(
      'Share Profile - Click',
      currentUser,
      positionsList,
      profile,
      loadingForPersonalInfoRedux,
      personalInfoExperienceRedux,
      personalInfoNationalityRedux,
      personalInfoSalaryRedux,
      personalInfoWorkTypeRedux
    );
  }
  // #endregion

  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    if (shareShow) {
      setShareShow2(true);
    } else {
      setTimeout(() => {
        setShareShow2(false);
      }, 300);
    }
  }, [shareShow]);

  useEffect(() => {
    if (profile) {
      // Linkedin Path update
      const linkedinpath = profile?.profileUrl;
      if (
        linkedinpath &&
        linkedinpath !== 'Null' &&
        linkedinpath !== 'NULL' &&
        linkedinpath.length > 0
      ) {
        if (
          linkedinpath.startsWith('https://www.linkedin') ||
          linkedinpath.startsWith('https://linkedin.com')
        ) {
          setLinkedinPath(linkedinpath);
        } else if (linkedinpath.startsWith('www.linkedin')) {
          let temp = '';
          temp = temp.concat('https://');
          temp = temp.concat(linkedinpath);
          setLinkedinPath(temp);
        } else if (linkedinpath.startsWith('linkedin')) {
          let temp = '';
          temp = temp.concat('https://www.');
          temp = temp.concat(linkedinpath);
          setLinkedinPath(temp);
        } else {
          let temp = '';
          temp = temp.concat('https://www.linkedin.com/in/');
          temp = temp.concat(linkedinpath);
          setLinkedinPath(temp);
        }
      } else {
        setLinkedinPath(null);
      }
    }
  }, [profile]);

  // Anonymous candidate logo or initials of the candidate for pp
  const nameInvalidFlag = !profile.fullName || profile?.fullName.trim() === '';

  /* In case of both 'Requested' and 'Already in your pool',
    the hieararchy is as follows:
    Accepted-Declined-Pending >  Already in your Pool > New
  */
  const isAutoApplyCandidateText = t('appliedCoensio');

  const statusText = isAutoApplyCandidateFlag ? isAutoApplyCandidateText : null;

  const positionText = `${
    profile?.latestExperience && profile?.currentCompany ? `${profile?.latestExperience} at ` : ''
  }${profile?.currentCompany || '-'}`;

  // Wrapper for all Modals in Candidate Profile
  const Modals = (
    <>
      {shareShow2 && (
        <ShareProfileModal show={shareShow} onCloseModal={() => setShareShow(false)} />
      )}
    </>
  );

  // Candidate pp component
  const IconComponent = (
    <div
      style={{
        background: nameInvalidFlag ? '#F4F4F4' : ''
      }}
      className={styles.candidatePP}
    >
      {nameInvalidFlag ? (
        <img alt="anonymous-candidate" src={anon} />
      ) : (
        `${profile?.fullName?.split(/\s/)[0]?.slice(0, 1)}${profile?.fullName
          ?.split(/\s/)
          [profile?.fullName?.split(/\s/)?.length - 1]?.slice(0, 1)}`
      )}
    </div>
  );

  const TagComponent = (
    <>
      {statusText ? (
        <div id="profile-tag" className={styles.inPoolDiv}>
          {statusText}
        </div>
      ) : null}
    </>
  );

  // Share profile wrapper
  const ShareComponent = (
    <div className={styles.buttonWrapper} id="ShareProfileButton">
      <CustomButton
        Icon={Share}
        customStyle={{
          width: screenW <= 576 || (screenW < 1150 && screenW > 960) ? '101px' : '151px'
        }}
        type={5}
        textField={screenW <= 576 || (screenW < 1150 && screenW > 960) ? t('share') : t('shareProfile')}
        size="small"
        buttonOnClick={() => {
          mixpanelShareProfileEvent();
          setShareShow(true);
        }}
      />
    </div>
  );

  // Web view - Candidate name row + job + linkedin for unlocked candidates
  const WebUpperPartUnlockedCandidate = (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: '10px'
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px'
          }}
        >
          <TextOverflow
            text={
              profile?.fullName && profile?.fullName !== ' ' ? profile?.fullName : profile?.email
            }
            className={styles.candidateName}
          />

          {TagComponent}
        </div>

        {ShareComponent}
      </div>

      <TextOverflow
        text={positionText}
        className={styles.candidateJob}
        style={{
          fontSize: profile && !profile?.email && '25px',
          fontFamily: profile && !profile?.email && 'Jost-300'
        }}
      />

      {linkedinPath ? (
        <a
          style={{
            width: 'fit-content',
            cursor: 'pointer',
            opacity: '1'
          }}
          href={linkedinPath}
          target="_blank"
          rel="noreferrer"
        >
          <Linkedin />
        </a>
      ) : (
        <div>&nbsp;</div>
      )}
    </>
  );

  // Web view - Candidate info wrapper
  const WebUpperPart = <div className={styles.infoBoxWrapper}>{WebUpperPartUnlockedCandidate}</div>;

  // Mobile view - Candidate name row + job + linkedin for unlocked candidates
  const MobileUpperPartUnlockedCandidate = (
    <>
      <div className={styles.lockedInfo}>
        <TextOverflow
          text={profile?.fullName && profile?.fullName !== ' ' ? profile?.fullName : profile?.email}
          className={styles.candidateName}
        />
        {TagComponent}
      </div>

      <TextOverflow
        text={positionText}
        className={styles.candidateJob}
        style={{
          fontSize: profile && !profile?.email && '25px',
          fontFamily: profile && !profile?.email && 'Jost-300'
        }}
      />

      {linkedinPath ? (
        <a
          style={{
            width: 'fit-content',
            cursor: 'pointer',
            opacity: '1'
          }}
          href={linkedinPath}
          target="_blank"
          rel="noreferrer"
        >
          <Linkedin />
        </a>
      ) : (
        <div>&nbsp;</div>
      )}
    </>
  );

  // Mobile view - Candidate info wrapper
  const MobileUpperPart = (
    <>
      <div className={styles.infoBoxWrapper}>{MobileUpperPartUnlockedCandidate}</div>
    </>
  );

  // Candidate contact information wrapper for unlocked candidates
  const LowerPartUnlockedCandidate = (
    <>
      <div className={styles.footerInfoWrapper}>
        <Location style={{ minWidth: '14px', minHeight: '14px' }} />
        <TextOverflow
          text={profile?.location || '-'}
          className={styles.infoText}
          style={{
            minWidth: '30px',
            fontSize: !profile?.location && '25px',
            fontFamily: !profile?.location && 'Jost-300'
          }}
        />
      </div>
      <div className={styles.footerInfoWrapper}>
        <Phone style={{ minWidth: '14px', minHeight: '14px' }} />
        <div
          style={{
            fontSize: !profile?.phone && '25px',
            fontFamily: !profile?.phone && 'Jost-300'
          }}
          className={styles.infoText}
          id="candidate-detail-phone"
        >
          {profile?.phone || '-'}
        </div>
      </div>
      <div className={styles.footerInfoWrapper}>
        <Email style={{ minWidth: '14px', minHeight: '14px' }} />

        <TextOverflow
          text={profile?.email || '-'}
          className={styles.infoText}
          style={{
            minWidth: '30px',
            fontSize: !profile?.email && '25px',
            fontFamily: !profile?.email && 'Jost-300'
          }}
        />
      </div>
    </>
  );

  // Web view - candidate contact information wrapper
  const WebLowerPart = <div className={styles.footerWrapper}>{LowerPartUnlockedCandidate}</div>;

  // Mobil view - candidate contact information wrapper
  const MobilLowerPart = <div className={styles.footerWrapper}>{LowerPartUnlockedCandidate}</div>;

  return (
    <>
      {profileLoading ? (
        <Skeleton type="candidate-profile" />
      ) : (
        <div className={styles.wrapper} id="candidate-profile-wrapper">
          {Modals}

          <div className={styles.contentWrapper} style={{ marginBottom: screenW <= 576 && '18px' }}>
            <div style={{ display: 'flex', width: '100%' }}>
              {IconComponent}

              {screenW > 576 ? WebUpperPart : null}
            </div>

            {screenW < 576 && ShareComponent}
          </div>

          {screenW <= 576 && MobileUpperPart}

          {screenW > 576 ? WebLowerPart : MobilLowerPart}
        </div>
      )}
    </>
  );
};
export default CandidateProfile;
