import React, { useState, useEffect } from 'react';
import MonacoEditor from '@monaco-editor/react';
import Dropdown from 'react-dropdown';
import { useDispatch, useSelector } from 'react-redux';

import {
  languages,
  variableTypeDict,
  initialCodeGeneration
} from '../Constants/codingLanguageConstants';

import styles from '../Styles/Coding.module.css';
import {
  initialSelectedLangRedux,
  outputVariableRedux,
  parametersRedux,
  testCasesRedux,
  validCasesRedux,
  verificationSelectedLangRedux
} from '../../../redux/addQuestion/selectors';
import {
  setInitialCodeDictionaryRedux,
  setInitialSelectedLangRedux,
  setIsPassedVerificationRedux,
  setOutputVariableRedux,
  setParametersRedux,
  setTestCasesRedux,
  setValidCasesRedux,
  setVerificationCodeRedux,
  setVerificationCodeResponseRedux
} from '../../../redux/addQuestion/slice';

function FunctionSignature() {
  const dispatch = useDispatch();
  const reduxOutputVariable = useSelector(outputVariableRedux);
  const reduxParameters = useSelector(parametersRedux);
  const reduxTestCases = useSelector(testCasesRedux);
  const reduxValidCases = useSelector(validCasesRedux);
  const reduxInitialSelectedLanguage = useSelector(initialSelectedLangRedux);
  const reduxSelectedLanguage = useSelector(verificationSelectedLangRedux);

  // Function signature - parameter control
  const [numberOfParams, setNumberOfParams] = useState(1);
  const [arrayElementChange, setArrayElementChange] = useState(0);
  const [outputChange, setOutputChange] = useState(0);
  const [error, setError] = useState('');
  const [paramError, setParamError] = useState({ name: '', index: 0 });
  /// /////////////////////////////////////////////////////////////////////////////////////////////////
  // Language Selection on Monaco Editor
  /// /////////////////////////////////////////////////////////////////////////////////////////////////
  // const [selectedLanguage, setSelectedLanguage] = useState({ value: 'python', label: 'Python' });

  useEffect(async () => {
    setNumberOfParams(reduxParameters.length);
  }, []);

  const onDropdownSelectMonaco = lang => {
    // setSelectedLanguage(lang);
    dispatch(setInitialSelectedLangRedux(lang));
    dispatch(setOutputVariableRedux({ ...reduxOutputVariable }));
  };

  const onDropdownSelectOutput = variable => {
    dispatch(setOutputVariableRedux({ ...reduxOutputVariable, type: variable.value }));
  };

  const outputNameUpdate = val => {
    dispatch(setOutputVariableRedux({ ...reduxOutputVariable, name: val }));
  };
  /// /////////////////////////////////////////////////////////////////////////////////////////////////
  // Check error conditions
  /// /////////////////////////////////////////////////////////////////////////////////////////////////

  const getErrorMessage = name => {
    const names = reduxParameters.map(x => x.name); // List of names of the parameters
    if (names?.indexOf(name) !== -1) {
      setError('Function name can not be same as one of the parameters');
    } else {
      if (error) {
        setParamError({ name: '', index: 0 });
      }
      setError('');
    }
  };
  const getParamErrorMessage = (paramName, i) => {
    const names = reduxParameters.map(x => x.name); // List of names of the parameters
    if (reduxOutputVariable.name === paramName) {
      setParamError({ name: 'Parameter name can not be same as function name', index: i });
    } else if (names?.indexOf(paramName) !== -1) {
      setParamError({ name: 'Parameter names can not be same', index: i });
    } else {
      if (paramError) {
        setError('');
      }
      setParamError({ name: '', index: 0 });
    }
  };
  /// /////////////////////////////////////////////////////////////////////////////////////////////////
  // Storage of Parameters in useStates
  /// /////////////////////////////////////////////////////////////////////////////////////////////////

  const onChangeResetVerificationCode = () => {
    dispatch(setIsPassedVerificationRedux(false));
    const init = initialCodeGeneration(reduxOutputVariable, reduxParameters);
    dispatch(setVerificationCodeRedux(init[reduxSelectedLanguage.value]));
    dispatch(setVerificationCodeResponseRedux(''));
  };

  const addParameter = () => {
    setNumberOfParams(numberOfParams + 1);
    onChangeResetVerificationCode();

    const testTemp = [];
    reduxTestCases.forEach((e, i) => {
      testTemp.push({});
      // testTemp[i].name= e.name;
      const innerInput = e.input.slice();
      innerInput.push('');
      testTemp[i].input = innerInput;
      testTemp[i].result = e.result;
      testTemp[i].weight = e.weight;
    });
    dispatch(setTestCasesRedux(testTemp));

    const validTemp = [];
    reduxValidCases.forEach((e, i) => {
      validTemp.push({});
      // validTemp[i].name= e.name;
      const innerInput = e.input.slice();
      innerInput.push('');
      validTemp[i].input = innerInput;
      validTemp[i].result = e.result;
      validTemp[i].weight = e.weight;
    });
    dispatch(setValidCasesRedux(validTemp));
  };

  const removeParameter = index => {
    const temp = reduxParameters.slice();
    temp.splice(index, 1);
    dispatch(setParametersRedux(temp));
    setNumberOfParams(numberOfParams - 1);

    const testTemp = [];
    reduxTestCases.forEach((e, i) => {
      testTemp.push({});
      // testTemp[i].name= e.name;
      const innerInput = e.input.slice();
      innerInput.splice(index, 1);
      testTemp[i].input = innerInput;
      testTemp[i].result = e.result;
      testTemp[i].weight = e.weight;
    });
    dispatch(setTestCasesRedux(testTemp));

    const validTemp = [];
    reduxValidCases.forEach((e, i) => {
      validTemp.push({});
      // validTemp[i].name= e.name;
      const innerInput = e.input.slice();
      innerInput.splice(index, 1);
      validTemp[i].input = innerInput;
      validTemp[i].result = e.result;
      validTemp[i].weight = e.weight;
    });
    dispatch(setValidCasesRedux(validTemp));
  };

  useEffect(async () => {
    if (numberOfParams > reduxParameters.length) {
      const temp = reduxParameters.slice();
      temp.push({ type: '', name: '' });
      dispatch(setParametersRedux(temp));
    }
  }, [numberOfParams]);

  const onDropdownSelectParameter = (variable, index) => {
    const temp = [];
    reduxParameters.forEach((e, i) => {
      temp.push({});
      temp[i].type = e.type;
      temp[i].name = e.name;
    });
    temp[index].type = variable.value;
    dispatch(setParametersRedux(temp));
  };

  const parameterNameUpdate = (val, index) => {
    const temp = [];
    reduxParameters.forEach((e, i) => {
      temp.push({});
      temp[i].type = e.type;
      temp[i].name = e.name;
    });
    temp[index].name = val;
    dispatch(setParametersRedux(temp));
  };

  /// /////////////////////////////////////////////////////////////////////////////////////////////////
  // Initial Codes for Monaco Editor based on parameter selections
  /// /////////////////////////////////////////////////////////////////////////////////////////////////
  const [initialCode, setInitialCode] = useState('');
  useEffect(async () => {
    const init = initialCodeGeneration(reduxOutputVariable, reduxParameters);
    dispatch(setInitialCodeDictionaryRedux(init));
    setInitialCode(init[reduxInitialSelectedLanguage.value]);
    setArrayElementChange(0);
  }, [
    reduxInitialSelectedLanguage,
    reduxOutputVariable,
    reduxParameters,
    numberOfParams,
    arrayElementChange
  ]);

  return (
    <div className={styles.RightPane}>
      <div className={styles.FunctionSignature}>
        <p>
          <b className={styles.funcP}>Function Signature</b>
        </p>
        <p className={styles.funcP}>
          Enter your function name and its returns to create initial function
        </p>
        <div
          className={`d-block w-100 p-2  ${styles.FormInput}`}
          style={{ 'background-color': 'white' }}
        >
          Output
          <div
            className="output"
            style={{ display: 'grid', 'grid-template-columns': '10fr 11fr', 'grid-gap': '5px' }}
          >
            <Dropdown
              className={styles.CodingQuestionOutputDropdown}
              options={variableTypeDict[reduxInitialSelectedLanguage.value]}
              onChange={val => {
                onDropdownSelectOutput(val);
                setOutputChange(1);
                onChangeResetVerificationCode();
              }}
              value={reduxOutputVariable.type}
            />
            <input
              className="output"
              placeholder="Name"
              type="text"
              name="output_name"
              onChange={val => {
                let temp = val.target.value.replace(' ', '');
                if (temp.length > 0 && temp[0] === temp[0].toUpperCase()) {
                  temp = temp.replace(temp[0], temp[0].toLowerCase());
                }
                getErrorMessage(temp);
                outputNameUpdate(temp);
                setOutputChange(1);
                onChangeResetVerificationCode();
              }}
              value={reduxOutputVariable.name}
            />
          </div>
          {error && <span className={styles.error}>{error}</span>}
        </div>
        <br />
        <div
          className={`d-block w-100 p-2  ${styles.FormInput}`}
          style={{ 'background-color': 'white' }}
        >
          Parameters
          <div className="input" id="input">
            <input type="button" value="Add Input" onClick={addParameter} /> <br /> <br />
            {reduxParameters.map((e, i) => (
              <div
                style={{
                  display: 'grid',
                  'grid-template-columns': '10fr 10fr 1fr',
                  'grid-gap': '5px',
                  marginBottom: '2px'
                }}
              >
                <Dropdown
                  className={styles.CodingQuestionOutputDropdown}
                  // style={{ 'grid-column-start': '1' }}
                  options={variableTypeDict[reduxInitialSelectedLanguage.value]}
                  onChange={val => {
                    onDropdownSelectParameter(val, i);
                    setArrayElementChange(1);
                    onChangeResetVerificationCode();
                  }}
                  value={e.type}
                />
                <input
                  className="output"
                  style={{ 'grid-column-start': '2' }}
                  placeholder="Name"
                  type="text"
                  name="output_name"
                  onChange={val => {
                    let temp = val.target.value.replace(' ', '');
                    if (temp.length > 0 && temp[0] === temp[0].toUpperCase()) {
                      temp = temp.replace(temp[0], temp[0].toLowerCase());
                    }
                    parameterNameUpdate(temp, i);
                    getParamErrorMessage(temp, i);
                    setArrayElementChange(1);
                    onChangeResetVerificationCode();
                  }}
                  value={e.name}
                />
                <button
                  type="button"
                  style={{ 'grid-column-start': '3' }}
                  onClick={() => {
                    removeParameter(i);
                    setNumberOfParams(numberOfParams - 1);
                    setArrayElementChange(1);
                    onChangeResetVerificationCode();
                  }}
                >
                  X
                </button>
                {paramError && paramError.index === i && (
                  <span className={styles.paramError}>{paramError.name}</span>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={styles.FunctionSignatureCompilerWrapper}>
        <div
          className={styles.FunctionSignatureCompilerDropdownRow}
          style={{ 'margin-bottom': '10px' }}
        >
          <div style={{ 'grid-column-start': '1' }}>Initial Code</div>
          <div
            style={{
              'grid-column-start': '2',
              display: 'flex',
              'flex-direction': 'row',
              alignItems: 'center'
            }}
          >
            Initial Code Language:
            <Dropdown
              className={styles.FunctionSignatureCompilerDropdown}
              options={languages}
              onChange={onDropdownSelectMonaco}
              value={reduxInitialSelectedLanguage.value}
            />
          </div>
        </div>
        <div className={styles.FunctionSignatureInitialCode}>
          <MonacoEditor
            defaultLanguage={reduxInitialSelectedLanguage.value}
            defaultValue={reduxInitialSelectedLanguage.label}
            theme="vs-dark"
            language={reduxInitialSelectedLanguage.value}
            value={initialCode}
            options={{ wordWrap: 'on', minimap: { enabled: false }, readOnly: true }}
          />
        </div>
      </div>
    </div>
  );
}

export default FunctionSignature;
