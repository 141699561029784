import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { candidateReportPublicLinkSelector } from '../../redux/candidateDetail/selectors';
import { userSelector } from '../../redux/auth/selectors';
import constants from '../../utils/constants';
import CustomButton from '../../Common/Components/CustomButton';

export default function usePublicReportButtonHandler() {
  const publicReportLink = useSelector(candidateReportPublicLinkSelector);
  const userRedux = useSelector(userSelector);
  const showPublicReportFlag = constants.SHOW_PUBLIC_REPORT_BUTTON?.includes(
    userRedux?.companyName
  );

  const PublicReportButton = useCallback(
    () =>
      showPublicReportFlag ? (
        <CustomButton
          Icon={Icon}
          type={5}
          textField="Skills V."
          size="small"
          buttonOnClick={() => window.open(publicReportLink, '_blank').focus()}
        />
      ) : null,
    [showPublicReportFlag, publicReportLink]
  );

  return { PublicReportButton };
}

const Icon = ({ ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.6496 10.8516C16.4682 10.8516 16.2943 10.9236 16.1661 11.0519C16.0378 11.1801 15.9658 11.354 15.9658 11.5354V18.6328H5.36752V8.03451H12.4581C12.6395 8.03451 12.8134 7.96247 12.9416 7.83424C13.0698 7.70601 13.1419 7.5321 13.1419 7.35075C13.1419 7.16941 13.0698 6.99549 12.9416 6.86726C12.8134 6.73903 12.6395 6.66699 12.4581 6.66699H5.27863C4.93952 6.66699 4.61429 6.8017 4.3745 7.0415C4.13471 7.28129 4 7.60651 4 7.94562V18.7149C4 19.0546 4.13449 19.3805 4.37408 19.6214C4.61368 19.8623 4.93889 19.9985 5.27863 20.0003H16.0479C16.3888 20.0003 16.7158 19.8649 16.9568 19.6238C17.1979 19.3827 17.3333 19.0558 17.3333 18.7149V11.5354C17.3333 11.354 17.2613 11.1801 17.1331 11.0519C17.0048 10.9236 16.8309 10.8516 16.6496 10.8516Z"
      fill="var(--coensio)"
    />
    <g clip-path="url(#clip0_1398_10765)">
      <path
        d="M19.237 4.84375H15.477C15.4271 4.8464 15.379 4.86336 15.3385 4.89259C15.2979 4.92182 15.2667 4.96209 15.2484 5.00861C15.2274 5.05669 15.2218 5.11005 15.2323 5.16143C15.2428 5.21282 15.2688 5.25973 15.3069 5.29579L16.5247 6.51896C16.5374 6.5309 16.5476 6.54532 16.5545 6.56132C16.5614 6.57733 16.565 6.59459 16.565 6.61203C16.565 6.62947 16.5614 6.64673 16.5545 6.66274C16.5476 6.67875 16.5374 6.69316 16.5247 6.7051L10.2121 13.0071C10.1375 13.0808 10.0782 13.1686 10.0378 13.2653C9.99738 13.3621 9.97656 13.4659 9.97656 13.5708C9.97656 13.6757 9.99738 13.7795 10.0378 13.8763C10.0782 13.9731 10.1375 14.0608 10.2121 14.1345C10.3617 14.2839 10.5644 14.3678 10.7758 14.3678C10.9872 14.3678 11.19 14.2839 11.3395 14.1345L17.6362 7.83254C17.6625 7.809 17.6966 7.79598 17.7319 7.79598C17.7673 7.79598 17.8013 7.809 17.8277 7.83254L19.0508 9.05572C19.0746 9.08126 19.1034 9.10156 19.1355 9.1153C19.1675 9.12903 19.2021 9.13591 19.237 9.13549C19.2702 9.14302 19.3047 9.14302 19.338 9.13549C19.3901 9.11428 19.4341 9.07712 19.4637 9.02935C19.4933 8.98157 19.507 8.92564 19.5029 8.86958V5.10966C19.5029 5.03913 19.4749 4.9715 19.425 4.92163C19.3751 4.87177 19.3075 4.84375 19.237 4.84375Z"
        fill="var(--coensio)"
      />
    </g>
    <defs>
      <clipPath id="clip0_1398_10765">
        <rect
          width="10.0285"
          height="10.0285"
          fill="white"
          transform="translate(9.47266 4.84375)"
        />
      </clipPath>
    </defs>
  </svg>
);
