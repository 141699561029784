import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MonacoEditor from '@monaco-editor/react';
import Dropdown from 'react-dropdown';
import { languages, initialCodeGeneration, apiFormatterForOutput, apiFormatterForInputs } from '../Constants/codingLanguageConstants';

import styles from '../Styles/Coding.module.css';
import {
  isPassedVerificationRedux,
  outputVariableRedux,
  parametersRedux,
  testCasesRedux,
  validCasesRedux,
  verificationCodeRedux,
  verificationCodeResponseRedux,
  verificationSelectedLangRedux
} from '../../../redux/addQuestion/selectors';
import {
	setIsPassedVerificationRedux,
  setVerificationCodeRedux,
  setVerificationCodeResponseRedux,
  setVerificationSelectedLangRedux
} from '../../../redux/addQuestion/slice';
import { postRunCodeAction } from '../../../redux/addQuestion/actions';

function VerificationCode() {
  const dispatch = useDispatch();
  const reduxOutputVariable = useSelector(outputVariableRedux);
  const reduxParameters = useSelector(parametersRedux);
  const reduxVerificationCode = useSelector(verificationCodeRedux);
  const reduxSelectedLanguage = useSelector(verificationSelectedLangRedux);

  const reduxTestCases = useSelector(testCasesRedux);
  const reduxValidCases = useSelector(validCasesRedux);
  const reduxVerificationCodeResponse = useSelector(verificationCodeResponseRedux);
  const reduxIsPassedVerification = useSelector(isPassedVerificationRedux);

  function isSameLang(lang1, lang2) {
    return lang1.value === lang2.value && lang1.label === lang2.label;
  }

  const onDropdownSelectMonaco = lang => {
    dispatch(setVerificationSelectedLangRedux(lang));
	if (!isSameLang(reduxSelectedLanguage, lang)) {
		dispatch(setIsPassedVerificationRedux(false));
		dispatch(setVerificationCodeResponseRedux(''));
	}
	
  };



  async function sendForVerification() {
	const mergedCases = [];
	reduxTestCases.forEach(e => {
		mergedCases.push(e);
	})
	reduxValidCases.forEach(e => {
		mergedCases.push(e);
	})
	const {weightsArray, inputsArrayRaw, outputsArray } = apiFormatterForOutput(mergedCases, reduxSelectedLanguage.value, reduxOutputVariable);
	const inputsArray = apiFormatterForInputs(inputsArrayRaw, reduxSelectedLanguage.value, reduxOutputVariable, reduxParameters);

    const inputCountTemp = reduxTestCases.length + reduxValidCases.length;
    const testCountsTemp = reduxTestCases.length + reduxValidCases.length;

    let verificationCodeTemp = reduxVerificationCode;
    if (reduxSelectedLanguage.value === 'php') {
      verificationCodeTemp = verificationCodeTemp.replace('<?php ', '');
      verificationCodeTemp = verificationCodeTemp.replace('?>', '');
    }

    const rawData = {
      answerCode: verificationCodeTemp,
      codeLanguage: reduxSelectedLanguage.value,
      outputType: reduxOutputVariable.type,

      inputCount: inputCountTemp,
      testCount: testCountsTemp,
      weights: weightsArray,
      outputs: outputsArray,
      inputs: inputsArray
    };
    await dispatch(postRunCodeAction(rawData));
  }

  useEffect(async () => {
    if (localStorage.getItem('verificationSelectedLang')) {
      if (
        !isSameLang(
          reduxSelectedLanguage,
          JSON.parse(localStorage.getItem('verificationSelectedLang'))
        )
      ) {
        const init = initialCodeGeneration(reduxOutputVariable, reduxParameters);
        dispatch(setVerificationCodeRedux(init[reduxSelectedLanguage.value]));
      }
    }
    localStorage.setItem('verificationSelectedLang', JSON.stringify(reduxSelectedLanguage));
  }, [reduxSelectedLanguage]);


  const onChangeResetVerificationCode = () => {
    dispatch(setIsPassedVerificationRedux(false));
    dispatch(setVerificationCodeResponseRedux(''));
  };

  return (
    <div className={styles.RightPaneVerificationCode}>
      <div className={styles.VerificationCodeInfoPanel}>
        <b>Verification Code</b> <br />
        To validate your coding question, write a code to pass all your test cases.
      </div>
      <div className={styles.VerificationCodeCompilerWrapper}>
        <div className={styles.VerificationCodeCompilerWrapperDropdownRow}>
          <div>
            <b>Coding language for verification code</b>
          </div>
          <Dropdown
            className={styles.VerificationCodeCompilerDropdown}
            options={languages}
            onChange={onDropdownSelectMonaco}
            value={reduxSelectedLanguage.value}
          />
        </div>
        <div className={styles.VerificationCodeCompiler}>
          <MonacoEditor
            defaultLanguage={reduxSelectedLanguage.value}
            defaultValue={reduxSelectedLanguage.label}
            theme="vs-dark"
            language={reduxSelectedLanguage.value}
            value={reduxVerificationCode}
            onChange={e => {dispatch(setVerificationCodeRedux(e)); onChangeResetVerificationCode();}}
            options={{ wordWrap: 'on', minimap: { enabled: false } }}
          />
        </div>
      </div>
      <div className={styles.VerificationCodeTestOutput}>
        <div className={styles.VerificationCodeTestOutputHeader}>
          <div style={{ 'padding-left': '10px', color: 'white' }}>Test Output</div>
          <button type="button" onClick={() => sendForVerification()}>
            Run Test
          </button>
        </div>

        <div
          className={styles.VerificationCodeTestOutputResult}
          style={{ 'padding-left': '10px', color: 'white' }}
        >
          Is passed verification code:&nbsp;
          <span style={reduxIsPassedVerification ? {color: "#66d766"} : {color: "#e55454"}}>{reduxIsPassedVerification.toString().toUpperCase()}</span>
          <br />
          {reduxVerificationCodeResponse && reduxVerificationCodeResponse !== ''
            ? reduxVerificationCodeResponse === 'Running...'
              ? 'Running...'
              : reduxVerificationCodeResponse.isSuccess
              ? `Got ${reduxVerificationCodeResponse.point} point out of ${reduxVerificationCodeResponse.weight}`
              : reduxVerificationCodeResponse.errorMessage
            : 'Try and check your test'}
        </div>
      </div>
    </div>
  );
}

export default VerificationCode;
