import React from 'react';
import styles from '../Styles/CandidateQuestionBar.module.css';

export default function CandidateQuestionBar() {
  return (
    <div className={styles.mainWrapper}>
      <div className={styles.wrapper} id="QuestionsBar">
        <div className={styles.left}>Questions</div>
        <div className={styles.right}>
          <div style={{ whiteSpace: 'nowrap' }}>Tab Switch</div>
          <div>Time</div>
          <div
            style={{
              width: '100%',
              alignItems: 'center',
              justifyItems: 'center',
              display: 'grid',
              gridTemplateColumns: '3fr 1fr'
            }}
          >
            <div>Score</div>
          </div>
        </div>
      </div>
    </div>
  );
}
