export const signupEmailRedux = state => state.signup.signupEmail;
export const signedUserIdRedux = state => state.signup.signedUserId;
export const signUpStepRedux = state => state.signup.signUpStep;
export const signUpStep1ErrorMessageRedux = state => state.signup.signUpStep1ErrorMessage;
export const signUpStep2ErrorMessageRedux = state => state.signup.signUpStep2ErrorMessage;

export const userInfoStepRedux = state => state.signup.userInfoStep;
export const userInfoSelectedAnswerRedux = state => state.signup.userInfoSelectedAnswer;

export const forgotPasswordStepRedux = state => state.signup.forgotPasswordStep;
export const forgotPasswordEmailRedux = state => state.signup.forgotPasswordEmail;
export const forgotPasswordErrorRedux = state => state.signup.forgotPasswordError;
export const forgotPasswordCompanyNameRedux = state => state.signup.forgotPasswordCompanyName;
export const forgotPasswordEdgeCaseStateRedux = state => state.signup.forgotPasswordEdgeCaseState;

export const newPasswordPageErrorFlagRedux = state => state.signup.newPasswordPageErrorFlag;
export const newPasswordPageErrorMessageRedux = state => state.signup.newPasswordPageErrorMessage;

export const invitedUserActivateErrorRedux = state => state.signup.invitedUserActivateError;
export const invitedUserCompanyLogoRedux = state => state.signup.invitedUserCompanyLogo;