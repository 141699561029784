import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { userInfoStepRedux } from '../../../redux/signup/selectors';
import UserInfoComponent from '../../Components/Signup/UserInfoComponent';
import styles from './UserInfoPage.module.css';
import { activateUserAction } from '../../../redux/signup/actions';
import DomainSignupComponent from '../../Components/Signup/DomainSignupComponent';
import Loading from '../../../Common/Components/Loading';

import mixpanel from '../../../utils/mixpanel';

const UserInfoPage = () => {
  const userInfoStep = useSelector(userInfoStepRedux);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const email = new URLSearchParams(search).get('email');
  const password = new URLSearchParams(search).get('password');
  const [userId, setUserId] = useState();

  const [isActivated, setIsActivated] = useState();
  const [userActivateError, setUserActivateError] = useState();
  const [userActivateErrorCompanyName, setUserActivateErrorCompanyName] = useState();

  async function activateRequest() {
    localStorage.removeItem('verificationMailSentDate');
    const data = {
      email: email,
      password: password
    };
    const resp = await dispatch(activateUserAction(data));

    if (resp.meta.requestStatus === 'fulfilled') {
      setUserId(resp?.payload?.user?.user);
      localStorage.setItem('authToken', resp?.payload?.accessTokenV1);
      localStorage.setItem('authTokenVol2', resp?.payload?.accessToken);
      setIsActivated(true);
      setUserActivateError();

      /* --------------- Mixpanel pageview --------------- */
      mixpanel.track('User Activate - Pageview', {
        "Encrypted Email": email,
        "Success Flag": true,
      })
      /* ------------------------------------------------- */
    } else {
      setUserActivateError(resp.payload.message);
      setIsActivated(false);

      /* --------------- Mixpanel pageview --------------- */
      mixpanel.track('User Activate - Pageview', {
        "Encrypted Email": email,
        "Success Flag": false,
        "Error Message": resp?.payload?.message,
      })
      /* ------------------------------------------------- */
    }
  }

  useEffect(async () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('authTokenVol2');
    localStorage.removeItem('user');
    localStorage.removeItem('activeCompany');
    localStorage.removeItem('Banner');
    activateRequest();
  }, []);

  useEffect(() => {
    if (userActivateError && userActivateError.startsWith('Domain exist.')) {
      setUserActivateErrorCompanyName(userActivateError.split('. ')[1]);
    }
  }, [userActivateError]);

  return (
    <div className={styles.ContentBody}>
      {isActivated && (
        <>
          {userInfoStep === 1 && (
            <UserInfoComponent
              userId={userId}
              questionNumber={1}
              isFirstQuestion
              question="How would you describe yourself?"
              answers={['Founder', 'Hiring Manager', 'Recruiter']}
            />
          )}
          {userInfoStep === 2 && (
            <UserInfoComponent
              userId={userId}
              questionNumber={2}
              question="How many job adds do you post per month?"
              answers={['1 - 2', '3 - 5', '5+']}
            />
          )}
          {userInfoStep === 3 && (
            <UserInfoComponent
              userId={userId}
              questionNumber={3}
              question="How many applicants do you have per month?"
              answers={['1 - 50', '51 - 100', '100+']}
            />
          )}
          {userInfoStep === 4 && (
            <UserInfoComponent
              userId={userId}
              questionNumber={4}
              isLastQuestion
              question="What is your company size?"
              answers={['1 - 10', '11 - 50', '50+']}
            />
          )}
        </>
      )}
      {isActivated === false && userActivateErrorCompanyName && (
        <DomainSignupComponent
          title="This domain has already signed up"
          companyNameFromParent={userActivateErrorCompanyName}
        />
      )}
      {!isActivated && !userActivateError && (
        <div className={styles.LoadingWrapper}>
          <Loading />
        </div>
      )}
    </div>
  );
};

export default UserInfoPage;
