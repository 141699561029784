import React, { useEffect, useRef, useState } from 'react';
import styles from './Animation.module.css';

const Animation = () => {
  const animatedBook = (
    <div className={styles.book}>
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );

  return <>{animatedBook}</>;
};
export default Animation;
