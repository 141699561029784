import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { logoPathForExternalAccessSelector } from '../../../redux/logo/selectors';

import coensioLogo from '../../../images/Common/Coensio_newLogo.svg';

export default function ReportHeader() {
  const userLogoRaw = useSelector(logoPathForExternalAccessSelector);

  useEffect(async () => {
    if (userLogoRaw) {
      await fetch(userLogoRaw, {cache: "reload"})
        // .then(resp => console.log(resp))
        .catch(e => console.log(e));
    }
  }, [userLogoRaw]);

  return (
    <div
      className="FlexRow spaceBetween verticallyCenter"
      style={{ padding: '16px 0px', height: '80px' }}
    >
      {userLogoRaw ? (
        <img
          src={userLogoRaw}
          alt="companyLogo"
          style={{
            maxHeight: '50px',
            maxWidth: '100px',
            // height: '100%',
            width: '100%'
          }}
        />
      ) : (
        <div />
      )}
      <div className="FlexRow gap-8 verticallyCenter">
        <span className='fw-400 font-14'>powered by</span>
        <img
          src={coensioLogo}
          alt="coensioLogo"
          style={{
            maxHeight: '50px',
            maxWidth: '100px',
            height: '100%',
            width: '100%'
          }}
        />
      </div>
    </div>
  );
}
