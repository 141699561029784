/**
 * @param {UserObject} currentUser: User object stored in redux.
 * @returns Object
 */
export function mixpanelReturnUserRelatedProperties(currentUser) {
  return {
    'User Id': currentUser?.userId,
    'Company Name': currentUser?.companyName,
    'Name Surname': currentUser?.userNameSurname,
    Email: currentUser?.email,
    'User Type': currentUser?.userGroupType
  };
}

/**
 * @param {AssessmentDetailObject} assessmentDetail: Assessment detail object stored in redux.
 * @returns Object
 */
export function mixpanelReturnAssessmentDetailRelatedProperties(assessmentDetail) {
  return {
    'Assessment Name': assessmentDetail?.title,
    'Assessment Category': assessmentDetail?.category,
    'Assessment Industry Average': assessmentDetail?.allScoreAverage,
    'Assessment Status':
      assessmentDetail?.assessmentStatus === 10
        ? 'Published'
        : assessmentDetail?.assessmentStatus === 20
        ? 'Unpublished'
        : 'Archived',
    'Assessment Id': assessmentDetail?.id
  };
}

/**
 * @param {CandidateListItemObject} candidateObj: Candidate detail object in CandidateListComponent.
 * @returns Object
 */
export function mixpanelCandidateRelatedProperties(candidateObj) {
  return {
    'Candidate Add Date': candidateObj?.addDate,
    'Invited by Email Flag': candidateObj?.arriveType === 10,
    'Is Suspect Flag': candidateObj?.isSuspect,
    'Candidate Email': candidateObj?.email,
    'Candidate Name': candidateObj?.name,
    'Is Locked Flag': candidateObj?.objStatusId === 4,
    'Candidate Score': candidateObj?.points,
    'Candidate User Id': candidateObj?.userId
  };
}
