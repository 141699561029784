import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import mixpanel from '../mixpanel';
import {
  AssessmentTitle,
  CandidateEmail,
  CompanyName,
  rootAssessmentNameRedux
} from '../../redux/AssessmentSession/selectors';

import mixpanelEventNames from './mixpaneEventNames.json';

export default function useMixpanelAutoApplyPageview(autoApplyStatus) {
  const candidateEmail = useSelector(CandidateEmail);
  const assessmentTitle = useSelector(AssessmentTitle);
  const rootAssessmentName = useSelector(rootAssessmentNameRedux);
  const companyName = useSelector(CompanyName);

  useEffect(() => {
    function mixpanelPageviewEventHelper(eventName, properties) {
      mixpanel.track(eventName, properties);
    }

    const properties = {
      'Assessment Name': assessmentTitle,
      'Root Assessment Name': rootAssessmentName,
      'Company Name': companyName,
      'Candidate Email': candidateEmail,

      'Auto Apply Status': autoApplyStatus
    };
    mixpanelPageviewEventHelper(mixpanelEventNames.autoApplyView, properties);
  }, []);
}
