import React from 'react';

import { useTranslation, Trans } from 'react-i18next';

import styles from '../Styles/AssessmentQuestion.module.css';
import coding from '../../../images/AddAssessment/AssessmentCustomization/coding_assessment.svg';
import multiple from '../../../images/AddAssessment/AssessmentCustomization/multiple_assessment.svg';
import freeText from '../../../images/AddAssessment/AssessmentCustomization/freetext_assessment.svg';
import clock from '../Images/clockDuration.svg';

const calculateMin = timeInit => {
  const timeInSeconds = parseInt(timeInit, 10);
  const time = {
    min: Math.floor(timeInSeconds / 60),
    sec: timeInSeconds - Math.floor(timeInSeconds / 60) * 60
  };
  if (time.sec === 0) {
    return <span><Trans i18nKey="assmMin" ns='tryAssm' values={{ timeMin: time.min }}>0<span>1</span><span>2</span></Trans></span>;
  }
  if (time.min === 0) {
    return <span><Trans i18nKey="assmSec" ns='tryAssm' values={{ timeSec: time.sec }}>0<span>1</span><span>2</span></Trans></span>;
  }
  return <span><Trans i18nKey="assmMinSec" ns='tryAssm' values={{ timeMin: time.min, timeSec: time.sec }}>0<span>1</span><span>2</span></Trans></span>;
};

const DurationElement = ({ duration }) => (
  <div className={styles.durationWrapper}>
    <span className={styles.imgWrapper}>
      <img alt="" src={clock} />
    </span>
    <div className={styles.duration}>{calculateMin(duration)}</div>
  </div>
);

export const FreeTextQuestions = ({
  qDesc,
  isDetailedShown = true,
  category,
  time,
  index,
  title,
  difficulty
}) => {
  return (
    <div className={isDetailedShown && styles.bodyWrapper}>
      <div className={isDetailedShown && styles.freeTextWrapper}>
        {isDetailedShown && (
          <div className={styles.headerRow}>
            <div>{index ? `${index}. ${title}` : `${title}`}</div>
            {difficulty && <div className={styles.difficulty}>{difficulty}</div>}
          </div>
        )}
        <div className={isDetailedShown && styles.innerBody}>
          <div
            className={styles.freeTextQuestionText}
            dangerouslySetInnerHTML={{ __html: qDesc }}
          />
        </div>
        {isDetailedShown && (
          <div className={styles.infoRow}>
            <img width={33} height={24} alt="" src={freeText} />
            <div className={styles.category}>{category}</div>
            <DurationElement duration={time} />
          </div>
        )}
      </div>
    </div>
  );
};

export const CodingQuestion = ({
  qDesc,
  category,
  time,
  index,
  title,
  difficulty,
  isDetailedShown = true
}) => {
  return (
    <div className={isDetailedShown && styles.bodyWrapper}>
      <div className={isDetailedShown && styles.codingWrapper}>
        {isDetailedShown && (
          <div className={styles.headerRow}>
            <div>{index ? `${index}. ${title}` : `${title}`}</div>
            {difficulty && <div className={styles.difficulty}>{difficulty}</div>}
          </div>
        )}
        <div className={isDetailedShown && styles.innerBody}>
          <div className={styles.codingQuestionText} dangerouslySetInnerHTML={{ __html: qDesc }} />
        </div>
        {isDetailedShown && (
          <div className={styles.infoRow}>
            <img width={33} height={24} alt="" src={coding} />
            <div className={styles.category}>{category}</div>
            <DurationElement duration={time} />
          </div>
        )}
      </div>
    </div>
  );
};

function parseAnswer(item) {
  let text = item;
  if (text.includes(`<img src="questions/`)) {
    text = text.replaceAll(`<img src="`, `<img src="${process.env.REACT_APP_IMAGE_API}/`);
    return text;
  }
  return '';
}

const alignedText = item => {
  let temp = item;
  temp = temp.replaceAll('text-align:right;', 'margin-left:auto;');
  temp = temp.replaceAll('text-align:left;', 'margin-right:auto;');
  temp = temp.replaceAll('text-align:none;', 'margin-right:auto;margin-left:auto;');
  return temp;
};
export const MultipleChoiceQuestion = ({
  qDesc,
  mcOptions,
  title,
  index,
  category,
  time,
  difficulty,
  imageOption = false,
  isDetailedShown = true
}) => {
  let opt = mcOptions;
  try {
    opt = JSON.parse(mcOptions);
  } catch (error) {
    // console.log(error, 'error on parsing multiple choice options');
    // console.log(mcOptions);
  }

  return (
    <div className={isDetailedShown && styles.bodyWrapper}>
      <div className={isDetailedShown && styles.multipleChoiceWrapper}>
        {isDetailedShown && (
          <div className={styles.headerRow}>
            <div>{index ? `${index}. ${title}` : `${title}`}</div>
            {difficulty && <div className={styles.difficulty}>{difficulty}</div>}
          </div>
        )}
        <div className={isDetailedShown && styles.innerBody}>
          <div
            className={styles.multipleChoiceQuestionText}
            dangerouslySetInnerHTML={{ __html: qDesc }}
          />
          {opt?.map((item, index) => (
            <div key={index} className={styles.Option}>
              <div className={styles.optionWrapper}>
                <div className={`${styles.optionCheckbox}`} />
                {imageOption ? (
                  <div
                    className={styles.optionText}
                    dangerouslySetInnerHTML={{ __html: alignedText(item) }}
                  />
                ) : item.includes(`<img src="questions`) ? (
                  <div dangerouslySetInnerHTML={{ __html: parseAnswer(item) }} />
                ) : (
                  <div className={`${styles.optionText} `}>{item}</div>
                )}
              </div>
            </div>
          ))}
        </div>
        {isDetailedShown && (
          <div className={styles.infoRow}>
            <img width={33} height={24} alt="" src={multiple} />
            <div className={styles.category}>{category}</div>
            <DurationElement duration={time} />
          </div>
        )}
      </div>
    </div>
  );
};

// eslint-disable-next-line consistent-return
const AssessmentQuestion = ({
  question,
  time,
  type,
  options,
  title,
  index,
  category,
  difficulty = null,
  imageOption
}) => {
  let qDesc = [];

  try {
    qDesc = JSON.parse(question);
    // qDesc = qDesc.replaceAll("https://api.coens.io", process.env.REACT_APP_IMAGE_API);
    qDesc = qDesc.replaceAll('https://api.coens.io/', '');
    qDesc = qDesc.replaceAll('https://file.coens.io/', '');
    qDesc = qDesc.replaceAll('<img src="', `<img src="${process.env.REACT_APP_IMAGE_API}/`);
    qDesc = qDesc.replaceAll('<source src="', `<source src="${process.env.REACT_APP_IMAGE_API}/`);
  } catch (error) {
    // console.log(error, 'error on parsing question body');
    // console.log(question);
  }

  switch (type) {
    case 1:
      return (
        <MultipleChoiceQuestion
          category={category}
          index={index}
          title={title}
          qDesc={qDesc}
          time={time}
          mcOptions={options}
          difficulty={difficulty}
          imageOption={imageOption}
        />
      );
    case 2:
      return (
        <CodingQuestion
          index={index}
          title={title}
          qDesc={qDesc}
          time={time}
          category={category}
          difficulty={difficulty}
        />
      );
    case 3:
      return (
        <FreeTextQuestions
          index={index}
          title={title}
          qDesc={qDesc}
          time={time}
          category={category}
          difficulty={difficulty}
        />
      );
    default:
      return null;
  }
};

export default AssessmentQuestion;
