export const filteredRootAssessmentList = state => state.assessmentsVol2.filteredRootAssessmentList;
export const currentUserDetail2 = state => state.assessmentsVol2.currentUserDetail2;
export const rootAssessmentQuestionList = state => state.assessmentsVol2.rootAssessmentQuestionList;
export const customAssessmentId = state => state.assessmentsVol2.customAssessmentId;
export const codingLanguageListSelector = state => state.assessmentsVol2.codingLanguageList;
export const rootAssessmentId = state => state.assessmentsVol2.rootAssessmentId;
export const assessmentActiveTab = state => state.assessmentsVol2.assessmentActiveTab;
export const deleteCandidate = state => state.assessmentsVol2.assessmentToBeDeleted;
export const publishedAssessment = state => state.assessmentsVol2.publishedAssessment;
export const questionBeingAdded = state => state.assessmentsVol2.questionBeingAdded;
export const candidateQuestionList = state => state.assessmentsVol2.candidateQuestionList;
export const filterState = state => state.assessmentsVol2.filterState;
export const questionBeingRemoved = state => state.assessmentsVol2.questionBeingRemoved;
export const removeQuestionShow = state => state.assessmentsVol2.removeQuestionShow;
export const sessionQuestion = state => state.assessmentsVol2.questionAddedInSession;
export const inviteUserShow = state => state.assessmentsVol2.inviteUserShow;
export const tryAssessmentQuestions = state => state.assessmentsVol2.tryAssessmentQuestions;
export const allUserListSelector = state => state.assessmentsVol2.allUserList;
export const invitedAllUserListSelector = state => state.assessmentsVol2.invitedAllUserList;

export const HMUserList = state => state.assessmentsVol2.HMUserList;
export const invitedHMList = state => state.assessmentsVol2.invitedHMList;
export const questionDetail = state => state.assessmentsVol2.questionDetail;
export const maxPageCount = state => state.assessmentsVol2.maxPageCount;

export const RecUserList = state => state.assessmentsVol2.RecUserList;
export const invitedRecList = state => state.assessmentsVol2.invitedRecList;
// export const selectedAuthorizedUser = state => state.assessmentsVol2.selectedAuthorizedUser;
export const authorizedHMList = state => state.assessmentsVol2.authorizedHMList;
export const authorizedRecList = state => state.assessmentsVol2.authorizedRecList;

export const loading = state => state.assessmentsVol2.isLoading;

// to keep track of updated company user list
export const userList = state => state.assessmentsVol2.userList;

export const newlyInvitedID = state => state.assessmentsVol2.newlyInvitedID;
export const newlyInvitedIDList = state => state.assessmentsVol2.newlyInvitedIDList;

export const AtsList = state => state.assessmentsVol2.AtsList;

export const CompanyAssessmentId = state => state.assessmentsVol2.CompanyAssessmentId;
export const authorizedUsers = state => state.assessmentsVol2.authorizedUsers;
export const pageIndex = state => state.assessmentsVol2.pageIndex;

export const assessmentDetailAfterCreate = state =>
  state.assessmentsVol2.assessmentDetailAfterCreate;

export const scoredEmails = state => state.assessmentsVol2.scoredEmailCount;
export const invitedEmail = state => state.assessmentsVol2.invitedCount;
export const ibeScoredRedirect = state => state.assessmentsVol2.ibeScoredRedirect;
export const sentMailSuccess = state => state.assessmentsVol2.sentMailSuccess;
export const mailRejected = state => state.assessmentsVol2.mailRejected;

export const selectedRootAssessmentQuestionListRedux = state =>
  state.assessmentsVol2.selectedRootAssessmentQuestionList;

export const continueToAssessmentCustomizationRedux = state =>
  state.assessmentsVol2.continueToAssessmentCustomization;
export const updateCustomAssessmentCheckRedux = state =>
  state.assessmentsVol2.updateCustomAssessmentCheck;

export const continueToAssessmentDetailRedux = state =>
  state.assessmentsVol2.continueToAssessmentDetail;
export const connectionListRedux = state => state.assessmentsVol2.connectionList;

export const removeSkillBasedErrorRedux = state => state.assessmentsVol2.removeSkillBasedError;

export const mostPopularRootAssessmentListRedux = state =>
  state.assessmentsVol2.mostPopularRootAssessmentList;

export const selectedRootAssmFiltersRedux = state => state.assessmentsVol2.selectedRootAssmFilters;

export const assessmentAddedBannerShowSelector = state =>
  state.assessmentsVol2.assessmentAddedBannerShow;
export const assessmentAddedBannerTextSelector = state =>
  state.assessmentsVol2.assessmentAddedBannerText;
