import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import EmailText from '../../../Common/Components/EmailText';
import CustomButton from '../../../Common/Components/CustomButton';
import {
  confirmDeletingAssessmentRecordAction,
  getCandidateAssessmentRecordAction,
  getConnectedAssessmentCountAction
} from '../../../redux/assessmentRecord/actions';

import styles from './AssessmentRecord.module.css';
import Modal from '../../../Common/CustomModal/Modal';
import questionConstants from '../../../utils/questionConstants';

function IndividualRecord({ assessmentData, userId }) {
  const {
    companyAssessmentId,
    companyAssessmentName,
    companyId,
    companyName,
    rootAssessmentId,
    rootAssessmentName,
    score,
    seniority,
    solvedDate,
    tabSwitch,
    totalQuestionCount,
    totalSeenQuestionCount
  } = assessmentData;

  const dispatch = useDispatch();
  const [showDetailModal, setShowDetailModal] = useState();
  const [deleteRecordLoading, setDeleteRecordLoading] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState();
  const [confirmationCount, setConfirmationCount] = useState();
  const [confirmDeleteLoading, setConfirmDeleteLoading] = useState();

  const deleteRecordClick = async () => {
    setDeleteRecordLoading(true);
    // TODO: api integration
    const resp = await dispatch(
      getConnectedAssessmentCountAction({ userId: userId, rootAssessmentId: rootAssessmentId })
    );

    if (resp?.meta?.requestStatus === 'fulfilled') {
      setConfirmationCount(resp?.payload);
      setShowConfirmationModal(true);
      setShowDetailModal(false);
    } else {
      alert('Error on fetching connected assessment count');
    }

    setDeleteRecordLoading(false);
  };

  const confirmDeletion = async () => {
    setConfirmDeleteLoading(true);
    const resp = await dispatch(
      confirmDeletingAssessmentRecordAction({
        rootAssessmentId: rootAssessmentId,
        userId: userId,
        companyAssessmentId: companyAssessmentId
      })
    );

    if (resp?.meta?.requestStatus === 'fulfilled' && resp?.payload === true) {
      alert('Assessment record is deleted successfuly');
      location.reload();
    } else {
      alert('An error occurred during deleting record!!');
    }

    setConfirmDeleteLoading(false);
  };

  const DetailModalContent = (
    <Modal
      show={showDetailModal}
      onClose={() => setShowDetailModal(false)}
      centerHorizontally
      centerVertically
      outsideClickClose
      contentStyle={{ padding: '24px 36px', width: '100%', maxWidth: '800px', fontSize: '16px' }}
    >
      <div className={`FlexColumn gap-12 ${styles.recordModal}`}>
        <div className="mb-24">
          <div className="FlexRow spaceBetween" style={{ borderBottom: '1px solid grey' }}>
            <span style={{ width: '70%' }}>Root Assessment Name</span>
            <span style={{ width: '20%' }}>Seniority</span>
            <span style={{ width: '10%' }}>Id</span>
          </div>

          <div className="FlexRow spaceBetween">
            <span style={{ width: '70%' }}>{rootAssessmentName}</span>
            <span style={{ width: '20%' }}>{questionConstants.SENIORITY_ENUMS[seniority]}</span>
            <span style={{ width: '10%' }}>{rootAssessmentId}</span>
          </div>
        </div>

        <div className="mb-24">
          <div className="FlexRow spaceBetween" style={{ borderBottom: '1px solid grey' }}>
            <span style={{ width: '90%' }}>Company Name</span>
            <span style={{ width: '10%' }}>Id</span>
          </div>

          <div className="FlexRow spaceBetween">
            <span style={{ width: '90%' }}>{companyName}</span>
            <span style={{ width: '10%' }}>{companyId}</span>
          </div>
        </div>

        <div className="mb-24">
          <div className="FlexRow spaceBetween" style={{ borderBottom: '1px solid grey' }}>
            <span style={{ width: '90%' }}>Company Assessment Name</span>
            <span style={{ width: '10%' }}>Id</span>
          </div>

          <div className="FlexRow spaceBetween">
            <span style={{ width: '90%' }}>{companyAssessmentName}</span>
            <span style={{ width: '10%' }}>{companyAssessmentId}</span>
          </div>
        </div>

        <span className="mb-24">
          <strong>Solve Date:</strong> {solvedDate}
        </span>

        <div className="mb-24">
          <div className="FlexRow spaceBetween" style={{ borderBottom: '1px solid grey' }}>
            <span style={{ width: '25%' }}>Score</span>
            <span style={{ width: '25%' }}>Tab Switch</span>
            <span style={{ width: '25%' }}>Seen Q. Count</span>
            <span style={{ width: '25%' }}>Total Q. Count</span>
          </div>

          <div className="FlexRow spaceBetween">
            <span style={{ width: '25%' }}>{score}</span>
            <span style={{ width: '25%' }}>{tabSwitch}</span>
            <span style={{ width: '25%' }}>{totalSeenQuestionCount}</span>
            <span style={{ width: '25%' }}>{totalQuestionCount}</span>
          </div>
        </div>

        <CustomButton
          textField="Delete Record"
          customStyle={{ marginLeft: 'auto' }}
          type={6}
          buttonOnClick={deleteRecordClick}
          loading={deleteRecordLoading}
        />
      </div>
    </Modal>
  );

  const ConfirmationModalContent = (
    <Modal
      show={showConfirmationModal}
      onClose={() => {
        setShowConfirmationModal(false);
        setConfirmationCount();
      }}
      centerHorizontally
      centerVertically
      outsideClickClose
      contentStyle={{ padding: '24px', width: '100%', maxWidth: '800px', fontSize: '16px' }}
    >
      {confirmationCount > 0 ? (
        <div>
          <span>
            {confirmationCount} assessment record(s) will be affected !!! Do you want to proceed?
          </span>
          <CustomButton
            textField="Delete"
            customStyle={{ marginLeft: 'auto' }}
            type={6}
            buttonOnClick={confirmDeletion}
            loading={confirmDeleteLoading}
          />
        </div>
      ) : (
        <span>This record cannot be deleted !!!</span>
      )}
    </Modal>
  );

  return (
    <>
      <div
        className={`FlexRow gap-8 ${styles.individualRecord}`}
        onClick={() => setShowDetailModal(true)}
      >
        <span style={{ width: '40%', maxWidth: '40%' }}>{rootAssessmentName}</span>
        <span style={{ width: '25%', maxWidth: '25%' }}>{companyName}</span>
        <span style={{ width: '25%', maxWidth: '25%' }}>{solvedDate}</span>
      </div>
      {DetailModalContent}
      {ConfirmationModalContent}
    </>
  );
}

export default function AssessmentRecordContent() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [assessmentList, setAssessmentList] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [userId, setUserId] = useState();

  const searchCandidate = async () => {
    setButtonLoading(true);
    const resp = await dispatch(getCandidateAssessmentRecordAction({ email: email }));
    if (resp?.meta?.requestStatus === 'fulfilled') {
      setUserId(resp?.payload?.userId);
      setAssessmentList(resp?.payload?.candidateAssessmentRecords);
    } else {
      alert('Error on fetching assessment records!');
    }
    setButtonLoading(false);
  };

  return (
    <div
      className="p-20 FlexColumn gap-24 w-100"
      style={{ maxWidth: '800px', alignSelf: 'center' }}
    >
      <div className="FlexRow gap-8 verticallyCenter">
        <EmailText
          value={email}
          setValue={setEmail}
          placeholder="Enter candidate email"
          style={{ width: '300px' }}
        />
        <CustomButton
          enterEnabled
          textField="Search"
          isResponsive
          isDisabled={!email || email?.trim() === ''}
          buttonOnClick={searchCandidate}
          loading={buttonLoading}
        />
      </div>

      <div className="FlexColumn gap-16">
        {assessmentList ? (
          assessmentList?.length > 0 ? (
            <>
              <div className="FlexRow gap-8" style={{ borderBottom: '1px solid grey' }}>
                <span style={{ width: '40%', maxWidth: '40%' }}>Root Assessment Name</span>
                <span style={{ width: '25%', maxWidth: '25%' }}>Company Name</span>
                <span style={{ width: '25%', maxWidth: '25%' }}>Solved Date</span>
              </div>
              {assessmentList.map(x => (
                <IndividualRecord assessmentData={x} userId={userId} />
              ))}
            </>
          ) : (
            <span>No such record found</span>
          )
        ) : null}
      </div>
    </div>
  );
}
