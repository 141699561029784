import React from 'react';

import { useTranslation, Trans } from 'react-i18next';

import styles from './QuestionReport.module.css';
import QuestionReportBody from './QuestionReportBody';

const QuestionReport = ({ qInfo, index }) => {
  const { t } = useTranslation(['candidateDetail']);

  return (
    <div className={styles.QuestionWrapper}>
      <div className={styles.InfoRow}>
        <text className={`${styles.noWrapper}`}>{index + 1}</text>
        <text className={`${styles.headerTextFlexGrow}`}>{qInfo.questionTitle}</text>
        <text
          className={`${styles.tabSwitch} ${styles.label}`}
          style={
            qInfo.tabSwitch > 0
              ? { marginRight: '28px', width: '30px', minWidth: '30px' }
              : { color: '#393939', marginRight: '28px', width: '30px', minWidth: '30px' }
          }
        >
          {qInfo.tabSwitch > 0 ? qInfo.tabSwitch : '-'}
        </text>
        <text className={`${styles.label}`}>
          {qInfo.time === 0 ? (
            '-'
          ) : qInfo.time % 60 === 0 ? (
            <Trans i18nKey="timeMin" ns="candidateDetail" values={{ timeMin: qInfo.time / 60 }}>
              0<span>1</span>
              <span>2</span>
            </Trans>
          ) : qInfo.time > 60 ? (
            <span>
              <Trans
                i18nKey="timeMinSec"
                ns="candidateDetail"
                values={{ timeMin: Math.floor(qInfo.time / 60), timeSec: qInfo.time % 60 }}
              >
                0<span>1</span>
                <span>2</span>
                <span>3</span>
                <span>4</span>
              </Trans>
            </span>
          ) : (
            <Trans i18nKey="timeSec" ns="candidateDetail" values={{ timeSec: qInfo.time }}>
              0<span>1</span>
              <span>2</span>
            </Trans>
          )}
        </text>
        <text className={`${styles.label}`}>
          {qInfo.point > 0 ? qInfo.point : 0}/{qInfo.maxPoints}
        </text>
      </div>
      <div className={styles.QuestionRow}>
        <QuestionReportBody
          type={qInfo.questionTypeId}
          qBody={qInfo.questionDescription}
          mcCorrectAnswer={qInfo.questionAnswerOption}
          mcSelectedAnswer={qInfo.candidateAnswerOption}
          mcOptions={qInfo.options}
          ftCandidateAnswer={qInfo.candidateAnswerFreetext}
          cdCandidateAnswer={qInfo.candidateAnswerCode}
          cdCandidateCodeLang={qInfo.questionCategory}
          imageOpt={qInfo.imageOption}
        />
      </div>
      <div className={styles.QuestionLabelRow}>
        <text>
          {qInfo.questionTypeId === 1
            ? t('multipleChoice')
            : qInfo.questionTypeId === 2
            ? t('coding')
            : t('freeText')}
        </text>
        <text>&#x2022; &nbsp; {qInfo.difficulty}</text>

        {qInfo.point >= qInfo.maxPoints ? ( // Max point
          <div className={`${styles.QuestionCorrectness} ${styles.GreenBackground} `}>
            {t('correct')}
          </div>
        ) : qInfo.point === 0 ? ( // 0 point
          qInfo.questionTypeId === 1 && qInfo.candidateAnswerOption === -1 ? ( // Multiple choice ise ve candidateAnswerOption alanı -1 ise aday soruyu skiplemiş demek, bu durumda da Not Answered basmak istiyoruz
            <div className={`${styles.QuestionCorrectness} ${styles.GreyBackground} `}>
              {t('notAnswered')}
            </div>
          ) : qInfo.questionTypeId === 3 &&
            (qInfo.candidateAnswerFreetext === '' ||
              qInfo.candidateAnswerFreetext === 'null' ||
              qInfo.candidateAnswerFreetext === 'Null' ||
              qInfo.candidateAnswerFreetext === null) ? ( // Free text ise ve candidateAnswerFreetext alanı boşsa ("", "null", "Null", null), Not Answered basmak istiyoruz
            <div className={`${styles.QuestionCorrectness} ${styles.GreyBackground} `}>
              {t('notAnswered')}
            </div>
          ) : (
            <div className={`${styles.QuestionCorrectness} ${styles.RedBackground} `}>
              {t('wrong')}
            </div>
          )
        ) : qInfo.point === -1 ? ( // -1 point
          <div className={`${styles.QuestionCorrectness} ${styles.GreyBackground} `}>
            {t('notAnswered')}
          </div>
        ) : (
          // Partial point
          <div className={`${styles.QuestionCorrectness} ${styles.YellowBackground} `}>
            {t('partiallyCorrect')}
          </div>
        )}
      </div>
    </div>
  );
};
export default QuestionReport;
