import React from 'react';
import Education from './Education';
import Experience from './Experience';
import styles from './OverviewTab.module.css';
import PersonalInformation from './PersonalInformation';

const OverviewTab = () => {
  return (
    <div className={styles.wrapper}>
      <PersonalInformation />
      <Experience />
      <Education />
    </div>
  );
};

export default OverviewTab;
