import client from '../../constant/indexVol2';
import API from '../../constant/apiVol2';

// NEW API for Assessment Settings Modal
export const getAssessmentInfoService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getAssessmentInfo, { params: data }).then(resolve).catch(reject);
  });

export const addConnectionToAssessmentService = data =>
  new Promise((resolve, reject) => {
    client.post(API.addConnectionToAssessment, data).then(resolve).catch(reject);
  });

export const changeAuthorizedUsersOfAssessmentService = data =>
  new Promise((resolve, reject) => {
    client.post(API.changeAuthorizedUsersOfAssessment, data).then(resolve).catch(reject);
  });

export const updateAssessmentInfoService = data =>
  new Promise((resolve, reject) => {
    client.post(API.updateAssessmentInfo, data).then(resolve).catch(reject);
  });
