import React from 'react';

import { useTranslation, Trans } from 'react-i18next';

import styles from './AssessmentInfoWrapper.module.css';

import { ReactComponent as SeniorityIcon } from '../../../../images/AddAssessment/AssessmentDetailsModal/seniority-icon.svg';
import { ReactComponent as QuestionIcon } from '../../../../images/AddAssessment/AssessmentDetailsModal/question-icon.svg';
import { ReactComponent as TimeIcon } from '../../../../images/AddAssessment/AssessmentDetailsModal/time-icon.svg';

const AssessmentInfoWrapper = ({
  item = { seniorityLevel: null, questionList: null, rootAssessmentTotalTime: null }
}) => {
  const { seniorityLevel, questionList, rootAssessmentTotalTime } = item;

  const { t } = useTranslation(['addPosition']);

  return (
    <div className={styles.infoWrapper}>
      {seniorityLevel ? (
        <>
          <div className={styles.infoItemWrapper}>
            <SeniorityIcon />
            <span>
              {seniorityLevel === 10
                ? 'Junior'
                : seniorityLevel === 20
                ? 'Mid-Senior'
                : seniorityLevel === 30
                ? 'Senior'
                : 'Junior'}
            </span>
          </div>
          <div className={styles.divider} />
        </>
      ) : null}

      <div className={styles.infoItemWrapper}>
        <QuestionIcon />
        <Trans i18nKey="questions" values={{ questionsCount: questionList?.length }}>
          {t('questions')}
        </Trans>
      </div>
      <div className={styles.divider} />

      <div className={styles.infoItemWrapper}>
        <TimeIcon />
        <Trans i18nKey="minutes" values={{ minutesCount: rootAssessmentTotalTime }}>
          {t('minutes')}
        </Trans>
      </div>
    </div>
  );
};

export default AssessmentInfoWrapper;
