import React, { useEffect, useRef, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { userSelector } from '../../redux/auth/selectors';
import logo from '../../images/Common/Coensio_newLogo.svg';
import coensioEmblem from '../../images/Common/coensio_emblem.svg';
import route from '../../constant/route';
import styles from '../Styles/Header.module.css';
import CompanySelectModal from '../Modals/companySelectModal';
import { getLogoByCompanyIdAction } from '../../redux/logo/actions';
import UserIcon from './UserIcon';
import { LogoPath } from '../../redux/logo/selectors';
import { setLogo } from '../../redux/logo/slice';
import Banner from './Banner';
import { setAvailableSpace } from '../../redux/user/slice';
import {
  currentUsageRedux,
  errorInSeePlans,
  errorTextInSeePlans,
  purchaseModalShowRedux
} from '../../redux/plan/selectors';
import { setErrorInSeePlans, setPurchaseModalShow } from '../../redux/plan/slice';
import CustomButton from './CustomButton';
import { ReactComponent as EditLogo } from '../../Auth/Images/logoUploadIcon.svg';
import { ReactComponent as Error } from '../../images/Common/errorSubmit.svg';
import InfoBanner from './InfoBanner';
import PurchaseModal from '../../Auth/Modals/PurchaseModal';
import ContactSalesSuccess from '../../Auth/Modals/UnpaidContactSales/ContactSalesSuccess';
import MobileHeaderComponent from './MobileHeaderComponent';
import { getHeaderHeight, useWindowSize } from '../../utils/helpers';
import ProfileBanner from '../ProfileBanner/ProfileBanner';

const Header = ({ setAvailableSpaceForContent = () => {} }) => {
  const user = useSelector(userSelector);
  const currentPackageUsage = useSelector(currentUsageRedux);
  const userLogo = useSelector(LogoPath); // Böyle düzeltmek lazım
  const error = useSelector(errorInSeePlans);
  const errorText = useSelector(errorTextInSeePlans);
  const purchaseShow = useSelector(purchaseModalShowRedux);

  const dispatch = useDispatch();
  const history = useHistory();
  const headerH = getHeaderHeight();

  const { t } = useTranslation(['dashboard']);

  const ref = useRef();

  const [showBanner, setShowBanner] = useState(false);
  const [userModal, setUserModal] = useState(false);
  const [companySelect, setCompanySelect] = useState(false);
  const [boundingBox, setBoundingBox] = useState();
  const [img, setImg] = useState(false);
  const [purchaseSuccess, setPurchaseSuccess] = useState(false);
  const [activeTab, setActiveTab] = useState(null);

  const [width] = useWindowSize();

  const getLogo = async currentUser => {
    const data = {
      CompanyId: currentUser.companyId
    };
    await dispatch(getLogoByCompanyIdAction(data));
  };

  useEffect(() => {
    if (history.location && history.location.pathname) {
      const path = history.location.pathname;

      if (path.startsWith(route.insights)) {
        setActiveTab('insights');
      } else {
        setActiveTab('assessments');
      }
    }
  }, [history.location.pathname]);

  useEffect(() => {
    dispatch(setAvailableSpace(`calc(100dvh - ${headerH}px)`));
    setAvailableSpaceForContent(`calc(100dvh - ${headerH}px)`);
  }, [headerH]);

  /* eslint-disable */
  useEffect(async () => {
    if (localStorage.getItem('user') && JSON.stringify(user) !== localStorage.getItem('user')) {
      dispatch(setLogo(null));
    }
  }, [user]);

  useEffect(() => {
    if (
      currentPackageUsage?.usedCandidates >= currentPackageUsage?.allowedCandidates &&
      !sessionStorage.getItem('isBannerClosed')
    ) {
      setShowBanner(true);
    } else {
      setShowBanner(false);
    }
  }, [currentPackageUsage]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setBoundingBox(document.getElementById('profileIcon')?.getBoundingClientRect());
    }, 500);
    return () => clearTimeout(timer);
  }, [width, userLogo, user, img]);

  useEffect(() => {
    if (user) {
      if (!userLogo) {
        getLogo(user);
      }
      const activeCompany = localStorage.getItem('activeCompany');
      if (user && user.userGroupType === 'Super Admin' && !activeCompany) {
        setCompanySelect(true);
      }
    }
  }, [user]);

  // Show conditions of the banners change -> headerUpdate event is trigerred
  useEffect(() => {
    if (width <= 576) {
      setTimeout(() => {
        window.dispatchEvent(new Event('headerUpdate'));
      }, 50);
    } else {
      window.dispatchEvent(new Event('headerUpdate'));
    }
  }, [showBanner, width]);

  const ModalBannerWrapper = (
    <>
      <ProfileBanner
        boundingBox={boundingBox}
        show={userModal}
        width={width}
        parentRef={ref}
        setShow={type => setUserModal(type)}
      />
      <CompanySelectModal
        show={companySelect}
        currentUser={user}
        onHide={() => setCompanySelect(false)}
        onHideSuccess={company => {
          setCompanySelect(false);
        }}
      />

      <InfoBanner
        text={errorText}
        show={error}
        CustomIcon={Error}
        setStatus={type => {
          dispatch(setErrorInSeePlans(type));
        }}
      />
      <PurchaseModal
        show={purchaseShow}
        onHide={() => dispatch(setPurchaseModalShow(false))}
        setSuccess={setPurchaseSuccess}
      />
      <ContactSalesSuccess
        icon="tick"
        show={purchaseSuccess}
        onHide={() => {
          setPurchaseSuccess(false);
        }}
        title={t('doneDusted')}
        description={t('thankForPurchase')}
      />
    </>
  );

  return (
    <>
      <div id="header" className={styles.headerParent}>
        {user && (
          <>
            {width < 576 ? (
              <MobileHeaderComponent />
            ) : (
              <div
                className={styles.CustomNavbar}
                style={{ height: width <= 576 ? '' : '64px' }}
                id="CoensioNavBar"
              >
                <div className="FlexRow verticallyCenter gap-18" style={{ marginLeft: '3rem' }}>
                  <Link to={route.assessments}>
                    <img
                      src={coensioEmblem}
                      alt="coensio"
                      style={{
                        maxWidth: '28px',
                        maxHeight: '28px'
                      }}
                    />
                  </Link>
                  {user.companyName === 'Brisa' && (
                    <div className="FlexRow verticallyCenter gap-24">
                      <Link to={route.assessments}>
                        <span
                          className={`uppercase font-12 fw-500 c-darkGray ${
                            activeTab === 'assessments' ? 'activeTab' : ''
                          }`}
                          style={{ opacity: activeTab === 'assessments' ? 1 : 0.4 }}
                        >
                          {t('assessments')}
                        </span>
                      </Link>
                      <Link to={route.insights}>
                        <span
                          className={`uppercase font-12 fw-500 c-darkGray ${
                            activeTab === 'insights' ? 'activeTab' : ''
                          }`}
                          style={{ opacity: activeTab === 'insights' ? 1 : 0.4 }}
                        >
                          {t('insights')}
                        </span>
                      </Link>
                    </div>
                  )}
                </div>

                <div
                  className="FlexRow verticallyCenter horizontallyCenter gap-16"
                  id="OnboardingAddLogoId"
                >
                  {user && user.userGroupType === 'Super Admin' && (
                    <span className={styles.selectCompany} onClick={() => setCompanySelect(true)}>
                      Change Corporate
                    </span>
                  )}
                  {user && user.userGroupType === 'Super Admin' && (
                    <span
                      className="pointer underline font-12"
                      onClick={() => history.push(route.adminDashboard)}
                    >
                      Admin Dashboard
                    </span>
                  )}
                  <div className="FlexRow verticallyCenter horizontallyCenter" ref={ref}>
                    <div
                      id="profileIcon"
                      style={{ cursor: 'pointer', padding: '.5rem .1rem', color: '#4a4546' }}
                      onClick={() => {
                        setUserModal(prev => !prev);
                      }}
                    >
                      <UserIcon name={user?.userNameSurname} surname=" " />
                    </div>
                    <div
                      style={{
                        height: '24px',
                        border: '1px solid #58585833',
                        marginLeft: '5px',
                        marginRight: '5px'
                      }}
                    />
                    {userLogo ? (
                      <img
                        onLoad={() => {
                          setImg(true);
                        }}
                        className="mt-1 pointer"
                        src={userLogo}
                        style={{
                          marginLeft: '5px',
                          maxHeight: '24px',
                          maxWidth: '100px',
                          marginRight: '10px'
                        }}
                        alt="logo"
                        onClick={() => setUserModal(prev => !prev)}
                      />
                    ) : (
                      <div className={styles.HeaderAddLogo}>
                        <CustomButton
                          textField={t('addLogo')}
                          Icon={EditLogo}
                          type={4}
                          size="small"
                          buttonOnClick={() => {
                            history.push('/settings/general');
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {user && showBanner && <Banner setShow={setShowBanner} />}
      </div>
      {ModalBannerWrapper}
    </>
  );
};

export default React.memo(Header, (prev, next) => {
  return true;
});
