import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  changeCompanyAction, getCompanyListAction,
} from '../../redux/auth/actions';
import styles from '../Styles/CompanySelectModal.module.css';
import CloseButton from '../Components/CloseButton';
import { setLogo } from '../../redux/logo/slice';
import { clearUserData } from '../../redux/auth/slice';
import Modal from '../CustomModal/Modal';

export default function companySelectModal({ currentUser, onHide, ...props }) {
  const dispatch = useDispatch();

  const [companies, setCompanies] = useState([]);

  const onChange = async (company, id) => {
    const formData = new FormData();
    formData.append('companyId', id);
    const rawData = {
      companyId: id,
    }
    localStorage.setItem('activeCompany', JSON.stringify(company));
    await dispatch(changeCompanyAction(rawData))
    onHide();
    dispatch(setLogo(null));
    dispatch(clearUserData());
    location.reload();
  };

  useEffect(async () => {
    if (props.show) {
      const response = await dispatch(getCompanyListAction());
      setCompanies(response?.payload);
    }
  }, [props.show]);

  return (
    <Modal
      contentClassName={styles.CompanySelectModalContent}
      onClose={onHide}
      show={props.show}
      centerHorizontally
      centerVertically
      outsideClickClose
    >
      <div className={styles['modal-title']}>
        <h3>Select Corporate</h3>
        <p>{currentUser?.companyName}</p>

        <span className={styles['top-right']}>
          <CloseButton onClick={onHide} size="large" />
        </span>
      </div>
      <div className="row">
        {companies  &&
          companies.map((item, index) => (
            <div key={index} className="col-md-3" onClick={() => onChange(item, item.id)}>
              <div className={`${styles['company-card']}`}>
                <span>{JSON.stringify(item.name)}</span>
              </div>
            </div>
          ))}
      </div>
    </Modal>
  );
}
