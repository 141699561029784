import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import mixpanel from '../mixpanel';

import mixpanelEventNames from './mixpaneEventNames.json';
import {
  AssessmentTitle,
  CandidateEmail,
  CompanyName,
  CvCheck,
  rootAssessmentNameRedux,
  screeningCurrencyTypeRedux,
  screeningExperienceRedux,
  screeningListObjRedux,
  screeningNationalityIdsRedux,
  screeningSalaryExpectationRedux,
  screeningWorkTypeListRedux
} from '../../redux/AssessmentSession/selectors';
import { isJsonString } from '../helpers';

export default function useMixpanelScreeningNextClick(triggerFlag, application) {
  const assessmentTitle = useSelector(AssessmentTitle);
  const rootAssessmentName = useSelector(rootAssessmentNameRedux);
  const companyName = useSelector(CompanyName);
  const email = useSelector(CandidateEmail);
  const cvCheck = useSelector(CvCheck);

  const questionListObj = useSelector(screeningListObjRedux);
  const reduxscreeningWorkTypeList = useSelector(screeningWorkTypeListRedux);
  const reduxscreeningExperience = useSelector(screeningExperienceRedux);
  const reduxscreeningNationalityIds = useSelector(screeningNationalityIdsRedux);
  const reduxscreeningCurrencyType = useSelector(screeningCurrencyTypeRedux);
  const reduxscreeningSalaryExpectation = useSelector(screeningSalaryExpectationRedux);

  useEffect(() => {
    function mixpanelPageviewEventHelper(eventName, properties) {
      mixpanel.track(eventName, properties);
    }

    if (triggerFlag) {
      const properties = {
        'Application Flag': !!application,
        'Assessment Name': assessmentTitle,
        'Root Assessment Name': rootAssessmentName,
        'Company Name': companyName,
        'Candidate Email': email,
        'Already Scored Flag':
          isJsonString(localStorage.getItem('isCandidateAlreadyPassed')) &&
          JSON.parse(localStorage.getItem('isCandidateAlreadyPassed')),
        'Is CV Needed Flag': cvCheck,

        'Experience Year': questionListObj.experience.experiences
          .filter(e => reduxscreeningExperience === e.id)
          .map(e => e.name)[0],
        'Work Type Preference': questionListObj.workType.workTypes
          .filter(e => reduxscreeningWorkTypeList.includes(e.id))
          .map(e => e.name),
        'Work Permit List': questionListObj.nationality.nationalities
          .filter(e => reduxscreeningNationalityIds?.includes(e.id))
          .map(e => e.name),
        'Currency Type': questionListObj.salaryExpectation.currencyTypes
          .filter(e => reduxscreeningCurrencyType === e.id)
          .map(e => e.name)[0],
        'Expected Salary': reduxscreeningSalaryExpectation,

        'Previously Selected Experience Year':
          questionListObj.experience.experiences
            .filter(e => questionListObj.experience.currentExperience === e.id)
            ?.map(e => e.name)[0] || null,
        'Previously Selected Work Type Preference': questionListObj.workType.workTypes
          .filter(e => questionListObj.workType.currentWorkTypeAnswers.includes(e.id))
          .map(e => e.name),
        'Previously Selected Work Permit List': questionListObj.nationality.nationalities
          .filter(e => questionListObj.nationality.currentNationality?.includes(e.id))
          .map(e => e.name),
        'Previously Selected Currency Type':
          questionListObj.salaryExpectation.currencyTypes
            .filter(e => questionListObj.salaryExpectation.currentCurrencyType === e.id)
            ?.map(e => e.name)[0] || null,
        'Previously Selected Expected Salary':
          questionListObj.salaryExpectation.currentSalaryExpectation
      };
      mixpanelPageviewEventHelper(mixpanelEventNames.screeningNextButtonClick, properties);
    }
  }, [triggerFlag]);
}
