import { loader } from "@monaco-editor/react";
import * as monaco from "monaco-editor";

import { conf, language } from 'monaco-editor/esm/vs/basic-languages/typescript/typescript';
import { conf as jsConf, language as jsLanguage } from 'monaco-editor/esm/vs/basic-languages/javascript/javascript';
import { conf as csConf, language as csLanguage } from 'monaco-editor/esm/vs/basic-languages/csharp/csharp';
import { MonacoLanguageClient, MonacoServices } from 'monaco-languageclient';
import { StandaloneServices } from 'vscode/services';
// import getNotificationServiceOverride from 'vscode/service-override/notifications';
import getDialogServiceOverride from 'vscode/service-override/dialogs';
import { toSocket, WebSocketMessageReader, WebSocketMessageWriter } from 'vscode-ws-jsonrpc';
import { CloseAction, ErrorAction, MessageTransports } from 'vscode-languageclient';

loader.config({ monaco });

console.error = function() { }

StandaloneServices.initialize({
  // ...getNotificationServiceOverride(document.body),
  ...getDialogServiceOverride()
});

function getInitializationOptionsForLanguage(language) {
  if (language === "ts") {
    return {
      initializationOptions: {
        disableAutomaticTypingAcquisition: true,
        preferences: {
          includeInlayParameterNameHints: 'none',
          includeInlayParameterNameHintsWhenArgumentMatchesName: false,
          includeInlayFunctionParameterTypeHints: false,
          includeInlayVariableTypeHints: true,
          includeInlayVariableTypeHintsWhenTypeMatchesName: false,
          includeInlayPropertyDeclarationTypeHints: false,
          includeInlayFunctionLikeReturnTypeHints: false,
          includeInlayEnumMemberValueHints: false,
        }
      },
    };
  }
  if (language === "java") {
    return {
      settings: [
        {
          java: {
            autobuild: false,
            gradle: {
              offline: true,
            },
            completion: {
              enabled: false
            }
          }
        }
      ]
    }
  }
  return null;
}

function createLanguageClient(transports, documentSelector) {
  const clientOptions = {
    // rootPath: "/tmp",
    // rootUri: "file:///tmp",
    trace: "verbose",
    documentSelector: [documentSelector],
    // disable the default error handler
    errorHandler: {
      error: err => {
        // return { action: ErrorAction.Shutdown };
        return { action: ErrorAction.Continue };
      },
      closed: () => {
        return { action: CloseAction.DoNotRestart };
      }
    }
  };
  if (documentSelector !== "cs") {
    clientOptions.workspaceFolders = ["/tmp"];
  }
  const initializationOptions = getInitializationOptionsForLanguage(documentSelector);
  if (initializationOptions) {
    clientOptions.initializationOptions = initializationOptions;
  }
  return new MonacoLanguageClient({
    name: 'Coensio Language Client',
    clientOptions: clientOptions,
    // create a language client connection from the JSON RPC connection on demand
    connectionProvider: {
      get: () => {
        return Promise.resolve(transports);
      }
    }
  });
}

let previousLangClient = null;
let currentLangCode = "";
let currentQuestionIndex = 0;

export const disconnectFromLSP = () => {
  if (previousLangClient !== null) {
    try {
      previousLangClient.stop();
      previousLangClient.dispose();
    } catch (e) {
      //
    }
  }
}

const __connectToServerByType = (lang, initialText, questionIndex) => {
  if (lang === "sql") {
    return;
  }
  if (currentLangCode === lang && questionIndex === currentQuestionIndex) {
     return;
  }
  currentQuestionIndex = questionIndex;
  currentLangCode = lang;
  if (previousLangClient !== null) {
    try {
      previousLangClient.stop();
      // previousLangClient.dispose();
    } catch (e) {
      //
    }
  }
  const url = `${process.env.REACT_APP_LSP_URL}/lsp/${lang}`;
  if (lang === "py") {
    lang = 'python';
  }
  // const url = createUrl('test.cenanozen.com', 80, `/${lang}`);
  const webSocket = new WebSocket(url);
  webSocket.onopen = () => {
    const socket = toSocket(webSocket);
    const reader = new WebSocketMessageReader(socket);
    const writer = new WebSocketMessageWriter(socket);
    if (lang === "cs") {
      writer.write({
        jsonrpc: "2.0",
        id: "0",
        method: "__init_cns",
        params: {
          initialText
        }
      });
    }
    const languageClient = createLanguageClient({
      reader,
      writer
    }, lang);
    previousLangClient = languageClient;
    languageClient.start();
    reader.onClose(() => languageClient.stop());
  };
};

export const connectToServerByType = (() => {
  let prevCode = "";
  let timer;
  return ((lang, initialText, questionIndex) => {
    if (prevCode !== "") {
      prevCode = lang;
      clearTimeout(timer);
    }
    prevCode = lang;
    timer = setTimeout(() => {
      __connectToServerByType(lang, initialText, questionIndex);
    }, 500);
  });
})();

export function initializeMonacoLanguages(monaco) {
  monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
    noSemanticValidation: true,
    noSyntaxValidation: true,
  });
  monaco.languages.register({
    id: 'js',
    extensions: ['.js', '.jsx'],
    aliases: ['javascript'],
    mimetypes: ['text/plain'],
  });

  monaco.languages.register({
    id: 'cs',
    extensions: ['.cs'],
    aliases: ['csharp'],
    mimetypes: ['text/plain']
  });

  monaco.languages.register({
    id: 'ts',
    extensions: ['.ts', '.tsx'],
    aliases: ['typescript'],
    mimetypes: ['text/plain'],
  }, {
    id: 'kotlin',
    extensions: ['.kt'],
    aliases: ['Kotlin', 'kotlin'],
    mimetypes: ['text/x-kotlin-source', 'text/x-kotlin', 'text/plain'],
  }, {
    id: 'sql',
    extensions: ['.sql'],
    aliases: [],
    mimetypes: ['text/plain'],
  }, {
    id: 'java',
    extensions: ['.java'],
    aliases: [],
    mimetypes: ['text/plain'],
  }, {
    id: 'py',
    extensions: ['.py'],
    aliases: ['python'],
    mimetypes: ['text/plain'],
  });

  monaco.languages.setMonarchTokensProvider('ts', language);
  monaco.languages.setLanguageConfiguration('ts', conf);
  monaco.languages.setMonarchTokensProvider('cs', csLanguage);
  monaco.languages.setLanguageConfiguration('cs', csConf);
  monaco.languages.setMonarchTokensProvider('js', jsLanguage);
  monaco.languages.setLanguageConfiguration('js', jsConf);

  // monaco.editor.createModel("", "ts", monaco.Uri.parse("file.ts"))
  MonacoServices.install();
}

export function getFilePathFromLanguage(language) {
  if (language === "kotlin") {
    return "file:///ws/file.kt";
  }
  if (language === "java") {
    return "file:///jdt/workspace/src/main/java/io/coensio/lsp/file.java";
  }
  if (language === "cs") {
    return "file:///csws/file.cs";
  }
  return `file:///tmp/file.${language}`;
}
