import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from './UserInfoQuestionComponent.module.css';
import { ReactComponent as Arrow } from '../../Images/backIcon.svg';
import { userInfoStepRedux, userInfoSelectedAnswerRedux } from '../../../redux/signup/selectors';
import { setUserInfoStep, setUserInfoSelectedAnswer } from '../../../redux/signup/slice';
import { createSignupInfoAction } from '../../../redux/signup/actions';
import { getDomainProtocol, getOnlyDomain } from '../../../utils/helpers';

import mixpanel from '../../../utils/mixpanel';

const UserInfoQuestionComponent = ({
  userId,
  isFirstQuestion,
  isLastQuestion,
  question,
  answers
}) => {
  const buttonText = isLastQuestion ? 'Done' : 'Continue';
  const [answer1, setAnswer1] = useState(false);
  const [answer2, setAnswer2] = useState(false);
  const [answer3, setAnswer3] = useState(false);
  const [selected, setSelected] = useState(null);

  const selectedAnswerList = useSelector(userInfoSelectedAnswerRedux);
  const list = selectedAnswerList.slice();
  const answerList = [null, null, null];
  const userInfoStep = useSelector(userInfoStepRedux);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedAnswerList[userInfoStep - 1] === 'answer1') {
      setAnswer1(true);
      setSelected('answer1');
    }
    if (selectedAnswerList[userInfoStep - 1] === 'answer2') {
      setAnswer2(true);
      setSelected('answer2');
    }
    if (selectedAnswerList[userInfoStep - 1] === 'answer3') {
      setAnswer3(true);
      setSelected('answer3');
    }
  }, [userInfoStep]);

  const createAnswerList = () => {
    if (list[1] === 'answer1') {
      // Question 2's answers
      answerList[0] = '1 - 2';
    }
    if (list[1] === 'answer2') {
      answerList[0] = '3 - 5';
    }
    if (list[1] === 'answer3') {
      answerList[0] = '5+';
    }
    if (list[2] === 'answer1') {
      // Question 3's answers
      answerList[1] = '1 - 50';
    }
    if (list[2] === 'answer2') {
      answerList[1] = '51 - 100';
    }
    if (list[2] === 'answer3') {
      answerList[1] = '100+';
    }
    if (list[3] === 'answer1') {
      // Question 4's answers
      answerList[2] = '1 - 10';
    }
    if (list[3] === 'answer2') {
      answerList[2] = '11 - 50';
    }
    if (list[3] === 'answer3') {
      answerList[2] = '50+';
    }
  };

  /* --------------- Mixpanel variable --------------- */
  const { search } = useLocation();
  const email = new URLSearchParams(search).get('email');

  const handleContinueClicked = async () => {
    dispatch(setUserInfoStep(userInfoStep + 1));
    list[userInfoStep - 1] = selected;
    dispatch(setUserInfoSelectedAnswer(list));
    if (isLastQuestion) {
      let role = 0;
      if (list[0] === 'answer1') {
        // FOUNDER
        role = 30;
      }
      if (list[0] === 'answer2') {
        // HIRING MANAGER
        role = 10;
      }
      if (list[0] === 'answer3') {
        // RECRUITER
        role = 20;
      }

      createAnswerList();
      const data = {
        userId: userId,
        role: role,
        jobAdds: answerList[0],
        applicant: answerList[1],
        companySize: answerList[2]
      };
      await dispatch(createSignupInfoAction(data));

      /* --------------- Mixpanel click event --------------- */
      mixpanel.track('Signup Survey', {
        "Encrypted Email": email,
        "User Id": userId,
        "Role Selection": role === 30 ? "Founder" : role === 20 ? "Recruiter" : role === 10 ? "Hiring Manager" : "Not set",
        "Job Posting Selection": answerList[0],
        "Applicant Count Selection": answerList[1],
        "Company Size Selection": answerList[2],
      })
      /* ------------------------------------------------- */

      window.location.href = `${getDomainProtocol()}://app.${getOnlyDomain()}/assessments`;
    }
  };
  const handleBackClicked = () => {
    dispatch(setUserInfoStep(userInfoStep - 1));
    list[userInfoStep - 1] = selected;
    dispatch(setUserInfoSelectedAnswer(list));
  };


  const Back = () => {
    return (
      <div className={styles.back} onClick={handleBackClicked}>
        <Arrow className={styles.arrow} />
        <div className={styles.backText}>Back</div>
      </div>
    );
  };

  return (
    <div className={styles.questionWrapper}>
      <div className={styles.Title}>{question}</div>
      <div className={styles.answerWrapper}>
        <div
          onClick={() => {
            if (answer1) {
              setAnswer1(false);
              setSelected(null);
            } else {
              setAnswer1(true);
              setSelected('answer1');
            }

            setAnswer2(false);
            setAnswer3(false);
          }}
          className={styles.answerButton}
          style={{
            background: answer1 ? '#7458d3' : '#ffffff',
            color: answer1 ? '#ffffff' : ' #7458d3'
          }}
        >
          {answers[0]}
        </div>
        <div
          onClick={() => {
            if (answer2) {
              setAnswer2(false);
              setSelected(null);
            } else {
              setAnswer2(true);
              setSelected('answer2');
            }
            setAnswer1(false);
            setAnswer3(false);
          }}
          className={styles.answerButton}
          style={{
            background: answer2 ? '#7458d3' : '#ffffff',
            color: answer2 ? '#ffffff' : ' #7458d3'
          }}
        >
          {answers[1]}
        </div>
        <div
          onClick={() => {
            if (answer3) {
              setAnswer3(false);
              setSelected(null);
            } else {
              setAnswer3(true);
              setSelected('answer3');
            }
            setAnswer1(false);
            setAnswer2(false);
          }}
          className={styles.answerButton}
          style={{
            background: answer3 ? '#7458d3' : '#ffffff',
            color: answer3 ? '#ffffff' : ' #7458d3'
          }}
        >
          {answers[2]}
        </div>
      </div>
      <div className={styles.ButtonWrapper} style={{ justifyContent: isFirstQuestion ? 'end' : 'space-between' }}>
        {!isFirstQuestion && <Back />}
        <div onClick={handleContinueClicked} className={styles.button}>
          {buttonText}
        </div>
      </div>
    </div>
  );
};

export default UserInfoQuestionComponent;
