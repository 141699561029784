export const categoryRedux = state => state.addQuestion.category;
export const assessmentLabelRedux = state => state.addQuestion.assessmentLabel;
export const subcategoryRedux = state => state.addQuestion.subcategory;
export const titleRedux = state => state.addQuestion.title;
export const difficultyRedux = state => state.addQuestion.difficulty;
export const languageRedux = state => state.addQuestion.language;
export const limitRedux = state => state.addQuestion.limit;
export const statusRedux = state => state.addQuestion.status;

export const questionDescriptionRedux = state => state.addQuestion.questionDescription;

export const multiSelectAnswersRedux = state => state.addQuestion.multiSelectAnswers;
export const selectedOptionIndexRedux = state => state.addQuestion.selectedOptionIndex;

export const freeTextAnswersRedux = state => state.addQuestion.freeTextAnswers;

export const testCasesRedux = state => state.addQuestion.testCases;
export const validCasesRedux = state => state.addQuestion.validCases;
export const outputVariableRedux = state => state.addQuestion.outputVariable;
export const parametersRedux = state => state.addQuestion.parameters;
export const initialSelectedLangRedux = state => state.addQuestion.initialSelectedLang;
export const initialCodeDictionaryRedux = state => state.addQuestion.initialCodeDictionary;
export const isPassedVerificationRedux = state => state.addQuestion.isPassedVerification;
export const verificationCodeRedux = state => state.addQuestion.verificationCode;
export const verificationSelectedLangRedux = state => state.addQuestion.verificationSelectedLang;

export const sqlQueryTypeRedux = state => state.addQuestion.sqlQueryType;
export const sqlCreateTableCodeRedux = state => state.addQuestion.sqlCreateTableCode;
export const sqlTestCasesRedux = state => state.addQuestion.sqlTestCases;
export const sqlValidCasesRedux = state => state.addQuestion.sqlValidCases;
export const sqlVerificationCodeRedux = state => state.addQuestion.sqlVerificationCode;


export const isSuccessRedux = state => state.addQuestion.isSuccess;
export const encryptedQuestionIdRedux = state => state.addQuestion.encryptedQuestionId;
export const verificationCodeResponseRedux = state => state.addQuestion.verificationCodeResponse;