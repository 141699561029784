import API from '../../constant/apiVol2';
import client from '../../constant/indexVol2';

export const getCustomerSuccessTeamService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCustomerSuccessTeam, { params: data }).then(resolve).catch(reject);
  });
export const getCompanyListService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCompanyList, { params: data }).then(resolve).catch(reject);
  });

export const getCompanyBundleInfoService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCompanyBundleInfo, { params: data }).then(resolve).catch(reject);
  });

export const getTemplatesService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getTemplates, { params: data }).then(resolve).catch(reject);
  });

export const getTemplateInfoService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getTemplateInfo, { params: data }).then(resolve).catch(reject);
  });

export const createTemplateService = data =>
  new Promise((resolve, reject) => {
    client.post(API.createTemplate, data).then(resolve).catch(reject);
  });

export const updateCompanyService = data =>
  new Promise((resolve, reject) => {
    client.post(API.updateCompany, data).then(resolve).catch(reject);
  });

export const createCompanyService = data =>
  new Promise((resolve, reject) => {
    client.post(API.createCompany, data).then(resolve).catch(reject);
  });

export const cancelCustomBundleService = data =>
  new Promise((resolve, reject) => {
    client.post(API.cancelCustomBundle, data).then(resolve).catch(reject);
  });

export const connectCompanyWithBundleService = data =>
  new Promise((resolve, reject) => {
    client.post(API.connectCompanyWithBundle, data).then(resolve).catch(reject);
  });
