import React, { useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

import PageContainer from '../../../Common/Pages/PageContainer';
import styles from './EditCandidateResult.module.css';
import Dropdown from '../../../Common/Dropdown/Dropdown';
import CustomButton from '../../../Common/Components/CustomButton';
import { getCompanyListAction, getContentAction, updateContentAction } from '../../Api/actions';
import TextInput from '../../../Common/Components/TextInput';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'draft-js-emoji-plugin/lib/plugin.css';

const CompanyCandidateResult = () => {
  const [loading, setLoading] = useState(false);
  const [corporate, setCorporate] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [assessmentDetails, setAssessmentDetails] = useState(null);

  const [wsScored, setWsScored] = useState(null);
  const [wsNotScored, setWsNotScored] = useState(null);
  const [appScored, setAppScored] = useState(null);
  const [appNotScored, setAppNotScored] = useState(null);

  const [wcScoredTr, setWcScoredTr] = useState(() => EditorState.createEmpty());
  const [wcScoredEn, setWcScoredEn] = useState(() => EditorState.createEmpty());
  const [wcNotScoredTr, setWcNotScoredTr] = useState(() => EditorState.createEmpty());
  const [wcNotScoredEn, setWcNotScoredEn] = useState(() => EditorState.createEmpty());

  const [appScoredTr, setAppScoredTr] = useState(() => EditorState.createEmpty());
  const [appScoredEn, setAppScoredEn] = useState(() => EditorState.createEmpty());
  const [appNotScoredTr, setAppNotScoredTr] = useState(() => EditorState.createEmpty());
  const [appNotScoredEn, setAppNotScoredEn] = useState(() => EditorState.createEmpty());

  useEffect(() => {
    fetcCompanyList();
  }, []);

  useEffect(() => {
    setWsScored(null);
    setWsNotScored(null);
    setAppScored(null);
    setAppNotScored(null);
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedCompany) {
      fetchDetails(selectedCompany.id);
    }
  }, [selectedCompany]);

  const fetcCompanyList = async () => {
    const resp = await getCompanyListAction();
    if (resp.data) {
      const companies = resp.data.map(company => {
        return {
          id: company.id,
          name: company.name
        };
      });
      setCorporate(companies);
    }
  };

  const fetchDetails = async companyId => {
    setLoading(true);

    try {
      const resp = await getContentAction({ companyId });

      if (resp.data) {
        const details = resp.data.templates;

        const wcScored = details
          .filter(_ => _.sourceType === 1)
          .filter(_ => _.funnelStatus === 1)[0];
        setWsScored(wcScored);
        setWcScoredTr(fromHtml(wcScored.subtitleTr));
        setWcScoredEn(fromHtml(wcScored.subtitleEn));
        const wsNotScored = details
          .filter(_ => _.sourceType === 1)
          .filter(_ => _.funnelStatus === 2)[0];
        setWsNotScored(wsNotScored);
        setWcNotScoredTr(fromHtml(wsNotScored.subtitleTr));
        setWcNotScoredEn(fromHtml(wsNotScored.subtitleEn));
        const appScored = details
          .filter(_ => _.sourceType === 2)
          .filter(_ => _.funnelStatus === 1)[0];
        setAppScored(appScored);
        setAppScoredTr(fromHtml(appScored.subtitleTr));
        setAppScoredEn(fromHtml(appScored.subtitleEn));
        const appNotScored = details
          .filter(_ => _.sourceType === 2)
          .filter(_ => _.funnelStatus === 2)[0];
        setAppNotScored(appNotScored);
        setAppNotScoredTr(fromHtml(appNotScored.subtitleTr));
        setAppNotScoredEn(fromHtml(appNotScored.subtitleEn));

        setAssessmentDetails(details);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const wsScoredTrHtml = draftToHtml(convertToRaw(wcScoredTr.getCurrentContent()));
      const wsScoredEnHtml = draftToHtml(convertToRaw(wcScoredEn.getCurrentContent()));
      const newWsScored = {
        ...wsScored,
        subtitleTr: wsScoredTrHtml,
        subtitleEn: wsScoredEnHtml
      };

      const wsNotScoredTrHtml = draftToHtml(convertToRaw(wcNotScoredTr.getCurrentContent()));
      const wsNotScoredEnHtml = draftToHtml(convertToRaw(wcNotScoredEn.getCurrentContent()));
      const newWsNotScored = {
        ...wsNotScored,
        subtitleTr: wsNotScoredTrHtml,
        subtitleEn: wsNotScoredEnHtml
      };

      const appScoredTrHtml = draftToHtml(convertToRaw(appScoredTr.getCurrentContent()));
      const appScoredEnHtml = draftToHtml(convertToRaw(appScoredEn.getCurrentContent()));
      const newAppScored = {
        ...appScored,
        subtitleTr: appScoredTrHtml,
        subtitleEn: appScoredEnHtml
      };

      const appNotScoredTrHtml = draftToHtml(convertToRaw(appNotScoredTr.getCurrentContent()));
      const appNotScoredEnHtml = draftToHtml(convertToRaw(appNotScoredEn.getCurrentContent()));
      const newAppNotScored = {
        ...appNotScored,
        subtitleTr: appNotScoredTrHtml,
        subtitleEn: appNotScoredEnHtml
      };

      const data = [newWsScored, newWsNotScored, newAppScored, newAppNotScored];

      const resp = await updateContentAction({
        companyAssessmentId: null,
        companyId: selectedCompany.id,
        templates: data
      });

      if (resp.data) {
        console.log(resp.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      fetchDetails(selectedCompany.id);
    }
  };

  const fromHtml = html => {
    const blocksFromHTML = htmlToDraft(html);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    return EditorState.createWithContent(state);
  };

  return (
    <PageContainer>
      <div className={styles.container}>
        <div className={styles.upperRow}>
          <Dropdown
            title="Company"
            placeholder="Select Company"
            searchBarPlaceholder="Search Company"
            list={corporate}
            openItemCount={7}
            selectedOption={setSelectedCompany}
            setSelectedOption={_ => setSelectedCompany(_)}
            isSearchable
            height="5rem"
            wrapperStyle={{ width: '300px' }}
          />
          <CustomButton
            textField="Save"
            title="Save"
            buttonOnClick={handleSave}
            customStyle={{
              width: '100px',
              height: '100%'
            }}
            loading={loading}
          />
        </div>
        {!loading && assessmentDetails && selectedCompany && (
          <div className={styles.content}>
            <div className={styles.contentCol}>
              <span className={styles.colHeader}>Welcome Candidate</span>
              <span className={styles.colSubHeader}>Already Scored</span>
              <span className={styles.colSubHeader}>TR</span>
              <div className={styles.inputContainer}>
                <TextInput
                  placeholder="Title (TR)"
                  value={wsScored.titleTr || ''}
                  onChange={e => {
                    setWsScored({
                      ...wsScored,
                      titleTr: e.target.value
                    });
                  }}
                  customStyle={{ width: '100%' }}
                />
                <Editor
                  editorState={wcScoredTr}
                  onEditorStateChange={setWcScoredTr}
                  toolbarClassName={styles.editorToolbar}
                  wrapperClassName={styles.editorWrapper}
                  editorClassName={styles.editor}
                />
                <span className={styles.colSubHeader}>EN</span>
                <div className={styles.inputContainer}>
                  <TextInput
                    placeholder="Title (EN)"
                    value={wsScored.titleEn || ''}
                    onChange={e => {
                      setWsScored({
                        ...wsScored,
                        titleEn: e.target.value
                      });
                    }}
                    customStyle={{ width: '100%' }}
                  />
                  <Editor
                    editorState={wcScoredEn}
                    onEditorStateChange={setWcScoredEn}
                    toolbarClassName={styles.editorToolbar}
                    wrapperClassName={styles.editorWrapper}
                    editorClassName={styles.editor}
                  />
                </div>

                <span className={styles.colSubHeader}>Not Scored</span>
                <span className={styles.colSubHeader}>TR</span>
                <div className={styles.inputContainer}>
                  <TextInput
                    placeholder="Title (TR)"
                    value={wsNotScored.titleTr || ''}
                    onChange={e => {
                      setWsNotScored({
                        ...wsNotScored,
                        titleTr: e.target.value
                      });
                    }}
                    customStyle={{ width: '100%' }}
                  />
                  <Editor
                    editorState={wcNotScoredTr}
                    onEditorStateChange={setWcNotScoredTr}
                    toolbarClassName={styles.editorToolbar}
                    wrapperClassName={styles.editorWrapper}
                    editorClassName={styles.editor}
                  />{' '}
                </div>

                <span className={styles.colSubHeader}>EN</span>
                <div className={styles.inputContainer}>
                  <TextInput
                    placeholder="Title (EN)"
                    value={wsNotScored.titleEn || ''}
                    onChange={e => {
                      setWsNotScored({
                        ...wsNotScored,
                        titleEn: e.target.value
                      });
                    }}
                    customStyle={{ width: '100%' }}
                  />
                  <Editor
                    editorState={wcNotScoredEn}
                    onEditorStateChange={setWcNotScoredEn}
                    toolbarClassName={styles.editorToolbar}
                    wrapperClassName={styles.editorWrapper}
                    editorClassName={styles.editor}
                  />
                </div>
              </div>
            </div>
            <div className={styles.contentCol}>
              <span className={styles.colHeader}>Application</span>
              <span className={styles.colSubHeader}>Already Scored</span>
              <span className={styles.colSubHeader}>TR</span>
              <div className={styles.inputContainer}>
                <TextInput
                  placeholder="Title (TR)"
                  value={appScored.titleTr || ''}
                  onChange={e => {
                    setAppScored({
                      ...appScored,
                      titleTr: e.target.value
                    });
                  }}
                  customStyle={{ width: '100%' }}
                />
                <Editor
                  editorState={appScoredTr}
                  onEditorStateChange={setAppScoredTr}
                  toolbarClassName={styles.editorToolbar}
                  wrapperClassName={styles.editorWrapper}
                  editorClassName={styles.editor}
                />{' '}
                <span className={styles.colSubHeader}>EN</span>
                <div className={styles.inputContainer}>
                  <TextInput
                    placeholder="Title (EN)"
                    value={appScored.titleEn || ''}
                    onChange={e => {
                      setAppScored({
                        ...appScored,
                        titleEn: e.target.value
                      });
                    }}
                    customStyle={{ width: '100%' }}
                  />
                  <Editor
                    editorState={appScoredEn}
                    onEditorStateChange={setAppScoredEn}
                    toolbarClassName={styles.editorToolbar}
                    wrapperClassName={styles.editorWrapper}
                    editorClassName={styles.editor}
                  />
                </div>
                <span className={styles.colSubHeader}>Not Scored</span>
                <span className={styles.colSubHeader}>TR</span>
                <div className={styles.inputContainer}>
                  <TextInput
                    placeholder="Title (TR)"
                    value={appNotScored.titleTr || ''}
                    onChange={e => {
                      setAppNotScored({
                        ...appNotScored,
                        titleTr: e.target.value
                      });
                    }}
                    customStyle={{ width: '100%' }}
                  />
                  <Editor
                    editorState={appNotScoredTr}
                    onEditorStateChange={setAppNotScoredTr}
                    toolbarClassName={styles.editorToolbar}
                    wrapperClassName={styles.editorWrapper}
                    editorClassName={styles.editor}
                  />
                </div>
                <span className={styles.colSubHeader}>EN</span>
                <div className={styles.inputContainer}>
                  <TextInput
                    placeholder="Title (EN)"
                    value={appNotScored.titleEn || ''}
                    onChange={e => {
                      setAppNotScored({
                        ...appNotScored,
                        titleEn: e.target.value
                      });
                    }}
                    customStyle={{ width: '100%' }}
                  />
                  <Editor
                    editorState={appNotScoredEn}
                    onEditorStateChange={setAppNotScoredEn}
                    toolbarClassName={styles.editorToolbar}
                    wrapperClassName={styles.editorWrapper}
                    editorClassName={styles.editor}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </PageContainer>
  );
};

export default CompanyCandidateResult;
