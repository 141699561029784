import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ReactComponent as CoensioLogo } from '../../../images/Common/Coensio_newLogo.svg';
import styles from './InvitedUserActivateComponent.module.css';
import CustomButton from '../../../Common/Components/CustomButton';
import Checkbox from '../../../Common/Components/Checkbox';
import PasswordInput from '../../../Common/Components/PasswordInput';
import { invitedUserActivateAction } from '../../../redux/signup/actions';
import { getDomainProtocol, getOnlyDomain } from '../../../utils/helpers';
import { invitedUserCompanyLogoRedux } from '../../../redux/signup/selectors';

const InvitedUSerActivateComponent = () => {
  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordAgainError, setPasswordAgainError] = useState(false);

  const [isChecked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const { search } = useLocation();
  const objId = new URLSearchParams(search).get('userId');

  const dispatch = useDispatch();
  const compLogo = useSelector(invitedUserCompanyLogoRedux);
  const [logoPath, setLogoPath] = useState();

  useEffect(() => {
    if (compLogo?.startsWith('CompanyLogo/')) {
      setLogoPath(`${process.env.REACT_APP_IMAGE_API}/${compLogo}`);
    } else {
      setLogoPath();
    }
  }, [compLogo]);

  function handleCheckbox() {
    if (isChecked) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  }

  const handleCreateMyAccount = async () => {
    setLoading(true);
    const data = {
      objId,
      password,
      passwordAgain
    };

    const resp = await dispatch(invitedUserActivateAction(data));
    if (resp.meta.requestStatus === 'fulfilled') {
      if (resp?.payload?.accessToken) {
        localStorage.setItem('authToken', resp?.payload?.accessTokenV1);
        localStorage.setItem('authTokenVol2', resp?.payload?.accessToken);
        setTimeout(() => {
          window.location.href = `${getDomainProtocol()}://app.${getOnlyDomain()}/assessments`;
        }, 100);
      } else {
        setTimeout(() => {
          window.location.href = `${getDomainProtocol()}://app.${getOnlyDomain()}/login`;
        }, 100);
      }
    }
    setLoading(false);
  };

  return (
    <div className={styles.PageWrapper}>
      <div className={styles.CardWrapper}>
        <div className={styles.outerCard}>
          <div className={styles.innerCard}>
            <CoensioLogo className={styles.logo} />
            <div className={styles.titleWrapper}>
              <div className={styles.cardTitle}>Join your Team</div>
              <div className={styles.logoWrapper}>
                {logoPath && <img src={logoPath} alt="compLogo" />}
              </div>
            </div>
            <div className={styles.passwordWrapper}>
              <PasswordInput
                value={password}
                secondPasswordForPasswordField={passwordAgain}
                placeholder="Password*"
                onChange={e => setPassword(e.target.value)}
                setParentErrorState={setPasswordError}
                showInvisibleError
              />
            </div>
            <div className={styles.passwordWrapper}>
              <PasswordInput
                id="PasswordInput"
                value={passwordAgain}
                isPasswordAgain
                firstPasswordForPasswordAgainField={password}
                placeholder="Password Again*"
                onChange={e => setPasswordAgain(e.target.value)}
                setParentErrorState={setPasswordAgainError}
                showInvisibleError
              />
            </div>
            <div className={styles.terms}>
              <Checkbox
                labelStyle={{ marginRight: '1rem', marginTop: '0.2rem' }}
                myOnClick={() => handleCheckbox()}
                myChecked={isChecked}
              />

              <div className={styles.text} onClick={() => handleCheckbox()}>
                I agree to the&nbsp;
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://coens.io/terms-of-use/"
                  className={styles.link}
                  onClick={e => e.stopPropagation()}
                >
                  Terms of Services
                </a>
                &nbsp;and&nbsp;
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://coens.io/privacy-policy/"
                  className={styles.link}
                  onClick={e => e.stopPropagation()}
                >
                  Privacy Policy
                </a>
                .
              </div>
            </div>
            <CustomButton
              isDisabled={
                !password || !passwordAgain || passwordError || passwordAgainError || !isChecked
              }
              loading={loading}
              buttonOnClick={handleCreateMyAccount}
              customStyle={{ width: '100%' }}
              textField="Create My Account"
              enterEnabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvitedUSerActivateComponent;
