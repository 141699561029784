import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';

import Header from '../Header/Header';
import {
  CandidateEmail,
  CompanyName,
  CvCheck,
  rootAssessmentNameRedux,
  screeningCheckRedux
} from '../../../../redux/AssessmentSession/selectors';
import {
  getCandidateInfoFromEmailAction,
  getScreeningQuestionsAction
} from '../../../../redux/AssessmentSession/actions';

import DoubleArrowIcon from '../../Images/doubleArrow.svg';
import DoubleArrowIconMobile from '../../Images/doubleArrowMobile.svg';

import styles from './UnpublishedAutoApplyComponent.module.css';
import CvCardComponent from '../CvCardComponent/CvCardComponent';
import AutoApplyComponent from './AutoApplyComponent';
import ScreeningCardComponent from '../Screening/ScreeningCardComponent';
import {
  ASSESSMENT_FLOW_MOBILE_THRESHOLD,
  ASSESSMENT_FLOW_CODING_MOBILE_THRESHOLD,
  useWindowSize
} from '../../../../utils/helpers';

const CLOSED = 0;
const OPEN = 1;

export default function UnpublishedAutoApplyComponent({ autoApplyStatus, id, application }) {
  const { t } = useTranslation(['application']);

  const [screeningCompleted, setScreeningCompleted] = useState(false);
  const dispatch = useDispatch();
  const email = useSelector(CandidateEmail);
  const companyName = useSelector(CompanyName);
  const rootAssessmentName = useSelector(rootAssessmentNameRedux);
  const [width] = useWindowSize();

  const [cardState, setCardState] = useState(OPEN);
  const [path, setPath] = useState(null);

  const [closeWindowFlag, setCloseWindowFlag] = useState(false);
  const [innerPhone, setInnerPhone] = useState();

  const screeningCheck = useSelector(screeningCheckRedux);
  const cvCheck = useSelector(CvCheck);

  useEffect(async () => {
    const data = {
      UserInfo: email,
      UserInfoType: 10
    };

    dispatch(getScreeningQuestionsAction(data));

    const jsonData = {
      Email: email,
      CompanyAssessmentKey: id
    };
    const resp = await dispatch(getCandidateInfoFromEmailAction(jsonData));
    const innerPhone = resp.payload.phone;

    if (innerPhone && !innerPhone.startsWith('+')) {
      setInnerPhone(`+${innerPhone}`);
    }
  }, []);

  return (
    <ContentBody>
      <PageWrapper>
        <Ellipse />
        <div style={{ zIndex: 1 }}>
          <Header transparent expired />
        </div>
        <ContentWrapper>
          {screeningCheck && !screeningCompleted ? (
            <ScreeningCardComponent
              closeScreening={() => setScreeningCompleted(true)}
              application={application}
            />
          ) : (
            <div className={styles.NewOpWrapper}>
              <div className={styles.NewOpInfoWrapper}>
                <div className={styles.CvHeader}>
                  <Trans i18nKey="unpublishedAutoApplyTitle">
                    It’s your <span style={{ color: '#7458D3' }}>lucky</span> day!
                  </Trans>
                </div>
                <div className={styles.NewOpInfoSubtitle}>
                  <Trans
                    i18nKey="unpublishedAutoApplySubtitle1"
                    values={{ companyName: companyName, rootAssessmentName: rootAssessmentName }}
                  >
                    {{ companyName }} is no longer looking for
                    <span style={{ color: '#DE4747' }}>{{ rootAssessmentName }}</span>. However,
                    many companies still do!
                  </Trans>
                </div>
                <div className={styles.NewOpInfoSubtitle}>{t('unpublishedAutoApplySubtitle2')}</div>
              </div>
              {cvCheck && (
                <CvCardComponent
                  id={id}
                  cardState={cardState}
                  setCardState={setCardState}
                  path={path}
                  setPath={setPath}
                  innerPhone={innerPhone}
                  setParentSavedState={setCloseWindowFlag}
                />
              )}

              {autoApplyStatus !== undefined && (
                <>
                  <div
                    className={styles.NewOpInfoWrapper}
                    style={{ marginTop: '24px', marginBottom: '14px' }}
                  >
                    <div className={styles.NewOpHeaderInfoTextWrapper}>
                      <div className={styles.DoubleArrowIconWrapper}>
                        <img
                          src={
                            width < ASSESSMENT_FLOW_MOBILE_THRESHOLD
                              ? DoubleArrowIconMobile
                              : DoubleArrowIcon
                          }
                          alt="doubleArrowIcon"
                        />
                      </div>
                      <div className={styles.NewOpInfoHeader}>
                        {width <= ASSESSMENT_FLOW_MOBILE_THRESHOLD
                          ? t('autoApplyInfoHeaderMobile')
                          : t('autoApplyInfoHeaderWeb')}
                      </div>
                      <div className={styles.DoubleArrowIconWrapper}>
                        <img
                          src={
                            width < ASSESSMENT_FLOW_MOBILE_THRESHOLD
                              ? DoubleArrowIconMobile
                              : DoubleArrowIcon
                          }
                          alt="doubleArrowIcon"
                        />
                      </div>
                    </div>
                  </div>
                  <AutoApplyComponent
                    status={autoApplyStatus}
                    assessmentKey={id}
                    candidateEmail={email}
                  />
                </>
              )}
              {closeWindowFlag && !cardState && (
                <div className={styles.closeWindow}>
                  <span>{t('closeWindow')}</span>
                </div>
              )}
            </div>
          )}
        </ContentWrapper>
      </PageWrapper>
    </ContentBody>
  );
}
UnpublishedAutoApplyComponent.propTypes = {
  id: PropTypes.string.isRequired,
  application: PropTypes.bool,
  autoApplyStatus: PropTypes.bool.isRequired
};

UnpublishedAutoApplyComponent.defaultProps = {
  application: false
};

const ContentBody = styled.div`
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  background-color: #fafafa;
  position: relative;
  display: block;
  overflow-x: hidden;
`;

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  justify-content: flex-start;
  display: flex;
  z-index: 0;
  flex-direction: column;
`;

const Ellipse = styled.div`
  width: 120vw;
  position: absolute;
  z-index: 1;
  transform: rotate(180deg);
  height: 40vh;
  background: #f1eff7;
  border-radius: 50% / 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  left: -10vw;
  @media screen and (max-width: ${ASSESSMENT_FLOW_CODING_MOBILE_THRESHOLD}px) {
    border-radius: 100% / 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const ContentWrapper = styled.div`
  width: 60%;
  min-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px;
  max-width: 700px;
  padding: 0.4rem 2.6rem 2.6rem 2.6rem; // FIXME
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: fit-content;
  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    max-width: none;
    min-width: 0;
    width: 95%;
    padding: 2.4rem;
  }
`;
