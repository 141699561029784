import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styles from './NewPasswordPage.module.css';
import CreateNewPasswordComponent from '../../Components/NewPassword/CreateNewPasswordComponent';
import {
  resetNewPasswordPageErrorFlag,
  resetNewPasswordPageErrorMessage
} from '../../../redux/signup/slice';
import { forgotPasswordCheckAction } from '../../../redux/signup/actions';
import ExpiredLinkComponent from '../../Components/NewPassword/ExpiredLinkComponent';
import Loading from '../../../Common/Components/Loading';

const NewPasswordPage = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const objId = new URLSearchParams(search).get('objId');
  // const errorFlag = useSelector(newPasswordPageErrorFlagRedux);
  // const errorMessage = useSelector(newPasswordPageErrorMessageRedux);

  const [isExpired, setIsExpired] = useState(null);

  const checkForExpired = async () => {
    const resp = await dispatch(forgotPasswordCheckAction({ ObjId: objId }));
    if (resp?.meta?.requestStatus === 'rejected') {
      setIsExpired(true);
    } else {
      setIsExpired(false);
    }
  };

  useEffect(async () => {
    dispatch(resetNewPasswordPageErrorFlag());
    dispatch(resetNewPasswordPageErrorMessage());
    localStorage.removeItem('authToken');
    localStorage.removeItem('authTokenVol2');
    localStorage.removeItem('user');

    checkForExpired();
  }, []);

  return (
    <div className={styles.ContentBody}>
      {isExpired === false && <CreateNewPasswordComponent setIsExpired={setIsExpired} />}
      {isExpired === true && <ExpiredLinkComponent />}
      {isExpired === null && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%'
          }}
        >
          <Loading />
        </div>
      )}
      {/* <InfoBanner
        text={errorMessage}
        show={errorFlag}
        CustomIcon={Error}
        setStatus={type => {
          dispatch(resetNewPasswordPageErrorFlag());
          dispatch(resetNewPasswordPageErrorMessage());
        }}
        bannerStyle={{ backgroundColor: 'white', border: '1px solid rgba(221, 58, 58, 0.1)' }}
      /> */}
    </div>
  );
};

export default NewPasswordPage;
