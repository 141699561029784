import React from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import PositionInfoContent from '../../Components/PositionInfoContent/PositionInfoContent';
import styles from '../AddPositionPage.module.css';
import { ReactComponent as BackIcon } from '../../../../images/Position/back-icon.svg';
import isMobile from '../../../../utils/isMobile';

const PositionInfoPage = () => {
  const history = useHistory();
  const isMobileFlag = isMobile();

  const { t } = useTranslation(['coensio-ai'])

  return (
    <div className={styles.positionInfoContainer}>
      <div className="FlexRow gap-4 verticallyCenter">
        {isMobileFlag ? (
          <BackIcon
            onClick={() => {
              history.goBack();
            }}
            className="pointer"
          />
        ) : null}
        <div className="fw-500 font-18 c-darkGray">
          {isMobileFlag ? t('letMagicHappen') : t('aiCreateAssm')}
        </div>
      </div>
      <PositionInfoContent />
    </div>
  );
};

export default PositionInfoPage;
