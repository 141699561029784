import React from 'react';
import Header from '../Components/Header';

const PageContainer = ({
  children,
  isHeaderPresent = true,
  setSpace = () => {},
  isScrollAvailable = true,
}) => {
  return (
    <div
      id="page-container"
      style={{
        overflow: isScrollAvailable ? 'auto' : 'hidden',
        display: 'flex',
        flexDirection: 'column',
        height: '100dvh',
        overflowX: 'hidden'
      }}
    >
      {isHeaderPresent ? <Header setAvailableSpaceForContent={setSpace} /> : null}
      <>{children}</>
    </div>
  );
};

export default PageContainer;
