import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import styles from '../Styles/Banner.module.css';
import CloseButton from './CloseButton';
import { ReactComponent as Arrow } from '../../images/Auth/Payment/banner_icon.svg';
import { currentPlanRedux } from '../../redux/plan/selectors';
import ContactSalesSuccess from '../../Auth/Modals/UnsubscribeSuccessModal';
import { userSelector } from '../../redux/auth/selectors';
import { contactSalesAction } from '../../redux/plan/actions';
import { setPaymentMethodModalShow, setShowPackageDetail } from '../../redux/plan/slice';
import CustomButton from './CustomButton';

const Text = styled.div`
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: ${isMobile ? '16px' : '18px'};
  line-height: 26px;
  display: flex;
  align-items: center;
  margin-left: ${isMobile ? '10px' : '40px'};
  color: #ffffff;
`;

const TextMobile = styled.div`
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #ffffff;
  text-overflow: ellipsis;
`;

function Banner({ setShow }) {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const currentPlan = useSelector(currentPlanRedux);
  const [contactSalesModalShow, setContactSalesModalShow] = useState(false);
  const handleClick = event => {
    sessionStorage.setItem('isBannerClosed', true);
    setShow(false);
  };

  const { t } = useTranslation(['banner'])

  const handleSales = async event => {
    const rawData = {
      userId: user?.userId,
      bundleItemCode: 20
    };
    const resp = await dispatch(contactSalesAction(rawData));
    if (resp.payload) {
      await setContactSalesModalShow(true);
    }
  };

  function handleMobileButtonClick() {
    if (currentPlan?.isCustom) {
      handleSales();
    } else if (currentPlan?.name === 'Free Trial') {
      dispatch(setShowPackageDetail(true));
    } else {
      dispatch(setPaymentMethodModalShow('change'));
    }
  }

  return (
    <>
      <ContactSalesSuccess
        icon="tick"
        show={contactSalesModalShow}
        onHide={() => setContactSalesModalShow(false)}
        title={t('requestReceived')}
        description={t('expertContact')}
      />
      {!isMobile ? (
        <div className={styles.container}>
          {currentPlan?.isCustom ? (
            <Text>
              {t('candSlotFullText')}
            </Text>
          ) : currentPlan?.name === 'Free Trial' ? (
            <Text>{t('subToSeeCandText')}</Text>
          ) : (
            <Text>
              {t('changePayMethodText')}
            </Text>
          )}
          {currentPlan?.isCustom ? (
            <div className={styles.bannerCell}>
              <Arrow />
              <div className={styles.button} onClick={handleSales}>
                {t('contactSales')}
              </div>
              <CloseButton onClick={handleClick} size="small" style={{ marginRight: 8 }} />
            </div>
          ) : currentPlan?.name === 'Free Trial' ? (
            <div className={styles.bannerCell}>
              <Arrow />
              <div className={styles.button} onClick={() => dispatch(setShowPackageDetail(true))}>
                {t('subNow')}
              </div>
              <CloseButton onClick={handleClick} size="small" style={{ marginRight: 8 }} />
            </div>
          ) : (
            <div className={styles.bannerCell}>
              <Arrow />
              <div
                className={styles.button}
                onClick={() => dispatch(setPaymentMethodModalShow(t('change')))}
              >
                {t('changePayMethod')}
              </div>
              <CloseButton onClick={handleClick} size="small" style={{ marginRight: 8 }} />
            </div>
          )}
        </div>
      ) : (
        <div className={styles.mobileContainer}>
          <div className={styles.headerRowMobile}>
            <span className={styles.slotFullText}>{t('candSlotFull')}</span>
            <CloseButton onClick={handleClick} size="small" />
          </div>

          <div style={{ marginBottom: '16px' }}>
            {currentPlan?.isCustom ? (
              <TextMobile>
                {t('contactSalesTeam')}
              </TextMobile>
            ) : currentPlan?.name === 'Free Trial' ? (
              <TextMobile>{t('subToSeeCands')}</TextMobile>
            ) : (
              <TextMobile>{t('changePayMethodToSeeCand')}</TextMobile>
            )}
          </div>

          <CustomButton
            type={3}
            customStyle={{ width: '100%' }}
            textField={
              currentPlan?.isCustom
                ? t('contactSales')
                : currentPlan?.name === 'Free Trial'
                ? t('seePlans')
                : t('changePayMethod')
            }
            size="small"
            buttonOnClick={() => handleMobileButtonClick()}
          />
        </div>
      )}
    </>
  );
}

export default Banner;
