import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  editDraftQuestionService,
  previewDraftAssessmentCheckService,
  publishAssessmentService,
  regenerateAssessmentAvailabilityCheckService,
  regenerateAssessmentService,
  regenerateQuestionAvailabilityCheckService,
  regenerateQuestionsService,
  removeDraftQuestionService
} from './serviceVol2';

export const previewDraftAssessmentCheckAction = createAsyncThunk(
  'assessmentCreation/previewDraftAssessmentCheck',
  async (data, thunkAPI) => {
    try {
      const resp = await previewDraftAssessmentCheckService(data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const editDraftQuestionAction = createAsyncThunk(
  'assessmentCreation/editDraftQuestion',
  async (data, thunkAPI) => {
    try {
      const resp = await editDraftQuestionService(data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const regenerateQuestionsAction = createAsyncThunk(
  'assessmentCreation/regenerateQuestions',
  async (data, thunkAPI) => {
    try {
      const resp = await regenerateQuestionsService(data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const publishAssessmentAction = createAsyncThunk(
  'assessmentCreation/publishAssessment',
  async (data, thunkAPI) => {
    try {
      const resp = await publishAssessmentService(data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const regenerateAssessmentAction = createAsyncThunk(
  'assessmentCreation/regenerateAssessment',
  async (data, thunkAPI) => {
    try {
      const resp = await regenerateAssessmentService(data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const removeDraftQuestionAction = createAsyncThunk(
  'assessmentCreation/removeDraftQuestion',
  async (data, thunkAPI) => {
    try {
      const resp = await removeDraftQuestionService(data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const regenerateAssessmentAvailabilityCheckAction = createAsyncThunk(
  'assessmentCreation/regenerateAssessmentAvailabilityCheck',
  async (data, thunkAPI) => {
    try {
      const resp = await regenerateAssessmentAvailabilityCheckService(data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const regenerateQuestionAvailabilityCheckAction = createAsyncThunk(
  'assessmentCreation/regenerateQuestionAvailabilityCheck',
  async (data, thunkAPI) => {
    try {
      const resp = await regenerateQuestionAvailabilityCheckService(data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
