import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import CloseButton from '../../../Common/Components/CloseButton';
import { userSelector } from '../../../redux/auth/selectors';
import mixpanel from '../../../utils/mixpanel';
import Modal from '../../../Common/CustomModal/Modal';
import constants from '../../../utils/constants';
import { useWindowSize } from '../../../utils/helpers';

export default function CustomAssessmentModal({ show, onHide, nextStage }) {
  const history = useHistory();
  const [screenW] = useWindowSize();
  const isMobileFlag = screenW <= constants.ASSESSMENT_CUSTOMIZATION_THRESHOLD;

  const { t } = useTranslation(['addPosition'])

  // Mixpanel Variable
  const user = useSelector(userSelector);

  const ModalButton = ({
    id,
    color,
    title,
    subtitle,
    userText,
    onClick,
    isnextStage = true,
    customStyle
  }) => (
    <div
      id={id}
      className="FlexColumn pointer gap-8"
      style={{
        borderRadius: '12px',
        height: 'auto',
        // width: '443px',
        padding: '16px 24px',
        background:
          color === 'purple'
            ? 'linear-gradient(277deg, #32CBE0 -0.29%, #7458D3 83.57%, #7458D3 83.57%)'
            : 'var(--light-gray-light-gray-60, rgba(244, 244, 244, 0.60))',
        color: color === 'purple' ? '#ffffff' : '#393939',
        ...customStyle
      }}
      onClick={() => {
        onClick();
        if (isnextStage) {
          nextStage();
        }
      }}
    >
      <div className="FlexRow verticallyCenter horizontallyCenter fw-400 font-16 centerText">
        {title}
      </div>

      <div className="fw-300 font-14 centerText" style={{ opacity: '0.8' }}>
        {subtitle}
      </div>

      <div
        className="FlexRow horizontallyCenter c-white fw-500 font-10 pointer uppercase"
        style={{
          borderRadius: '10px',
          padding: '6px 10px',
          width: 'fit-content',
          margin: '4px auto 0px',
          color: color === 'purple' ? '#7458d3' : '#ffffff',
          background:
            color === 'purple'
              ? '#ffffff'
              : 'linear-gradient(277deg, #32CBE0 -0.29%, #7458D3 83.57%, #7458D3 83.57%)'
        }}
      >
        {userText}
      </div>
    </div>
  );

  return (
    <Modal
      show={show}
      onClose={onHide}
      centerHorizontally
      centerVertically
      outsideClickClose
      contentClassName="FlexColumn gap-8"
      contentStyle={{
        border: 'none',
        padding: '20px 28px 28px',
        borderRadius: '12px',
        maxWidth: '500px',
        width: '100%'
      }}
    >
      <div className="FlexRow fw-500 font-18 c-lightGray">
        {t('createCustomAssessment')}
        <div style={{ marginLeft: 'auto', marginRight: '-10px' }}>
          <CloseButton width={28} height={28} onClick={onHide} />
        </div>
      </div>
      {!isMobileFlag ? (
        <div className="fw-400 font-16 c-lightGray" style={{ marginBottom: '20px' }}>
          {t('selectToCreateAssm')}
        </div>
      ) : <div style={{height: '0px'}} />}

      <ModalButton
        id="AIButton"
        isnextStage={false}
        color="purple"
        title={t('letMagicHappen')}
        subtitle={t('pasteJobDescAndCreate')}
        userText="✨ COENSIO AI ✨"
        onClick={() => {
          history.push('/coensio-ai');
          /* Mixpanel Button Click Event */
          mixpanel.track('Create Custom Assessment Popup Selection', {
            'User Id': user.userId,
            'Company Name': user.companyName,
            'Name Surname': user.userNameSurname,
            'Selected Option': 'Coensio AI'
          });
        }}
      />

      {!isMobileFlag ? (
        <ModalButton
          color="grey"
          title={t('chooseQ')}
          subtitle={t('selectQfrom')}
          userText={t('coensioLib').toLocaleUpperCase('tr')}
          customStyle={{ marginTop: '8px' }}
          onClick={async () => {
            /* Mixpanel Button Click Event */
            mixpanel.track('Create Custom Assessment Popup Selection', {
              'User Id': user.userId,
              'Company Name': user.companyName,
              'Name Surname': user.userNameSurname,
              'Selected Option': 'Hiring Manager'
            });
          }}
        />
      ) : null}
    </Modal>
  );
}
