import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  addCandidatesToFunnelService,
  getAssessmentDetailService,
  getCandidatesByFilterService,
  getFunnelInformationService,
  updateFunnelNameService,
  updateFunnelPriorityService,
  deleteFunnelService,
  addNewFunnelService,
  fetchShortUrlService,
  getWelcomeCandidateLinkService,
  getRejectionReasonsService
} from './serviceVol2';

export const getAssessmentDetailVol2Action = createAsyncThunk(
  'getAssessmentDetailVol2',
  async (data, thunkAPI) => {
    try {
      const resp = await getAssessmentDetailService(data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCandidatesByFilterAction = createAsyncThunk(
  'getCandidatesByFilter',
  async (data, thunkAPI) => {
    try {
      const resp = await getCandidatesByFilterService(data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

/* Same endpoint -> just had to differentiate its reducer behavior */
export const getCandidatesByFilterAfterMoveToFunnelAction = createAsyncThunk(
  'getCandidatesByFilterAfterMove',
  async (data, thunkAPI) => {
    try {
      const resp = await getCandidatesByFilterService(data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getFunnelInformationAction = createAsyncThunk(
  'getFunnelInformation',
  async (data, thunkAPI) => {
    try {
      const resp = await getFunnelInformationService(data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addCandidatesToFunnelAction = createAsyncThunk(
  'addCandidatesToFunnel',
  async (data, thunkAPI) => {
    try {
      const resp = await addCandidatesToFunnelService(data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateFunnelNameAction = createAsyncThunk(
  'updateFunnelName',
  async (data, thunkAPI) => {
    try {
      const resp = await updateFunnelNameService(data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateFunnelPriorityAction = createAsyncThunk(
  'UpdateFunnelPriority',
  async (data, thunkAPI) => {
    try {
      const resp = await updateFunnelPriorityService(data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const deleteFunnelAction = createAsyncThunk('deleteFunnel', async (data, thunkAPI) => {
  try {
    const resp = await deleteFunnelService(data);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const addNewFunnelAction = createAsyncThunk('addNewFunnel', async (data, thunkAPI) => {
  try {
    const resp = await addNewFunnelService(data);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchShortUrlAction = createAsyncThunk('fetchShortUrl', async (data, thunkAPI) => {
  try {
    const resp = await fetchShortUrlService(data);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getWelcomeCandidateLinkAction = createAsyncThunk(
  'getWelcomeCandidateLink',
  async (data, thunkAPI) => {
    try {
      const resp = await getWelcomeCandidateLinkService(data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getRejectionReasonsAction = createAsyncThunk('getRejectionReasons', async (data, thunkAPI) => {
  try {
    const resp = await getRejectionReasonsService(data);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
