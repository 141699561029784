const useHideKeyboard = () => {
  const hideMobileKeyboardOnReturn = element => {
    element.addEventListener('keyup', keyboardEvent => {
      const key = keyboardEvent.code || keyboardEvent.keyCode;
      if (
        key === 'Enter' ||
        key === 13 ||
        key === 9 ||
        key === 261 ||
        key === 87 ||
        key === 66 ||
        key === 160
      ) {
        element.blur();
      }
    });
  };

  const elements = Object.values(document.getElementsByTagName('input'));
  elements.forEach(element => {
    hideMobileKeyboardOnReturn(element);
  });
};

export default useHideKeyboard;
