export const PageNumber = state => state.salesScreen.pageNumber;
export const BillingPeriod = state => state.salesScreen.billingPeriod;
export const AssessmentNumber = state => state.salesScreen.assessmentNumber;
export const CandidateNumber = state => state.salesScreen.candidateNumber;
export const CandidateReport = state => state.salesScreen.candidateReport;
export const PackageName = state => state.salesScreen.packageName;
export const Price = state => state.salesScreen.price;
export const CustomQuestion = state => state.salesScreen.customQuestion;
export const InfoModal = state => state.salesScreen.infoModal;
export const InfoText = state => state.salesScreen.infoText;
export const CSList = state => state.salesScreen.CSList;
export const CompanyList = state => state.salesScreen.CompanyList;
export const SelectedCompany = state => state.salesScreen.selectedCompany;
export const TemplateList = state => state.salesScreen.templateList;
export const CustomBundleId = state => state.salesScreen.customBundleId;
export const createdCompanyId = state => state.salesScreen.createdCompanyId;
export const createTempAndConnectTempActionSuccessCheckRedux = state => state.salesScreen.createTempAndConnectTempActionSuccessCheck;
export const cancelBundleSuccessCheckRedux = state => state.salesScreen.cancelBundleSuccessCheck;
