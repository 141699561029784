import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  addConnectionToAssessmentService,
  getAssessmentInfoService,
  changeAuthorizedUsersOfAssessmentService,
  updateAssessmentInfoService,
} from './serviceVol2';

// NEW API for Assessment Settings Modal

export const addConnectionToAssessmentAction = createAsyncThunk(
  'assessmentDetail/addConnectionToAssessment',
  async (data, thunkAPI) => {
    try {
      return await addConnectionToAssessmentService(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAssessmentInfoAction = createAsyncThunk(
  'assessmentDetail/getAssessmentInfo',
  async (data, thunkAPI) => {
    try {
      return await getAssessmentInfoService(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const changeAuthorizedUsersOfAssessmentAction = createAsyncThunk(
  'assessmentDetail/changeAuthorizedUsersOfAssessment',
  async (data, thunkAPI) => {
    try {
      return await changeAuthorizedUsersOfAssessmentService(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateAssessmentInfoAction = createAsyncThunk(
  'assessmentDetail/updateAssessmentInfo',
  async (data, thunkAPI) => {
    try {
      return await updateAssessmentInfoService(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

