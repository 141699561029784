import { createSlice } from '@reduxjs/toolkit';
import { getUserGroupTypeListAction } from './actions';
import initialState from './initialState';

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserEditedBanner: (state, action) => {
      state.userEditedBanner = action.payload;
    },
    setEditedUserBannerText: (state, action) => {
      state.editedUserBannerText = action.payload;
    },
    setAvailableSpace: (state, action) => {
      state.availableSpace = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(getUserGroupTypeListAction.fulfilled, (state, action) => {
      const temp = [];
      action.payload.forEach(x => {
        temp.push({ id: x.id, title: x.name });
      });
      state.userGroupTypes = temp;
    });
  }
});

export const { setUserEditedBanner, setEditedUserBannerText, setAvailableSpace } =
  slice.actions;
export default slice.reducer;
