import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { locationDataSelector, userSelector } from '../redux/auth/selectors';
import { setLocationData } from '../redux/auth/slice';
import companyList from '../constant/edgeCaseHelper';

export function useIp() {
  const dispatch = useDispatch();
  const locationDataRedux = useSelector(locationDataSelector);
  const [data, setData] = useState(locationDataRedux);
  const user = useSelector(userSelector);

  const getDataFromIp = async () => {
    /* To avoid excess request to ipapi, we store location data in redux 
        Whenever page is refreshed, a new request will be sent
        After each request, even if an error occurred, locationDataRedux will be set,
            that is why we look for locationDataRedux.currency for upcoming api requests
    */

    if (
      user?.companyName &&
      companyList.EXCLUDED_COMPANIES_FOR_CURRENCY.includes(user?.companyName)
    ) {
      setData({ currency: 'TRY' });
    } else if (locationDataRedux) {
      setData(locationDataRedux);
    } else {
      // Connect ipapi.co with fetch()
      // const response = await fetch('https://ipapi.co/json/');
      await new Promise(() => {
        axios
          .get(`https://ipapi.co/json/?key=${process.env.REACT_APP_IPAPI_API_KEY}`, {
            timeout: 3000
          })
          .then(resolve => {
            dispatch(setLocationData(resolve?.data));
            setData(resolve?.data);
          })
          .catch(() => {
            dispatch(setLocationData('error on ip fetching'));
            setData('error on ip fetching');
          });
      });
    }
  };

  // Run `getIP` function above just once when the page is rendered
  useEffect(() => {
    getDataFromIp();
  }, []);

  return [data, data?.currency || 'TRY'];
}
