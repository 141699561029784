import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getCodingLanguagesService,
  getRootAssessmentListServiceVol2,
  createCustomAssessmentService,
  updateCustomAssessmentService,
  removeSkillBasedQuestionService,
  updateQuestionWeightService,
  getQuestionByFilterService,
  addQuestionBasedQuestionService,
  tryAssessmentService,
  getUserListByCompanyService,
  sendEmailForReviewService,
  getQuestionDetailService,
  inviteUserService,
  getAtsListService,
  addAssessmentServiceVol2,
  getAssessmentDetailAfterCreateService,
  applicantAssessmentSendEmailService
} from '../../Assessment/AddAssessment/Api/service';

export const getRootAssessmentListActionVol2 = createAsyncThunk(
  'assessmentsVol2/filteredRootAssessmentList',
  async (data, thunkAPI) => {
    try {
      const response = await getRootAssessmentListServiceVol2(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCodingLanguagesAction = createAsyncThunk(
  'assessmentsVol2/codingLanguageList',
  async (data, thunkAPI) => {
    try {
      const response = await getCodingLanguagesService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createCustomAssessmentAction = createAsyncThunk(
  'assessmentsVol2/createCustomAssessment',
  async (data, thunkAPI) => {
    try {
      const response = await createCustomAssessmentService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateCustomAssessmentAction = createAsyncThunk(
  'assessmentsVol2/updateCustomAssessment',
  async (data, thunkAPI) => {
    try {
      const response = await updateCustomAssessmentService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const removeSkillBasedQuestionAction = createAsyncThunk(
  'assessmentsVol2/removeSkillBasedQuestion',
  async (data, thunkAPI) => {
    try {
      const response = await removeSkillBasedQuestionService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateQuestionWeightAction = createAsyncThunk(
  'assessmentsVol2/updateQuestionWeight',
  async (data, thunkAPI) => {
    try {
      const response = await updateQuestionWeightService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getQuestionByFilterAction = createAsyncThunk(
  'assessmentsVol2/getQuestionByFilter',
  async (data, thunkAPI) => {
    try {
      const response = await getQuestionByFilterService({
        ...data,
        companyId: JSON.parse(localStorage.getItem('user')).companyId
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// addQuestionBasedQuestionService

export const addQuestionBasedQuestionAction = createAsyncThunk(
  'assessmentsVol2/addQuestionBasedQuestion',
  async (data, thunkAPI) => {
    try {
      const response = await addQuestionBasedQuestionService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const tryAssessmentAction = createAsyncThunk(
  'assessmentsVol2/tryAssessment',
  async (data, thunkAPI) => {
    try {
      const response = await tryAssessmentService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getUserListByCompanyAction = createAsyncThunk(
  'assessmentsVol2/getUserListByCompany',
  async (data, thunkAPI) => {
    try {
      const response = await getUserListByCompanyService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const sendEmailForReviewAction = createAsyncThunk(
  'assessmentsVol2/sendEmailForReview',
  async (data, thunkAPI) => {
    try {
      const response = await sendEmailForReviewService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getQuestionDetailAction = createAsyncThunk(
  'assessmentsVol2/getQuestionDetail',
  async (data, thunkAPI) => {
    try {
      const response = await getQuestionDetailService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const inviteUser = createAsyncThunk('assessmentsVol2/inviteUser', async (data, thunkAPI) => {
  try {
    const response = await inviteUserService(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getAtsListAction = createAsyncThunk(
  'assessmentsVol2/getAtsList',
  async (data, thunkAPI) => {
    try {
      const response = await getAtsListService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const addAssessmentActionVol2 = createAsyncThunk(
  'assessmentsVol2/addAssessment',
  async (data, thunkAPI) => {
    try {
      const response = await addAssessmentServiceVol2(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAssessmentDetailAfterCreateAction = createAsyncThunk(
  'assessmentsVol2/getAssessmentDetailAfterCreate',
  async (data, thunkAPI) => {
    try {
      const response = await getAssessmentDetailAfterCreateService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const applicantAssessmentSendEmailAction = createAsyncThunk(
  'assessmentsVol2/applicantSendEmail',
  async (data, thunkAPI) => {
    try {
      const response = await applicantAssessmentSendEmailService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
