import React, { useState, useCallback, useEffect } from 'react';
import OTPVerification from '../Components/OTPVerification/OTPVerification';

const useOTPVerification = isRequired => {
  const [isVerified, setIsVerified] = useState(false);
  const [phone, setPhone] = useState(null);

  useEffect(() => {
    if (isRequired === false) {
      setIsVerified(true);
    }
  }, [isRequired]);

  useEffect(() => {
    setIsVerified(!isRequired);
  }, [isRequired]);

  const OTPHandler = useCallback(() => {
    if (isRequired === true) {
      return <OTPVerification setPhoneNumber={setPhone} setIsVerified={setIsVerified} />;
    }
    return null;
  }, [isVerified]);

  return { isVerified, phone, OTPHandler };
};

export default useOTPVerification;
