import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Checkbox from '../../../../Common/RawCheckbox/Checkbox';
import { setScreeningWorkTypeList } from '../../../../redux/AssessmentSession/slice';
import { useWindowSize } from '../../../../utils/helpers';
import styles from './ScreeningCardComponent.module.css';

const MultiSelectCheckbox = ({ text, isSelected, handleClick }) => {
  const [screenW] = useWindowSize();

  return (
    <div
      className={styles.checkboxWrapper}
      onClick={() => {
        handleClick();
      }}
      role="none"
    >
      <Checkbox isChecked={isSelected} size={screenW < 576 ? 19 : 16} />
      <div className={styles.text}>{text}</div>
    </div>
  );
};

MultiSelectCheckbox.propTypes = {
  text: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired
};

const WorkTypeQuestion = ({ content }) => {
  const { t } = useTranslation(['application']);
  const dispatch = useDispatch();

  const { currentWorkTypeAnswers: currentWorkTypeAnswersList, workTypes: optionList } =
    content || {};
  const [selectedArr, setSelectedArr] = useState(currentWorkTypeAnswersList || []);

  useEffect(() => {
    dispatch(setScreeningWorkTypeList(selectedArr));
  }, [selectedArr]);

  const handleClick = id => {
    if (!selectedArr.includes(id)) {
      const temp = [...selectedArr];
      temp.push(id);
      setSelectedArr(temp);
    } else {
      const temp = [...selectedArr];
      const ind = temp.indexOf(id);
      temp.splice(ind, 1);
      setSelectedArr(temp);
    }
  };

  function localizedTextSelection(id) {
    /*
      Ids:
      10 - Remote
      20 - Office
      30 - Hybrid
    */
    switch (id) {
      case 10:
        return t('workTypeRemote');
      case 20:
        return t('workTypeOffice');
      case 30:
        return t('workTypeHybrid');

      default:
        return 'Unknown type id!'
    }
  }

  return (
    <div className={styles.questionWrapper}>
      <div className={styles.question}>{t('workTypeQuestionText')}</div>
      <div className={styles.multiWrapper}>
        {optionList?.map(e => (
          <MultiSelectCheckbox
            key={e?.id}
            text={localizedTextSelection(e?.id)}
            isSelected={selectedArr.includes(e?.id)}
            handleClick={() => handleClick(e?.id)}
          />
        ))}
      </div>
    </div>
  );
};

WorkTypeQuestion.propTypes = {
  content: PropTypes.object.isRequired
};

export default WorkTypeQuestion;
