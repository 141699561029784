import React from 'react';
import styles from '../Styles/Skeleton.module.css';

export default function Skeleton({ type, commentType = 'left' }) {
  const AssessmentName = () => (
    <div className={styles.wrapper}>
      <div className={styles.text} />
    </div>
  );

  const InfoName = () => (
    <div className={styles.infoWrapper}>
      <div className={styles.text} style={{ width: 279, marginLeft: 23 }} />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className={styles.text} style={{ width: 195, marginRight: 21 }} />
        <div className={styles.text} style={{ width: 125, marginRight: 20 }} />
      </div>
    </div>
  );

  const CandidateName = () => <div className={styles.candidateName} />;

  const CandidateEmail = () => <div className={styles.candidateEmail} />;

  const CandidateCv = () => <div className={styles.CVWrapper} />;

  const ProgressBar = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        width: '100%',
        height: '150px',
        marginTop: '40px',
        marginBottom: '30px'
      }}
    >
      <div className={styles.bigBar}>
        <div className={styles.bigBarCircle} />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          width: '60%'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <div className={styles.progressText} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'flex-end'
            }}
          >
            <div className={styles.progress} />
            <div className={styles.progressSmallText} />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <div className={styles.progressText} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'flex-end'
            }}
          >
            <div className={styles.progress} />
            <div className={styles.progressSmallText} />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <div className={styles.progressText} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'flex-end'
            }}
          >
            <div className={styles.progress} />
            <div className={styles.progressSmallText} />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <div className={styles.progressText} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'flex-end'
            }}
          >
            <div className={styles.progress} />
            <div className={styles.progressSmallText} />
          </div>
        </div>
      </div>
    </div>
  );

  const CandidteQuestion = () => (
    <div className={styles.Question}>
      <div className={styles.QuestionTitle} />
      <div className={styles.QuestionBody}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className={styles.QuestionElement} style={{ width: 40, marginRight: 9 }} />
          <div className={styles.QuestionElement} style={{ width: 80 }} />
        </div>
        <div className={styles.QuestionElement} style={{ width: 80 }} />
      </div>
    </div>
  );

  const ListRadio = () => (
    <div className={styles.radioMainWrapper}>
      <div className={styles.radioWrapper}>
        <div className={styles.radioBodyWrapper}>
          <div className={styles.radioTitle} />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className={styles.radioCircle} />
            <div>
              <div className={styles.radioText} />
              <div className={styles.radioLongText} />
            </div>
          </div>
          <div className={styles.radioBottomInfo} />
        </div>
      </div>
    </div>
  );

  const CommentSkeleton = ({ type = 'left' }) => (
    <div
      className={styles.commentWrapper}
      style={type === 'left' ? { justifyContent: 'flex-start' } : { justifyContent: 'flex-end' }}
    >
      {type === 'left' ? (
        <>
          <div className={styles.icon} />
          <div className={styles.commentBody} />
        </>
      ) : (
        <>
          <div className={styles.commentBody} />
          <div className={`${styles.icon} ${styles.iconLeft}`} />
        </>
      )}
    </div>
  );

  const CurrentUsageSkeleton = () => (
    <div style={{ display: 'flex' }}>
      <div className={styles.circleContainerSkeleton} />
      <div className={styles.contentWrapperSkeleton}>
        <div className={styles.contentSkeleton}>
          <div className={styles.placeholderSkeleton} />
          <div className={styles.placeholderSkeleton} />
        </div>
      </div>
    </div>
  );

  const CandidateListComponentSkeleton = () => (
    <>
      <div className={styles.CandidateListComponentWrapperSkeleton}>
        <div style={{ width: '15px' }} />
        <div className={styles.CandidateListComponentCandidateWrapperSkeleton}>
          <div className={styles.CandidateListComponentLeftPartSkeleton} />
          <div className={styles.CandidateListComponentRightPartSkeleton} />
        </div>
      </div>
    </>
  );

  const FunnelSkeleton = () => (
    <>
      <div className={styles.funnelSkeletonWrapper}>
        <div className={styles.funnelSkeleton} />
        <div className={styles.funnelSkeleton} />
        <div className={styles.funnelSkeleton} />
        <div className={styles.funnelSkeleton} />
      </div>
    </>
  );

  const ProfileSkeleton = () => (
    <>
      <div className={styles.profileSkeleton} />
    </>
  );

  const AssessmentReportSkeleton = () => (
    <>
      <div className={styles.assessmentReportSkeletonWrapper}>
        <div className={styles.assessmentReportQuestionBarSkeleton} />
        <div className={styles.assessmentReportQuestionSkeleton} />
        <div className={styles.assessmentReportQuestionSkeleton} />
      </div>
    </>
  );

  const PositionCard = () => (
    <>
      <div className={styles.positionCardWrapper} />
    </>
  );

  const SalarySkeleton = () => (
    <>
      <div className={styles.salaryQuestionWrapper}>
        <div className={styles.salaryQuestion} />
      </div>
      <div className={styles.salaryQuestionWrapper}>
        <div className={styles.salaryQuestion} />
      </div>
      <div className={styles.salaryQuestionWrapper}>
        <div className={styles.salaryQuestion} />
      </div>
      <div className={styles.salaryQuestionWrapper}>
        <div className={styles.salaryQuestion} />
      </div>
    </>
  );

  const FunnelDropdownSkeleton = () => (
    <div style={{ width: '100%', display: 'flex' }}>
      <div className={styles.funnelDropdown} />
      <div className={styles.funnelDropdownButton} />
    </div>
  );

  // Plan Tab in Settings
  const CurrentUsagePlanSkeleton = () => (
    <>
      <div className={styles.currentUsageInPlanWrapper} />
    </>
  );
  const CurrentPlanSkeleton = () => (
    <>
      <div className={styles.currentUsageInPlanWrapper} />
    </>
  );

  const SettingsGeneralCompanySkeleton = () => (
    <div className={styles.settingsGeneralCompanyWrapper}>
      <div className={styles.settingsGeneralCompanyName} />
      <div className={styles.settingsGeneralCompanyName} />
      <div className={styles.settingsGeneralCompanyLogo} />
    </div>
  );

  const SettingsGeneralInfo = () => (
    <div className={styles.settingsGeneralCompanyWrapper}>
      <div className={styles.settingsGeneralInfo} />
    </div>
  );

  const SettingsProfileSkeleton = () => (
    <>
      <div className={styles.settingsProfile} />
    </>
  );

  const SettingsTeamTabSkeleton = () => (
    <div style={{ gap: '8px', display: 'flex', flexDirection: 'column' }}>
      <div className={styles.settingsTeamTab} />
      <div className={styles.settingsTeamTab} />
      <div className={styles.settingsTeamTab} />
      <div className={styles.settingsTeamTab} />
    </div>
  );

  const PersonalInfoSkeleton = () => <div className={styles.personalInfoWrapper} />;

  if (type === 'info') {
    return <InfoName />;
  }
  if (type === 'radio') {
    return <ListRadio />;
  }
  if (type === 'name') {
    return <CandidateName />;
  }
  if (type === 'email') {
    return <CandidateEmail />;
  }
  if (type === 'cv') {
    return <CandidateCv />;
  }
  if (type === 'question') {
    return <CandidteQuestion />;
  }
  if (type === 'progress') {
    return <ProgressBar />;
  }
  if (type === 'comment') {
    return <CommentSkeleton type={commentType} />;
  }
  if (type === 'currentUsage') {
    return <CurrentUsageSkeleton />;
  }
  if (type === 'candidateListComponent') {
    return <CandidateListComponentSkeleton />;
  }
  if (type === 'funnel') {
    return <FunnelSkeleton />;
  }
  if (type === 'candidate-profile') {
    return <ProfileSkeleton />;
  }
  if (type === 'candidateDetail-assessmentReport') {
    return <AssessmentReportSkeleton />;
  }
  if (type === 'positionCard') {
    return <PositionCard />;
  }
  if (type === 'salary') {
    return <SalarySkeleton />;
  }
  if (type === 'funnelDropdown') {
    return <FunnelDropdownSkeleton />;
  }
  if (type === 'personalInfo') {
    return <PersonalInfoSkeleton />;
  }
  if (type === 'current-usage') {
    return <CurrentUsagePlanSkeleton />;
  }
  if (type === 'current-plan') {
    return <CurrentPlanSkeleton />;
  }
  if (type === 'settingsGeneralCompany') {
    return <SettingsGeneralCompanySkeleton />;
  }
  if (type === 'settingsGeneralInfo') {
    return <SettingsGeneralInfo />;
  }
  if (type === 'settingsProfile') {
    return <SettingsProfileSkeleton />;
  }
  if (type === 'settingsTeamTab') {
    return <SettingsTeamTabSkeleton />;
  }

  return <AssessmentName />;
}
