import { useEffect, useState } from 'react';
import { getJobDescriptionListAction } from '../Api/actions';

export default function useGetCategories(companyId) {
  const [categoryList, setCategoryList] = useState();
  const [categoryLoading, setLoading] = useState(false);

  useEffect(() => {
    async function getJobDescriptionList() {
      setLoading(true);
      const data = {
        CompanyId: companyId
      };
      const resp = await getJobDescriptionListAction(data);

      if (resp?.status === 200) {
        const list = resp?.data?.categoryLabels;
        const temp = [];
        const values = Object.values(list);
        values?.forEach(categoryArray => {
          categoryArray?.forEach(subcategory => {
            if (!temp?.includes(subcategory)) {
              temp.push(subcategory);
            }
          });
        });
        setCategoryList(temp);
      }
      setLoading(false);
    }

    getJobDescriptionList();
  }, [companyId]);

  return { categoryList, categoryLoading };
}
