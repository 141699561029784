import client from '../../constant/indexVol2';
import API from '../../constant/apiVol2';

export const getCandidateAssessmentRecordService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCandidateAssessmentRecord, { params: data }).then(resolve).catch(reject);
  });

export const getConnectedAssessmentCountService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getConnectedAssessmentCount, { params: data }).then(resolve).catch(reject);
  });

  export const confirmDeletingAssessmentRecordService = data =>
  new Promise((resolve, reject) => {
    client.post(API.confirmDeletingAssessmentRecord, data).then(resolve).catch(reject);
  });
  