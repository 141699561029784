import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import styles from '../Styles/StartPage.module.css';
import Checkbox from '../../../Common/Components/Checkbox';
import CustomButton from '../../../Common/Components/CustomButton';
import { ReactComponent as Start } from '../../../images/Candidate/TestPages/startAssessmentIcon.svg';
import { ReactComponent as Coding } from '../../../images/Candidate/TestPages/coding.svg';
import { ReactComponent as FreeText } from '../../../images/Candidate/TestPages/freetext.svg';
import { ReactComponent as MultipleChoice } from '../../../images/Candidate/TestPages/multiple.svg';
import { ReactComponent as Info } from '../../../images/Candidate/TestPages/assessmentInfoIcon.svg';
import Tooltip from '../../../Common/Components/Tooltip';
import {
  AssessmentTitle,
  CandidateEmail,
  CodingCount,
  CompanyName,
  FreeCount,
  LanguageList,
  MultiCount,
  QuestionCount,
  rootAssessmentNameRedux,
  ShowContract,
  TotalTime,
  DueDate,
  isCameraNeededRedux
} from '../../../redux/AssessmentSession/selectors';
import { useWindowSize } from '../../../utils/helpers';
import {
  candidateContractConfirmAction,
  getNextQuestionAction,
  startAssessmentSessionAction
} from '../../../redux/AssessmentSession/actions';

import mixpanel from '../../../utils/mixpanel';
import useHandleCameraPermission from '../Utils/useHandleCameraPermission';
import CamperaPermissionModal from '../Modals/CameraPermissionModal/CameraPermissionModal';
import { setPhone } from '../../../redux/AssessmentSession/slice';

const StartPage = ({ phone }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const assessmentId = new URLSearchParams(search).get('assessment');
  const arriveType = new URLSearchParams(search).get('ArriveType');

  const history = useHistory();
  const [width] = useWindowSize();

  const reduxEmail = useSelector(CandidateEmail);
  const reduxAssessmentTitle = useSelector(AssessmentTitle);
  const reduxCompanyName = useSelector(CompanyName);
  const reduxQuestionCount = useSelector(QuestionCount);
  const reduxTotalTime = useSelector(TotalTime);
  const codingCount = useSelector(CodingCount);
  const freeCount = useSelector(FreeCount);
  const multiCount = useSelector(MultiCount);
  const languageList = useSelector(LanguageList);
  const showContract = useSelector(ShowContract);
  const [contractStatus, setContractStatus] = useState({ privacy: false, posted: false });
  const rootAssessmentName = useSelector(rootAssessmentNameRedux);
  const dueDate = useSelector(DueDate);
  const isCameraNeeded = useSelector(isCameraNeededRedux);

  const [cookies, setCookie] = useCookies(['acListSecret']);
  const isSuspectFlag = !!cookies.acListSecret;
  // const { executeRecaptcha } = useGoogleReCaptcha();
  const [buttonLoading, setButtonLoading] = useState(false);

  function toggleFullScreen() {
    const doc = window.document;
    const docEl = doc.documentElement;

    const requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen ||
      docEl.webkitEnterFullscreen;

    const cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(docEl);
    } else {
      cancelFullScreen.call(doc);
    }
  }

  const getDueDateByString = (dueDate, i18n) => {
    // if dueDate is exist return me string for how much days remaining
    if (dueDate) {
      const options = { day: 'numeric', month: 'long' };
      const locale = i18n.language === 'tr' ? 'tr-TR' : 'en-US';
      const date = new Date(dueDate);
      return date.toLocaleDateString(locale, options);
    }
    return '';
  };

  const onSubmitHandler = async () => {
    setButtonLoading(true);

    // Mixpanel Start Assessment Click Event
    mixpanel.track('Assessment Session Information - Start Assessment Button Click', {
      'Assessment Name': reduxAssessmentTitle,
      'Root Assessment Name': rootAssessmentName,
      'Company Name': reduxCompanyName,
      'Candidate Email': reduxEmail,
      'Contract Flag': showContract,
      'Language List': languageList,
      'Total Question Count': reduxQuestionCount,
      'Multiple Choice Question Count': multiCount,
      'Coding Question Count': codingCount,
      'Free Text Question Count': freeCount,
      Duration: reduxTotalTime
    });

    if (showContract) {
      // Mixpanel Start Assessment Click - Contract Check Click
      mixpanel.track('Assessment Session Information - Start Assessment - Contract Check Click', {
        'Assessment Name': reduxAssessmentTitle,
        'Root Assessment Name': rootAssessmentName,
        'Company Name': reduxCompanyName,
        'Candidate Email': reduxEmail,
        'Contract Flag': showContract,
        'Language List': languageList,
        'Total Question Count': reduxQuestionCount,
        'Multiple Choice Question Count': multiCount,
        'Coding Question Count': codingCount,
        'Free Text Question Count': freeCount,
        Duration: reduxTotalTime
      });
      const contractResp = await dispatch(
        candidateContractConfirmAction({ candidateEmail: reduxEmail })
      );
      if (contractResp?.meta?.requestStatus === 'fulfilled') {
        // Mixpanel Start Assessment Click - Contract Check Success
        mixpanel.track(
          'Assessment Session Information - Start Assessment - Contract Check Success',
          {
            'Assessment Name': reduxAssessmentTitle,
            'Root Assessment Name': rootAssessmentName,
            'Company Name': reduxCompanyName,
            'Candidate Email': reduxEmail,
            'Contract Flag': showContract,
            'Language List': languageList,
            'Total Question Count': reduxQuestionCount,
            'Multiple Choice Question Count': multiCount,
            'Coding Question Count': codingCount,
            'Free Text Question Count': freeCount,
            Duration: reduxTotalTime
          }
        );
      } else {
        // Mixpanel Start Assessment Click - Contract Check Fail
        mixpanel.track('Assessment Session Information - Start Assessment - Contract Check Fail', {
          'Assessment Name': reduxAssessmentTitle,
          'Root Assessment Name': rootAssessmentName,
          'Company Name': reduxCompanyName,
          'Candidate Email': reduxEmail,
          'Contract Flag': showContract,
          'Language List': languageList,
          'Total Question Count': reduxQuestionCount,
          'Multiple Choice Question Count': multiCount,
          'Coding Question Count': codingCount,
          'Free Text Question Count': freeCount,
          Duration: reduxTotalTime,
          'Error message': contractResp?.payload
        });
        // Mixpanel Start Assessment Click Fail Event
        mixpanel.track('Assessment Session Information - Start Assessment Button Fail', {
          'Assessment Name': reduxAssessmentTitle,
          'Root Assessment Name': rootAssessmentName,
          'Company Name': reduxCompanyName,
          'Candidate Email': reduxEmail,
          'Contract Flag': showContract,
          'Language List': languageList,
          'Total Question Count': reduxQuestionCount,
          'Multiple Choice Question Count': multiCount,
          'Coding Question Count': codingCount,
          'Free Text Question Count': freeCount,
          Duration: reduxTotalTime,
          'Error message': 'Error on contract check'
        });
        setButtonLoading(false);
        // alert('Error on contract check')
        return;
      }
    }

    const data = arriveType
      ? {
          email: reduxEmail,
          phone,
          secretKey: cookies.acListSecret ? cookies.acListSecret : '',
          companyAssessmentKey: assessmentId,
          kariyerAtsUtm: localStorage.getItem('kariyernetToken'),
          arriveType
        }
      : {
          email: reduxEmail,
          phone,
          secretKey: cookies.acListSecret ? cookies.acListSecret : '',
          companyAssessmentKey: assessmentId,
          kariyerAtsUtm: localStorage.getItem('kariyernetToken')
        };

    const resp = await dispatch(startAssessmentSessionAction(data));

    if (resp?.meta?.requestStatus === 'fulfilled') {
      dispatch(setPhone(phone));
      const cookieInfo = resp.payload.secretKey;
      if (cookieInfo) {
        setCookie('acListSecret', resp.payload.secretKey, { path: '/' });
      }
      const token = resp.payload.assessmentSessionToken;
      if (token) {
        const data2 = {
          Token: token
        };
        const qResp = await dispatch(getNextQuestionAction(data2));
        if (qResp?.meta?.requestStatus === 'rejected') {
          // Mixpanel Start Assessment Click Fail Event
          mixpanel.track('Assessment Session Information - Start Assessment Button Fail', {
            'Assessment Name': reduxAssessmentTitle,
            'Root Assessment Name': rootAssessmentName,
            'Company Name': reduxCompanyName,
            'Candidate Email': reduxEmail,
            'Contract Flag': showContract,
            'Language List': languageList,
            'Total Question Count': reduxQuestionCount,
            'Multiple Choice Question Count': multiCount,
            'Coding Question Count': codingCount,
            'Free Text Question Count': freeCount,
            Duration: reduxTotalTime,
            'Error message': 'Error on fetching first question'
          });
          setButtonLoading(false);
          return;
        }

        try {
          if (width > 710) {
            toggleFullScreen();
          }
        } catch (error) {
          console.log('Fullscreen error:', error);
        }

        // Mixpanel Start Assessment Click Success Event
        mixpanel.track('Assessment Session Information - Start Assessment Button Success', {
          'Assessment Name': reduxAssessmentTitle,
          'Root Assessment Name': rootAssessmentName,
          'Company Name': reduxCompanyName,
          'Candidate Email': reduxEmail,
          'Contract Flag': showContract,
          'Language List': languageList,
          'Total Question Count': reduxQuestionCount,
          'Multiple Choice Question Count': multiCount,
          'Coding Question Count': codingCount,
          'Free Text Question Count': freeCount,
          Duration: reduxTotalTime,
          'isSuspect Flag': isSuspectFlag
        });

        history.replace(`/assessment-session?assessment=${assessmentId}`);
      } else {
        console.log('This email is already started an assessment session');
        // Mixpanel Start Assessment Click Fail Event
        mixpanel.track('Assessment Session Information - Start Assessment Button Fail', {
          'Assessment Name': reduxAssessmentTitle,
          'Root Assessment Name': rootAssessmentName,
          'Company Name': reduxCompanyName,
          'Candidate Email': reduxEmail,
          'Contract Flag': showContract,
          'Language List': languageList,
          'Total Question Count': reduxQuestionCount,
          'Multiple Choice Question Count': multiCount,
          'Coding Question Count': codingCount,
          'Free Text Question Count': freeCount,
          Duration: reduxTotalTime,
          'Error message': 'This email is already started an assessment session'
        });
        setButtonLoading(false);
        return;
      }

      setButtonLoading(false);
    } else {
      // Mixpanel Start Assessment Click Fail Event
      mixpanel.track('Assessment Session Information - Start Assessment Button Fail', {
        'Assessment Name': reduxAssessmentTitle,
        'Root Assessment Name': rootAssessmentName,
        'Company Name': reduxCompanyName,
        'Candidate Email': reduxEmail,
        'Contract Flag': showContract,
        'Language List': languageList,
        'Total Question Count': reduxQuestionCount,
        'Multiple Choice Question Count': multiCount,
        'Coding Question Count': codingCount,
        'Free Text Question Count': freeCount,
        Duration: reduxTotalTime,
        'Error message': resp?.payload
      });
      setButtonLoading(false);
      // alert('Error on starting assessment session')
      return;
    }

    setButtonLoading(false);
  };

  const [languagesText, setLanguagesText] = useState('');
  const { t, i18n } = useTranslation(['application']);

  useEffect(() => {
    if (languageList) {
      const temp = [];
      languageList?.forEach(e => {
        temp.push(t(e));
      });
      temp.sort();

      let tempStr = '';
      temp.forEach((e, i) => {
        if (i < temp.length - 2) {
          tempStr = tempStr.concat(e);
          tempStr = tempStr.concat(', ');
        } else if (i === temp.length - 2) {
          tempStr = tempStr.concat(e);
          tempStr = tempStr.concat(t('and'));
        } else {
          tempStr = tempStr.concat(e);
        }
      });
      setLanguagesText(tempStr);
    }
  }, [languageList, t]);

  const codingMobileThreshold = 710;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (width < codingMobileThreshold) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  /* ------------------------- Mixpanel related methods -------------------------*/
  useEffect(() => {
    if (rootAssessmentName) {
      mixpanel.track('Assessment Session Information - Pageview', {
        'Assessment Name': reduxAssessmentTitle,
        'Root Assessment Name': rootAssessmentName,
        'Company Name': reduxCompanyName,
        'Candidate Email': reduxEmail,
        'Contract Flag': showContract, // Whether user sees contract options or not
        'Language List': languageList,
        'Total Question Count': reduxQuestionCount,
        'Multiple Choice Question Count': multiCount,
        'Coding Question Count': codingCount,
        'Free Text Question Count': freeCount,
        Duration: reduxTotalTime
      });
    }
  }, [rootAssessmentName]);

  /* ------------------------- Mixpanel related methods -------------------------*/

  function SecToMin(totalTime) {
    if (totalTime) {
      const min = Math.floor(totalTime / 60);
      const sec = totalTime - min * 60;

      return min === 0
        ? sec === 0
          ? `error`
          : `${sec} ${t('sec')}`
        : sec === 0
        ? `${min} ${t('min')}`
        : `${min} ${t('min')} ${sec} ${t('sec')}`;
    }
  }

  /* ------------------------- Camera stuff -------------------------*/
  const { isCameraRequired, isPermissionGranted } = useHandleCameraPermission(isCameraNeeded);
  const [cameraPermissionDisabled, setCameraPermissionDisabled] = useState(false);

  useEffect(() => {
    if (isCameraRequired && isPermissionGranted === false) {
      setCameraPermissionDisabled(true);
    }
  }, [isCameraRequired, isPermissionGranted]);

  return (
    <>
      {cameraPermissionDisabled && <CamperaPermissionModal />}
      <div>
        {width > 576 && <div className={styles.companyName}>{reduxCompanyName}</div>}
        <div className={styles.assessmentTitle}>{reduxAssessmentTitle}</div>
        <InfoCard>
          <div className={styles.questionWrapper}>
            <div className={styles.questionTitle}>
              {reduxCompanyName === 'Brisa' ? t('questionBrisa') : t('question')}
            </div>
            <div className={styles.infoPart}>
              {width > 576 ? (
                <>
                  <div className={styles.infoText}>{reduxQuestionCount}</div>
                  <Tooltip
                    direction="right"
                    style={{ padding: '1rem 2rem' }}
                    content={
                      <ul className={styles.skillList}>
                        <li className={styles.codingElement}>
                          <span className={styles.infoText} style={{ marginBottom: '1.8rem' }}>
                            {`${t('coding')}: ${codingCount}`}
                          </span>
                        </li>
                        <li className={styles.multiElement}>
                          <span className={styles.infoText} style={{ marginBottom: '1.8rem' }}>
                            {`${t('multipleChoice')}: ${multiCount}`}
                          </span>
                        </li>
                        <li className={styles.FreeTextElement}>
                          <span className={styles.infoText}>
                            {`${t('freeText')}: ${freeCount}`}
                          </span>
                        </li>
                      </ul>
                    }
                  >
                    <Info className={styles.infoIcon} />
                  </Tooltip>
                </>
              ) : (
                <div className={styles.questionPart}>
                  <div className={styles.iconWrapper}>
                    <Coding />
                    <div className={styles.questionNumber}>{codingCount}</div>
                  </div>
                  <div className={styles.iconWrapper}>
                    <MultipleChoice />
                    <div className={styles.questionNumber}>{multiCount}</div>
                  </div>
                  <div className={styles.iconWrapper}>
                    <FreeText />
                    <div className={styles.questionNumber}>{freeCount}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {width <= 576 && <div className={styles.divider} />}
          <div className={`${styles.questionWrapper}`}>
            <div className={styles.questionTitle}>{t('duration')}</div>
            <div className={styles.infoText}>{SecToMin(parseInt(reduxTotalTime, 10))}</div>
          </div>

          {dueDate &&
            (reduxCompanyName === 'Brisa' ? null : (
              <>
                <div className={styles.divider} />
                <div
                  style={{ border: 'none', paddingBottom: 0 }}
                  className={`${styles.questionWrapper} ${styles.inner}`}
                >
                  <div className={styles.questionTitle}>
                    {reduxCompanyName === 'Pluxee'
                      ? t('timeToCompleteForPluxee')
                      : t('timeToComplete')}
                  </div>
                  <div className={styles.infoText}>
                    {getDueDateByString(dueDate, i18n)}
                    {/* {reduxCompanyName === 'Turkish Airlines'
                    ? '13.03.2024 23:59'
                    : reduxCompanyName === 'Pluxee'
                    ? '26 Mart'
                    : reduxCompanyName === 'Doğuş Teknoloji Grubu'
                    ? t('7daysForDogus')
                    : assessmentId === 'c234067f-ca17-41e6-82b5-efc93437e9d2'
                    ? '24 Mayıs'
                    : assessmentId === '6674ba68-09ca-40e3-886a-ec09172ba80e'
                    ? '24 Mayıs'
                    : t('7days')} */}
                  </div>
                </div>
              </>
            ))}
        </InfoCard>

        {isMobile && codingCount > 0 && (
          <>
            <div className={`${styles.infoText} ${styles.desc}`}>{t('codingWarning')}</div>
          </>
        )}

        <div className={`${styles.infoTitle}`}>{t('infoTitle')}</div>

        <div className={`${styles.infoText} ${styles.bullet}`}>
          <Trans i18nKey="infoLanguage" values={{ languages: languagesText }}>
            This assessment contains {{ languagesText }} questions.
          </Trans>
        </div>
        {codingCount > 0 && (
          <div className={`${styles.infoText} ${styles.bullet}`}>{t('codingInfo')}</div>
        )}
        <div className={`${styles.infoText} ${styles.bullet}`}>{t('onceInfo')}</div>
        <div className={`${styles.infoText} ${styles.bullet}`}>
          {reduxCompanyName === 'Turkish Airlines' ? t('refreshInfoThy') : t('refreshInfo')}
        </div>
        <div className={`${styles.infoText} ${styles.bullet}`}>{t('dontleave')}</div>

        {isCameraRequired ? (
          <div className={`${styles.infoText} ${styles.bullet}`}>{t('cameraText')}</div>
        ) : null}

        {reduxCompanyName === 'Doğuş Teknoloji Grubu' ? (
          <>
            <div className={`${styles.infoText} ${styles.bullet}`}>{t('dogusBullet1')}</div>
            <div className={`${styles.infoText} ${styles.bullet}`}>{t('dogusBullet2')}</div>
            <div className={`${styles.infoText} ${styles.bullet}`}>{t('dogusBullet3')}</div>
            <div className={`${styles.infoText} ${styles.bullet}`}>{t('dogusBullet4')}</div>
            <div className={`${styles.infoText} ${styles.bullet}`}>{t('dogusBullet5')}</div>
          </>
        ) : null}

        <div className={styles.infoText} style={{ marginTop: '0.6rem', marginBottom: '2.4rem' }}>
          {t('goodLuck')}
        </div>
      </div>
      <div>
        {showContract ? (
          <div className={styles.checkboxGroup}>
            <div
              className={styles.checkBoxItem}
              style={{
                marginBottom: '1.5rem',
                cursor: 'pointer',
                paddingBottom: isMobile ? '1rem' : '0rem'
              }}
              onClick={() => setContractStatus(prev => ({ ...prev, privacy: !prev.privacy }))}
            >
              <Checkbox
                myOnClick={() => setContractStatus(prev => ({ ...prev, privacy: !prev.privacy }))}
                myChecked={contractStatus.privacy}
                style={{ marginBottom: '0rem' }}
              />

              <div
                className={styles.infoText}
                style={{ marginBottom: 0, marginLeft: '1rem', cursor: 'pointer' }}
              >
                <Trans i18nKey="privacyPolicy">
                  I Agree to Coensio&apos;s&nbsp;
                  <a
                    href="https://coens.io/privacy-policy/"
                    target="__BLANK"
                    style={{ color: '#7458D3' }}
                    onClick={() => setContractStatus(prev => ({ ...prev, privacy: !prev.privacy }))}
                  >
                    Privacy Policy
                  </a>
                </Trans>
              </div>
            </div>
            <div
              className={styles.checkBoxItem}
              style={{ marginBottom: '2.4rem', cursor: 'pointer' }}
              onClick={() => setContractStatus(prev => ({ ...prev, posted: !prev.posted }))}
            >
              <Checkbox
                myOnClick={() => setContractStatus(prev => ({ ...prev, posted: !prev.posted }))}
                myChecked={contractStatus.posted}
                style={{ marginBottom: '0rem' }}
              />
              <div
                className={styles.infoText}
                style={{ marginBottom: 0, marginLeft: '1rem', cursor: 'pointer' }}
              >
                {t('allowProcess')}
              </div>
            </div>
          </div>
        ) : null}
        <div style={{ paddingBottom: '30px' }}>
          <CustomButton
            customStyle={{ width: '100%' }}
            iconStyle={{ fill: 'none' }}
            Icon={Start}
            type={1}
            buttonOnClick={async () => {
              onSubmitHandler();
            }}
            isDisabled={showContract && !(contractStatus.privacy && contractStatus.posted)}
            tooltipText="Please accept the privacy policy and allow the process."
            loading={buttonLoading}
            style={{ width: '100%' }}
            textField={t('startAssessment')}
          />
        </div>
      </div>
    </>
  );
};

export default StartPage;

const InfoCard = styled.div`
  width: 100%;
  border: 1px solid rgba(57, 57, 57, 0.15);
  border-radius: 12px;
  margin-bottom: 2.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.8rem;
  // min-height: 151px;
  gap: 14px;
  max-height: 200px;
`;
