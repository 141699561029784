import client from '../../constant/indexVol2';
import API from '../../constant/apiVol2';

export const getAssessmentDetailService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getAssessmentDetail, { params: data }).then(resolve).catch(reject);
  });

export const getCandidatesByFilterService = data =>
  new Promise((resolve, reject) => {
    client.post(API.getCandidatesByFilter, data).then(resolve).catch(reject);
  });

export const getFunnelInformationService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getFunnelInformation, { params: data }).then(resolve).catch(reject);
  });

export const addCandidatesToFunnelService = data =>
  new Promise((resolve, reject) => {
    client.post(API.addUserToFunnel, data).then(resolve).catch(reject);
  });
export const updateFunnelNameService = data =>
  new Promise((resolve, reject) => {
    client.post(API.updateFunnelName, data).then(resolve).catch(reject);
  });
export const updateFunnelPriorityService = data =>
  new Promise((resolve, reject) => {
    client.post(API.updateFunnelPriority, data).then(resolve).catch(reject);
  });

export const deleteFunnelService = data =>
  new Promise((resolve, reject) => {
    client.post(API.deleteFunnel, data).then(resolve).catch(reject);
  });

export const addNewFunnelService = data =>
  new Promise((resolve, reject) => {
    client.post(API.addNewFunnel, data).then(resolve).catch(reject);
  });

export const fetchShortUrlService = data =>
  new Promise((resolve, reject) => {
    client.post(API.fetchShortUrl, data).then(resolve).catch(reject);
  });

export const getWelcomeCandidateLinkService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getWelcomeCandidateLink, { params: data }).then(resolve).catch(reject);
  });

export const getRejectionReasonsService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getRejectionReasons, { params: data }).then(resolve).catch(reject);
  });
