import React from 'react';

const CancelIcon = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect y="0.5" width="24" height="24" rx="8" fill="#F4F4F4" />
      <path
        d="M8.23464 16.2668C8.30801 16.3419 8.39567 16.4016 8.49245 16.4424C8.58923 16.4831 8.69319 16.5041 8.79821 16.5041C8.90322 16.5041 9.00718 16.4831 9.10396 16.4424C9.20075 16.4016 9.2884 16.3419 9.36177 16.2668L12.0003 13.6346L14.6388 16.2668C14.7122 16.3419 14.7998 16.4016 14.8966 16.4424C14.9934 16.4831 15.0974 16.5041 15.2024 16.5041C15.3074 16.5041 15.4114 16.4831 15.5081 16.4424C15.6049 16.4016 15.6926 16.3419 15.7659 16.2668C15.8403 16.1929 15.8993 16.1051 15.9396 16.0084C15.9799 15.9117 16.0006 15.808 16.0006 15.7032C16.0006 15.5984 15.9799 15.4947 15.9396 15.398C15.8993 15.3012 15.8403 15.2134 15.7659 15.1396L13.1338 12.5011L15.7659 9.86259C15.9154 9.71312 15.9994 9.5104 15.9994 9.29902C15.9994 9.08764 15.9154 8.88492 15.7659 8.73545C15.6165 8.58598 15.4138 8.50201 15.2024 8.50201C14.991 8.50201 14.7883 8.58598 14.6388 8.73545L12.0003 11.3676L9.36177 8.73545C9.21231 8.58598 9.00959 8.50201 8.79821 8.50201C8.58683 8.50201 8.38411 8.58598 8.23464 8.73545C8.08517 8.88492 8.0012 9.08764 8.0012 9.29902C8.0012 9.5104 8.08517 9.71312 8.23464 9.86259L10.8668 12.5011L8.23464 15.1396C8.16029 15.2134 8.10127 15.3012 8.061 15.398C8.02073 15.4947 8 15.5984 8 15.7032C8 15.808 8.02073 15.9117 8.061 16.0084C8.10127 16.1051 8.16029 16.1929 8.23464 16.2668Z"
        fill="#393939"
      />
    </svg>
  );
};

export default CancelIcon;
