import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation, Trans } from 'react-i18next';

import { contactSalesAction } from '../../../../redux/plan/actions';
import { setContactSalesModalShow } from '../../../../redux/plan/slice';
import {
  setSettingsPageErrorMessage,
  setSettingsPageErrorShow
} from '../../../../redux/auth/slice';
import { userSelector } from '../../../../redux/auth/selectors';
import { ReactComponent as MasterCard } from '../../../Images/Mastercard.svg';
import WhiteVisa from '../../../../images/Auth/Payment/whiteVisa.svg';
import Amex from '../../../Images/Americanexpress.svg';
import { ReactComponent as Chip } from '../../../Images/chip.svg';
import styles from './BillingTabCard.module.css';

const getCardType = type => {
  switch (type) {
    case 'MASTER_CARD':
      return 'Mastercard';
    case 'Visa':
      return 'Visa';
    case 'AMEX':
      return 'Amex';
    default:
      return '';
  }
};

function BillingTabCard({ enterprise, type = '', expired = '', cardLastFourDigits = '' }) {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  const { t } = useTranslation(['billingTab']);

  return !enterprise ? (
    <div className={styles.cardWrapper}>
      <div>
        <Chip />
      </div>
      <div>
        <div className={styles.cardName}>
          <span>
            <Trans
              i18nKey="lastFourDigits"
              ns="billingTab"
              values={{ lastDigits: cardLastFourDigits }}
            >
              0<span>1</span>
              <span>2</span>
            </Trans>
          </span>
        </div>
      </div>
      <div>
        <div className={styles.expiredRow}>
          {/* <div className={styles.cardExpiration}>{`Expires on ${expired}`}</div> */}
          <span>
            <Trans i18nKey="expiresOn" ns='billingTab' values={{ expired: expired }}>
              <span className={styles.cardExpiration}>1</span>
              <span className={styles.cardExpiration}>2</span>
            </Trans>
          </span>
          {getCardType(type) === 'Mastercard' ? (
            <MasterCard />
          ) : getCardType(type) === 'Visa' ? (
            <span>
              <img alt="" src={WhiteVisa} />
            </span>
          ) : getCardType(type) === 'Amex' ? (
            <span>
              <img alt="" src={Amex} />
            </span>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  ) : (
    <div>
      <div className={styles.enterpriseWrapper}>
        <div className={styles.enterpriseText}>{t('notAvailableEnterprisePlan')}</div>
        <div
          className={styles.contactText}
          onClick={async () => {
            const data = {
              userId: user?.userId
            };
            const a = await dispatch(contactSalesAction(data));
            if (!a?.payload?.message) {
              await dispatch(setContactSalesModalShow(true));
            } else {
              await dispatch(setSettingsPageErrorMessage(a?.payload?.message));
              await dispatch(setSettingsPageErrorShow(true));
            }
          }}
        >
          {t('contactUs')}
        </div>
      </div>
    </div>
  );
}

export default BillingTabCard;
