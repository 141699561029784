import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';
import {
  getCompanyBundleInfoAction,
  getCompanyListAction,
  getCustomerSuccessTeamAction,
  getTemplateInfoAction,
  getTemplatesAction,
  createCompanyAction,
  createTemplateAction,
  connectCompanyWithBundleAction,
  cancelCustomBundleAction
} from './actions';

const salesScreenSlice = createSlice({
  name: 'salesScreen',
  initialState,
  reducers: {
    setInitial: () => initialState,
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload;
    },
    setBillingPeriod: (state, action) => {
      state.billingPeriod = action.payload;
    },
    setAssessmentNumber: (state, action) => {
      state.assessmentNumber = action.payload;
    },
    setCandidateNumber: (state, action) => {
      state.candidateNumber = action.payload;
    },
    setCandidateReport: (state, action) => {
      state.candidateReport = action.payload;
    },
    setPackageName: (state, action) => {
      state.packageName = action.payload;
    },
    setPrice: (state, action) => {
      state.price = action.payload;
    },
    setCustomQuestion: (state, action) => {
      state.customQuestion = action.payload;
    },
    setInfoModal: (state, action) => {
      state.infoModal = action.payload;
    },
    setInfoText: (state, action) => {
      state.infoText = action.payload;
    },
    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload;
    },
    setCustomBundleId: (state, action) => {
      state.customBundleId = action.payload;
    },
    setCreateTempAndConnectTempActionSuccessCheck: (state, action) => {
      state.createTempAndConnectTempActionSuccessCheck = action.payload;
    },
    setCancelBundleSuccessCheck: (state, action) => {
      state.cancelBundleSuccessCheck = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(getCustomerSuccessTeamAction.fulfilled, (state, action) => {
      state.CSList = action.payload;
    });
    builder.addCase(getCompanyListAction.fulfilled, (state, action) => {
      state.CompanyList = action.payload;
    });
    builder.addCase(getTemplatesAction.fulfilled, (state, action) => {
      state.templateList = action.payload;
    });

    builder.addCase(getTemplateInfoAction.fulfilled, (state, action) => {
      state.assessmentNumber = action.payload.templateItem?.filter(
        x => x.name === 'Published Assessments'
      )[0]?.count;
      state.candidateNumber = action.payload.templateItem?.filter(
        x => x.name === 'Candidates'
      )[0]?.count;
      state.candidateReport =
        action.payload.templateItem?.filter(x => x.name === 'Candidate Report')[0]?.count > 0;
      state.price = action.payload.price;
      state.billingPeriod = action.payload.period === 365 ? 'annual' : 'monthly';
    });

    builder.addCase(getCompanyBundleInfoAction.fulfilled, (state, action) => {
      state.customBundleId = action.payload.customBundleId;
      state.price = action.payload.price;
      state.billingPeriod = action.payload.period === 365 ? 'annual' : 'monthly';
      state.assessmentNumber = action.payload.currentTemplateItem?.filter(
        x => x.name === 'Published Assessments' && x.type === 1
      )[0]?.count;
      state.candidateNumber = action.payload.currentTemplateItem?.filter(
        x => x.name === 'Candidates' && x.type === 1
      )[0]?.count;
      state.candidateReport =
        action.payload.currentTemplateItem?.filter(
          x => x.name === 'Candidate Report' && x.type === 1
        )[0]?.count > 0;
    });

    builder.addCase(getCompanyBundleInfoAction.pending, state => {
      state.customBundleId = '';
      state.price = 0;
      state.billingPeriod = '';
      state.assessmentNumber = null;
      state.candidateNumber = null;
      state.candidateReport = false;
      state.customQuestion = false;
    });
    builder.addCase(createCompanyAction.pending, state => {
      state.createdCompanyId = null;
    });
    builder.addCase(createCompanyAction.rejected, state => {
      state.createdCompanyId = null;
    });
    builder.addCase(createCompanyAction.fulfilled, (state, action) => {
      state.createdCompanyId = action.payload.companyId;
    });

    builder.addCase(connectCompanyWithBundleAction.fulfilled, state => {
      state.createTempAndConnectTempActionSuccessCheck = true;
    });
    builder.addCase(connectCompanyWithBundleAction.rejected, state => {
      state.createTempAndConnectTempActionSuccessCheck = false;
    });
    builder.addCase(connectCompanyWithBundleAction.pending, state => {
      state.createTempAndConnectTempActionSuccessCheck = null;
    });

    builder.addCase(createTemplateAction.fulfilled, state => {
      state.createTempAndConnectTempActionSuccessCheck = true;
    });
    builder.addCase(createTemplateAction.rejected, state => {
      state.createTempAndConnectTempActionSuccessCheck = false;
    });
    builder.addCase(createTemplateAction.pending, state => {
      state.createTempAndConnectTempActionSuccessCheck = null;
    });

    builder.addCase(cancelCustomBundleAction.fulfilled, state => {
      state.cancelBundleSuccessCheck = true;
    });
    builder.addCase(cancelCustomBundleAction.rejected, state => {
      state.cancelBundleSuccessCheck = false;
    });
    builder.addCase(cancelCustomBundleAction.pending, state => {
      state.cancelBundleSuccessCheck = null;
    });
  }
});

export const {
  setPageNumber,
  setBillingPeriod,
  setAssessmentNumber,
  setCandidateNumber,
  setCandidateReport,
  setPackageName,
  setPrice,
  setCustomQuestion,
  setInfoModal,
  setInfoText,
  setInitial,
  setSelectedCompany,
  setCustomBundleId,
  setCreateTempAndConnectTempActionSuccessCheck,
  setCancelBundleSuccessCheck
} = salesScreenSlice.actions;

export default salesScreenSlice.reducer;
