import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import { 
  EditorState, 
  convertToRaw, 
  ContentState, 
  // convertFromHTML, 
  // convertFromRaw 
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { multiSelectAnswersRedux } from '../../../redux/addQuestion/selectors';
import { setMultiSelectAnswersRedux } from '../../../redux/addQuestion/slice';

import styles from '../Styles/MultiSelectTextEditor.module.css';
import { imageSaveAction } from '../../../Common/Api/actions';

export default function MultiSelectTextEditor({ index, change, clicked }) {
  const dispatch = useDispatch();
  const reduxMultiSelectAnswers = useSelector(multiSelectAnswersRedux);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  const handleEditorChange = state => {
    setEditorState(state);
    const tempAnswers = [];
    reduxMultiSelectAnswers.forEach((e, i) => {
      if (i === index) {
        tempAnswers.push(draftToHtml(convertToRaw(state.getCurrentContent())));
      } else {
        tempAnswers.push(e);
      }
    });
    dispatch(setMultiSelectAnswersRedux(tempAnswers));
  };

  useEffect(() => {
    const blocksFromHtml = htmlToDraft(reduxMultiSelectAnswers[index]);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    setEditorState(EditorState.createWithContent(contentState));
    // const innerText = convertFromHTML(reduxMultiSelectAnswers[index]);
    // const state = ContentState.createFromBlockArray(innerText.contentBlocks, innerText.entityMap);
    // setEditorState(EditorState.createWithContent(state));
  }, [change]);

  function uploadImage(file) {
    return new Promise((resolve, reject) => {
      const body = new FormData();
      body.append('file', file);
      body.append('directoryName', 'multiselectoptionimages');
      imageSaveAction(body)
        .then(resp => {
          if (resp?.status === 200) {
            const path = `${process.env.REACT_APP_IMAGE_API}${resp?.data?.imageUrl}`;
            resolve({ data: { link: path } });
          }
        })
        .catch(reject);
    });
  }

  return (
    <div
      id="wrapper"
      className={styles.MainWrapper}
      style={index === clicked ? { height: '500px' } : { height: '90%' }}
    >
      <Editor
        toolbarHidden={index !== clicked}
        // toolbarOnFocus
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        toolbar={{
          image: {
            urlEnabled: false,
            uploadEnabled: true,
            previewImage: true,
            uploadCallback: uploadImage,
            alignmentEnabled: false,
            defaultSize: { width: '400px' }
          }
        }}
        toolbarClassName={styles.Toolbar}
        wrapperClassName={styles.Wrapper}
        editorClassName={styles.Editor}
      />
    </div>
  );
}
