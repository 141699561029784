import { createSlice } from '@reduxjs/toolkit';
import {
  createNoteAction,
  deleteNoteAction,
  getActivitiesAction,
  getCandidateBackgroundAction,
  getCandidateCVAction,
  getCandidatePositionsAction,
  getCandidateProfileAction,
  getCandidateSolutionsAction,
  getNotesAction,
  getPersonalInformationAction,
  getPositionsForCurrentFunnelListAction,
  newOpsCandidateCheckAction,
  refreshCandidatePositionsAction,
  updateNoteAction
} from './actions';
import initialState from './initialState';

const candidateDetailSlice = createSlice({
  name: 'candidateDetail',
  initialState,
  reducers: {
    resetToInitial: () => initialState,
    setInfoBannerText: (state, action) => {
      state.infoBannerText = action.payload;
    },
    setInfoBannerShow: (state, action) => {
      state.infoBannerShow = action.payload;
    },
    setShowRejectionPopupFlag: (state, action) => {
      state.showRejectionPopupFlag = action.payload;
    },
    setShowRejectionSuccessFlag: (state, action) => {
      state.showRejectionSuccessFlag = action.payload;
    },
    setIsRejectionMailSent: (state, action) => {
      state.isRejectionMailSent = action.payload;
    },
    setShowChangeFunnelPopup: (state, action) => {
      state.showChangeFunnelPopup = action.payload;
    },
    setCurrentAssessmentInfo: (state, action) => {
      state.currentAssessmentInfo = action.payload;
    },
    setSelectedAssessmentForReport: (state, action) => {
      state.selectedAssessmentForReport = action.payload;
    },
    setIsAutoApplyCandidate: (state, action) => {
      state.isAutoApplyCandidate = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(getCandidateProfileAction.fulfilled, (state, action) => {
      state.candidateProfile = action.payload;
    });

    builder.addCase(getCandidatePositionsAction.fulfilled, (state, action) => {
      state.candidatePositions = action.payload.positions;
      state.defaultAssessmentFunnelList = action.payload.currentAssessmentFunnelList;
      state.currentAssessmentFunnelList = action.payload.currentAssessmentFunnelList;
    });

    builder.addCase(getCandidatePositionsAction.pending, state => {
      state.candidatePositions = [];
      state.defaultAssessmentFunnelList = [];
      state.currentAssessmentFunnelList = [];
    });

    builder.addCase(refreshCandidatePositionsAction.fulfilled, (state, action) => {
      state.candidatePositions = action.payload.positions;
    });
    builder.addCase(newOpsCandidateCheckAction.fulfilled, (state, action) => {
      state.isCandidateAuthorized = action.payload;
    });

    builder.addCase(getNotesAction.fulfilled, (state, action) => {
      state.notes = action.payload;
    });

    builder.addCase(getCandidateSolutionsAction.fulfilled, (state, action) => {
      state.candidateSolutions = action.payload.solutionList;
      state.solutionAuthorize = action.payload.isAuthorize;
      state.priceForUnlock = action.payload.price;
      state.loadingForSolutions = false;
      state.candidateReportPublicLink = action.payload.reportLink;
    });

    builder.addCase(getCandidateSolutionsAction.pending, state => {
      state.candidateSolutions = null;
      state.solutionAuthorize = null;
      state.priceForUnlock = null;
      state.loadingForSolutions = true;
      state.candidateReportPublicLink = null;
    });

    builder.addCase(getCandidateSolutionsAction.rejected, state => {
      state.candidateSolutions = null;
      state.solutionAuthorize = null;
      state.priceForUnlock = null;
      state.loadingForSolutions = false;
      state.candidateReportPublicLink = null;
    });

    builder.addCase(createNoteAction.fulfilled, (state, action) => {
      state.notes = action.payload;
    });
    builder.addCase(updateNoteAction.fulfilled, (state, action) => {
      state.notes = action.payload;
    });
    builder.addCase(deleteNoteAction.fulfilled, (state, action) => {
      state.notes = action.payload;
    });
    builder.addCase(getCandidateCVAction.fulfilled, (state, action) => {
      state.candidateCvPath = action.payload;
    });

    builder.addCase(getCandidateBackgroundAction.fulfilled, (state, action) => {
      state.candidateBackground = action.payload;
    });

    builder.addCase(getPositionsForCurrentFunnelListAction.pending, state => {
      state.currentAssessmentFunnelList = [];
    });
    builder.addCase(getPositionsForCurrentFunnelListAction.fulfilled, (state, action) => {
      state.currentAssessmentFunnelList = action.payload.currentAssessmentFunnelList;
    });

    builder.addCase(getPersonalInformationAction.fulfilled, (state, action) => {
      state.personalInfoExperience = action.payload.experience;
      state.personalInfoNationality = action.payload.nationality;
      state.personalInfoSalary = action.payload.salary;
      state.personalInfoWorkType = action.payload.workType;
      state.loadingForPersonalInfo = false;
    });

    builder.addCase(getPersonalInformationAction.pending, state => {
      state.personalInfoExperience = null;
      state.personalInfoNationality = null;
      state.personalInfoSalary = null;
      state.personalInfoWorkType = null;
      state.loadingForPersonalInfo = true;
    });

    builder.addCase(getPersonalInformationAction.rejected, state => {
      state.personalInfoExperience = null;
      state.personalInfoNationality = null;
      state.personalInfoSalary = null;
      state.personalInfoWorkType = null;
      state.loadingForPersonalInfo = true;
    });

    builder.addCase(getActivitiesAction.fulfilled, (state, action) => {
      state.candidateActivities = action.payload;
    });
  }
});

export const {
  resetToInitial,
  setInfoBannerText,
  setInfoBannerShow,
  setShowRejectionPopupFlag,
  setShowRejectionSuccessFlag,
  setIsRejectionMailSent,
  setShowChangeFunnelPopup,
  setCurrentAssessmentInfo,
  setSelectedAssessmentForReport,
  setIsAutoApplyCandidate
} = candidateDetailSlice.actions;

export default candidateDetailSlice.reducer;
