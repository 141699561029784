export const TYPESCRIPT = 'Typescript';
export const PYTHON = 'Python3';
export const JAVASCRIPT = 'Javascript';
export const CSHARP = 'C#';
export const JAVA = 'Java';
export const KOTLIN = 'Kotlin';
export const SQL = 'SQL';

export const TYPESCRIPT_EXTENSION = 'ts';
export const PYTHON_EXTENSION = 'py';
export const JAVASCRIPT_EXTENSION = 'js';
export const CSHARP_EXTENSION = 'cs';
export const JAVA_EXTENSION = 'java';
export const KOTLIN_EXTENSION = 'kotlin';
export const SQL_EXTENSION = 'sql';

export const dropdownListUpdater = langObj => {
  switch (langObj?.label) {
    case TYPESCRIPT:
      return TYPESCRIPT_EXTENSION;

    case PYTHON:
      return PYTHON_EXTENSION;

    case JAVASCRIPT:
      return JAVASCRIPT_EXTENSION;

    case CSHARP:
      return CSHARP_EXTENSION;

    case JAVA:
      return JAVA_EXTENSION;

    case KOTLIN:
      return KOTLIN_EXTENSION;

    case SQL:
      return SQL_EXTENSION;

    default:
      return langObj?.value;
  }
};
