import React, { useEffect, useState } from 'react';
import { animated, useSpring, config, useSpringRef, useChain } from 'react-spring';
import styles from '../Styles/Checkbox.module.css';

export default function Checkbox({
  labelStyle = {},
  myChecked,
  myDisabled,
  myOnClick,
  indeterminate,
  borderColor
}) {
  const [isChecked, setIsChecked] = useState();
  const checkboxAnimationRef = useSpringRef();
  const checkboxAnimationStyle = useSpring({
    backgroundColor: isChecked ? '#7458D3' : '#fff',
    border: isChecked
      ? '1.5px solid #7458D3'
      : borderColor
      ? `1.5px solid ${borderColor}`
      : '1.5px solid #585858',
    config: config.gentle,
    ref: checkboxAnimationRef,
    borderRadius: labelStyle ? `${labelStyle.borderRadius}` : '5px',
    width: labelStyle ? `${labelStyle.width}` : '',
    height: labelStyle ? `${labelStyle.height}` : '',
    paddingBottom: '3px'
  });
  const [checkmarkLength, setCheckmarkLength] = useState(null);

  const checkmarkAnimationRef = useSpringRef();
  const checkmarkAnimationStyle = useSpring({
    x: isChecked ? 0 : checkmarkLength,
    config: config.gentle,
    ref: checkmarkAnimationRef
  });

  useEffect(async () => {
    setIsChecked(myChecked);
  }, [myChecked]);

  useChain(
    isChecked
      ? [checkboxAnimationRef, checkmarkAnimationRef]
      : [checkmarkAnimationRef, checkboxAnimationRef],
    [0.0, 0.1]
  );

  return (
    <label className={styles.wrapper} style={{...labelStyle ,  cursor: !myDisabled ? 'pointer' : 'initial' }}>
      <input checked={isChecked} disabled={myDisabled} type="checkbox" onClick={myOnClick} />

      <animated.svg
        style={checkboxAnimationStyle}
        className={`${styles.checkbox} ${isChecked ? 'selected' : ''}  ${
          myDisabled && isChecked ? styles.disabled : ''
        } ${isChecked && !myDisabled && styles.allowHover} ${
          !isChecked && !myDisabled && styles.allowEmptyHover
        } ${indeterminate && styles.indeterminate}`}
        aria-hidden="true"
        viewBox="0 0 15 11"
        fill="none"
      >
        {!indeterminate && isChecked && (
          <animated.path
            className={styles.path}
            d="M3 7L5 10L11 3"
            stroke={myDisabled && isChecked ? '#f8f9fa' : 'white'}
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            ref={ref => {
              if (ref) {
                setCheckmarkLength(ref.getTotalLength());
              }
            }}
            strokeDasharray={checkmarkLength}
            strokeDashoffset={checkmarkAnimationStyle.x}
          />
        )}
      </animated.svg>
    </label>
  );
}
