import { createAsyncThunk } from '@reduxjs/toolkit';

import { getLogoByCompanyIdService } from './service';

export const getLogoByCompanyIdAction = createAsyncThunk('getCompanyLogoById', async (data, getThunkAPI) => {
  try {
    const response = await getLogoByCompanyIdService(data);
    return response.data;
  } catch (e) {
    return getThunkAPI.rejectWithValue(e);
  }
});
