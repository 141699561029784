import React, { useEffect, useState } from 'react';

import { useTranslation, Trans } from 'react-i18next';

import Modal from '../../Common/CustomModal/Modal';
import CloseButton from '../../Common/Components/CloseButton';
import TextInput from '../../Common/Components/TextInput';
import CustomButton from '../../Common/Components/CustomButton';
import {
  addCustomFunnelAction,
  deleteCustomFunnelAction,
  renameCustomFunnelAction
} from '../Api/actions';

export default function useCustomFunnelModalHandler(
  activeFunnelInformation,
  setFunnelGroupList,
  funnelGroupList,
  setActiveFunnelInformation
) {
  // 0 -> Closed
  // 1 -> Add Funnel Group
  // 2 -> Rename Funnel Group
  // 3 -> Delete Funnel Group
  const [showModalType, setShowModalType] = useState(0);
  const [funnelGroupName, setFunnelGroupName] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const isConfirmDisabled =
    funnelGroupName?.trim() === '' ||
    funnelGroupList?.some(x => x?.funnelGroupName === funnelGroupName);

  const onClose = () => {
    setShowModalType(0);
  };

  const { t } = useTranslation(['positionTab']);

  useEffect(() => {
    if (activeFunnelInformation) {
      setFunnelGroupName(activeFunnelInformation?.funnelGroupName);
    } else {
      setFunnelGroupName('');
    }
  }, [showModalType]);

  async function addCustomFunnelGroup(funnelGroupName) {
    return addCustomFunnelAction({ name: funnelGroupName });
  }

  async function renameCustomFunnelGroup(funnelGroupName) {
    return renameCustomFunnelAction({
      name: funnelGroupName,
      customFunnelGroupId: activeFunnelInformation?.funnelGroupId
    });
  }

  async function deleteCustomFunnelGroup() {
    return deleteCustomFunnelAction({
      customFunnelGroupId: activeFunnelInformation?.funnelGroupId
    });
  }

  function responseHandler(resp) {
    if (resp?.status === 200) {
      const funnelGroupList = resp?.data;
      setFunnelGroupList(funnelGroupList);
      setActiveFunnelInformation(
        funnelGroupList?.filter(x => x?.funnelGroupName === funnelGroupName)[0]
      );
      setShowModalType(0);
    }
  }

  const addButtonClick = async () => {
    if (!isConfirmDisabled) {
      setButtonLoading(true);
      const resp = await addCustomFunnelGroup(funnelGroupName);
      responseHandler(resp);
      setButtonLoading(false);
    }
  };

  const renameButtonClick = async () => {
    if (!isConfirmDisabled) {
      setButtonLoading(true);
      const resp = await renameCustomFunnelGroup(funnelGroupName);
      responseHandler(resp);
      setButtonLoading(false);
    }
  };

  const deleteButtonClick = async () => {
    setButtonLoading(true);
    const resp = await deleteCustomFunnelGroup();
    responseHandler(resp);
    setButtonLoading(false);
  };

  // Function to handle key down events
  const handleKeyDownAdd = event => {
    if (event.key === 'Enter') {
      addButtonClick();
    } else if (event.key === 'Escape') {
      onClose();
    }
  };

  // Function to handle key down events
  const handleKeyDownRename = event => {
    if (event.key === 'Enter') {
      renameButtonClick();
    } else if (event.key === 'Escape') {
      onClose();
    }
  };

  const AddFunnelGroupModal = (
    <Modal
      centerHorizontally
      centerVertically
      outsideClickClose
      show={showModalType === 1}
      onClose={onClose}
      contentClassName="FlexColumn w-100 gap-24"
      contentStyle={{ padding: '30px', borderRadius: '16px', maxWidth: '600px' }}
    >
      <div className="FlexRow verticallyCenter">
        <span className="fw-500 font-18 c-darkGray">{t('addTitle')}</span>
        <CloseButton width={24} height={24} onClick={onClose} />
      </div>
      <TextInput
        placeholder={t('addTextInputPlaceholder')}
        value={funnelGroupName}
        onChange={e => setFunnelGroupName(e?.target?.value)}
        onKeyDown={handleKeyDownAdd}
        autoFocus
      />
      <div className="FlexRow gap-10" style={{ marginLeft: 'auto' }}>
        <CustomButton size="small" type={2} textField={t('cancel')} buttonOnClick={onClose} />
        <CustomButton
          size="small"
          textField={t('confirm')}
          loading={buttonLoading}
          buttonOnClick={addButtonClick}
          isDisabled={isConfirmDisabled}
        />
      </div>
    </Modal>
  );

  const RenameFunnelGroupModal = (
    <Modal
      centerHorizontally
      centerVertically
      outsideClickClose
      show={showModalType === 2}
      onClose={onClose}
      contentClassName="FlexColumn w-100 gap-24"
      contentStyle={{ padding: '30px', borderRadius: '16px', maxWidth: '600px' }}
    >
      <div className="FlexRow verticallyCenter">
        <span className="fw-500 font-18 c-darkGray">{t('renameTitle')}</span>
        <CloseButton width={24} height={24} onClick={onClose} />
      </div>
      <TextInput
        placeholder={t('addTextInputPlaceholder')}
        value={funnelGroupName}
        onChange={e => setFunnelGroupName(e?.target?.value)}
        onKeyDown={handleKeyDownRename}
        autoFocus
      />
      <div className="FlexRow gap-10" style={{ marginLeft: 'auto' }}>
        <CustomButton size="small" type={2} textField={t('cancel')} buttonOnClick={onClose} />
        <CustomButton
          size="small"
          textField={t('confirm')}
          loading={buttonLoading}
          buttonOnClick={renameButtonClick}
          isDisabled={isConfirmDisabled}
        />
      </div>
    </Modal>
  );

  const DeleteFunnelGroupModal = (
    <Modal
      centerHorizontally
      centerVertically
      outsideClickClose
      show={showModalType === 3}
      onClose={onClose}
      contentClassName="FlexColumn w-100 gap-24"
      contentStyle={{ padding: '30px', borderRadius: '16px', maxWidth: '600px' }}
    >
      <div className="FlexRow verticallyCenter">
        <span className="fw-500 font-18 c-darkGray">
          <Trans
            i18nKey="deleteFunnel"
            ns="positionTab"
            values={{ funnelName: activeFunnelInformation?.funnelGroupName }}
          >
            0<span>1</span>
            <span>2</span>
            <span>3</span>
          </Trans>
        </span>
        <CloseButton width={24} height={24} onClick={onClose} />
      </div>
      <span className="fw-400 font-16 c-darkGray">
        <Trans i18nKey="funnelPermDelete" ns="positionTab">
          0<span>1</span>
          <span>2</span>
        </Trans>
      </span>
      <div className="FlexRow gap-10" style={{ marginLeft: 'auto' }}>
        <CustomButton size="small" type={2} textField={t('cancel')} buttonOnClick={onClose} />
        <CustomButton
          size="small"
          type={6}
          textField={t('delete')}
          loading={buttonLoading}
          buttonOnClick={deleteButtonClick}
        />
      </div>
    </Modal>
  );
  const FunnelGroupModalContent = [
    AddFunnelGroupModal,
    RenameFunnelGroupModal,
    DeleteFunnelGroupModal
  ];

  return { FunnelGroupModalContent, setShowModalType };
}
