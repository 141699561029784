import React, { useEffect, useRef } from 'react';
import styles from '../Styles/ProgressBarWrapper.module.css';
import CandidateProgressBar from '../../Common/Components/CandidateProgressBar';
import AnimatedText from './AnimatedText';
import ShortText from '../../Common/Components/ShortText';

export default function CandidateLabel({ myindex, x, index, color }) {
  const ref = useRef();
  useEffect(() => {
    if (myindex === index) {
      // eslint-disable-next-line no-unused-expressions
      ref.current && ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [index]);

  return (
    <div className={styles.rightRow} ref={ref}>
      <div className={styles.labelText}>
        <ShortText name={x.labelName} type={5} />
      </div>
      <CandidateProgressBar
        bgcolor={color}
        height={12}
        progress={(100 * x.candidatePoint) / x.maxPoint}
      />
      <div className={styles.percantageWrapper}>
        <AnimatedText
          score={
            (100 * x.candidatePoint) / x.maxPoint >= 97
              ? 100
              : (100 * x.candidatePoint) / x.maxPoint
          }
        />
        <div>%</div>
      </div>
    </div>
  );
}
