import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isSafari } from 'react-device-detect';

import { useTranslation } from 'react-i18next';

import Modal from '../../../../Common/CustomModal/Modal';
import CloseButton from '../../../../Common/Components/CloseButton';
import CustomButton from '../../../../Common/Components/CustomButton';
import SearchBar from '../../../../Common/Components/SearchBar';
import Checkbox from '../../../../Common/Components/Checkbox';
import copyLink from '../../../../images/Common/copyLink.svg';
import linkCopiedImg from '../../../../images/Common/linkCopied.svg';
import invited from '../../../../images/Common/invited.svg';
import styles from './ShareProfile.module.css';
import { ReactComponent as Add } from './addUser.svg';
import {
  userList,
  HMUserList,
  invitedHMList,
  inviteUserShow,
  newlyInvitedIDList,
  allUserListSelector,
  invitedAllUserListSelector
} from '../../../../redux/assessmentsVol2/selectors';
import { setInviteUserShow } from '../../../../redux/assessmentsVol2/slice';
import {
  getUserListByCompanyAction,
  sendEmailForReviewAction
} from '../../../../redux/assessmentsVol2/actions';
import { teamRedux, userSelector } from '../../../../redux/auth/selectors';
import { ReactComponent as Success } from '../../../../images/Common/SFR_success.svg';
import InviteUserModal from './InviteUserModal';
import { shareProfileLinkAction } from '../../../../redux/candidateDetail/actions';
import { getTeamAction } from '../../../../redux/auth/actions';
import ShortText from '../../../../Common/Components/ShortText';
import MobileModal from '../../../../Common/Components/MobileModal/MobileModal';
import { getParam, useWindowSize } from '../../../../utils/helpers';
import { ReactComponent as ShareIcon } from './exportLink.svg';
import {
  genericMixpanelCandidateDetailEventGenerator,
  mixpanelReturnCandidateDetailProperties
} from '../helpers';
import {
  candidatePositionsSelector,
  candidateProfileSelector,
  loadingForPersonalInfoSelector,
  personalInfoExperienceSelector,
  personalInfoNationalitySelector,
  personalInfoSalarySelector,
  personalInfoWorkTypeSelector
} from '../../../../redux/candidateDetail/selectors';
import mixpanel from '../../../../utils/mixpanel';
import { fetchShortUrlAction } from '../../../../redux/assessmentDetailVol2/actions';

export default function ShareProfileModal({ show, onCloseModal, ...props }) {
  const [selectedHRlist, setSelectedHRlist] = useState([]);
  const newlyInvitedList = useSelector(newlyInvitedIDList);
  const newUserList = useSelector(userList);
  const inviteShow = useSelector(inviteUserShow);
  const [searchParam, setSearchParam] = useState('');
  const [copySuccess, setCopySuccess] = useState(false);
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const { id } = useParams();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [emailsReady, setEmailsReady] = useState(false);
  const [windowW] = useWindowSize();

  // Team
  const allUsers = useSelector(allUserListSelector);
  const invitedAllUsers = useSelector(invitedAllUserListSelector);

  // Mixpanel Share Profile - Send Button Click
  // #region
  const positionsList = useSelector(candidatePositionsSelector);
  const candidateProfile = useSelector(candidateProfileSelector);
  const loadingForPersonalInfoRedux = useSelector(loadingForPersonalInfoSelector);
  const personalInfoExperienceRedux = useSelector(personalInfoExperienceSelector);
  const personalInfoNationalityRedux = useSelector(personalInfoNationalitySelector);
  const personalInfoSalaryRedux = useSelector(personalInfoSalarySelector);
  const personalInfoWorkTypeRedux = useSelector(personalInfoWorkTypeSelector);

  const { t } = useTranslation(['candidateDetail'])

  function mixpanelSendButtonEvent() {
    const candDetProps = mixpanelReturnCandidateDetailProperties(
      user,
      positionsList,
      candidateProfile,
      loadingForPersonalInfoRedux,
      personalInfoExperienceRedux,
      personalInfoNationalityRedux,
      personalInfoSalaryRedux,
      personalInfoWorkTypeRedux
    );
    let hmCount = 0;
    let recCount = 0;

    selectedHRlist?.forEach(selected => {
      allUsers
        ?.filter(x => x?.email === selected)
        ?.forEach(x => {
          if (x?.userGroupType === 'Recruiter') {
            recCount += 1;
          } else if (x?.userGroupType === 'Hiring Manager') {
            hmCount += 1;
          }
        });
    });

    mixpanel.track('Share Profile - Send Button Click', {
      ...candDetProps,
      'Shared Hiring Manager Count': hmCount,
      'Shared Recruiter Count': recCount
    });
  }
  // #endregion

  // Mixpanel Share Profile - Copy Link Click
  // #region
  function mixpanelCopyLinkEvent() {
    genericMixpanelCandidateDetailEventGenerator(
      'Share Profile - Copy Link Click',
      user,
      positionsList,
      candidateProfile,
      loadingForPersonalInfoRedux,
      personalInfoExperienceRedux,
      personalInfoNationalityRedux,
      personalInfoSalaryRedux,
      personalInfoWorkTypeRedux
    );
  }
  // #endregion

  const [copyModal, setCopyModal] = useState(false);
  const [copyUrl, setCopyUrl] = useState('');

  const CopyModal = (
    <Modal
      size={{ width: '450px' }}
      modalId="safari-share-stage-copy-modal"
      contentStyle={{ padding: '24px', borderRadius: '20px' }}
      show={copyModal}
      onClose={() => setCopyModal(false)}
      centerHorizontally
      centerVertically
      outsideClickClose
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px'
          }}
        >
          <div style={{ fontFamily: 'Jost-400', fontSize: '20px', color: '#585858' }}>
            {t('shareWithColleagues')}
          </div>
          <CloseButton
            size="small"
            onClick={() => {
              setCopyModal(false);
            }}
          />
        </div>
        <div
          style={{
            fontFamily: 'Jost-400',
            fontSize: '16px',
            marginBottom: '50px',
            userSelect: 'initial'
          }}
        >
          {copyUrl}
        </div>
        <CustomButton
          customStyle={{ width: '100%' }}
          textField={t('copyLink')}
          size="medium"
          type={1}
          buttonOnClick={() => {
            navigator.clipboard.writeText(copyUrl);
            mixpanelCopyLinkEvent();
            setCopySuccess(true);
            setCopyModal(false);
          }}
        />
      </div>
    </Modal>
  );

  async function copyToClipBoard() {
    let urlToShort = window.location.href;
    const isCopiedBefore = getParam(urlToShort, 'copied');

    if (!isCopiedBefore) {
      urlToShort = urlToShort.concat('&copied=true');
    }

    await dispatch(fetchShortUrlAction({ url: urlToShort })).then(resp => {
      if (resp?.meta?.requestStatus === 'fulfilled') {
        const shortUrl = resp?.payload?.shortUrl;

        if (!isSafari) {
          navigator.clipboard.writeText(shortUrl);
          mixpanelCopyLinkEvent();
          setCopySuccess(true);
        } else {
          setCopyUrl(shortUrl);
          setCopyModal(true);
        }
      }
    });
  }

  async function handleShareProfile() {
    const link = window.location.href;
    const data = {
      profileLink: link,
      emails: selectedHRlist,
      userId: id,
      companyAssessmentId: getParam(location.search, 'currentAssessmentId')
    };
    mixpanelSendButtonEvent();

    const response = await dispatch(shareProfileLinkAction(data));
    if (response.payload) {
      setSuccess(true);
      window.dispatchEvent(new Event('fetchActivites'));
    }
    setEmailsReady(false);
    setButtonLoading(false);
  }

  useEffect(() => {
    if (user && show) {
      dispatch(getUserListByCompanyAction({ CompanyId: user.companyId }));
    }
    return () => {
      setSelectedHRlist([]);
      setSuccess(false);
    };
  }, [user, show]);

  function handleCheckbox(hr) {
    let temp = [...selectedHRlist];
    if (selectedHRlist.includes(hr)) {
      temp = temp.filter(e => e !== hr);
      setSelectedHRlist(temp);
    } else {
      setSelectedHRlist(prev => [...prev, hr]);
    }
  }

  useEffect(() => {
    setButtonLoading(false);
  }, []);

  async function handleList() {
    setButtonLoading(true);
    if (newlyInvitedList?.length > 0) {
      await newlyInvitedList?.forEach(id => {
        const email = newUserList?.filter(user => user?.id === id)[0]?.email;
        setSelectedHRlist(prev => [...prev, email]);
      });
    }
    await setEmailsReady(true);
  }

  useEffect(() => {
    if (emailsReady) {
      handleShareProfile();
    }
  }, [emailsReady]);

  const WebModal = (
    <Modal
      modalId="share-profile-modal"
      outsideClickClose={!copyModal}
      centerHorizontally
      centerVertically
      show={show}
      onClose={onCloseModal}
      size={{ width: '572px' }}
      contentStyle={{
        background: '#FFFFFF',
        borderRadius: '16px',
        padding: '23px 28px'
      }}
      backdropStyle={{ overflow: 'hidden' }}
    >
      <InviteUserModal show={inviteShow} onHide={() => dispatch(setInviteUserShow(false))} />
      <div className={`${styles.ModalWrapper} ${inviteShow && styles.blurred}`}>
        <div className={styles.header}>
          {!success && <div className={styles.headerTitle}>{t('shareProfile')}</div>}
          <CloseButton onClick={onCloseModal} size="small" />
        </div>

        {success ? (
          <>
            <div className={styles.successContainer}>
              <Success className={styles.successIcon} />
              <div className={styles.successTitle}>{t('successfullySent')}</div>
              <div className={styles.successSubtitle}>
                {t('candProfileSentText')}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.lineWrapper}>
              <SearchBar
                size="line"
                isExtended={1}
                color="gray"
                inputFieldPlaceholder={t('search')}
                inputFieldOnChange={event => {
                  setSearchParam(event.target.value);
                }}
                inputFieldValue={searchParam}
                extendedStyle={{
                  width: '100%',
                  fontSize: '16px',
                  color: 'rgba(57, 57, 57, 0.5)',
                  height: '36px'
                }}
              />
              <CustomButton
                customStyle={{ height: '36px', fontSize: '16px', width: 'max-content' }}
                Icon={Add}
                textField={t('addNewUser')}
                type={5}
                buttonOnClick={() => dispatch(setInviteUserShow(true))}
              />
            </div>

            <div className={styles.hrList}>
              {allUsers?.map(
                (user, key) =>
                  user.nameSurname
                    .toString()
                    .toLowerCase()
                    .includes(searchParam.toLocaleLowerCase()) && (
                    <div className={styles.checkboxRow}>
                      <div className={styles.checkboxWrapper}>
                        <Checkbox
                          myOnClick={() => handleCheckbox(user.email)}
                          myChecked={
                            selectedHRlist.includes(user.email) ||
                            newlyInvitedList.includes(user.id)
                          }
                          myDisabled={newlyInvitedList.includes(user.id)}
                        />
                      </div>
                      <div
                        className={`${styles.hrText} ${
                          newlyInvitedList.includes(user.id) && styles.disabledHR
                        }`}
                        key={key}
                      >
                        <ShortText name={user?.nameSurname} staticLimit={32} />
                      </div>
                      {newlyInvitedList.includes(user.id) && styles.disabledHR && (
                        <div className={styles.invitedWrapper}>
                          <span className={styles.imgWrapper}>
                            <img alt="" src={invited} />
                          </span>
                          <span className={styles.invitedText}>{t('invSent')}</span>
                        </div>
                      )}
                      {JSON.stringify(invitedAllUsers).includes(user.id) &&
                        !newlyInvitedList.includes(user.id) && (
                          <div className={styles.invitedWrapper}>
                            <span className={styles.imgWrapper}>
                              <img alt="" src={invited} />
                            </span>
                            <span className={styles.invitedText}>{t('invited')}</span>
                          </div>
                        )}
                    </div>
                  )
              )}
            </div>
            <div className={styles.linkRow}>
              <div className={styles.copyLink} onClick={() => copyToClipBoard()}>
                <span className={styles.imgWrapper}>
                  <img
                    style={{ height: '100%', width: '100%' }}
                    alt=""
                    src={copySuccess ? linkCopiedImg : copyLink}
                  />
                </span>
                <span>{copySuccess ? t('linkCopied') : t('copyLink')}</span>
              </div>
              <div className={styles.sendButton}>
                <CustomButton
                  textField={t('send')}
                  buttonOnClick={async () => {
                    // eslint-disable-next-line no-restricted-syntax
                    handleList();
                  }}
                  size="small"
                  isDisabled={selectedHRlist.length === 0 && newlyInvitedList.length === 0}
                  loading={buttonLoading}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );

  const MobilModal = (
    <MobileModal
      id="mobile-shareProfile-modal"
      onClose={onCloseModal}
      show={show}
      outsideClickClose={!copyModal}
      contentStyle={{
        padding: '25px',
        height: `413px`,
        minWidth: '375px',
        overflow: 'hidden'
      }}
    >
      <InviteUserModal show={inviteShow} onHide={() => dispatch(setInviteUserShow(false))} />
      <div className={`${styles.ModalWrapper} ${inviteShow && styles.blurred}`}>
        <div className={styles.header}>
          {!success && (
            <div className={styles.headerWrapper}>
              <ShareIcon /> <div className={styles.headerTitle}>{t('shareProfile')}</div>
            </div>
          )}
          <CloseButton onClick={onCloseModal} size="small" />
        </div>

        {success ? (
          <>
            <div className={styles.successContainer}>
              <Success className={styles.successIcon} />
              <div className={styles.successTitle}>{t('successfullySent')}</div>
              <div className={styles.successSubtitle}>
                {t('candProfileSentText')}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.lineWrapper}>
              <SearchBar
                size="line"
                isExtended={1}
                color="white"
                inputFieldPlaceholder={t('search')}
                inputFieldOnChange={event => {
                  setSearchParam(event.target.value);
                }}
                inputFieldValue={searchParam}
                extendedStyle={{
                  width: '100%',
                  fontSize: '16px',
                  border: '1px solid rgba(57, 57, 57, 0.15)',
                  borderRadius: '8px',
                  color: 'rgba(57, 57, 57, 0.5)',
                  height: '36px'
                }}
              />
              <CustomButton
                customStyle={{ height: '36px', fontSize: '16px' }}
                Icon={Add}
                textField={t('add')}
                type={5}
                buttonOnClick={() => dispatch(setInviteUserShow(true))}
              />
            </div>

            <div className={styles.hrList}>
              {allUsers?.map(
                (user, key) =>
                  user.nameSurname
                    .toString()
                    .toLowerCase()
                    .includes(searchParam.toLocaleLowerCase()) && (
                    <div className={styles.checkboxRow}>
                      <div className={styles.checkboxWrapper}>
                        <Checkbox
                          myOnClick={() => handleCheckbox(user.email)}
                          myChecked={
                            selectedHRlist.includes(user.email) ||
                            newlyInvitedList.includes(user.id)
                          }
                          myDisabled={newlyInvitedList.includes(user.id)}
                        />
                      </div>
                      <div
                        className={`${styles.hrText} ${
                          newlyInvitedList.includes(user.id) && styles.disabledHR
                        }`}
                        key={key}
                      >
                        <ShortText
                          name={user?.nameSurname}
                          staticLimit={
                            windowW < 420
                              ? 15
                              : windowW < 440
                              ? 20
                              : windowW < 480
                              ? 27
                              : windowW < 510
                              ? 33
                              : 38
                          }
                        />
                      </div>
                      {newlyInvitedList.includes(user.id) && styles.disabledHR && (
                        <div className={styles.invitedWrapper}>
                          <span className={styles.imgWrapper}>
                            <img alt="" src={invited} />
                          </span>
                          <span className={styles.invitedText}>Invitation sent!</span>
                        </div>
                      )}
                      {JSON.stringify(invitedAllUsers).includes(user.id) &&
                        !newlyInvitedList.includes(user.id) && (
                          <div className={styles.invitedWrapper}>
                            <span className={styles.imgWrapper}>
                              <img alt="" src={invited} />
                            </span>
                            <span className={styles.invitedText}>{t('invited')}</span>
                          </div>
                        )}
                    </div>
                  )
              )}
            </div>
            <div className={styles.linkRow}>
              <CustomButton
                textField={copySuccess ? t('linkCopied') : t('copyLink')}
                type={8}
                buttonOnClick={() => {
                  copyToClipBoard();
                }}
                size="mobile"
              />
              <CustomButton
                textField={t('send')}
                buttonOnClick={async () => {
                  // eslint-disable-next-line no-restricted-syntax
                  handleList();
                }}
                size="mobile"
                isDisabled={selectedHRlist.length === 0 && newlyInvitedList.length === 0}
                loading={buttonLoading}
              />
            </div>
          </>
        )}
      </div>
    </MobileModal>
  );

  return (
    <>
      {windowW > 576 ? (
        <>
          {WebModal}
          {CopyModal}
        </>
      ) : (
        <>
          {MobilModal}
          {CopyModal}
        </>
      )}
    </>
  );
}
