import client from '../../constant/indexVol2';
import API from '../../constant/apiVol2';

export const addUserToCompanyService = data =>
  new Promise((resolve, reject) => {
    client.post(API.addUserToCompany, data).then(resolve).catch(reject);
  })

  export const resendInvitationService = data =>
  new Promise((resolve, reject) => {
    client.post(API.resendInvitation, data).then(resolve).catch(reject);
  })

  export const getUserGroupTypeListService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getUserGroupTypeList, {params: data}).then(resolve).catch(reject);
  });
  
