import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import CustomButton from '../../../../Common/Components/CustomButton';
import { BlurredImageWrapper } from './BlurredImageWrapper';
import { applyUserAutoApplyAction } from '../../Api/actions';
import useMixpanelAutoApplyClick from '../../../../utils/mixpanelHelpers/useMixpanelAutoApplyClick';

export const NotYetAcceptedCase = ({ setStatus, assessmentKey, candidateEmail }) => {
  const { t } = useTranslation(['application']);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [triggerEvent, setTriggerEvent] = useState(false);
  useMixpanelAutoApplyClick(triggerEvent);

  const applyAutoApply = async () => {
    setButtonLoading(true);
    const data = {
      email: candidateEmail,
      companyAssessmentKey: assessmentKey
    };
    applyUserAutoApplyAction(data);
    setTriggerEvent(true);
    setTimeout(() => {
      setButtonLoading(false);
      setStatus(true);
    }, 2000);
  };

  return (
    <>
      <span className="font-16r fw-300 c-darkGray centerText">
        <Trans i18nKey="autoApplyNotYetAcceptedText">
          Many companies looking for a this position, too. Interested? <br />
          You can also apply to
          <strong className="fw-400">current and future job opportunities</strong> automatically! 🚀
        </Trans>
      </span>

      <BlurredImageWrapper />

      <CustomButton
        textField={t('autoApplySaveButton')}
        buttonOnClick={applyAutoApply}
        customStyle={{ width: '100%' }}
        loading={buttonLoading}
      />
    </>
  );
};
