import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import styles from '../Styles/FilterColumn.module.css';
import { ReactComponent as DropDown } from '../../../Candidate/Images/arrow_assessment.svg';
import { filterState } from '../../../redux/assessmentsVol2/selectors';
import { getQuestionByFilterAction } from '../../../redux/assessmentsVol2/actions';
import {
  setFilterState,
  setPageIndex,
  resetCandidateQuestionList,
  setFilterLoading
} from '../../../redux/assessmentsVol2/slice';
import CustomButton from '../../../Common/Components/CustomButton';
import mixpanel from '../../../utils/mixpanel';
import { userSelector } from '../../../redux/auth/selectors';
import Checkbox from '../../../Common/RawCheckbox/Checkbox';
import useGetCategories from '../Utils/useGetCategories';
import MultiSelect from '../../../Common/Components/MultiSelect';

export default function FilterColumn({ setResetScrollPositionFlag, setResetFilterFlag }) {
  const PADDING_LEFT = 20;
  const PADDING_RIGHT = 15;

  const { id } = useParams();

  const [skillsDropdown, setSkillsDropdown] = useState(true);
  const [questionDropDown, setQuestionDropDown] = useState(true);
  const [difficultyDropdown, setDifficultyDropdown] = useState(false);
  const [languageDropdown, setLanguageDropdown] = useState(false);

  const { t } = useTranslation(['questionBasedAssm'])

  const { categoryList, categoryLoading } = useGetCategories(id);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [filterApplied, setFilterApplied] = useState();

  const filter = useSelector(filterState);
  const dispatch = useDispatch();

  // Mixpanel variable
  const user = useSelector(userSelector);

  const resetFilterFunc = async () => {
    dispatch(setFilterLoading(true));
    dispatch(resetCandidateQuestionList());
    setResetFilterFlag(true);
    await dispatch(
      setFilterState({
        ...filter,
        pageSize: 50,
        currentPage: 1,
        rootAssessmentId: id,
        skills: [],
        questionTypeIds: [],
        searchParameter: '',
        difficultyList: [],
        questionLanguages: [],
        purchaseTypes: []
      })
    );
    setQuestionDropDown(true);
    setDifficultyDropdown(false);
    setLanguageDropdown(false);
    setSelectedSubcategories([]);
  };

  const fetchDefaultQuestionList = () => {
    dispatch(setPageIndex(1));
    dispatch(
      getQuestionByFilterAction({
        ...filter,
        pageSize: 50,
        currentPage: 1,
        rootAssessmentId: id,
        skills: [],
        questionTypeIds: [],
        searchParameter: '',
        difficultyList: [],
        questionLanguages: [],
        purchaseTypes: []
      })
    );
  };

  useEffect(() => {
    resetFilterFunc();

    return () => {
      resetFilterFunc();
    };
  }, []);

  async function applyFilter() {
    setResetScrollPositionFlag(true);
    /* Mixpanel Apply Filter Click */
    mixpanel.track('Question Based Assessment - Apply Filter Button Click', {
      'User Id': user.userId,
      'Company Name': user.companyName,
      'Name Surname': user.userNameSurname,
      'Root Assessment Id': id,
      'Root Assessment Name': localStorage.getItem(
        'Mixpanel_AssessmentCustomizationRootAssessmentName'
      ),
      'Difficulty List': filter.difficultyList || [],
      'Question Language List': filter.questionLanguages || [],
      'Question Types List':
        filter.questionTypeIds.length > 0
          ? filter.questionTypeIds.map(e => {
              if (e === 2) {
                return 'Coding';
              }
              if (e === 3) {
                return 'Free Text';
              }
              if (e === 1) {
                return 'Multiple Choice';
              }
              return e;
            })
          : [],
      'Search Parameter': filter.searchParameter || '',
      'Skills List': filter.skills || []
    });
    /* --------------------------- */

    await dispatch(getQuestionByFilterAction({ ...filter, rootAssessmentId: id }));
  }

  useEffect(() => {
    if (
      filter?.searchParameter !== '' ||
      filter?.skills?.length > 0 ||
      filter?.questionLanguages?.length > 0 ||
      // HERE
      filter?.purchaseTypes?.length > 0 ||
      filter?.difficultyList?.length > 0 ||
      filter?.questionTypeIds?.length > 0 ||
      filter?.skills?.length > 0
    ) {
      setFilterApplied(true);
    } else {
      setFilterApplied(false);
    }
  }, [JSON.stringify(filter)]);

  function updateFilterOnSkillChange() {
    const data = {
      ...filter,
      skills: selectedSubcategories
    };

    dispatch(setFilterState(data));
  }

  useEffect(() => {
    updateFilterOnSkillChange();
  }, [selectedSubcategories]);

  return (
    <>
      <div className={`w-100 ${styles.container}`}>
        <div
          className={styles.headerBorder}
          style={{ paddingLeft: PADDING_LEFT, paddingRight: PADDING_RIGHT }}
        >
          <div className={styles.header}>
            <div className={styles.title}>{t('filterBy')}</div>
            <div
              className={styles.reset}
              onClick={async () => {
                await resetFilterFunc();
                /* Mixpanel Reset Filter Click */
                mixpanel.track('Question Based Assessment - Reset Filter Button Click', {
                  'User Id': user.userId,
                  'Company Name': user.companyName,
                  'Name Surname': user.userNameSurname,
                  'Root Assessment Id': id,
                  'Root Assessment Name': localStorage.getItem(
                    'Mixpanel_AssessmentCustomizationRootAssessmentName'
                  )
                });
                /* --------------------------- */
                fetchDefaultQuestionList();
              }}
            >
              {t('resetFilter')}
            </div>
          </div>
        </div>

        <div
          className={styles.skillDropdown}
          style={{ paddingLeft: PADDING_LEFT, paddingRight: PADDING_RIGHT }}
          onClick={() => {
            setSkillsDropdown(!skillsDropdown);
          }}
        >
          <div className={styles.dropDownQuestions}>{t('skills')}</div>
          <DropDown className={`${styles.QuestionIcon} ${skillsDropdown ? styles.extented : ''}`} />
        </div>

        <div
          className="FlexColumn gap-4"
          // className={`FlexColumn gap-4 ${styles.skillContainer}`}
          style={{
            paddingLeft: PADDING_LEFT,
            paddingRight: PADDING_RIGHT,
            marginTop: skillsDropdown ? '10px' : 'unset',
            width: skillsDropdown ? 'unset' : 0,
            height: skillsDropdown ? 'unset' : 0,
            display: !skillsDropdown && 'none'
          }}
        >
          <MultiSelect 
            options={categoryList}
            selectedOptions={selectedSubcategories}
            setSelectedOptions={setSelectedSubcategories}
            placeholder={t('searchSkills')}
            searchCharacterCount={3}
            loadingFlag={categoryLoading}
            listBadgesReverse
            keepSearchTerm
            debounceMs={500}
          />
          
        </div>

        <div
          className={styles.skillDropdown}
          style={{ paddingLeft: PADDING_LEFT, paddingRight: PADDING_RIGHT }}
          onClick={() => {
            setQuestionDropDown(!questionDropDown);
          }}
        >
          <div className={styles.dropDownQuestions}>{t('qType').toLocaleUpperCase('tr')}</div>
          <DropDown
            className={`${styles.QuestionIcon} ${questionDropDown ? styles.extented : ''}`}
          />
        </div>

        <div
          className={`FlexColumn gap-4 ${styles.skillContainer}`}
          style={{
            paddingLeft: PADDING_LEFT,
            paddingRight: PADDING_RIGHT,
            marginTop: questionDropDown ? '10px' : 'unset',
            width: questionDropDown ? 'unset' : 0,
            height: questionDropDown ? 'unset' : 0
          }}
        >
          <QuestionItem item={t('coding')} filter={filter} id={id} />
          <QuestionItem item={t('freeText')} filter={filter} id={id} />
          <QuestionItem item={t('multipleChoice')} filter={filter} id={id} />
        </div>
        <div
          className={styles.skillDropdown}
          style={{ paddingLeft: PADDING_LEFT, paddingRight: PADDING_RIGHT }}
          onClick={() => {
            setDifficultyDropdown(!difficultyDropdown);
          }}
        >
          <div className={styles.dropDownQuestions}>{t('questionDiff')}</div>
          <DropDown
            className={`${styles.QuestionIcon} ${difficultyDropdown ? styles.extented : ''}`}
          />
        </div>
        <div
          className={`FlexColumn gap-4 ${styles.skillContainer}`}
          style={{
            paddingLeft: PADDING_LEFT,
            paddingRight: PADDING_RIGHT,
            marginTop: difficultyDropdown ? '10px' : 'unset',
            width: difficultyDropdown ? 'unset' : 0,
            height: difficultyDropdown ? 'unset' : 0
          }}
        >
          <DifficultyItem filterKey="difficultyList" item={t('easy')} filter={filter} id={id} />
          <DifficultyItem filterKey="difficultyList" item={t('medium')} filter={filter} id={id} />
          <DifficultyItem filterKey="difficultyList" item={t('hard')} filter={filter} id={id} />
        </div>

        <div
          className={styles.skillDropdown}
          style={{ paddingLeft: PADDING_LEFT, paddingRight: PADDING_RIGHT }}
          onClick={() => {
            setLanguageDropdown(!languageDropdown);
          }}
        >
          <div className={styles.dropDownQuestions}>{t('questionLang')}</div>
          <DropDown
            className={`${styles.QuestionIcon} ${languageDropdown ? styles.extented : ''}`}
          />
        </div>
        <div
          className={`FlexColumn gap-4 ${styles.skillContainer}`}
          style={{
            paddingLeft: PADDING_LEFT,
            paddingRight: PADDING_RIGHT,
            marginTop: languageDropdown ? '10px' : 'unset',
            width: languageDropdown ? 'unset' : 0,
            height: languageDropdown ? 'unset' : 0
          }}
        >
          <LanguageItem filterKey="questionLanguages" item="TR" filter={filter} id={id} />
          <LanguageItem filterKey="questionLanguages" item="EN" filter={filter} id={id} />
          <LanguageItem filterKey="questionLanguages" item="DE" filter={filter} id={id} />
          <LanguageItem filterKey="questionLanguages" item="RU" filter={filter} id={id} />
          <LanguageItem filterKey="questionLanguages" item="IT" filter={filter} id={id} />
          <LanguageItem filterKey="questionLanguages" item="AR" filter={filter} id={id} />
        </div>
      </div>
      <div
        className={styles.buttonWrapper}
        style={{
          paddingLeft: PADDING_LEFT,
          paddingRight: PADDING_RIGHT
        }}
      >
        <CustomButton
          customStyle={{ backgroundColor: filterApplied && 'rgba(116, 88, 211, 0.2) ' }}
          textField={t('applyFilter')}
          type={3}
          size="small"
          style={{ fontSize: '16px', width: '100%', pointerEvents: 'auto' }}
          buttonOnClick={() => {
            dispatch(setFilterLoading(true));
            applyFilter();
            dispatch(setPageIndex(1));
          }}
        />
      </div>
    </>
  );
}

const QuestionItem = ({ item, filter, id }) => {
  const { t } = useTranslation(['questionBasedAssm'])

  const [checkBoxState, setCheckBoxState] = React.useState(false);
  const dispatch = useDispatch();
  // 1 multipleChoice
  // 2 Coding
  // 3 FreeText

  useEffect(() => {
    const type = item === t('coding') ? 2 : item === t('freeText') ? 3 : 1;
    if (filter?.questionTypeIds.indexOf(type) === -1) {
      setCheckBoxState(false);
    } else {
      setCheckBoxState(true);
    }
  }, [filter]);

  const onClickBehavior = () => {
    const type = item === t('coding') ? 2 : item === t('freeText') ? 3 : 1;
    let temp = [];
    if (checkBoxState) {
      temp = [...filter.questionTypeIds];
      temp.splice(temp.indexOf(type), 1);
    }
    const data = {
      ...filter,
      currentPage: 1,
      rootAssessmentId: id,
      questionTypeIds: checkBoxState ? temp : [...filter.questionTypeIds, type]
    };

    dispatch(setFilterState(data));
  };

  return (
    <div className={`pointer ${styles.questionRow}`} onClick={onClickBehavior}>
      <Checkbox isChecked={checkBoxState} size={18} />
      <div className={styles.question}>{item}</div>
    </div>
  );
};

const DifficultyItem = ({ item, filter, id }) => {
  const [checkBoxState, setCheckBoxState] = React.useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const type = item;
    if (filter?.difficultyList.indexOf(type) === -1) {
      setCheckBoxState(false);
    } else {
      setCheckBoxState(true);
    }
  }, [filter]);

  const onClickBehavior = () => {
    const type = item;
    let temp = [];
    if (checkBoxState) {
      temp = [...filter.difficultyList];
      temp.splice(temp.indexOf(type), 1);
    }
    const data = {
      ...filter,
      currentPage: 1,
      rootAssessmentId: id,
      difficultyList: checkBoxState ? temp : [...filter.difficultyList, type]
    };

    dispatch(setFilterState(data));
  };

  return (
    <div className={`pointer ${styles.questionRow}`} onClick={onClickBehavior}>
      <Checkbox isChecked={checkBoxState} size={18} />
      <div className={styles.question}>{item}</div>
    </div>
  );
};

const LanguageItem = ({ item, filter, id, filterKey }) => {
  const [checkBoxState, setCheckBoxState] = React.useState(false);
  const dispatch = useDispatch();
  // 1 multipleChoice
  // 2 Coding
  // 3 FreeText

  useEffect(() => {
    if (filter && filter[filterKey]?.indexOf(item === 'EN' ? 'EN' : item) === -1) {
      setCheckBoxState(false);
    } else {
      setCheckBoxState(true);
    }
  }, [filter]);

  const onClickBehavior = () => {
    let temp = [];
    if (checkBoxState) {
      temp = [...filter[filterKey]];
      temp.splice(temp.indexOf(item === 'EN' ? 'EN' : item), 1);
    }
    const data = {
      ...filter,
      currentPage: 1,
      rootAssessmentId: id,
      [filterKey]: checkBoxState ? temp : [...filter[filterKey], item === 'EN' ? 'EN' : item]
    };

    dispatch(setFilterState(data));
  };

  return (
    <div className={`pointer ${styles.questionRow}`} onClick={onClickBehavior}>
      <Checkbox isChecked={checkBoxState} size={18} />
      <div className={styles.question}>{item}</div>
    </div>
  );
};
