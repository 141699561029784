import React, { useState } from 'react';
import MonacoEditor from '@monaco-editor/react';
import Dropdown from 'react-dropdown';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../Styles/Sql.module.css';
import { sqlCreateTableCodeRedux, sqlQueryTypeRedux } from '../../../redux/addQuestion/selectors';
import { setIsPassedVerificationRedux, setSqlCreateTableCode, setSqlQueryTypeRedux, setVerificationCodeResponseRedux } from '../../../redux/addQuestion/slice';

function SqlSignature() {
  const dispatch = useDispatch();

  const reduxSqlQueryType = useSelector(sqlQueryTypeRedux);
  const reduxSqlCreateTableCode = useSelector(sqlCreateTableCodeRedux);

  const [dbStructure, setDbStructure] = useState(['SELECT', 'INSERT', 'UPDATE', 'DELETE']);

  const initialCode = '--Please use ; after each sql command!\n\n--your SQL script goes below this line';


  const onChangeResetVerificationCode = () => {
    dispatch(setIsPassedVerificationRedux(false));
    dispatch(setVerificationCodeResponseRedux(''));
  };

  return (
    <div className={styles.SignatureWrapper}>
      <div className={styles.InitialDatabaseWrapper}>
        <div className={styles.InitialDatabaseDropdownWrapper}>
          Database Structure (Type of query):
          <Dropdown
            className={styles.InitialDatabaseDropdown}
            options={dbStructure}
            onChange={val => {
              dispatch(setSqlQueryTypeRedux(val.value));
              onChangeResetVerificationCode();
            }}
            value={reduxSqlQueryType}
            placeholder="Select a query type"
          />
        </div>
        Enter your query and its returns to create initial database
        <div className={styles.HorizontalDivider} />
        Script Initial Database:
        <div className={styles.InitialDatabaseEditorWrapper}>
          <MonacoEditor
            defaultLanguage="sql"
            theme="vs-dark"
            options={{ wordWrap: 'on', minimap: { enabled: false }, readOnly: false }}
            value={reduxSqlCreateTableCode}
            onChange={val => {
              dispatch(setSqlCreateTableCode(val));
              onChangeResetVerificationCode();
            }}
          />
        </div>
      </div>
      <div className={styles.InitialCodeWrapper}>
        Initial Code
        <MonacoEditor
          defaultLanguage="sql"
          defaultValue={initialCode}
          theme="vs-dark"
          options={{ wordWrap: 'on', minimap: { enabled: false }, readOnly: true }}
        />
      </div>
    </div>
  );
}
export default SqlSignature;
