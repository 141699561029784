import React from 'react';

const DisabledFunnelWrapper = ({ funnelList, borderBottomFlag, borderTopFlag }) => {
  return (
    <div
      className="FlexColumn gap-10 w-100"
      style={{
        paddingBottom: borderBottomFlag ? '10px' : 'unset',
        borderBottom: borderBottomFlag
          ? '1px solid var(--dark-gray-01, rgba(57, 57, 57, 0.1))'
          : 'unset',
        paddingTop: borderTopFlag ? '10px' : 'unset',
        borderTop: borderTopFlag ? '1px solid var(--dark-gray-01, rgba(57, 57, 57, 0.1))' : 'unset'
      }}
    >
      {funnelList?.map(x => (
        <DisabledFunnelItem funnelName={x?.customFunnelName} />
      ))}
    </div>
  );
};

export default DisabledFunnelWrapper;

const DisabledFunnelItem = ({ funnelName }) => {
  return (
    <div
      className="fw-400 font-16 c-darkGray-03 noSelect"
      style={{
        padding: '8px 12px',
        borderRadius: '8px',
        background: 'var(--gray-1, rgba(244, 244, 244, 1))',
        border: '1px solid var(--gray-1, rgba(244, 244, 244, 1))'
      }}
    >
      {funnelName}
    </div>
  );
};
