import { useEffect } from "react";

const useInsideClick = (ref, callback) => {

    useEffect(() => {
        const handleInsideClick = event => {
            if (ref.current && ref.current.contains(event.target)) {
                callback();
            }
        };

        document.addEventListener('mousedown', handleInsideClick);

        return () => {
            document.removeEventListener('mousedown', handleInsideClick);
        };
    }, [ref, callback]);

};

export default useInsideClick;