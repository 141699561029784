import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useTranslation, Trans } from 'react-i18next';

import styles from '../Styles/QuestionWeightPanel.module.css';
import { ReactComponent as Info } from '../../../images/AddAssessment/AssessmentCustomization/weight_info.svg';
import { ReactComponent as NoQuestion } from '../Images/no-question-image.svg';
import AssessmentWeightSelectorItem from './AssessmentWeightSelectorItem';
import CustomButton from '../../../Common/Components/CustomButton';
import Tooltip from '../../../Common/Components/Tooltip';
import {
  customAssessmentId,
  rootAssessmentQuestionList
} from '../../../redux/assessmentsVol2/selectors';
import { updateCustomAssessmentAction } from '../../../redux/assessmentsVol2/actions';
import {
  setAssessmentActiveTab,
  setPublishedAssessment
} from '../../../redux/assessmentsVol2/slice';
import SkeletonWrapper from '../../../Common/Components/SkeletonWrapper';

export default function QuestionWeightPanel() {
  const questions = useSelector(rootAssessmentQuestionList);
  const dispatch = useDispatch();
  const history = useHistory();
  const assessmentId = useSelector(customAssessmentId);
  const [loading, setLoading] = useState(false);
  const isQuestionListEmpty = questions && questions.length === 0;
  const [totalTime, setTotalTime] = useState();

  const { t } = useTranslation(['assmCustomization']);
  const LOADING = !questions;

  const calculateMin = questionList => {
    let timeInSeconds = 0;

    questionList?.forEach(item => {
      timeInSeconds += parseInt(item.questionDuration, 10);
    });

    const time = {
      min: Math.floor(timeInSeconds / 60),
      sec: timeInSeconds - Math.floor(timeInSeconds / 60) * 60
    };
    if (time.sec === 0) {
      // return `${time.min} min`;
      return (
        <Trans i18nKey="timeMin" ns="assmCustomization" values={{ min: time.min }}>
          0<span>1</span>
          <span>2</span>
        </Trans>
      );
    }
    if (time.min === 0) {
      // return `${time.sec} sec`;
      return (
        <Trans i18nKey="timeSec" ns="assmCustomization" values={{ sec: time.sec }}>
          0<span>1</span>
          <span>2</span>
        </Trans>
      );
    }
    // return `${time.min} min ${time.sec} sec`;
    return (
      <Trans i18nKey="timeMinSec" ns="assmCustomization" values={{ min: time.min, sec: time.sec }}>
        0<span>1</span>
        <span>2</span>
        <span>3</span>
        <span>4</span>
      </Trans>
    );
  };

  useEffect(() => {
    setTotalTime(calculateMin(questions));
  }, [questions]);

  const publishOnClick = async () => {
    setLoading(true);
    const data = {
      rootAssessmentId: assessmentId,
      objStatus: 1
    };
    dispatch(setPublishedAssessment(true)); // Removes lock on page navigation
    await dispatch(updateCustomAssessmentAction(data));
    dispatch(setAssessmentActiveTab(1)); // On add assessment page - switch to Custom lib

    // Handles navigation after lock is removed
    if (history.location.key) {
      history.goBack();
    } else {
      history.replace('/add-assessment');
    }
    setLoading(false);
  };

  const clickPreviewAssessment = async () => {
    window.open(`/try-assessment/${assessmentId}`, '_blank');
  };

  const [buttonWrapperHeight, setButtonWrapperHeight] = useState();
  const [usedAssessDetailHeight, setUsedAssessDetailHeight] = useState();

  useEffect(() => {
    setTimeout(() => {
      const buttonWrapper = document
        .getElementById('weightPanel-buttonWrapper')
        ?.getBoundingClientRect();
      const innerButtonWrapperHeight = buttonWrapper?.height;
      setButtonWrapperHeight(innerButtonWrapperHeight);

      const assessDetailWrapper = document
        .getElementById('weightPanel-assessDetailWrapper')
        ?.getBoundingClientRect();
      const assessDetailWrapperHeight = assessDetailWrapper?.height;
      setUsedAssessDetailHeight(assessDetailWrapperHeight);
    }, 10);
  }, []);

  return (
    <>
      <div
        className="FlexRow verticallyCenter spaceBetween"
        style={{ padding: '16px 20px', gap: '20px' }}
        id="weightPanel-buttonWrapper"
      >
        <SkeletonWrapper loadingCondition={LOADING} className="w-100" style={{ height: '36px' }}>
          <CustomButton
            type={5}
            size="small"
            textField={t('previewAssmButton')}
            style={{ width: '100%' }}
            isDisabled={questions?.length === 0}
            tooltipText={t('noQuestionTooltip')}
            buttonOnClick={clickPreviewAssessment}
          />
          <CustomButton
            type={1}
            loading={loading}
            size="small"
            isDisabled={isQuestionListEmpty}
            buttonOnClick={publishOnClick}
            textField={t('saveButton')}
            style={{ width: '100%' }}
          />
        </SkeletonWrapper>
      </div>
      <div
        className={styles.weightWrapper}
        style={{ height: `calc(100% - ${buttonWrapperHeight}px)` }}
      >
        <div
          id="weightPanel-assessDetailWrapper"
          className="w-100"
          style={{
            padding: '0px 20px'
          }}
        >
          <div className={`${styles.weightText}`} style={{ padding: '12px 0px 10px' }}>
            {t('assmDetails').toLocaleUpperCase('tr')}
          </div>
          <div className={styles.detailsBar}>
            <SkeletonWrapper loadingCondition={LOADING}>
              <div className={styles.detailsColumn}>
                <div className={styles.questionInfo}>{t('questionCount')}</div>
                <div className={styles.questionNumber}>{questions && questions.length}</div>
              </div>
              <div className={styles.detailsColumn}>
                <div className={styles.questionInfo}>{t('duration')}</div>
                <div className={styles.questionNumber}>{totalTime}</div>
              </div>
            </SkeletonWrapper>
          </div>
          <div className={styles.questionDetailsRow}>
            <div className={styles.weightText}>{t('questions')}</div>
            <div className={styles.detailsRowRight}>
              <div className={styles.weightInfo} style={{ marginRight: '8px' }}>
                <Tooltip direction="left" content={t('scoresCalcTooltip')}>
                  <Info className={styles.infoIcon} />
                </Tooltip>
                <div className={styles.weightText}>{t('weight')}</div>
              </div>
              <div className={styles.weightInfo}>
                <Tooltip direction="top" content={t('totalPointTooltip')}>
                  <Info className={styles.infoIcon} />
                </Tooltip>
                <div className={styles.weightText}>{t('points')}</div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={styles.questionListWrapper}
          style={{ height: `calc(100% - ${usedAssessDetailHeight}px)` }}
        >
          <SkeletonWrapper loadingCondition={LOADING} style={{ height: '200px', margin: '20px' }}>
            {isQuestionListEmpty ? (
              <div className={styles.noQImage}>
                <NoQuestion />
                <div className={styles.noQuestionText}>{t('noQuestion')}</div>

                <div className={styles.noQDetailText}>
                  <Trans i18nKey="selectQCreateAssm" ns="assmCustomization">
                    <span>0</span>
                    <strong className="fw-400">1</strong>
                    <span>2</span>
                    <span>3</span>
                  </Trans>
                </div>
              </div>
            ) : (
              questions?.map((question, index) => (
                <AssessmentWeightSelectorItem
                  index={index}
                  item={question}
                  key={`${question}`}
                  id={assessmentId}
                  weightFlag
                />
              ))
            )}
          </SkeletonWrapper>
        </div>
      </div>
    </>
  );
}
