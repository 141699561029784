import React from 'react';
import PositionInfoPage from './PositionInfo/PositionInfoPage';
import PageContainer from '../../../Common/Pages/PageContainer';

const AddPositionPage = () => {
  return (
    <PageContainer>
      <PositionInfoPage />
    </PageContainer>
  );
};

export default AddPositionPage;
