import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import styles from './PersonalInformation.module.css';
import experienceIcon from './experienceIcon.svg';
import salaryIcon from './salaryIcon.svg';
import workTypeIcon from './workTypeIcon.svg';
import workPermitIcon from './workPermitIcon.svg';
import {
  loadingForPersonalInfoSelector,
  personalInfoExperienceSelector,
  personalInfoNationalitySelector,
  personalInfoSalarySelector,
  personalInfoWorkTypeSelector
} from '../../../../redux/candidateDetail/selectors';
import { useIp } from '../../../../hooks/useIp';
import Skeleton from '../../../../Common/Components/Skeleton';

const PersonalInformation = () => {
  const loadingForPersonalInfoRedux = useSelector(loadingForPersonalInfoSelector);
  const personalInfoExperienceRedux = useSelector(personalInfoExperienceSelector);
  const personalInfoNationalityRedux = useSelector(personalInfoNationalitySelector);
  const personalInfoSalaryRedux = useSelector(personalInfoSalarySelector);
  const personalInfoWorkTypeRedux = useSelector(personalInfoWorkTypeSelector);

  const [locationInfo, currency] = useIp();

  const [expText, setExpText] = useState();
  const [workTypeText, setWorkTypeText] = useState();
  const [workPermitText, setWorkPermitText] = useState();
  const [currencyText, setCurrencyText] = useState();

  const { t } = useTranslation(['candidateDetail'])

  function removeComma(num) {
    if (num && num !== '') {
      let a;
      a = num.toString().replace(/,/g, ''); // 1125, but a string, so convert it to number
      a = parseInt(a, 10);
      return a;
    }
    return null;
  }

  function commafy(num) {
    if (num) {
      const innerNum = removeComma(num);
      return innerNum.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    return '';
  }

  useEffect(() => {
    if (!loadingForPersonalInfoRedux && locationInfo) {
      if (personalInfoExperienceRedux && !personalInfoExperienceRedux?.isLocked) {
        // Experience enum
        // [Display(Name = "0 - 1")]
        // ZeroToOne = 10,
        // [Display(Name = "1 - 3")]
        // OneToFive = 20,
        // [Display(Name = "3 - 5")]
        // FiveToTen = 30,
        // [Display(Name = "5+")]
        // TenPlus = 40
        switch (personalInfoExperienceRedux.experiencePeriod) {
          case 10:
            setExpText(t('zeroToOne'));
            break;

          case 20:
            setExpText(t('oneToThree'));
            break;

          case 30:
            setExpText(t('threeToFive'));
            break;

          case 40:
            setExpText(t('fivePlus'));
            break;

          default:
            setExpText('-');
            break;
        }
      } else {
        setExpText('-');
      }

      if (
        personalInfoWorkTypeRedux &&
        !personalInfoWorkTypeRedux?.isLocked &&
        personalInfoWorkTypeRedux?.workTypes?.length >= 1
      ) {
        let temp = personalInfoWorkTypeRedux?.workTypes.join(', ');
        // Work type enum
        // [Display(Name = "Remote")]
        // Remote = 10,
        // [Display(Name = "Office")]
        // Hybrid = 20,
        // [Display(Name = "Hybrid")]
        // Office = 30
        temp = temp.replaceAll('10', t('remote'));
        temp = temp.replaceAll('20', t('office'));
        temp = temp.replaceAll('30', t('hybrid'));
        setWorkTypeText(temp);
      } else {
        setWorkTypeText('-');
      }

      if (
        personalInfoNationalityRedux &&
        !personalInfoNationalityRedux?.isLocked &&
        personalInfoNationalityRedux?.nationalities?.length >= 1
      ) {
        setWorkPermitText(personalInfoNationalityRedux?.nationalities?.join(', '));
      } else {
        setWorkPermitText('-');
      }

      if (
        personalInfoSalaryRedux &&
        !personalInfoSalaryRedux?.isLocked &&
        personalInfoSalaryRedux?.currencies
      ) {
        let temp = '';

        let isValidCurrency = true;
        // const del = 'IDR'
        // switch (del) {
        switch (currency) {
          case 'TRY':
            temp = '₺';
            break;

          case 'USD':
            temp = '$';
            break;

          case 'EUR':
            temp = '€';
            break;

          case 'GBP':
            temp = '£';
            break;

          default:
            temp = '$';
            isValidCurrency = false;
            break;
        }
        temp = temp.concat(commafy(parseInt(personalInfoSalaryRedux?.currencies[isValidCurrency ? currency : 'USD'], 10)));
        setCurrencyText(temp);
      } else {
        setCurrencyText('-');
      }
    }
  }, [loadingForPersonalInfoRedux, locationInfo]);

  return (
    <div className={styles.personalInfoWrapper}>
      <text className={styles.personalInfoHeader}>{t('personalInfo')}</text>
      {!locationInfo && loadingForPersonalInfoRedux ? (
        <Skeleton type="personalInfo" />
      ) : !personalInfoExperienceRedux &&
        !personalInfoNationalityRedux &&
        !personalInfoSalaryRedux &&
        !personalInfoWorkTypeRedux ? (
        <div style={{ textAlign: 'center', fontSize: '15px', color: '#393939'}}>{t('noInfoAboutCand')}</div>
      ) : (
        <>
          <div className={styles.personalInfoDoubleWrapper}>
            <SingleInfo imgSrc={experienceIcon} title={t('experienceLvl')} subtext={expText} />
            <div className={`${styles.personalInfoBorder} ${styles.personalInfoMobileBorder}`} />
            <SingleInfo imgSrc={salaryIcon} title={t('salaryExp')} subtext={currencyText} />
          </div>
          <div className={styles.personalInfoBorder} />
          <div className={styles.personalInfoDoubleWrapper}>
            <SingleInfo imgSrc={workTypeIcon} title={t('workType')} subtext={workTypeText} />
            <div className={`${styles.personalInfoBorder} ${styles.personalInfoMobileBorder}`} />
            <SingleInfo imgSrc={workPermitIcon} title={t('workPermit')} subtext={workPermitText} />
          </div>
        </>
      )}
    </div>
  );
};

export default PersonalInformation;

const SingleInfo = ({ imgSrc, title, subtext }) => {
  return (
    <div className={styles.singleInfoWrapper}>
      <div className={styles.singleInfoIconWrapper}>
        <img src={imgSrc} alt="infoIcon" />
      </div>
      <div className={styles.singleInfoTextWrapper}>
        <text className={styles.singleInfoTitle}>{title}</text>
        <text className={styles.singleInfoSubtext}>{subtext}</text>
      </div>
    </div>
  );
};
