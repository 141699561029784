import { configureStore } from '@reduxjs/toolkit';
import assessmentDetail from './assessmentDetail/slice';
import candidate from './candidate/slice';
import usersSlice from './user/slice';
import authSlice from './auth/slice';
import filterState from './FilterDetail/slice';
import addAssessmentState from './addAssessment/slice';
import assessmentsVol2Slice from './assessmentsVol2/slice';
import successFactorSlice from './CandidatePublic/slice';
import AddQuestionSlice from './addQuestion/slice';
import logoStateSlice from './logo/slice';
import PlanSlice from './plan/slice';
import assessmentSessionSlice from './AssessmentSession/slice';
import salesScreenSlice from './salesScreen/slice';
import signupSlice from './signup/slice';
import assessmentDetailVol2Slice from './assessmentDetailVol2/slice';
import candidateDetailSlice from "./candidateDetail/slice";
import previewAssessmentSlice from './previewAssessment/slice'

export const store = configureStore({
  reducer: {
    assessmentDetail,
    user: usersSlice,
    auth: authSlice,
    candidate,
    filterState,
    addAssessmentState,
    logoState: logoStateSlice,
    assessmentsVol2: assessmentsVol2Slice,
    successFactors: successFactorSlice,
    addQuestion: AddQuestionSlice,
    plan: PlanSlice,
    assessmentSession: assessmentSessionSlice,
    salesScreen: salesScreenSlice,
    signup: signupSlice,
    assessmentDetailVol2: assessmentDetailVol2Slice,
    candidateDetail: candidateDetailSlice,
    previewAssessment: previewAssessmentSlice,
  }
});
