import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import StartPage from '../Components/StartPage';
import mockImage2 from '../../../images/Common/Coensio_newLogo.svg';
import {
  AssessmentTitle,
  CandidateEmail,
  CompanyName,
  LogoPath,
  isOtpNeededRedux
} from '../../../redux/AssessmentSession/selectors';
import {
  applicantAssessmentDetailAction,
  candidateContractCheckAction
} from '../../../redux/AssessmentSession/actions';
import { ASSESSMENT_FLOW_MOBILE_THRESHOLD, useWindowSize } from '../../../utils/helpers';
import styles from '../Styles/AssessmentSessionInformationPage.module.css';
import useDisableOnTranslation from '../Utils/useDisableOnTranslation';
import useOTPVerification from '../Utils/useOTPVerification';
import InfoBanner from '../../../Common/Components/InfoBanner';
import {
  infoBannerShowStateSelector,
  infoBannerTextSelector,
  infoBannerErrorFlagSelector
} from '../../../redux/assessmentDetailVol2/selectors';
import {
  setInfoBannerShowState,
  setInfoBannerText,
  setInfoBannerErrorFlag
} from '../../../redux/assessmentDetailVol2/slice';
import { ReactComponent as Error } from '../../../images/Common/errorSubmit.svg';

const AssessmentSessionInformation = () => {
  const dispatch = useDispatch();

  const isOtpNeeded = useSelector(isOtpNeededRedux);

  const { isTranslateEnabled, DisableModal } = useDisableOnTranslation();
  const [width] = useWindowSize();
  const { isVerified, phone, OTPHandler } = useOTPVerification(isOtpNeeded);

  const { search } = useLocation();
  const assessmentId = new URLSearchParams(search).get('assessment');
  const reduxLogoPath = useSelector(LogoPath);
  const [innerPath, setInnerPath] = useState(reduxLogoPath);
  const reduxCompanyName = useSelector(CompanyName);
  const reduxAssessmentName = useSelector(AssessmentTitle);
  const reduxEmail = useSelector(CandidateEmail);
  const infoBannerShowStateRedux = useSelector(infoBannerShowStateSelector);
  const infoBannerTextRedux = useSelector(infoBannerTextSelector);
  const infoBannerErrorFlagRedux = useSelector(infoBannerErrorFlagSelector);

  useEffect(async () => {
    if (!reduxCompanyName || !reduxAssessmentName) {
      const jsonData = {
        Key: assessmentId
      };
      await dispatch(applicantAssessmentDetailAction(jsonData));
    }
  }, [reduxLogoPath, reduxCompanyName, reduxAssessmentName]);

  useEffect(() => {
    if (reduxLogoPath && reduxLogoPath.startsWith('CompanyLogo')) {
      let temp = '';
      temp = temp.concat(`${process.env.REACT_APP_IMAGE_API}/`);
      temp = temp.concat(reduxLogoPath);
      setInnerPath(temp);
    }
  }, [reduxLogoPath]);

  useEffect(async () => {
    const contractCheck = { candidateEmail: reduxEmail };
    dispatch(candidateContractCheckAction(contractCheck));
  }, []);

  /* Forwards the history state FIXME: could be buggy */
  const blockBackButton = event => {
    event.preventDefault();
    history.go(1);
  };

  useEffect(() => {
    /* Prevent back button by going forward */
    window.onpopstate = blockBackButton;
  }, []);

  if (isOtpNeeded !== undefined) {
    return (
      <ContentBody>
        <div className={styles.AssessmentSessionInformationPageWrapper}>
          {reduxCompanyName && (
            <>
              <Header>
                {width <= ASSESSMENT_FLOW_MOBILE_THRESHOLD ? (
                  <img
                    alt=""
                    src={innerPath || mockImage2}
                    style={{ maxHeight: 20, maxWidth: 110 }}
                  />
                ) : (
                  <>
                    <img
                      alt=""
                      src={innerPath || mockImage2}
                      style={{ maxHeight: '3.2rem', maxWidth: '13.7rem' }}
                    />
                    <Row>
                      <Powered>Powered by</Powered>
                      <img
                        alt=""
                        src={mockImage2}
                        style={{
                          maxWidth: 80,
                          maxHeight: 32,
                          marginLeft: '0.6rem',
                          height: '32px'
                        }}
                      />
                    </Row>
                  </>
                )}
              </Header>
              {!isVerified ? (
                <OTPHandler />
              ) : isTranslateEnabled ? (
                <DisableModal />
              ) : (
                <PageWrapper>
                  <ContentWrapper>
                    <StartPage phone={phone} />
                  </ContentWrapper>
                </PageWrapper>
              )}
            </>
          )}
        </div>
        <InfoBanner
          text={infoBannerTextRedux}
          show={infoBannerShowStateRedux}
          CustomIcon={infoBannerErrorFlagRedux ? Error : null}
          setStatus={() => {
            dispatch(setInfoBannerShowState(false));
            dispatch(setInfoBannerText());
            dispatch(setInfoBannerErrorFlag(false));
          }}
          bannerStyle={{
            border: infoBannerErrorFlagRedux
              ? '1px solid rgba(221, 58, 58, 0.1)'
              : '1px solid rgba(74, 195, 137, 0.1)'
          }}
        />
      </ContentBody>
    );
  }
  return null;
};

export default AssessmentSessionInformation;

const ContentBody = styled.div`
  width: 100vw;
  height: 100vh;
  display: block;
  background: white;
`;

const PageWrapper = styled.div`
  width: 100%;
  // min-height: 100%;
  background: white;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // box-shadow: 0px 1px 24px rgba(131, 131, 131, 0.1);
  flex-grow: 1;
  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    background-color: white;
    box-shadow: none;
  }
`;

const ContentWrapper = styled.div`
  margin-left: auto;
  padding-top: 2.4rem;
  height: 90%;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  max-height: 700px;
  justify-content: space-between;
  min-width: 450px;
  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    width: 100%;
    height: 100%;
    max-width: none; // Bunlar nasıl daha iyi yapılır
    min-height: 0;
    max-height: none;
    padding: 2.4rem;
    min-width: 0;
    box-shadow: none;
  }
`;
const Header = styled.div`
  background: #fafafa;
  padding-left: 3.6rem;
  padding-top: 2rem;
  padding-right: 4.7rem;
  @media screen and (min-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    padding-bottom: 2rem;
  }

  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    background-color: white;
    padding-left: 2.4rem;
  }
`;

const Powered = styled.div`
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: #393939;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
