import React, { useCallback, useEffect, useState } from 'react';
import TabSwitch from '../../Common/Components/TabSwitch';
import CustomFunnelContent from '../Components/Settings/PositionTab/CustomFunnelContent';

export default function useHandlePositionSettingsTabSwitch() {
  const tabSwitchNameList = ['Hiring Funnel', 'Candidate Filter', 'Mail Templates', 'UI Language'];
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const tabSwitchFunctionList = [...Array(tabSwitchNameList?.length)]
    .map((x, index) => index)
    .map(x => () => setActiveTabIndex(x));

  const PositionSettingsTabSwitch = useCallback(() => {
    return <TabSwitch tabNameList={tabSwitchNameList} onClickFuncList={tabSwitchFunctionList} />;
  }, []);

  const PositionSettingsContent = useCallback(() => {
    switch (activeTabIndex) {
      case 0:
        return <CustomFunnelContent />;

      case 1:
        return <div>Coming soon...</div>;

      case 2:
        return <div>Coming soon...</div>;

      case 3:
        return <div>Coming soon...</div>;

      default:
        return <CustomFunnelContent />;
    }
  }, [activeTabIndex]);

  return { PositionSettingsTabSwitch, PositionSettingsContent };
}
