import React from 'react';

import { useTranslation } from 'react-i18next';

import styles from '../Styles/LeavingPageModal.module.css';
import CustomButton from '../../../Common/Components/CustomButton';
import { ReactComponent as Delete } from '../../../images/AddAssessment/AssessmentCustomization/leavePageModalDelete.svg';
import CloseButton from '../../../Common/Components/CloseButton';
import Modal from '../../../Common/CustomModal/Modal';

export default function LeavingPageModal({
  show = false,
  onHide = () => {},
  cancelClick,
  confirmClick
}) {

  const { t } = useTranslation(['questionBasedAssm'])

  return (
    <Modal
      show={show}
      onClose={onHide}
      centerHorizontally
      centerVertically
      outsideClickClose
      contentClassName={styles.modalContent}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          // marginRight: 0,
          // marginLeft: 'auto',
          justifyContent: 'space-between',
          marginBottom: '24px'
        }}
      >
        <div className={styles.title}>{t('progSavedAsDraft')}</div>
        <CloseButton onClick={() => onHide()} />
      </div>

      <div className={styles.subtitle}>
        {t('continueOrDeleteAssmText')}{' '}
      </div>
      <div className={styles.row}>
        <div
          style={{
            marginLeft: 0,
            marginRight: 'auto',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Delete className={styles.deleteIcon} />
          <button type="button" className={styles.delete} onClick={cancelClick}>
            {t('deleteAssm')}
          </button>
        </div>
        <CustomButton
          size="small"
          style={{ width: 70, height: 37 }}
          textField={t('ok')}
          buttonOnClick={e => {
            confirmClick(e);
          }}
          type={1}
        />
      </div>
    </Modal>
  );
}
