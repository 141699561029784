import React, { useEffect, useState } from 'react';

import { useTranslation, Trans } from 'react-i18next';

import Tooltip from '../../../../Common/Components/Tooltip';
import styles from './ProgressBar.module.css';
import { ReactComponent as Arrow } from '../../../../images/AssessmentDetail/AssessmentDetailHeader/average_arrow.svg';

const ProgressBar = ({ score, average, tooltipText }) => {
  const [colorCode, setColorCode] = useState();
  const [percentage, setPercentage] = useState(10);

  const { t } = useTranslation(['candidateDetail']);

  useEffect(() => {
    if (average !== null) {
      if (score === -1) {
        setColorCode('#FF7E62');
      } else if (score >= average) {
        setColorCode('#4AC389');
      } else if (score >= average * 0.8 && score < average) {
        setColorCode('#FFD600');
      } else {
        setColorCode('#CA1515');
      }
    } else {
      setColorCode('#FFD600');
    }

    if (!average) {
      setPercentage(100);
    } else {
      setPercentage(
        Math.round((Math.abs(Math.floor(average) - score) / Math.floor(average)) * 100)
      );
    }
  }, [score, average]);

  return (
    <div className={styles.ProgressBarWrapper}>
      <div className={styles.UltimateInnerWrapper}>
        <div className={styles.InnerWrapper}>
          <Tooltip
            style={{ marginTop: '2px', fontSize: '14px', padding: '4px 8px' }}
            content={tooltipText}
            childrenStyle={{ marginLeft: `calc(${average || 0}% )` }}
            theme="dark"
            offset={-15}
          >
                <Arrow />
          </Tooltip>

          <div className={styles.ProgressBarBackDiv}>
            <div
              className={styles.ProgressBarFrontDiv}
              style={{
                width: `${(score !== -1 ? score : 0) || 0}%`,
                background: colorCode,
                borderRadius: score === 100 ? '3px' : 'inherit',
                transition: 'width 1s'
              }}
            />
          </div>
        </div>

        <text className={styles.ProgressBarScore}>{score !== -1 ? score : 'N/A'}</text>
      </div>

      <text style={{ color: 'rgba(57, 57, 57, 0.5)', fontSize: '15px' }}>
        {average === null ? (
          <text>{t('indAvgNotAvailable')}</text>
        ) : score !== -1 ? (
          score >= average ? (
            <>
              <span>
                <Trans i18nKey='betterThan' ns='candidateDetail' values={{ percentage: percentage }}>
                  0<span>1</span><span style={{ color: colorCode }}>2</span><span>3</span>
                </Trans>
              </span>
            </>
          ) : score >= average * 0.8 ? (
            <>
              <span>
                <Trans i18nKey="indAvg" ns="candidateDetail">
                  0<span>1</span>
                  <span style={{ color: colorCode }}>2</span>
                  <span>3</span>4
                </Trans>
              </span>
            </>
          ) : (
            <>
              <span>
                <Trans i18nKey='worseThan' ns='candidateDetail' values={{ percentage: percentage }}>
                  0<span>1</span><span style={{ color: colorCode }}>2</span><span>3</span>
                </Trans>
              </span>
            </>
          )
        ) : (
          <>
            <span>
              <Trans i18nKey="notTakeAssm" ns="candidateDetail">
                0<span>1</span>
                <span style={{ color: colorCode }}>2</span>
                <span>3</span>4
              </Trans>
            </span>
          </>
        )}
      </text>
    </div>
  );
};

export default ProgressBar;
