import React from 'react';

const FunnelGroupItemSkeletonLoading = () => {
  return (
    <>
      <div
        className="skeletonAnimation"
        style={{ borderRadius: '12px', height: '64px', width: 'calc(100% - 10px)' }}
      />
      <div
        className="skeletonAnimation"
        style={{ borderRadius: '12px', height: '64px', width: 'calc(100% - 10px)' }}
      />
      <div
        className="skeletonAnimation"
        style={{ borderRadius: '12px', height: '64px', width: 'calc(100% - 10px)' }}
      />
    </>
  );
};

export default FunnelGroupItemSkeletonLoading;