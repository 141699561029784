import React, { useEffect, useState } from 'react';

function CandidateProgressBar({ bgcolor, progress, height }) {
  const [width, setWidth] = useState(0);
  const Parentdiv = {
    height,
    backgroundColor: 'whitesmoke',
    borderRadius: 5,
    width: "calc((100% - 30px) * 0.6 )",
    // maxWidth: '280px',
    marginLeft: '20px'
  };

  const Childdiv = {
    height: '100%',
    width: `${width}%`,
    backgroundColor: bgcolor,
    borderRadius: 5,
    textAlign: 'right',
    transition: '0.7s'
  };

  useEffect(() => {
    if (progress >= 97) {
      setWidth(100);
    } else {
      setWidth(progress);
    }
  });

  return (
    <div style={Parentdiv}>
      <div style={Childdiv} />
    </div>
  );
}

export default CandidateProgressBar;
