import React, { useEffect, useState } from 'react';
import styles from '../Styles/CustomButton.module.css';
import Tooltip from './Tooltip';

function CustomButton({
  buttonRef,
  textField,
  buttonOnClick = () => {},
  isDisabled,
  size,
  type,
  Icon,
  iconStyle,
  enterEnabled,
  loading = false, // Use for loading animation
  buttonType = 'button',
  tooltipText,
  customStyle,
  buttonId = '',
  isResponsive = false,
  ...props
}) {
  // size: has 3 default options -> medium: 112px * 42px and 18px font / small: 96px * 36px and 16px font / mobile: 100% * 4.2rem w/1.6rem font-size (adaptive for mobile buttons)
  // type: there are 4 different button type. Details can be found in figma. Usually type 1 and 2 will be
  //       used (1: next / 2: back)

  // !!! Icon should be passed as ReactComponent !!!!

  const [buttonBody, setButtonBody] = useState('');
  const [buttonSize, setButtonSize] = useState('');

  const validSizeList = ['small', 'medium', 'mobile'];

  let enterCount = 1;

  function handleEnterClick(event) {
    if (event.key === 'Enter' && enterEnabled && !isDisabled && enterCount === 1) {
      event.preventDefault();
      enterCount = 3;
      buttonOnClick();
    }
  }
  useEffect(() => {
    const listener = event => {
      if ((event.code === 'Enter' || event.code === 'NumpadEnter') && enterEnabled) {
        event.preventDefault();
        handleEnterClick(event);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  });

  useEffect(async () => {
    if (type) {
      setButtonBody(`Type${type}Button`);
    } else {
      setButtonBody('Type1Button');
    }

    if (validSizeList.includes(size)) {
      setButtonSize(size);
    } else {
      setButtonSize('medium');
    }
  }, [type, textField, isDisabled, size]);

  const TextWrapper = (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {Icon && (
        <div className={styles.IconWrapper}>
          <Icon className={styles.IconColor} style={iconStyle} />
        </div>
      )}
      {textField}
    </div>
  );

  return (
    <>
      <button
        ref={buttonRef}
        /* eslint-disable-next-line react/button-has-type */
        type={buttonType}
        id={buttonId}
        className={`${styles[buttonSize]} ${styles[buttonBody]} 
          ${Icon ? styles.ButtonLayoutWithIcon : styles.ButtonLayout} 
          ${loading ? styles.animationSpan : ''} ${isResponsive ? styles.responsive : ''}`}
        style={{ ...props.style, ...customStyle }}
        onClick={buttonOnClick}
        disabled={isDisabled || loading}
      >
        {/* !!! Icon should be passed as ReactComponent !!!! */}
        {isDisabled && tooltipText ? (
          <Tooltip
            content={tooltipText}
            offset={10}
            style={size === 'mobile' && { maxWidth: '175px' }}
          >
            {TextWrapper}
          </Tooltip>
        ) : (
          TextWrapper
        )}
      </button>
    </>
  );
}

export default CustomButton;
