import React from 'react';

import { useTranslation } from 'react-i18next';

export default function ReportQuestionBar() {
  const { t } = useTranslation(['candidateDetail'])
  return (
    <div
      className="FlexRow verticallyAlign"
      style={{
        background: '#7458d3',
        borderRadius: '10px',
        padding: '10px 18px',
        lineHeight: '22px'
      }}
    >
      <span className="fw-400 c-white font-15" style={{ width: '44px' }}>
        No
      </span>
      <span className="fw-400 c-white font-15" style={{ flexGrow: '1' }}>
        {t('questionTitle')}
      </span>
      <span
        className="FlexRow fw-400 c-white font-15"
        style={{ marginRight: '4px', width: '90px', justifyContent: 'end' }}
      >
        {t('tabSwitch')}
      </span>
      <span
        className="FlexRow fw-400 c-white font-15"
        style={{ width: '100px', justifyContent: 'end' }}
      >
        {t('timeTaken')}
      </span>
      <span
        className="FlexRow fw-400 c-white font-15"
        style={{ width: '85px', justifyContent: 'end' }}
      >
        {t('score')}
      </span>
    </div>
  );
}
