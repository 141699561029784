import React from 'react';
import { useWindowSize } from '../../../utils/helpers';
import CloseButton from '../CloseButton';
import styles from './CustomSearchbar.module.css';
import searchIcon from './searchIcon.svg';
import searchMobile from './searchMobile.svg';
import extendedSearchMobile from './extendedSearchMobile.svg';

const CustomSearchbar = ({
  isExtended = true, // extended or not - if not should also pass iconOnClickForNotExtended
  wrapperStyle = {}, // main wrapper for whole searchbar
  extendedIconOnClick = () => {}, // onClick behavior of search icon "in" search bar
  inputFieldValue = null, // value of input field
  inputFieldPlaceholder, // placeholder for input field
  inputFieldOnChange = () => {}, // onChange behavior of input field
  inputFieldOnFocus = () => {}, // onFocus behavior of input field
  inputFieldOnKeyDown = () => {}, // onKeyDown behavior of input field
  iconOnClickForNotExtended = () => {}, // onClick behavior for clickable search icon before extend / If already isExtended, no need
  crossOnClick, // onClick behavior for cross icon in input field
  autoFocus = false, // If given, autoFocus is enabled
  extendedStyle = {}, // additional style for extended state
  notExtendedStyle = {}, // additional style for not extended state
  searchbarId,
  inputFieldStyle = {}, // additional style for input field
  optionalHoverForSearchIcon = false
}) => {
  const [screenW, screenH] = useWindowSize();

  const MOBILE_THRESHOLD = 576;

  return (
    <div
      className={styles.MainWrapper}
      id={searchbarId}
      style={
        isExtended
          ? { ...wrapperStyle, ...extendedStyle }
          : { ...wrapperStyle, ...notExtendedStyle }
      }
    >
      <button
        type="button"
        className={`${styles.SearchIcon} ${isExtended && styles.extendedIcon} ${
          optionalHoverForSearchIcon && styles.searchHover
        }`}
        onClick={isExtended ? extendedIconOnClick : iconOnClickForNotExtended}
        aria-label="searchIcon"
      >
        <img
          src={
            screenW > MOBILE_THRESHOLD
              ? searchIcon
              : isExtended
              ? extendedSearchMobile
              : searchMobile
          }
          alt="searchIcon"
        />
      </button>
      {isExtended ? (
        <input
          id={`${searchbarId}-input`}
          className={styles.SearchInput}
          style={{ ...inputFieldStyle }}
          type="text"
          value={inputFieldValue}
          name="search"
          placeholder={inputFieldPlaceholder}
          autoFocus={autoFocus}
          onFocus={inputFieldOnFocus}
          onKeyDown={inputFieldOnKeyDown}
          onChange={inputFieldOnChange}
        />
      ) : null}

      {isExtended && crossOnClick ? (
        <div className={styles.CloseWrapper}>
          <CloseButton onClick={crossOnClick} size={screenW > 720 ? 'small' : 'large'} />
        </div>
      ) : null}
    </div>
  );
};
export default CustomSearchbar;
