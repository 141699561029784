import React from 'react';
import { useSelector } from 'react-redux';
import styles from './SalesInfoModal.module.css';
import CloseButton from '../../../Common/Components/CloseButton';
import { InfoText } from '../../../redux/salesScreen/selectors';
import Modal from '../../../Common/CustomModal/Modal';

const SalesInfoModal = ({ show = true, onHide }) => {
  const infoText = useSelector(InfoText);
  return (
    <Modal
      show={show}
      contentClassName={`${styles.customModal} ${styles['modal-content']} `}
      centerHorizontally
      centerVertically
      outsideClickClose
      onClose={onHide}
    >
      <CloseButton onClick={onHide} />
      <div className={styles.text}>{infoText}</div>
    </Modal>
  );
};

export default SalesInfoModal;
