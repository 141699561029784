import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import InfoBanner from '../../../Common/Components/InfoBanner';
import {
  signUpStepRedux,
  signUpStep1ErrorMessageRedux,
  signUpStep2ErrorMessageRedux
} from '../../../redux/signup/selectors';
import {
  resetSignUpStep2ErrorMessage,
  resetToInitial,
  setSignedUserId,
  setSignupStep
} from '../../../redux/signup/slice';
import DomainSignupComponent from '../../Components/Signup/DomainSignupComponent';
import SignupComponent from '../../Components/Signup/SignupComponent';
import { ReactComponent as Error } from '../../../images/Common/errorSubmit.svg';
import styles from './Signup.module.css';
import InviteComponent from '../../Components/Signup/InviteComponent';

const SignupPage = () => {
  const dispatch = useDispatch();
  const signUpStep = useSelector(signUpStepRedux);
  const step1ErrorMessage = useSelector(signUpStep1ErrorMessageRedux);
  const step2ErrorMessage = useSelector(signUpStep2ErrorMessageRedux);

  const { search } = useLocation();
  const userIdFromUrl = new URLSearchParams(search).get('UserId');
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    setLoading(true);

    await dispatch(resetToInitial());

    if (userIdFromUrl) {
      localStorage.setItem('completeSignupUserId', userIdFromUrl);
      const currentUrl = window.location.href;
      window.location.href = currentUrl.split('?UserId=')[0];
    } else if (localStorage.getItem('completeSignupUserId')) {
      await dispatch(setSignedUserId(localStorage.getItem('completeSignupUserId')));
      await dispatch(setSignupStep(2));
      localStorage.removeItem('completeSignupUserId');
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <div className={styles.ContentBody}>
      {!loading && step1ErrorMessage?.startsWith('Domain exist.') && (
        <DomainSignupComponent title="This domain has already signed up" />
      )}
      {!loading && step1ErrorMessage?.startsWith('Already invited.') && (
        <DomainSignupComponent title="Your team has already invited you to Coensio" invitation />
      )}
      {!loading &&
      !step1ErrorMessage?.startsWith('Already invited.') &&
      !step1ErrorMessage?.startsWith('Domain exist.') &&
      signUpStep === 1 ? (
        <SignupComponent />
      ) : signUpStep === 2 ? (
        <InviteComponent />
      ) : null}

      {/* {!loading && signUpStep === 2 && <SignupComponent2 />}
      {!loading && signUpStep === 3 && <VerifyEmailComponent />} */}

      <InfoBanner
        text={step2ErrorMessage}
        show={!!step2ErrorMessage && step2ErrorMessage !== 'Company name exist.'}
        CustomIcon={Error}
        setStatus={type => dispatch(resetSignUpStep2ErrorMessage())}
        bannerStyle={{ backgroundColor: 'white', border: '1px solid rgba(221, 58, 58, 0.1)' }}
      />
    </div>
  );
};

export default SignupPage;
