import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import CustomButton from '../../../Common/Components/CustomButton';
import SearchBar from '../../../Common/Components/SearchBar';
import Checkbox from '../../../Common/Components/Checkbox';
import invited from '../../../images/Common/invited.svg';
import styles from '../Styles/AuthorizeUserList.module.css';
import { ReactComponent as UserIcon } from '../Images/addUser.svg';
import {
  HMUserList,
  RecUserList,
  invitedHMList,
  invitedRecList,
  authorizedHMList,
  authorizedRecList,
  newlyInvitedIDList,
  newlyInvitedID
} from '../../../redux/assessmentsVol2/selectors';
import {
  setAuthorizedHm,
  setInviteUserShow,
  setAuthorizedRec,
  setAuthorizedUsers
} from '../../../redux/assessmentsVol2/slice';
import { userSelector } from '../../../redux/auth/selectors';
import rightIcon from '../Images/rightIcon.svg';
import { setUserTypeOfAuthComponent } from '../../../redux/addAssessment/slice';
import { userTypeOfAuthComponent } from '../../../redux/addAssessment/selectors';
import { useWindowSize } from '../../../utils/helpers';

export default function AuthorizeUserList({ userRole, style }) {
  const invitedUserlist = useSelector(userRole !== 'rec' ? invitedHMList : invitedRecList);
  const [searchParam, setSearchParam] = useState('');
  const myUser = useSelector(userSelector);
  const dispatch = useDispatch();
  const userList = useSelector(userRole !== 'rec' ? HMUserList : RecUserList);
  const [isOpen, setIsOpen] = useState(false);
  const [windowW] = useWindowSize();

  const newlyInvitedList = useSelector(newlyInvitedIDList);
  const newInvitedUser = useSelector(newlyInvitedID);
  const authHM = useSelector(authorizedHMList);
  const authRec = useSelector(authorizedRecList);
  const [selectedUserList, setSelectedUserList] = useState(userRole !== 'rec' ? authHM : authRec);

  const { t } = useTranslation(['createAssm']);

  // For select all indeterminate state
  const [defaultSelectedCountState, setDefaultSelectedCountState] = useState();
  // For select all disabled state
  const [newlyInvitedCountForCategory, setNewlyInvitedCountForCategory] = useState();

  useEffect(() => {
    if (myUser) {
      if (
        ((myUser.userGroupType === 'Hiring Manager' && userRole !== 'rec') ||
          (myUser.userGroupType !== 'Hiring Manager' && userRole === 'rec')) &&
        !selectedUserList.includes(myUser.userId)
      ) {
        setSelectedUserList(prev => [...prev, myUser.userId]);
      }

      if (
        (myUser.userGroupType === 'Hiring Manager' || myUser.userGroupType === 'Super Admin') &&
        userRole === 'rec'
      ) {
        setIsOpen(true);
      } else if (myUser.userGroupType === 'Recruiter' && userRole === 'hr') {
        setIsOpen(true);
      }

      setDefaultSelectedCountState(1);
    }
    return () => {
      // setSelectedUserList([]);
    };
  }, [myUser]);

  function handleCheckbox(user) {
    let temp = [...selectedUserList];
    if (!newlyInvitedList.includes(user)) {
      if (selectedUserList.includes(user)) {
        temp = temp.filter(e => e !== user);
        setSelectedUserList(temp);
      } else {
        setSelectedUserList(prev => [...prev, user]);
      }
    }
  }

  const usertypeOfAuth = useSelector(userTypeOfAuthComponent);

  useEffect(() => {
    const len = newlyInvitedList.length;
    if (
      newInvitedUser &&
      newInvitedUser !== newlyInvitedList[len - 1] &&
      ((userRole && userRole !== 'rec' && usertypeOfAuth === 'hm') ||
        (userRole && userRole === 'rec' && usertypeOfAuth === 'rec'))
    ) {
      setSelectedUserList(prev => [...prev, newInvitedUser]);
    }
  }, [newInvitedUser]);

  function handleUserType() {
    if (userRole && userRole !== 'rec') {
      dispatch(setUserTypeOfAuthComponent('hm'));
    } else {
      dispatch(setUserTypeOfAuthComponent('rec'));
    }
    // setTimeout(() => {
    //   handleNewUser(newInvitedUser);
    // }, 500);
  }

  useEffect(() => {
    if (userRole && userRole !== 'rec') {
      dispatch(setAuthorizedHm(selectedUserList));
    } else {
      dispatch(setAuthorizedRec(selectedUserList));
    }
  }, [selectedUserList]);

  useEffect(() => {
    let temp = '-';
    authHM?.forEach(hmID => {
      temp = temp + hmID + '-';
    });

    authRec?.forEach(recID => {
      temp = temp + recID + '-';
    });

    newlyInvitedList?.forEach(invitedID => {
      if (!newlyInvitedList.includes(invitedID)) {
        temp = temp + invitedID + '-';
      }
    });

    dispatch(setAuthorizedUsers(temp));

    // For select all indeterminate state - below
    let defaultSelectedCount = 0;
    // Adds current user
    if (
      (myUser.userGroupType === 'Hiring Manager' && userRole !== 'rec') ||
      (myUser.userGroupType !== 'Hiring Manager' && userRole === 'rec')
    ) {
      defaultSelectedCount += 1;
    }

    // Adds users in newlyInvited array
    let innerNewlyInvitedCountForCategory = 0;
    const idArr = [];
    userList.forEach(e => idArr.push(e.id));
    newlyInvitedList.forEach(e => {
      if (idArr.includes(e)) {
        defaultSelectedCount += 1;
        innerNewlyInvitedCountForCategory += 1;
      }
    });

    setDefaultSelectedCountState(defaultSelectedCount);
    setNewlyInvitedCountForCategory(innerNewlyInvitedCountForCategory);
  }, [authHM, authRec, newlyInvitedList]);

  const Header = () => {
    return (
      <>
        <div className={styles.roleText}>
          {userRole === 'rec' ? t('recruiter') : t('hiringManager')}
        </div>
        <div className={styles.countWrapper}>
          <span className={styles.count}>{selectedUserList.length}</span>
          {/* <span className={styles.users}>{`user${selectedUserList?.length > 1 ? 's' : ''}`}</span> */}

          {selectedUserList?.length > 1 
            ? ( <span className={styles.users}>{t('users')}</span>
          ) : (
            <span className={styles.users}>{t('user')}</span>
          )}

          <span className={isOpen ? styles.open : styles.vectorIcon}>
            <img alt="" src={rightIcon} />
          </span>
        </div>
      </>
    );
  };

  const [invitedIdsArray, setInvitedIdsArray] = useState();

  useEffect(() => {
    if (invitedUserlist) {
      const temp = [];
      invitedUserlist.forEach(x => temp.push(x.id));
      setInvitedIdsArray(temp);
    }
  }, [invitedUserlist]);

  const handleSelectAll = () => {
    const temp = [];

    let defaultSelectedCount = 0;
    // Adds current user
    if (
      (myUser.userGroupType === 'Hiring Manager' && userRole !== 'rec') ||
      (myUser.userGroupType !== 'Hiring Manager' && userRole === 'rec')
    ) {
      temp.push(myUser.userId);
      defaultSelectedCount += 1;
    }

    // Adds users in newlyInvited array
    let innerNewlyInvitedCountForCategory = 0;
    const idArr = [];
    userList.forEach(e => idArr.push(e.id));
    newlyInvitedList.forEach(e => {
      if (idArr.includes(e)) {
        temp.push(e);
        defaultSelectedCount += 1;
        innerNewlyInvitedCountForCategory += 1;
      }
    });

    setDefaultSelectedCountState(defaultSelectedCount);
    setNewlyInvitedCountForCategory(innerNewlyInvitedCountForCategory);

    // If no users is selected "additionally", select all
    if (selectedUserList?.length === 0 || selectedUserList?.length === defaultSelectedCount) {
      userList?.forEach(e => {
        if (!newlyInvitedList.includes(e.id) && !selectedUserList.includes(e.id)) {
          temp.push(e.id);
        }
      });
    }
    setSelectedUserList(temp);
  };

  return (
    <div className={styles.wrapper} style={style}>
      {!isOpen ? (
        <div className={styles.headerWrapper} onClick={() => setIsOpen(!isOpen)}>
          <div className={styles.closeWrapper} style={{ cursor: 'pointer' }}>
            <Header />
          </div>
        </div>
      ) : (
        <div className={styles.openWrapper}>
          <div
            className={styles.headerWrapper}
            style={{ cursor: 'pointer' }}
            onClick={() => setIsOpen(!isOpen)}
          >
            <Header />
          </div>
          <div className={styles.searchWrapper}>
            <SearchBar
              extendedStyle={{ fontSize: '16px', height: '42px' }}
              color="white"
              size="line"
              inputFieldPlaceholder={t('search')}
              isExtended={1}
              inputFieldOnChange={event => {
                setSearchParam(event.target.value);
              }}
              inputFieldValue={searchParam}
            />
            {/* Değişecek */}
            {windowW > 700 ? (
              <CustomButton
                size="small"
                className={styles.userButton}
                id="userButton"
                Icon={UserIcon}
                textField={t('addNewUser')}
                type={5}
                buttonOnClick={() => {
                  dispatch(setInviteUserShow(true));
                  handleUserType();
                }}
              />
            ) : (
              <CustomButton
                size="mobile"
                className={styles.userButton}
                id="userButton"
                Icon={UserIcon}
                textField={t('add')}
                type={5}
                buttonOnClick={() => {
                  dispatch(setInviteUserShow(true));
                  handleUserType();
                }}
              />
            )}
          </div>
          <div className={styles.SelectAllWrapper}>
            <Checkbox
              labelStyle={{ marginRight: '15px' }}
              myDisabled={
                userList?.length === 0 || userList?.length === newlyInvitedCountForCategory
              } // Either no user in userList or all of them are newlyInvited in this category
              myChecked={
                !(
                  selectedUserList?.length === 0 ||
                  selectedUserList?.length === defaultSelectedCountState
                )
              } // Either a user is selected or, there are current user or users that are newly invited in that category
              myOnClick={handleSelectAll}
              indeterminate={
                (selectedUserList.includes(myUser.userId)
                  ? userList?.length !== selectedUserList?.length - 1
                  : userList?.length !== selectedUserList?.length) &&
                !(
                  selectedUserList?.length === 0 ||
                  selectedUserList?.length === defaultSelectedCountState
                )
              } // there are selected users, but not all of them
            />
            <div
              style={{
                height: '23px',
                color: '#393939',
                fontSize: '16px',
                cursor:
                  userList?.length === 0 || userList?.length === newlyInvitedCountForCategory
                    ? 'default'
                    : 'pointer',
                opacity:
                  userList?.length === 0 || userList?.length === newlyInvitedCountForCategory
                    ? '0.4'
                    : '1'
              }}
              onClick={() => {
                if (
                  !(userList?.length === 0 || userList?.length === newlyInvitedCountForCategory)
                ) {
                  // If not disabled
                  handleSelectAll();
                }
              }}
            >
              {t('selectAll')}
            </div>
          </div>
          <div className={styles.listWrapper}>
            {userList?.map(
              (user, key) =>
                user.nameSurname
                  .toString()
                  .toLowerCase()
                  .includes(searchParam.toLocaleLowerCase()) && (
                  <div className={styles.checkboxRow}>
                    <div className={styles.checkboxWrapper}>
                      <Checkbox
                        myOnClick={() => handleCheckbox(user.id)}
                        myChecked={
                          selectedUserList.includes(user.id) ||
                          myUser?.userId === user.id ||
                          newlyInvitedList?.includes(user.id)
                        }
                        myDisabled={
                          myUser?.userId === user.id || newlyInvitedList?.includes(user.id)
                        }
                      />
                    </div>
                    <div
                      className={`${styles.hrText} ${
                        invitedUserlist.filter(x => x.nameSurname === user.nameSurname).length >
                          0 && styles.disabledHR
                      }`}
                      style={{ cursor: 'pointer' }}
                      key={key}
                      onClick={() => handleCheckbox(user.id)}
                    >
                      {user.nameSurname}
                    </div>
                    {invitedIdsArray?.includes(user.id) && (
                      <div className={styles.invitedWrapper}>
                        <span className={styles.imgWrapper}>
                          <img alt="" src={invited} />
                        </span>
                        <span className={styles.invitedText}>
                          {newlyInvitedList.includes(user.id) && windowW > 700
                            ? t('newUserInv')
                            : t('invited')}
                        </span>
                      </div>
                    )}
                  </div>
                )
            )}
          </div>
        </div>
      )}
    </div>
  );
}
