import React from 'react';

export default function ReportFooter() {
  return (
    <div className="FlexRow verticallyCenter gap-18">
      <div
        className="w-100"
        style={{
          height: '1px',
          background: 'rgba(57, 57, 57, 0.2)'
        }}
      />
      <span className="c-darkGray-06 fw-400 font-14 verticallyCenter">coens.io</span>
      <div
        className="w-100"
        style={{
          height: '1px',
          background: 'rgba(57, 57, 57, 0.2)'
        }}
      />
    </div>
  );
}
