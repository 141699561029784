import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation, Trans } from 'react-i18next';

import { format, parseISO } from 'date-fns';
import { isMobile } from 'react-device-detect';
import { useWindowSize } from '../../../../utils/helpers';
import {
  abortDowngradeSuccessShow,
  asAddOnsRedux,
  caAddOnsRedux,
  currentPlanRedux,
  currentUsageRedux,
  errorInPlan,
  errorTextInPlan,
  planUnsubscribedModalShow,
  unsubscribeModalShow
} from '../../../../redux/plan/selectors';
import UserIcon from '../../../../Common/Components/UserIcon';
import styles from './PlanTab.module.css';
import {
  setAbortDowngradeSuccessShow,
  setPlanUnsubscribedModalShow,
  setShowPackageDetail,
  setUnsubscribeModalShow,
  setErrorInPlan
} from '../../../../redux/plan/slice';
import TextOverflow from '../../../../Common/Components/TextOverflow';
import ProgressCircle from '../../../../Candidate/Components/AnimatedProgressBar';
import Tooltip from '../../../../Common/Components/Tooltip';
import AddOnCard from './AddOnCard';
import { userSelector } from '../../../../redux/auth/selectors';
import {
  getAddOnsAction,
  getCurrentPlanAction,
  getCurrentUsageAction
} from '../../../../redux/plan/actions';
import UnsubscribeSuccessModal from '../../../Modals/UnsubscribeSuccessModal';
import UnsubscribeModal from '../../../Modals/UnsubscribeModal';
import PlanUnsubscribedSuccess from '../../../Modals/PlanUnsubscribedSuccess';
import HeadsUpModal from '../../../Modals/HeadsUpModal';
import InfoBanner from '../../../../Common/Components/InfoBanner';
import Skeleton from '../../../../Common/Components/Skeleton';
import { ReactComponent as Error } from '../../../../images/Common/errorSubmit.svg';

const PlanTab = () => {
  const dispatch = useDispatch();
  const [screenW] = useWindowSize();
  const user = useSelector(userSelector);
  const [usageLoading, setUsageLoading] = useState(true);
  const [planLoading, setPlanLoading] = useState(true);

  // Payment related selectors
  const currentPlan = useSelector(currentPlanRedux);
  const currentUsage = useSelector(currentUsageRedux);
  const addOnCa = useSelector(caAddOnsRedux);
  const addOnAs = useSelector(asAddOnsRedux);
  const unsubscribeShow = useSelector(unsubscribeModalShow);
  const unsubscribePlanSuccess = useSelector(planUnsubscribedModalShow);
  const abortDowngradeSuccess = useSelector(abortDowngradeSuccessShow);
  const error = useSelector(errorInPlan);
  const errorText = useSelector(errorTextInPlan);

  // Current usage data states
  const [name, setName] = useState();
  const [surname, setSurname] = useState();
  const [untilDate, setUntilDate] = useState('');

  // Payment related states
  const [contactSalesForUpgrade, setContactSalesForUpgrade] = useState(false);
  const [contactSalesSuccessShow, setContactSalesSuccessShow] = useState(false);

  useEffect(async () => {
    const data = {
      CompanyId: user.companyId
    };
    const resp1 = await dispatch(getCurrentPlanAction(data));
    const resp2 = await dispatch(getCurrentUsageAction(data));
    if (resp1.meta.requestStatus !== 'fulfilled') {
      setPlanLoading(true);
    } else {
      setPlanLoading(false);
    }
    if (resp2.meta.requestStatus !== 'fulfilled') {
      setUsageLoading(true);
    } else {
      setUsageLoading(false);
    }

    dispatch(getAddOnsAction(data));
  }, []);

  useEffect(() => {
    // to set the name and surname of unsubscribed user for userIcon
    if (currentPlan?.companyFuturePlan?.futurePlanUser) {
      const nameSurname = currentPlan?.companyFuturePlan?.futurePlanUser?.split(' ');
      const len = nameSurname?.length;
      setName(nameSurname[0]);
      setSurname(nameSurname[len - 1]);
    }
    if (currentPlan?.endDate) {
      const temp = format(parseISO(currentPlan?.endDate), 'MMM dd, yyyy');
      setUntilDate(temp);
    }
  }, [currentPlan]);

  const UsageRow = memo(({ numerator, denominator, tooltipText }) => {
    return (
      <div className={styles.barRow}>
        <div className={styles.percentage}>{`${
          numerator > denominator ? denominator : numerator
        }/${denominator}`}</div>
        <div className={styles.circleContainer}>
          <ProgressCircle
            score={
              denominator ? (numerator > denominator ? 100 : (numerator / denominator) * 100) : 0
            }
          />
        </div>
        {!isMobile ? (
          <Tooltip content={tooltipText}>
            <div className={styles.tooltipIcon} />
          </Tooltip>
        ) : null}
      </div>
    );
  });
  const { t } = useTranslation(['planTab']);

  const CurrentPlanContainer = () => {
    return (
      <div className={styles.planContainer} id="plan-container-settings">
        <div className={styles.buttonRow}>
          <div className={styles.buttonRowHeader}>
            <span className={styles.subheader}>{t('currentPlan')}</span>
            <TextOverflow
              className={styles.planName}
              text={currentPlan?.isCustom ? t('customEnterprisePlan') : currentPlan?.name}
            />
          </div>

          <button
            onClick={() => {
              if (currentPlan?.isCustom) {
                setContactSalesForUpgrade(true);
              } else {
                dispatch(setShowPackageDetail(true));
              }
            }}
            className={styles.upgradeButton}
            type="button"
          >
            {currentPlan?.name === 'Free Trial'
              ? t('subNow')
              : currentPlan?.isCustom
              ? t('upgPlan')
              : t('upgNow')}
          </button>
        </div>
        <div className={styles.tableRow}>
          <div className={styles.tableColumn}>
            <span className={styles.subheader}>{t('sub').toLocaleUpperCase('tr')}</span>
            <span className={styles.text}>
              {currentPlan?.name === 'Free Trial'
                ? t('14days')
                : currentPlan?.period === '30'
                ? t('monthly')
                : t('annualy')}
            </span>
          </div>
          <div className={styles.tableColumn}>
            <span className={styles.subheader}>{t('users')}</span>
            <span className={styles.text}>
              {currentPlan?.users ? currentPlan.users : t('unlimited')}
            </span>
          </div>
          <div className={styles.tableColumn}>
            <span className={styles.subheader}>{t('assms').toLocaleUpperCase('tr')}</span>
            <span className={styles.text}>
              {currentPlan?.currentBundleItem?.map(e => e.code === 10 && `${e.count}`)}
            </span>
          </div>
          <div className={styles.tableColumn}>
            <span className={styles.subheader}>{t('cands')}</span>
            <span className={styles.text}>
              {currentPlan?.currentBundleItem?.map(e => e.code === 20 && `${e.count}`)}
            </span>
          </div>
        </div>
        <div className={styles.footerRow}>
          {currentPlan?.isCanceled ? (
            <div className={styles.unsubscribedRow}>
              <div className={styles.unsubscribedInfo}>
                <div>
                  {/* <span className={styles.boldText}>{t('unsub')}</span>
                  <span
                    className={styles.lightText}
                  >{`by ${currentPlan?.companyFuturePlan?.futurePlanUser}`}</span> */}
                  <span>
                    <Trans
                      i18nKey="unsubBy"
                      ns="planTab"
                      values={{ user: currentPlan?.companyFuturePlan?.futurePlanUser }}
                    >
                      0<span className={styles.boldText}>1</span>
                      <span className={styles.lightText}>2</span>
                      <span className={styles.lightText}>3</span>
                      <span className={styles.lightText}>2</span>
                      <span className={styles.boldText}>1</span>
                    </Trans>
                  </span>
                </div>
                {!isMobile ? (
                  <UserIcon
                    textClassName={styles.userIconText}
                    className={styles.userIcon}
                    comment
                    name={name}
                    surname={surname}
                  />
                ) : null}
              </div>
              <span className={styles.validInfoText}>{t('billPeriod')}</span>
            </div>
          ) : (
            <div className={styles.unsubscribeRow}>
              {currentPlan?.name === 'Free Trial' ? (
                <span style={{ textAlign: 'left' }} className={styles.renewText}>
                  {t('freeTrialEnds')} {untilDate}
                </span>
              ) : (
                <div className={styles.renewalRow}>
                  <span
                    className={styles.text}
                    onClick={() => {
                      dispatch(setUnsubscribeModalShow(true));
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {t('unsubMobile')}
                  </span>
                  {!(currentPlan?.companyFuturePlan?.planOrder < currentPlan?.order) ||
                    (!currentPlan?.companyFuturePlan?.planOrder ? (
                      <span className={styles.renewText}>
                        {t('autoRenews')} {untilDate}
                      </span>
                    ) : (
                      <span className={styles.renewText}>
                        {/* Will be downgraded to {currentPlan?.companyFuturePlan?.planName} on&nbsp;
                        {untilDate} */}
                        <span>
                          <Trans
                            i18nKey="willDowngrade"
                            ns="planTab"
                            values={{ currentPlan: currentPlan?.companyFuturePlan?.planName }}
                          >
                            0<span>1</span>
                            <span>2</span>
                            <span>3</span>
                          </Trans>
                        </span>
                        {untilDate}
                      </span>
                    ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const CurrentUsageContainer = memo(() => {
    const { t } = useTranslation(['planTab']);
    return (
      <div className={styles.usageContainer}>
        <span className={styles.subheader}>{t('currentUsage')}</span>
        <div className={styles.usageGroup}>
          <span className={styles.text}>{t('assms')}</span>
          <div className={styles.usageRow}>
            <div className={styles.usageSubheader}>{t('published')}</div>
            <UsageRow
              numerator={currentUsage?.usedAssessmentCount}
              denominator={currentUsage?.allowedAssessmentCount}
              tooltipText={t('publishedAssms')}
            />
          </div>
        </div>

        <div className={styles.usageGroup}>
          <span className={styles.text}>{t('cands')}</span>
          <div className={styles.usageRow}>
            <div className={styles.usageSubheader}>{t('applied')}</div>

            <UsageRow
              numerator={currentUsage?.usedCandidates}
              denominator={currentUsage?.allowedCandidates}
              tooltipText={t('scoredCands')}
            />
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className={styles.wrapper}>
      <span className={styles.header}>{t('plan')}</span>
      <div className={styles.container}>
        <div className={styles.leftColumn}>
          {!planLoading ? <CurrentPlanContainer /> : <Skeleton type="current-plan" />}
          {screenW <= 1040 ? (
            !usageLoading ? (
              <CurrentUsageContainer />
            ) : (
              <Skeleton type="current-usage" />
            )
          ) : null}
          <>
            {addOnAs?.length > 0 && <AddOnCard addOnName="assessments" items={addOnAs} />}
            {addOnCa?.length > 0 && <AddOnCard addOnName="candidates" items={addOnCa} />}
          </>
        </div>
        {screenW > 1040 ? (
          <div className={styles.rightColumn}>
            {!usageLoading ? <CurrentUsageContainer /> : <Skeleton type="current-usage" />}
          </div>
        ) : null}
      </div>

      <InfoBanner
        text={errorText || t('errorOccured')}
        show={error}
        CustomIcon={Error}
        setStatus={type => {
          dispatch(setErrorInPlan(type));
        }}
      />
      <HeadsUpModal
        addOnCode={10}
        show={contactSalesForUpgrade}
        onHide={() => setContactSalesForUpgrade(false)}
        title={t('hello')}
        description={t('wantToContact')}
        isCustom
        setSuccess={setContactSalesSuccessShow}
      />
      <contactSalesSuccess
        icon="tick"
        show={contactSalesSuccessShow}
        onHide={() => setContactSalesSuccessShow(false)}
        title={t('requestReceived')}
        description={t('expertWillContact')}
      />
      {/* Unsubscribe Plan confirm modal */}
      <UnsubscribeModal
        show={unsubscribeShow}
        onHide={() => dispatch(setUnsubscribeModalShow(false))}
        title={t('areYouSure')}
        description={t('sureToUnsub')}
      />

      {/* Unsubscribe Plan Success Modal */}
      <PlanUnsubscribedSuccess
        show={unsubscribePlanSuccess}
        onHide={() => dispatch(setPlanUnsubscribedModalShow(false))}
      />

      {/* Abort downgrade Success Modal */}
      <UnsubscribeSuccessModal
        show={abortDowngradeSuccess}
        onHide={() => dispatch(setAbortDowngradeSuccessShow(false))}
        title={t('successCanceled')}
        footer={
          <span>
            <Trans
              i18nKey="nextBillPeriodText"
              ns="planTab"
              values={{ currentPlan: currentPlan?.name }}
            >
              0<span>1</span>
              <span>2</span>
              <span>3</span>
            </Trans>
          </span>
        }
        icon="tick"
      />
    </div>
  );
};

export default PlanTab;
