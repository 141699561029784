export default {
  ADD_POSITION_FLOW: 'addPositionFlow',
  POSITION_FLAGS: 'addPositionFlags',
  CONNECTION_FLAG: 'connectionFlag',
  ASSESSMENT_FLAG: 'assessmentFlag',

  ADD_POSITION_SELECTED_ASSESSMENT_ID: 'selectedAssessmentId',

  ADD_POSITION_COENSIO_AI_DATA: 'createAssessmentData',

  QUESTION_TYPE_MULTIPLE_CHOICE: 1,
  QUESTION_TYPE_FREE_TEXT: 3,
  QUESTION_TYPE_CODING: 2,

  SENIORITY_ENUMS: {
    10: 'Junior',
    20: 'Mid-Senior',
    30: 'Senior'
  },

  QUESTION_TYPE_ENUMS: {
    // TODO: Update below
    'Multiple Choice': 1,
    'Free Text': 2,
    Coding: 3
  },

  STAGE_CODES: {
    '-1': 'all',
    10: 'new',
    20: 'requested',
    30: 'shortlisted',
    40: 'contacted',
    50: 'interview',
    60: 'offered',
    70: 'hired',
    80: 'rejected'
  },

  LANGUAGE_LIST: [
    { label: 'EN', value: 2 },
    { label: 'TR', value: 1 }
  ],

  DIFFICULTY_ENUMS: {
    10: 'easy',
    20: 'medium',
    30: 'hard'
  },

  POSITION_DETAIL_STAGE_POPUP_TYPES: {
    RENAME: 10,
    SHARE: 20,
    ADD_ASSESSMENT: 30,
    DELETE: 40,
    ADD_STAGE: 50
  },

  UNCHANGABLE_STAGES: [-1, 10, 20, 70, 80],

  // Other = 0,
  // Email = 10, // Invite by email from Assessment detail page "send assessment link" button
  // LinkedIn = 20,
  // Kariyer = 30,
  // WelcomePageLink = 40, // welcome-candidate page
  // ApplicationLink = 50, // application page -> email -> start assessment
  // SuccessFactors = 60, // success factors
  // AlreadySolvedTransfer = 70, // apply_1 -> apply_2 -> solve_2 -> automaitcly transfer score to application1
  // NewOpsNewOpenings = 80,
  // NewOpsFewApplicants = 90,
  // NewOpsPopulars = 100,
  // RequestCandidate = 110,

  REQUEST_ARRIVE_TYPE: 110,
  REQUEST_PENDING_STATUS: 4,
  REQUEST_DECLINED_STATUS: 2,
  REQUEST_ACCEPTED_STATUS: 1,
  REQUEST_NEW_STATUS: 3,

  NEWOP_ARRIVE_TYPES: [80, 90, 100],
  NEWOP_LOCKED_STATUS: 4,
  NEWOP_UNLOCKED_STATUS: 1,

  OLD_COMPANY_POOL_ARRIVE_TYPE: 120,

  IBE_ARRIVE_TYPE: 10,

  CANDIDATE_ROW_COLOR_CODES: {
    10: '#4ac389', // green
    20: '#ffb800', // yellow
    30: '#ca1515', // red
    40: '#393939' // black - not scored
  }
};
