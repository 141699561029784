export default {
  index: 0,
  AssessmentName: '',
  funnelGroupId: null,
  switchState: 0,
  atsState: null,
  checkBoxState: [],
  userTypeOfAuthComponent: '',
  publishEnabled: false,
  rootAssmCategories: [],
  rootAssmJobRoles: [],
  rootAssmSeniorities: []
};
