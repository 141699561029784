import { createSlice } from '@reduxjs/toolkit';

import {
  comparePlansAndFeaturesAction,
  contactSalesAction,
  getAddOnsAction,
  getBillingInfoAction,
  getCountriesAction,
  getCurrentPlanAction,
  getCurrentUsageAction,
  getInvoiceListAction,
  getMyCardsAction,
  getPackageDetailAction,
  getProfileAction,
  isAuthorizedForCandidateReportAction,
  purchaseAddOnAction,
  upgradePackageAction
} from './actions';
import initialState from './initialState';

const PlanSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    setUnsubscribeAddOnInfoCount: (state, action) => {
      state.unsubscribeAddOnInfoCount = action.payload;
    },
    setUnsubscribeAddOnItemCode: (state, action) => {
      state.unsubscribeAddOnItemCode = action.payload;
    },
    setUnsubscribeModalShow: (state, action) => {
      state.unsubscribeModalShow = action.payload;
    },
    setUnsubscribeAddOnModalShow: (state, action) => {
      state.unsubscribeAddOnModalShow = action.payload;
    },
    setAbortDowngradeSuccessShow: (state, action) => {
      state.abortDowngradeSuccessShow = action.payload;
    },
    setUnsubscribeSuccessShow: (state, action) => {
      state.unsubscribeSuccessShow = action.payload;
    },
    setPurchaseModalShow: (state, action) => {
      state.purchaseModalShow = action.payload;
    },
    setPurchaseModalPackageId: (state, action) => {
      state.purchaseModalPackageId = action.payload;
    },
    setPlanUnsubscribedModalShow: (state, action) => {
      state.planUnsubscribedModalShow = action.payload;
    },
    setEditBillingShow: (state, action) => {
      state.editBillingModalShow = action.payload;
    },
    setPaymentMethodModalShow: (state, action) => {
      state.paymentMethodModalShow = action.payload;
    },
    setContactSalesModalShow: (state, action) => {
      state.contactSalesSuccess = action.payload;
    },
    setToBeCancelledAddOnType: (state, action) => {
      state.toBeCancelledAddOnType = action.payload;
    },
    setShowPackageDetail: (state, action) => {
      state.showPackageDetail = action.payload;
    },
    setDowngradeModalShow: (state, action) => {
      state.downgradeModalShow = action.payload;
    },
    setUpgradeModalShow: (state, action) => {
      state.upgradeModalShow = action.payload;
    },
    setDowngradeSuccess: (state, action) => {
      state.downgradeSuccess = action.payload;
    },
    setUpgradeSuccess: (state, action) => {
      state.upgradeSuccess = action.payload;
    },

    setEditCompanyInfoShow: (state, action) => {
      state.editCompanyInfoShow = action.payload;
    },
    setErrorInPlan: (state, action) => {
      state.errorInPlan = action.payload;
    },
    setErrorTextInPlan: (state, action) => {
      state.errorTextInPlan = action.payload;
    },
    setErrorInSeePlans: (state, action) => {
      state.errorInSeePlans = action.payload;
    },
    setErrorTextInSeePlans: (state, action) => {
      state.errorTextInSeePlans = action.payload;
    },
    setToBePurchasedAddOn: (state, action) => {
      state.toBePurchasedAddOn = action.payload;
    },
    setPurchaseSuccessShow: (state, action) => {
      state.purchaseSuccessShow = action.payload;
    },
    setPurchasedAddOnCode: (state, action) => {
      state.purchasedAddOnCode = action.payload;
    },
    setPaymentFailedMessage: (state, action) => {
      state.paymentFailedErrorMessage = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(getCurrentPlanAction.fulfilled, (state, action) => {
      state.currentPlan = action.payload;
      state.loading = false;
      // state.purchaseModalPackageId = action.payload;
    });
    builder.addCase(getCurrentPlanAction.pending, state => {
      state.loading = true;
    });
    builder.addCase(getCurrentPlanAction.rejected, state => {
      state.loading = false;
      // state.purchaseModalPackageId = null;
      state.currentPlan = null;
    });
    builder.addCase(getCurrentUsageAction.fulfilled, (state, action) => {
      state.currentUsage = action.payload;
    });
    builder.addCase(getCurrentUsageAction.pending, state => {
      state.currentUsage = null;
    });
    builder.addCase(getAddOnsAction.fulfilled, (state, action) => {
      state.addOns = action.payload;
      state.caAddOns = action.payload?.filter(x => x.addOnCode === 20); // Candidate AddOn list
      state.asAddOns = action.payload?.filter(x => x.addOnCode === 10); // Assessment AddOn list
    });
    builder.addCase(getPackageDetailAction.fulfilled, (state, action) => {
      state.packageDetail = action.payload;
    });
    builder.addCase(getPackageDetailAction.pending, state => {
      state.packageDetail = null;
    });
    builder.addCase(getBillingInfoAction.fulfilled, (state, action) => {
      state.billingInfo = action.payload;
    });
    builder.addCase(getMyCardsAction.fulfilled, (state, action) => {
      state.cardInfo = action.payload;
    });
    builder.addCase(getCountriesAction.fulfilled, (state, action) => {
      state.countries = action.payload;
    });
    builder.addCase(comparePlansAndFeaturesAction.fulfilled, (state, action) => {
      state.plansDetailJSON = action.payload;
    });
    builder.addCase(contactSalesAction.fulfilled, state => {
      state.contactSalesSuccess = true;
    });
    builder.addCase(contactSalesAction.rejected, state => {
      state.contactSalesSuccess = true;
    });
    builder.addCase(getProfileAction.fulfilled, (state, action) => {
      state.profileObj = action.payload;
    });
    builder.addCase(purchaseAddOnAction.rejected, (state, action) => {
      state.paymentFailedErrorMessage = action.payload?.message;
    });
    builder.addCase(upgradePackageAction.rejected, (state, action) => {
      state.paymentFailedErrorMessage = action.payload?.message;
    });
    builder.addCase(isAuthorizedForCandidateReportAction.fulfilled, (state, action) => {
      state.isAuthorizedCR = action.payload;
    });
    builder.addCase(getInvoiceListAction.fulfilled, (state, action) => {
      state.invoiceList = action.payload;
    });
  }
});

export const {
  setUnsubscribeModalShow,
  setUnsubscribeAddOnModalShow,
  setUnsubscribeSuccessShow,
  setPurchaseModalShow,
  setPurchaseModalPackageId,
  setPlanUnsubscribedModalShow,
  setEditBillingShow,
  setPaymentMethodModalShow,
  setContactSalesModalShow,
  setToBeCancelledAddOnType,
  setShowPackageDetail,
  setDowngradeModalShow,
  setUpgradeModalShow,
  setDowngradeSuccess,
  setUpgradeSuccess,
  setEditCompanyInfoShow,
  setUnsubscribeAddOnInfoCount,
  setUnsubscribeAddOnItemCode,
  setErrorInPlan,
  setErrorTextInPlan,
  setErrorInSeePlans,
  setErrorTextInSeePlans,
  setAbortDowngradeSuccessShow,
  setToBePurchasedAddOn,
  setPurchaseSuccessShow,
  setPurchasedAddOnCode,
  setPaymentFailedMessage
} = PlanSlice.actions;

export default PlanSlice.reducer;
