import React from 'react';

import { useTranslation } from 'react-i18next';

import styles from './QuestionBar.module.css';

const QuestionBar = () => {
  const { t } = useTranslation(['candidateDetail'])
  return (
    <div className={styles.BarWrapper}>
      <text className={`${styles.noWrapper}`}>No</text>
      <text className={`${styles.headerTextFlexGrow}`}>{t('questionTitle')}</text>
      <text className={`${styles.label}`}>{t('tabSwitch')}</text>
      <text className={`${styles.label}`}>
        {t('timeTaken')}
      </text>
      <text className={`${styles.label}`}>{t('score')}</text>
    </div>
  );
};
export default QuestionBar;
