import { createAsyncThunk } from '@reduxjs/toolkit';
import { confirmDeletingAssessmentRecordService, getCandidateAssessmentRecordService, getConnectedAssessmentCountService } from './service';

export const getCandidateAssessmentRecordAction = createAsyncThunk(
  'getCandidateAssessmentRecord',
  async (data, thunkAPI) => {
    try {
      const resp = await getCandidateAssessmentRecordService(data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getConnectedAssessmentCountAction = createAsyncThunk(
  'getConnectedAssessmentCount',
  async (data, thunkAPI) => {
    try {
      const resp = await getConnectedAssessmentCountService(data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const confirmDeletingAssessmentRecordAction = createAsyncThunk(
  'confirmDeletingAssessmentRecord',
  async (data, thunkAPI) => {
    try {
      const resp = await confirmDeletingAssessmentRecordService(data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
