import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import LeavingPageModal from '../../Assessment/AddAssessment/Modals/LeavingPageModal';
import { setDeletedId } from '../../redux/assessmentsVol2/slice';

export default function ConfimationPopup({
  when,
  pathname,
  navigate,
  shouldBlockNavigation,
  deleteModal,
  confirmedNavigation,
  setConfirmedNavigation
}) {
  // Use anywhere you want to ask for user confimation for leavign that page
  const [modalVisible, setModalVisible] = useState(false);
  const [modalClosedState, setModalClosedState] = useState(null);
  const [lastLocation, setLastLocation] = useState(null);
  const [action, setAction] = useState('POP');
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nexLocation, action) => {
    if (!confirmedNavigation && shouldBlockNavigation(nexLocation)) {
      setModalVisible(true);
      setAction(action);
      setLastLocation(nexLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  /* useEffect(() => {
     if (modalVisible && history.location !== modalClosedState && action === 'POP') {
       history.goForward();
     } else if (!modalVisible && !confirmedNavigation) {
       setModalClosedState(history.location);
     }
   }, [modalVisible]); */

  useEffect(() => {
    if (!modalVisible) {
      if (!confirmedNavigation) {
        if (window.location.pathname !== pathname) {
          history.goForward();
        }
      }
    }
  }, [modalVisible]);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      if (window.location.pathname === pathname) {
        if (action === 'PUSH') {
          navigate(lastLocation.pathname);
        }
        if (action === 'POP') {
          history.goBack();
        }
        if (action === 'REPLACE') {
          history.replace(lastLocation.pathname)
        }
      } else {
        history.replace(window.location.pathname);
      }
    }
  }, [confirmedNavigation, lastLocation]);
  return (
    <>
      <Prompt message={handleBlockedNavigation} when={when} />
      <LeavingPageModal
        show={modalVisible}
        cancelClick={() => {
          deleteModal(true);
          dispatch(setDeletedId(id));
          setModalVisible(false);
        }}
        onHide={() => {
          setModalVisible(false);
        }}
        confirmClick={handleConfirmNavigationClick}
      />
    </>
  );
}
