import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { logoutAndRedirect } from '../../utils/helpers';
import styles from '../Styles/MobileHeaderComponent.module.css';
import CloseButton from './CloseButton';
import Menu from '../Images/headerMenu.svg';
import logout from '../Images/logout.svg';
import { LogoPath } from '../../redux/logo/selectors';

import CircleLogo from '../Images/circleLogo.svg';
import route from '../../constant/route';
import CurrentUsageContent from '../ProfileBanner/CurrentUsage/CurrentUsageContent';
import settingsIcon from '../../images/Common/mobileSettingsIcon.svg';

function MobileHeaderComponent() {
  const [isMenuOpen, openMenu] = useState(false);

  const { t } = useTranslation(['profileBanner'])

  const userLogo = useSelector(LogoPath);
  const history = useHistory();

  return (
    <div className={styles.mobileWrapper}>
      {/* Only available in mobile devices */}
      <div className={styles.headerContainer}>
        <NavLink to={route.assessments}>
          <img src={CircleLogo} alt="" />
        </NavLink>
        <div style={{ padding: '6px 0px' }}>
          {isMenuOpen ? (
            <CloseButton onClick={() => openMenu(false)} width={38} height={38} />
          ) : (
            <img
              src={Menu}
              alt=""
              onClick={() => {
                openMenu(true);
              }}
            />
          )}
        </div>
      </div>
      <div className={`${styles.headerMenu} ${isMenuOpen && styles.headerOpen}`}>
        {userLogo ? (
          <div className={styles.logoContainer}>
            <span className={styles.title}>{t('company')}</span>
            <img
              src={userLogo}
              style={{
                maxHeight: '28px',
                maxWidth: '105px',
                objectFit: 'contain',
                alignSelf: 'baseline'
              }}
              alt="logoMobileInMenu"
            />
          </div>
        ) : null}

        <div className={styles.usageContainer}>
          <CurrentUsageContent />
        </div>

        <div
          className={styles.settingsButtonWrapper}
          onClick={() => {
            history.push('/settings/myprofile');
            openMenu(false);
          }}
        >
          <img src={settingsIcon} alt="settings" />
          <text>{t('settings')}</text>
        </div>

        <div className={styles.logoutContainer}>
          <div
            className={styles.logoutRow}
            onClick={() => {
              logoutAndRedirect();
            }}
          >
            <img alt="logoutInMobileHeader" src={logout} />
            <div className={styles.logoutText}>{t('logout')}</div>
          </div>
        </div>
      </div>
      {isMenuOpen ? (
        <div
          onClick={() => openMenu(false)}
          style={{
            color: 'rgba(0, 0, 0, 0.25)',
            minWidth: 'calc(100% + 40px)',
            marginLeft: '-20px',
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            overflow: 'hidden'
          }}
        />
      ) : null}
    </div>
  );
}

export default MobileHeaderComponent;
