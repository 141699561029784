import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './SalesMain.module.css';
import { setCustomBundleId } from '../../../redux/salesScreen/slice';
import CustomButton from '../../../Common/Components/CustomButton';

const SalesMain = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  // Cleanup
  useEffect(() => {
    // Create new template
    dispatch(setCustomBundleId(''));
  }, []);

  return (
    <div className={styles.contentBody}>
      <CustomButton
        type={5}
        textField="Create New Company"
        customStyle={{ width: '20%', minWidth: '300px' }}
        buttonOnClick={() => {
          history.push(`/nRdDOsdIFdwEdfdsaSd/1`);
        }}
      />

      <CustomButton
        type={5}
        textField="Update Existing Company"
        customStyle={{ width: '20%', minWidth: '300px' }}
        buttonOnClick={() => {
          history.push(`/nRdDOsdIFdwEdfdsaSd/2`);
        }}
      />

      <CustomButton
        type={5}
        textField="Create New Package Template"
        customStyle={{ width: '20%', minWidth: '300px' }}
        buttonOnClick={() => {
          history.push(`/nRdDOsdIFdwEdfdsaSd/3`);
        }}
      />
    </div>
  );
};

export default SalesMain;
