import React, { useEffect, useRef, useState } from 'react';
import MaskedInput from 'react-text-mask';

import { useTranslation } from 'react-i18next';

import styles from '../Styles/CardInput.module.css';

const ccMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

const lateMonthMask = [/\d/, /\d/, ' ', '/', ' ', /\d/, /\d/];
const earlyMonthMask = ['0', /\d/, ' ', '/', ' ', /\d/, /\d/];

const cscMask = [/\d/, /\d/, /\d/];

export default function CardInput({
  no,
  cvc,
  mmyy,
  setNo = () => {},
  setMmyy = () => {},
  setCvc = () => {}
}) {
  const ccNumberRef = useRef(undefined);
  const ccExpRef = useRef(null);
  const ccCSCRef = useRef(null);
  const [isEarlyMonth, setIsLowMonth] = useState(false);
  const [othersActive, setOthersActive] = useState(false);

  const { t } = useTranslation(['banner'])

  const [cardNo, setCardNo] = useState(no);
  const [cardCvc, setCardCvc] = useState(cvc);
  const [cardMmyy, setCardMmyy] = useState(mmyy);
  const NO_MAX_LENGTH = 19;
  const MMYY_MAX_LENGTH = 7;

  function useOutsideAlerter(ref, ref2) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (!ref?.current?.contains(event.target) && !ref2?.current?.contains(event.target)) {
          setOthersActive(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, ref2]);
  }

  useOutsideAlerter(ccExpRef, ccCSCRef);

  useEffect(() => {
    if (cardNo?.length === NO_MAX_LENGTH) {
      document?.getElementById('ccExpRef')?.focus();
    }
  }, [cardNo]);

  useEffect(() => {
    if (cardMmyy?.length === MMYY_MAX_LENGTH) {
      document?.getElementById('ccCSCRef')?.focus();
    }
  }, [cardMmyy]);

  return (
    <div className={styles.container}>
      <div className={`${styles['credit-card']}`}>
        <MaskedInput
          onClick={() => setOthersActive(false)}
          itemRef={ccNumberRef}
          value={cardNo}
          autocomplete="cc-number"
          autocorrect="off"
          spellcheck="false"
          name="cardnumber"
          inputMode="numeric"
          guide={false}
          className={`${styles.input} ${styles['cc-number']} ${no && styles.filledLabel}`}
          mask={ccMask}
          onChange={event => {
            setCardNo(event.target.value);
            setNo(event.target.value);
          }}
        />
        <label className={`${styles.noText} ${no && styles.filled}`}>
          {othersActive ? t('cardInfo') : t('cardNo')}
        </label>

        <MaskedInput
          id="ccExpRef"
          onClick={() => setOthersActive(true)}
          itemRef={ccExpRef}
          value={cardMmyy}
          autocomplete="cc-exp"
          autocorrect="off"
          spellcheck="false"
          name="exp-date"
          inputMode="numeric"
          guide={false}
          placeholder="MM / YY"
          className={`${styles.input} ${styles['cc-exp']}`}
          mask={isEarlyMonth ? earlyMonthMask : lateMonthMask}
          onChange={event => {
            setCardMmyy(event.target.value);
            setMmyy(event.target.value);
          }}
        />
        <MaskedInput
          id="ccCSCRef"
          onClick={() => setOthersActive(true)}
          itemRef={ccCSCRef}
          value={cardCvc}
          autocomplete="cc-csc"
          autocorrect="off"
          spellcheck="false"
          name="cvc"
          inputMode="numeric"
          guide={false}
          placeholder="CVC"
          className={`${styles.input} ${styles['cc-csc']}`}
          mask={cscMask}
          onChange={event => {
            setCardCvc(event.target.value);
            setCvc(event.target.value);
          }}
        />
      </div>
    </div>
  );
}
