import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useIp } from '../hooks/useIp';

export default function useLocalizationByIp() {
  const [locationInfo] = useIp();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (!locationInfo) {
      i18n.changeLanguage('en');
      return;
    }

    if (locationInfo?.country === 'TR') {
      i18n.changeLanguage('tr');
      return;
    }

    i18n.changeLanguage('en');
  }, [locationInfo]);
}
