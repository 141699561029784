import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { setScreeningNationalityIds } from '../../../../redux/AssessmentSession/slice';
import styles from './ScreeningCardComponent.module.css';
import MultiSelect from '../../../../Common/Components/MultiSelect';

const WorkPermitQuestion = ({ content, setLocationState }) => {
  const { t } = useTranslation(['application']);
  const dispatch = useDispatch();

  const { currentNationality: currentNationalityList, nationalities: nationalityList } =
    content || {};
  const [selectedList, setSelectedList] = useState([]);

  useEffect(() => {
    setSelectedList(currentNationalityList);
  }, [currentNationalityList]);

  useEffect(() => {
    dispatch(setScreeningNationalityIds(selectedList));
  }, [selectedList]);

  // If Work permit question is showed, location question is not showed to the user,
  // so set it to pre-existing value here
  useEffect(() => {
    setLocationState();
  }, []);

  return (
    <div className={styles.questionWrapper}>
      <div className={styles.question}>{t('workPermitQuestionText')}</div>
      <div className={styles.answer}>
        <MultiSelect
          placeholder={t('workPermitDropdownPlaceholder')}
          options={nationalityList}
          selectedOptions={selectedList}
          setSelectedOptions={setSelectedList}
          isObject
          displayValue="name"
          returnedProperty="id"
          listBadgesReverse
        />
      </div>
    </div>
  );
};

WorkPermitQuestion.propTypes = {
  content: PropTypes.object.isRequired,
  setLocationState: PropTypes.func.isRequired
};

export default WorkPermitQuestion;
