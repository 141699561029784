const authToken = localStorage.getItem('authToken');
const authTokenVol2 = localStorage.getItem('authTokenVol2');
const user = localStorage.getItem('user');

export default {
  loading: false,
  token: authToken,
  token2: authTokenVol2,
  user: user ? (user !== 'undefined' ? JSON.parse(user) : null) : null,
  isLogged: !!authToken && !!authTokenVol2,
  reason: null,
  companyInfo: { companyId: '', companyInformation: '', companyLogoUrl: '', companyName: '' },
  team: null,
  errorInTeam: false,
  errorMessageInTeam: '',
  successInTeam: false,
  successMessageInTeam: '',
  settingsPageErrorMessage: '',
  settingsPageErrorShow: false,
  settingsPageSuccessMessage: '',
  settingsPageSuccessShow: false,

  loginPageErrorFlag: null,
  loginPageErrorMessage: null,
  upgradePrices: {},

  locationData: null,
};
