import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import constants from '../../../../utils/constants';
import CustomButton from '../../../../Common/Components/CustomButton';
import { updateCustomFunnelGroupAction } from '../../../Api/actions';

import DisabledFunnelWrapper from './DisabledFunnelWrapper';
import DraggableFunnelWrapper from './DraggableFunnelWrapper';

export default function EditableFunnelList({
  activeFunnelInformation,
  setFunnelGroupList,
  setActiveFunnelInformation,
  setEditState
}) {
  const [funnelList, setFunnelList] = useState();
  const isNonModified =
    activeFunnelInformation?.funnels?.length === funnelList?.length &&
    funnelList?.every(
      (x, i) =>
        x?.customFunnelName === activeFunnelInformation?.funnels[i]?.customFunnelName &&
        x?.priority === activeFunnelInformation?.funnels[i]?.priority
    );

  async function updateCustomFunnelGroup() {
    const resp = await updateCustomFunnelGroupAction({
      funnelGroupId: activeFunnelInformation?.funnelGroupId,
      funnelList: funnelList
    });

    if (resp?.status === 200) {
      const funnelGroupList = resp?.data;
      setFunnelGroupList(funnelGroupList);
      setActiveFunnelInformation(
        funnelGroupList?.filter(
          x => x?.funnelGroupName === activeFunnelInformation?.funnelGroupName
        )[0]
      );
    }
  }

  const confirmClick = async () => {
    if (!isNonModified) {
      await updateCustomFunnelGroup();
    }
  };

  return (
    <>
      <div
        className="FlexColumn overflowAuto gap-10"
        style={{ padding: '14px 20px 20px 10px', position: 'relative' }}
      >
        <DisabledFunnelWrapper funnelList={constants?.HIRING_FUNNEL_LIST} borderBottomFlag />
        <DisabledFunnelWrapper funnelList={constants?.NEW_LIST} borderBottomFlag />
        <DraggableFunnelWrapper
          funnelList={activeFunnelInformation?.funnels}
          updateParentFunnelList={setFunnelList}
        />
        <DisabledFunnelWrapper funnelList={constants?.CONCLUDED_LIST} borderTopFlag />
      </div>
      <ButtonWrapper
        confirmClick={confirmClick}
        cancelClick={() => setEditState(false)}
        confirmDisabledState={isNonModified}
      />
    </>
  );
}

const ButtonWrapper = ({ confirmClick, cancelClick, confirmDisabledState }) => {
  const [buttonLoading, setButtonLoading] = useState(false);

  const innerConfirmClick = async () => {
    setButtonLoading(true);
    await confirmClick();
    setButtonLoading(false);
  };

  const { t } = useTranslation(['positionTab'])

  return (
    <div
      className="FlexRow gap-10 w-100"
      style={{
        marginTop: 'auto',
        justifyContent: 'flex-end',
        padding: '10px 20px',
        position: 'sticky',
        bottom: 0,
        boxShadow: '0px -1px 0px 0px var(--gray-1, rgba(244, 244, 244, 1))'
      }}
    >
      <CustomButton textField={t('cancel')} type={2} buttonOnClick={cancelClick} />
      <CustomButton
        textField={t('confirm')}
        loading={buttonLoading}
        buttonOnClick={innerConfirmClick}
        isDisabled={confirmDisabledState}
      />
    </div>
  );
};
