import { useEffect, useState } from 'react';
import { getUserAutoApplyAction } from '../Api/actions';

export default function useGetUserAutoApply(applicationId, assessmentId, candidateEmail) {
  const [autoApplyLoading, setAutoApplyLoading] = useState(true);
  const [autoApplyStatus, setAutoApplyStatus] = useState(undefined);

  useEffect(() => {
    async function fetchUserApplyStatus() {
      const data = {
        Email: candidateEmail,
        CompanyAssessmentKey: assessmentId || applicationId
      };
      const resp = await getUserAutoApplyAction(data);
      if (resp?.status === 200) {
        const respData = resp?.data;
        setAutoApplyStatus(respData?.status);
      }
      setAutoApplyLoading(false);
    }

    if (candidateEmail && (applicationId || assessmentId)) {
      setAutoApplyLoading(true);
      fetchUserApplyStatus();
    }
  }, [applicationId, assessmentId, candidateEmail]);

  return {autoApplyStatus, autoApplyLoading};
}
