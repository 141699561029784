import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import styled from 'styled-components';
import { ASSESSMENT_FLOW_MOBILE_THRESHOLD } from '../../../utils/helpers';

import CoensioLogo from './Coensio_expiredLogo.svg';
import oopsImage from './expiredLinkOopsImg.svg';
import styles from './ExpiredLinkComponent.module.css';
import CustomButton from '../../../Common/Components/CustomButton';
import { setForgotPasswordEmail } from '../../../redux/signup/slice';
import { forgotPasswordAction } from '../../../redux/signup/actions';
import { useInterval } from '../../../utils/useInterval';
import InfoBanner from '../../../Common/Components/InfoBanner';
import { ReactComponent as Error } from '../../../images/Common/errorSubmit.svg';

const ExpiredLinkComponent = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const email = new URLSearchParams(search).get('email');

  const [step, setStep] = useState(1);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onClickSendEmail = async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return false;
    }
    const token = await executeRecaptcha('forgotPassword');
    await dispatch(setForgotPasswordEmail(email));
    const data = {
      email,
      reCaptchaToken: token
    };
    const resp = await dispatch(forgotPasswordAction(data));

    if (resp?.meta?.requestStatus === 'fulfilled') {
      setStep(2);
      return true;
    }
    return false;
  };

  return (
    <PageWrapper>
      <CardWrapper>
        <BigCard>
          <InnerCard>
            <div className={`${styles.Panel} ${styles.Left}`}>
              <div className={styles.logoWrapper}>
                <img className={styles.companyLogo} src={CoensioLogo} alt="coensioLogo" />
              </div>
              <div className={styles.imageWrapper}>
                <img className={styles.oopsImg} src={oopsImage} alt="oops" />
              </div>
            </div>
            <div className={`${styles.Panel} ${styles.Right}`}>
              {step === 1 ? (
                <ExpiredInfo onClickSend={onClickSendEmail} />
              ) : (
                <ExpiredResent onClickSend={onClickSendEmail} />
              )}
            </div>
          </InnerCard>
        </BigCard>
      </CardWrapper>
    </PageWrapper>
  );
};
export default ExpiredLinkComponent;

const ExpiredInfo = ({ ...props }) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isErrorOccurred, setIsErrorOccurred] = useState(false);

  const onClick = async () => {
    setIsErrorOccurred(false);
    setButtonLoading(true);
    const bool = await props.onClickSend();
    setButtonLoading(false);
    if (!bool) {
      setIsErrorOccurred(true);
    }
  };

  return (
    <>
      <div className={styles.Header}>This link has been expired!</div>
      <div className={styles.Subtext}>
        Seems like you need to have a new link to reset your password.
      </div>
      <CustomButton
        textField="Send Email"
        customStyle={{ width: '100%' }}
        buttonOnClick={onClick}
        loading={buttonLoading}
      />
      <InfoBanner
        setStatus={setIsErrorOccurred}
        show={isErrorOccurred}
        text="An error occurred"
        CustomIcon={Error}
        bannerStyle={{ backgroundColor: 'white', border: '1px solid rgba(221, 58, 58, 0.1)' }}
      />
    </>
  );
};

const ExpiredResent = ({ ...props }) => {
  const VERIF_MAIL_INTERVAL = 60;
  const [calculateTime, setCalculateTime] = useState(true);
  const [showTime, setShowTime] = useState(true);
  const [timer, setTimer] = useState('');
  const [isDisabled, setDisabled] = useState(true);
  const [resendClicked, setResendClicked] = useState(false);

  useInterval(() => {
    if (calculateTime) {
      const initDate = localStorage.getItem('forgotPasswordMailSent');
      const msecs = new Date() - new Date(initDate);
      const remaining = VERIF_MAIL_INTERVAL - Math.ceil(msecs / 1000);
      setTimer('0:' + (remaining < 10 ? '0' : '') + String(remaining));
      if (remaining <= 0) {
        setCalculateTime(false);
        setDisabled(false);
        setTimer('');
        setShowTime(false);
      }
    }
  }, 500);

  const onClick = async () => {
    if (!isDisabled) {
      setDisabled(true);
      await props.onClickSend();
      setResendClicked(true);
      setShowTime(true);
      setCalculateTime(true);
    }
  };

  return (
    <>
      <div className={styles.Header}>We have sent you an email!</div>
      <div className={`${styles.Subtext} ${styles.Subtext2}`}>
        Please check your inbox to reset your password.
      </div>
      <text className={styles.ResendText}>
        Didn’t receive an email? Check your spam box or
        <text
          onClick={onClick}
          className={styles.link}
          style={{
            pointerEvents: isDisabled ? 'none' : 'inherit',
            opacity: isDisabled ? '0.4' : '1'
          }}
        >
          &nbsp;resend email.&nbsp;
        </text>
        {showTime && <label className={styles.remaining}> Time remaining: {timer}</label>}
      </text>
      <InfoBanner
        setStatus={setResendClicked}
        show={resendClicked}
        text="Mail Resent"
        textColor="#4AC389"
      />
    </>
  );
};

const PageWrapper = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;
  flex-direction: column;
  position: relative;
  justify-content: center;
  height: 100%;
  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    background-color: white;
    justify-content: space-between;
  }
`;

const CardWrapper = styled.div`
  width: 100%;
  height: 410px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    justify-content: flex-start;
    padding: 2.4rem;
    padding-top: 28px;
    height: fit-content;
  }
`;

const BigCard = styled.div`
  max-width: 1000px; // Margin should be bigger if there screen is bigger
  min-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px; // If less change to mobile version
  background: #ffffff;
  display: flex;
  margin-left: 2rem;
  width: 60%; // To try the continuous scaling
  margin-right: 2rem;
  flex-direction: column;
  padding: 3.2rem;
  justify-content: center;
  box-shadow: 0px 1px 24px rgba(131, 131, 131, 0.1);

  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    width: 100%;
    max-width: none; // Bunlar nasıl daha iyi yapılır
    max-height: none;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    min-width: 0;
    box-shadow: none;
  }
`;

const InnerCard = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
`;
