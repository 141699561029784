import React, { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import addNewFunnelGroup from '../../images/Common/BackgroundImage/noCustomAssessment.svg';
import CustomButton from '../../Common/Components/CustomButton';
import FunnelGroupTitle from '../Components/Settings/PositionTab/FunnelGroupTitle';
import NonEditableFunnelList from '../Components/Settings/PositionTab/NonEditableFunnelList';
import EditableFunnelList from '../Components/Settings/PositionTab/EditableFunnelList';
import PlusIcon from '../../images/Auth/Settings/PlusIcon';


export default function useCustomFunnelRightColumnHandler(
  activeFunnelInformation,
  setShowModalType,
  setFunnelGroupList,
  setActiveFunnelInformation
) {
  const { funnelGroupId, funnelGroupName, funnels } = activeFunnelInformation || {};

  const [editState, setEditState] = useState(false);

  useEffect(() => {
    setEditState(false);
  }, [activeFunnelInformation]);

  const FunnelNonEditableList = () => {
    return (
      <div className="FlexColumn h-100">
        <FunnelGroupTitle
          title={funnelGroupName}
          isEditable={editState}
          setShowModalType={setShowModalType}
          setEditState={setEditState}
        />
        <NonEditableFunnelList activeFunnelInformation={activeFunnelInformation} />
      </div>
    );
  };

  const FunnelEditableList = () => {
    return (
      <div className="FlexColumn h-100">
        <FunnelGroupTitle
          title={funnelGroupName}
          isEditable={editState}
          setShowModalType={setShowModalType}
          setEditState={setEditState}
        />
        <EditableFunnelList
          activeFunnelInformation={activeFunnelInformation}
          setActiveFunnelInformation={setActiveFunnelInformation}
          setFunnelGroupList={setFunnelGroupList}
          setEditState={setEditState}
        />
      </div>
    );
  };

  const NewGroupContent = useCallback(() => {
    const { t } = useTranslation(['positionTab'])
    return (
      <div
        className="FlexColumn gap-32 w-100 verticallyCenter centerText c-darkGray"
        style={{ padding: '40px 20px' }}
      >
        <img
          src={addNewFunnelGroup}
          style={{ maxWidth: '60%', minWidth: '150px' }}
          alt="Add New Funnel Group"
        />
        <span className="fw-500 font-18">{t('addNewTitle')}</span>
        <span className="fw-400 font-16">{t('addNewText')}</span>
        <CustomButton
          textField={t('addButton')}
          type={5}
          size="small"
          Icon={PlusIcon}
          buttonOnClick={() => setShowModalType(1)}
        />
      </div>
    );
  }, []);

  const FunnelList = useCallback(() => {
    if (!activeFunnelInformation) return <NewGroupContent />;
    if (editState) return <FunnelEditableList />;
    return <FunnelNonEditableList />;
  }, [activeFunnelInformation, editState]);

  return { FunnelList };
}
