import Dropdown from 'react-dropdown';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../Styles/QuestionDetail.module.css';
import {
  assessmentLabelRedux,
  categoryRedux,
  difficultyRedux,
  languageRedux,
  limitRedux,
  statusRedux,
  subcategoryRedux,
  titleRedux
} from '../../../redux/addQuestion/selectors';
import {
  setAssessmentLabelRedux,
  setCategoryRedux,
  setDifficultyRedux,
  setLanguageRedux,
  setLimitRedux,
  setStatusRedux,
  setSubcategoryRedux,
  setTitleRedux
} from '../../../redux/addQuestion/slice';
import {
  getCreateQuestionAssessmentLabelDropdownAction,
  getCreateQuestionDropdownAction,
  getCreateQuestionSubcategoryDropdownAction
} from '../../../redux/addQuestion/actions';
import route from '../../../constant/route';

function QuestionDetails({ detailChange }) {
  const dispatch = useDispatch();

  const [isCoding, setIsCoding] = useState(false);

  useEffect(() => {
    const path = window.location.pathname;
    if (path === route.sqlQuestion || path === route.codingQuestion) {
      setIsCoding(true);
    }
  }, []);

  // Selectors
  const reduxCategory = useSelector(categoryRedux);
  const reduxAssessmentLabel = useSelector(assessmentLabelRedux);
  const reduxSubcategory = useSelector(subcategoryRedux);
  const reduxTitle = useSelector(titleRedux);
  const reduxDifficulty = useSelector(difficultyRedux);
  const reduxLanguage = useSelector(languageRedux);
  const reduxLimit = useSelector(limitRedux);
  const reduxStatus = useSelector(statusRedux);

  const [categoryList, setCategoryList] = useState([]);
  const [subcategoryList, setSubcategoryList] = useState([]);
  const [difficultyList, setDifficultyList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [limitList, setLimitList] = useState([]);

  async function fetchDropdowns() {
    const resp = await dispatch(getCreateQuestionDropdownAction());
    setCategoryList(resp.payload.categoryList);
    setDifficultyList(resp.payload.difficultyList);
    setLanguageList(resp.payload.languageList);
    setLimitList(resp.payload.timeList);
  }

  async function fetchSubcategoryDropdown() {
    const rawData = {
      Category: reduxCategory
    };
    const resp = await dispatch(getCreateQuestionSubcategoryDropdownAction(rawData));
    setSubcategoryList(resp.payload.subcategoryList);
  }

  async function fetchAssessmentListDropdown() {
    const rawData = {
      Category: reduxCategory,
      SubCategory: reduxSubcategory
    };
    const resp = await dispatch(getCreateQuestionAssessmentLabelDropdownAction(rawData));
    dispatch(setAssessmentLabelRedux(resp.payload.assessmentLabel));
  }

  useEffect(() => {
    fetchDropdowns();
  }, []);

  useEffect(() => {
    if (reduxCategory && !isCoding) {
      fetchSubcategoryDropdown();
    }
  }, [reduxCategory]);

  useEffect(() => {
    if (reduxSubcategory && !isCoding) {
      fetchAssessmentListDropdown();
    }
  }, [reduxSubcategory]);

  return (
    <div className={styles.QuestionDetailsWrapper}>
      <p>
        <b>Question Details</b>
      </p>
      <div className={`${styles.FormInput}`}>
        <div className={styles.IndividiualRow}>
          <div className={styles.NameWrapper}>Title</div>
          <div className={styles.DropdownWrapper}>
            <input
              placeholder="Question Title"
              type="text"
              onChange={val => {
                dispatch(setTitleRedux(val.target.value));
                detailChange(1);
              }}
              value={reduxTitle}
              style={{ width: '100%', height: '90%', paddingLeft: '9px' }}
            />
          </div>
        </div>

        <div className={styles.IndividiualRow}>
          <div className={styles.NameWrapper}>Specify the time limit (seconds)</div>
          <div
            className={styles.DropdownWrapper}
            style={limitList.length === 0 ? { opacity: '0.4' } : {}}
          >
            <Dropdown
              className="limit"
              options={limitList}
              onChange={val => {
                dispatch(setLimitRedux(val.value));
                detailChange(1);
              }}
              value={reduxLimit.toString()}
              disabled={limitList.length === 0}
            />
          </div>
        </div>

        <div className={styles.IndividiualRow}>
          <div className={styles.NameWrapper}>Category</div>
          <div
            className={styles.DropdownWrapper}
            style={categoryList.length === 0 ? { opacity: '0.4' } : {}}
          >
            <Dropdown
              className="category"
              options={categoryList}
              onChange={val => {
                dispatch(setCategoryRedux(val.value));
                dispatch(setSubcategoryRedux(''));
                setSubcategoryList([]);
                dispatch(setAssessmentLabelRedux(''));
                detailChange(1);
              }}
              value={reduxCategory}
              disabled={categoryList.length === 0}
            />
          </div>
        </div>

        <div className={styles.IndividiualRow} style={{ display: isCoding && 'none' }}>
          <div className={styles.NameWrapper}>Sub-category</div>
          <div
            className={styles.DropdownWrapper}
            style={subcategoryList.length === 0 ? { opacity: '0.4' } : {}}
          >
            <Dropdown
              className="subcategory"
              options={subcategoryList}
              onChange={val => {
                dispatch(setSubcategoryRedux(val.value));
                dispatch(setAssessmentLabelRedux(''));
                detailChange(1);
              }}
              value={reduxSubcategory}
              disabled={subcategoryList.length === 0}
            />
          </div>
        </div>

        <div className={styles.IndividiualRow} style={{ display: isCoding && 'none' }}>
          <div className={styles.NameWrapper}>Assessment Label</div>
          <div
            className={styles.DropdownWrapper}
            style={reduxAssessmentLabel ? {} : { opacity: 0.4 }}
          >
            <input
              placeholder="Assessment Label"
              type="text"
              disabled
              value={reduxAssessmentLabel}
              style={{ width: '100%', height: '90%', paddingLeft: '9px' }}
            />
          </div>
        </div>

        <div className={styles.IndividiualRow}>
          <div className={styles.NameWrapper}>Difficulty Level</div>
          <div
            className={styles.DropdownWrapper}
            style={difficultyList.length === 0 ? { opacity: '0.4' } : {}}
          >
            <Dropdown
              className="difficulty"
              options={difficultyList}
              onChange={val => {
                dispatch(setDifficultyRedux(val.value));
                detailChange(1);
              }}
              value={reduxDifficulty}
              disabled={difficultyList.length === 0}
            />
          </div>
        </div>

        <div className={styles.IndividiualRow}>
          <div className={styles.NameWrapper}>Question Language</div>
          <div
            className={styles.DropdownWrapper}
            style={languageList.length === 0 ? { opacity: '0.4' } : {}}
          >
            <Dropdown
              className="language"
              options={languageList}
              onChange={val => {
                dispatch(setLanguageRedux(val.value));
                detailChange(1);
              }}
              value={reduxLanguage}
              disabled={languageList.length === 0}
            />
          </div>
        </div>

        <div className={styles.IndividiualRow}>
          <div className={styles.NameWrapper}>Status</div>
          <div className={styles.RadioButtonWrapper}>
            <div className={styles.IndividualRadioButton}>
              <input
                style={{ marginRight: '5px' }}
                type="radio"
                value="Active"
                name="status"
                checked={reduxStatus}
                onChange={() => dispatch(setStatusRedux(true))}
              />{' '}
              Active
            </div>
            <div className={styles.IndividualRadioButton}>
              <input
                style={{ marginRight: '5px' }}
                type="radio"
                value="Passive"
                name="status"
                checked={!reduxStatus}
                onChange={() => dispatch(setStatusRedux(false))}
              />{' '}
              Passive
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default QuestionDetails;
