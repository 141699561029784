import React, { useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import styles from './RejectionMailTextEditor.module.css';
import { imageSaveAction } from '../../../../../Common/Api/actions';

const NewRejectionMailTextEditor = ({ setStyle = {}, sampleMail, passMailToParent }) => {
  const [latestMailContent, setLatestMailContent] = useState();
  const [changeAllowed, setChangeAllowed] = useState(false);
  const [editorState, setEditorState] = useState(sampleMail?.emailContent);

  useEffect(() => {
    if (sampleMail) {
      setEditorState(sampleMail?.emailContent);
      setLatestMailContent(sampleMail?.emailContent);
    }
  }, [sampleMail]);

  const handleEditorChange = state => {
    setEditorState(state);
    if (latestMailContent !== state) {
      passMailToParent(state);
    }
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then(file => {
            const body = new FormData();
            body.append('file', file);
            body.append('directoryName', 'rejectionMail');
            imageSaveAction(body)
              .then(resp => {
                if (resp?.status === 200) {
                  const path = `${process.env.REACT_APP_IMAGE_API}${resp?.data?.imageUrl}`;
                  resolve({ default: path });
                }
              })
              .catch(reject);
          });
        });
      }
    };
  }

  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      return uploadAdapter(loader);
    };
  }

  useEffect(() => {
    console.log('changeAllowed', changeAllowed);
  }, [changeAllowed]);

  return (
    <div
      className={styles.RejectionMailTextEditorWrapper}
      style={setStyle}
      focus={JSON.stringify(changeAllowed)}
    >
      <CKEditor
        editor={Editor}
        data={editorState}
        className={styles.CKEditor}
        config={{
          extraPlugins: [MyCustomUploadAdapterPlugin]
        }}
        onChange={(event, editor) => {
          if (changeAllowed) {
            const data = editor.getData();
            handleEditorChange(data);
          }
        }}
        onFocus={() => setChangeAllowed(true)}
        onBlur={() => setChangeAllowed(false)}
      />
    </div>
  );
};

export default NewRejectionMailTextEditor;
