import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { InfoModal } from '../../../redux/salesScreen/selectors';
import SalesMain from '../SalesMain/SalesMain';
import CompanySignup from '../CompanySignup/CompanySignup';
import UpdateCompany from '../UpdateCompany/UpdateCompany';
import SalesScreen from '../SalesScreen/SalesScreen';
import SalesInfoModal from '../../Modals/SalesInfoModal/SalesInfoModal';
import { setInfoModal, setInitial } from '../../../redux/salesScreen/slice';
import {
  getCustomerSuccessTeamAction
} from '../../../redux/salesScreen/actions';

export const MAIN_PAGE = '0';
export const SIGN_UP = '1';
export const UPDATE = '2';
export const PACKAGE = '3';

const SalesPage = () => {
  const { index } = useParams();
  const history = useHistory();
  const infoModal = useSelector(InfoModal);
  const dispatch = useDispatch();

  useEffect(() => {
    if (index === '0') {
      dispatch(getCustomerSuccessTeamAction());
    }
  }, [index]);

  const changePages = () => {
    switch (index) {
      case MAIN_PAGE:
        return <SalesMain />;
      case SIGN_UP:
        return <CompanySignup />;
      case UPDATE:
        return <UpdateCompany />;
      case PACKAGE:
        return <SalesScreen />;
      default:
        return null;
    }
  };
  return (
    <>
      {changePages()}
      <SalesInfoModal
        show={infoModal}
        onHide={() => {
          dispatch(setInfoModal(false));
          dispatch(setInitial());
          history.push('/nRdDOsdIFdwEdfdsaSd/0');
        }}
      />
    </>
  );
};

export default SalesPage;
