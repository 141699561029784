import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import useOutsideClickRef from '../../../../utils/useOutsideClickRef';
import EditMenuIcon from '../../../../images/Auth/Settings/EditMenuIcon';
import TextOverflow from '../../../../Common/Components/TextOverflow';

const FunnelGroupTitle = ({ title, isEditable, setShowModalType, setEditState }) => {
  const editRef = useRef(null);
  const menuRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);

  const [menuWidth, setMenuWidth] = useState(0);

  const { t } = useTranslation(['positionTab'])

  useEffect(() => {
    if (showMenu) {
      setMenuWidth(menuRef?.current?.getBoundingClientRect()?.width);
    }
  }, [showMenu]);

  useOutsideClickRef(editRef, () => setShowMenu(false));

  useEffect(() => {
    setShowMenu(false);
  }, [isEditable]);

  return (
    <div
      className="FlexRow verticallyCenter w-100 spaceBetween"
      style={{ padding: '16px 20px 16px 10px' }}
    >
      <TextOverflow text={title} className="fw-400 font-16 c-darkGray" />
      <div ref={editRef} style={{ visibility: isEditable ? 'hidden' : 'visible' }}>
        <EditMenuIcon
          onClick={() => setShowMenu(true)}
          className="pointer"
          style={{ position: 'relative' }}
        />
        {showMenu ? (
          <div
            ref={menuRef}
            className="FlexColumn"
            style={{
              position: 'absolute',
              top:
                editRef?.current?.getBoundingClientRect()?.top +
                editRef?.current?.getBoundingClientRect()?.height +
                10,
              left: menuWidth ? editRef?.current?.getBoundingClientRect()?.right - menuWidth : 0,
              visibility: menuWidth ? 'visible' : 'hidden',
              border: '1px solid rgba(57, 57, 57, 0.1)',
              padding: '7px',
              borderRadius: '10px',
              gap: '7px'
            }}
          >
            <MenuTitle text={t('edit')} color="var(--dark-gray)" onClick={() => setEditState(true)} />
            <MenuTitle text={t('rename')} color="var(--dark-gray)" onClick={() => setShowModalType(2)} />
            <div className="w-100" style={{ border: '1px solid rgba(57, 57, 57, 0.1)' }} />

            <MenuTitle
              text={t('delete')}
              color="rgba(222, 71, 71, 1)"
              onClick={() => setShowModalType(3)}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default FunnelGroupTitle;

const MenuTitle = ({ text, color, onClick }) => {
  return (
    <StyledMenuTitle color={color} onClick={onClick}>
      {text}
    </StyledMenuTitle>
  );
};

const StyledMenuTitle = styled.div`
  font-family: Jost-400;
  font-size: 16px;
  color: ${props => props.color};
  padding: 7px 10px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background: rgba(244, 244, 244, 1);
  }
`;
