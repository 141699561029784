import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { currentPlanRedux } from '../../redux/plan/selectors';
import { userSelector } from '../../redux/auth/selectors';
import styles from '../Pages/Settings/Settings.module.css';
import { LoadingWrapper } from '../../utils/helpers';
import Loading from '../../Common/Components/Loading';
import BillingPage from '../Components/Settings/Billing/BillingPage';
import PlanTab from '../Components/Settings/PlanTab/PlanTab';
import GeneralTab from '../Components/Settings/GeneralTab/GeneralTab';
import ProfileTab from '../Components/Settings/ProfileTab/ProfileTab';
import TeamTab from '../Components/Settings/Team/TeamTab';
import PositionTab from '../Components/Settings/PositionTab/PositionTab';

export default function useSettingsPageComponentHandler() {
  const { tab } = useParams();
  const history = useHistory();

  const currentPlan = useSelector(currentPlanRedux);
  const currentUser = useSelector(userSelector);
  const disabledToSeeBillingTab =
    (!currentUser?.isAdmin && currentUser?.userGroupType !== 'Super Admin') ||
    currentPlan?.name === 'Free Trial' ||
    currentPlan?.isCustom;

  const ContentSelector = useCallback(() => {
    switch (tab) {
      case 'billing':
        return <BillingPage disabled={disabledToSeeBillingTab} />;

      case 'plan':
        return <PlanTab />;

      case 'general':
        return <GeneralTab />;

      case 'myprofile':
        return <ProfileTab />;

      case 'team':
        return <TeamTab />;

      case 'position':
        return <PositionTab />;

      default:
        history.push('/not-found');
        return null;
    }
  }, [tab, disabledToSeeBillingTab]);

  const SettingsPageContent = useCallback(() => {
    return (
      <div className={styles.tabContainer}>
        {currentUser ? (
          <ContentSelector />
        ) : (
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        )}
      </div>
    );
  }, [currentUser, tab]);

  return { SettingsPageContent };
}
