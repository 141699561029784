import React from 'react';

const DragIcon = ({ ...props }) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.625651 1.04167H9.37565C9.37565 1.04167 9.79232 1.04167 9.79232 1.45834V1.87501C9.79232 1.87501 9.79232 2.29167 9.37565 2.29167H0.625651C0.625651 2.29167 0.208984 2.29167 0.208984 1.87501V1.45834C0.208984 1.45834 0.208984 1.04167 0.625651 1.04167Z"
        fill="#393939"
        fill-opacity="0.5"
      />
      <path
        d="M0.625651 4.375H9.37565C9.37565 4.375 9.79232 4.375 9.79232 4.79167V5.20833C9.79232 5.20833 9.79232 5.625 9.37565 5.625H0.625651C0.625651 5.625 0.208984 5.625 0.208984 5.20833V4.79167C0.208984 4.79167 0.208984 4.375 0.625651 4.375Z"
        fill="#393939"
        fill-opacity="0.5"
      />
      <path
        d="M0.625651 7.70834H9.37565C9.37565 7.70834 9.79232 7.70834 9.79232 8.12501V8.54168C9.79232 8.54168 9.79232 8.95834 9.37565 8.95834H0.625651C0.625651 8.95834 0.208984 8.95834 0.208984 8.54168V8.12501C0.208984 8.12501 0.208984 7.70834 0.625651 7.70834Z"
        fill="#393939"
        fill-opacity="0.5"
      />
    </svg>
  );
};

export default DragIcon;