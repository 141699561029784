import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';
import {
  previewDraftAssessmentCheckAction,
  regenerateAssessmentAvailabilityCheckAction,
  regenerateQuestionAvailabilityCheckAction
} from './actions';

const previewAssessmentSlice = createSlice({
  name: 'previewAssessment',
  initialState,
  reducers: {
    // setSearchParam: (state, action) => {
    //   state.searchParameter = action.payload;
    // },
  },

  extraReducers: builder => {
    builder.addCase(previewDraftAssessmentCheckAction.fulfilled, (state, action) => {
      // We need to store index of questions to be able to show them later
      state.detailObj = action.payload;
    });
    builder.addCase(previewDraftAssessmentCheckAction.pending, state => {
      state.detailObj = null;
    });
    builder.addCase(previewDraftAssessmentCheckAction.rejected, state => {
      state.detailObj = null;
    });

    builder.addCase(regenerateAssessmentAvailabilityCheckAction.fulfilled, (state, action) => {
      state.regenerateAssessmentAvailability = action.payload.isAvailable;
    });
    builder.addCase(regenerateAssessmentAvailabilityCheckAction.pending, state => {
      state.regenerateAssessmentAvailability = null;
    });
    builder.addCase(regenerateAssessmentAvailabilityCheckAction.rejected, state => {
      state.regenerateAssessmentAvailability = false;
    });

    builder.addCase(regenerateQuestionAvailabilityCheckAction.fulfilled, (state, action) => {
      state.regenerateQuestionRemainingRight = action.payload.remainingRight;
    });
    builder.addCase(regenerateQuestionAvailabilityCheckAction.pending, state => {
      state.regenerateQuestionRemainingRight = null;
    });
    builder.addCase(regenerateQuestionAvailabilityCheckAction.rejected, state => {
      state.regenerateQuestionRemainingRight = 0;
    });
  }
});

// export const {
//    setSearchParam,
// } = assessmentCreationSlice.actions;

export default previewAssessmentSlice.reducer;
