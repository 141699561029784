import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import styles from '../Styles/NameAssessment.module.css';
import TextInput from '../../../Common/Components/TextInput';
import CustomButton from '../../../Common/Components/CustomButton';
import { userSelector } from '../../../redux/auth/selectors';
import { createCustomAssessmentAction } from '../../../redux/assessmentsVol2/actions';
import {
  continueToAssessmentCustomizationRedux,
  customAssessmentId,
  rootAssessmentId
} from '../../../redux/assessmentsVol2/selectors';
import { setContinueToAssessmentCustomization } from '../../../redux/assessmentsVol2/slice';
import { useWindowSize } from '../../../utils/helpers';
import MobileModal from '../../../Common/Components/MobileModal/MobileModal';
import CloseButton from '../../../Common/Components/CloseButton';
import Modal from '../../../Common/CustomModal/Modal';

export default function NameAssessmentModal({
  show,
  prevStage,
  onHide = () => {},
  onDuplicate = () => {}
}) {
  const [name, setName] = useState('');
  const user = useSelector(userSelector);
  const assessmentId = useSelector(rootAssessmentId);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const { t } = useTranslation(['addPosition'])

  const customAssessmentIdForNavigation = useSelector(customAssessmentId);
  const continueToAssessmentCustomizationFlag = useSelector(continueToAssessmentCustomizationRedux);

  const [screenW] = useWindowSize();
  const MOBILE_THRESDHOLD = 576;

  useEffect(() => {
    if (!show) {
      setLoading(false);
      setName('');
    }
  }, [show]);

  useEffect(async () => {
    if (continueToAssessmentCustomizationFlag === true && show && screenW >= MOBILE_THRESDHOLD) {
      dispatch(setContinueToAssessmentCustomization('pending'));
      history.push(`/assessment-customization/${customAssessmentIdForNavigation}`);
    } else if (continueToAssessmentCustomizationFlag === true && show) {
      await onHide();
      await onDuplicate();
      // success banner
    }
  }, [continueToAssessmentCustomizationFlag]);

  return screenW <= MOBILE_THRESDHOLD ? (
    <MobileModal
      id="mobile-nameassessment"
      onClose={onHide}
      show={show}
      outsideClickClose
      backdropStyle={{ overflowY: 'hidden' }}
      contentStyle={{
        padding: '2rem',
        height: `207px`,
        minWidth: '375px',
        overflow: 'hidden'
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className={styles.title}>{t('nameAssm')}</div>
        <div>
          <CloseButton onClick={onHide} />
        </div>
      </div>

      <TextInput
        placeholder={t('expAssmName')}
        value={name}
        onChange={event => {
          setName(event.target.value);
        }}
      />
      <div className={styles.row}>
        <CustomButton
          customStyle={{ marginTop: '-15px' }}
          size="mobile"
          textField={t('confirm')}
          type={1}
          loading={loading}
          buttonOnClick={async () => {
            setLoading(true);
            const assessment = {
              companyId: user.companyId,
              rootAssessmentName: name,
              rootAssessmentId: assessmentId
            };
            await dispatch(createCustomAssessmentAction(assessment));
            setLoading(false);
          }}
          isDisabled={!name}
          enterEnabled
        />
      </div>
    </MobileModal>
  ) : (
    <Modal
      show={show}
      onClose={() => {
        setName('');
        onHide();
      }}
      centerHorizontally
      centerVertically
      outsideClickClose
      contentClassName={styles.modalContent}
    >
      <div className={styles.title}>{t('nameAssm')}</div>
      <TextInput
        placeholder={t('expAssmName')}
        value={name}
        onChange={event => {
          setName(event.target.value);
        }}
      />
      <div className={styles.row}>
        <CustomButton
          size="small"
          style={{ marginRight: '4px' }}
          textField={t('cancel')}
          type={2}
          buttonOnClick={() => {
            onHide();
            setName('');
            prevStage();
          }}
        />
        <CustomButton
          size="small"
          textField={t('confirm')}
          type={1}
          loading={loading}
          buttonOnClick={async () => {
            setLoading(true);
            const assessment = {
              companyId: user.companyId,
              rootAssessmentName: name,
              rootAssessmentId: assessmentId
            };
            await dispatch(createCustomAssessmentAction(assessment));
            setLoading(false);
          }}
          isDisabled={!name}
          enterEnabled
        />
      </div>
    </Modal>
  );
}
