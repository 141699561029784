import React from 'react';
import constants from '../../../utils/constants';
import { useWindowSize } from '../../../utils/helpers';
import Modal from '../../CustomModal/Modal';
import MobileModal from '../MobileModal/MobileModal';

export default function ResponsiveModal({
  show,
  onClose,
  contentStyle,
  contentClassName,
  bodyStyle,
  children,
  id,
  outsideClickClose = true,
  responsiveCondition = null,
  borderRadius,
  backdropStyle
}) {
  const MOBILE_MODAL_THRESHOLD = constants.MOBILE_THRESHOLD;
  const [screenW] = useWindowSize();

  return (responsiveCondition !== null ? responsiveCondition : screenW > MOBILE_MODAL_THRESHOLD) ? (
    <Modal
      show={show}
      onClose={onClose}
      contentStyle={{...contentStyle, borderRadius: borderRadius}}
      contentClassName={contentClassName}
      bodyStyle={bodyStyle}
      modalId={id}
      centerHorizontally
      centerVertically
      outsideClickClose={outsideClickClose}
      backdropStyle={backdropStyle}
    >
      {children}
    </Modal>
  ) : (
    <MobileModal
      show={show}
      onClose={onClose}
      contentStyle={{...contentStyle}}
      contentClassName={contentClassName}
      bodyStyle={bodyStyle}
      id={id}
      outsideClickClose={outsideClickClose}
      backdropStyle={backdropStyle}
    >
      {children}
    </MobileModal>
  );
}
