import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createCompanyService,
  createTemplateService,
  getCompanyBundleInfoService,
  getCompanyListService,
  getCustomerSuccessTeamService,
  getTemplateInfoService,
  getTemplatesService,
  updateCompanyService,
  cancelCustomBundleService,
  connectCompanyWithBundleService
} from './service';

export const getCustomerSuccessTeamAction = createAsyncThunk(
  'getCustomerSuccessTeam',
  async (data, thunkAPI) => {
    try {
      const response = await getCustomerSuccessTeamService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getCompanyListAction = createAsyncThunk('getCompanyList', async (data, thunkAPI) => {
  try {
    const response = await getCompanyListService(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const getCompanyBundleInfoAction = createAsyncThunk(
  'getCompanyBundleInfo',
  async (data, thunkAPI) => {
    try {
      const response = await getCompanyBundleInfoService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTemplatesAction = createAsyncThunk('getTemplates', async (data, thunkAPI) => {
  try {
    const response = await getTemplatesService(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const getTemplateInfoAction = createAsyncThunk('getTemplateInfo', async (data, thunkAPI) => {
  try {
    const response = await getTemplateInfoService(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const createTemplateAction = createAsyncThunk('createTemplate', async (data, thunkAPI) => {
  try {
    const response = await createTemplateService(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const updateCompanyAction = createAsyncThunk('updateCompany', async (data, thunkAPI) => {
  try {
    const response = await updateCompanyService(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const createCompanyAction = createAsyncThunk('createCompany', async (data, thunkAPI) => {
  try {
    const response = await createCompanyService(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const cancelCustomBundleAction = createAsyncThunk(
  'cancelCustomBundle',
  async (data, thunkAPI) => {
    try {
      const response = await cancelCustomBundleService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const connectCompanyWithBundleAction = createAsyncThunk(
  'connectCompanyWithBundle',
  async (data, thunkAPI) => {
    try {
      const response = await connectCompanyWithBundleService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
