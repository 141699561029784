import React from 'react';
import Modal from '../../../../Common/CustomModal/Modal';

const CamperaPermissionModal = () => {
  return (
    <Modal
      centerHorizontally
      centerVertically
      show
      onClose={() => {}}
      contentStyle={{ borderRadius: '10px', padding: '18px 24px', maxWidth: '635px', textAlign: 'center'}}
      contentClassName="FlexColumn gap-16 w-100 fw-400 font-16"
    >
      Please give permission to access the camera and refresh the page.
    </Modal>
  );
};

export default CamperaPermissionModal;
