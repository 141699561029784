import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import mixpanel from '../mixpanel';

import mixpanelEventNames from './mixpaneEventNames.json';
import {
  AssessmentTitle,
  CandidateEmail,
  CompanyName,
  CvCheck,
  CvPath,
  rootAssessmentNameRedux
} from '../../redux/AssessmentSession/selectors';

export default function useMixpanelCandidateResultPageview(
  application,
  isAlreadyPassed,
  autoApplyStatus
) {
  const assessmentTitle = useSelector(AssessmentTitle);
  const rootAssessmentName = useSelector(rootAssessmentNameRedux);
  const companyName = useSelector(CompanyName);
  const candidateEmail = useSelector(CandidateEmail);
  const cvCheck = useSelector(CvCheck);
  const cvPath = useSelector(CvPath);

  useEffect(() => {
    function mixpanelPageviewEventHelper(eventName, properties) {
      mixpanel.track(eventName, properties);
    }

    const properties = {
      'Application Flag': !!application,
      'Assessment Name': assessmentTitle,
      'Root Assessment Name': rootAssessmentName,
      'Company Name': companyName,
      'Candidate Email': candidateEmail,
      'Is CV Needed Flag': cvCheck,
      'CV Flag': !!cvPath,
      'Already Scored Flag': isAlreadyPassed === 'true',
      'NewOpp Flag': !!autoApplyStatus
    };
    mixpanelPageviewEventHelper(mixpanelEventNames.candidateResultPageview, properties);
  }, []);
}
