import React, { useEffect, useState } from 'react';
import styles from './Checkbox.module.css';

export default function Checkbox({
  myStyle,
  id,
  setIsChecked = () => {},
  isChecked,
  isDisabled,
  indeterminate = false,
  // setIndeterminate = () => {},
  parentUpdate = () => {},
  size,
}) {
  const [myChecked, setMyChecked] = useState(isChecked);
  // const [myIndeterminate, setMyIndeterminate] = useState(indeterminate);

  async function handleClick() {
    setMyChecked(!myChecked);
    setIsChecked(!myChecked);
  }

  useEffect(() => {
    setMyChecked(isChecked);
  }, [isChecked]);

  return (
    <div
      id={id}
      style={{
        minWidth: `${size}px`,
        minHeight: `${size}px`,
        maxWidth: `${size}px`,
        maxHeight: `${size}px`,
        ...myStyle
      }}
      className={`${styles.container} ${isDisabled && styles.disabled} ${
        myChecked && styles.checked
      } ${indeterminate && styles.indeterminate}`}
      onClick={async () => {
        await handleClick();
        parentUpdate();
      }}
    />
  );
}
