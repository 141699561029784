import { useEffect, useState } from 'react';

export default function useHandleCameraPermission(isCameraNeeded) {
  const [isCameraRequired, setIsCameraRequired] = useState(isCameraNeeded);
  const [isPermissionGranted, setIsPermissionGranted] = useState(null);

  useEffect(() => {
    if (isCameraNeeded) {
      setIsCameraRequired(true);
    } else {
      setIsCameraRequired(false);
    }
  }, [isCameraNeeded]);

  useEffect(() => {
    async function requestCameraPermission() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        stream.getTracks().forEach(track => track.stop());
        setIsPermissionGranted(true);
      } catch (error) {
        setIsPermissionGranted(false);
      }
    }

    // Request camera permission if required
    if (isCameraRequired) {
      requestCameraPermission();

      // Set up an event listener to track permission changes
      const permissionChangeHandler = () => {
        navigator.permissions.query({ name: 'camera' }).then(permissionStatus => {
          setIsPermissionGranted(permissionStatus.state === 'granted');
        });
      };

      // Add the event listener to track changes
      navigator.permissions.query({ name: 'camera' }).then(permissionStatus => {
        permissionStatus.addEventListener('change', permissionChangeHandler);
      });

      // Clean up event listener
      return () => {
        navigator.permissions.query({ name: 'camera' }).then(permissionStatus => {
          permissionStatus.removeEventListener('change', permissionChangeHandler);
        });
      };
    }
    // If camera permission is not required, no cleanup is needed
    setIsPermissionGranted(null);
    return () => {};
  }, [isCameraRequired]);

  return { isCameraRequired, isPermissionGranted };
}
