import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { candidateProfileSelector } from '../../redux/candidateDetail/selectors';
import { getPersonalityTestAction, sendPersonalityTestAction } from '../Api/action';
import CustomButton from '../../Common/Components/CustomButton';
import tick from '../../images/Common/successSubmit.svg';
import error from '../../images/Common/errorSubmit.svg';

const ALREADY_INVITED_ERROR_MESSAGE = 'This test has been solved before.';

export default function useBigFivePTHandler() {
  const profile = useSelector(candidateProfileSelector);
  const candidateEmail = profile?.email;

  const [AllowedComponent, setAllowedComponent] = useState(() => () => null);

  async function handlePTComponent(email) {
    const returnedLink = await fetchPTStatus(email);

    if (returnedLink === null) {
      setAllowedComponent(() => () => null);
      return;
    }
    if (returnedLink === '') {
      setAllowedComponent(() => () => <SendPTInvitation email={candidateEmail} />);
      return;
    }
    setAllowedComponent(() => () => <ShowPTResult reportLink={returnedLink} />);
  }

  useEffect(() => {
    if (candidateEmail) {
      handlePTComponent(candidateEmail);
    }
  }, [candidateEmail]);

  return { BigFivePTComponent: AllowedComponent };
}

const SendPTInvitation = ({ email }) => {
  const [loading, setLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [alreadyInvited, setAlreadyInvited] = useState(false);

  const { t } = useTranslation(['candidateDetail'])

  const sendTest = async () => {
    setLoading(true);
    const resp = await sendPTTest(email);
    console.log(resp);
    if (resp?.typeCode === 500 && resp?.message === ALREADY_INVITED_ERROR_MESSAGE) {
      setAlreadyInvited(true);
      setIsSent(true);
    } else if (resp === true) {
      setIsSent(true);
    }

    setLoading(false);
  };

  return !isSent ? (
    <div
      className="FlexColumn gap-20"
      style={{
        padding: '20px 24px 24px',
        borderRadius: '10px',
        background: '#fff',
        boxShadow: '0px 2px 24px 0px rgba(134, 134, 134, 0.15)'
      }}
    >
      <span className="c-darkGray fw-500 font-18">{t('coensioPersonTest')}</span>
      <CustomButton
        type={1}
        size="small"
        textField={t('sendTest')}
        customStyle={{ width: '100%', height: '42px' }}
        buttonOnClick={sendTest}
        loading={loading}
      />
    </div>
  ) : alreadyInvited ? (
    <div className="FlexRow gap-12 verticallyCenter">
      <img src={error} alt="already_invited" width={16} height={16} />
      <span className="fw-400 font-16" style={{ color: '#DE4747' }}>
        {t('candAlreadyInv')}
      </span>
    </div>
  ) : (
    <div className="FlexRow gap-12 verticallyCenter">
      <img src={tick} alt="successfully_sent" width={16} height={16} />
      <span className="fw-400 font-16" style={{ color: '#4AC389' }}>
        {t('testSent')}
      </span>
    </div>
  );
};

const ShowPTResult = ({ reportLink }) => {
  const seeResults = () => {
    window.open(reportLink, '_blank').focus();
  };
  const { t } = useTranslation(['candidateDetail'])

  return (
    <CustomButton
      type={5}
      size="small"
      textField={t('seeReport')}
      customStyle={{ width: '100%', height: '42px' }}
      buttonOnClick={seeResults}
    />
  );
};

async function fetchPTStatus(email) {
  const data = {
    email: email
  };
  try {
    const resp = await getPersonalityTestAction(data);
    return resp?.data?.resultLink;
  } catch (error) {
    return null;
  }
}

async function sendPTTest(email) {
  const data = {
    email: email
  };
  try {
    const resp = await sendPersonalityTestAction(data);
    return !!resp?.data?.email;
  } catch (error) {
    return error;
  }
}
