import React from 'react';
import Header from '../../../Common/Components/Header';
import PreviewAssessmentContent from '../Components/PreviewAssessmentContent';

import styles from '../Styles/PreviewAssessment.module.css'

const PreviewAssessment = () => {

  return (
    <div className={styles.PageWrapper}>
      <Header />
      <PreviewAssessmentContent />
    </div>
  );
};

export default PreviewAssessment;
