export const CvCheck = state => state.assessmentSession.cvCheck;
export const screeningCheckRedux = state => state.assessmentSession.screeningCheck;
export const LogoPath = state => state.assessmentSession.logoPath;
export const AssessmentTitle = state => state.assessmentSession.assessmentTitle;
export const CompanyName = state => state.assessmentSession.companyName;
export const CandidateEmail = state => state.assessmentSession.candidateEmail;
export const QuestionCount = state => state.assessmentSession.questionCount;
export const TotalTime = state => state.assessmentSession.totalTime;
export const ShowContract = state => state.assessmentSession.showContract;

export const candidateName = state => state.assessmentSession.candidateName;
export const candidatePhone = state => state.assessmentSession.candidatePhone;
export const CvPath = state => state.assessmentSession.cvPath;
export const candidateLinkedIn = state => state.assessmentSession.candidateLinkedIn;
export const candidateSurname = state => state.assessmentSession.candidateSurname;
export const CodingCount = state => state.assessmentSession.codingCount;
export const FreeCount = state => state.assessmentSession.freeCount;
export const MultiCount = state => state.assessmentSession.multiCount;
export const LanguageList = state => state.assessmentSession.languageList;

export const assessmentSessionTokenRedux = state => state.assessmentSession.assessmentSessionToken;
export const questionInfoRedux = state => state.assessmentSession.questionInfo;
export const nextButtonLoadingRedux = state => state.assessmentSession.nextButtonLoading;

export const PageLoading = state => state.assessmentSession.loading;
export const ApplicationState = state => state.assessmentSession.applicationState;
export const buttonLoading = state => state.assessmentSession.saveLoading;
export const ShowBanner = state => state.assessmentSession.showBanner;
export const Expired = state => state.assessmentSession.expired;
export const BannerText = state => state.assessmentSession.bannerText;

export const rootAssessmentNameRedux = state => state.assessmentSession.rootAssessmentName;

export const DescriptionEN = state => state.assessmentSession.descriptionEN;
export const DescriptionTR = state => state.assessmentSession.descriptionTR;
export const PositionHeaderEN = state => state.assessmentSession.positionHeaderEN;
export const PositionHeaderTR = state => state.assessmentSession.positionHeaderTR;

export const DueDate = state => state.assessmentSession.dueDate;

export const contentTemplates = state => state.assessmentSession.contentTemplates;

export const isCameraNeededRedux = state => state.assessmentSession.isCameraNeeded;
export const isCvNeededRedux = state => state.assessmentSession.isCvNeeded;
export const isScreeningNeededRedux = state => state.assessmentSession.isScreeningNeeded;

export const isOtpNeededRedux = state => state.assessmentSession.isOtpNeeded;

export const screeningLoadingRedux = state => state.assessmentSession.screeningLoading;
export const screeningListObjRedux = state => state.assessmentSession.screeningListObj;

export const screeningWorkTypeListRedux = state => state.assessmentSession.screeningWorkTypeList;
export const screeningSalaryExpectationRedux = state =>
  state.assessmentSession.screeningSalaryExpectation;
export const screeningNationalityIdsRedux = state =>
  state.assessmentSession.screeningNationalityIds;
export const screeningCurrencyTypeRedux = state => state.assessmentSession.screeningCurrencyType;
export const screeningExperienceRedux = state => state.assessmentSession.screeningExperience;

export const screeningLocationRedux = state => state.assessmentSession.screeningLocation;

export const hasAssessmentRedux = state => state.assessmentSession.hasAssessment;
