import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  publishAssessmentControlService,
  getRootAssessmentDropdownListService
} from '../../Assessment/AddAssessment/Api/service';

export const publishAssessmentControlAction = createAsyncThunk(
  'publishAssessmentControl',
  async (data, thunkAPI) => {
    try {
      const response = await publishAssessmentControlService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getRootAssessmentDropdownListAction = createAsyncThunk(
  'getRootAssessmentDropdownList',
  async (data, thunkAPI) => {
    try {
      const response = await getRootAssessmentDropdownListService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
