export default {
  candidateProfile: {},
  candidatePositions: [],
  defaultAssessmentFunnelList: [],

  selectedAssessmentForReport: null,  // Added for candidate report - pdf

  infoBannerText: null,
  infoBannerShow: null,
  isCandidateAuthorized: false,
  showRejectionPopupFlag: null,
  showRejectionSuccessFlag: null,
  isRejectionMailSent: null,

  notes: [],

  candidateSolutions: null,
  solutionAuthorize: null,
  priceForUnlock: null,
  loadingForSolutions: null,
  candidateReportPublicLink: null,

  candidateBackground: {},
  candidateCvPath: '',

  currentAssessmentFunnelList: [],

  loadingForPersonalInfo: null,
  personalInfoExperience: null,
  personalInfoNationality: null,
  personalInfoSalary: null,
  personalInfoWorkType: null,
  candidateActivities: null,

  showChangeFunnelPopup: null,
  currentAssessmentInfo: null,

  isAutoApplyCandidate: null,
};
