import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import {
  contactSalesAction,
  getAllPlansAction,
  comparePlansAndFeaturesAction,
  abortCancelInProgressAction,
  getCurrentPlanAction,
  getCurrentUsageAction,
  getAddOnsAction
} from '../../redux/plan/actions';
import { userSelector } from '../../redux/auth/selectors';
import {
  setPurchaseModalPackageId,
  setDowngradeModalShow,
  setUpgradeModalShow,
  setPurchaseModalShow,
  setAbortDowngradeSuccessShow,
  setDowngradeSuccess,
  setUpgradeSuccess,
  setContactSalesModalShow
} from '../../redux/plan/slice';
import {
  plansDetailJSON,
  currentPlanRedux,
  contactSalesSuccess,
  purchaseModalPackageIdRedux,
  downgradeSuccess,
  downgradeModalShow,
  upgradeModalShow,
  upgradeSuccess,
  abortDowngradeSuccessShow
} from '../../redux/plan/selectors';
import { setSettingsPageErrorMessage, setSettingsPageErrorShow } from '../../redux/auth/slice';
import { getUpgradePricesAction } from '../../redux/auth/actions';

import ContactSalesSuccess from './UnpaidContactSales/ContactSalesSuccess';
import UnsubscribeModal from './UnsubscribeModal';
import UnsubscribeSuccessModal from './UnsubscribeSuccessModal';
import AllPricesModal from './AllPricesModal/AllPricesModal';
import PaymentMethod from './PaymentMethod';
import CloseButton from '../../Common/Components/CloseButton';
import Tooltip from '../../Common/Components/Tooltip';
import mixpanel from '../../utils/mixpanel';

import styles from '../Styles/PackageDetailsModal.module.css';

import enterprise from '../../images/Auth/Payment/enterprise.svg';
import { ReactComponent as Arrow } from '../../images/Auth/Payment/packageDetailArrow.svg';
import { ReactComponent as ArrowUp } from '../../images/Auth/Payment/arrowUp.svg';
import { ReactComponent as Gradient } from '../../images/Auth/Payment/seePlansBackground.svg';
import { ReactComponent as Highlight } from '../../images/Auth/Payment/theBestHighlight.svg';
import yes from '../../images/Auth/Payment/yes.svg';
import no from '../../images/Auth/Payment/no.svg';
import tick from '../../images/Auth/Payment/tickSeePlans.svg';
import Modal from '../../Common/CustomModal/Modal';
import { useWindowSize } from '../../utils/helpers';

const PlanContainer = styled.div`
  background: #fff;
  padding: 12px 24px;
  border-radius: 12px;
  display: grid;
  grid-template-rows: 54% 46%;
  min-height: 600px;
  min-width: 320px;
  flex-grow: 0;
  width: 23%;
  flex-direction: column;
  box-shadow: 0px 2px 24px rgb(134 134 134 / 15%);

  @media screen and (max-width: 576px){
    margin-right: auto;
    margin-left: auto;
}
  }
`;

const StyledButton = styled.div`
  width: 100%;
  background: #7458d3;
  box-shadow: 0px 1px 16px rgba(90, 90, 90, 0.1);
  border-radius: 10px;
  font-family: 'Jost-400';
  font-style: normal;
  min-width: fit-content;
  font-weight: 400;
  white-space: nowrap;
  font-size: 1.8rem;
  line-height: 29px;
  text-align: center;
  padding-top: 7px;
  cursor: pointer;
  margin-bottom: 18px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 10px;
  padding-left: 15%;
  padding-right: 15%;
  color: #fff;
  height: 42px;
`;

const PassiveButton = styled.div`
  border: 1px solid rgba(57, 57, 57, 0.2);
  background: transparent;
  cursor: unset;
  width: 100%;
  border-radius: 10px;
  font-family: 'Jost-400';
  font-style: normal;
  min-width: fit-content;
  font-weight: 400;
  white-space: nowrap;
  font-size: 1.8rem;
  line-height: 29px;
  text-align: center;
  padding-top: 7px;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 10px;
  padding-left: 15%;
  padding-right: 15%;
  color: rgba(57, 57, 57, 0.2);
  height: 42px;
`;

const RecommendedDiv = styled.div`
  width: fit-content;
  height: 23px;
  background: rgba(255, 187, 1, 0.15);
  border-radius: 6px;
  font-family: 'Jost-500';
  font-size: 1.2rem;
  line-height: 17px;
  color: #ffbb01;
  padding: 3px 8px;
  margin-top: -12px;
`;

const PackageItem = ({
  item,
  id,
  title,
  price,
  definition,
  list,
  icon,
  listing,
  recommended,
  period,
  buyNow,
  order,
  currentPlan
}) => {
  return (
    <>
      <PlanContainer
        id="underQS"
        className={`${styles.planContainerGrid} ${recommended && styles.recommendedCol}`}
      >
        <div className={styles.planFirstRow}>
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <div className={styles.title}>{title}</div>
              {recommended && <RecommendedDiv>RECOMMENDED</RecommendedDiv>}
            </div>
            {title === 'Enterprise' ? (
              <div className={styles.enterpriseContainer}>
                <span style={{ marginBottom: '26px' }}>
                  <img alt="" src={enterprise} />
                </span>
                {/* <Enterprise style={{ marginBottom: '26px' }} /> */}
                <span className={styles.letsTalk}>Let&apos;s Talk</span>
              </div>
            ) : (
              <div className={styles.description}>{definition}</div>
            )}
            {title !== 'Enterprise' && price && (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span className={styles.value}>{`$${price}`}</span>
                <span className={styles.value2}>{`/${period === '30' ? 'month' : 'year'}`}</span>
              </div>
            )}
            {title !== 'Enterprise' && period && (
              <div className={styles.footnote}>
                {listing || `${period === '30' ? 'monthly' : 'annually'} billing only`}
              </div>
            )}
          </div>
          {title === currentPlan?.name ? (
            <PassiveButton>Current Plan</PassiveButton>
          ) : (
            <StyledButton onClick={() => buyNow(item)}>{`${
              title === 'Enterprise' || currentPlan?.isCustom || !currentPlan
                ? 'Contact Sales' // In enterprise plan
                : currentPlan?.name === 'Free Trial'
                ? 'Buy now' // In free trial
                : currentPlan?.companyFuturePlan?.planOrder < currentPlan?.order &&
                  currentPlan?.companyFuturePlan?.planName === item.name
                ? 'Cancel downgrade' // If there is a downgrade in process
                : order < currentPlan?.order
                ? 'Downgrade'
                : order > currentPlan?.order
                ? 'Upgrade'
                : 'Current Plan'
            }`}</StyledButton>
          )}
          {/* <div className={styles.divider} /> */}
        </div>
        {title !== 'Enterprise' ? (
          <ul className={styles.packageList}>
            {list?.map((x, index) => {
              return (
                <li className={styles.packageElement}>
                  <span
                    style={{
                      marginRight: '8px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center'
                    }}
                  >
                    <img alt="" src={tick} />
                  </span>
                  <span>{x}</span>
                </li>
              );
            })}
          </ul>
        ) : (
          <span className={styles.packageElementEnt}>
            <span
              style={{
                marginRight: '8px',
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              Let&apos;s talk to find the best coverage for all of your needs
            </span>
          </span>
        )}
      </PlanContainer>
    </>
  );
};

function CategoryHeader({ item, index, list }) {
  const myModalBody = document?.getElementById('modalBody');

  const StyledDiv = styled.div`
    background: linear-gradient(270deg, #32cbe0 6.2%, #7458d3 87.72%);
    height: 52px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    // margin-top: -8px;
    border-radius: 10px;
    text-align: center;
    font-family: 'Jost-500';
    font-size: 1.8rem;
    text-transform: capitalize;
    color: #ffffff;
    padding-top: 11px;
  `;

  useEffect(() => {
    if (myModalBody) {
      myModalBody.onscroll = function () {
        const header = document?.getElementById('myHeader');
        const modalRect = document?.getElementById('modalBody')?.getBoundingClientRect()?.y;
        const featureBody = document?.getElementById('featureContainer');
        const bundleItem = document?.getElementById('0-0bundle')?.getBoundingClientRect();

        if (header?.getBoundingClientRect()?.y <= modalRect) {
          header?.classList?.add(styles.myStickyHeader);
          header.style.width = `${bundleItem.width}px`;
          header.style.left = `${bundleItem.x}px`;
        } else if (featureBody?.getBoundingClientRect()?.y > header?.getBoundingClientRect()?.y) {
          header?.classList?.remove(styles.myStickyHeader);
          header.style.width = '100%';
          header.style.left = '0';
        }
      };
    }
  });

  return (
    <>
      <div
        id={`${index}category`}
        style={{
          height: '67px',
          borderTopLeftRadius: index === 0 && '12px',
          borderTopRightRadius: index === 0 && '12px'
        }}
        className={`${styles.categoryHeaderContainer} ${styles.categoryText} ${styles.categoryGridWrapper}`}
      >
        {item.categoryGroupName}
        {index === 0 && (
          <StyledDiv id="myHeader">
            <span>Basic</span>
            <span>Essential</span>
            <span>Premium</span>
          </StyledDiv>
        )}
      </div>
      {item.bundleDetails.map((bundle, i) => (
        <div
          className={`${
            i % 2 !== 0 ? styles.categoryHeaderContainer : styles.categoryHeaderContainerGray
          } ${styles.categoryGridWrapper}`}
        >
          <div
            className={`
           ${styles.bundleItemText}`}
          >
            {bundle.toolTip !== '' ? (
              <Tooltip direction="right" content={bundle.toolTip} style={{ fontSize: '16px' }}>
                <span
                  style={{
                    cursor: 'pointer',
                    borderBottom: '1px dashed #cbd5e1'
                  }}
                >
                  {bundle.categoryName}
                </span>
              </Tooltip>
            ) : (
              <span>{bundle.categoryName}</span>
            )}
          </div>
          <div className={styles.planValueContainer} id={`${index}-${i}bundle`}>
            {bundle.planValues.map(
              (plan, i2) =>
                i2 !== 0 && (
                  <div>
                    {plan.planValue === 'yes' ? (
                      <span>
                        <img src={yes} alt="" />
                      </span>
                    ) : plan.planValue === 'no' ? (
                      <span>
                        <img src={no} alt="" />
                      </span>
                    ) : (
                      plan.planValue
                    )}
                  </div>
                )
            )}
          </div>
        </div>
      ))}
    </>
  );
}

function PackageDetailsModal({ show = true, onHide, setShow = () => {}, json }) {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const plansAndFeatures = useSelector(plansDetailJSON);
  const [planDetails, setPlanDetails] = useState(null);
  const [compareOpen, setCompareOpen] = useState(false);

  const [allPlans, setAllPlans] = useState();
  const myPlan = useSelector(currentPlanRedux);
  const contactSalesSuccessModal = useSelector(contactSalesSuccess);
  const [blur, setBlur] = useState(false);

  useEffect(async () => {
    setBlur(false);
    const data = {
      CompanyId: user?.companyId
    };
    const resp = await dispatch(getAllPlansAction(data));
    setAllPlans(resp.payload);
    dispatch(comparePlansAndFeaturesAction());

    if (!show) {
      setCompareOpen(false);
    }
  }, [show]);

  const generateList = item => {
    const list = [];
    list.push('Unlimited users');

    item?.bundleItemInfos.forEach(e => {
      if (e?.code === 10 && e.type === 1 && e?.count > 0) {
        list.push(`${e?.count} published assessment`);
      }
      if (e?.code === 20 && e.type === 1 && e?.count > 0) {
        list.push(`${e?.count} candidates`);
      }
      if (e?.code === 30 && e.type === 1 && e?.isUse) {
        list.push(`Candidate reports`);
      }
      if (e?.code === 50 && e.type === 1 && !e?.isUse) {
        list.push(`Customize assessments with free questions`);
      }
      if (e?.code === 50 && e.type === 1 && e?.isUse) {
        list.push(`Customize assessments with all questions including premium`);
      }
    });
    return list;
  };

  const contactSales = async e => {
    const rawData = {
      userId: user?.userId,
      bundleItemMapId: e?.bundleId
    };
    const a = await dispatch(contactSalesAction(rawData));
    await setBlur(true);
  };

  const continuePayment = async e => {
    await dispatch(setPurchaseModalPackageId(e));
    const data = {
      newBundleId: e.bundleId
    };
    await dispatch(getUpgradePricesAction(data));
    if (myPlan?.name === 'Free Trial') {
      await onHide();
      await dispatch(setPurchaseModalShow(true));
    } else if (e?.order < myPlan?.order) {
      setBlur(true);
      await dispatch(setDowngradeModalShow(true));
    } else if (e?.order > myPlan?.order) {
      setBlur(true);
      await dispatch(setUpgradeModalShow(true));
    }
    setShow(false);
  };

  const cancelDowngrade = async e => {
    await dispatch(setPurchaseModalPackageId(e));
    const data = {
      companyId: user?.companyId
    };
    const a = await dispatch(abortCancelInProgressAction(data));
    if (a?.meta?.requestStatus === 'fulfilled') {
      // await onHide();
      await setBlur(true);
      await dispatch(setAbortDowngradeSuccessShow(true));
      await dispatch(getCurrentPlanAction(data));
      await dispatch(getCurrentUsageAction(data));
      await dispatch(getAddOnsAction(data));
    } else {
      await dispatch(setSettingsPageErrorMessage(a?.payload?.message));
      // await onHide();
      await dispatch(setSettingsPageErrorShow(true));
    }
  };

  useEffect(() => {
    if (plansAndFeatures) {
      setPlanDetails(plansAndFeatures);
    }
  }, [plansAndFeatures]);

  const modalBody = useRef();

  useEffect(() => {
    if (compareOpen) {
      const height = document?.getElementById('innerContainer')?.clientHeight;
      document.getElementById('modalBody')?.scrollTo({ top: height + 130, behavior: 'smooth' });
    }
  }, [compareOpen]);

  // Variables for inner modals
  const downgradeSuccessModal = useSelector(downgradeSuccess);
  const downgradeConfirmModal = useSelector(downgradeModalShow);
  const upgradeSuccessModal = useSelector(upgradeSuccess);
  const upgradeConfirmModal = useSelector(upgradeModalShow);
  const abortSuccess = useSelector(abortDowngradeSuccessShow);
  const newPackageObject = useSelector(purchaseModalPackageIdRedux);
  const [paymentMethodShow, setPaymentMethodShow] = useState('');

  useEffect(() => {
    if (
      !downgradeSuccessModal &&
      !downgradeConfirmModal &&
      !upgradeSuccessModal &&
      !upgradeConfirmModal &&
      !contactSalesSuccessModal &&
      !abortSuccess &&
      paymentMethodShow === ''
    ) {
      setBlur(false);
    } else if (downgradeSuccessModal || upgradeSuccessModal) {
      setBlur(true);
    }
  }, [
    downgradeSuccessModal,
    downgradeConfirmModal,
    upgradeSuccessModal,
    upgradeConfirmModal,
    contactSalesSuccessModal,
    abortSuccess,
    paymentMethodShow
  ]);

  const [vWidth, setVwidth] = useWindowSize();

  const StyledGradient = styled.div`
    path {
      transform: scaleX(calc(${vWidth + 20} / 1350));
    }
  `;

  useEffect(() => {
    if (show) {
      mixpanel.track('See Plans - Pageview', {
        'User Id': user?.userId,
        'Company Name': user?.companyName,
        'Name Surname': user?.userNameSurname,
        Email: user?.email,
        'User Role': user?.userGroupType,
        'Is Admin Flag': user?.isAdmin,
        'Plan Name': myPlan?.name
      });
    }
  }, [show]);

  const RelatedModals = (
    <>
      {/* MODALS */}
      <ContactSalesSuccess
        icon="tick"
        show={contactSalesSuccessModal}
        onHide={() => dispatch(setContactSalesModalShow(false))}
        title="Your request has been received!"
        description="One of our experts will contact you shortly."
      />
      {/* Downgrade confirm modal */}
      <UnsubscribeModal
        title="Are you sure?"
        description={`If you downgrade your plan, you will lose shiny features of ${myPlan?.name}.`}
        show={downgradeConfirmModal}
        onHide={() => dispatch(setDowngradeModalShow(false))}
        downgrade
      />
      {/* Downgrade success modal */}
      <UnsubscribeSuccessModal
        show={downgradeSuccessModal}
        onHide={() => {
          dispatch(setDowngradeSuccess(false));
          onHide();
        }}
        title="Done!"
        description={`Your plan will be changed to ${newPackageObject?.name} on the next period.`}
        footer={`*You can access ${myPlan?.name} features till the next billing period.`}
        icon="tick"
      />

      <AllPricesModal
        show={upgradeConfirmModal}
        onHide={() => dispatch(setUpgradeModalShow(false))}
        showPaymentMethod={setPaymentMethodShow}
      />

      {/* Upgrade Success Modal */}
      <UnsubscribeSuccessModal
        show={upgradeSuccessModal}
        onHide={() => {
          dispatch(setUpgradeSuccess(false));
          onHide();
        }}
        title="Congrats!"
        description={`You levelled up your hiring process with ${newPackageObject?.name}.`}
        icon="confetti"
      />
      <PaymentMethod show={paymentMethodShow !== ''} onHide={() => setPaymentMethodShow('')} />
    </>
  );

  return (
    <>
      <div id="package">
        <Modal
          show={show}
          onClose={onHide}
          overlayClassName={styles.backdropClass}
          contentClassName={`${styles['modal-content']} ${styles.modalContent} ${
            !compareOpen && styles.modalContentClosed
          } ${styles['modal-dialog']} ${styles['package-modal-dialog']}`}
        >
          <div
            className={`${styles.modalBody} ${blur && styles.blurredContent}`}
            ref={modalBody}
            id="modalBody"
          >
            <StyledGradient>
              <Gradient />
            </StyledGradient>
            <div className={styles.newContainer}>
              <div className={styles.innerBody}>
                <div className={styles.titleRow}>
                  <div className={styles.headerContainer}>
                    <div className={styles.modalTitle}>Choose the best plan for your company!</div>
                    <div className={styles.highlightedHeader}>
                      {isMobile ? null : <Highlight />}
                    </div>
                  </div>
                  <div className={styles.closeButton}>
                    <CloseButton onClick={onHide} />
                  </div>
                </div>
                <div className={styles.innerContainer} id="innerContainer">
                  {allPlans?.map(
                    (e, i) =>
                      e?.name !== 'Free Trial' && (
                        <PackageItem
                          item={e}
                          id={e.bundleId}
                          title={e.name === 'Enterprise (Sales)' ? 'Enterprise' : e.name}
                          price={e.price}
                          definition={e?.description ? e?.description : 'Not yet available.'}
                          list={generateList(e)}
                          period={e.period}
                          recommended={
                            (e.order === myPlan?.order + 1 &&
                              myPlan?.name !== 'Free Trial' &&
                              myPlan?.name !== 'Premium' &&
                              !myPlan?.isCustom) ||
                            (e.name === 'Essential' && myPlan?.name === 'Free Trial')
                          }
                          buyNow={
                            myPlan?.isCustom
                              ? contactSales
                              : myPlan?.companyFuturePlan?.planOrder < myPlan?.order &&
                                myPlan?.companyFuturePlan?.planName === e.name
                              ? cancelDowngrade
                              : continuePayment
                          }
                          order={e?.order}
                          currentPlan={myPlan}
                        />
                      )
                  )}
                  <PackageItem
                    title="Enterprise"
                    definition="Not yet available"
                    buyNow={contactSales}
                    currentPlan={myPlan}
                  />
                </div>

                <div
                  id="compareButton"
                  className={styles.row}
                  onClick={event => {
                    event.preventDefault();
                    setCompareOpen(!compareOpen);
                  }}
                >
                  {compareOpen ? <ArrowUp /> : <Arrow />}
                  <div className={styles.compare}>Compare Plans and Features</div>
                </div>

                {compareOpen && (
                  <div className={styles.featureContainer} id="featureContainer">
                    {planDetails &&
                      planDetails?.map((item, i) => (
                        <>
                          <CategoryHeader list={planDetails} index={i} item={item} />
                        </>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </div>
      {RelatedModals}
    </>
  );
}

export default PackageDetailsModal;
