import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import CustomButton from '../../../../Common/Components/CustomButton';
import { ASSESSMENT_FLOW_CODING_MOBILE_THRESHOLD } from '../../../../utils/helpers';
import styles from './ScreeningCardComponent.module.css';
import {
  CandidateEmail,
  CompanyName,
  screeningCurrencyTypeRedux,
  screeningExperienceRedux,
  screeningListObjRedux,
  screeningLocationRedux,
  screeningNationalityIdsRedux,
  screeningSalaryExpectationRedux,
  screeningWorkTypeListRedux
} from '../../../../redux/AssessmentSession/selectors';
import WorkTypeQuestion from './WorkTypeQuestion';
import ExperienceQuestion from './ExperienceQuestion';
import WorkPermitQuestion from './WorkPermitQuestion';
import SalaryQuestion from './SalaryQuestion';
import { saveScreeningAnswerAction } from '../../../../redux/AssessmentSession/actions';
import { useIp } from '../../../../hooks/useIp';
import Skeleton from '../../../../Common/Components/Skeleton';
import LocationQuestion from './LocationQuestion';
import {
  setScreeningLocation,
  setScreeningNationalityIds
} from '../../../../redux/AssessmentSession/slice';
import useMixpanelScreeningNextClick from '../../../../utils/mixpanelHelpers/useMixpanelScreeningNextClick';

const ScreeningCardComponent = ({ closeScreening = () => {}, application }) => {
  const { t } = useTranslation(['application']);
  const dispatch = useDispatch();
  const [locationInfo, currency] = useIp();

  const [buttonLoading, setButtonLoading] = useState(false);
  const [triggerEvent, setTriggerEvent] = useState(false);
  const [isAlreadyAnswered, setIsAlreadyAnswered] = useState(false);

  const companyName = useSelector(CompanyName);

  const email = useSelector(CandidateEmail);
  const questionListObj = useSelector(screeningListObjRedux);
  const reduxscreeningWorkTypeList = useSelector(screeningWorkTypeListRedux);
  const reduxscreeningExperience = useSelector(screeningExperienceRedux);
  const reduxscreeningNationalityIds = useSelector(screeningNationalityIdsRedux);
  const reduxscreeningCurrencyType = useSelector(screeningCurrencyTypeRedux);
  const reduxscreeningSalaryExpectation = useSelector(screeningSalaryExpectationRedux);
  const reduxScreeningLocation = useSelector(screeningLocationRedux);

  useMixpanelScreeningNextClick(triggerEvent, application);

  // Flag for show Location instead of work permit
  const showLocationFlag = locationInfo?.country === 'TR';

  const submitAnswers = async () => {
    setButtonLoading(true);

    // (Email = 10), (EncryptUserId = 20), (EncryptEmail = 30);
    const data = {
      userInfo: email,
      userInfoType: 10,
      workTypes: reduxscreeningWorkTypeList,
      salaryExpectation: reduxscreeningSalaryExpectation,

      // If location is showed and user has no pre-existing work permit data, set it to TR
      nationalityIds:
        reduxscreeningNationalityIds?.length > 0
          ? reduxscreeningNationalityIds
          : [questionListObj?.nationality?.nationalities?.filter(x => x?.name === 'TURKEY')[0]?.id],

      currencyType: reduxscreeningCurrencyType,
      experience: reduxscreeningExperience,
      locationId: reduxScreeningLocation
    };
    const resp = await dispatch(saveScreeningAnswerAction(data));
    if (resp?.meta?.requestStatus === 'fulfilled') {
      setTriggerEvent(true);
      closeScreening();
    }
    setButtonLoading(false);
  };

  useEffect(() => {
    function isAlreadyAnsweredCheck(questionListObj) {
      if (
        questionListObj.experience.currentExperience ||
        questionListObj.workType.currentWorkTypeAnswers?.length !== 0 ||
        questionListObj.nationality.currentNationality?.length !== 0 ||
        questionListObj.salaryExpectation.currentCurrencyType ||
        questionListObj.salaryExpectation.currentSalaryExpectation ||
        questionListObj.location.currentLocation
      ) {
        setIsAlreadyAnswered(true);
      }
    }

    if (questionListObj) {
      isAlreadyAnsweredCheck(questionListObj);
    }
  }, [questionListObj]);

  return (
    <>
      {companyName === 'Brisa' ? (
        <>
          <div className={styles.title}>{t('screeningTitleBrisa')}</div>
          <span className={styles.subtitle}>{t('screeningSubtitleBrisa')}</span>
        </>
      ) : isAlreadyAnswered ? (
        <>
          <div className={styles.title}>{t('screeningTitleAnswered')}</div>
          <span className={styles.subtitle}>{t('screeningSubtitle1Answered')}</span>
          <span className={styles.subtitle}>{t('screeningSubtitle2Answered')}</span>
        </>
      ) : (
        <>
          <div className={styles.title}>{t('screeningTitleNotAnswered')}</div>
          <span className={styles.subtitle}>{t('screeningSubtitle1NotAnswered')}</span>
          <span className={styles.subtitle}>{t('screeningSubtitle2NotAnswered')}</span>
        </>
      )}

      <Card>
        {locationInfo ? (
          <>
            {isAlreadyAnswered && (
              <div className={styles.alreadyAnsweredTextWrapper}>
                <Trans i18nKey="screeningCardHeader">
                  We have your answers, are they up to date? If not,
                  <text style={{ fontFamily: 'Jost-500' }}> you can update them easily!</text>
                </Trans>
              </div>
            )}
            {questionListObj && (
              <>
                <ExperienceQuestion content={questionListObj.experience} />
                <WorkTypeQuestion content={questionListObj.workType} />
                {showLocationFlag ? (
                  <LocationQuestion
                    content={questionListObj?.location}
                    setWorkPermitState={() =>
                      dispatch(
                        setScreeningNationalityIds(questionListObj?.nationality?.currentNationality)
                      )
                    }
                  />
                ) : (
                  <WorkPermitQuestion
                    content={questionListObj.nationality}
                    setLocationState={() =>
                      dispatch(setScreeningLocation(questionListObj?.location?.currentLocation))
                    }
                  />
                )}
                <SalaryQuestion
                  content={questionListObj.salaryExpectation}
                  locationCurrency={currency}
                />
              </>
            )}

            <div className={styles.buttonWrapper}>
              <CustomButton
                textField={isAlreadyAnswered ? t('screeningSaveButton') : t('screeningNextButton')}
                buttonOnClick={() => {
                  submitAnswers();
                }}
                size="medium"
                loading={buttonLoading}
                isDisabled={
                  reduxscreeningWorkTypeList?.length === 0 ||
                  !reduxscreeningSalaryExpectation ||
                  reduxscreeningSalaryExpectation === '' ||
                  reduxscreeningSalaryExpectation === '0' ||
                  reduxscreeningSalaryExpectation.toString().includes('.') ||
                  reduxscreeningSalaryExpectation.toString().includes(',') ||
                  reduxscreeningSalaryExpectation.toString().includes('-') ||
                  parseInt(reduxscreeningSalaryExpectation, 10) === 0 ||
                  (reduxscreeningNationalityIds?.length === 0 && !showLocationFlag) ||
                  !reduxscreeningCurrencyType ||
                  !reduxscreeningExperience ||
                  (!reduxScreeningLocation && showLocationFlag)
                }
              />
            </div>
          </>
        ) : (
          <Skeleton type="salary" />
        )}
      </Card>
    </>
  );
};

ScreeningCardComponent.propTypes = {
  closeScreening: PropTypes.func, // closeScreening is a function
  application: PropTypes.bool // application is a boolean
};

ScreeningCardComponent.defaultProps = {
  closeScreening: () => {}, // Default function that does nothing
  application: false // Default value for application is false
};

export default ScreeningCardComponent;

const Card = styled.div`
  background-color: white;
  margin-top: 2.4rem;
  box-shadow: 0px 1px 24px rgba(208, 208, 208, 0.15);
  border-radius: 12px;
  width: 100%;
  padding: 2.6rem 3.2rem 2rem;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  overflow: hidden;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: ${ASSESSMENT_FLOW_CODING_MOBILE_THRESHOLD}px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }
`;
