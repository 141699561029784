import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import CandidateResultPageContent from '../Components/CandidateResultPageContent';
import Loading from '../../../Common/Components/Loading';
import ellipse from '../../../images/Candidate/TestPages/resultBackground.svg';
import {
  applicantAssessmentDetailWithEmailAction,
  getScreeningQuestionsAction
} from '../../../redux/AssessmentSession/actions';
import {
  CandidateEmail,
  screeningLoadingRedux,
  candidatePhone,
  isCvNeededRedux,
  isScreeningNeededRedux
} from '../../../redux/AssessmentSession/selectors';
import useMailOTPVerification from '../Utils/useMailOTPVerification';
import {
  ASSESSMENT_FLOW_CODING_MOBILE_THRESHOLD,
  ASSESSMENT_FLOW_MOBILE_THRESHOLD
} from '../../../utils/helpers';
import Header from '../Components/Header/Header';
import styles from '../Styles/CandidateResultPage.module.css';
import useGetUserAutoApply from '../Utils/useGetUserAutoApply';

const CandidateResultPage = ({ application }) => {
  const { search } = useLocation();
  const { id } = useParams();
  const assessmentId = new URLSearchParams(search).get('assessment');
  const email = useSelector(CandidateEmail);
  const phone = useSelector(candidatePhone);
  const isCvNeeded = useSelector(isCvNeededRedux);
  const isScreeningNeeded = useSelector(isScreeningNeededRedux);
  const candidateIsExist = localStorage.getItem('isCandidateExist');
  const isOtpNeeded = localStorage.getItem('isOtpNeeded') === 'true';
  const dispatch = useDispatch();
  const [style, setStyle] = useState({
    position: 'absolute',
    height: '20%',
    transition: '0.5s',
    width: '100%',
    backgroundColor: '#fafafa'
  });
  const [innerPhone, setInnerPhone] = useState();
  const [isAlreadyPassed, setIsAlreadyPassed] = useState();
  const [loadingAssessmentInfo, setLoadingAssessmentInfo] = useState(true);

  const { isVerified, MailOTPHandler } = useMailOTPVerification(
    candidateIsExist !== "null" ? (application ? true : !isOtpNeeded) : false,
    application,
    isScreeningNeeded,
    isCvNeeded
  );

  useEffect(async () => {
    if (isVerified) {
      setLoadingAssessmentInfo(true);
      const jsonData = {
        companyAssessmentKey: id || assessmentId,
        email,
        kariyerAtsUtm: localStorage.getItem('kariyernetToken')
      };
      const resp = await dispatch(applicantAssessmentDetailWithEmailAction(jsonData));
      const innerPhone = resp.payload.phone;
      setIsAlreadyPassed(localStorage.getItem('isCandidateAlreadyPassed'));
      // setIsAlreadyPassed(resp.payload.isAlreadyPassed);
      if (innerPhone && !innerPhone.startsWith('+')) {
        setInnerPhone(`+${innerPhone}`);
      } else {
        setInnerPhone(phone);
      }
      setLoadingAssessmentInfo(false);
      setTimeout(() => {
        setStyle({ ...style, height: '100%' });
      }, 200);
    }
  }, [isVerified]);

  const { autoApplyStatus, autoApplyLoading } = useGetUserAutoApply(id, assessmentId, email);
  const screeningLoadingFlag = useSelector(screeningLoadingRedux);

  useEffect(() => {
    if (isVerified) {
      // Email = 10,
      // EncryptUserId = 20,
      // EncryptEmail = 30
      if (assessmentId || id) {
        const data = {
          UserInfo: email, // email
          UserInfoType: 10
        };

        dispatch(getScreeningQuestionsAction(data));
      }
    }
  }, [assessmentId, id, isVerified]);

  if (!isVerified) {
    return <MailOTPHandler />;
  }

  return (
    <ContentBody>
      {loadingAssessmentInfo || autoApplyLoading || screeningLoadingFlag ? (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      ) : (
        <PageWrapper>
          <div className={styles.CandidateResultPageWrapper}>
            {/* <Background className={styles.background */}
            <Ellipse />
            <div style={{ zIndex: 1 }}>
              <Header transparent />
            </div>
            <ContentWrapper>
              <CandidateResultPageContent
                application={application}
                id={id || assessmentId}
                innerPhone={innerPhone}
                isAlreadyPassed={isAlreadyPassed}
                autoApplyStatus={autoApplyStatus}
              />
            </ContentWrapper>
          </div>
        </PageWrapper>
      )}
    </ContentBody>
  );
};

CandidateResultPage.propTypes = {
  application: PropTypes.bool // Optional boolean prop
};

CandidateResultPage.defaultProps = {
  application: false
};

export default CandidateResultPage;

const ContentBody = styled.div`
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  background-color: #fafafa;
  position: relative;
  display: block;
  /*background-size: 100vw auto;
  background-position-y: top;
  background-repeat: no-repeat;
  background-image: url(${ellipse});
  z-index: 1;*/
  overflow-x: hidden;
`;

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  justify-content: flex-start;
  display: flex;
  z-index: 0;
  flex-direction: column;
`;
const Ellipse = styled.div`
  width: 120vw;
  position: absolute;
  z-index: 1;
  transform: rotate(180deg);
  height: 40vh;
  background: #f1eff7;
  border-radius: 50% / 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  left: -10vw;
  @media screen and (max-width: ${ASSESSMENT_FLOW_CODING_MOBILE_THRESHOLD}px) {
    border-radius: 100% / 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const ContentWrapper = styled.div`
  width: 60%;
  min-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px;
  max-width: 700px;
  padding: 0.4rem 2.6rem 2.6rem 2.6rem; // FIXME
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: fit-content;
  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    max-width: none;
    min-width: 0;
    width: 95%;
    padding: 2.4rem;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;
