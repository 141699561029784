import React, { useEffect, useRef, useState } from 'react';
import styles from './ProfileBanner.module.css';
import CurrentUsageContent from './CurrentUsage/CurrentUsageContent';
import SettingsContent from './Settings/SettingsContent';

const ProfileBanner = ({ show, boundingBox, setShow, parentRef, width }) => {
  const ref = useRef();

  function useOutsideAlerter(ref, buttonwrapper) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref.current &&
          buttonwrapper.current &&
          !buttonwrapper.current.contains(event.target) &&
          !ref.current.contains(event.target)
        ) {
          setShow(false);
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, parentRef]);
  }

  useOutsideAlerter(ref, parentRef);
  const [newLeft, setNewLeft] = useState(null);
  useEffect(() => {
    if (boundingBox?.left - 145 + 290 > width) {
      setNewLeft(boundingBox?.left - 145 - (boundingBox?.left + 145 - width) - 30);
    } else {
      setNewLeft();
    }
  }, [boundingBox]);

  return (
    <>
      {show && (
        <div
          id="profile_Banner"
          style={{
            display: show ? 'block' : 'none',
            top: boundingBox?.top + 40,
            opacity: show ? 1 : 0,
            left: newLeft || boundingBox?.left - 145
          }}
          ref={ref}
          className={styles.container}
        >
          <CurrentUsageContent />
          <div className={styles.borderBottom} />
          <SettingsContent />
        </div>
      )}
    </>
  );
};

export default ProfileBanner;
