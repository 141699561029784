/* eslint-disable */
import mixpanel from 'mixpanel-browser';

mixpanel.init(process.env.REACT_APP_MIXPANEL_API_KEY, {debug: false});

/**
 * UTM related stuff
 * By default, mixpanel records first touch events for utm values.
 *
 * campaignParams() method manually overwrites default utm values with
 * utm values present in url
 */
// #region

function getQueryParam(url, param) {
  // Expects a raw URL
  param = param.replace(/[[]/, '[').replace(/[]]/, ']');
  var regexS = '[?&]' + param + '=([^&#]*)',
    regex = new RegExp(regexS),
    results = regex.exec(url);
  if (results === null || (results && typeof results[1] !== 'string' && results[1].length)) {
    return '';
  } else {
    return decodeURIComponent(results[1]).replace(/\W/gi, ' ');
  }
}
function campaignParams() {
  var campaign_keywords = 'utm_source utm_medium utm_campaign utm_content utm_term utm_name'.split(
      ' '
    ),
    kw = '',
    params = {},
    first_params = {};
  var index;
  for (index = 0; index < campaign_keywords.length; ++index) {
    kw = getQueryParam(document.URL, campaign_keywords[index]);
    if (kw.length) {
      params[campaign_keywords[index]] = kw;
      //   params[campaign_keywords[index] + ' [last touch]'] = kw;
    }
  }
  mixpanel.register(params);
}

campaignParams();
// #endregion

/**
 * To be able to exclude certain companies from tracking, we have to overwrite default
 * track() method of mixpanel.
 * For other events, we delegate each method to mixpanel library.
 *
 * Unused and probably wont be used methods are not added here.
 */
export const EXCLUDED_COMPANY_LIST = ['Kariyernet', 'A101', 'Kariyer360'];
class mixpanelInstance {
  // Used ones
  // #region
  track(event_name, properties, optionsOrCallback, callback) {
    const companyName = properties?.['Company Name'];
    const isExcluded = EXCLUDED_COMPANY_LIST?.includes(companyName);
    if (!isExcluded) {
      mixpanel.track(event_name, properties, optionsOrCallback, callback);
    }
  }

  identify(unique_id) {
    mixpanel.identify(unique_id);
  }

  people = mixpanel.people;

  set_group(group_key, group_ids, callback) {
    mixpanel.set_group(group_key, group_ids, callback);
  }

  get_group(group_key, group_id) {
    return mixpanel.get_group(group_key, group_id);
  }

  // #endregion

  // Might use in future
  // #region
  add_group(group_key, group_id, callback) {
    mixpanel.add_group(group_key, group_id, callback);
  }

  alias(alias, original) {
    mixpanel.alias(alias, original);
  }

  disable(events) {
    mixpanel.disable(events);
  }

  register(props, days) {
    mixpanel.register(props, days);
  }

  register_once(props, default_value, days) {
    mixpanel.register_once(props, default_value, days);
  }

  remove_group(group_key, group_ids, callback) {
    mixpanel.remove_group(group_key, group_ids, callback);
  }

  reset() {
    mixpanel.reset();
  }

  track_with_groups(event_name, properties, groups, callback) {
    mixpanel.track_with_groups(event_name, properties, groups, callback);
  }

  unregister(property) {
    mixpanel.unregister(property);
  }
  // #endregion
}

const instance = new mixpanelInstance();

export default instance;
