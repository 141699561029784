import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import PageContainer from '../../../Common/Pages/PageContainer';
import Dropdown from '../../../Common/Dropdown/Dropdown';
import Checkbox from '../../../Common/Components/Checkbox';
import {
  getPositionDetailsAction,
  modifyPositionDetailsAction,
  getCompanyAssessmentListAction,
  getCompanyListAction
} from '../../Api/actions';
import CustomButton from '../../../Common/Components/CustomButton';
import styles from './EditAssessmentDetail.module.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'draft-js-emoji-plugin/lib/plugin.css';

const EditAssessmentDetail = () => {
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [assessmentDetails, setAssessmentDetails] = useState(null);
  const [unEditedDetails, setUnEditedDetails] = useState(null);
  const [emptyDate, setEmptyDate] = useState(true);
  const [loading, setLoading] = useState(false);
  const [activeAssessments, setActiveAssessments] = useState(true);
  const [corporate, setCorporate] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companyAssessmentList, setCompanyAssessmentList] = useState([]);

  const [dueDate, setDueDate] = useState(null);
  const [descEnEditorState, setDescEnEditorState] = useState(() => EditorState.createEmpty());
  const [descTrEditorState, setDescTrEditorState] = useState(() => EditorState.createEmpty());
  const [titleEnEditorState, setTitleEnEditorState] = useState(() => EditorState.createEmpty());
  const [titleTrEditorState, setTitleTrEditorState] = useState(() => EditorState.createEmpty());

  useEffect(() => {
    fetcCompanyList();
  }, []);

  useEffect(() => {
    setSelectedPosition(null);
    setCompanyAssessmentList([]);
    setUnEditedDetails(null);
    setAssessmentDetails(null);
  }, [selectedCompany]);

  useEffect(() => {
    setUnEditedDetails(null);
    setAssessmentDetails(null);
  }, [activeAssessments, selectedPosition]);

  useEffect(() => {
    setAssessmentDetails(null);
    setUnEditedDetails(null);
    setSelectedPosition(null);
    setCompanyAssessmentList([]);
  }, [activeAssessments]);

  useEffect(() => {
    if (selectedCompany) {
      fetchCompanyAssessmentList(selectedCompany.id);
    }
  }, [selectedCompany, activeAssessments]);

  const fetcCompanyList = async () => {
    const resp = await getCompanyListAction();
    if (resp.data) {
      const companies = resp.data.map(company => {
        return {
          id: company.id,
          name: company.name
        };
      });
      setCorporate(companies);
    }
  };

  const fetchCompanyAssessmentList = async companyId => {
    const resp = await getCompanyAssessmentListAction({ companyId, IsActive: activeAssessments });
    if (resp.data) {
      const positions = resp.data.map(position => {
        return {
          id: position.id,
          name: position.name
        };
      });
      setCompanyAssessmentList(positions);
    }
  };

  useEffect(() => {
    if (selectedPosition) {
      fetchDetails(selectedPosition.id);
    }
  }, [selectedPosition, activeAssessments]);

  const fetchDetails = async id => {
    setLoading(true);
    const resp = await getPositionDetailsAction({ companyAssessmentId: id }).finally(() =>
      setLoading(false)
    );
    if (resp.data) {
      setAssessmentDetails(resp.data);
      setUnEditedDetails(resp.data);

      setDescEnEditorState(fromHtml(resp.data.descriptionEn));
      setDescTrEditorState(fromHtml(resp.data.descriptionTr));
      setTitleEnEditorState(fromHtml(resp.data.positionHeaderEn));
      setTitleTrEditorState(fromHtml(resp.data.positionHeaderTr));
      setDueDate(resp.data.dueDate);

      if (resp.data.dueDate === null) {
        setEmptyDate(true);
      } else {
        setEmptyDate(false);
      }
    }
  };

  useEffect(() => {
    if (assessmentDetails) {
      setAssessmentDetails({
        descriptionEn: draftToHtml(convertToRaw(descEnEditorState.getCurrentContent())),
        descriptionTr: draftToHtml(convertToRaw(descTrEditorState.getCurrentContent())),
        positionHeaderEn: draftToHtml(convertToRaw(titleEnEditorState.getCurrentContent())),
        positionHeaderTr: draftToHtml(convertToRaw(titleTrEditorState.getCurrentContent())),
        dueDate: emptyDate ? null : assessmentDetails.dueDate
      });
    }
  }, [descEnEditorState, descTrEditorState, titleEnEditorState, titleTrEditorState, emptyDate]);

  const fromHtml = html => {
    const blocksFromHTML = htmlToDraft(html);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    return EditorState.createWithContent(state);
  };


  const handleSave = async () => {
    const data = {
      companyAssessmentId: selectedPosition.id,
      descriptionEn: draftToHtml(convertToRaw(descEnEditorState.getCurrentContent())),
      descriptionTr: draftToHtml(convertToRaw(descTrEditorState.getCurrentContent())),
      positionHeaderEn: draftToHtml(convertToRaw(titleEnEditorState.getCurrentContent())),
      positionHeaderTr: draftToHtml(convertToRaw(titleTrEditorState.getCurrentContent())),
      dueDate: emptyDate ? null : assessmentDetails.dueDate
    };

    setLoading(true);
    await modifyPositionDetailsAction(data).finally(() => {
      fetchDetails(selectedPosition.id).finally(() => setLoading(false));
    });
  };

  const handleReset = () => {
    setAssessmentDetails({
      descriptionEn: unEditedDetails.descriptionEn,
      descriptionTr: unEditedDetails.descriptionTr,
      positionHeaderEn: unEditedDetails.positionHeaderEn,
      positionHeaderTr: unEditedDetails.positionHeaderTr,
      dueDate: unEditedDetails.dueDate
    });

    setDescEnEditorState(fromHtml(unEditedDetails.descriptionEn));
    setDescTrEditorState(fromHtml(unEditedDetails.descriptionTr));
    setTitleEnEditorState(fromHtml(unEditedDetails.positionHeaderEn));
    setTitleTrEditorState(fromHtml(unEditedDetails.positionHeaderTr));

    if (unEditedDetails.dueDate === null) {
      setEmptyDate(true);
    } else {
      setEmptyDate(false);
    }
  };

  return (
    <PageContainer>
      <div className={styles.container}>
        <div className={styles.upperRow}>
          <div className={styles.activeSelection}>
            <label className={styles.dateLabel}>Active Assessments: </label>
            <Checkbox
              myChecked={activeAssessments}
              size={20}
              myOnClick={_ => {
                setActiveAssessments(!activeAssessments);
              }}
            />
          </div>

          <CustomButton
            textField="Reset"
            title="Reset"
            buttonOnClick={handleReset}
            customStyle={{
              width: '100px',
              height: '100%'
            }}
            isDisabled={JSON.stringify(assessmentDetails) === JSON.stringify(unEditedDetails)}
            loading={loading}
          />
          <Dropdown
            title="Company"
            placeholder="Select Company"
            searchBarPlaceholder="Search Company"
            list={corporate}
            openItemCount={7}
            selectedOption={setSelectedCompany}
            setSelectedOption={_ => setSelectedCompany(_)}
            isSearchable
            height="5rem"
            wrapperStyle={{ width: '300px' }}
          />
          <Dropdown
            title="Position"
            placeholder="Select Position"
            searchBarPlaceholder="Search Position"
            list={companyAssessmentList}
            openItemCount={7}
            selectedOption={selectedPosition}
            setSelectedOption={_ => setSelectedPosition(_)}
            isSearchable
            height="5rem"
            wrapperStyle={companyAssessmentList.length > 0 ? { width: '300px', opacity: '1' } : { width: '300px', opacity: '0.5' }}
          />
          <CustomButton
            textField="Save"
            title="Save"
            buttonOnClick={handleSave}
            customStyle={{
              width: '100px',
              height: '100%'
            }}
            isDisabled={JSON.stringify(assessmentDetails) === JSON.stringify(unEditedDetails)}
            loading={loading}
          />
        </div>
        {assessmentDetails && (
          <div className={styles.positionDetailSection}>
            <div className={styles.leftColumn}>
              <div className={styles.dateSection}>
                <div className={styles.dateLabel}>Due Date: </div>
                <Checkbox
                  myChecked={!emptyDate}
                  size={20}
                  myOnClick={_ => {
                    setEmptyDate(!_.target.checked);
                    if (!_.target.checked) {
                      setAssessmentDetails({ ...assessmentDetails, dueDate: null });
                      setDueDate(null);
                    }
                  }}
                />
                {/** Date Picker */}
                {!emptyDate && (
                  <>
                    <input
                      type="date"
                      value={dueDate && dueDate.split('T')[0]}
                      className={styles.datePicker}
                      onChange={e => {
                        setAssessmentDetails({ ...assessmentDetails, dueDate: e.target.value });
                        setDueDate(e.target.value.split('T')[0]);
                      }}
                    />
                  </>
                )}
              </div>

              <div className={styles.inputSection}>
                <span className={styles.inputTitle}>Description (EN)</span>
                <Editor
                  editorState={descEnEditorState}
                  onEditorStateChange={_ => {
                    setDescEnEditorState(_);
                  }}
                  toolbarClassName={styles.EditorToolbar}
                  wrapperClassName={styles.EditorWrapper}
                  editorClassName={styles.Editor}
                />
              </div>
            </div>
            <div className={styles.midColumn}>
              <span className={styles.inputTitle}>Description (TR)</span>
              <Editor
                editorState={descTrEditorState}
                onEditorStateChange={_ => {
                  setDescTrEditorState(_);
                }}
                toolbarClassName={styles.EditorToolbar}
                wrapperClassName={styles.EditorWrapper}
                editorClassName={styles.Editor}
              />
            </div>

            <div className={styles.rightColumn}>
              <div className={styles.inputSection}>
                <span className={styles.inputTitle}>Title (EN)</span>
                <Editor
                  editorState={titleEnEditorState}
                  onEditorStateChange={_ => {
                    setTitleEnEditorState(_);
                  }}
                  toolbarClassName={styles.EditorToolbar}
                  wrapperClassName={styles.EditorWrapper}
                  editorClassName={styles.Editor}
                />
              </div>
              <div className={styles.inputSection}>
                <span className={styles.inputTitle}>Title (TR)</span>
                <Editor
                  editorState={titleTrEditorState}
                  onEditorStateChange={_ => {
                    setTitleTrEditorState(_);
                  }}
                  toolbarClassName={styles.EditorToolbar}
                  wrapperClassName={styles.EditorWrapper}
                  editorClassName={styles.Editor}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </PageContainer>
  );
};

export default EditAssessmentDetail;
