import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { useTranslation } from 'react-i18next';

import {
  candidateCvPathSelector,
  candidatePositionsSelector,
  candidateProfileSelector,
  loadingForPersonalInfoSelector,
  personalInfoExperienceSelector,
  personalInfoNationalitySelector,
  personalInfoSalarySelector,
  personalInfoWorkTypeSelector
} from '../../../../redux/candidateDetail/selectors';
import styles from './ResumeTab.module.css';
import Animation from '../../../../Common/Components/Animations/Animation';
import { userSelector } from '../../../../redux/auth/selectors';
import { genericMixpanelCandidateDetailEventGenerator } from '../helpers';
import download from './Images/resumeDownloadIcon.svg';
import newTab from './Images/resumeNewTabIcon.svg';
import Tooltip from '../../../../Common/Components/Tooltip';
import { downloadFileHelper } from '../../../../utils/downloadFileHelper';

const ResumeTab = ({ isHidden }) => {
  const frameRef = useRef();
  const cvPath = useSelector(candidateCvPathSelector);
  const candidateProfile = useSelector(candidateProfileSelector);
  const [finalPath, setFinalPath] = useState();
  const [pdfUrl, setPdfUrl] = useState();

  const { t } = useTranslation(['candidateDetail'])

  function handleFrameLoad() {
    let cvpath = cvPath?.path;
    if (cvpath) {
      cvpath = cvpath.replace('https://api.coens.io', '');
      cvpath = cvpath.replace('https://coensioapi.solvera.com.tr', '');
      if (isMobile) {
        setFinalPath(
          `https://docs.google.com/gview?embedded=true&url=${process.env.REACT_APP_IMAGE_API}${cvpath}`
        );
      } else {
        setFinalPath(`${process.env.REACT_APP_IMAGE_API}${cvpath}#toolbar=0`);
      }
      setPdfUrl(`${process.env.REACT_APP_S3_URL}${cvpath}`);
    }
  }

  useEffect(() => {
    handleFrameLoad();
  }, [cvPath]);

  const [hasFrameLoaded, setHasFrameLoaded] = useState(false);

  const [intervalId, setIntervalId] = useState(-1);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (!isMobile) {
      setHasFrameLoaded(true);
    }

    if (!hasFrameLoaded && isMobile) {
      setIntervalId(
        setInterval(() => {
          const embedElement = document.getElementById('cvPath-embedTag')?.contentWindow;
          if (embedElement?.length !== 1) {
            setFinalPath(null);
            setRefresh(true);
          } else {
            setHasFrameLoaded(true);
          }
        }, 2500)
      );
    } else {
      clearInterval(intervalId);
    }
  }, [hasFrameLoaded]);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
      handleFrameLoad();
    }
  }, [refresh]);

  // Mixpanel Resume - Pageview
  // #region
  const currentUser = useSelector(userSelector);
  const positionsList = useSelector(candidatePositionsSelector);
  const loadingForPersonalInfoRedux = useSelector(loadingForPersonalInfoSelector);
  const personalInfoExperienceRedux = useSelector(personalInfoExperienceSelector);
  const personalInfoNationalityRedux = useSelector(personalInfoNationalitySelector);
  const personalInfoSalaryRedux = useSelector(personalInfoSalarySelector);
  const personalInfoWorkTypeRedux = useSelector(personalInfoWorkTypeSelector);

  useEffect(() => {
    if (
      isHidden === false &&
      currentUser &&
      positionsList &&
      positionsList?.length > 0 &&
      candidateProfile &&
      !loadingForPersonalInfoRedux
    ) {
      genericMixpanelCandidateDetailEventGenerator(
        'Resume - Pageview',
        currentUser,
        positionsList,
        candidateProfile,
        loadingForPersonalInfoRedux,
        personalInfoExperienceRedux,
        personalInfoNationalityRedux,
        personalInfoSalaryRedux,
        personalInfoWorkTypeRedux
      );
    }
  }, [isHidden, currentUser, positionsList, candidateProfile, loadingForPersonalInfoRedux]);

  // #endregion

  async function downloadResume() {
    await downloadFileHelper(
      pdfUrl,
      candidateProfile?.fullName
        ? `${candidateProfile?.fullName}_Resume.pdf`
        : candidateProfile?.email
        ? `${candidateProfile?.email}_Resume.pdf`
        : 'CandidateResume.pdf'
    );
  }

  return (
    <>
      <div
        style={{
          visibility: !hasFrameLoaded && !isHidden ? 'visible' : 'hidden',
          height: (hasFrameLoaded || isHidden) && 0
        }}
        className={styles.animationWraper}
      >
        <Animation />
      </div>

      <div
        className={styles.wrapper}
        style={{
          maxHeight: !isHidden && hasFrameLoaded ? 'unset' : 0,
          visibility: !isHidden && hasFrameLoaded ? 'visible' : 'hidden'
        }}
      >
        <div className={styles.frameWrapper}>
          {!isMobile ? (
            <div className={styles.webResumeHeader}>
              <text className={styles.resumeName}>
                {candidateProfile?.fullName || candidateProfile?.email || 'Resume'}
              </text>
              <div className={styles.resumeIconWrapper}>
                <Tooltip style={{ fontSize: '12px' }} content={t('downloadResume')}>
                  <img src={download} alt="downloadPDF" onClick={downloadResume} />
                </Tooltip>
                <Tooltip style={{ fontSize: '12px' }} content={t('openInTab')}>
                  <img
                    src={newTab}
                    alt="openPDFinNewTab"
                    onClick={() => window.open(pdfUrl, '_blank')}
                  />
                </Tooltip>
              </div>
            </div>
          ) : null}

          <iframe
            ref={frameRef}
            id="cvPath-embedTag"
            src={finalPath}
            frameBorder="0"
            allowFullScreen
            title="cv"
            key={finalPath}
            className={styles.cvFrame}
          />
        </div>
      </div>
    </>
  );
};

export default ResumeTab;
