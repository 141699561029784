import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AddAssessmentContent from './AddAssessmentContent';
import styles from '../Styles/AddAssessmentBody.module.css';
import {
  resetMostPopularRootAssessmentList,
  setFilteredRootAssessmentList
} from '../../../redux/assessmentsVol2/slice';

export default function AddAssessmentBody() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    setLoading(true);
    await dispatch(resetMostPopularRootAssessmentList());
    await dispatch(setFilteredRootAssessmentList([]));
    setLoading(false);
  }, []);

  return (
    <section id="ContentBody" className={styles.ContentBody}>
      <div className={styles.AssessmentContent}>{loading ? null : <AddAssessmentContent />}</div>
    </section>
  );
}
