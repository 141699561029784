export default {
  loading: false,
  cardInfo: null,
  countries: null,
  currentPlan: null,
  currentUsage: {},
  addOns: null,
  packageDetail: null,
  billingInfo: null,
  unsubscribeModalShow: false,
  unsubscribeAddOnModalShow: false,
  // toBeCancelledAddOn: [],
  unsubscribeSuccessShow: false,
  purchaseModalPackageId: {},
  purchaseModalShow: false,
  planUnsubscribedModalShow: false,
  editBillingModalShow: false,
  paymentMethodModalShow: '',
  plansDetailJSON: null,
  contactSalesSuccess: false,
  toBeCancelledAddOnType: '',
  showPackageDetail: false,
  downgradeModalShow: false,
  downgradeSuccess: false,
  upgradeModalShow: false,
  upgradeSuccess: false,
  editCompanyInfoShow: false,
  profileObj: null,
  unsubscribeAddOnInfoCount: null,
  unsubscribeAddOnItemCode: null,
  errorInPlan: false,
  errorTextInPlan: '',
  errorInSeePlans: false,
  errorTextInSeePlans: '',
  caAddOns: [],
  asAddOns: [],
  abortDowngradeSuccessShow: false,
  toBePurchasedAddOn: {},
  purchaseSuccessShow: false,
  paymentFailedErrorMessage: '',
  invoiceList: {},
};
