import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WelcomeCandidatePage from './WelcomeCandidatePage';
import { ApplicationState, isOtpNeededRedux } from '../../redux/AssessmentSession/selectors';
import { setApplicationState } from '../../redux/AssessmentSession/slice';
import CandidateResultPage from '../../Assessment/Test/Pages/CandidateResultPage';

const ApplicationPage = () => {
  const state = useSelector(ApplicationState);
  const isOtpRequired = useSelector(isOtpNeededRedux);
  const dispatch = useDispatch();

  /* Prevent starting from the result page */
  useEffect(() => {
    dispatch(setApplicationState('email'));
  }, []);

  useEffect(() => {
    console.log('isOtpRequired', isOtpRequired);
  }, [isOtpRequired]);

  return (
    <>
      {state === 'email' ? (
        <WelcomeCandidatePage application />
      ) : (
         <CandidateResultPage application />
      )}
    </>
  );
};

export default ApplicationPage;
