import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import RadioButton from '../../../../Common/Components/RadioButtonItem';
import { setScreeningExperience } from '../../../../redux/AssessmentSession/slice';
import styles from './ScreeningCardComponent.module.css';

// Input Type: 30
const SingleSelectRadioButton = ({ text, id, selected, setSelected }) => {
  return (
    <div className={styles.checkboxWrapper}>
      <RadioButton
        checkboxBoxStyle={{ border: '0.9px solid #585858' }}
        isActive={selected}
        activate={setSelected}
        item={text}
        myKey={id}
      />
    </div>
  );
};

SingleSelectRadioButton.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  setSelected: PropTypes.func.isRequired
};

const ExperienceQuestion = ({ content }) => {
  const { t } = useTranslation(['application']);
  const dispatch = useDispatch();

  const { currentExperience: currentExpId, experiences: optionList } = content || {};
  const [selectedId, setSelectedId] = useState(currentExpId);

  useEffect(() => {
    dispatch(setScreeningExperience(selectedId));
  }, [selectedId]);

  return (
    <div className={styles.questionWrapper}>
      <div className={styles.question}>{t('experienceQuestionText')}</div>
      <div className={styles.wrapper}>
        {optionList?.map(e => (
          <SingleSelectRadioButton
            key={e.id}
            text={e.name}
            id={e.id}
            selected={selectedId === e.id}
            setSelected={setSelectedId}
          />
        ))}
      </div>
    </div>
  );
};

ExperienceQuestion.propTypes = {
  content: PropTypes.object.isRequired
};

export default ExperienceQuestion;
