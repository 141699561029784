import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import CloseButton from '../../../Common/Components/CloseButton';
import CustomButton from '../../../Common/Components/CustomButton';
import TextInput from '../../../Common/Components/TextInput';
import styles from '../Styles/InviteUserModal.module.css';
import { setNewlyInvitedIDList } from '../../../redux/assessmentsVol2/slice';
import { ReactComponent as Success } from '../../../images/Common/SFR_success.svg';
import { inviteUser, getUserListByCompanyAction } from '../../../redux/assessmentsVol2/actions';
import { userTypeOfAuthComponent } from '../../../redux/addAssessment/selectors';
import { newlyInvitedID, newlyInvitedIDList } from '../../../redux/assessmentsVol2/selectors';
import { userSelector } from '../../../redux/auth/selectors';
import isEmail from '../../../utils/isEmail';
import EmailText from '../../../Common/Components/EmailText';
import InfoBanner from '../../../Common/Components/InfoBanner';
import { ReactComponent as Error } from '../../../images/Common/errorSubmit.svg';
import { useWindowSize } from '../../../utils/helpers';
import MobileModal from '../../../Common/Components/MobileModal/MobileModal';
import Modal from '../../../Common/CustomModal/Modal';

export default function InviteUserModal({
  show,
  onHide,
  createAssessment,
  setErrorText = () => {},
  setSuccessShow = () => {},
  setErrorShow = () => {},
}) {
  const [windowW] = useWindowSize();

  const dispatch = useDispatch();
  const [myName, setName] = useState();
  const [mySurname, setSurname] = useState();
  const [mail, setMail] = useState();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const userTypeee = useSelector(userTypeOfAuthComponent);
  const newlyInvited = useSelector(newlyInvitedID);
  const newlyInvitedList = useSelector(newlyInvitedIDList);
  const myUser = useSelector(userSelector);

  const [isSuccessfullyInvited, setIsSuccessfullyInvited] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);

  const { t } = useTranslation(['createAssm'])

  useEffect(() => {
    setIsSuccessfullyInvited();
    setErrorMessage();
  }, []);

  const handleInviteUser = async () => {
    setButtonLoading(true);
    const data = {
      name: myName,
      surname: mySurname,
      email: mail,
      companyId: myUser.companyId,
      userGroupTypeId:
        userTypeee === 'rec' ? 'KRQ6UcYKqmMHd%2f7Q34W22Q%3d%3d' : '9EoPhO4wpBZgHNr4WAQqfQ%3d%3d'
    };
    const invite = await dispatch(inviteUser(data));

    if (invite.meta.requestStatus === 'rejected') {
      setIsSuccessfullyInvited(false);
      setErrorMessage(invite.payload?.message);

      if (windowW <= 700) {
        if (invite.payload?.message === t('thereIsAlreadySameMail') ) {
          setErrorText(t('alreadyRegistered'));
        } else {
          setErrorText(t('errorOccured'));
        }
        setTimeout(() => {
          setErrorShow(true);
        }, 400);
      }
    } else {
      setIsSuccessfullyInvited(true);
      setErrorMessage();

      if (windowW <= 700) {
        setSuccessShow(true);
      }
    }
    if (windowW <= 700) {
      onHide();
    }
    setButtonLoading(false);
  };

  useEffect(() => {
    if (isSuccessfullyInvited === true) {
      dispatch(getUserListByCompanyAction({ CompanyId: myUser.companyId }));
      setSuccess(true);
    }
  }, [isSuccessfullyInvited]);

  useEffect(() => {
    if (newlyInvited && show) {
      dispatch(setNewlyInvitedIDList([...newlyInvitedList, newlyInvited]));
    }
  }, [newlyInvited]);

  useEffect(() => {
    if (!show) {
      setSuccess(false);
      setName('');
      setMail('');
      setSurname('');
      setIsSuccessfullyInvited();
    }
  }, [show]);

  const WebModal = (
    <Modal
      centerHorizontally
      centerVertically
      backdropStyle={{ overflow: 'hidden' }}
      contentStyle={{
        padding: success ? '10px' : '17px 20px 16px 24px',
        width: success ? '524px' : '540px',
        background: '#ffffff',
        boxShadow: '0px 2px 18px rgba(0, 0, 0, 0.15)',
        borderRadius: '10px',
        boxSizing: 'border-box',
        border: 'none',
        fontFamily: 'Jost-400',
        height: success && '233px'
      }}
      outsideClickClose
      onClose={onHide}
      show={show}
    >
      <InfoBanner
        text={errorMessage}
        show={isSuccessfullyInvited === false}
        CustomIcon={Error}
        setStatus={() => {
          setIsSuccessfullyInvited();
          setErrorMessage();
        }}
        bannerStyle={{ backgroundColor: 'white', border: '1px solid rgba(221, 58, 58, 0.1)' }}
      />
      <div className={!success && styles.inviteUserHeader}>
        {!success && <div className={styles.inviteTitle}>{t('addNewUser')}</div>}
        {success && (
          <div>
            <CloseButton width={24} height={24} onClick={onHide} />
          </div>
        )}
      </div>
      {success ? (
        <div className={styles.successContainer}>
          <Success className={styles.successIconSmall} />
          <div className={styles.successTitleSmall}>{t('newUserInvSent')}</div>
          {!createAssessment && (
            <div className={styles.successFootNoteSmall}>
              {t('invForAssmReview')}
            </div>
          )}
        </div>
      ) : (
        <>
          <div className={styles.row1}>
            <TextInput
              style={{ marginRight: '8px' }}
              placeholder={t('namePholder')}
              onChange={e => setName(e.target.value)}
              value={myName}
            />
            <TextInput
              style={{ marginLeft: '8px' }}
              placeholder={t('surnamePholder')}
              onChange={e => setSurname(e.target.value)}
              value={mySurname}
            />
          </div>
          <div className={styles.row2}>
            <EmailText
              setValue={setMail}
              placeholder={t('email')}
              value={mail}
              inputChange={e => {
                setMail(e.target.value);
                if (!isEmail(e.target.value.trim())) {
                  setError(true);
                } else {
                  setError(false);
                }
              }}
            />
          </div>
          <div style={{ justifyContent: 'flex-end' }} className={styles.row3}>
            <CustomButton
              textField={t('cancel')}
              type={2}
              size="small"
              style={{ marginRight: '4px' }}
              buttonOnClick={onHide}
            />
            <CustomButton
              textField={t('sendInv')}
              buttonOnClick={() => {
                handleInviteUser();
              }}
              size="small"
              isDisabled={!myName || !mySurname || !mail || error}
              loading={buttonLoading}
            />
          </div>
        </>
      )}
    </Modal>
  );

  const Mobile = (
    <MobileModal
      outsideClickClose
      id="send-for-review-mobile"
      show={show}
      onClose={onHide}
      contentStyle={{ padding: '20px', minWidth: '375px' }}
      backdropStyle={{ overflowY: 'hidden' }}
    >
      <div className={styles.inviteUserHeader}>
        <div className={styles.inviteTitle}>{t('addNewUser')}</div>
        <CloseButton width={24} height={24} onClick={onHide} />
      </div>

      <>
        <div style={{ gap: '18px', display: 'flex', flexDirection: 'column' }}>
          <TextInput placeholder={t('namePholder')} onChange={e => setName(e.target.value)} value={myName} />
          <TextInput
            placeholder={t('surnamePholder')}
            onChange={e => setSurname(e.target.value)}
            value={mySurname}
          />

          <EmailText
            setValue={setMail}
            placeholder={t('email')}
            value={mail}
            inputChange={e => {
              setMail(e.target.value);
              if (!isEmail(e.target.value.trim())) {
                setError(true);
              } else {
                setError(false);
              }
            }}
          />
        </div>
        <div className={styles.mobileButtonWrapper}>
          <CustomButton
            textField={t('cancel')}
            type={8}
            size="mobile"
            style={{ marginRight: '4px' }}
            buttonOnClick={onHide}
          />
          <CustomButton
            textField={t('sendInv')}
            buttonOnClick={() => {
              handleInviteUser();
            }}
            size="mobile"
            isDisabled={!myName || !mySurname || !mail || error}
            loading={buttonLoading}
          />
        </div>
      </>
    </MobileModal>
  );
  return <>{windowW > 700 ? <>{WebModal}</> : <>{Mobile}</>}</>;
}
