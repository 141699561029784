
export async function downloadFileHelper(urlPath, fileName) {
  const resp = await fetch(
    urlPath
  );
  if (resp?.status === 200 && resp?.type === 'cors') {
    const blob = await resp.blob();
    const fileURL = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = fileURL;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }
}
