import React, { useState, useEffect, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styles from './MultiSelect.module.css'; // Import your CSS module
import CloseButton from '../CloseButton';
import useOutsideClick from '../Utils/useOutsideClick';
import useInsideClick from '../Utils/useInsideClick';
import useDebouncedValue from '../../../utils/useDebouncedValue';

const MultiSelect = ({
  id = null,
  options,
  selectedOptions,
  setSelectedOptions,
  isObject = false,
  displayValue = 'name',
  returnedProperty = null,
  placeholder = 'Select...',
  loadingFlag = false,
  searchCharacterCount = 0,
  showCheckbox = true,
  customCloseIcon = null,
  keepSearchTerm = false,
  listBadgesReverse = false,
  debounceMs = null,
  showSelectAllOption = false
}) => {
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedValue = debounceMs ? useDebouncedValue(searchTerm, debounceMs) : searchTerm;
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const { t } = useTranslation(['questionBasedAssm']);
  /* Close the dropdown when clicked outside */
  useOutsideClick(dropdownRef, () => {
    setIsOpen(false);
  });

  /* Focus the input when the dropdown is clicked */
  useInsideClick(dropdownRef, () => {
    inputRef.current.focus();
  });

  /* Focus the input when the dropdown is opened */
  useEffect(() => {
    if (isOpen) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  /* Clear the searchTerm if keepSearchTerm is false */
  useEffect(() => {
    if (!keepSearchTerm) {
      setSearchTerm('');
    }
  }, [selectedOptions]);

  useEffect(() => {
    if (debouncedValue.length >= searchCharacterCount) {
      setFilteredOptions(
        options.filter(option => {
          const optionValue = isObject ? option[displayValue] : option;
          const filteredOption = optionValue
            ?.toLocaleLowerCase('tr-TR')
            .includes(debouncedValue.toLocaleLowerCase('tr-TR'));
          return filteredOption;
        })
      );
    }
  }, [debouncedValue, options]);

  const handleOptionClick = option => {
    if (isObject) {
      if (returnedProperty) {
        if (selectedOptions.includes(option[returnedProperty])) {
          setSelectedOptions(
            selectedOptions.filter(selected => selected !== option[returnedProperty])
          );
        } else if (listBadgesReverse) {
          setSelectedOptions([option[returnedProperty], ...selectedOptions]);
        } else {
          setSelectedOptions([...selectedOptions, option[returnedProperty]]);
        }
      } else if (selectedOptions.includes(option)) {
        setSelectedOptions(selectedOptions.filter(selected => selected !== option));
      } else if (listBadgesReverse) {
        setSelectedOptions([option, ...selectedOptions]);
      } else {
        setSelectedOptions([...selectedOptions, option]);
      }
    } else if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter(selected => selected !== option));
    } else if (listBadgesReverse) {
      setSelectedOptions([option, ...selectedOptions]);
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }

    inputRef.current.focus();

    if (!keepSearchTerm) {
      setSearchTerm('');
    }
  };

  const handleToggleDropdown = () => {
    setIsOpen(true);
    inputRef.current.focus();
  };

  const handleBadgeClick = option => {
    setSelectedOptions(selectedOptions.filter(selected => selected !== option));

    inputRef.current.focus();
  };

  const renderListContent = () => {
    if (loadingFlag) {
      return <li className={styles.noResults}>Loading...</li>;
    }

    if (searchCharacterCount > 0 && debouncedValue.length < searchCharacterCount) {
      return (
        <li className={styles.noResults}>
          {/* Enter at least {searchCharacterCount} character to search */}
          <Trans
            i18nKey="enterLeastChar"
            ns="questionBasedAssm"
            values={{ searchChar: searchCharacterCount }}
          >
            <span>1</span>
            <span>2</span>
            <span>3</span>
          </Trans>
        </li>
      );
    }
    const result = [];
    // if there are options and the search term has at least 1 character
    // prepend the select all option
    if (filteredOptions && filteredOptions.length > 0) {
      if (debouncedValue.length >= 1 && showSelectAllOption) {
        result.push(
          <li
            key="selectAll"
            className={styles.option}
            onClick={() => {
              filteredOptions.forEach(option => {
                if (isObject) {
                  if (returnedProperty) {
                    if (selectedOptions.includes(option[returnedProperty])) {
                      // options is already selected, do nothing
                    } else if (listBadgesReverse) {
                      setSelectedOptions(s => [option[returnedProperty], ...s]);
                    } else {
                      setSelectedOptions(s => [...s, option[returnedProperty]]);
                    }
                  } else if (selectedOptions.includes(option)) {
                    // options is already selected, do nothing
                  } else if (listBadgesReverse) {
                    setSelectedOptions(s => [option, ...s]);
                  } else {
                    setSelectedOptions(s => [...s, option]);
                  }
                } else if (selectedOptions.includes(option)) {
                  // options is already selected, do nothing
                } else if (listBadgesReverse) {
                  setSelectedOptions(s => [option, ...s]);
                } else {
                  setSelectedOptions(s => [...s, option]);
                }
              });
              setSearchTerm('');
              setIsOpen(false);
            }}
          >
            <input type="checkbox" readOnly className={styles.checkbox} checked={false} />
            Select All
          </li>
        );
      }
      filteredOptions.map((option, i) =>
        result.push(
          <li
            key={`option${i}`}
            className={styles.option}
            onClick={() => handleOptionClick(option)}
          >
            {showCheckbox && (
              <input
                type="checkbox"
                readOnly
                className={styles.checkbox}
                checked={
                  returnedProperty
                    ? selectedOptions.includes(option[returnedProperty])
                    : selectedOptions.includes(option)
                }
              />
            )}
            {isObject ? option[displayValue] : option}
          </li>
        )
      );
    } else {
      result.push(
        <li key="noResults" className={styles.noResults}>
          No results found
        </li>
      );
    }
    return <>{result}</>;
  };

  return (
    <div className={styles.dropdownContainer} ref={dropdownRef}>
      <div className={styles.dropdownHeader} onClick={handleToggleDropdown}>
        <div className={styles.badgeContainer}>
          {selectedOptions.length > 0 &&
            selectedOptions.map((option, index) => (
              <div key={index} className={styles.badge}>
                {isObject
                  ? returnedProperty
                    ? options.find(o => o[returnedProperty] === option)[displayValue]
                    : option[displayValue]
                  : option}
                {customCloseIcon ? (
                  <span className={styles.closeIcon}>{customCloseIcon}</span>
                ) : (
                  <CloseButton width={20} height={20} onClick={() => handleBadgeClick(option)} />
                )}
              </div>
            ))}
        </div>
        <input
          id={id}
          ref={inputRef}
          type="text"
          className={styles.searchInput}
          placeholder={placeholder}
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
      </div>
      <div className={`${styles.dropdownContent} ${isOpen ? styles.show : ''}`}>
        <div className={`${styles.dropdownList} ${isOpen ? styles.show : ''}`}>
          {renderListContent()}
        </div>
      </div>
    </div>
  );
};

export default MultiSelect;
