import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import useGetCustomFunnelHandler from '../../../Auth/Utils/useGetCustomFunnelHandler';

export default function useFunnelGroupHandler() {
  const [modifiedFunnelGroupList, setModifiedFunnelGroupList] = useState();
  const [listForDropdown, setListForDropdown] = useState();
  const { funnelGroupList, funnelLoading } = useGetCustomFunnelHandler();

  const { t } = useTranslation(['createAssm'])

  useEffect(() => {
    function addDefaultFunnelGroupToList() {
      const defaultFunnelGroup = {
        funnelGroupId: null,
        funnelGroupName: t('default'),
        funnels: [
          {
            customFunnelName: 'Shortlisted',
            priority: 1
          },
          {
            customFunnelName: 'Contacted',
            priority: 2
          },
          {
            customFunnelName: 'Interviewed',
            priority: 3
          },
          {
            customFunnelName: 'Offered',
            priority: 4
          }
        ]
      };

      setModifiedFunnelGroupList([defaultFunnelGroup, ...funnelGroupList]);
    }

    if (funnelGroupList) {
      addDefaultFunnelGroupToList();
    }
  }, [funnelGroupList]);

  useEffect(() => {
    function convertListToDropdownList() {
      const temp = [];
      modifiedFunnelGroupList?.forEach(x =>
        temp.push({
          value: x?.funnelGroupId,
          label: x?.funnelGroupName
        })
      );
      setListForDropdown(temp);
    }

    if (modifiedFunnelGroupList) {
      convertListToDropdownList();
    }
  }, [modifiedFunnelGroupList]);

  return { funnelGroupList: modifiedFunnelGroupList, funnelGroupListForDropdown: listForDropdown };
}
