/* eslint-disable no-return-assign */
import React from 'react';
import styles from '../Styles/UserIcon.module.css';

function UserIcon({
  textClassName,
  comment = false,
  headerComment = false,
  name = '',
  surname = ' ',
  nested = false,
  style = {},
  className = ''
}) {
  function getClassName() {
    if (nested) {
      return styles.NestedPeople;
    }
    if (comment) {
      return styles.comment;
    }
    if (headerComment) {
      return styles.headerComment;
    }
    return 'alp';
  }

  function getTextClass() {
    if (comment) {
      return styles.commentText;
    }
    if (headerComment) {
      return styles.headerCommentText;
    }
    return '';
  }

  return (
    <span
      className={
        comment
          ? `${getClassName()} ${className}`
          : `${styles['user-profile-img']} ${getClassName()}`
      }
      style={style}
    >
      <span
        className={
          comment
            ? `${getTextClass()} ${textClassName}`
            : `${styles.userIconText} ${getTextClass()}`
        }
      >
        {name && surname
          ? `${name} ${surname}`
              .split(/\s/)
              .reduce((response, word) => (response += word.slice(0, 1)), '')
          : ``}
      </span>
    </span>
  );
}

export default UserIcon;
