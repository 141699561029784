import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TestCaseElement from './testCaseElement';

import styles from "../Styles/Coding.module.css";
import { parametersRedux, testCasesRedux, validCasesRedux, outputVariableRedux, verificationSelectedLangRedux } from '../../../redux/addQuestion/selectors';
import { setIsPassedVerificationRedux, setTestCasesRedux, setValidCasesRedux, setVerificationCodeRedux, setVerificationCodeResponseRedux } from '../../../redux/addQuestion/slice';
import { initialCodeGeneration } from '../Constants/codingLanguageConstants';

function TestCases() {
  const dispatch = useDispatch();

  const reduxTestCases = useSelector(testCasesRedux);
  const reduxValidCases = useSelector(validCasesRedux);
  const reduxParameters = useSelector(parametersRedux);
  const reduxOutputVariable = useSelector(outputVariableRedux);
  const reduxSelectedLanguage = useSelector(verificationSelectedLangRedux);

  const [testCaseNo, setTestCaseNo] = useState(reduxTestCases.length);
  const [validCaseNo, setValidCaseNo] = useState(reduxValidCases.length);

  const [testCaseChange, setTestCaseChange] = useState(0);
  const [validCaseChange, setValidCaseChange] = useState(0);

  useEffect(async () => {
    setTestCaseChange(0);
  }, [testCaseChange]);

  useEffect(async () => {
    setValidCaseChange(0);
  }, [validCaseChange]);

  useEffect(async () => {
    setTestCaseNo(reduxTestCases.length);  
  }, [reduxTestCases]);

  useEffect(async () => {
    setValidCaseNo(reduxValidCases.length);
  }, [reduxValidCases]);

  function addTestCaseElement() {
    setTestCaseNo(testCaseNo + 1);
  }
  function addValidCaseElement() {
    setValidCaseNo(validCaseNo + 1);
  }

  const onChangeResetVerificationCode = () => {
    dispatch(setIsPassedVerificationRedux(false));
    const init = initialCodeGeneration(reduxOutputVariable, reduxParameters);
    dispatch(setVerificationCodeRedux(init[reduxSelectedLanguage.value]));
    dispatch(setVerificationCodeResponseRedux(""));
  }

  useEffect(async () => {
    if (testCaseNo > reduxTestCases.length) {
      const temp = reduxTestCases.slice();
      // const innerName = '';
      const innerResult = '';
      const innerWeight = '1';
      const innerInput = [];
      reduxParameters.forEach(e => {
        innerInput.push('');
      })
      temp.push({ input: innerInput, result: innerResult, weight: innerWeight });
      dispatch(setTestCasesRedux(temp));
    }
  }, [testCaseNo]);

  useEffect(async () => {
    if (validCaseNo > reduxValidCases.length) {
      const temp = reduxValidCases.slice();
      // const innerName = '';
      const innerResult = '';
      const innerWeight = '1';
      const innerInput = [];
      reduxParameters.forEach(e => {
        innerInput.push('');
      })
      temp.push({ input: innerInput, result: innerResult, weight: innerWeight });
      dispatch(setValidCasesRedux(temp));
    }
  }, [validCaseNo]);

  const removeTestCaseElement = index => {
    onChangeResetVerificationCode();
    if (testCaseNo > 1) {
      setTestCaseNo(testCaseNo - 1);
      const temp = reduxTestCases.slice();
      temp.splice(index, 1);
      dispatch(setTestCasesRedux(temp));
    }
  };

  const removeValidCaseElement = index => {
    onChangeResetVerificationCode();
    if (validCaseNo > 1) {
      setValidCaseNo(validCaseNo - 1);
      const temp = reduxValidCases.slice();
      temp.splice(index, 1);
      dispatch(setValidCasesRedux(temp));
    }
  };

  return (
    <div className={styles.TestCasesWrapper}>
      <div className={styles.TestCases}>
        <p>Test cases to be used during the test</p>
        {reduxTestCases.map((item, i) => (
          <TestCaseElement
            id={i}
            removeElement={removeTestCaseElement}
            setChange={setTestCaseChange}
            group="test"
          />
        ))}
        <button
          type="button"
          onClick={val => {
            addTestCaseElement();
            setTestCaseChange(1);
            onChangeResetVerificationCode();
          }}
        >
          Add New
        </button>
      </div>
      <div className={styles.ValidationCases}>
        <p>Test cases to be used for validation</p>
        {reduxValidCases.map((item, i) => (
          <TestCaseElement
            id={i}
            removeElement={removeValidCaseElement}
            setChange={setValidCaseChange}
            group="valid"
          />
        ))}
        <button
          type="button"
          onClick={val => {
            addValidCaseElement();
            setValidCaseChange(1);
            onChangeResetVerificationCode();
          }}
        >
          Add New
        </button>
      </div>
    </div>
  );
}

export default TestCases;
