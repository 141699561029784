import { React, useCallback, useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation, Trans } from 'react-i18next';

import { ReactComponent as Plus } from '../../../images/AddAssessment/AssessmentCustomization/new_AddIcon.svg';
import styles from '../Styles/QuestionBasedLeftColumn.module.css';
import route from '../../../constant/route';
import {
  customAssessmentId,
  rootAssessmentQuestionList
} from '../../../redux/assessmentsVol2/selectors';
import CustomButton from '../../../Common/Components/CustomButton';
import HMViewQuestion from './HMViewQuestion';
import GoBackButton from '../../../Common/Components/GoBackButton';
import { setAssessmentActiveTab } from '../../../redux/assessmentsVol2/slice';
import { useWindowSize } from '../../../utils/helpers';
import SkeletonWrapper from '../../../Common/Components/SkeletonWrapper';
import useEditDurationHandler from '../Utils/useEditDurationHandler';

export default function QuestionBasedLeftColumn() {
  const history = useHistory();
  const dispatch = useDispatch();
  const url = window.location.href;
  const lastSegment = url.split('/').pop();

  const { t } = useTranslation(['assmCustomization']);

  const questions = useSelector(rootAssessmentQuestionList);
  const LOADING = !questions;

  const [screenWidth] = useWindowSize();

  const [questionWidth, setQuestionWidth] = useState();
  const WRAPPER_PADDING_LEFT = 35;
  const WRAPPER_PADDING_RIGHT = 24;
  const LIST_WRAPPER_PADDING = 0;
  const HM_QUESTION_PADDING = 7;

  const updateQuestionWidth = useCallback(() => {
    setTimeout(() => {
      const leftPanelWidth = document
        .getElementById('questionBasedLeftColumn')
        ?.getBoundingClientRect()?.width;
      if (leftPanelWidth === 0) {
        setTimeout(() => updateQuestionWidth(), 100);
        return;
      }
      setQuestionWidth(
        leftPanelWidth -
          WRAPPER_PADDING_LEFT -
          WRAPPER_PADDING_RIGHT -
          2 * LIST_WRAPPER_PADDING -
          2 * HM_QUESTION_PADDING
      );
    }, 0);
  }, []);

  useEffect(() => {
    setQuestionWidth(0);
    updateQuestionWidth();
  }, [screenWidth, updateQuestionWidth]);

  const rootAssessmentId = useSelector(customAssessmentId);
  const { EditQuestionDurationModal, setEditDurationQuestionData } = useEditDurationHandler({
    rootAssessmentId: rootAssessmentId
  });

  return (
    <div
      id="questionBasedLeftColumn"
      className={styles.wrapper}
      style={{
        paddingLeft: WRAPPER_PADDING_LEFT,
        paddingRight: WRAPPER_PADDING_RIGHT
      }}
    >
      <div className={styles.questionCount}>
        <div className="FlexRow verticallyCenter">
          <GoBackButton
            style={{ marginLeft: '-34px' }}
            onClick={async () => {
              await dispatch(setAssessmentActiveTab(1));
              if (history.location.key) {
                history.goBack();
              } else {
                history.replace('/add-assessment');
              }
            }}
          />
          
          <SkeletonWrapper loadingCondition={LOADING} style={{ height: '26px', width: '100px' }}>
            {/* <span className={styles.countText}>Questions ({questions?.length})</span> */}
            <span className={styles.countText}>
              <span>
                <Trans
                  i18nKey="questionsCount"
                  ns="assmCustomization"
                  values={{ questionCount: questions && questions.length }}
                >
                  0<span>1</span>
                  <span>2</span>
                </Trans>
              </span>
            </span>
          </SkeletonWrapper>
        </div>

        <SkeletonWrapper loadingCondition={LOADING} className={styles.icon}>
          <NavLink className={styles.icon} to={route.questionBasedAssessment(lastSegment)}>
            <Plus className={styles.plus} />
          </NavLink>
        </SkeletonWrapper>
      </div>

      <SkeletonWrapper loadingCondition={LOADING} className="w-100" style={{ height: '260px' }}>
        {questions?.length === 0 ? (
          <div className={styles.noQuestion}>
            <div className={styles.noQuestionTextContainer}>
              <div className={styles.noQuestionText}>{t('noQuestion')}</div>
              <NavLink
                className={styles.noQuestionButton}
                to={route.questionBasedAssessment(lastSegment)}
              >
                <CustomButton
                  style={{ height: '42px' }}
                  type={1}
                  textField={t('addFirstQuestion')}
                  size="small"
                />
              </NavLink>
            </div>
          </div>
        ) : (
          <div className="gap-12" style={{ padding: LIST_WRAPPER_PADDING, marginBottom: '36px' }}>
            {questions?.map((question, index) => (
              <div style={{ padding: HM_QUESTION_PADDING }} key={question?.questionId}>
                <HMViewQuestion
                  wrapperWidth={questionWidth}
                  question={question}
                  index={index}
                  setEditDurationQuestionData={setEditDurationQuestionData}
                />
              </div>
            ))}
          </div>
        )}
      </SkeletonWrapper>
      {EditQuestionDurationModal}
    </div>
  );
}
