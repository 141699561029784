import React, { useEffect, useState } from 'react';
import MonacoEditor from '@monaco-editor/react';
import { useDispatch, useSelector } from 'react-redux';

import styles from '../Styles/Sql.module.css';
import {
  sqlQueryTypeRedux,
  sqlTestCasesRedux,
  sqlValidCasesRedux
} from '../../../redux/addQuestion/selectors';
import {
  setIsPassedVerificationRedux,
  setSqlTestCases,
  setSqlValidCases,
  setVerificationCodeResponseRedux
} from '../../../redux/addQuestion/slice';

function SqlTestCaseElement({ id, item, removeElement, group }) {
  const dispatch = useDispatch();

  const reduxSqlTestCases = useSelector(sqlTestCasesRedux);
  const reduxSqlValidCases = useSelector(sqlValidCasesRedux);
  const reduxSqlQueryType = useSelector(sqlQueryTypeRedux);

  const [isExpanded, setIsExpanded] = useState(true);

  const onChangeResetVerificationCode = () => {
    dispatch(setIsPassedVerificationRedux(false));
    dispatch(setVerificationCodeResponseRedux(''));
  };

  const handleChange = (value, type) => {
    let temp = [];

    if (group === 'test') {
      temp = [...reduxSqlTestCases];
    } else if (group === 'valid') {
      temp = [...reduxSqlValidCases];
    }

    switch (type) {
      case 'setup':
        temp[id] = { ...temp[id], setup: value };
        break;

      case 'checkingQuery':
        temp[id] = { ...temp[id], checkingQuery: value };
        break;

      case 'result':
        temp[id] = { ...temp[id], result: value };
        break;

      case 'weight':
        temp[id] = { ...temp[id], weight: value };
        break;

      default:
        break;
    }

    if (group === 'test') {
      dispatch(setSqlTestCases(temp));
    } else if (group === 'valid') {
      dispatch(setSqlValidCases(temp));
    }

    onChangeResetVerificationCode();
  };

  const [resultVariable, setResultVariable] = useState([
    ['', ''],
    ['', '']
  ]);

  useEffect(() => {
    if (item && item.result) {
      if (item.result === '\n') {
        setResultVariable([[]]);
      } else {
        const resultVariableArr = [];
        const temp = item.result.split('\n');
        temp.forEach((e, i) => {
          resultVariableArr.push([]);
          const innerTemp = e.split('|');
          innerTemp.forEach(innerE => {
            resultVariableArr[i].push(innerE);
          });
        });
        resultVariableArr.pop();
        setResultVariable(resultVariableArr);
      }
    }
  }, []);

  // Result tablosundaki alanlarda bir değişiklik olduğunda
  const handleResult = (row, column, value) => {
    const temp = [...resultVariable];
    temp[row][column] = value;
    setResultVariable(temp);

    let realResult = '';
    temp.forEach(e => {
      e.forEach((innerE, index) => {
        realResult = realResult.concat(innerE);
        if (index !== e.length - 1) {
          realResult = realResult.concat('|');
        }
      });
      realResult = realResult.concat('\n');
    });
    handleChange(realResult, 'result');
  };

  // Result alanında row/column eklenip çıkarıldığında
  const handleResultOnArrayChange = arr => {
    let realResult = '';
    arr.forEach(e => {
      e.forEach((innerE, index) => {
        realResult = realResult.concat(innerE);
        if (index !== e.length - 1) {
          realResult = realResult.concat('|');
        }
      });
      realResult = realResult.concat('\n');
    });
    if (arr.length === 0) {
      realResult = '\n';
    }
    handleChange(realResult, 'result');
  };

  const addColumn = () => {
    const temp = [];
    if (resultVariable.length === 0) {
      temp.push(['']);
    } else {
      resultVariable.forEach((e, i) => {
        temp.push([]);
        e.forEach(innerE => {
          temp[i].push(innerE);
        });
        temp[i].push('');
      });
    }
    setResultVariable(temp);
    handleResultOnArrayChange(temp);
  };

  const removeColumn = () => {
    const temp = [];
    if (resultVariable[0]?.length === 1) {
      // temp.push()
    } else {
      resultVariable.forEach((e, i) => {
        temp.push([]);
        e.forEach((innerE, innerI) => {
          if (e.length - 1 !== innerI) {
            temp[i].push(innerE);
          }
        });
      });
    }

    setResultVariable(temp);
    handleResultOnArrayChange(temp);
  };

  const addRow = () => {
    const temp = [];
    resultVariable.forEach(e => {
      temp.push(e);
    });
    if (resultVariable[0]?.length === 0) {
      temp[0].push('');
    } else {
      temp.push([]);
      resultVariable[0]?.forEach(e => {
        temp[temp.length - 1].push('');
      });
      if (!resultVariable[0]) {
        temp[temp.length - 1].push('');
      }
    }

    setResultVariable(temp);
    handleResultOnArrayChange(temp);
  };

  const removeRow = () => {
    const temp = resultVariable.slice(0, -1);
    setResultVariable(temp);
    handleResultOnArrayChange(temp);
  };

  return (
    <div className={styles.CaseWrapper}>
      <div className={styles.CaseHeader}>
        <div>Test {id + 1}</div>
        <div className={styles.ButtonWrapper}>
          <button
            type="button"
            onClick={() => {
              removeElement(id);
              setIsExpanded(false);
            }}
          >
            X
          </button>
          <button type="button" onClick={() => setIsExpanded(!isExpanded)}>
            ^
          </button>
        </div>
      </div>
      {isExpanded && (
        <div className={styles.ExpandedWrapper}>
          <div className={styles.Weight}>
            Weight:
            <input
              className={styles.CaseWeightInputField}
              type="text"
              value={item.weight}
              onChange={val => handleChange(val.target.value, 'weight')}
            />
          </div>
          <div className={styles.CaseSetup}>
            Test Case Setup:
            <MonacoEditor
              defaultLanguage="sql"
              theme="vs-dark"
              options={{ wordWrap: 'on', minimap: { enabled: false }, readOnly: false }}
              value={item.setup}
              onChange={val => handleChange(val, 'setup')}
            />
          </div>
          {reduxSqlQueryType !== '' && reduxSqlQueryType !== 'SELECT' && (
            <div className={styles.CaseCheckingQuery}>
              Checking Query:
              <MonacoEditor
                defaultLanguage="sql"
                theme="vs-dark"
                options={{ wordWrap: 'on', minimap: { enabled: false }, readOnly: false }}
                value={item.checkingQuery}
                onChange={val => handleChange(val, 'checkingQuery')}
                className={styles.CheckingEditor}
              />
            </div>
          )}
          <div className={styles.CaseResult}>
            Enter expected result:
            {/* <MonacoEditor
              defaultLanguage="sql"
              theme="vs-dark"
              options={{ wordWrap: 'on', minimap: { enabled: false }, readOnly: false }}
              value={item.result}
              onChange={val => handleChange(val, 'result')}
            /> */}
            <div className={styles.TableLayout}>
              <table>
                <tbody>
                  {resultVariable.map((e, i) => (
                    <tr>
                      {e.map((innerE, innerI) => (
                        <td>
                          <input
                            type="text"
                            value={innerE}
                            onChange={val => handleResult(i, innerI, val.target.value)}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div>
              <span className={styles.AddButtons} onClick={addColumn}>
                Add a new column
              </span>
              <span className={styles.RemoveButtons} onClick={removeColumn}>
                Remove column
              </span>
            </div>
            <div>
              <span className={styles.AddButtons} onClick={addRow}>
                Add a new row
              </span>
              <span className={styles.RemoveButtons} onClick={removeRow}>
                Remove row
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default SqlTestCaseElement;
