import React from 'react';
import Modal from '../../../Common/CustomModal/Modal';
import { ReactComponent as BlueInfo } from '../../../Auth/Images/blueInfoIcon.svg';
import CloseButton from '../../../Common/Components/CloseButton';

const RateLimitModal = ({ onHide, show }) => {
  return (
    <Modal
      onClose={onHide}
      show={show}
      outsideClickClose
      centerVertically
      centerHorizontally
      contentStyle={{
        maxHeight: '90vh',
        borderRadius: '16px',
        padding: '24px 24px 44px 24px'
      }}
      size={{ width: '520px' }}
    >
      <div className="FlexColumn">
        <CloseButton onClick={() => onHide()} size="small" />
        <div className="FlexColumn gap-20">
          <div className="FlexRow horizontallyCenter">
            <BlueInfo />
          </div>
          <span className="FlexRow horizontallyCenter font-24 fw-400 c-darkGray">Heads up!</span>
          <span className="FlexRow horizontallyCenter font-18 fw-300 c-darkGra-08 centerText">
            You&apos;ve reached rate limit for Coensio AI! Check again for magic later.
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default RateLimitModal;
