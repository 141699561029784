import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { currentPlanRedux } from '../../redux/plan/selectors';
import { userSelector } from '../../redux/auth/selectors';

export default function useDisableBilling() {
  const currentPlan = useSelector(currentPlanRedux);
  const currentUser = useSelector(userSelector);

  const [isBillingDisabled, setIsBillingDisabled] = useState(true);

  useEffect(() => {
    if (currentPlan && currentUser) {
      const isDisabled =
        (!currentUser?.isAdmin && currentUser?.userGroupType !== 'Super Admin') ||
        currentPlan?.name === 'Free Trial' ||
        currentPlan?.isCustom;

      setIsBillingDisabled(isDisabled);
    }
  }, [currentPlan, currentUser]);

  return { isBillingDisabled };
}
