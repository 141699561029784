import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import FilterColumn from './FilterColumn';
import QuestionBasedMiddleColumn from './QuestionBasedMiddleColumn';
import QuestionBasedRightColumn from './QuestionBasedRightColumn';
import styles from '../Styles/QuestionBasedFilterBody.module.css';
import {
  filterState,
  pageIndex,
  rootAssessmentQuestionList
} from '../../../redux/assessmentsVol2/selectors';
import {
  getQuestionByFilterAction,
  updateCustomAssessmentAction
} from '../../../redux/assessmentsVol2/actions';
import { setCustomAssessment } from '../../../redux/assessmentsVol2/slice';
import { userSelector } from '../../../redux/auth/selectors';

import mixpanel from '../../../utils/mixpanel';

export default function QuestionBasedFilterBody() {
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const { id } = useParams();
  const qList = useSelector(rootAssessmentQuestionList);
  const data2 = useSelector(filterState);
  useEffect(async () => {
    if (!qList) {
      const data = {
        objStatus: 4,
        rootAssessmentId: id
      };
      await dispatch(updateCustomAssessmentAction(data));
    }

    await dispatch(setCustomAssessment(id));

    /* Mixpanel pageview */
    mixpanel.track('Question Based Assessment - Pageview', {
      'User Id': user.userId,
      'Company Name': user.companyName,
      'Name Surname': user.userNameSurname,
      'Root Assessment Id': id,
      'Root Assessment Name': localStorage.getItem(
        'Mixpanel_AssessmentCustomizationRootAssessmentName'
      )
    });
  }, []);

  const qPerPage = 50;
  const myPageIndex = useSelector(pageIndex);

  // Assessmenta soru eklenip çıkarıldığında getQuestionByFilter'ın tekrar çağrılmasını
  // istiyoruz. Fakat aynı zamanda index'in de tutulmasını istiyoruz. Dolayısıyla currentPage
  // değerini değiştirmeden istek atıp pageSize'ı istediğimiz değere getirmemiz gerekli
  const [resetCurrentListFlag, setResetCurrentFlagList] = useState(false);
  const [resetScrollPositionFlag, setResetScrollPositionFlag] = useState(false);
  const [resetFilterFlag, setResetFilterFlag] = useState(false);

  useEffect(() => {
    if (user.companyId && id && qList) {
      const rawData = {
        ...data2,
        currentPage: 1,
        pageSize: qPerPage * myPageIndex,
        rootAssessmentId: id
      };
      setResetCurrentFlagList(true);
      dispatch(getQuestionByFilterAction(rawData));
    }
  }, [user, id, qList]);

  useEffect(() => {
    if (resetFilterFlag) {
      setResetFilterFlag(false);
    }
  }, [resetFilterFlag]);

  return (
    <div
      className="w-100 h-100 FlexRow verticallyCenter"
      style={{
        overflowY: 'hidden',
        overflowX: 'auto'
      }}
    >
      <div className={styles.filterColumn}>
        <FilterColumn
          setResetScrollPositionFlag={setResetScrollPositionFlag}
          setResetFilterFlag={setResetFilterFlag}
        />
      </div>
      <div className={styles.middleColumn} id="questionBasedMiddleColumn">
        <QuestionBasedMiddleColumn
          resetCurrentListFlag={resetCurrentListFlag}
          setResetCurrentFlagList={setResetCurrentFlagList}
          resetScrollPositionFlag={resetScrollPositionFlag}
          setResetScrollPositionFlag={setResetScrollPositionFlag}
          resetFilterFlag={resetFilterFlag}
        />
      </div>
      <div className={styles.rightColumn}>
        <QuestionBasedRightColumn />
      </div>
    </div>
  );
}
