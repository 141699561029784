/* eslint-disable */
import React, { useRef, useState } from 'react';

import QuestionDetailBody from './QuestionDetailBody';
import ShortText from '../../Common/Components/ShortText';

import styles from '../Styles/QuestionDetail.module.css';

import codingIcon from '../../images/Candidate/PublicPage/codingIcon.svg';
import freeTextIcon from '../../images/Candidate/PublicPage/freeTextIcon.svg';
import multipleChoiceIcon from '../../images/Candidate/PublicPage/multipleChoiceIcon.svg';
import arrow from '../../images/Candidate/PublicPage/arrow.svg';

function CandidateQuestionDetail({ item, index }) {
  const [isExtended, setIsExtended] = useState(false);
  const myRef = useRef(null);

  return (
    <div
      className={styles.BodyWrapper}
      style={{ overflow: 'hidden' }}
      onClick={() => {
        // if (!isExtended) {
        myRef?.current.scrollIntoView({ inline: 'center', block: 'center', behavior: 'smooth' });
        // }
        setIsExtended(!isExtended);
      }}
      ref={myRef}
    >
      <div className={styles.Header}>
        <div className={styles.HeaderLeft}>
          <ShortText name={`${index + 1}. ${item.questionTitle}`} type={3} />
          <div className={styles.questionDifficulty}>{item.difficulty}</div>
        </div>
        <div className={styles.HeaderRight}>
          <div style={item.tabSwitch > 0 ? { color: 'rgba(222, 71, 71, 0.8)' } : {}}>
            {item.tabSwitch !== -1 ? item.tabSwitch : '-'}
          </div>
          <div>
            {item.time === 0 ? '-' :
              item.time % 60 === 0
              ? `${item.time / 60}m`
              : item.time > 60
              ? `${Math.floor(item.time / 60)}m ${item.time % 60}s`
              : `${item.time}s`}
          </div>
          <div
            style={{
              width: '100%',
              alignItems: 'center',
              justifyItems: 'center',
              display: 'grid',
              gridTemplateColumns: '3fr 1fr'
            }}
          >
            <div>
              {item.point !== -1 ? 
                item.point > item.maxPoints ? item.maxPoints : item.point   // To avoid 4.4/4.3 cases
                : 
                0}/{item.maxPoints}
              {/* {item.point}/{item.maxPoints.toFixed(2)} */}
            </div>
            <div style={{ justifySelf: 'start', display: "flex"}}>
              <img
                src={arrow}
                alt=""
                style={
                  isExtended
                    ? { transform: 'rotate(90deg)', transition: '0.2s', width: "24px", height: "24px" }
                    : { transform: 'rotate(0deg)', transition: '0.2s', width: "24px", height: "24px"}
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.Body} ${isExtended ? styles.Extended : ''}`}>
        <QuestionDetailBody
          type={item.questionTypeId}
          qBody={item.questionDescription}
          mcCorrectAnswer={item.questionAnswerOption}
          mcSelectedAnswer={item.candidateAnswerOption}
          mcOptions={item.options}
          ftCandidateAnswer={item.candidateAnswerFreetext}
          cdCandidateAnswer={item.candidateAnswerCode}
          cdCandidateCodeLang={item.questionCategory}
          imageOpt={item.imageOption}
        />
      </div>

      <div className={styles.Tags}>
        <img
          src={
            item.questionTypeId === 1
              ? multipleChoiceIcon
              : item.questionTypeId === 2
              ? codingIcon
              : freeTextIcon
          }
          alt=""
        />
        <div
          className={styles.QuestionLabel}
          style={
            item.questionTypeId !== 2 || item.questionCategory === '' ? { display: 'none' } : {}
          }
        >
          {item.questionCategory.startsWith('notSelected') ? 'Coding' : item.questionCategory}
        </div>

        {item.point >= item.maxPoints ? ( // Max point
          <div className={`${styles.QuestionCorrectness} ${styles.GreenBackground} `}>
            <div
              style={{
                marginRight: '4px',
                width: '4px',
                height: '4px',
                background: '#4AC389',
                borderRadius: '20px'
              }}
            />
            CORRECT
          </div>
        ) : item.point === 0 ? ( // 0 point
          item.questionTypeId === 1 && item.candidateAnswerOption === -1 ? ( // Multiple choice ise ve candidateAnswerOption alanı -1 ise aday soruyu skiplemiş demek, bu durumda da Not Answered basmak istiyoruz
            <div className={`${styles.QuestionCorrectness} ${styles.GreyBackground} `}>
              <div
                style={{
                  marginRight: '4px',
                  width: '4px',
                  height: '4px',
                  background: '#585858',
                  borderRadius: '20px'
                }}
              />
              NOT ANSWERED
            </div>
          ) : item.questionTypeId === 3 &&
            (item.candidateAnswerFreetext === '' ||
              item.candidateAnswerFreetext === 'null' ||
              item.candidateAnswerFreetext === 'Null' ||
              item.candidateAnswerFreetext === null) ? ( // Free text ise ve candidateAnswerFreetext alanı boşsa ("", "null", "Null", null), Not Answered basmak istiyoruz
            <div className={`${styles.QuestionCorrectness} ${styles.GreyBackground} `}>
              <div
                style={{
                  marginRight: '4px',
                  width: '4px',
                  height: '4px',
                  background: '#585858',
                  borderRadius: '20px'
                }}
              />
              NOT ANSWERED
            </div>
          ) : (
            <div className={`${styles.QuestionCorrectness} ${styles.RedBackground} `}>
              <div
                style={{
                  marginRight: '4px',
                  width: '4px',
                  height: '4px',
                  background: '#DE4747',
                  borderRadius: '20px'
                }}
              />
              WRONG
            </div>
          )
        ) : item.point === -1 ? ( // -1 point
          <div className={`${styles.QuestionCorrectness} ${styles.GreyBackground} `}>
            <div
              style={{
                marginRight: '4px',
                width: '4px',
                height: '4px',
                background: '#585858',
                borderRadius: '20px'
              }}
            />
            NOT ANSWERED
          </div>
        ) : (
          // Partial point
          <div className={`${styles.QuestionCorrectness} ${styles.YellowBackground} `}>
            <div
              style={{
                marginRight: '4px',
                width: '4px',
                height: '4px',
                background: '#FFD600',
                borderRadius: '20px'
              }}
            />
            PARTIALLY CORRECT
          </div>
        )}
      </div>
    </div>
  );
}

export default CandidateQuestionDetail;
