import React from 'react';
import useTranslationDetection from '../../../utils/useTranslationDetection';
import Modal from '../../../Common/CustomModal/Modal';

export default function useDisableOnTranslation() {
  const isTranslateEnabled = useTranslationDetection();

  const DisableModal = () => {
    return (
      <Modal
        centerHorizontally
        centerVertically
        show
        onClose={() => {}}
        contentStyle={{ borderRadius: '10px', padding: '18px 24px', maxWidth: '635px' }}
        contentClassName="FlexColumn gap-16 w-100 fw-400 font-16"
      >
        Please turn off translation services to proceed with the test.
      </Modal>
    );
  };

  return { isTranslateEnabled, DisableModal };
}
