import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import styles from '../Styles/AnimatedProgressBar.module.css';

export default function AnimatedProgressBar({ score, color, style = {} }) {
  const circlePath = `M18 2.0845
  a 15.9155 15.9155 0 0 1 0 31.831
  a 15.9155 15.9155 0 0 1 0 -31.831`;

  const Wheel = ({ title, stroke, otherStroke }) => (
    <div style={{ display: 'flex' }}>
      <svg viewBox="0 0 36 36" className={styles.wheel} style={style}>
        <path className={styles['circle-bg']} d={circlePath} />
        <animated.path
          className={styles.circle}
          style={{ stroke: `${color}` }}
          strokeDasharray={otherStroke}
          d={circlePath}
        />
      </svg>
    </div>
  );
  const [active, set] = useState(false);
  const props3 = useSpring({
    stroke: active ? 90 : 0,
    otherStroke: active ? `${score !== -1 ? score : '0'}, 100` : '0,100'
  });

  useEffect(() => {
    set(true);
  });

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className={styles.App}>
      <Wheel
        stroke={props3.stroke.interpolate(x => Math.round(x))}
        otherStroke={props3.otherStroke}
      />
    </div>
  );
}
