import React, { useCallback } from 'react';
import useHandleCameraPermission from './useHandleCameraPermission';
import WebcamComponent from '../Components/WebcamComponent/WebcamComponent';
// import Modal from '../../../Common/CustomModal/Modal';

export default function useCameraHandler(isCameraNeeded, onCapture) {
  const { isCameraRequired, isPermissionGranted } = useHandleCameraPermission(isCameraNeeded);

  function trigger() {
    document.dispatchEvent(new Event('capturePhoto'));
  }

  const WebcamHandler = useCallback(() => {
    if (!isCameraRequired) return null;
    // if (isPermissionGranted) {
    return <WebcamComponent visible={false} onCapture={onCapture} />;
    // return <WebcamComponent visible onCapture={onCapture} />;
    // }
    // if (isPermissionGranted === null) {
    //   return null;
    // }
    // return <PermissionModal />;
    // return null
  }, [isCameraRequired, isPermissionGranted]);

  return { WebcamHandler, trigger };
}

// const PermissionModal = () => {
//   return (
//     <Modal
//       centerHorizontally
//       centerVertically
//       show
//       contentStyle={{ borderRadius: '24px', padding: '20px' }}
//     >
//       Enable the camera and refresh the page
//     </Modal>
//   );
// };
