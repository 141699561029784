import React from 'react';
import styles from '../Styles/QuestionBasedAssessmentBody.module.css';
import QuestionBasedLeftColumn from './QuestionBasedLeftColumn';

export default function QuestionBasedAssessmentBody() {
  return (
    <div className={styles.contentBody} id="questionBasedAssessmentBody">
      <div className={styles.pageWrapper}>
        <QuestionBasedLeftColumn />
      </div>
    </div>
  );
}
