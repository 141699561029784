import React from 'react';
import useCustomFunnelLeftColumnHandler from '../../../Utils/useCustomFunnelLeftColumnHandler';
import useCustomFunnelRightColumnHandler from '../../../Utils/useCustomFunnelRightColumnHandler';
import useCustomFunnelModalHandler from '../../../Utils/useCustomFunnelModalHandler';

export default function CustomFunnelContent() {
  const {
    FunnelGroupList,
    activeFunnelInformation,
    setFunnelGroupList,
    funnelGroupList,
    setActiveFunnelInformation
  } = useCustomFunnelLeftColumnHandler();

  const { FunnelGroupModalContent, setShowModalType } = useCustomFunnelModalHandler(
    activeFunnelInformation,
    setFunnelGroupList,
    funnelGroupList,
    setActiveFunnelInformation
  );

  const { FunnelList } = useCustomFunnelRightColumnHandler(
    activeFunnelInformation,
    setShowModalType,
    setFunnelGroupList,
    setActiveFunnelInformation
  );

  return (
    <>
      <div
        className="FlexRow"
        style={{ minHeight: 'min(640px, 70dvh)', maxHeight: '70dvh', background: '#ffffff' }}
      >
        <div className="FlexColumn" style={{ width: '46%' }}>
          {FunnelGroupList}
        </div>
        <div className="FlexColumn" style={{ width: '54%' }}>
          <FunnelList />
        </div>
      </div>
      {FunnelGroupModalContent}
    </>
  );
}
