import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { useTranslation } from 'react-i18next';

import { notesSelector } from '../../../../redux/candidateDetail/selectors';
import NoteInput from './NoteInput';
import Note from './Note';
import styles from './Notes.module.css';
import { useWindowSize } from '../../../../utils/helpers';

const Notes = () => {
  const notes = useSelector(notesSelector);
  const [windowW, windowH] = useWindowSize();
  const [scrollAvailable, setScrollAvailable] = useState(false);
  const [hover, setHover] = useState(false);

  const { t } = useTranslation(['candidateDetail']);

  useEffect(() => {
    const noteWrapper = document.getElementById('notes-wrapper');
    const scrollH = noteWrapper?.scrollHeight;
    const wrapperH = noteWrapper?.clientHeight;
    if (scrollH > wrapperH) {
      setScrollAvailable(true);
    } else {
      setScrollAvailable(false);
    }
  }, [windowW, JSON.stringify(notes), hover]);

  return (
    <div
      className={styles.wrapper}
      id="notes-wrapper"
      style={{ paddingRight: scrollAvailable && !hover && !isMobile ? '36px' : '24px' }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className={styles.header}>{t('notes')}</div>
      <NoteInput />
      {notes?.map((note, index) => (
        <Note index={index} note={note} />
      ))}
      {notes.length === 0 && (
        <div className={styles.emptyNote}>{t('noNotes')}</div>
      )}
    </div>
  );
};
export default Notes;
