import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import styles from '../Styles/AuthorizeUser.module.css';
import CustomButton from '../../../Common/Components/CustomButton';
import AuthorizeUserList from './AuthorizeUserList';
import { userSelector } from '../../../redux/auth/selectors';
import { getUserListByCompanyAction } from '../../../redux/assessmentsVol2/actions';
import { ReactComponent as InfoIcon } from '../../../images/AddAssessment/CreateAssessment/purpleInfoIcon.svg';

import mixpanel from '../../../utils/mixpanel';
import { useWindowSize } from '../../../utils/helpers';

export default function AuthorizeUser({ id }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const myUser = useSelector(userSelector);
  const [windowW] = useWindowSize();

  const { t } = useTranslation(['createAssm'])

  useEffect(() => {
    if (myUser) {
      dispatch(getUserListByCompanyAction({ CompanyId: myUser.companyId }));
    }
  }, [myUser]);

  /* Mixpanel pageview */
  useEffect(() => {
    mixpanel.track('Create Assessment Page - Authorize User Pageview', {
      'User Id': myUser.userId,
      'Company Name': myUser.companyName,
      'Name Surname': myUser.userNameSurname,
      'Root Assessment Id': id,
      'Root Assessment Name': localStorage.getItem(
        'Mixpanel_AssessmentCustomizationRootAssessmentName'
      )
    });
  }, []);

  return (
    <div className={styles.container}>
      <div>
        {windowW > 700 && <div className={styles.title}>{t('authorizeUser')}</div>}
        <div className={styles.subtitle}>{t('selectUserToAuth')}</div>
        <AuthorizeUserList userRole="rec" style={{ marginBottom: '16px' }} />
        <AuthorizeUserList userRole="hr" />
        {windowW > 700 && (
          <div className={styles.purpleTextWrapper}>
            {/* <span className={styles.okayImg} /> */}
            <InfoIcon style={{ marginRight: '15px' }} />
            <span className={styles.purpleText}>
              {t('dontForgetToAuthorize')}
            </span>
          </div>
        )}
      </div>
      <div className={styles.buttonsWrapper}>
        <CustomButton
          size={windowW <= 700 && 'mobile'}
          type={windowW > 700 ? 5 : 8}
          textField={t('back')}
          buttonOnClick={() => {
            history.replace(`/create-assessment/${id}/${2}`);
          }}
        />
        <CustomButton
          size={windowW <= 700 && 'mobile'}
          textField={t('next')}
          style={{ marginRight: 0, marginLeft: 'auto' }}
          buttonOnClick={() => {
            history.replace(`/create-assessment/${id}/${4}`);
          }}
        />
      </div>
    </div>
  );
}
