import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { useWindowSize } from '../../../utils/helpers';
import styles from '../Styles/PositionSettingsStep.module.css';
import CustomButton from '../../../Common/Components/CustomButton';
import useFunnelGroupHandler from '../Utils/useFunnelGroupHandler';
import UnlabeledSingleSelectDropdown from '../../../Common/Components/UnlabeledSingleSelectDropdown/UnlabeledSingleSelectDropdown';
import CloseButton from '../../../Common/Components/CloseButton';
import NonEditableFunnelList from '../../../Auth/Components/Settings/PositionTab/NonEditableFunnelList';
import ResponsiveModal from '../../../Common/Components/ResponsiveModal/ResponsiveModal';
import { setFunnelGroupId } from '../../../redux/addAssessment/slice';
import TextOverflow from '../../../Common/Components/TextOverflow';

export default function PositionSettingsStep({ id }) {
  const dispatch = useDispatch();
  const [windowW] = useWindowSize();
  const history = useHistory();

  const { t } = useTranslation(['createAssm']);

  const { funnelGroupList, funnelGroupListForDropdown } = useFunnelGroupHandler();
  const [selectedFunnelGroup, setSelectedFunnelGroup] = useState();
  const funnelGroupToBePreviewed = funnelGroupList?.filter(
    x => x?.funnelGroupId === selectedFunnelGroup?.value
  )[0];
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    if (funnelGroupListForDropdown) {
      setSelectedFunnelGroup(funnelGroupListForDropdown[0]);
    }
  }, [funnelGroupListForDropdown]);

  useEffect(() => {
    dispatch(setFunnelGroupId(selectedFunnelGroup?.value));
  }, [selectedFunnelGroup]);

  return (
    <div className={styles.container}>
      <div className="FlexColumn gap-20">
        {windowW > 700 ? <div className={styles.title}>{t('positionSettings')}</div> : null}

        <FunnelGroupSelection
          funnelGroupListForDropdown={funnelGroupListForDropdown}
          selectedFunnelGroup={selectedFunnelGroup}
          setSelectedFunnelGroup={setSelectedFunnelGroup}
          onPreview={() => setShowPreview(true)}
          windowW={windowW}
        />

        <PreviewModal
          show={showPreview}
          onClose={() => setShowPreview(false)}
          funnelGroupDetail={funnelGroupToBePreviewed}
        />
      </div>

      <div className={styles.buttonsWrapper}>
        <CustomButton
          size={windowW && windowW <= 700 && 'mobile'}
          type={windowW > 700 ? 5 : 8}
          textField={t('back')}
          buttonOnClick={() => {
            history.replace(`/create-assessment/${id}/${1}`);
          }}
        />
        <CustomButton
          size={windowW && windowW <= 700 && 'mobile'}
          textField={t('next')}
          style={{ marginRight: 0, marginLeft: 'auto' }}
          buttonOnClick={() => {
            history.replace(`/create-assessment/${id}/${3}`);
          }}
        />
      </div>
    </div>
  );
}

const FunnelGroupSelection = ({
  funnelGroupListForDropdown,
  selectedFunnelGroup,
  setSelectedFunnelGroup,
  onPreview,
  windowW
}) => {
  const { t } = useTranslation(['createAssm']);

  return (
    <div
      className="FlexColumn gap-16"
      style={{
        padding: '20px',
        borderRadius: '12px',
        background: 'var(--gray-06, rgba(244, 244, 244, 0.6))'
      }}
    >
      <span className="fw-400 font-18 c-darkGray">{t('hiringFunnel')}</span>
      <span className="fw-300 font-16 c-darkGray-08">{t('selectFunnelOrContinue')}</span>
      {funnelGroupListForDropdown ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            gap: '16px'
          }}
        >
          <UnlabeledSingleSelectDropdown
            list={funnelGroupListForDropdown}
            selectedValue={selectedFunnelGroup}
            setSelectedValue={setSelectedFunnelGroup}
            placeholder={t('funnelGroups')}
            wrapperStyle={
              windowW && windowW <= 700
                ? {
                    width: '100%',
                    maxWidth: '350px'
                  }
                : {
                    width: '100%',
                    maxWidth: '625px'
                  }
            }
          />
          <span className={`${styles.PreviewButton}`} onClick={onPreview}>
            {t('preview')}
          </span>
        </div>
      ) : (
        <div className="skeletonAnimation w-100" style={{ borderRadius: '12px', height: '42px' }} />
      )}
    </div>
  );
};

const PreviewModal = ({ show, onClose, funnelGroupDetail }) => {
  const ModalContent = (
    <>
      <div
        className="FlexRow gap-24"
        style={{ padding: '0px 0px 0px 10px', maxWidth: '90%', justifyContent: 'space-between' }}
      >
        <TextOverflow
          className="fw-500 font-18 c-darkGray"
          text={funnelGroupDetail?.funnelGroupName}
        />
        <CloseButton onClick={onClose} width={24} height={24} />
      </div>
      <NonEditableFunnelList activeFunnelInformation={funnelGroupDetail} />
    </>
  );

  // const WebModal = (
  //   <Modal
  //     show={show}
  //     onClose={onClose}
  //     centerHorizontally
  //     centerVertically
  //     outsideClickClose
  //     contentStyle={{
  //       padding: '18px 24px 0px 10px',
  //       borderRadius: '10px',
  //       minWidth: '448px',
  //       maxHeight: '80dvh'
  //     }}
  //     contentClassName="FlexColumn gap-10"
  //   >
  //     {ModalContent}
  //   </Modal>
  // );

  const Modal = (
    <ResponsiveModal
      show={show}
      onClose={onClose}
      outsideClickClose
      contentStyle={{
        padding: '18px 24px 0px 10px',
        borderRadius: '10px',
        minWidth: '448px',
        maxHeight: '80dvh'
      }}
      contentClassName="FlexColumn gap-10"
    >
      {ModalContent}
    </ResponsiveModal>
  );

  return Modal;
};
