/* eslint-disable no-return-assign */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { userSelector } from '../../../../redux/auth/selectors';import { candidatePositionsSelector, candidateProfileSelector, loadingForPersonalInfoSelector, personalInfoExperienceSelector, personalInfoNationalitySelector, personalInfoSalarySelector, personalInfoWorkTypeSelector } from '../../../../redux/candidateDetail/selectors';
import { useWindowSize } from '../../../../utils/helpers';
import { genericMixpanelCandidateDetailEventGenerator } from '../helpers';
import styles from './UpperTab.module.css';

const UpperTab = ({ selectedTab, setSelectedTab = () => {} }) => {

  const { t } = useTranslation(['candidateDetail'])

  const tabs = [t('profileTab'), t('assmReportTab'), t('activitiesTab')];
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);

  const tabsRef = useRef([]);
  const [windowW] = useWindowSize();

  useEffect(() => {
    function setTabPosition() {
      const currentTab = tabsRef.current[selectedTab];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    }

    setTabPosition();
    window.addEventListener('resize', setTabPosition);

    return () => window.removeEventListener('resize', setTabPosition);
  }, [selectedTab, windowW]);


  // Mixpanel Assessment Report - Pageview
  // #region 
  const currentUser = useSelector(userSelector);
  const positionsList = useSelector(candidatePositionsSelector);
  const candidateProfile = useSelector(candidateProfileSelector);
  const loadingForPersonalInfoRedux = useSelector(loadingForPersonalInfoSelector);
  const personalInfoExperienceRedux = useSelector(personalInfoExperienceSelector);
  const personalInfoNationalityRedux = useSelector(personalInfoNationalitySelector);
  const personalInfoSalaryRedux = useSelector(personalInfoSalarySelector);
  const personalInfoWorkTypeRedux = useSelector(personalInfoWorkTypeSelector);

  function mixpanelAssessmentReportEvent() {
    genericMixpanelCandidateDetailEventGenerator('Assessment Report - Pageview', currentUser, positionsList, candidateProfile, loadingForPersonalInfoRedux, personalInfoExperienceRedux, personalInfoNationalityRedux, personalInfoSalaryRedux, personalInfoWorkTypeRedux)
  }

  // #endregion

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {tabs?.map((tab, index) => (
          <>
            <div
              ref={el => (tabsRef.current[index] = el)}
              className={`${styles.tabWrapper} ${index === selectedTab ? styles.active : null}`}
              onClick={() => {
                setSelectedTab(index);
                if (index === 1 && selectedTab !== 1) {
                  mixpanelAssessmentReportEvent();
                }
              }}
            >
              {tab}
            </div>
            <span
              className={styles.borderBottom}
              style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
            />
          </>
        ))}
      </div>
    </div>
  );
};

export default UpperTab;
