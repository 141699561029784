import React from 'react';
import styles from '../../../Styles/CustomFunnel.module.css';

const COENSIO_PURPLE = 'var(--coensio)';
const COENSIO_WHITE = 'var(--white)';

const FunnelGroupItem = ({ activeSelection, onClick = () => {}, children }) => {
  return (
    <div
      className={styles.FunnelGroupItem}
      isactive={JSON.stringify(activeSelection)}
      onClick={onClick}
    >
      <FunnelGroupItemBox activeSelection={activeSelection}>{children}</FunnelGroupItemBox>
      <RightTriangle activeSelection={activeSelection} />
    </div>
  );
};

export default FunnelGroupItem;

const FunnelGroupItemBox = ({ activeSelection, children }) => {
  return (
    <div
      className={`${styles.FunnelGroupItemBox} FlexRow verticallyCenter fw-400 font-16 gap-6 pointer`}
      style={{
        padding: '20px',
        width: 'calc(100% - 10px)',
        background: activeSelection ? COENSIO_PURPLE : COENSIO_WHITE,
        zIndex: activeSelection ? 1 : 0
      }}
    >
      {children}
    </div>
  );
};

const RightTriangle = ({ activeSelection }) => {
  return (
    <div
      style={{
        marginRight: '-10px',
        width: '10px',
        height: '100%',
        background: activeSelection ? COENSIO_PURPLE : 'transparent',
        clipPath: 'polygon(0% 0%, 100% 50%, 0% 100%)',
        border: `1px solid ${activeSelection ? COENSIO_PURPLE : 'transparent'}`
      }}
    />
  );
};
