import React from 'react';

const TickIcon = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect y="0.5" width="24" height="24" rx="8" fill="#7458D3" />
      <path
        d="M16.2625 8.65674C16.0897 8.53052 15.8741 8.4774 15.6624 8.50885C15.4508 8.5403 15.26 8.65379 15.1313 8.8248L11.3047 13.9959L8.84193 12.0761C8.75988 12.0039 8.66403 11.9491 8.56018 11.915C8.45632 11.8808 8.34663 11.8681 8.23773 11.8776C8.12883 11.8872 8.02299 11.9187 7.92661 11.9703C7.83024 12.0219 7.74534 12.0925 7.67706 12.1778C7.60877 12.2632 7.55851 12.3615 7.52933 12.4669C7.50014 12.5722 7.49264 12.6824 7.50727 12.7907C7.5219 12.8991 7.55836 13.0033 7.61445 13.0971C7.67053 13.1909 7.74507 13.2724 7.83356 13.3366L10.9233 15.8123C11.0089 15.8795 11.1071 15.9289 11.2121 15.9575C11.3171 15.9861 11.4267 15.9934 11.5346 15.979C11.6424 15.9645 11.7463 15.9286 11.84 15.8733C11.9338 15.818 12.0155 15.7445 12.0803 15.6572L16.4306 9.78793C16.4939 9.70265 16.5398 9.60572 16.5656 9.50269C16.5914 9.39966 16.5967 9.29255 16.5811 9.18749C16.5655 9.08242 16.5293 8.98147 16.4746 8.8904C16.42 8.79932 16.3479 8.71993 16.2625 8.65674Z"
        fill="white"
      />
    </svg>
  );
};

export default TickIcon