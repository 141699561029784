import React from 'react';
import { useSelector } from 'react-redux';
import { encryptedQuestionIdRedux, isSuccessRedux } from '../../../redux/addQuestion/selectors';
import styles from '../Styles/InformationPopup.module.css';
import Modal from '../../../Common/CustomModal/Modal';

export default function InformationPopup({ show, onHide }) {

  const reduxEncryptedQuestionId = useSelector(encryptedQuestionIdRedux);
  const reduxIsSuccess = useSelector(isSuccessRedux);

  return (
    <Modal
      show={show}
      onClose={onHide}
      centerHorizontally
      centerVertically
      outsideClickClose
      contentClassName={styles.modalContent}
    >
      <div className={styles.ModalBody}>
        {reduxIsSuccess === true && (
          <>
            <div>Success</div>
            {reduxEncryptedQuestionId && reduxEncryptedQuestionId !== '' && (
              <button className={styles.Button} type='button' onClick={() => window.open(`/question/${reduxEncryptedQuestionId}`, '_blank').focus()}>Go to Question Detail</button>
            )}
          </>
        )}
        {!reduxIsSuccess && 'Failed'}
        {reduxIsSuccess && reduxIsSuccess !== true && 'Loading'}
      </div>
    </Modal>
  );
}
