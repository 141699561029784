import React from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { candidateSolutionsSelector } from '../../../redux/candidateDetail/selectors';

import ReportQuestionBar from './ReportQuestionBar';
import ReportQuestionWrapper from './ReportQuestionWrapper';

export default function ReportAssessmentSummary() {
  const solutionList = useSelector(candidateSolutionsSelector);
  const { t } = useTranslation(['candidateDetail'])

  return (
    <div className="FlexColumn gap-16">
      <span className="fw-500 font-18 c-darkGray">{t('assmSummary')}</span>
      <div className="FlexColumn gap-24">
        {solutionList?.length > 0 ? (
          <>
            <ReportQuestionBar />
            {solutionList.map((e, i) => (
              <ReportQuestionWrapper question={e} index={i} />
            ))}
          </>
        ) : (
          <span className="fw-400 font-16 c-darkGray-08">
            {t('noResults')}
          </span>
        )}
      </div>
    </div>
  );
}
