import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import QuestionWeightPanel from '../Components/QuestionWeightPanel';
import QuestionBasedAssessmentBody from '../Components/QuestionBasedAssessmentBody';
import styles from '../Styles/AssessmentCustomizationPage.module.css';
import DeleteAssessmentModal from '../Modals/DeleteAssessmentModal';
import ConfimationPopup from '../../../Common/Components/ConfimationPopup';
import RemoveQuestionModal from '../Modals/RemoveQuestionModal';
import {
  publishedAssessment,
  rootAssessmentQuestionList,
  removeQuestionShow,
  removeSkillBasedErrorRedux
} from '../../../redux/assessmentsVol2/selectors';
import { updateCustomAssessmentAction } from '../../../redux/assessmentsVol2/actions';
import {
  setCustomAssessment,
  setPublishedAssessment,
  setRemoveQuestionShow,
  setRemoveSkillBasedError
} from '../../../redux/assessmentsVol2/slice';
import InfoBanner from '../../../Common/Components/InfoBanner';
import { ReactComponent as Error } from '../../../images/Common/errorSubmit.svg';

import { userSelector } from '../../../redux/auth/selectors';
import mixpanel from '../../../utils/mixpanel';
import PageContainer from '../../../Common/Pages/PageContainer';

export default function AssessmentCustomizationPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(null);
  const published = useSelector(publishedAssessment);
  const location = useLocation();
  const { id } = useParams();
  const questions = useSelector(rootAssessmentQuestionList);
  const showRemoveQModal = useSelector(removeQuestionShow);

  // Mixpanel variable
  const user = useSelector(userSelector);

  useEffect(async () => {
    if (!questions) {
      const data = {
        objStatus: 4,
        rootAssessmentId: id
      };
      await dispatch(updateCustomAssessmentAction(data));
      await dispatch(setCustomAssessment(id));
    }

    /* Mixpanel pageview */
    mixpanel.track('Assessment Customization - Pageview', {
      'User Id': user.userId,
      'Company Name': user.companyName,
      'Name Surname': user.userNameSurname,
      'Root Assessment Id': id,
      'Root Assessment Name': localStorage.getItem(
        'Mixpanel_AssessmentCustomizationRootAssessmentName'
      )
    });
  }, []);

  const removeSkillBasedErrorFlag = useSelector(removeSkillBasedErrorRedux);

  const Modals = (
    <>
      <DeleteAssessmentModal
        show={showDeleteModal}
        onHide={() => {
          setShowDeleteModal(false);
        }}
        setConfirmedNavigation={setConfirmedNavigation}
      />
      <ConfimationPopup
        when
        pathname={history.location.pathname}
        deleteModal={setShowDeleteModal}
        confirmedNavigation={confirmedNavigation}
        setConfirmedNavigation={setConfirmedNavigation}
        navigate={path => history.push(path)}
        shouldBlockNavigation={clocation => {
          if (
            clocation.pathname === location.pathname ||
            published ||
            clocation.pathname.startsWith('/question-based-assessment/') ||
            clocation.pathname.startsWith('/try-assessment/')
          ) {
            dispatch(setPublishedAssessment(false));
            return false;
          }
          return true;
        }}
      />

      <RemoveQuestionModal
        show={showRemoveQModal}
        onHide={() => dispatch(setRemoveQuestionShow(false))}
      />

      <InfoBanner
        text="An error occurred. Please try again."
        show={removeSkillBasedErrorFlag === true}
        CustomIcon={Error}
        setStatus={() => {
          dispatch(setRemoveSkillBasedError());
        }}
        bannerStyle={{ border: '1px solid rgba(221, 58, 58, 0.1)' }}
      />
    </>
  );

  const [availableHeight, setAvailableHeight] = useState();

  return (
    <>
      <PageContainer setSpace={setAvailableHeight} isScrollAvailable={false}>
        <div className={styles.pageWrapper} style={{ height: availableHeight }}>
          <div className={styles.left}>
            <QuestionBasedAssessmentBody />
          </div>
          <div className={styles.right}>
            <QuestionWeightPanel />
          </div>
        </div>
      </PageContainer>
      {Modals}
    </>
  );
}
