import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { DndContext, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
  arrayMove
} from '@dnd-kit/sortable';
import styles from '../../../Styles/CustomFunnel.module.css';
import DragIcon from '../../../../images/Auth/Settings/DragIcon';
import TickIcon from '../../../../images/Auth/Settings/TickIcon';
import EditIcon from '../../../../images/Auth/Settings/EditIcon';
import CancelIcon from '../../../../images/Auth/Settings/CancelIcon';
import DeleteIcon from '../../../../images/Auth/Settings/DeleteIcon';
import PlusIcon from '../../../../images/Auth/Settings/PlusIcon';

const DraggableFunnelWrapper = ({ funnelList, updateParentFunnelList }) => {
  const [innerFunnelList, setInnerFunnelList] = useState();
  const [addingNewFunnel, setIsAddingNewFunnel] = useState(false);
  const [editedFunnelIndex, setEditedFunnelIndex] = useState(null);
  const allowAddNewFunnel = !addingNewFunnel && editedFunnelIndex === null;

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5
      }
    })
  );

  function generateUniqueId() {
    return `id_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
  }

  useEffect(() => {
    const modifiedList = funnelList?.map(x => {
      return { ...x, id: generateUniqueId() };
    });
    setInnerFunnelList(modifiedList);
  }, [funnelList]);

  async function handleDragEnd(event) {
    const { active, over } = event;
    const updatedFunnelList = [];

    if (active.id !== over.id) {
      const activeIndex = innerFunnelList?.findIndex(a => a.id === active.id);
      const overIndex = innerFunnelList?.findIndex(a => a.id === over.id);
      const sortedArray = arrayMove(innerFunnelList, activeIndex, overIndex);

      sortedArray.forEach((funnelItem, index) => {
        updatedFunnelList.push({
          customFunnelName: funnelItem?.customFunnelName,
          priority: index + 1,
          id: funnelItem?.id
        });
      });

      setInnerFunnelList(updatedFunnelList);
    }
  }

  useEffect(() => {
    updateParentFunnelList(innerFunnelList);
  }, [innerFunnelList]);

  const itemIds = useMemo(() => innerFunnelList?.map(item => item?.id), [innerFunnelList]);

  function confirmNewStage(newFunnelTitle) {
    setInnerFunnelList(prev => [
      ...prev,
      { customFunnelName: newFunnelTitle, priority: funnelList?.length, id: generateUniqueId() }
    ]);
    setIsAddingNewFunnel(false);
  }

  function cancelNewStage() {
    setIsAddingNewFunnel(false);
  }

  function confirmEditStage(index, newFunnelTitle) {
    setInnerFunnelList(prev =>
      innerFunnelList?.map((x, i) =>
        i === index
          ? { customFunnelName: newFunnelTitle, priority: x?.priority, id: x?.id }
          : { ...x }
      )
    );
    setEditedFunnelIndex(null);
  }

  function cancelEditStage() {
    setEditedFunnelIndex(null);
  }

  function deleteStage(index) {
    setInnerFunnelList(prev => innerFunnelList?.filter((x, i) => i !== index));
  }

  const { t } = useTranslation(['positionTab'])

  return (
    <div className="FlexColumn gap-10 w-100">
      {innerFunnelList ? (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={e => {
            handleDragEnd(e);
          }}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        >
          <SortableContext strategy={verticalListSortingStrategy} items={itemIds}>
            <div className="FlexColumn gap-10">
              {innerFunnelList
                ?.sort((a, b) => a?.priority - b?.priority)
                ?.map((x, i) =>
                  editedFunnelIndex === i ? (
                    <EditableItem
                      initialText={x?.customFunnelName}
                      confirm={e => confirmEditStage(i, e)}
                      cancel={() => cancelEditStage()}
                      funnelList={innerFunnelList}
                    />
                  ) : (
                    <DraggableItem
                      key={x?.id}
                      funnelItem={x}
                      isDragAllowed={editedFunnelIndex === null && !addingNewFunnel}
                      setEditable={() => setEditedFunnelIndex(i)}
                      deleteStage={() => deleteStage(i)}
                    />
                  )
                )}
            </div>
          </SortableContext>
        </DndContext>
      ) : null}

      {addingNewFunnel ? (
        <EditableItem
          confirm={e => confirmNewStage(e)}
          cancel={() => cancelNewStage()}
          funnelList={innerFunnelList}
        />
      ) : null}

      <div
        className={styles.AddStageWrapper}
        onClick={() => {
          if (allowAddNewFunnel) {
            setIsAddingNewFunnel(true);
          }
        }}
        style={!allowAddNewFunnel ? { pointerEvents: 'none', opacity: '0.4' } : {}}
      >
        <PlusIcon width={20} height={20} />
        <span className="fw-400 font-16 c-coensio">{t('addStage')}</span>
      </div>
    </div>
  );
};

export default DraggableFunnelWrapper;

const DraggableItem = ({ funnelItem, isDragAllowed, setEditable, deleteStage }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: funnelItem.id
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const [title, setTitle] = useState(funnelItem?.customFunnelName);

  useEffect(() => {
    setTitle(funnelItem?.customFunnelName);
  }, [funnelItem]);

  return (
    <div
      className={styles.IndividualItem}
      id={`${funnelItem.id}-sorted`}
      ref={setNodeRef}
      style={{ ...style, pointerEvents: isDragAllowed ? 'initial' : 'none' }}
      {...attributes}
      {...(isDragAllowed ? listeners : {})}
    >
      <div
        className="FlexRow w-100 verticallyCenter gap-12 pointer"
        style={{
          padding: '8px 12px',
          borderRadius: '8px',
          background: 'var(--white, #ffffff)',
          border: '1px solid var(--dark-gray-01, rgba(57, 57, 57, 0.1))'
        }}
      >
        <DragIcon />
        <span
          className="fw-400 font-16 c-darkGray noSelect"
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '80%'
          }}
        >
          {title}
        </span>
        <div className="FlexRow gap-8" style={{ marginLeft: 'auto' }}>
          <EditIcon
            className={`${styles.ShowOnParentHover} ${styles.IconHover}`}
            onClick={setEditable}
          />
          <DeleteIcon
            className={`${styles.ShowOnParentHover} ${styles.IconHover}`}
            onClick={deleteStage}
          />
        </div>
      </div>
    </div>
  );
};

const EditableItem = ({ confirm, cancel, initialText = '', funnelList }) => {
  const [title, setTitle] = useState(initialText);
  const isTickDisabled =
    !title || title?.trim() === '' || funnelList?.some(x => x?.customFunnelName === title);

  function cancelClick() {
    cancel();
  }

  async function confirmClick() {
    if (!isTickDisabled) {
      confirm(title);
    }
  }

  // Function to handle key down events
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      confirmClick();
    } else if (event.key === 'Escape') {
      cancelClick();
    }
  };

  return (
    <div
      className="FlexRow w-100 verticallyCenter gap-12"
      style={{
        padding: '8px 12px',
        borderRadius: '8px',
        background: 'var(--white, #ffffff)',
        border: '1px solid var(--coensio, #7458d3)'
      }}
    >
      <DragIcon style={{ opacity: '0.2' }} />
      <input
        className="fw-400 font-16 c-darkGray FlexGrow"
        style={{
          border: 'none',
          height: '24px',
          padding: '0px'
        }}
        autoFocus
        value={title}
        onChange={e => setTitle(e?.target?.value)}
        onKeyDown={handleKeyDown}
      />
      <div className="FlexRow gap-8">
        <CancelIcon className="pointer" onClick={() => cancelClick()} />
        <TickIcon
          className="pointer"
          style={isTickDisabled ? { cursor: 'default', opacity: '0.4' } : {}}
          onClick={() => confirmClick()}
        />
      </div>
    </div>
  );
};
