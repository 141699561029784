/* Filter related selectors */
export const searchParameterSelector = state => state.assessmentDetailVol2.searchParameter;
export const orderTypeSelector = state => state.assessmentDetailVol2.orderType;
export const funnelIdSelector = state => state.assessmentDetailVol2.funnelId;
export const startDateSelector = state => state.assessmentDetailVol2.startDate;
export const endDateSelector = state => state.assessmentDetailVol2.endDate;
export const startScoreSelector = state => state.assessmentDetailVol2.startScore;
export const endScoreSelector = state => state.assessmentDetailVol2.endScore;
export const pageNumberSelector = state => state.assessmentDetailVol2.pageNumber;
export const arriveTypeSelector = state => state.assessmentDetailVol2.arriveType;
export const oldestInitialDateSelector = state => state.assessmentDetailVol2.oldestInitialDate;
export const industryListSelector = state => state.assessmentDetailVol2.industryList;
export const schoolListSelector = state => state.assessmentDetailVol2.schoolList; // filtered schools taken from user filter
export const majorListSelector = state => state.assessmentDetailVol2.majorList; // filtered majors taken from user filter
export const industryIncludeTypeSelector = state => state.assessmentDetailVol2.industryIncludeType;
export const screeningExperiencesSelector = state =>
  state.assessmentDetailVol2.screeningExperiences;
export const screeningWorkTypesSelector = state => state.assessmentDetailVol2.screeningWorkTypes;
export const screeningNationalitiesSelector = state =>
  state.assessmentDetailVol2.screeningNationalities;
export const screeningLocationsSelector = state => state.assessmentDetailVol2.screeningLocations;

export const screeningSalaryCurrencySelector = state =>
  state.assessmentDetailVol2.screeningSalaryCurrency;
export const screeningSalaryMinValueSelector = state =>
  state.assessmentDetailVol2.screeningSalaryMinValue;
export const screeningSalaryMaxValueSelector = state =>
  state.assessmentDetailVol2.screeningSalaryMaxValue;

export const activeFilterCountSelector = state => state.assessmentDetailVol2.activeFilterCount;
export const authFlagSelector = state => state.assessmentDetailVol2.authFlag;
export const rejectReasonListUrlSelector = state => state.assessmentDetailVol2.rejectReasonListUrl;
export const rejectReasonListFromApiSelector = state =>
  state.assessmentDetailVol2.rejectReasonListFromApi;

/* Company Assessment Detail related selectors */
export const industryAvgSelector = state => state.assessmentDetailVol2.industryAvg;
export const top5AvgSelector = state => state.assessmentDetailVol2.top5Avg;
export const assessmentObjStatusSelector = state => state.assessmentDetailVol2.assessmentObjStatus;
export const rootAssessmentNameSelector = state => state.assessmentDetailVol2.rootAssessmentName;
export const ratingSelector = state => state.assessmentDetailVol2.rating;
export const companyAssessmentNameSelector = state =>
  state.assessmentDetailVol2.companyAssessmentName;
export const fiveCandidatesExistFlagSelector = state =>
  state.assessmentDetailVol2.fiveCandidatesExistFlag;
export const isCustomFlagSelector = state => state.assessmentDetailVol2.isCustomFlag;
export const rootAssessmentIdSelector = state => state.assessmentDetailVol2.rootAssessmentId;
export const assessmentDetailLoadingSelector = state =>
  state.assessmentDetailVol2.assessmentDetailLoading;

// Mixpanel related
export const assessmentDetailObjSelector = state => state.assessmentDetailVol2.assessmentDetailObj;

/* Candidate List related state */
export const candidateListSelector = state => state.assessmentDetailVol2.candidateList;
export const selectedCandidateListSelector = state =>
  state.assessmentDetailVol2.selectedCandidateList;
export const candidateCountWithCurrentFilterSelector = state =>
  state.assessmentDetailVol2.candidateCountWithCurrentFilter;
export const loadingForScrollCandidatesSelector = state =>
  state.assessmentDetailVol2.loadingForScrollCandidates;
export const loadingForFirstFetchSelector = state =>
  state.assessmentDetailVol2.loadingForFirstFetch;
export const blurCandidateWrapperAfterMoveSelector = state =>
  state.assessmentDetailVol2.blurCandidateWrapperAfterMoveFlag;

export const newOpMixpanelDataSelector = state => state.assessmentDetailVol2.newOpMixpanelData;

/* Move candidates to funnel related states */
export const isMoveEnabledFlagSelector = state => state.assessmentDetailVol2.isMoveEnabledFlag;

/* Funnel Information */
export const funnelInfoSelector = state => state.assessmentDetailVol2.funnelInfo;
export const funnelOpenSelector = state => state.assessmentDetailVol2.funnelOpen;
export const funnelLoadingSelector = state => state.assessmentDetailVol2.funnelLoading;

/* Popup related selectors */
export const popupShowStateSelector = state => state.assessmentDetailVol2.popupShowState;
export const popupTypeStateSelector = state => state.assessmentDetailVol2.popupTypeState;
export const clickedFunnelSelector = state => state.assessmentDetailVol2.clickedFunnel;

export const rejectModalSelector = state => state.assessmentDetailVol2.rejectModalState;
export const rejectModalSelectedCountSelector = state =>
  state.assessmentDetailVol2.rejectModalSelectedCount;
export const rejectSuccessSelector = state => state.assessmentDetailVol2.rejectSuccessState;

export const infoBannerShowStateSelector = state => state.assessmentDetailVol2.infoBannerShowState;
export const infoBannerTextSelector = state => state.assessmentDetailVol2.infoBannerText;
export const infoBannerErrorFlagSelector = state => state.assessmentDetailVol2.infoBannerErrorFlag;


// Brisa demo stuff
export const showMailModalSelector = state => state.assessmentDetailVol2.showMailModal;
export const mailModalClickedFunnelInfoSelector = state => state.assessmentDetailVol2.mailModalClickedFunnelInfo;