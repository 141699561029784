import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import {
  candidatePositionsSelector,
} from '../../../../redux/candidateDetail/selectors';
import PositionCard from './PositionCard';
import styles from './Positions.module.css';
import leftScroll from './leftScroll.svg';
import leftScrollLight from './leftScrollLight.svg';
import rightScroll from './rightScroll.svg';
import rightScrollLight from './rightScrollLight.svg';
import Skeleton from '../../../../Common/Components/Skeleton';
import { orderPositions } from '../helpers';

const PositionWrapper = () => {
  const candidatePositionsRedux = useSelector(candidatePositionsSelector);
  const [orderedList, setOrderedList] = useState([]);
  const [rightScrollable, setRightScrollable] = useState(true);
  const [leftScrollable, setLeftScrollable] = useState(false);

  const { t } = useTranslation(['candidateDetail'])

  useEffect(() => {
    const orderedList = orderPositions(candidatePositionsRedux);
    setOrderedList(orderedList);
    setRightScrollable(orderedList?.length > 1);
  }, [candidatePositionsRedux]);

  function handleScrollIcons() {
    const listLength = orderedList?.length;
    const lastCard = document
      .getElementById(`positionCardWrapper-${orderedList[listLength - 1].companyAssessmentId}`)
      ?.getBoundingClientRect();
    const firstCard = document
      .getElementById(`positionCardWrapper-${orderedList[0].companyAssessmentId}`)
      ?.getBoundingClientRect();
    const wrapper = document.getElementById('positionWrapper')?.getBoundingClientRect();
    const wrapperRight = wrapper?.right;
    const wrapperLeft = wrapper?.left;
    if (lastCard?.right <= wrapperRight) {
      setRightScrollable(false);
    } else {
      setRightScrollable(true);
    }
    if (firstCard?.left < wrapperLeft) {
      setLeftScrollable(true);
    } else {
      setLeftScrollable(false);
    }
  }

  function handleHorizontalScroll(direction) {
    let availableCardCount = 0;
    let halfCardLeft = 0;
    const wrapper = document.getElementById('positionWrapper')?.getBoundingClientRect();
    const wrapperRight = wrapper?.right;
    const wrapperLeft = wrapper?.left;
    let scrollX = 0;
    let scrollXleft = 0;

    orderedList?.every(catElem => {
      const card = document
        .getElementById(`positionCardWrapper-${catElem.companyAssessmentId}`)
        ?.getBoundingClientRect();
      const cardRight = card?.right;
      const cardLeft = card?.left;
      if (cardRight < wrapperRight && cardLeft > wrapperLeft) {
        availableCardCount += 1;
      }
      if (cardRight + 18 > wrapperRight) {
        scrollX = wrapperLeft - cardLeft;
        return false; // works as break
      }
      return true;
    });
    orderedList?.every((catElem, index) => {
      const card = document
        .getElementById(`positionCardWrapper-${catElem.companyAssessmentId}`)
        ?.getBoundingClientRect();
      const cardRight = card?.right;
      const cardLeft = card?.left;
      if (cardLeft < wrapperLeft && cardRight > wrapperLeft) {
        halfCardLeft = index;
        scrollXleft = wrapperRight - cardRight;
        return false; // works as break
      }
      return true;
    });

    // RIGHT scroll
    if (direction === 'right') {
      document.getElementById('positionCardsWrapper').scrollLeft -= scrollX + 20;
    }
    // LEFT scroll below !
    else if (halfCardLeft !== 0) {
      document.getElementById('positionCardsWrapper').scrollLeft -= scrollXleft - 20;
    } else {
      document.getElementById('positionCardsWrapper').scrollLeft -= availableCardCount * 396 - 20;
    }
    setTimeout(() => {
      handleScrollIcons();
    }, 500);
  }

  return (
    <div className={styles.PositionsWrapper} id="positionWrapper">
      <text className={styles.PositionsText}>
        {t('positions')}
      </text>

      <div className={styles.scrollWrapper}>
        <span className={styles.scrollIcon}>
          <img
            alt=""
            src={leftScrollable ? leftScroll : leftScrollLight}
            onClick={() => {
              if (leftScrollable) {
                handleHorizontalScroll('left');
              }
            }}
          />
        </span>
        <span className={styles.scrollIcon}>
          <img
            alt=""
            src={!rightScrollable ? rightScrollLight : rightScroll}
            onClick={() => {
              if (rightScrollable) {
                handleHorizontalScroll('right');
              }
            }}
          />
        </span>
      </div>

      <div className={styles.CardsWrapper} id="positionCardsWrapper">
        {orderedList?.length === 0 ? (
          <>
            <Skeleton type="positionCard" />
            <Skeleton type="positionCard" />
          </>
        ) : null}

        <div className={styles.blurWrapper}>
          {orderedList?.map(e => (
            <PositionCard item={e} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PositionWrapper;
