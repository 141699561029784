import {
  deleteFilterService,
  getFiltersService,
  getIndustriesService,
  getSchoolsService,
  getScreeningFiltersService,
  renameFilterService,
  saveFilterService,
  triggerAssessmentService,
  getFilterMajorsService,
  getCandidateDataExportService
} from './service';

export const triggerAssessmentAction = async data => {
  try {
    return await triggerAssessmentService(data);
  } catch (error) {
    return null;
  }
};

export const getScreeningFiltersAction = async data => {
  try {
    return await getScreeningFiltersService(data);
  } catch (error) {
    return null;
  }
};

export const getFilterSchoolsAction = async data => {
  try {
    return await getSchoolsService(data);
  } catch (error) {
    return null;
  }
};

export const getIndustriesAction = async data => {
  try {
    return await getIndustriesService(data);
  } catch (error) {
    return null;
  }
};

export const getFiltersAction = async data => {
  try {
    return await getFiltersService(data);
  } catch (error) {
    return null;
  }
};

export const saveFilterAction = async data => {
  try {
    return await saveFilterService(data);
  } catch (error) {
    return null;
  }
};

export const renameFilterAction = async data => {
  try {
    return await renameFilterService(data);
  } catch (error) {
    return null;
  }
};

export const deleteFilterAction = async data => {
  try {
    return await deleteFilterService(data);
  } catch (error) {
    return null;
  }
};

export const getFilterMajorsAction = async data => {
  try {
    return await getFilterMajorsService(data);
  } catch (error) {
    return null;
  }
};

export const getCandidateDataExportAction = async data => {
  try {
    return await getCandidateDataExportService(data);
  } catch (error) {
    return error;
  }
};
